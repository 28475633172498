import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ErrorsService } from 'src/app/core/services/errors.service';
import { ZipCodeService } from 'src/app/core/services/zip-code.service';

import { PasswordValidator } from 'src/app/validators/password.validator';
import { StoreService } from 'src/app/services/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-store-form',
  templateUrl: './store-user-form.component.html',
  styleUrls: ['./store-user-form.component.scss'],
})
export class StoreUserFormComponent implements OnInit {
  public formGroup: FormGroup;
  public states: Object[] = [];
  public plans: Object[] = [];
  public storeID: any;

  constructor(
    public errorsService: ErrorsService,
    private zipCodeService: ZipCodeService,
    private formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.initForm();
  }

  ngOnInit() {

  }

  initForm() {
    this.storeID = this.route.snapshot.paramMap.get('storeID');
    this.formGroup = this.formBuilder.group(
      {
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        emailAddress: [null, [Validators.required, Validators.email]],
        phoneNumber: [null, [Validators.required]],
        storeId: [this.storeID, [Validators.required]],
      },
    );
  };


  async submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    let data = {
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: `+55${this.formGroup.value.phoneNumber}`,
      storeId: this.formGroup.value.storeId,
    }

    this.authService.signup(data).subscribe(
      async (response) => {
        if (response.isCreated) {
          this.snackBar.open('Usuário criado com sucesso.');
          this.router.navigate(['./dashboard/stores/' + this.storeID + '/users']);
        }

      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }
}
