import { enumCmsPermissions } from '../models/enumCmsPermissions';
import * as i0 from "@angular/core";
// import { BusinessPartner } from '../models/businessPartner'
var PermissionsService = /** @class */ (function () {
    function PermissionsService() {
    }
    PermissionsService.prototype.loadUserInfo = function () {
        try {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.userPermissions = this.userInfo.permissions;
        }
        catch (err) {
            console.log("Failed to get user permissions", err);
        }
    };
    PermissionsService.prototype.userHasPermission = function (permission) {
        this.loadUserInfo();
        var userPermissions = this.userPermissions || [];
        var permissionKey = Object.keys(enumCmsPermissions)[Object.values(enumCmsPermissions).indexOf(permission)];
        return userPermissions.includes(permissionKey);
    };
    PermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(); }, token: PermissionsService, providedIn: "root" });
    return PermissionsService;
}());
export { PermissionsService };
