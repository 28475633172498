import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-store-list',
  templateUrl: './closings-incorporations-integrations.component.html',
  styleUrls: ['./closings-incorporations-integrations.component.scss'],
})
export class ClosingsIncorporationsIntegrationsComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'nomeFantasia', 'nd', 'nf']
  public closings: any[] = []
  public formFilter: FormGroup
  public idIncorporation: any

  constructor (
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.idIncorporation = this.route.snapshot.paramMap.get('id')
    console.log(this.idIncorporation)

    this.listAll()
  }

  listAll () {
    this.closings = []

    this.authService.closingsIntegrationsIncorporations(this.idIncorporation).then(
      async response => {
        this.closings = response.results
        console.log(response)
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  calcNF (jcoins) {
    return 0.5 * jcoins / 100
  }
}
