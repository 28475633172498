import { FundService } from 'src/app/services/fund.service'
import { TransactionService } from 'src/app/services/transaction.service'
import { Location } from '@angular/common'
import { CustomersAssociatesService } from 'src/app/services/customersAssociates.service'
import { MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component'
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-funds-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class FundTransactionsComponent implements OnInit {
  fieldsForm: any
  headersTable: string[] = [
    'orderNumber',
    'storeName',
    'fullName',
    'customerCpf',
    'description',
    'createdAt',
    'totalValue',
    'state',
    'actions',
  ]

  listStatus: any[] = []
  listStatusDefault: any[] = []

  dataSource: any
  fund: any = {}
  public userRole
  public fundId = ''
  public params = { formGroupValue: [] }

  public pageTotal: number

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public fundService: FundService,
    public customersAssociatesService: CustomersAssociatesService,
    public transactionService: TransactionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.userRole = localStorage.getItem('userRole')
    this.init().catch(err => {
      this.snackBar.open(err.message)
    })
  }

  async init () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'number',
        formControlName: 'numberFundTransaction',
        label: 'Número da Transação',
        placeholder: 'Número da Transação',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfFundTransaction',
        label: 'CPF do Cliente',
        placeholder: 'CPF do Cliente',
      },
      {
        field: 'input',
        type: 'date',
        formControlName: 'dateFundTransaction',
      },
      {
        field: 'select',
        formControlName: 'statusFundTransaction',
        label: 'Status',
        placeholder: 'Status',
        list: this.listStatus,
        optionDefault: 'TODOS',
      },
    ]
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    await Promise.all([this.fetchTransactionsStatus(), this.currentFund(), this.listTransactions()])
  }

  async currentFund () {
    this.fund = await this.fundService.find(this.fundId)
  }

  async fetchTransactionsStatus () {
    this.listStatusDefault = await this.transactionService.getStatus().toPromise()
    Object.keys(this.listStatusDefault).map(k => {
      if (k !== 'PENDING') {
        this.listStatus.push({ key: k, value: this.listStatusDefault[k] })
      }
    })

    this.listStatus.sort((a, b) => {
      return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
    })
  }

  async listTransactions () {
    const { results, total } = await this.fundService.getTransactions(
      this.fundId,
      `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
    )
    this.pageTotal = total
    this.dataSource = new MatTableDataSource(results)
  }

  detail (transacationId, fullName, customerCpf) {
    if (this.userRole === 'ADMIN') {
      this.transactionService
        .listTransactionsById(`${transacationId}?showManualDetails=true`)
        .subscribe(
          response => {
            this.dialog.open(ModalCustomerInfoComponent, {
              data: {
                customerBalance: response.customerBalance,
                customerCpf: response.customerCpf,
                customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
                customerBirthDate: response.customerBirthDate,
                customerEmailAddress: response.customerEmailAddress,
                customerPhoneNumber: response.customerPhoneNumber,
                customerGender: response.customerGender,
                customerId: response.customerId,
                user: this.userRole,
              },
            })
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      this.dialog.open(ModalCustomerInfoComponent, {
        data: {
          customerCpf: customerCpf,
          customerFullName: fullName,
          user: this.userRole,
        },
      })
    }
  }

  openDialogCancelTransaction (transaction): void {
    const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'cancel') {
        this.cancel(transaction.id, result, transaction.type)
      }
    })
  }

  convertStatusTransactions (transaction) {
    if (transaction.isManual && transaction.state === 'PENDING') {
      return 'AGUARDANDO APROVAÇÃO DA JHSF'
    } else {
      return this.listStatusDefault[transaction.state]
    }
  }

  view (transaction) {
    if (transaction.isManual) {
      localStorage.setItem('isManual', 'true')
    } else {
      localStorage.setItem('isManual', 'false')
    }
  }

  cancel (transactionID, reason, transactionType) {
    const data = {
      reason: reason,
    }

    let cancelObservable: Observable<any>;
    if(transactionType && transactionType.toLowerCase() === 'c2c')
      cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data)
    else 
      cancelObservable = this.transactionService.cancelTransaction(transactionID, data)
    
    cancelObservable.toPromise()
      .then(async response => {
        this.snackBar.open('Transação cancelada com sucesso.')
        return this.listTransactions()
      })
      .catch(error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      })
  }

  voltar () {
    this.location.back()
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
