import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { startWith, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../core/services/errors.service'
import { ReportService } from '../../../../services/report.service'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryFormComponent implements OnInit {
  public formGroup: FormGroup
  public selectedCustomers: any[] = []
  public customerControl = new FormControl()
  // public cpfControl = new FormControl()
  public customerInvalid = false
  customers: any[] = []
  customer: any
  id: string[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]

  filteredCustomers: Observable<string[]>
  filteredCustomersName: Observable<string[]>

  public idBeneficiary: any
  public customerId: any

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>
  // @ViewChild('cpfInput', { static: false }) cpfInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.idBeneficiary = this.route.snapshot.paramMap.get('id')
    this.customerId = this.route.snapshot.paramMap.get('customerId')

    this.formGroup = this.formBuilder.group({
      customersInput: [null, [Validators.required]],
      // cpfInput: [null, [Validators.required]],
      // firstName: [null, [Validators.required]],
      // lastName: [null, [Validators.required]],
      // cpf: [null, [Validators.required]],
    })

    this.reportService.listCustomers().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.authService
      .customerUserInfo(this.customerId)
      .subscribe(
        response => {
          this.customer = response
        },
        error => {
          console.log(error)
        },
      )

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    // this.filteredCustomers = this.cpfControl.valueChanges.pipe(
    //   startWith(''),
    //   map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    // )

    if (this.idBeneficiary) {
      this.beneficiaryFindOne().catch(err => console.log(err))
    }
  }

  async beneficiaryFindOne () {
    await this.authService.findOneBeneficiary(this.idBeneficiary)
      .then(async response => {
        // this.formGroup.patchValue({
        //   firstName: response.firstName,
        //   lastName: response.lastName,
        //   cpf: response.cpf,
        // })
        this.selectedCustomers.push(
          {
            customerId: response.beneficiary[0].customerBeneficiary[0].id,
            cpf: response.beneficiary[0].customerBeneficiary[0].cpf,
            name:
              `${response.beneficiary[0].customerBeneficiary[0].firstName} ${response.beneficiary[0].customerBeneficiary[0].lastName}`,
          },
        )
      })
  }

  private _filter (customer: string): string[] {
    const remove = String(customer).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.customers
      .filter(c => !this.selectedCustomers.includes(c))
      .filter(c =>
        `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
      )
      .slice(0, 20)
  }

  removeCustomer (customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (this.selectedCustomers.length < 1) {
      this.selectedCustomers.push(event.option.value)
    }
    this.customerInput.nativeElement.value = ''
    this.customerControl.setValue(null)
    // this.cpfControl.setValue(null)
    event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true)
  }

  async submit () {
    this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true)
    if (this.customerInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const findCustomers = this.selectedCustomers.find(() => true)
    let data
    if (this.idBeneficiary) {
      data = {
        beneficiaryCustomerId: findCustomers.customerId,
      }
    } else {
      data = {
        customerId: this.customerId,
        beneficiaryCustomerId: findCustomers.customerId,
      }
    }
    if (this.idBeneficiary) {
      this.authService.updateBeneficiary(this.idBeneficiary, data).then(
        async response => {
          if (response.updated) {
            await this.router.navigate(['./dashboard/beneficiary'])
            this.snackBar.open('Beneficiário atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.createBeneficiary(data).then(
        async response => {
          if (response.success) {
            await this.router.navigate(['./dashboard/beneficiary'])
            this.snackBar.open('Beneficiário cadastrado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
