<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Clientes</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-companies">
        VOLTAR
      </button>
      <button
        *ngIf="addNew"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/architect-company/{{ id }}/customers/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>

  <!-- <div *ngIf="company" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ company.name }}</div>
      <div><b>CNPJ: </b>{{ company.cnpj | mask: '00.000.000/0000-00' }}</div>
      <div *ngIf="company.balance > 0"><b>JCoins: </b>{{ company.balance }}</div>
    </mat-card>
  </div> -->

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerCpf | mask: '000.000.000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef>Porcentagem</th>
        <td mat-cell *matCellDef="let element">{{ element.percentage * 100 }}%</td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="isClient">
        <th mat-header-cell *matHeaderCellDef>Cliente cadastrado</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            *ngIf="!element.isPreRegistration"
            matTooltip="Cadastrado"
            style="padding-left: 40%"
            >check_circle_outline</mat-icon
          >
          <mat-icon
            *ngIf="element.isPreRegistration"
            matTooltip="Não cadastrado"
            style="padding-left: 40%"
            >cancel</mat-icon
          >
        </td>
      </ng-container> -->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id, element.companyAirportId)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>

  <!-- <div style="margin-bottom: 1rem; padding-top: 20px">
    <div><b>CPF excluídos</b></div>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSourceDelete">
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerCpf | mask: '000.000.000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerFirstName }} {{ element.customerLastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef>Porcentagem</th>
        <td mat-cell *matCellDef="let element">{{ element.percentage * 100 }}%</td>
      </ng-container>

      <ng-container matColumnDef="isClient">
        <th mat-header-cell *matHeaderCellDef>Cliente cadastrado</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            *ngIf="element.isPreRegistration === 0"
            matTooltip="Cadastrado"
            style="padding-left: 40%"
            >check_circle_outline</mat-icon
          >
          <mat-icon
            *ngIf="element.isPreRegistration === 1"
            matTooltip="Não cadastrado"
            style="padding-left: 40%"
            >cancel</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="deletedAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.deletedAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableDelete"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableDelete"></tr>
    </table>
  </div> -->
</app-logged>
