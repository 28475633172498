import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var EmailConfirmationComponent = /** @class */ (function () {
    function EmailConfirmationComponent(errorsService, authService, snackBar, formBuilder, router, route) {
        this.errorsService = errorsService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.hide = true;
        this.token = '';
    }
    EmailConfirmationComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.paramMap.get('token');
        this.initForm();
    };
    EmailConfirmationComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            token: [this.token, [Validators.required]],
        });
        this.submit();
    };
    EmailConfirmationComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Token inválido.');
                    return [2 /*return*/, false];
                }
                this.authService.emailConfirmation(this.formGroup.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('E-mail confirmado com sucesso.');
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return EmailConfirmationComponent;
}());
export { EmailConfirmationComponent };
