import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import JsFileDownloader from 'js-file-downloader';
var FinancialReceivablesListComponent = /** @class */ (function () {
    function FinancialReceivablesListComponent(receivablesService, prepaymentsService, formBuilder, snackBar, errorsService, storeService) {
        this.receivablesService = receivablesService;
        this.prepaymentsService = prepaymentsService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.storeService = storeService;
        this.isAdmin = localStorage.getItem('userRole') === 'ADMIN';
        this.stores = [];
        this.headersTable = ['name', 'document', 'total', 'status', 'cardValue', 'coinValue', 'cardDiscount', 'coinDiscount', 'date', 'installment'];
        this.receivables = [];
        this.selectedStore = '';
    }
    FinancialReceivablesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formFilter = this.formBuilder.group({
            startDate: ['', [Validators.required]],
            finalDate: ['', [Validators.required]],
            status: [''],
            storeName: ['', [Validators.required]],
        });
        if (this.isAdmin) {
            this.loadStores();
            this.storeOptions = this.formFilter.controls.storeName.valueChanges.pipe(startWith(''), map(function (value) {
                if (value === '') {
                    _this.selectedStore = '';
                }
                return _this.stores.filter(function (store) {
                    var newValue = typeof value === 'object' ? value.nomeFantasia : value;
                    return (store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
                        store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
                        store.cnpj.toLowerCase().includes(newValue.toLowerCase()));
                });
            }));
        }
        else {
            this.selectedStore = JSON.parse(localStorage.getItem('storeSelected')).storeId;
            this.headersTable.splice(0, 2);
        }
    };
    FinancialReceivablesListComponent.prototype.listAll = function (fromDate, toDate, storeId, status) {
        var _this = this;
        if (!storeId) {
            return this.snackBar.open('Insira uma lojá para os recebíveis');
        }
        this.receivables = [];
        this.receivablesService.listAllFromRoadpass({ status: status, fromDate: fromDate, toDate: toDate, storeId: storeId }).subscribe(function (response) {
            _this.receivables = response || [];
        }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    FinancialReceivablesListComponent.prototype.submitFilter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var startDate, finalDate, diffInDays;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formFilter.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                startDate = new Date(this.formFilter.value.startDate);
                finalDate = new Date(this.formFilter.value.finalDate);
                diffInDays = Math.ceil(Math.abs(finalDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1;
                if (diffInDays > 30) {
                    this.snackBar.open('O filtro deve ter no máximo 30  dias.');
                    return [2 /*return*/, false];
                }
                this.listAll(this.formFilter.value.startDate, this.formFilter.value.finalDate, this.selectedStore, this.formFilter.value.status);
                return [2 /*return*/];
            });
        });
    };
    FinancialReceivablesListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    FinancialReceivablesListComponent.prototype.handleStoreSelected = function (event) {
        this.selectedStore = event.option.value.id;
        this.formFilter.patchValue({ storeName: event.option.value.nomeFantasia });
    };
    FinancialReceivablesListComponent.prototype.loadStores = function () {
        var _this = this;
        this.storeService.listStores().subscribe(function (response) {
            _this.stores = response;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    FinancialReceivablesListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formFilter.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        filters = {
                            fromDate: this.formFilter.value.startDate,
                            toDate: this.formFilter.value.finalDate,
                            storeId: this.selectedStore,
                            status: this.formFilter.value.status,
                        };
                        return [4 /*yield*/, this.receivablesService.createReport(filters).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    return [2 /*return*/, new JsFileDownloader({
                                            url: response.fileUrl,
                                            nameCallback: function () {
                                                return "Relat\u00F3rio de Receb\u00EDveis Financeiro. " + response.fileUrl.split('.').splice(5, 5).join('.');
                                            },
                                        })];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return FinancialReceivablesListComponent;
}());
export { FinancialReceivablesListComponent };
