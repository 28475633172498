import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalChooseTransactionTypeComponent = /** @class */ (function () {
    function ModalChooseTransactionTypeComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ModalChooseTransactionTypeComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalChooseTransactionTypeComponent.prototype.onNormalButtonClick = function () {
        this.dialogRef.close('normal');
    };
    ModalChooseTransactionTypeComponent.prototype.onManualButtonClick = function () {
        this.dialogRef.close('manual');
    };
    ModalChooseTransactionTypeComponent.prototype.onPaymentAnticipatedButtonClick = function () {
        this.dialogRef.close('paymentAnticipated');
    };
    ModalChooseTransactionTypeComponent.prototype.ngOnInit = function () { };
    return ModalChooseTransactionTypeComponent;
}());
export { ModalChooseTransactionTypeComponent };
