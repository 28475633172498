<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Prefixo da Aeronave Elegívies</h2>
    <div class="store-list__header__actions">
      <!-- <div style="margin-bottom: 0.5rem"> -->
        <input type="file" (change)="onFileChange($event)" id="file" #inputFile />
        <button
          *ngIf="isImport"
          class="btn-primary-light"
          mat-button
          (click)="clear()"
          style="margin-right: 0.5rem"
        >
          Remover
        </button>
        <button *ngIf="isImport" class="btn-primary-light" mat-button (click)="saveFile()">
          Salvar
        </button>
        <button
          class="btn-primary-light"
          mat-button
          routerLink="/dashboard/airports/not-eligible/new"
        >
          ADICIONAR
        </button>
      <!-- </div> -->
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="prefix">
        <th mat-header-cell *matHeaderCellDef>Prefixo da Aeronave</th>
        <td mat-cell *matCellDef="let element">{{ element.prefix }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!isImport"
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
