import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-monthlyPayment',
  templateUrl: './modal-form-monthlyPayment.component.html',
  styleUrls: ['./modal-form-monthlyPayment.component.scss'],
})
export class ModalFormMonthlyPaymentComponent implements OnInit {
  public formGroup: FormGroup
  public selectedCustomers: any[] = []
  public customerControl = new FormControl()
  public filter: string
  customers: any[] = []
  id: string[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]

  filteredCustomers: Observable<string[]>
  filteredCustomersName: Observable<string[]>

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  constructor(
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalFormMonthlyPaymentComponent>,
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      customersInput: [null, []],
    })

    this.reportService.listCustomers().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )
  }

  private _filter(customer: string): string[] {
    const remove = String(customer).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.customers
      .filter(c => !this.selectedCustomers.includes(c))
      .filter(c =>
        `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
      )
      .slice(0, 20)
  }

  removeCustomer(customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedCustomers.push(event.option.value)
    this.customerInput.nativeElement.value = ''
    this.customerControl.setValue(null)
  }

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  submit() {
    const customers = this.selectedCustomers.map(c => c.customerId)

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.selectedCustomers.length) {
      this.filter = `monthly-payment?customersIds=${customers}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    } else {
      this.filter = `monthly-payment?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    }

    this.reportService
      .createReport(this.filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório de Mensalidade.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
