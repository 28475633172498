import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import JsFileDownloader from 'js-file-downloader';
var ArchitectCompanyFormComponent = /** @class */ (function () {
    function ArchitectCompanyFormComponent(errorsService, snackBar, formBuilder, companiesService, route, router, zipCodeService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.companiesService = companiesService;
        this.route = route;
        this.router = router;
        this.zipCodeService = zipCodeService;
        this.states = [];
        this.commercialPhoneInvalid = false;
        this.phoneNumberInvalid = false;
        this.socialContractInvalid = false;
        this.otherSegment = false;
        this.states = [
            {
                abbr: 'AC',
                name: 'Acre',
            },
            {
                abbr: 'AL',
                name: 'Alagoas',
            },
            {
                name: 'Amapá',
                abbr: 'AP',
            },
            {
                name: 'Amazonas',
                abbr: 'AM',
            },
            {
                name: 'Bahia',
                abbr: 'BA',
            },
            {
                name: 'Ceará',
                abbr: 'CE',
            },
            {
                name: 'Distrito Federal',
                abbr: 'DF',
            },
            {
                name: 'Espírito Santo',
                abbr: 'ES',
            },
            {
                name: 'Goiás',
                abbr: 'GO',
            },
            {
                name: 'Maranhão',
                abbr: 'MA',
            },
            {
                name: 'Mato Grosso',
                abbr: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                abbr: 'MS',
            },
            {
                name: 'Minas Gerais',
                abbr: 'MG',
            },
            {
                name: 'Pará',
                abbr: 'PA',
            },
            {
                name: 'Paraíba',
                abbr: 'PB',
            },
            {
                name: 'Paraná',
                abbr: 'PR',
            },
            {
                name: 'Pernambuco',
                abbr: 'PE',
            },
            {
                name: 'Piauí',
                abbr: 'PI',
            },
            {
                name: 'Rio de Janeiro',
                abbr: 'RJ',
            },
            {
                name: 'Rio Grande do Norte',
                abbr: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                abbr: 'RS',
            },
            {
                name: 'Rondônia',
                abbr: 'RO',
            },
            {
                name: 'Roraima',
                abbr: 'RR',
            },
            {
                name: 'Santa Catarina',
                abbr: 'SC',
            },
            {
                name: 'São Paulo',
                abbr: 'SP',
            },
            {
                name: 'Sergipe',
                abbr: 'SE',
            },
            {
                name: 'Tocantins',
                abbr: 'T',
            },
        ];
    }
    ArchitectCompanyFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            cnpj: [null, [Validators.required]],
            // businessName: [null, []],
            companyName: [null, [Validators.required]],
            responsibleName: [null, [Validators.required]],
            phoneNumber: [null, [Validators.required]],
            emailAddress: [null, [Validators.required]],
            zipCode: [null, [Validators.required]],
            street: [null, [Validators.required]],
            addressNumber: [null, [Validators.required]],
            complement: [null, []],
            neighborhood: [null, [Validators.required]],
            city: [null, [Validators.required]],
            state: [null, [Validators.required]],
            operatingTime: [null, [Validators.required]],
            commercialPhone: [null, [Validators.required]],
            segment: [null, [Validators.required]],
            otherSegment: [null, []],
            storeReferralName: [{ value: null, disabled: true }, []],
        });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    ArchitectCompanyFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService.findOne(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                this.formGroup.patchValue({
                                    cnpj: response.cnpj,
                                    companyName: response.companyName,
                                    responsibleName: response.responsibleName,
                                    phoneNumber: response.phoneNumber,
                                    emailAddress: response.emailAddress,
                                    zipCode: response.zipCode,
                                    street: response.street,
                                    addressNumber: response.addressNumber,
                                    complement: response.complement,
                                    neighborhood: response.neighborhood,
                                    city: response.city,
                                    state: response.state,
                                    operatingTime: response.operatingTime,
                                    commercialPhone: response.commercialPhone,
                                    segment: response.segment,
                                    otherSegment: response.otherSegment,
                                    storeReferralName: response.storeReferralName,
                                });
                                this.otherSegment = response.segment === 'OTHERS';
                                this.documentUrl = response.socialContractUrl;
                                this.approveDoc = response.socialContractName;
                                if (response.cnpj) {
                                    this.formGroup.get('cnpj').disable();
                                }
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectCompanyFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
    };
    ArchitectCompanyFormComponent.prototype.download = function (event) {
        var _this = this;
        event.preventDefault();
        // eslint-disable-next-line no-new
        new JsFileDownloader({
            url: this.documentUrl,
            nameCallback: function () {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return _this.approveDoc;
            },
        });
    };
    ArchitectCompanyFormComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (event.target.files && event.target.files[0]) {
                    reader_1 = new FileReader();
                    reader_1.readAsDataURL(event.target.files[0]); // read file as data url
                    reader_1.onload = function () {
                        _this.documentUrl = reader_1.result;
                    };
                    this.uploadData = new FormData();
                    this.uploadData.append('file', event.target.files[0], event.target.files[0].name);
                    this.approveDoc = event.target.files[0].name;
                    this.socialContractInvalid = false;
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectCompanyFormComponent.prototype.loadAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zipCode;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                zipCode = this.formGroup.controls.zipCode.value;
                if (zipCode.length === 8) {
                    this.zipCodeService.getAddress(zipCode).subscribe(function (address) {
                        _this.formGroup.patchValue({
                            state: address.uf,
                            city: address.localidade,
                            neighborhood: address.bairro,
                            street: address.logradouro,
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectCompanyFormComponent.prototype.onChange = function () {
        this.commercialPhoneInvalid = !this.formGroup.value.commercialPhone;
    };
    ArchitectCompanyFormComponent.prototype.onChangePhone = function () {
        this.phoneNumberInvalid = !this.formGroup.value.phoneNumber;
    };
    ArchitectCompanyFormComponent.prototype.others = function (e) {
        if (e === 'OTHERS') {
            this.otherSegment = true;
        }
        else {
            this.otherSegment = false;
        }
    };
    ArchitectCompanyFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.commercialPhoneInvalid = !this.formGroup.value.commercialPhone;
                this.phoneNumberInvalid = !this.formGroup.value.phoneNumber;
                this.socialContractInvalid = !this.documentUrl;
                if (!this.formGroup.valid && this.commercialPhoneInvalid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    cnpj: this.formGroup.value.cnpj,
                    companyName: this.formGroup.value.companyName,
                    responsibleName: this.formGroup.value.responsibleName,
                    phoneNumber: this.formGroup.value.phoneNumber,
                    emailAddress: this.formGroup.value.emailAddress,
                    zipCode: this.formGroup.value.zipCode,
                    street: this.formGroup.value.street,
                    addressNumber: this.formGroup.value.addressNumber,
                    complement: this.formGroup.value.complement || undefined,
                    neighborhood: this.formGroup.value.neighborhood,
                    city: this.formGroup.value.city,
                    state: this.formGroup.value.state,
                    socialContract: this.documentUrl ? this.documentUrl : undefined,
                    socialContractName: this.approveDoc,
                    operatingTime: this.formGroup.value.operatingTime,
                    commercialPhone: this.formGroup.value.commercialPhone,
                    segment: this.formGroup.value.segment,
                    otherSegment: this.formGroup.value.otherSegment || undefined,
                };
                if (this.id) {
                    this.companiesService.update(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.company) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/architect-companies'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Empresa atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.companiesService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.company) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/architect-companies'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Empresa cadastrada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return ArchitectCompanyFormComponent;
}());
export { ArchitectCompanyFormComponent };
