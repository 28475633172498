import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as queryString from 'query-string';
var StoreSellsReportComponent = /** @class */ (function () {
    function StoreSellsReportComponent(errorsService, formBuilder, reportService, authService, snackBar, currencyPipe) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.isReportToClient = false;
        this.initForm();
    }
    StoreSellsReportComponent.prototype.ngOnInit = function () {
        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    };
    StoreSellsReportComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            initialDate: [
                null,
                [Validators.required, this.dateValidator({ dateMax: new Date() })],
            ],
            finalDate: [new Date().toISOString().slice(0, 10), [Validators.required]],
        }, { validator: [this.formValidator.bind(this)] });
    };
    StoreSellsReportComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, query;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    endDate: this.formGroup.controls.finalDate.value,
                    startDate: this.formGroup.controls.initialDate.value,
                    storeId: this.storeSelected.storeId,
                };
                query = queryString.stringify(data);
                this.reportService.storeSellsReport(query).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.downloadFile(response);
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    // o backend retorna um http status 302, e o browser automaticamente tenta redirecionar pra url do pdf
                    // isso resulta nesse erro, pois está sendo enviado pro AWS todos os nossos headers, e entao ocorre um erro 400
                    // aqui é verificado se a url do erro é a do aws, se sim ele simplesmente abre uma janela com a url, e funciona
                    if (error.url && error.url.indexOf('amazonaws') != -1) {
                        _this.downloadFile(error.url);
                    }
                    else {
                        _this.snackBar.open(error.error.message);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    StoreSellsReportComponent.prototype.downloadFile = function (url) {
        window.open(url, '_blank', 'width=800,height=500,menubar=no,location=no');
    };
    StoreSellsReportComponent.prototype.dateValidator = function (prms) {
        var _this = this;
        if (prms === void 0) { prms = {}; }
        return function (control) {
            var val = new Date(control.value);
            console.log(val);
            return _this.validateDate(val, prms);
        };
    };
    StoreSellsReportComponent.prototype.formValidator = function (form) {
        console.log('formvalidator');
        var finalDateControl = form.controls.finalDate;
        var initialDateControl = form.controls.initialDate;
        if (finalDateControl && initialDateControl) {
            var finalDate = new Date(finalDateControl.value);
            var initialDate = new Date(initialDateControl.value);
            var res = this.validateDate(finalDate, { dateMin: initialDate });
            if (res != null)
                finalDateControl.setErrors(res);
            else
                finalDateControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
        return null;
    };
    StoreSellsReportComponent.prototype.validateDate = function (val, prms) {
        if (!(prms.dateMin instanceof Date) && !(prms.dateMax instanceof Date)) {
            return null;
        }
        else if (prms.dateMin instanceof Date) {
            return val < prms.dateMin ? { dateMin: true } : null;
        }
        else if (prms.dateMax instanceof Date) {
            return val > prms.dateMax ? { dateMax: true } : null;
        }
        else {
            return null;
        }
    };
    return StoreSellsReportComponent;
}());
export { StoreSellsReportComponent };
