import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar, MatDialog } from '@angular/material'
import { NotificationService } from '../../../../services/notification.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-notication-types-form',
  templateUrl: './notication-types-form.component.html',
  styleUrls: ['./notication-types-form.component.scss'],
})
export class NoticationTypesFormComponent implements OnInit {
  public formGroup: FormGroup
  public id: any

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public notificationService: NotificationService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      type: [null, [Validators.required]],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.notificationService.findOneType(this.id).then(async response => {
      this.formGroup.patchValue({
        name: response.name,
        type: response.type,
      })
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      name: this.formGroup.value.name,
      type: this.formGroup.value.type,
    }

    if (this.formGroup.value.type === 'AUTOMATIC') {
      await this.notificationService.listTypes('?type=AUTOMATIC').then(async response => {
        console.log(response[0].id)
        if (response[0].id === this.id) {
          this.createOrUpdate(data)
        } else {
          this.dialog
            .open(ModalConfirmComponent, {
              width: '500px',
              data: {
                text: `Tipo de notificação "${response[0].name}" está como automático deseja remover ?`,
                buttonConfirmText: 'Remover',
              },
            })
            .afterClosed()
            .toPromise()
            .then(async result => {
              if (result && result !== 'cancel') {
                return this.notificationService.deleteType(response[0].id).then(
                  async response => {
                    this.snackBar.open('tipo de notificação removida com sucesso.')
                    this.createOrUpdate(data)
                  },
                  error => {
                    this.snackBar.open(error.error.message)
                  },
                )
              }
            })
            .catch(err => {
              this.snackBar.open(err.message)
            })
        }
      })
    } else {
      this.createOrUpdate(data)
    }
  }

  createOrUpdate (data) {
    if (this.id) {
      this.notificationService.updateType(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/notification-types'])
            this.snackBar.open('Tipo da notificação atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.notificationService.createType(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/notification-types'])
            this.snackBar.open('Tipo da notificação criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
