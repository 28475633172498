import { PrepaymentsService } from 'src/app/services/prepayments.service'
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar, MatTableDataSource } from '@angular/material'
import { startWith, map, distinctUntilChanged, debounceTime, filter } from 'rxjs/operators'
import { Observable, from } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { CompaniesService } from '../../../../../services/companies.service'
import { ReportService } from '../../../../../services/report.service'
import { PreRegistrationService } from 'src/app/services/preRegistration.service'

@Component({
  selector: 'app-architect-company-customer-form',
  templateUrl: './architect-company-customer-form.component.html',
  styleUrls: ['./architect-company-customer-form.component.scss'],
})
export class ArchitectsCompanyCustomerFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  company: any
  totalCustomers: any
  customer1: any
  filteredCustomers1: Observable<string[]>
  customers: any[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]
  cpf: any
  firstName1: any
  hideCustomer1 = true
  hideEdit1 = true
  addCustomer1 = true
  showFirstName1 = false
  percentage1: any
  totalPercentage = 100
  tempPercentageCalc = 100
  public selectedCustomers1: any[] = []
  public customerControl1 = new FormControl()

  targets$: Observable<string[]>
  loadingArchitects = false

  selectedData: any[] = []

  public headersTable: string[] = ['cpf', 'comissionFee', 'fullName', 'actions']
  dataSource: any
  dataSourceDelete: any

  @ViewChild('customerInput1', { static: false }) customerInput1: ElementRef<HTMLInputElement>
  @ViewChild('auto1', { static: false }) matAutocomplete1: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public reportService: ReportService,
    public preRegistrationService: PreRegistrationService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      cpf: [null, []],
      percentage: [null, []],
      fullName: [null, []],
    })

    this.customerControl1.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter((targetNameOrDocument) => !!targetNameOrDocument),
    ).subscribe(targetNameOrDocument => {
      this.targets$ = from(this.searchArchitects(targetNameOrDocument))
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async searchArchitects (termToSearch) {
    this.loadingArchitects = true
    let architects: any = []
    try {
      architects = await this.companiesService.searchArchitectsToAddInCompany(termToSearch)
    } catch (error) {
    } finally {
      this.loadingArchitects = false
    }

    return architects.filter(architect =>
      this.selectedData.findIndex(selected => selected.cpf === architect.cpf) === -1,
    )
  }

  onArchitectChange (architectResponse) {
    
    this.formGroup.patchValue({
      cpf: architectResponse.cpf,
      fullName: [architectResponse.firstName, architectResponse.lastName].join(' '),
    })
    this.customerInput1.nativeElement.value = ''
    this.customerControl1.setValue(null)
    this.tempPercentageCalc = this.totalPercentage
  }

  getArchitectDisplayNames (architect) {
    const displayName = architect.firstName + ' ' + architect.lastName + ' - CPF: ' + architect.cpf
    return displayName
  }
  

  removeSelectedArchitect() {
    this.formGroup.patchValue({
      cpf: null,
      fullName: null,
    })
    this.customerInput1.nativeElement.value = ''
    this.customerControl1.setValue(null)
    this.tempPercentageCalc = this.totalPercentage
  }

  addCpf (event) {
    event.preventDefault()
    const remaining = Number(this.totalPercentage) - Number(this.formGroup.value.percentage)
    this.tempPercentageCalc = remaining
    if (this.formGroup.value.percentage && this.formGroup.value.cpf && remaining >= 0) {
      this.selectedData.push(
        {
          cpf: this.formGroup.value.cpf,
          comissionFee: Number(this.formGroup.value.percentage),
          fullName: this.formGroup.value.fullName,
        },
      )
      this.dataSource = new MatTableDataSource(this.selectedData)
      this.totalPercentage = remaining
      this.formGroup.get('percentage').setValue(null)
      
      this.customerControl1.setValue('')      
      this.removeSelectedArchitect()
      this.targets$ = from([])
    }
  }

  remove (cpf) {
    const index = this.selectedData.indexOf(cpf)
    if (index >= 0) {
      this.selectedData.splice(index, 1)
      this.totalPercentage = 100
      for (const d of this.selectedData) {
        this.totalPercentage = Number(this.totalPercentage) - Number(d.comissionFee)
      }
      this.dataSource = new MatTableDataSource(this.selectedData)
    }
    this.tempPercentageCalc = this.totalPercentage
  }

  async findOne () {
    await this.companiesService
      .findOne(this.id)
      .then(async response => {
        this.company = response
        this.totalPercentage = 100
        for (const c of response.customers) {
          this.selectedData.push({
            cpf: c.customerCpf,
            comissionFee: c.comissionFee,
            fullName: `${c.firstName} ${c.lastName}`,
          })
          this.totalPercentage =
            Number(this.totalPercentage) - Number(c.comissionFee)
        }
        this.dataSource = new MatTableDataSource(this.selectedData)
        this.tempPercentageCalc = this.totalPercentage
      })
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
  }

  async submit () {
    let total = 0
    for (const d of this.selectedData) {
      total = total + Number(d.comissionFee)
    }
    if (this.totalPercentage !== 0 && total !== 100) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    this.selectedData.map(cs => delete cs.fullName)
    this.companiesService.update(this.id, { customers: this.selectedData }).then(
      async response => {
        if (response) {
          this.snackBar.open('Cliente cadastrado com sucesso.')
          this.router.navigate(['./dashboard/architect-companies'])
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
