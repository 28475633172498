import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { TransactionService } from 'src/app/services/transaction.service'
import { Store } from 'src/app/models/store'
import { MatSnackBar } from '@angular/material'
import { DecimalPipe, CurrencyPipe } from '@angular/common'

@Component({
  selector: 'app-store-manual-transaction-form',
  templateUrl: './store-manual-transaction-form.html',
  styleUrls: ['./store-manual-transaction-form.scss'],
})
export class StoreManualTransactionFormComponent implements OnInit {
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: any
  public store: Store
  public installments: any[] = [1]
  public isValidTotalValue = false
  public isValidTotalValueMsg = ''
  public headersTable: string[] = ['minimumTransactionValue']
  public transactionsInfo: any[] = []
  public documentUrl
  public urlUploadS3
  public uploadData: FormData
  public approveDoc: string
  public userRole
  public subStores: any[] = []
  public params = { formGroupValue: [], previousRoute: '', storeID: '', storeFormGroupValue: [] }
  @ViewChild('fileInputBanner', { static: false }) fileInputBanner: ElementRef

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public transactionService: TransactionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
  ) {
    this.initForm()
    this.userRole = localStorage.getItem('userRole')
  }

  ngOnInit () { }

  initForm () {
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
      this.params.formGroupValue = params.formGroupValue
      this.params.previousRoute = params.previousRoute
      this.params.storeID = params.storeID
    })
    if (!this.storeID) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
      this.params.storeID = this.storeID
    }

    this.formGroup = this.formBuilder.group({
      customerCpf: [
        null,
        [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
      ],
      totalValue: [null, [Validators.required]],
      description: [null, [Validators.required]],
      maximumInstallments: [1, [Validators.required]],
      isMembershipActive: [null, [Validators.required]],
      isPayActive: [null, [Validators.required]],
      documentUrl: [null, []],
      subStoreId: [null, []],
    })

    if (this.storeID != '' && this.storeID != null) {
      this.storeService.getStore(this.storeID).subscribe(async response => {
        for (const st of response.subStoreAndMacroGroup) {
          if (st.subStore.length) {
            this.subStores.push(st.subStore[0])
          }
        }
        if (this.subStores.length) {
          this.subStores.sort((a, b) => {
            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0
          })
        }
        this.store = response

        this.formGroup.controls.isMembershipActive.setValue(this.store.isMembershipActive)
        this.formGroup.controls.isPayActive.setValue(this.store.isPayActive)

        this.transactionsInfo = [
          {
            minimumTransactionValue: this.store.minimumTransactionValue / 100,
            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
          },
        ]
      })
    }
  }

  selectFile (event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = evt => {
        // called once readAsDataURL is completed
        this.documentUrl = (<any>evt).target.result
      }

      this.uploadData = new FormData()
      this.uploadData.append('file', event.target.files[0], event.target.files[0].name)
      this.approveDoc = event.target.files[0].name
    } else {
      this.removeDocument()
    }
  }

  imgLoadError () {
    this.documentUrl = null
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.approveDoc = undefined
    this.fileInputBanner.nativeElement.value = ''
  }

  changeValueTotal (event) {
    this.isValidTotalValue = false
    if (this.formGroup.value.totalValue * 100 >= this.store.minimumTransactionValue) {
      this.isValidTotalValueMsg = `O valor mínimo da transação é de R$ ${this.currencyPipe.transform(
        this.store.minimumTransactionValue,
        'BRL',
      )}`
      this.isValidTotalValue = true
      this.calcTotalInstallments()
    }
  }

  calcTotalInstallments () {
    this.installments = [1]
    for (let index = 2; index <= this.store.maximumInstallmentsPerTransaction; index++) {
      if ((this.formGroup.value.totalValue * 100) / index >= this.store.minimumInstallmentValue) {
        this.installments.push(index)
      }
    }
  }

  convertCentsToMoney (value) {
    const money = this.currencyPipe.transform(value, 'BRL')
    // this.formGroup.controls['totalValue'].setValue(money);
    console.log(money)
  }

  convertNumberToBoolean (num) {
    if (num == 0) {
      return false
    } else {
      return true
    }
  }

  async submit () {
    if (this.formGroup.value.totalValue * 100 < this.store.minimumTransactionValue) {
      this.formGroup.controls.totalValue.setErrors({ transactionNotMatch: true })
    } else {
      this.formGroup.controls.totalValue.setErrors(null)
    }

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '')
    const data = {
      customerCpf: this.formGroup.value.customerCpf,
      totalValue: parseInt(totalValue),
      description: this.formGroup.value.description,
      maximumInstallments: this.formGroup.value.maximumInstallments
        ? parseInt(this.formGroup.value.maximumInstallments)
        : 0,
      isMembershipActive: this.formGroup.value.isMembershipActive,
      isPayActive: this.formGroup.value.isPayActive,
      isManual: true,
      approveDoc: this.approveDoc,
      storeId: this.storeID,
      subStoreId: this.formGroup.value.subStoreId,
    }
    this.transactionService.addTransaction(data).subscribe(
      async response => {
        if (response.isCreated) {
          try {
            await this.uploadToS3(response.approveDoc)
            this.finishCreate('Transação criada com sucesso.')
          } catch (e) {
            this.finishCreate(
              'A transação foi criada, mas não foi possível salvar o documento de aprovação.',
            )
          }
        }
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  finishCreate (message: string) {
    this.snackBar.open(message)
    if (this.userRole == 'ADMIN') {
      this.router.navigate(['./dashboard/stores/' + this.storeID + '/transactions/pending'], { queryParams: this.params, skipLocationChange: true })
    } else {
      this.router.navigate(['./dashboard/stores/' + this.storeID + '/transactions'], { queryParams: this.params, skipLocationChange: true })
    }
  }

  async uploadToS3 (s3Url) {
    if (this.uploadData) {
      return await new Promise((resolve, reject) => {
        this.transactionService.uploadAuthorizationDocument(s3Url, this.uploadData).subscribe(
          async response => {
            resolve(response)
          },
          error => {
            reject(error)
          },
        )
      })
    } else {
      return true
    }
  }
}
