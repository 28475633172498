import { MatDialogRef } from '@angular/material';
var ModalInstallmentsComponent = /** @class */ (function () {
    function ModalInstallmentsComponent(dialogRef, data, router) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.router = router;
        this.tableHeader = [
            'installmentNumber',
            'createdAt',
            'amount',
            'installmentValue',
        ];
    }
    ModalInstallmentsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    return ModalInstallmentsComponent;
}());
export { ModalInstallmentsComponent };
