import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import JsFileDownloader from 'js-file-downloader'
import { AuthService } from '../../../../../services/auth.service'
import { CompaniesService } from '../../../../../services/companies.service'

@Component({
  selector: 'app-client-term-form',
  templateUrl: './client-term-form.component.html',
  styleUrls: ['./client-term-form.component.scss'],
})
export class ClientTermFormComponent implements OnInit {
  public formGroup: FormGroup
  public id: string
  public document: string
  public documentUrl: any
  public uploadData: FormData
  public approveDoc: string
  public disapprovedDocument = false
  public approvedDocument: boolean
  public type: string

  @ViewChild('fileInputBanner', { static: false }) fileInputBanner: ElementRef

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public companiesService: CompaniesService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.document = this.route.snapshot.paramMap.get('document')
    this.type = this.route.snapshot.paramMap.get('type')

    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      term: [null, []],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    if (this.type === 'company') {
      await this.companiesService.findOneCompanyTerm(this.id).then(async response => {
        this.documentUrl = response.documentUrl
        this.approveDoc = response.documentName
      })
    } else {
      await this.authService.findOneCustomerTerm(this.id).then(async response => {
        this.documentUrl = response.documentUrl
        this.approveDoc = response.documentName
      })
    }
  }

  async goBack (document) {
    await this.router.navigate([`./dashboard/customer/${document}/terms/${this.type}`])
  }

  async selectFile (event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = () => {
        this.documentUrl = reader.result
      }

      this.uploadData = new FormData()
      this.uploadData.append('file', event.target.files[0], event.target.files[0].name)
      this.approveDoc = event.target.files[0].name
    } else {
      this.removeDocument()
    }
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.approveDoc = undefined
    this.fileInputBanner.nativeElement.value = ''
  }

  download (event) {
    event.preventDefault()
    // eslint-disable-next-line no-new
    new JsFileDownloader({
      url: this.documentUrl,
      nameCallback: () => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return this.approveDoc
      },
    })
  }

  async submit () {
    if (!this.documentUrl) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    let data: any
    if (this.type === 'company') {
      data = {
        cnpj: this.document,
        documentUrl: this.documentUrl,
        documentName: this.approveDoc,
      }
    } else {
      data = {
        cpf: this.document,
        documentUrl: this.documentUrl,
        documentName: this.approveDoc,
      }
    }

    if (this.id) {
      if (this.type === 'company') {
        this.companiesService.updateCompanyTerm(this.id, data).then(
          async response => {
            if (response.isUpdated) {
              await this.router.navigate([`./dashboard/customer/${this.document}/terms/${this.type}`])
              this.snackBar.open('Termo criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        this.authService.updateCustomerTerm(this.id, data).then(
          async response => {
            if (response.isUpdated) {
              if (this.type) {
                await this.router.navigate([`./dashboard/customer/${this.document}/terms/${this.type}`])
              } else {
                await this.router.navigate([`./dashboard/customer/${this.document}/terms`])
              }
              this.snackBar.open('Termo atualizado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      }
    } else {
      if (this.type === 'company') {
        this.companiesService.createCompanyTerm(data).then(
          async response => {
            if (response.isCreated) {
              await this.router.navigate([`./dashboard/customer/${this.document}/terms/${this.type}`])
              this.snackBar.open('Termo criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        this.authService.createCustomerTerm(data).then(
          async response => {
            if (response.isCreated) {
              if (this.type) {
                await this.router.navigate([`./dashboard/customer/${this.document}/terms/${this.type}`])
              } else {
                await this.router.navigate([`./dashboard/customer/${this.document}/terms`])
              }
              this.snackBar.open('Termo criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      }
    }
  }
}
