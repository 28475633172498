import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import JsFileDownloader from 'js-file-downloader';
var ClientTermFormComponent = /** @class */ (function () {
    function ClientTermFormComponent(errorsService, formBuilder, authService, snackBar, router, route, companiesService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.companiesService = companiesService;
        this.disapprovedDocument = false;
    }
    ClientTermFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.document = this.route.snapshot.paramMap.get('document');
        this.type = this.route.snapshot.paramMap.get('type');
        this.initForm();
    };
    ClientTermFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            term: [null, []],
        });
        if (this.id) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    ClientTermFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.type === 'company')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService.findOneCompanyTerm(this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.documentUrl = response.documentUrl;
                                    this.approveDoc = response.documentName;
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.authService.findOneCustomerTerm(this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.documentUrl = response.documentUrl;
                                this.approveDoc = response.documentName;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ClientTermFormComponent.prototype.goBack = function (document) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + document + "/terms/" + this.type])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientTermFormComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (event.target.files && event.target.files[0]) {
                    reader_1 = new FileReader();
                    reader_1.readAsDataURL(event.target.files[0]); // read file as data url
                    reader_1.onload = function () {
                        _this.documentUrl = reader_1.result;
                    };
                    this.uploadData = new FormData();
                    this.uploadData.append('file', event.target.files[0], event.target.files[0].name);
                    this.approveDoc = event.target.files[0].name;
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    ClientTermFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
        this.fileInputBanner.nativeElement.value = '';
    };
    ClientTermFormComponent.prototype.download = function (event) {
        var _this = this;
        event.preventDefault();
        // eslint-disable-next-line no-new
        new JsFileDownloader({
            url: this.documentUrl,
            nameCallback: function () {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return _this.approveDoc;
            },
        });
    };
    ClientTermFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.documentUrl) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (this.type === 'company') {
                    data = {
                        cnpj: this.document,
                        documentUrl: this.documentUrl,
                        documentName: this.approveDoc,
                    };
                }
                else {
                    data = {
                        cpf: this.document,
                        documentUrl: this.documentUrl,
                        documentName: this.approveDoc,
                    };
                }
                if (this.id) {
                    if (this.type === 'company') {
                        this.companiesService.updateCompanyTerm(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isUpdated) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms/" + this.type])];
                                    case 1:
                                        _a.sent();
                                        this.snackBar.open('Termo criado com sucesso.');
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                    }
                    else {
                        this.authService.updateCustomerTerm(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isUpdated) return [3 /*break*/, 5];
                                        if (!this.type) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms/" + this.type])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms"])];
                                    case 3:
                                        _a.sent();
                                        _a.label = 4;
                                    case 4:
                                        this.snackBar.open('Termo atualizado com sucesso.');
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                    }
                }
                else {
                    if (this.type === 'company') {
                        this.companiesService.createCompanyTerm(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isCreated) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms/" + this.type])];
                                    case 1:
                                        _a.sent();
                                        this.snackBar.open('Termo criado com sucesso.');
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                    }
                    else {
                        this.authService.createCustomerTerm(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isCreated) return [3 /*break*/, 5];
                                        if (!this.type) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms/" + this.type])];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, this.router.navigate(["./dashboard/customer/" + this.document + "/terms"])];
                                    case 3:
                                        _a.sent();
                                        _a.label = 4;
                                    case 4:
                                        this.snackBar.open('Termo criado com sucesso.');
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    return ClientTermFormComponent;
}());
export { ClientTermFormComponent };
