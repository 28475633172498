import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { AirportsService } from '../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-airport-info-fields',
  templateUrl: './airport-info-fields-list.component.html',
  styleUrls: ['./airport-info-fields-list.component.scss'],
})
export class AirportInfoFieldsListComponent implements OnInit {
  public headersTable: string[] = ['order', 'fieldTitle', 'fieldValue', 'fieldLabel', 'actions']
  public fields: any[] = []
  public informationID: string
  public airportID: string
  public titleInfo: string

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.informationID = this.route.snapshot.paramMap.get('informationID')
    this.airportID = this.route.snapshot.paramMap.get('airportID')

    this.airportsService.informationFindOne(this.informationID).then(
      response => {
        this.titleInfo = response.title
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    this.listFields()
  }

  listFields () {
    this.airportsService.listInfoFields(this.informationID).then(
      response => {
        this.fields = response
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa coluna?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.deleteField(id).then(
            async response => {
              this.listFields()
              this.snackBar.open('Coluna removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
