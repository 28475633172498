import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StoreUserFormComponent = /** @class */ (function () {
    function StoreUserFormComponent(errorsService, zipCodeService, formBuilder, storeService, authService, router, route, snackBar) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.states = [];
        this.plans = [];
        this.initForm();
    }
    StoreUserFormComponent.prototype.ngOnInit = function () {
    };
    StoreUserFormComponent.prototype.initForm = function () {
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.formGroup = this.formBuilder.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            emailAddress: [null, [Validators.required, Validators.email]],
            phoneNumber: [null, [Validators.required]],
            storeId: [this.storeID, [Validators.required]],
        });
    };
    ;
    StoreUserFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    firstName: this.formGroup.value.firstName,
                    lastName: this.formGroup.value.lastName,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: "+55" + this.formGroup.value.phoneNumber,
                    storeId: this.formGroup.value.storeId,
                };
                this.authService.signup(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.snackBar.open('Usuário criado com sucesso.');
                            this.router.navigate(['./dashboard/stores/' + this.storeID + '/users']);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return StoreUserFormComponent;
}());
export { StoreUserFormComponent };
