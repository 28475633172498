import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service';


@Component({
  selector: 'app-liquidation-transfer-settings',
  templateUrl: './liquidation-transfer-settings.component.html',
  styleUrls: ['./liquidation-transfer-settings.component.scss']
})
export class LiquidationTransferSettings implements OnInit {

  settingsForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    private snackBar: MatSnackBar,
    private liquidationTransferService: LiquidationTransferService,
    ) { }

  ngOnInit(): void {
    
    this.settingsForm = this.formBuilder.group({      
      administrativeTaxCnpjReceiver: [null, [Validators.required]],
      scheduleAdvanceInDays: [null, [Validators.required]], 
      bancoOrigem: [null, [Validators.required]],
      agenciaOrigem: [null, [Validators.required]],
      contaOrigem: [null, [Validators.required]],
      tipoContaDebitada: [null, [Validators.required]],
      tokenUsuario: [null, [Validators.required]],
      inscricaoParceiro : [null, [Validators.required]],
    });   

    this.loadSettings()   
  }

  async loadSettings(){
    const {
      administrativeTaxCnpjReceiver,
      scheduleAdvanceInDays,
      bancoOrigem,
      agenciaOrigem,
      contaOrigem,
      tipoContaDebitada,
      tokenUsuario,
      inscricaoParceiro,
    } = await this.liquidationTransferService.getLiquidationTransferSettings().toPromise()
    
    this.settingsForm.patchValue({      
      administrativeTaxCnpjReceiver: administrativeTaxCnpjReceiver,
      scheduleAdvanceInDays: scheduleAdvanceInDays,
      bancoOrigem: bancoOrigem,
      agenciaOrigem: agenciaOrigem,
      contaOrigem: contaOrigem,
      tipoContaDebitada: tipoContaDebitada,
      tokenUsuario: tokenUsuario,
      inscricaoParceiro: inscricaoParceiro,
    })    
  }
 

  async submitForm() {

    if (!this.settingsForm.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {      
      administrativeTaxCnpjReceiver: this.settingsForm.get('administrativeTaxCnpjReceiver').value,
      scheduleAdvanceInDays: this.settingsForm.get('scheduleAdvanceInDays').value,
      bancoOrigem: this.settingsForm.get('bancoOrigem').value,
      agenciaOrigem: this.settingsForm.get('agenciaOrigem').value,
      contaOrigem: this.settingsForm.get('contaOrigem').value,
      tipoContaDebitada: this.settingsForm.get('tipoContaDebitada').value,
      tokenUsuario: this.settingsForm.get('tokenUsuario').value,
      inscricaoParceiro: this.settingsForm.get('inscricaoParceiro').value,
    }
    
    try {
      const res = await this.liquidationTransferService.updateLiquidationTransferSettings(data).toPromise()      
      this.snackBar.open('Configurações atualizadas com sucesso')
    } catch(e) {
      this.snackBar.open('Problema ao atualizar as configurações')
    }
  
  }

}