import { Component, OnInit } from '@angular/core'
import { NotificationService } from '../../../../services/notification.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { Location } from '@angular/common'
import * as moment from 'moment'
import {
  MatDialog,
  MatSnackBar,
  MatTableDataSource,
} from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-notification-types-list',
  templateUrl: './notification-types-list.component.html',
  styleUrls: ['./notification-types-list.component.scss'],
})
export class NotificationTypesListComponent implements OnInit {
  public headersTable: string[]
  public pageTotal: number
  dataSource: any
  fieldsForm: any
  formGroup: FormGroup

  constructor (
    public notificationService: NotificationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.notificationList()
    this.headersTable = ['name', 'type', 'actions']
  }

  notificationList () {
    this.notificationService
      .listTypes()
      .then(
        async response => {
          this.dataSource = new MatTableDataSource(response)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse tipo de notificação?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.notificationService.deleteType(id).then(
            async response => {
              this.notificationList()
              this.snackBar.open('tipo de notificação removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
