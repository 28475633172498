import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import numeral from 'numeral';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalRejectGenericComponent } from 'src/app/components/modal-reject-generic/modal-reject-generic.component';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
var ArchitectInvoiceDetailComponent = /** @class */ (function () {
    function ArchitectInvoiceDetailComponent(location, architectInvoiceService, route, dialog, snackBar, permissionsService) {
        this.location = location;
        this.architectInvoiceService = architectInvoiceService;
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.permissionsService = permissionsService;
        this.tableColumns = ['name', 'fee', 'jcoins', 'cashbackStatus', 'customerStatus'];
        if (this.route.snapshot.paramMap.get('architectInvoiceId')) {
            this.architectInvoiceId = this.route.snapshot.paramMap.get('architectInvoiceId');
        }
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = localStorage.getItem('userRole');
    }
    ArchitectInvoiceDetailComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.architectInvoiceService.get(this.architectInvoiceId)];
                    case 1:
                        _a.invoice = _c.sent();
                        this.invoiceStatus = this.architectInvoiceService.getInvoiceStatus(this.invoice);
                        if (!this.isArchitectAdmin()) return [3 /*break*/, 3];
                        _b = this;
                        return [4 /*yield*/, this.architectInvoiceService.getDetails(this.architectInvoiceId)];
                    case 2:
                        _b.invoiceDetails = _c.sent();
                        _c.label = 3;
                    case 3:
                        console.log(this.invoice);
                        console.log(this.invoiceDetails);
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceDetailComponent.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectInvoiceDetailComponent.prototype.getCustomerName = function (architectInvoicePaymentHistory) {
        var customer = architectInvoicePaymentHistory.customer, preRegistrationArchitect = architectInvoicePaymentHistory.preRegistrationArchitect;
        try {
            return customer
                ? customer.firstName + " " + customer.lastName
                : preRegistrationArchitect.preRegistration.firstName + " " + preRegistrationArchitect.preRegistration.lastName;
        }
        catch (e) {
            return '';
        }
    };
    ArchitectInvoiceDetailComponent.prototype.getCustomerJcoinStatus = function (architectInvoicePaymentHistory) {
        var customer = architectInvoicePaymentHistory.customer;
        var _a = architectInvoicePaymentHistory.paymentHistories, isComputed = _a.isComputed, isConfirmPointsQueued = _a.isConfirmPointsQueued, isPendingPointsQueued = _a.isPendingPointsQueued, isCanceled = _a.isCanceled, loyaltyPendingId = _a.loyaltyPendingId;
        if (isComputed)
            return 'Jcoins confirmados';
        if (isConfirmPointsQueued)
            return 'Processando confirmação dos Jcoins...';
        if (isPendingPointsQueued)
            return 'Processando Jcoins pendentes...';
        if (isCanceled)
            return 'Jcoins cancelados';
        if (loyaltyPendingId)
            return 'Jcoins Pendentes - Aguardando aprovação da Nota';
        if (!customer)
            return 'Aguardando cadastro do cliente';
        return 'Aguardando pagamento da Nota';
    };
    ArchitectInvoiceDetailComponent.prototype.validateInvoice = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.dialog.open(ModalGenericComponent, {
                                width: '500px',
                                data: {
                                    text: 'Deseja validar essa nota fiscal?',
                                    buttonConfirmText: 'Validar Nota',
                                },
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.architectInvoiceService.validateInvoices([this.architectInvoiceId])];
                    case 2:
                        _a.sent();
                        this.snackBar.open('Nota Fiscal validada');
                        window.location.reload();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.snackBar.open('Não foi possível validar essa Nota fiscal');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceDetailComponent.prototype.invalidateInvoice = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reason, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.dialog
                                .open(ModalRejectGenericComponent, {
                                width: '500px',
                                data: {
                                    title: 'Deseja tornar essa nota fiscal inválida?',
                                    buttonText: 'Invalidar nota fiscal',
                                    reasonText: 'Motivo da rejeição',
                                },
                            })
                                .afterClosed()
                                .toPromise()];
                    case 1:
                        reason = _a.sent();
                        if (!(reason && reason !== 'cancel')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.architectInvoiceService.invalidateInvoices(this.architectInvoiceId, reason)];
                    case 2:
                        _a.sent();
                        this.snackBar.open('Nota Fiscal invalidada');
                        window.location.reload();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_2 = _a.sent();
                        this.snackBar.open('Não foi possível invalidar essa Nota fiscal');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceDetailComponent.prototype.showValidateButtons = function () {
        if (!this.invoiceDetails)
            return false;
        return !this.invoiceDetails.cancelled
            && !this.invoiceDetails.approved
            && !this.invoiceDetails.validated
            && !this.invoiceDetails.invalidated;
    };
    ArchitectInvoiceDetailComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ArchitectInvoiceDetailComponent.prototype.multiplyTo100 = function (value) {
        return numeral(value).multiply(100).value();
    };
    ArchitectInvoiceDetailComponent.prototype.isArchitectAdmin = function () {
        return this.userRole === 'ADMIN'
            || this.permissionsService.userHasPermission(enumCmsPermissions.architect_admin_menu);
    };
    return ArchitectInvoiceDetailComponent;
}());
export { ArchitectInvoiceDetailComponent };
