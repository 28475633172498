<app-logged class="store-list">
  <header class="store-list__header">
    <h2>USUÁRIOS MEMBERSHIP</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/memberships/plans" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <app-form-filter actions="form" type="membershipPlanCustomers" maskExample="000.000.000-00" [fieldsForm]="fieldsForm"
    [planId]="planId" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>PLANO MEMBERSHIP: </b>{{ planName }}</div>
      <div><b>Data Início: </b>{{ beginDate | date: 'dd/MM/yyyy' }}</div>
      <div><b>Data Fim: </b>{{ endDate | date: 'dd/MM/yyyy' }}</div>
      <div><b>Valor: </b>{{ convertCentsToReal(value) | currency: 'BRL':'R$' }}</div>
      <div><b>Período Recorrente: </b>{{ month(recurrencePeriod) }}</div>
      <div>
        <b>Ativo: </b>
        <mat-icon *ngIf="active">check_circle_outline</mat-icon>
        <mat-icon *ngIf="!active">build_circle</mat-icon>
      </div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.cpf }}" class="cellText">
          {{ element.cpf | mask: '000.000.000-00' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="beginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Início</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.beginDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Final</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.endDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastPayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ultimo Pagamento</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.lastPayment | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Transações" matTooltipPosition="left" routerLink="/dashboard/memberships/transactions/plan/{{ element.planId }}/customer/{{
              element.customerId
            }}">
            <mat-icon>swap_horiz</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter actions="pagination" type="membershipPlanCustomers" [length]="pageTotal" [planId]="planId"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
