/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./modal-confirmation.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/material/snack-bar";
var styles_ModalConfirmationComponent = [i0.styles];
var RenderType_ModalConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalConfirmationComponent, data: {} });
export { RenderType_ModalConfirmationComponent as RenderType_ModalConfirmationComponent };
export function View_ModalConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "main", [["class", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "link-cancel"], ["mat-icon-button", ""], ["matTooltip", "Cancelar"], ["matTooltipPosition", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-card", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deseja aprovar essa transa\u00E7\u00E3o manual?"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "btn-submit-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn-submit-dark"], ["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sim"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ModalConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalConfirmationComponent_0, RenderType_ModalConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalConfirmationComponent, [i5.MatDialogRef, i5.MAT_DIALOG_DATA, i6.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalConfirmationComponentNgFactory = i1.ɵccf("app-modal", i4.ModalConfirmationComponent, View_ModalConfirmationComponent_Host_0, {}, {}, []);
export { ModalConfirmationComponentNgFactory as ModalConfirmationComponentNgFactory };
