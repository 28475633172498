import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
var AirportCompanyCustomerFormComponent = /** @class */ (function () {
    function AirportCompanyCustomerFormComponent(errorsService, snackBar, formBuilder, airportsService, route, router, reportService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.airportsService = airportsService;
        this.route = route;
        this.router = router;
        this.reportService = reportService;
        this.customers = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.hideCustomer1 = true;
        this.hideCustomer2 = true;
        this.hideCustomer3 = true;
        this.hideCustomer4 = true;
        this.hideEdit1 = true;
        this.hideEdit2 = true;
        this.hideEdit3 = true;
        this.hideEdit4 = true;
        this.addCustomer1 = true;
        this.addCustomer2 = true;
        this.addCustomer3 = true;
        this.addCustomer4 = true;
        this.showFirstName1 = false;
        this.showFirstName2 = false;
        this.showFirstName3 = false;
        this.showFirstName4 = false;
        this.selectedCustomers1 = [];
        this.selectedCustomers2 = [];
        this.selectedCustomers3 = [];
        this.selectedCustomers4 = [];
        this.customerControl1 = new FormControl();
        this.customerControl2 = new FormControl();
        this.customerControl3 = new FormControl();
        this.customerControl4 = new FormControl();
    }
    AirportCompanyCustomerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get('id');
        this.preRegisterId = this.route.snapshot.paramMap.get('preRegisterId');
        this.formGroup = this.formBuilder.group({
            firstName1: [null, []],
            lastName1: [null, []],
            cpf1: [null, []],
            percentage1: [null, []],
            firstName2: [null, []],
            lastName2: [null, []],
            cpf2: [null, []],
            percentage2: [null, []],
            firstName3: [null, []],
            lastName3: [null, []],
            cpf3: [null, []],
            percentage3: [null, []],
            firstName4: [null, []],
            lastName4: [null, []],
            cpf4: [null, []],
            percentage4: [null, []],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers1 = this.customerControl1.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        this.filteredCustomers2 = this.customerControl2.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        this.filteredCustomers3 = this.customerControl3.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        this.filteredCustomers4 = this.customerControl4.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        if (this.id) {
            this.list().catch(function (err) { console.log(err); });
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    AirportCompanyCustomerFormComponent.prototype._filter = function (customer) {
        var _this = this;
        if (!this.selectedCustomers1.length) {
            var remove_1 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_1 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers1.includes(c); })
                .filter(function (c) {
                return (String(c.name) + " " + String(c.cpf)).toLowerCase().includes(remove_1.toLowerCase());
            })
                .slice(0, 20);
        }
        if (!this.selectedCustomers2.length) {
            var remove_2 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_2 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers2.includes(c); })
                .filter(function (c) {
                return (String(c.name) + " " + String(c.cpf) + " ").toLowerCase().includes(remove_2.toLowerCase());
            })
                .slice(0, 20);
        }
        if (!this.selectedCustomers3.length) {
            var remove_3 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_3 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers3.includes(c); })
                .filter(function (c) {
                return (String(c.name) + " " + String(c.cpf) + " ").toLowerCase().includes(remove_3.toLowerCase());
            })
                .slice(0, 20);
        }
        if (!this.selectedCustomers4.length) {
            var remove_4 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_4 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers4.includes(c); })
                .filter(function (c) {
                return (String(c.name) + " " + String(c.cpf) + " ").toLowerCase().includes(remove_4.toLowerCase());
            })
                .slice(0, 20);
        }
    };
    AirportCompanyCustomerFormComponent.prototype.removeCustomer = function (customer, input) {
        if (input === 1) {
            var index = this.selectedCustomers1.indexOf(customer);
            if (index >= 0) {
                this.selectedCustomers1.splice(index, 1);
            }
            this.customerId1 = undefined;
            this.hideCustomer1 = true;
        }
        if (input === 2) {
            var index = this.selectedCustomers2.indexOf(customer);
            if (index >= 0) {
                this.selectedCustomers2.splice(index, 1);
            }
            this.customerId2 = undefined;
            this.hideCustomer2 = true;
        }
        if (input === 3) {
            var index = this.selectedCustomers3.indexOf(customer);
            if (index >= 0) {
                this.selectedCustomers3.splice(index, 1);
            }
            this.customerId3 = undefined;
            this.hideCustomer3 = true;
        }
        if (input === 4) {
            var index = this.selectedCustomers4.indexOf(customer);
            if (index >= 0) {
                this.selectedCustomers4.splice(index, 1);
            }
            this.customerId4 = undefined;
            this.hideCustomer4 = true;
        }
    };
    AirportCompanyCustomerFormComponent.prototype.selected = function (event, input) {
        if (!this.selectedCustomers1.length && input === 1) {
            this.selectedCustomers1.push(event.option.value);
            this.customerInput1.nativeElement.value = '';
            this.customerControl1.setValue(null);
            this.customerId1 = this.selectedCustomers1[0].customerId;
            this.hideCustomer1 = false;
        }
        if (!this.selectedCustomers2.length && input === 2) {
            this.selectedCustomers2.push(event.option.value);
            this.customerInput2.nativeElement.value = '';
            this.customerControl2.setValue(null);
            this.customerId2 = this.selectedCustomers2[0].customerId;
            this.hideCustomer2 = false;
        }
        if (!this.selectedCustomers3.length && input === 3) {
            this.selectedCustomers3.push(event.option.value);
            this.customerInput3.nativeElement.value = '';
            this.customerControl3.setValue(null);
            this.customerId3 = this.selectedCustomers3[0].customerId;
            this.hideCustomer3 = false;
        }
        if (!this.selectedCustomers4.length && input === 4) {
            this.selectedCustomers4.push(event.option.value);
            this.customerInput4.nativeElement.value = '';
            this.customerControl4.setValue(null);
            this.customerId4 = this.selectedCustomers4[0].customerId;
            this.hideCustomer4 = false;
        }
    };
    AirportCompanyCustomerFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneCompanyAirport(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyCustomerFormComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total, fullName, fullName, fullName, fullName;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .listCompanyAirportCustomers(this.id)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.totalCustomers = total;
                        if (results[0]) {
                            this.addCustomer1 = false;
                            this.hideEdit1 = false;
                            this.formGroup.get('firstName1').disable();
                            this.formGroup.get('lastName1').disable();
                            this.formGroup.get('cpf1').disable();
                            this.formGroup.get('percentage1').disable();
                            if (results[0].isPreRegistration) {
                                this.showFirstName1 = true;
                                this.formGroup.patchValue({
                                    firstName1: results[0].customerFirstName,
                                    lastName1: results[0].customerLastName,
                                    cpf1: results[0].customerCpf,
                                });
                            }
                            else {
                                this.hideCustomer1 = false;
                                fullName = results[0].customerFirstName + " " + results[0].customerLastName;
                                this.selectedCustomers1.push({
                                    cpf: results[0].customerCpf,
                                    customerId: results[0].customerId,
                                    name: fullName,
                                });
                            }
                            this.formGroup.patchValue({
                                percentage1: results[0].percentage * 100,
                            });
                            this.percentage1 = results[0].percentage * 100;
                        }
                        if (results[1]) {
                            this.addCustomer2 = false;
                            this.hideEdit2 = false;
                            this.formGroup.get('firstName2').disable();
                            this.formGroup.get('lastName2').disable();
                            this.formGroup.get('cpf2').disable();
                            this.formGroup.get('percentage2').disable();
                            if (results[1].isPreRegistration) {
                                this.showFirstName2 = true;
                                this.formGroup.patchValue({
                                    firstName2: results[1].customerFirstName,
                                    lastName2: results[1].customerLastName,
                                    cpf2: results[1].customerCpf,
                                });
                            }
                            else {
                                this.hideCustomer2 = false;
                                fullName = results[1].customerFirstName + " " + results[1].customerLastName;
                                this.selectedCustomers2.push({
                                    cpf: results[1].customerCpf,
                                    customerId: results[1].customerId,
                                    name: fullName,
                                });
                            }
                            this.formGroup.patchValue({
                                percentage2: results[1].percentage * 100,
                            });
                            this.percentage2 = results[1].percentage * 100;
                        }
                        if (results[2]) {
                            this.addCustomer3 = false;
                            this.hideEdit3 = false;
                            this.formGroup.get('firstName3').disable();
                            this.formGroup.get('lastName3').disable();
                            this.formGroup.get('cpf3').disable();
                            this.formGroup.get('percentage3').disable();
                            if (results[2].isPreRegistration) {
                                this.showFirstName3 = true;
                                this.formGroup.patchValue({
                                    firstName3: results[2].customerFirstName,
                                    lastName3: results[2].customerLastName,
                                    cpf3: results[2].customerCpf,
                                });
                            }
                            else {
                                this.hideCustomer3 = false;
                                fullName = results[2].customerFirstName + " " + results[2].customerLastName;
                                this.selectedCustomers3.push({
                                    cpf: results[2].customerCpf,
                                    customerId: results[2].customerId,
                                    name: fullName,
                                });
                            }
                            this.formGroup.patchValue({
                                percentage3: results[2].percentage * 100,
                            });
                            this.percentage3 = results[2].percentage * 100;
                        }
                        if (results[3]) {
                            this.addCustomer4 = false;
                            this.hideEdit4 = false;
                            this.formGroup.get('firstName4').disable();
                            this.formGroup.get('lastName4').disable();
                            this.formGroup.get('cpf4').disable();
                            this.formGroup.get('percentage4').disable();
                            if (results[3].isPreRegistration) {
                                this.showFirstName4 = true;
                                this.formGroup.patchValue({
                                    firstName4: results[3].customerFirstName,
                                    lastName4: results[3].customerLastName,
                                    cpf4: results[3].customerCpf,
                                });
                            }
                            else {
                                this.hideCustomer4 = false;
                                fullName = results[3].customerFirstName + " " + results[3].customerLastName;
                                this.selectedCustomers1.push({
                                    cpf: results[3].customerCpf,
                                    customerId: results[3].customerId,
                                    name: fullName,
                                });
                            }
                            this.formGroup.patchValue({
                                percentage4: results[3].percentage * 100,
                            });
                            this.percentage4 = results[3].percentage * 100;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyCustomerFormComponent.prototype.valueInputCpf = function (e, input) {
        if (input === 1 && this.selectedCustomers1.length === 0) {
            this.firstName1 = e.target.value;
        }
        if (input === 2 && this.selectedCustomers2.length === 0) {
            this.firstName2 = e.target.value;
        }
        if (input === 3 && this.selectedCustomers3.length === 0) {
            this.firstName3 = e.target.value;
        }
        if (input === 4 && this.selectedCustomers4.length === 0) {
            this.firstName4 = e.target.value;
        }
    };
    AirportCompanyCustomerFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var p1, p2, p3, p4, percentages, body;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                p1 = this.percentage1 ? this.percentage1 : this.formGroup.value.percentage1;
                p2 = this.percentage2 ? this.percentage2 : this.formGroup.value.percentage2;
                p3 = this.percentage3 ? this.percentage3 : this.formGroup.value.percentage3;
                p4 = this.percentage4 ? this.percentage4 : this.formGroup.value.percentage4;
                percentages = Number(p1) +
                    Number(p2) +
                    Number(p3) +
                    Number(p4);
                if (percentages > 100) {
                    this.snackBar.open('Total de porcetagem passou de 100%');
                    return [2 /*return*/, false];
                }
                if (percentages < 100) {
                    this.snackBar.open('Total de porcetagem não pode ser menos de 100%');
                    return [2 /*return*/, false];
                }
                body = [];
                if (this.customerId1 && this.addCustomer1) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        customerId: this.customerId1,
                        percentage: this.formGroup.value.percentage1 / 100,
                    });
                }
                else if (this.firstName1 && this.addCustomer1) {
                    body.push({
                        companyAirportId: this.id,
                        firstName: this.firstName1,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        lastName: this.formGroup.value.lastName1,
                        cpf: this.formGroup.value.cpf1,
                        percentage: this.formGroup.value.percentage1 / 100,
                    });
                }
                if (this.customerId2 && this.addCustomer2) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        customerId: this.customerId2,
                        percentage: this.formGroup.value.percentage2 / 100,
                    });
                }
                else if (this.firstName2 && this.addCustomer2) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        firstName: this.firstName2,
                        lastName: this.formGroup.value.lastName2,
                        cpf: this.formGroup.value.cpf2,
                        percentage: this.formGroup.value.percentage2 / 100,
                    });
                }
                if (this.customerId3) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        customerId: this.customerId3,
                        percentage: this.formGroup.value.percentage3 / 100,
                    });
                }
                else if (this.firstName3 && this.addCustomer3) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        firstName: this.firstName3,
                        lastName: this.formGroup.value.lastName3,
                        cpf: this.formGroup.value.cpf3,
                        percentage: this.formGroup.value.percentage3 / 100,
                    });
                }
                if (this.customerId4) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        customerId: this.customerId4,
                        percentage: this.formGroup.value.percentage4 / 100,
                    });
                }
                else if (this.firstName4 && this.addCustomer4) {
                    body.push({
                        companyAirportId: this.id,
                        preRegisterCompanyAirportId: this.preRegisterId,
                        firstName: this.firstName4,
                        lastName: this.formGroup.value.lastName4,
                        cpf: this.formGroup.value.cpf4,
                        percentage: this.formGroup.value.percentage4 / 100,
                    });
                }
                this.airportsService.createAirportCompanyCustomer(body).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.isCreated) return [3 /*break*/, 5];
                                if (!this.id) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.router.navigate(["./dashboard/airport-company/" + this.id + "/customers"])];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                if (!this.preRegisterId) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.router.navigate(['./dashboard/airport-companies'])];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                this.snackBar.open('Cliente cadastrado na Empresa Aeroporto com sucesso.');
                                _a.label = 5;
                            case 5: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return AirportCompanyCustomerFormComponent;
}());
export { AirportCompanyCustomerFormComponent };
