/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cropper-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@alyle/ui";
import * as i3 from "@alyle/ui/dialog";
import * as i4 from "../../../../node_modules/@alyle/ui/button/alyle-ui-button.ngfactory";
import * as i5 from "@alyle/ui/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@alyle/ui/icon";
import * as i8 from "../../../../node_modules/@alyle/ui/image-cropper/alyle-ui-image-cropper.ngfactory";
import * as i9 from "@alyle/ui/image-cropper";
import * as i10 from "@angular/common";
import * as i11 from "./cropper-dialog.component";
import * as i12 from "ngx-image-compress";
import * as i13 from "@angular/material/snack-bar";
var styles_CropperDialogComponent = [i0.styles];
var RenderType_CropperDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropperDialogComponent, data: {} });
export { RenderType_CropperDialogComponent as RenderType_CropperDialogComponent };
export function View_CropperDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cropper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 50, "div", [["ly-dialog-content", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(3, 16384, null, 0, i2.LyStyle, [i2.StyleRenderer], { px: [0, "px"] }, null), i1.ɵdid(4, 81920, null, 0, i3.ɵc, [i1.Renderer2, i1.ElementRef, i2.LyTheme2], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 29, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["appearance", "icon"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropper.zoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(8, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ly-icon", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(11, 737280, null, 0, i7.LyIcon, [i7.LyIconService, i1.ElementRef, i1.Renderer2, i2.LyTheme2, i2.StyleRenderer, i6.Platform], null, null), (_l()(), i1.ɵted(-1, null, ["zoom_in"])), (_l()(), i1.ɵeld(13, 0, null, null, 6, "button", [["appearance", "icon"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropper.zoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(15, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 3, "ly-icon", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(18, 737280, null, 0, i7.LyIcon, [i7.LyIconService, i1.ElementRef, i1.Renderer2, i2.LyTheme2, i2.StyleRenderer, i6.Platform], null, null), (_l()(), i1.ɵted(-1, null, ["zoom_out"])), (_l()(), i1.ɵeld(20, 0, null, null, 6, "button", [["appearance", "icon"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropper.center() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(22, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], { appearance: [0, "appearance"] }, null), (_l()(), i1.ɵeld(23, 0, null, 0, 3, "ly-icon", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(25, 737280, null, 0, i7.LyIcon, [i7.LyIconService, i1.ElementRef, i1.Renderer2, i2.LyTheme2, i2.StyleRenderer, i6.Platform], null, null), (_l()(), i1.ɵted(-1, null, ["filter_center_focus"])), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropper.fit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(29, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], null, null), (_l()(), i1.ɵted(-1, 0, ["Fit"])), (_l()(), i1.ɵeld(31, 0, null, null, 3, "button", [["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropper.fitToScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(33, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], null, null), (_l()(), i1.ɵted(-1, 0, ["Fit to screen"])), (_l()(), i1.ɵeld(35, 0, null, null, 4, "ly-img-cropper", [], null, [[null, "scaleChange"], [null, "ready"], [null, "cleaned"], [null, "minScale"], [null, "cropped"], [null, "loaded"], [null, "error"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 37)._resize$() !== false);
        ad = (pd_0 && ad);
    } if (("scaleChange" === en)) {
        var pd_1 = ((_co.scale = $event) !== false);
        ad = (pd_1 && ad);
    } if (("ready" === en)) {
        var pd_2 = ((_co.ready = true) !== false);
        ad = (pd_2 && ad);
    } if (("cleaned" === en)) {
        var pd_3 = ((_co.ready = false) !== false);
        ad = (pd_3 && ad);
    } if (("minScale" === en)) {
        var pd_4 = ((_co.minScale = $event) !== false);
        ad = (pd_4 && ad);
    } if (("cropped" === en)) {
        var pd_5 = (_co.onCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("loaded" === en)) {
        var pd_6 = (_co.onLoaded($event) !== false);
        ad = (pd_6 && ad);
    } if (("error" === en)) {
        var pd_7 = (_co.onError($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i8.View_LyImageCropper_0, i8.RenderType_LyImageCropper)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(37, 245760, [[1, 4]], 0, i9.LyImageCropper, [i2.StyleRenderer, i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone, i10.DOCUMENT], { config: [0, "config"], scale: [1, "scale"] }, { scaleChange: "scaleChange", minScaleChange: "minScale", loaded: "loaded", ready: "ready", cropped: "cropped", cleaned: "cleaned", error: "error" }), (_l()(), i1.ɵeld(38, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag and drop image"])), (_l()(), i1.ɵeld(40, 0, null, null, 11, "div", [["container", ""], ["ly-dialog-actions", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(42, 16384, null, 0, i2.LyStyle, [i2.StyleRenderer], { justifyContent: [0, "justifyContent"] }, null), i1.ɵdid(43, 81920, null, 0, i3.ɵd, [i1.Renderer2, i1.ElementRef, i2.LyTheme2], null, null), (_l()(), i1.ɵeld(44, 0, null, null, 3, "button", [["color", "primary"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(46, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["CANCELAR"])), (_l()(), i1.ɵeld(48, 0, null, null, 3, "button", [["color", "primary"], ["ly-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LyButton_0, i4.RenderType_LyButton)), i1.ɵprd(512, null, i2.StyleRenderer, i2.StyleRenderer, [i2.LyTheme2, [2, i1.ElementRef], [2, i1.Renderer2]]), i1.ɵdid(50, 4964352, null, 0, i5.LyButton, [i1.ElementRef, i1.Renderer2, i2.LyTheme2, i1.NgZone, i2.LyRippleService, i2.LyFocusState, i2.StyleRenderer, i6.Platform, [2, i5.LY_BUTTON_DEFAULT_OPTIONS]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["PRONTO"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = 1; _ck(_v, 3, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = "icon"; _ck(_v, 8, 0, currVal_1); _ck(_v, 11, 0); var currVal_2 = "icon"; _ck(_v, 15, 0, currVal_2); _ck(_v, 18, 0); var currVal_3 = "icon"; _ck(_v, 22, 0, currVal_3); _ck(_v, 25, 0); _ck(_v, 29, 0); _ck(_v, 33, 0); var currVal_4 = _co.myConfig; var currVal_5 = _co.scale; _ck(_v, 37, 0, currVal_4, currVal_5); var currVal_6 = "flex-end"; _ck(_v, 42, 0, currVal_6); _ck(_v, 43, 0); var currVal_7 = "primary"; _ck(_v, 46, 0, currVal_7); var currVal_8 = "primary"; _ck(_v, 50, 0, currVal_8); }, null); }
export function View_CropperDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cropper-dialog", [], null, null, null, View_CropperDialogComponent_0, RenderType_CropperDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i11.CropperDialogComponent, [i3.LY_DIALOG_DATA, i2.StyleRenderer, i3.LyDialogRef, i12.NgxImageCompressService, i13.MatSnackBar], null, null)], null, null); }
var CropperDialogComponentNgFactory = i1.ɵccf("app-cropper-dialog", i11.CropperDialogComponent, View_CropperDialogComponent_Host_0, {}, {}, []);
export { CropperDialogComponentNgFactory as CropperDialogComponentNgFactory };
