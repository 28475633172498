import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import numeral from 'numeral'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { CompaniesService } from '../../../../../services/companies.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'
import { StoreService } from 'src/app/services/store.service'
import { CampaignService } from 'src/app/services/campaign.service'
import { BusinessUnitsService } from 'src/app/services/business-units.services'
@Component({
  selector: 'app-architect-stores-form',
  templateUrl: './architect-stores-form.component.html',
  styleUrls: ['./architect-stores-form.component.scss'],
})
export class ArchitectStoresFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  store: any
  cnpjSelected: any
  storeBusiness: any
  campaignStoreId: any
  businessUnitsList: any
  public campaigns: any[] = []
  public states: Object[] = []

  public cnpj: string

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zipCodeService: ZipCodeService,
    public storeService: StoreService,
    public campaignService: CampaignService,
    public businessUnitsService: BusinessUnitsService,
  ) {
    this.states = [
      {
        abbr: 'AC',
        name: 'Acre',
      },
      {
        abbr: 'AL',
        name: 'Alagoas',
      },
      {
        name: 'Amapá',
        abbr: 'AP',
      },
      {
        name: 'Amazonas',
        abbr: 'AM',
      },
      {
        name: 'Bahia',
        abbr: 'BA',
      },
      {
        name: 'Ceará',
        abbr: 'CE',
      },
      {
        name: 'Distrito Federal',
        abbr: 'DF',
      },
      {
        name: 'Espírito Santo',
        abbr: 'ES',
      },
      {
        name: 'Goiás',
        abbr: 'GO',
      },
      {
        name: 'Maranhão',
        abbr: 'MA',
      },
      {
        name: 'Mato Grosso',
        abbr: 'MT',
      },
      {
        name: 'Mato Grosso do Sul',
        abbr: 'MS',
      },
      {
        name: 'Minas Gerais',
        abbr: 'MG',
      },
      {
        name: 'Pará',
        abbr: 'PA',
      },
      {
        name: 'Paraíba',
        abbr: 'PB',
      },
      {
        name: 'Paraná',
        abbr: 'PR',
      },
      {
        name: 'Pernambuco',
        abbr: 'PE',
      },
      {
        name: 'Piauí',
        abbr: 'PI',
      },
      {
        name: 'Rio de Janeiro',
        abbr: 'RJ',
      },
      {
        name: 'Rio Grande do Norte',
        abbr: 'RN',
      },
      {
        name: 'Rio Grande do Sul',
        abbr: 'RS',
      },
      {
        name: 'Rondônia',
        abbr: 'RO',
      },
      {
        name: 'Roraima',
        abbr: 'RR',
      },
      {
        name: 'Santa Catarina',
        abbr: 'SC',
      },
      {
        name: 'São Paulo',
        abbr: 'SP',
      },
      {
        name: 'Sergipe',
        abbr: 'SE',
      },
      {
        name: 'Tocantins',
        abbr: 'T',
      },
    ]
  }

  ngOnInit () {
    this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj')
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      cnpj: [
        this.cnpjSelected,
        [Validators.required, Validators.minLength(14), Validators.maxLength(14)],
      ],
      nomeFantasia: [null, [Validators.required]],
      razaoSocial: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      openingDate: [null, [Validators.required]],
      emailAddress: [null, [Validators.required]],
      zipCode: [null, [Validators.required]],
      street: [null, [Validators.required]],
      addressNumber: [null, [Validators.required]],
      complement: [null, []],
      neighborhood: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      // pointsConversionFactorOut: [null, []],
      // pointsConversionFactorIn: [null, []],
      rateCashback: [null, [Validators.required]],
      rateAdministrative: [null, [Validators.required]],
      // rateRewardsTransaction: [null, []],
      // pointsExpiration: [null, []],
      campaigns: [null, [Validators.required]],
    })

    this.loadCampaigns().catch(err => console.log(err))
    this.businessUnits().catch(err => console.log(err))

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
    if (this.cnpjSelected) {
      this.findOneStore().catch(err => console.log(err))
    }
  }

  async loadCampaigns () {
    await this.campaignService
      .listCampaigns()
      .subscribe(
        response => {
          this.campaigns = response.results
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  async businessUnits () {
    await this.businessUnitsService
      .list()
      .then(async response => {
        this.businessUnitsList = response.businessUnits
        console.log(this.businessUnitsList)
      })
  }

  async findOneStore () {
    const storeFind =
      await this.storeService.getStoreByCnpj(this.cnpjSelected).toPromise().catch(err => {
        this.snackBar.open(err.message)
      })

    const storeBusinessUnits = await this.companiesService
      .listStoresBusinessUnits(`?cnpj=${this.cnpjSelected}`)
      .catch(err => {
        this.snackBar.open(err.message)
      })

    this.storeBusiness =
      storeBusinessUnits.stores.length > 0 ? storeBusinessUnits.stores[0] : undefined

    const campaignsStoreBusines =
      this.storeBusiness ?
        await this.companiesService.findOneCampaignStoreBusinessUnit(this.storeBusiness.id) :
        undefined

    const campaignStoreBusines =
      campaignsStoreBusines && campaignsStoreBusines.campaignStore.length > 0 ?
        campaignsStoreBusines.campaignStore[0].campaign.id :
        undefined

    if (campaignStoreBusines) {
      this.campaignStoreId = campaignsStoreBusines.campaignStore[0].campaignStoreId
    }

    if (this.storeBusiness || storeFind) {
      this.formGroup.patchValue({
        cnpj: storeFind.cnpj || this.cnpjSelected,
        nomeFantasia:
          this.storeBusiness ? this.storeBusiness.nomeFantasia : storeFind.nomeFantasia,
        razaoSocial: this.storeBusiness ? this.storeBusiness.razaoSocial : storeFind.razaoSocial,
        phoneNumber: this.storeBusiness ? this.storeBusiness.phoneNumber : storeFind.phoneNumber,
        openingDate:
          this.storeBusiness ?
            this.storeBusiness.openingDate.substring(0, 10) :
            storeFind.openingDate.substring(0, 10),
        emailAddress:
          this.storeBusiness ? this.storeBusiness.emailAddress : storeFind.emailAddress,
        zipCode: this.storeBusiness ? this.storeBusiness.zipCode : storeFind.zipCode,
        street: this.storeBusiness ? this.storeBusiness.street : storeFind.street,
        addressNumber: this.storeBusiness ? this.storeBusiness.number : storeFind.number,
        complement: this.storeBusiness ? this.storeBusiness.complement : storeFind.complement,
        neighborhood:
          this.storeBusiness ? this.storeBusiness.neighborhood : storeFind.neighborhood,
        city: this.storeBusiness ? this.storeBusiness.city : storeFind.city,
        state: this.storeBusiness ? this.storeBusiness.state : storeFind.state,
        // pointsConversionFactorOut: response.pointsConversionFactorOut,
        // pointsConversionFactorIn: response.pointsConversionFactorIn,
        rateCashback:
          this.storeBusiness ?
            numeral(this.storeBusiness.rateCashback).multiply(100).value() :
            '',
        rateAdministrative:
          this.storeBusiness ?
            numeral(this.storeBusiness.rateAdministrative).multiply(100).value() :
            '',
        // rateRewardsTransaction: response.taxRewardsTransaction,
        // pointsExpiration: response.pointsExpiration,
        campaigns: campaignStoreBusines,
      })
    }
  }

  async findOne () {
    await this.companiesService.findOneStoreBusinessUnit(this.id)
      .then(async response => {
        this.store = response.length > 0 ? response[0] : undefined

        const campaignsStoreBusines =
          await this.companiesService.findOneCampaignStoreBusinessUnit(this.id)

        const campaignStoreBusines =
          campaignsStoreBusines.campaignStore.length > 0 ?
            campaignsStoreBusines.campaignStore[0].campaign.id :
            undefined

        if (campaignStoreBusines) {
          this.campaignStoreId = campaignsStoreBusines.campaignStore[0].campaignStoreId
        }

        if (this.store.cnpj) {
          this.formGroup.get('cnpj').disable()
        }

        this.cnpj = this.store.cnpj

        this.formGroup.patchValue({
          cnpj: this.store.cnpj,
          nomeFantasia: this.store.nomeFantasia,
          razaoSocial: this.store.razaoSocial,
          phoneNumber: this.store.phoneNumber,
          openingDate: this.store.openingDate.substring(0, 10),
          emailAddress: this.store.emailAddress,
          zipCode: this.store.zipCode,
          street: this.store.street,
          addressNumber: this.store.number,
          complement: this.store.complement,
          neighborhood: this.store.neighborhood,
          city: this.store.city,
          state: this.store.state,
          rateCashback: numeral(this.store.rateCashback).multiply(100).value(),
          rateAdministrative: numeral(this.store.rateAdministrative).multiply(100).value(),
          campaigns: campaignStoreBusines,
        })
      })
  }

  async loadAddress () {
    const zipCode = this.formGroup.controls.zipCode.value

    if (zipCode.length === 8) {
      this.zipCodeService.getAddress(zipCode).subscribe((address: any) => {
        this.formGroup.patchValue({
          state: address.uf,
          city: address.localidade,
          neighborhood: address.bairro,
          street: address.logradouro,
        })
      })
    }
  }

  convertPercentToNumber (value) {
    if (value) {
      return numeral(Number(value)).divide(100).value()
    } else {
      return (value = 0)
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      cnpj: this.formGroup.value.cnpj || this.cnpj,
      nomeFantasia: this.formGroup.value.nomeFantasia,
      razaoSocial: this.formGroup.value.razaoSocial,
      phoneNumber: this.formGroup.value.phoneNumber,
      openingDate: this.formGroup.value.openingDate,
      emailAddress: this.formGroup.value.emailAddress,
      zipCode: this.formGroup.value.zipCode,
      street: this.formGroup.value.street,
      number: this.formGroup.value.addressNumber,
      complement: this.formGroup.value.complement || '',
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      // pointsConversionFactorOut: this.formGroup.value.pointsConversionFactorOut,
      // pointsConversionFactorIn: this.formGroup.value.pointsConversionFactorIn,
      rateCashback: this.convertPercentToNumber(this.formGroup.value.rateCashback),
      rateAdministrative: this.convertPercentToNumber(this.formGroup.value.rateAdministrative),
      // rateRewardsTransaction:
      //   this.convertPercentToNumber(this.formGroup.value.rateRewardsTransaction),
      // pointsExpiration: this.formGroup.value.pointsExpiration,
    }

    const businessUnitsFilter = this.businessUnitsList.filter(bu => bu.name === 'architect')

    if (this.id || this.storeBusiness) {
      if (this.storeBusiness && !this.id) this.id = this.storeBusiness.id
      this.companiesService.updateStoresBusinessUnits(this.id, data).then(
        async response => {
          if (response.id) {
            const dataCampaign = {
              storeId: response.id,
              businessUnitId: businessUnitsFilter ? businessUnitsFilter[0].id : '',
              campaignId: this.formGroup.value.campaigns,
            }
            await this.companiesService.updateCampaignStoresBusinessUnits(
              this.campaignStoreId, dataCampaign,
            ).then(
              async response => {
                console.log(response)
                await this.router.navigate(['./dashboard/architect-stores'])
                this.snackBar.open('Parceiro atualizado com sucesso.')
              },
            )
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.companiesService.createStoresBusinessUnits(data).then(
        async response => {
          if (response.id) {
            const dataCampaign = {
              storeId: response.id,
              businessUnitId: businessUnitsFilter ? businessUnitsFilter[0].id : '',
              campaignId: this.formGroup.value.campaigns,
            }
            await this.companiesService.createCampaignStoresBusinessUnits(dataCampaign).then(
              async response => {
                console.log(response)
                await this.router.navigate(['./dashboard/architect-stores'])
                this.snackBar.open('Parceiro cadastrado com sucesso.')
              },
            )
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
