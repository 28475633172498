import { OnInit } from '@angular/core';
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent(loaderService) {
        var _this = this;
        this.loaderService = loaderService;
        this.loaderService.isLoading.subscribe(function (v) {
            _this.loading = v;
        });
    }
    LoaderComponent.prototype.ngOnInit = function () {
    };
    return LoaderComponent;
}());
export { LoaderComponent };
