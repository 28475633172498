import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
var RegulationFormComponent = /** @class */ (function () {
    function RegulationFormComponent(errorsService, formBuilder, regulationService, authService, snackBar, router, route) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.regulationService = regulationService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.states = [];
        this.plans = [];
        this.configs = [];
        this.Editor = ClassicEditor;
        this.regulationsInfo = {
            heading: '',
            subtitle: '',
            content: '',
        };
        this.initForm();
        this.config = {
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    'undo',
                    'redo'
                ]
            },
            image: {
                toolbar: [
                    'imageStyle:full',
                    'imageStyle:side',
                    '|',
                    'imageTextAlternative'
                ]
            },
        };
    }
    RegulationFormComponent.prototype.ngOnInit = function () {
    };
    RegulationFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            heading: [null, [Validators.required]],
            subtitle: [null, [Validators.required]],
            content: [null, [Validators.required]],
        });
        if (this.route.snapshot.paramMap.get('regulationID')) {
            this.regulationID = this.route.snapshot.paramMap.get('regulationID');
            if (this.regulationID != '') {
                this.loadInfos();
            }
            else {
                localStorage.removeItem('regulationsInfos');
            }
        }
    };
    ;
    RegulationFormComponent.prototype.onReady = function (editor) {
        console.log(editor);
    };
    RegulationFormComponent.prototype.loadInfos = function () {
        this.regulationsInfo = JSON.parse(localStorage.getItem('regulationsInfos'));
        this.formGroup.patchValue({
            heading: this.regulationsInfo['heading'],
            subtitle: this.regulationsInfo['subtitle'],
            content: this.regulationsInfo['content'],
        });
        this.textDescription = this.regulationsInfo['content'];
    };
    RegulationFormComponent.prototype.change = function (_a) {
        var editor = _a.editor;
        var EditorData = editor.getData();
        this.formGroup.get('content').setValue(EditorData);
    };
    RegulationFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.regulationsInfo['heading'] == '') {
                    this.new();
                }
                else {
                    this.update();
                }
                return [2 /*return*/];
            });
        });
    };
    RegulationFormComponent.prototype.new = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.regulationService.addRegulation(this.formGroup.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.router.navigate(['./dashboard/regulations']);
                            this.snackBar.open('Regulamento criado com sucesso.');
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    RegulationFormComponent.prototype.update = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        this.regulationService.updateRegulation(this.formGroup.value, this.regulationsInfo['id']).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (response.isUpdated) {
                    this.router.navigate(['./dashboard/regulations']);
                    this.snackBar.open('Regulamento atualizado com sucesso.');
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return RegulationFormComponent;
}());
export { RegulationFormComponent };
