<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">Cadastro de Empresa Aeroporto</h2>
    <h2 *ngIf="id">Editar Empresa Aeroporto</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/airport-companies">
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="company" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ company.name }}</div>
      <div><b>CNPJ: </b>{{ company.cnpj | mask: '00.000.000/0000-00' }}</div>
    </mat-card>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
