import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(errorsService, snackBar, formBuilder, router, authService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.router = router;
        this.authService = authService;
        this.hide = true;
        this.initForm();
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () { };
    ForgotPasswordComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            emailAddress: [null, [Validators.required, Validators.email]],
        });
    };
    ForgotPasswordComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente o campo e tente novamente.');
            return false;
        }
        this.authService.passwordRecover(this.formGroup.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (response.isRecovered) {
                    this.snackBar.open('E-mail de recuperação enviado com sucesso.');
                    this.router.navigate(['/login']);
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
