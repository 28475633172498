<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Cadastrar Prefixo da Aeronave Elegívies</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/airports/not-eligible">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <mat-form-field>
              <mat-label>Prefixo da Aeronave</mat-label>
              <input matInput formControlName="prefix"/>
              <mat-error *ngIf="formGroup.controls.prefix?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.prefix) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
