import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
var ModalDeleteClosingComponent = /** @class */ (function () {
    function ModalDeleteClosingComponent(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        console.log('data', data);
    }
    ModalDeleteClosingComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalDeleteClosingComponent.prototype.submit = function () {
        this.dialogRef.close(true);
    };
    ModalDeleteClosingComponent.prototype.ngOnInit = function () {
    };
    return ModalDeleteClosingComponent;
}());
export { ModalDeleteClosingComponent };
