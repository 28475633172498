import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import JsFileDownloader from 'js-file-downloader';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var ClientTermsListComponent = /** @class */ (function () {
    function ClientTermsListComponent(authService, snackBar, dialog, route, router, companiesService) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.companiesService = companiesService;
        this.headersTable = ['date', 'documentName', 'document', 'actions'];
        this.terms = [];
    }
    ClientTermsListComponent.prototype.ngOnInit = function () {
        this.document = this.route.snapshot.paramMap.get('document');
        this.type = this.route.snapshot.paramMap.get('type');
        this.listTerms();
    };
    ClientTermsListComponent.prototype.listTerms = function () {
        var _this = this;
        if (this.type === 'company') {
            this.companiesService.listCompaniesTerms(this.document).then(function (response) {
                _this.terms = response.results;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            this.authService.listCustomerTerms(this.document).then(function (response) {
                _this.terms = response.results;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
    };
    ClientTermsListComponent.prototype.goBack = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.type === 'pre-register-incorporation-airport')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration/INCORPORATION_AIRPORT'])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 2:
                        if (!(this.type === 'pre-register')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/pre-registration/'])];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 4:
                        if (!(this.type === 'client')) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/clients'])];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 6:
                        if (!(this.type === 'company')) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.router.navigate(['./dashboard/architect-companies'])];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ClientTermsListComponent.prototype.download = function (event, documentUrl, documentName) {
        event.preventDefault();
        // eslint-disable-next-line no-new
        new JsFileDownloader({
            url: documentUrl,
            nameCallback: function () {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                return documentName;
            },
        });
    };
    ClientTermsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Termo?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    if (this.type === 'company') {
                        return [2 /*return*/, this.companiesService.deleteCompanyTerm(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isDeleted) {
                                        this.listTerms();
                                        this.snackBar.open('Termo removido com sucesso.');
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    }
                }
                else {
                    return [2 /*return*/, this.authService.deleteCustomerTerm(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (response.isDeleted) {
                                    this.listTerms();
                                    this.snackBar.open('Termo removido com sucesso.');
                                }
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return ClientTermsListComponent;
}());
export { ClientTermsListComponent };
