import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var MembershipPlansListCustomerComponent = /** @class */ (function () {
    function MembershipPlansListCustomerComponent(snackBar, membershipService, dialog, route, location, storeService, router) {
        this.snackBar = snackBar;
        this.membershipService = membershipService;
        this.dialog = dialog;
        this.route = route;
        this.location = location;
        this.storeService = storeService;
        this.router = router;
        this.headersTable = ['name', 'beginDate', 'endDate', 'value', 'active', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.params = { formGroupValue: [] };
    }
    MembershipPlansListCustomerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.customerId = this.route.snapshot.paramMap.get('customerID');
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'namePlanMembershipCustomer',
                label: 'Plano Membership',
                placeholder: 'Plano Membership',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Início',
                formControlName: 'beginDatePlanMembershipCustomer',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Final',
                formControlName: 'endDatePlanMembershipCustomer',
            },
        ];
        this.listPlans();
        this.storeService
            .listUsersCustomers("?filters={\"filters\":[{\"fields\":[\"customers.id\"], \"op\": \"=\", \"value\":\"" + this.customerId + "\"}]}")
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var customer;
            return tslib_1.__generator(this, function (_a) {
                customer = response.items.find(function () { return true; });
                this.cpf = customer.cpf;
                this.fullName = customer.firstName + " " + customer.lastName;
                this.email = customer.emailAddress;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipPlansListCustomerComponent.prototype.listPlans = function () {
        var _this = this;
        this.membershipService
            .listPlansByCustomer(this.customerId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipPlansListCustomerComponent.prototype.goBack = function () {
        this.location.back();
    };
    MembershipPlansListCustomerComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    MembershipPlansListCustomerComponent.prototype.month = function (recurrencePeriod) {
        if (recurrencePeriod == 0) {
            return '';
        }
        else if (recurrencePeriod > 1) {
            return recurrencePeriod + " meses";
        }
        else {
            return recurrencePeriod + " m\u00EAs";
        }
    };
    MembershipPlansListCustomerComponent.prototype.infoPlan = function (planId) {
        this.router.navigateByUrl("/dashboard/memberships/plans/" + planId);
    };
    MembershipPlansListCustomerComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja inativar esse Plano Membership?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 2];
                        data = {
                            isActive: false,
                        };
                        return [4 /*yield*/, this.membershipService.editPlan(id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.listPlans();
                                    this.snackBar.open('Plano Membership inativo.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    MembershipPlansListCustomerComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return MembershipPlansListCustomerComponent;
}());
export { MembershipPlansListCustomerComponent };
