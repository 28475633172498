<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content class="content">
    <mat-card>
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <!-- <div mat-dialog-content class="content"> -->
        <div class="row">
          <div class="col-12 col-sm-12">
            <h3 *ngIf="data.text">{{ data.text }}</h3>
            <div *ngIf="data.html" [innerHTML]="getSanitizedHtml(data.html)"></div>
            <mat-form-field>
              <input matInput formControlName="bankNumber" placeholder="Código do banco" type="text" />
              <mat-error *ngIf="formGroup.controls.bankNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankNumber) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="bankAgency" placeholder="Agencia" type="number"

              />
              <mat-error *ngIf="formGroup.controls.bankAgency?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankAgency) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="bankAccountNumber"
                placeholder="Conta"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls.bankAccountNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankAccountNumber) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="bankAccountDigit"
                placeholder="Digito"
                type="text"
              />
              <mat-error *ngIf="formGroup.controls.bankAccountDigit?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankAccountDigit) }}
              </mat-error>
            </mat-form-field>

            <div class="check">
              <input formControlName="propagateChangesToNextTransferSchedules" type="checkbox" />
              <mat-label> &nbsp;Aplicar essas mudanças para as transferencias desse parceiro que já estão agendadas?</mat-label>
            </div>

            <div class="check">
              <input formControlName="propagateChangesToStore" type="checkbox" />
              <mat-label> &nbsp;Aplicar essas mudanças no cadastro conta bancária do parceiro?</mat-label>
            </div>


            <div mat-dialog-actions>
              <div class="actions">
                <button *ngIf="data.buttonNot" mat-button (click)="not()" class="btn-submit-dark">
                  {{ data.buttonNot }}
                </button>
                <button
                  *ngIf="data.buttonConfirmText"
                  mat-button
                  (click)="confirm()"
                  class="btn-submit-dark"
                >
                  {{ data.buttonConfirmText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</main>
