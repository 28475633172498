import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import moment from 'moment'
import 'moment/locale/pt-br'

import numeral from 'numeral'
import 'numeral/locales/pt-br'

moment.locale('pt-br')

numeral.locale('pt-br')

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
