import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'src/app/services/uploadAdapter.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import numeral from 'numeral';
import { ModalVerifyStoreCampaignTypeComponent } from './../../../../components/modal-verify-store-campaign-type/modal-verify-store-campaign-type.component';
import { ModalVerifyInitialCampaignDateComponent } from 'src/app/components/modal-verify-initial-campaign-date/modal-verify-initial-campaign-date.component';
var CampaignsFormComponent = /** @class */ (function () {
    function CampaignsFormComponent(macroGroupsService, storeService, errorsService, formBuilder, campaignService, snackBar, router, route, authService, dialog) {
        this.macroGroupsService = macroGroupsService;
        this.storeService = storeService;
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.campaignService = campaignService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.authService = authService;
        this.dialog = dialog;
        this.partnersSearchText = '';
        this.Editor = ClassicEditor;
        this.params = { formGroupValue: [] };
        this.contentInvalid = false;
        this.initalized = false;
        this.macroGroups = [];
        this.partnersList = [];
        this.macroGroupControl = new FormControl();
        this.separatorKeysCodes = [ENTER, COMMA];
        this.selectedMacroGroups = [];
        this.selectedMacroGroupsPartnersList = [];
        this.parsedStoresIdsWithMacroGroups = [];
        this.parsedStoresIdsWithOutMacroGroups = [];
    }
    CampaignsFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // deploy
        this.isCampaignInfoChanged = false;
        this.campaignID = this.route.snapshot.paramMap.get('campaignID');
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        if (this.campaignID) {
            this.loadInfos();
        }
        else {
            this.loadStores();
            this.loadMacroGroups();
        }
        this.initForm().catch(function (err) { return console.log(err); });
        this.config = {
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
    };
    CampaignsFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup = this.formBuilder.group({
                    description: [null, []],
                    campaignTypes: ['ACCUMULATION', [Validators.required]],
                    isConvertDollar: [false, [Validators.required]],
                    convertionFactor: [null, [Validators.required]],
                    spendingFactor: [false, [Validators.required]],
                    spendingValue: [null, []],
                    fixedPoints: [false, []],
                    fixedPointsAmount: [null, []],
                    confirmationCampaignType: ['PENDING_POINTS', [Validators.required]],
                    confirmationCampaign: ['DENY_PARTIAL', [Validators.required]],
                    expirationDaysAmount: [null, []],
                    amountDayAfterConfirmated: [null, []],
                    isAllowedExpirationPointsDate: [false, [Validators.required]],
                    pointsExpirationDate: [null, []],
                    pointsExpirationTime: [null, []],
                    campaignInitialDate: [null, [Validators.required]],
                    campaignInitialTime: [null, [Validators.required]],
                    campaignEndDate: [null, []],
                    campaignEndTime: [null, []],
                    partnersSearchText: ['', []],
                    isDefault: [false, [Validators.required]],
                });
                return [2 /*return*/];
            });
        });
    };
    CampaignsFormComponent.prototype.loadInfos = function () {
        var _this = this;
        this.campaignService.findOne(this.campaignID).subscribe(function (response) {
            var parsedPoinstExpirationDate = response.pointsExpirationDate ? response.pointsExpirationDate.split('T')[0] : null;
            var parsedPointsExpirationTime = response.pointsExpirationDate ? _this.getTime(response.pointsExpirationDate) : null;
            var parsedCampaignInitialDate = response.campaignInitialDate ? response.campaignInitialDate.split('T')[0] : null;
            var parsedCampaignInitialTime = response.campaignInitialDate ? _this.getTime(response.campaignInitialDate) : null;
            var parsedCampaignEndDate = response.campaignEndDate ? response.campaignEndDate.split('T')[0] : null;
            var parsedCampaignEndTime = response.campaignEndDate ? _this.getTime(response.campaignEndDate) : null;
            _this.formGroup.patchValue({
                description: response.description,
                campaignTypes: response.campaignTypes,
                isConvertDollar: response.isConvertDollar,
                convertionFactor: numeral(response.convertionFactor).multiply(100).value(),
                spendingFactor: !!response.spendingValue,
                spendingValue: response.spendingValue,
                fixedPoints: !!response.fixedPoints,
                fixedPointsAmount: response.fixedPoints,
                confirmationCampaignType: response.confirmationCampaignType,
                confirmationCampaign: response.confirmationCampaign,
                expirationDaysAmount: response.expirationDaysAmount,
                amountDayAfterConfirmated: response.amountDayAfterConfirmated,
                isAllowedExpirationPointsDate: !!response.pointsExpirationDate,
                pointsExpirationDate: parsedPoinstExpirationDate,
                pointsExpirationTime: parsedPointsExpirationTime,
                campaignInitialDate: parsedCampaignInitialDate,
                campaignInitialTime: parsedCampaignInitialTime,
                campaignEndDate: parsedCampaignEndDate,
                campaignEndTime: parsedCampaignEndTime,
                isDefault: response.isDefault,
            });
            _this.changeConvertDollarEnabled({ value: response.isConvertDollar });
            _this.changeSpendingFactorEnabled({ value: !!response.spendingValue });
            _this.changeFixedPointsEnabled({ value: !!response.fixedPoints });
            _this.changeIsAllowedExpirationPointsDateEnabled({ value: !!response.pointsExpirationDate });
            var storesIds = response.storesIds;
            var storesWithMacro = [];
            var macroGroups = [];
            var storesWithoutMacro = [];
            storesIds.map(function (store) {
                if (store.macroGroupId) {
                    if (!macroGroups.includes(store.macroGroupId)) {
                        macroGroups.push(store.macroGroupId);
                    }
                    storesWithMacro.push(store.storesId);
                }
                else {
                    storesWithoutMacro.push(store.storesId);
                }
            });
            _this.loadStores(storesWithoutMacro);
            _this.loadMacroGroups(storesWithMacro, macroGroups);
            _this.isCampaignInfoChanged = false;
        });
    };
    CampaignsFormComponent.prototype.loadMacroGroups = function (storesWithMacro, macroGroups) {
        var _this = this;
        this.macroGroupsService.list().then(function (response) {
            _this.macroGroups = response.results;
            var alreadySelectedMacroGroupsList = [];
            _this.filteredMacroGroups = _this.macroGroups
                .map(function (macro) {
                if (macroGroups && macroGroups.includes(macro.id)) {
                    alreadySelectedMacroGroupsList.push({ id: macro.id, heading: macro.macroName });
                }
                return { id: macro.id, heading: macro.macroName };
            });
            alreadySelectedMacroGroupsList.map(function (macro) {
                return _this.chargeSelectedMacroGroups(macro, storesWithMacro);
            });
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    CampaignsFormComponent.prototype.onChange = function (_a) {
        var editor = _a.editor;
        if (!this.initalized) {
            return;
        }
        this.contentInvalid = !this.formGroup.value.spendingValue;
    };
    CampaignsFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    CampaignsFormComponent.prototype.changeConvertDollarEnabled = function (event) {
        if (event.value === true) {
            this.formGroup.get('convertionFactor').setValidators([Validators.required]);
        }
        else {
            this.formGroup.get('convertionFactor').setValidators(null);
        }
    };
    CampaignsFormComponent.prototype.changeSpendingFactorEnabled = function (event) {
        if (event.value === true) {
            this.formGroup.get('spendingValue').setValidators([Validators.required]);
            this.formGroup.get('spendingValue').updateValueAndValidity();
        }
        else {
            this.formGroup.get('spendingValue').setValidators(null);
            this.formGroup.get('spendingValue').setErrors(null);
        }
    };
    CampaignsFormComponent.prototype.changeFixedPointsEnabled = function (event) {
        if (event.value === true) {
            this.formGroup.get('fixedPointsAmount').setValidators([Validators.required]);
            this.formGroup.get('fixedPointsAmount').updateValueAndValidity();
        }
        else {
            this.formGroup.get('fixedPointsAmount').setValidators(null);
            this.formGroup.get('fixedPointsAmount').setErrors(null);
        }
    };
    CampaignsFormComponent.prototype.changeIsAllowedExpirationPointsDateEnabled = function (event) {
        if (event.value === true) {
            this.formGroup.get('pointsExpirationDate').setValidators([Validators.required]);
            this.formGroup.get('pointsExpirationDate').updateValueAndValidity();
            this.formGroup.get('pointsExpirationTime').setValidators([Validators.required]);
            this.formGroup.get('pointsExpirationTime').updateValueAndValidity();
        }
        else {
            this.formGroup.get('pointsExpirationDate').setValidators(null);
            this.formGroup.get('pointsExpirationDate').setErrors(null);
            this.formGroup.get('pointsExpirationTime').setValidators(null);
            this.formGroup.get('pointsExpirationTime').setErrors(null);
        }
    };
    CampaignsFormComponent.prototype.removeMacroGroup = function (macroGroup, macroGroupIndex) {
        var _this = this;
        var index = this.selectedMacroGroups.indexOf(macroGroup);
        if (index >= 0) {
            this.selectedMacroGroups.splice(index, 1);
            this.filteredMacroGroups.push(macroGroup);
            this.selectedMacroGroupsPartnersList[macroGroupIndex]
                .partners.map(function (partnerMacro) {
                _this.partnersList.map(function (partner) {
                    if (partner.id === partnerMacro.storeId) {
                        partner.disabled = false;
                    }
                });
            });
            this.formGroup.removeControl(macroGroup.id);
            this.selectedMacroGroupsPartnersList.splice(index, 1);
        }
    };
    CampaignsFormComponent.prototype.selected = function (event) {
        this.isCampaignInfoChanged = true;
        if (event.option.value.id) {
            this.selectedMacroGroups.push(event.option.value);
            this.macroGroupsInput.nativeElement.value = '';
            this.macroGroupControl.setValue(null);
        }
        else {
            this.selectedMacroGroups.push(event.option.value);
            this.macroGroupsInput.nativeElement.value = '';
            this.macroGroupControl.setValue(null);
        }
        this.filteredMacroGroups = this.filteredMacroGroups
            .filter(function (item) { return item.id !== event.option.value.id; });
        this.loadMacroGroupPartners(event.option.value.id);
    };
    CampaignsFormComponent.prototype.chargeSelectedMacroGroups = function (macro, selectedStoresIds) {
        if (macro.id) {
            this.selectedMacroGroups.push(macro);
            this.macroGroupsInput.nativeElement.value = '';
            this.macroGroupControl.setValue(null);
        }
        else {
            this.selectedMacroGroups.push(macro);
            this.macroGroupsInput.nativeElement.value = '';
            this.macroGroupControl.setValue(null);
        }
        this.filteredMacroGroups = this.filteredMacroGroups
            .filter(function (item) { return item.id !== macro.id; });
        this.loadMacroGroupPartners(macro.id, selectedStoresIds);
    };
    CampaignsFormComponent.prototype.loadStores = function (storesWithoutMacro) {
        var _this = this;
        this.storeService
            .listStores()
            .toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (storesWithoutMacro) {
                    this.partnersList = response
                        .map(function (partner) {
                        if (storesWithoutMacro.includes(partner.id)) {
                            return {
                                id: partner.id,
                                nomeFantasia: partner.nomeFantasia,
                                checked: true,
                                disabled: false,
                            };
                        }
                        return {
                            id: partner.id,
                            nomeFantasia: partner.nomeFantasia,
                            checked: false,
                            disabled: false,
                        };
                    });
                }
                else {
                    this.partnersList = response
                        .map(function (partner) { return ({
                        id: partner.id,
                        nomeFantasia: partner.nomeFantasia,
                        checked: false,
                        disabled: false,
                    }); });
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    CampaignsFormComponent.prototype.loadMacroGroupPartners = function (id, selectedStoresIds) {
        var _this = this;
        this.macroGroupsService.fetchMacroGroupPartners(id)
            .then(function (response) {
            var parsedPartners = response.results.map(function (partner) {
                if (selectedStoresIds && _this.campaignID) {
                    if (selectedStoresIds.includes(partner.storeId)) {
                        return tslib_1.__assign({}, partner, { checked: true });
                    }
                    return tslib_1.__assign({}, partner, { checked: false });
                }
                return tslib_1.__assign({}, partner, { checked: true });
            });
            _this.selectedMacroGroupsPartnersList.push({ id: id, partners: parsedPartners });
            _this.formGroup.addControl(id, new FormControl('', []));
        })
            .catch(function (err) { return console.log(err); });
    };
    CampaignsFormComponent.prototype.changePartnerCheckbox = function (event, macroGroupIndex) {
        this.isCampaignInfoChanged = true;
        this.selectedMacroGroupsPartnersList[macroGroupIndex].partners
            .map(function (partner) {
            if (partner.storeId === event.target.value) {
                partner.checked = event.target.checked;
            }
        });
    };
    CampaignsFormComponent.prototype.changeFromStoreListCheckbox = function (event) {
        this.isCampaignInfoChanged = true;
        this.partnersList
            .map(function (partner) {
            if (partner.id === event.target.value) {
                partner.checked = event.target.checked;
            }
        });
    };
    CampaignsFormComponent.prototype.getTime = function (datetime) {
        return new Date(datetime).getHours().toString() + ":" + (new Date(datetime).getMinutes() < 10 ? '0' : '') + new Date(datetime).getMinutes().toString();
    };
    CampaignsFormComponent.prototype.getConvertionFactor = function (convertionFactor) {
        if (convertionFactor) {
            return parseFloat("0." + convertionFactor.substring(1));
        }
        return 0.0;
    };
    CampaignsFormComponent.prototype.openDialogStoresVerificationType = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                dialogRef = this.dialog.open(ModalVerifyStoreCampaignTypeComponent, {
                    width: '500px',
                    data: data,
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result !== 'cancel') {
                        // result is returned with an array of partners to be removed
                        _this.parsedStoresIdsWithMacroGroups
                            .map(function (macro, i) {
                            macro[Object.keys(macro)[0]].map(function (store) {
                                result.forEach(function (item) { return item.map(function (i) {
                                    if (i.id === store) {
                                        // Filtering every item other than store
                                        _this.parsedStoresIdsWithMacroGroups
                                            .forEach(function (m, index) {
                                            m[Object.keys(m)[0]] =
                                                m[Object.keys(m)[0]].filter(function (str) { return str !== store; });
                                        });
                                    }
                                }); });
                            });
                        });
                        if (_this.parsedStoresIdsWithOutMacroGroups.length) {
                            _this.parsedStoresIdsWithOutMacroGroups
                                .map(function (store) {
                                result.forEach(function (item) { return item.map(function (i) {
                                    if (i.id === store) {
                                        // Filtering every item other than store
                                        _this.parsedStoresIdsWithOutMacroGroups =
                                            _this.parsedStoresIdsWithOutMacroGroups
                                                .filter(function (str) { return str !== store; });
                                    }
                                }); });
                            });
                        }
                        _this.submit();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CampaignsFormComponent.prototype.openDialogVerifyInitialCampaignDate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                dialogRef = this.dialog.open(ModalVerifyInitialCampaignDateComponent, {
                    width: '500px',
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result === 'continue') {
                        _this.verifyStores();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CampaignsFormComponent.prototype.handleCampaignInfoChanging = function () {
        this.isCampaignInfoChanged = true;
    };
    CampaignsFormComponent.prototype.verifyCampaignInitialDate = function () {
        var initialDate = new Date(this.formGroup.get('campaignInitialDate').value + " " + this.formGroup.get('campaignInitialTime').value);
        var differenceInTime = initialDate.getTime() - new Date().getTime();
        var differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
        if (differenceInDays <= 4) {
            this.openDialogVerifyInitialCampaignDate().catch(function (err) { return console.log(err); });
        }
        else {
            this.verifyStores();
        }
    };
    CampaignsFormComponent.prototype.verifyStores = function () {
        var _this = this;
        if (this.formGroup.value.isDefault) {
            this.formGroup.patchValue(tslib_1.__assign({}, this.formGroup, { campaignEndDate: null, campaignEndTime: null }));
        }
        //  Filtering only checked partners and creating a macro group id key based Object from all selected macro groups
        this.parsedStoresIdsWithMacroGroups = this.selectedMacroGroupsPartnersList.map(function (macro) {
            var obj = Object();
            var ids = macro.partners.filter(function (partner) { return (partner.checked); })
                .map(function (partner) { return (partner.storeId); });
            obj[macro.id] = ids;
            return obj;
        });
        // Filtering only checked partners from non macro group list for campaigns binding verification purpose
        var parsedStoreIdsList = this.partnersList
            .filter(function (partner) { return partner.checked; })
            .map(function (partner) { return ({ id: partner.id, nomeFantasia: partner.nomeFantasia }); });
        this.parsedStoresIdsWithOutMacroGroups = parsedStoreIdsList.map(function (store) { return store.id; });
        console.log(this.formGroup.value.campaignInitialTime);
        if (this.isCampaignInfoChanged) {
            var body = {
                storesIdsWithMacroGroups: this.parsedStoresIdsWithMacroGroups,
                storesIds: this.parsedStoresIdsWithOutMacroGroups,
                isDefault: this.formGroup.value.isDefault,
                campaignId: this.campaignID ? this.campaignID : undefined,
                campaignInitialDate: this.formGroup.value.campaignInitialDate ? this.formGroup.value.campaignInitialDate + " " + this.formGroup.value.campaignInitialTime + ":00" : null,
                campaignEndDate: this.formGroup.value.campaignEndDate ? this.formGroup.value.campaignEndDate + " " + this.formGroup.value.campaignEndTime + ":00" : null,
            };
            this.campaignService.verifyStores(body)
                .toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var body_1;
                return tslib_1.__generator(this, function (_a) {
                    if (response.results && response.results.length) {
                        response.results = response.results
                            .map(function (campaign) {
                            return ({
                                description: campaign.description,
                                stores: campaign.stores
                                    .map(function (store) { return ({ id: store.id, nomeFantasia: store.nomeFantasia, checked: true }); }),
                            });
                        });
                        body_1 = {
                            results: response.results,
                        };
                        this.openDialogStoresVerificationType(body_1).catch(function (err) { return console.log(err); });
                    }
                    else {
                        this.submit().catch(function (err) { return console.log(err); });
                    }
                    return [2 /*return*/];
                });
            }); }, function (error) { return console.log(error); });
        }
        else {
            this.submit().catch(function (err) { return console.log(err); });
        }
    };
    CampaignsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parsedPointsExpirationDate, parsedcampaignInitialDate, parsedCampaignEndDate, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        parsedPointsExpirationDate = this.formGroup.value.isAllowedExpirationPointsDate ? this.formGroup.value.pointsExpirationDate + " " + this.formGroup.value.pointsExpirationTime + ":00" : null;
                        parsedcampaignInitialDate = this.formGroup.value.campaignInitialDate ? this.formGroup.value.campaignInitialDate + " " + this.formGroup.value.campaignInitialTime + ":00" : null;
                        parsedCampaignEndDate = this.formGroup.value.campaignEndDate ? this.formGroup.value.campaignEndDate + " " + this.formGroup.value.campaignEndTime + ":00" : null;
                        data = {
                            description: this.formGroup.value.description,
                            campaignTypes: this.formGroup.value.campaignTypes,
                            isConvertDollar: this.formGroup.value.isConvertDollar,
                            convertionFactor: numeral(Number(this.formGroup.value.convertionFactor)).divide(100).value(),
                            spendingValue: this.formGroup.value.spendingValue,
                            fixedPoints: this.formGroup.value.fixedPointsAmount,
                            confirmationCampaignType: this.formGroup.value.confirmationCampaignType,
                            confirmationCampaign: this.formGroup.value.confirmationCampaign,
                            expirationDaysAmount: this.formGroup.value.expirationDaysAmount,
                            amountDayAfterConfirmated: this.formGroup.value.amountDayAfterConfirmated,
                            pointsExpirationDate: parsedPointsExpirationDate,
                            campaignInitialDate: parsedcampaignInitialDate,
                            campaignEndDate: parsedCampaignEndDate,
                            storesIdsWithMacroGroups: this.parsedStoresIdsWithMacroGroups,
                            storesIds: this.parsedStoresIdsWithOutMacroGroups,
                            isDefault: this.formGroup.value.isDefault,
                        };
                        if (!this.campaignID) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.campaignService.update(this.campaignID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Campanha editada com sucesso.');
                                    this.router.navigate(['./dashboard/campaign']);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.campaignService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (response.isCreated) {
                                    this.snackBar.open('Campanha criada com sucesso.');
                                    this.router.navigate(['./dashboard/campaign']);
                                }
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return CampaignsFormComponent;
}());
export { CampaignsFormComponent };
