import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, } from '@angular/material';
var QuizClientListComponent = /** @class */ (function () {
    function QuizClientListComponent(router, route, snackBar, dialog, quizService) {
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.quizService = quizService;
        this.quizID = '';
        this.name = '';
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    QuizClientListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.headersTable = ['cpfQuizClient', 'nameQuizClient', 'amountAnswer', 'actions'];
        this.quizID = this.route.snapshot.paramMap.get('quizID');
        if (this.quizID) {
            this.quizService.findOne(this.quizID).then(function (response) {
                _this.name = response.name;
            }, function (error) {
                _this.snackBar.open(error.error.message);
            });
            this.fieldsForm = [
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfQuizClient',
                    label: 'CPF',
                    placeholder: 'CPF do Cliente',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'nameQuizClient',
                    label: 'Nome',
                    placeholder: 'Nome cliente',
                },
            ];
        }
        this.quizClientList();
    };
    QuizClientListComponent.prototype.quizClientList = function () {
        var _this = this;
        this.quizService
            .listQuizClient(this.quizID, "?filters={\"page\":" + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuizClientListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return QuizClientListComponent;
}());
export { QuizClientListComponent };
