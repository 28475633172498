import { UnloggedComponent } from './unlogged.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [UnloggedComponent],
  exports: [UnloggedComponent],
})
export class UnloggedModule {}
