import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalFormIntegrationsComponent = /** @class */ (function () {
    function ModalFormIntegrationsComponent(dialogRef, data, formBuilder, snackBar, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
    }
    ModalFormIntegrationsComponent.prototype.ngOnInit = function () {
        this.filterForm = this.formBuilder.group({
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
            status: ['COMPUTED']
        });
    };
    ModalFormIntegrationsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormIntegrationsComponent.prototype.submit = function () {
        if (!this.filterForm.valid) {
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        var formattedStartDate = this.formatDate(this.filterForm.value.startDate);
        var formattedEndDate = this.formatDate(this.filterForm.value.endDate);
        var filters = {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            status: this.filterForm.value.status
        };
        this.dialogRef.close(filters);
    };
    ModalFormIntegrationsComponent.prototype.formatDate = function (date) {
        var parsedDate = new Date(date);
        var utcDate = new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60000);
        var day = this.padZero(utcDate.getUTCDate());
        var month = this.padZero(utcDate.getUTCMonth() + 1);
        var year = utcDate.getUTCFullYear();
        return "" + day + month + year;
    };
    ModalFormIntegrationsComponent.prototype.padZero = function (value) {
        return value < 10 ? "0" + value : "" + value;
    };
    return ModalFormIntegrationsComponent;
}());
export { ModalFormIntegrationsComponent };
