<app-unlogged>
  <div class="forgot-password">
    <div class="forgot-password__logo">
      <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
    </div>
    <div class="forgot-password__content">
      <p>Obrigado por fazer parte do JHSF ID</p>
    </div>
  </div>
</app-unlogged>
