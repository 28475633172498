import * as tslib_1 from "tslib";
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ReceivablesService = /** @class */ (function () {
    function ReceivablesService(http) {
        this.http = http;
    }
    ReceivablesService.prototype.listAll = function (params) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/cms/receivables", { params: params })
            .pipe(tap(function (_) { return _this.log('/receivables'); }), catchError(this.handleError));
    };
    ReceivablesService.prototype.listAllFromRoadpass = function (params) {
        return this.http
            .get(environment.apiUrl + "/cms/business-partners/receivables", { params: params })
            .pipe(catchError(this.handleError));
    };
    ReceivablesService.prototype.createReport = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/business-partners/receivables/export", { params: params })
                        .toPromise()];
            });
        });
    };
    ReceivablesService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    ReceivablesService.prototype.log = function (message) { };
    ReceivablesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReceivablesService_Factory() { return new ReceivablesService(i0.ɵɵinject(i1.HttpClient)); }, token: ReceivablesService, providedIn: "root" });
    return ReceivablesService;
}());
export { ReceivablesService };
