<app-logged class="funds-list">
  <header class="funds-list__header">
    <h2>Fundos</h2>
    <div class="funds-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="addFund()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter
    maskExample="00.000.000/0000-00"
    actions="form"
    type="fund"
    [fieldsForm]="fieldsForm"
    [formGroupValue]="formGroup"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef width="200px">CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="jcoinBalance">
        <th mat-header-cell *matHeaderCellDef>Saldo J-Coins</th>
        <td mat-cell *matCellDef="let element">
          <div
            *ngIf="element.totalFuture == 0 || element.totalFuture == null; else jcoinsFuture"
            matTooltip="Saldo Atual"
          >
            {{ element.jcoinBalance }}
          </div>
          <ng-template #jcoinsFuture>
            <div matTooltip="Saldo Atual / Saldo Futuro">
              {{ element.jcoinBalance }} / {{ element.totalFuture }}
            </div>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="membership">
        <th mat-header-cell *matHeaderCellDef>Membership</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.membershipActive">check_circle</mat-icon>
          <mat-icon *ngIf="!element.membershipActive">close</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef width="50px">Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.active">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.active">build_circle</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="250px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/funds/form/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="permissionsService.userHasPermission(cmsPermissions.manual_credit_maker)"
            [disabled]="!element.membershipActive"
            mat-icon-button
            matTooltip="Creditar Pontos"
            matTooltipPosition="left"
            routerLink="dashboard/manual-jcoin-credit/request/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>money</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Transações"
            matTooltipPosition="left"
            routerLink="/dashboard/funds/{{ element.id }}/transactions"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Extrato"
            matTooltipPosition="left"
            routerLink="/dashboard/funds/{{ element.id }}/extract"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>receipt</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Associados"
            matTooltipPosition="left"
            routerLink="/dashboard/funds/{{ element.id }}/associates"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>supervisor_account</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter
      type="fund"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
