import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import JsFileDownloader from 'js-file-downloader';
var ModalFormCustomerMonthBalanceComponent = /** @class */ (function () {
    function ModalFormCustomerMonthBalanceComponent(errorsService, snackBar, formBuilder, reportService, dialogRef) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
    }
    ModalFormCustomerMonthBalanceComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            month: [null, [Validators.required]],
            year: [null, [Validators.required]],
        });
    };
    ModalFormCustomerMonthBalanceComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormCustomerMonthBalanceComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        this.reportService
            .createReport("customer/month-balance?month=" + this.formGroup.value.month + "&year=" + this.formGroup.value.year)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Relatório de Estoque de J-Coins (Mês).' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormCustomerMonthBalanceComponent;
}());
export { ModalFormCustomerMonthBalanceComponent };
