import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import moment from "moment";
import { ModalLateArchitectInvoices } from "src/app/components/modal-late-architect-invoices/modal-late-architect-invoices.component";
import { ErrorsService } from "src/app/core/services/errors.service";
import { ArchitectClosingsService } from "src/app/services/architectClosings.service";

@Component({
  selector: 'architect-closings-form',
  templateUrl: './architect-closings-form.component.html',
  styleUrls: ['./architect-closings-form.component.scss'],
})
export class ArchitectClosingForm implements OnInit {

  public formGroup: FormGroup  
  public disableSubmit: boolean = false

  constructor(
    private readonly formBuilder: FormBuilder,
    private location: Location,
    private snackBar: MatSnackBar,
    private architectClosingsService: ArchitectClosingsService,
    public errorsService: ErrorsService,
    private readonly dialog: MatDialog,
  ){
    this.initForm();
  }

  ngOnInit() {

  }     

  initForm() {

    this.formGroup = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],     
    })
  }

  goBack() {
    this.location.back()
  }

  async submit() {

    if(!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data: any = {
      startDate: this.formGroup.value.startDate,
      endDate: this.formGroup.value.endDate,
    }

    if(moment(data.startDate).isAfter(moment(data.endDate))) {
      this.snackBar.open('A data inicial é posterior a data final')
      return false
    }

    try {
      this.disableSubmit = true
      
      const lateInvoices:any = await this.architectClosingsService.getLateInvoicesForClosing(data.startDate)
      
      if(lateInvoices.length > 0){
        const modalResult = await this.openLateInvoiceSelectionModal(lateInvoices);
        if(!modalResult || modalResult == 'cancel')  
          return
      
        data.lateInvoiceIds = modalResult        
      }
      
      const response = await this.architectClosingsService.createClosing(data)

      if(response) {
        this.snackBar.open('Fechamento criado com sucesso.')
        this.location.back()
      }

    } catch(error) {
      this.snackBar.open(error.error.message)
    } finally {
      this.disableSubmit = false
    }

  }

  async openLateInvoiceSelectionModal(lateInvoices) {
    
    try{
      const result = await this.dialog
        .open(ModalLateArchitectInvoices, {
          width: '800px',
          data: lateInvoices,
        })
        .afterClosed()
        .toPromise()
      
     return result
    } catch(err) {      
    }
   
  }
}