import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
var StoreTransactionFormComponent = /** @class */ (function () {
    function StoreTransactionFormComponent(errorsService, zipCodeService, formBuilder, storeService, transactionService, router, route, snackBar, currencyPipe) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.transactionService = transactionService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.states = [];
        this.plans = [];
        this.installments = [1];
        this.isValidTotalValue = false;
        this.isValidTotalValueMsg = '';
        this.headersTable = ['minimumTransactionValue', 'minimumInstallmentValue'];
        this.transactionsInfo = [];
        this.subStores = [];
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] };
        this.userRole = localStorage.getItem('userRole');
    }
    StoreTransactionFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
            _this.storeID = params.storeID;
        });
        this.initForm();
    };
    StoreTransactionFormComponent.prototype.initForm = function () {
        var _this = this;
        if (!this.storeID) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
            this.params.storeID = this.storeID;
        }
        this.formGroup = this.formBuilder.group({
            customerCpf: [
                null,
                [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
            ],
            totalValue: [null, [Validators.required]],
            description: [null, [Validators.required]],
            maximumInstallments: [1, [Validators.required]],
            isMembershipActive: [null, [Validators.required]],
            isPayActive: [null, [Validators.required]],
            subStoreId: [null, []],
        });
        if (this.storeID != '' && this.storeID != null) {
            var storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
            if (storeSelected && this.userRole != 'ADMIN') {
                this.storeID = storeSelected.storeId;
            }
            this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var e_1, _a, _b, _c, st;
                return tslib_1.__generator(this, function (_d) {
                    try {
                        for (_b = tslib_1.__values(response.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                            st = _c.value;
                            if (st.subStore.length) {
                                this.subStores.push(st.subStore[0]);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (this.subStores.length) {
                        this.subStores.sort(function (a, b) {
                            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0;
                        });
                    }
                    this.store = response;
                    this.isCardPayment = this.store.isCardPayment;
                    this.formGroup = this.formBuilder.group({
                        customerCpf: [
                            null,
                            [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
                        ],
                        totalValue: [null, [Validators.required]],
                        description: [null, [Validators.required]],
                        maximumInstallments: this.isCardPayment ? [null, [Validators.required]] : [null, []],
                        isMembershipActive: [this.store.isMembershipActive, [Validators.required]],
                        isPayActive: [this.store.isPayActive, [Validators.required]],
                        subStoreId: [null, []],
                    });
                    this.transactionsInfo = [
                        {
                            minimumTransactionValue: this.store.minimumTransactionValue / 100,
                            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
                        },
                    ];
                    return [2 /*return*/];
                });
            }); });
        }
    };
    StoreTransactionFormComponent.prototype.changeValueTotal = function (event) {
        this.isValidTotalValue = false;
        if (this.formGroup.value.totalValue * 100 >= this.store.minimumTransactionValue) {
            this.isValidTotalValueMsg = "O valor m\u00EDnimo da Transa\u00E7\u00E3o \u00E9 de R$ " + this.store.minimumTransactionValue;
            this.isValidTotalValue = true;
            this.calcTotalInstallments();
        }
    };
    StoreTransactionFormComponent.prototype.calcTotalInstallments = function () {
        this.installments = [1];
        for (var index = 2; index <= this.store.maximumInstallmentsPerTransaction; index++) {
            if ((this.formGroup.value.totalValue * 100) / index >= this.store.minimumInstallmentValue) {
                this.installments.push(index);
            }
        }
    };
    StoreTransactionFormComponent.prototype.convertCentsToMoney = function (value) {
        var money = this.currencyPipe.transform(value, 'BRL');
        // this.formGroup.controls['totalValue'].setValue(money);
        console.log(money);
    };
    StoreTransactionFormComponent.prototype.convertNumberToBoolean = function (num) {
        if (num == 0) {
            return false;
        }
        else {
            return true;
        }
    };
    StoreTransactionFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var totalValue, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.formGroup.value.totalValue * 100 < this.store.minimumTransactionValue) {
                    this.formGroup.controls.totalValue.setErrors({ transactionNotMatch: true });
                }
                else {
                    this.formGroup.controls.totalValue.setErrors(null);
                }
                if (!this.formGroup.valid) {
                    // this.snackBar.open(error.error.message);
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '');
                data = {
                    customerCpf: this.formGroup.value.customerCpf,
                    totalValue: parseInt(totalValue),
                    description: this.formGroup.value.description,
                    maximumInstallments: this.formGroup.value.maximumInstallments
                        ? parseInt(this.formGroup.value.maximumInstallments)
                        : 1,
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    storeId: this.storeID,
                    subStoreId: this.formGroup.value.subStoreId,
                };
                this.transactionService.addTransaction(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.snackBar.open('Transação criada com sucesso.');
                            this.params = tslib_1.__assign({}, this.params, { itComesFromNewTransaction: true });
                            this.router.navigate(['./dashboard/transactions/detail/' + response.id], { queryParams: this.params, skipLocationChange: true });
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return StoreTransactionFormComponent;
}());
export { StoreTransactionFormComponent };
