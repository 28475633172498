import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar } from '@angular/material'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import { CategoriesService } from 'src/app/services/categories.service'
import { UploadAdapter } from 'src/app/services/uploadAdapter.service'
import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { Location } from '@angular/common'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'

@Component({
  selector: 'app-subcategories-form',
  templateUrl: './subcategories-form.component.html',
  styleUrls: ['./subcategories-form.component.scss'],
})
export class subCategoriesFormComponent implements OnInit {
  public formGroup: FormGroup

  public categoryID: string
  public subCategoryID: string

  public Editor = ClassicEditor
  public config: any
  public srcResult: any
  public textDescription = ''
  public bannerUrl = ''
  public mainIconUrl = ''
  public menuIconUrl = ''
  public menuIconUrlInvalid = false

  private originalFlashSalesImage: any
  public croppedFlashSalesImage: any
  private flashSalesImagePosition: any
  private flashSalesImageData: any

  private originalPartnerBannerImage: any
  public croppedPartnerBannerImage: any
  private partnerBannerImagePosition: any
  private partnerBannerImageData: any

  public readUrlPartnerBanner: any
  public readUrlFlashSales: any

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public categoriesService: CategoriesService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    private readonly location: Location,
  ) {
    this.config = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'blockQuote',
          'undo',
          'redo',
        ],
      },
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }
  }

  ngOnInit () {
    this.categoryID = this.route.snapshot.paramMap.get('categoryID')
    this.subCategoryID = this.route.snapshot.paramMap.get('subCategoryID')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      heading: [null, [Validators.required]],
      menuIconUrl: [null, []],
      partnerBanner: [null, []],
      flashSales: [null, []],
      order: [null, [Validators.required, Validators.min(1)]],
      bannerLink: [null, []],
      flashSalesLink: [null, []],
      showMain: [null, []],
      description: [null, []],
      isActive: [null, []],
      isParking: [null, []],
    })
    if (this.subCategoryID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.categoriesService.findOne(this.subCategoryID).then(async response => {
      this.formGroup.patchValue({
        heading: response.heading,
        menuIconUrl: response.menuIconUrl,
        order: response.order,
        bannerLink: response.bannerLink,
        flashSalesLink: response.flashSalesLink,
        showMain: response.showMain,
        description: response.description,
        isActive: !!response.isActive,
        isParking: response.isParking,
      })
      this.croppedPartnerBannerImage = response.bannerUrl
      this.croppedFlashSalesImage = response.flashSalesUrl
      this.menuIconUrl = response.menuIconUrl
    })
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  selectFile (event, type) {
    const uploadData = new FormData()
    uploadData.append('file', event.target.files[0], event.target.files[0].name)

    this.categoriesService.upload(uploadData).subscribe(
      response => {
        switch (type) {
          case 'menuIconUrl': {
            this.formGroup.get('menuIconUrl').setValue(response.url)
            this.menuIconUrl = response.url
            this.menuIconUrlInvalid = !response.url
            break
          }
          default:
            break
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  selectFilePartnerBanner (event: Event) {
    const originalPartnerBannerImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalPartnerBannerImage = reader.result
    }
    try {
      reader.readAsDataURL(originalPartnerBannerImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedPartnerBannerImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedPartnerBannerImage = result.img.dataURL
          this.partnerBannerImagePosition = result.config
          this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  selectFileFlashSales (event: Event) {
    const originalFlashSalesImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalFlashSalesImage = reader.result
    }
    try {
      reader.readAsDataURL(originalFlashSalesImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedFlashSalesImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedFlashSalesImage = result.img.dataURL
          this.flashSalesImagePosition = result.config
          this.flashSalesImageData = await base64ToBlob(this.croppedFlashSalesImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editPartnerBannerImage () {
    try {
      const img = this.originalPartnerBannerImage
        ? this.originalPartnerBannerImage
        : await this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.partnerBannerImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedPartnerBannerImage = result.img.dataURL
        this.partnerBannerImagePosition = result.config
        this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async removePartnerBannerImage () {
    this.readUrlPartnerBanner = ''
    this.originalPartnerBannerImage = undefined
    this.croppedPartnerBannerImage = undefined
    this.partnerBannerImagePosition = undefined
    this.partnerBannerImageData = undefined
  }

  async editFlashSalesImage () {
    try {
      const img = this.originalFlashSalesImage
        ? this.originalFlashSalesImage
        : await this.getBase64ImageFromUrl(this.croppedFlashSalesImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.flashSalesImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedFlashSalesImage = result.img.dataURL
        this.flashSalesImagePosition = result.config
        this.flashSalesImageData = await base64ToBlob(this.croppedFlashSalesImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async removeFlashSalesImage () {
    this.readUrlFlashSales = ''
    this.originalFlashSalesImage = undefined
    this.croppedFlashSalesImage = undefined
    this.flashSalesImagePosition = undefined
    this.flashSalesImageData = undefined
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  change ({ editor }: ChangeEvent) {
    const EditorData = editor.getData()
    this.formGroup.get('content').setValue(EditorData)
  }

  goBack () {
    this.location.back()
  }

  async submit () {
    this.menuIconUrlInvalid = !this.menuIconUrl
    if (!this.formGroup.valid || this.menuIconUrlInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    if (this.croppedPartnerBannerImage && this.partnerBannerImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlPartnerBanner = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)
    }
    if (this.croppedFlashSalesImage && this.flashSalesImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlFlashSales = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.flashSalesImageData)
    }

    const data = {
      heading: this.formGroup.value.heading,
      order: this.formGroup.value.order,
      menuIconUrl: this.formGroup.value.menuIconUrl,
      bannerUrl: this.readUrlPartnerBanner,
      bannerLink: this.formGroup.value.bannerLink || '',
      flashSalesUrl: this.readUrlFlashSales,
      flashSalesLink: this.formGroup.value.flashSalesLink || '',
      showMain: !!this.formGroup.value.showMain,
      description: this.formGroup.value.description,
      parentId: this.categoryID ? this.categoryID : undefined,
      isActive: !!this.formGroup.value.isActive,
      isParking: !!this.formGroup.value.isParking,
    }
    if (this.subCategoryID) {
      this.categoriesService.updateSubCategories(data, this.subCategoryID).then(
        async response => {
          if (response.isUpdated) {
            await this.location.back()
            this.snackBar.open('Subcategoria atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.categoriesService.addSubCategories(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate([`./dashboard/categories/${this.categoryID}/subcategories`])
            this.snackBar.open('Subcategoria criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
