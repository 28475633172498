import { Component, OnInit, Inject, Input } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-modal-create-transaction-anticipated',
  templateUrl: './modal-create-transaction-anticipated.component.html',
  styleUrls: ['./modal-create-transaction-anticipated.component.scss'],
})
export class ModalCreateTransactionAnticipatedComponent implements OnInit {
  public showInput = false
  totalValue = new FormControl(this.data.amountPayment / 100, [])

  constructor (
    public dialogRef: MatDialogRef<ModalCreateTransactionAnticipatedComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      amountPayment: number
      cpf: string
      fullName: string
      descriptionPayment: string
      paymentDate: string
    },
  ) {}

  ngOnInit () {}

  alterValue () {
    this.showInput = true
  }

  convertCentsToReal (value) {
    return value / 100
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  createTransacation () {
    if (this.showInput) {
      this.dialogRef.close({ createTransacation: true, totalNewValue: this.totalValue.value * 100 })
    } else {
      this.dialogRef.close({ createTransacation: true, amountPayment: this.data.amountPayment })
    }
  }
}
