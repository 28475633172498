import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSnackBar, MatDialog } from '@angular/material'
import { Location } from '@angular/common'
import numeral from 'numeral'

import { ErrorsService } from '../../../../../../src/app/core/services/errors.service'
import { MembershipService } from '../../../../services/membership.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-membership-plans-form',
  templateUrl: './plans-form.component.html',
  styleUrls: ['./plans-form.component.scss'],
})
export class MembershipPlansFormComponent implements OnInit {
  public formGroup: FormGroup
  public planId: string
  isDefault: boolean
  isFreeUsed = ''
  isDefaultUsed = {}
  clickDefault: boolean
  clickFree: boolean
  valueInvalid = false
  recurrencePeriodInvalid = false
  public params = { formGroupValue: [] }

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    public membershipService: MembershipService,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.planId = this.route.snapshot.paramMap.get('planID')
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })

    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      beginDate: [null, [Validators.required]],
      endDate: [null, []],
      value: [null, []],
      recurrencePeriod: [null, []],
      isDefault: [null, [Validators.required]],
      isFree: [null, [Validators.required]],
      isPj: [null, [Validators.required]],
      isActive: [null, [Validators.required]],
    })

    if (this.planId) {
      this.getById()
    } else {
      this.formGroup.patchValue({
        isActive: '0',
      })
    }
  }

  getById () {
    this.membershipService.listPlansById(this.planId).then(
      async response => {
        this.formGroup.patchValue({
          name: response.name,
          beginDate: response.beginDate.substring(0, 10),
          endDate: response.endDate ? response.endDate.substring(0, 10) : '',
          value: numeral(response.value).divide(100).value(),
          recurrencePeriod: response.recurrencePeriod,
          isDefault: !!response.isDefault,
          isFree: !!response.isFree,
          isPj: !!response.isPj,
          isActive: String(response.isActive),
        })
      },
      error => {
        this.snackBar.open(error.message)
      },
    )
  }

  changeDefault (e) {
    if (e.value) {
      this.clickDefault = true
    }
  }

  changeFree (e) {
    if (e.value) {
      this.clickFree = true
      if (this.valueInvalid) {
        this.valueInvalid = false
      }
      if (this.recurrencePeriodInvalid) {
        this.recurrencePeriodInvalid = false
      }
    }
  }

  async modalConfirmation (data, text, body) {
    await this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: `O ${data.name} está como Plano Membership ${text}, deseja alterar ?`,
          buttonConfirmText: 'Alterar',
          buttonNot: 'NÃO',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel' && result !== 'not') {
          return await this.membershipService.editPlan(data.id, body).then(
            async response => {
              return response
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        } else {
          if (this.clickDefault) {
            this.formGroup.patchValue({
              isDefault: false,
            })
          }
          if (this.clickFree) {
            this.formGroup.patchValue({
              isFree: false,
            })
          }
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  onChange (e) {
    this.valueInvalid = !this.formGroup.value.value
  }

  async submit () {
    if (this.formGroup.value.isDefault) {
      await this.membershipService.listPlans().then(
        async response => {
          this.isDefaultUsed = response
            .filter(p => p.isDefault === 1 && p.id !== this.planId)
            .find(() => true)
          if (this.isDefaultUsed) {
            const body = {
              isDefault: 0,
            }
            await this.modalConfirmation(this.isDefaultUsed, 'Default', body)
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.formGroup.value.isFree) {
      await this.membershipService.listPlans().then(
        async response => {
          this.isFreeUsed = response
            .filter(p => p.isFree === 1 && p.id !== this.planId)
            .find(() => true)
          if (this.isFreeUsed) {
            const body = {
              isFree: 0,
            }
            await this.modalConfirmation(this.isFreeUsed, 'Free', body)
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
    this.valueInvalid = !!(!this.formGroup.value.isFree && !this.formGroup.value.value)

    this.recurrencePeriodInvalid = !!(
      !this.formGroup.value.isFree && !this.formGroup.value.recurrencePeriod
    )

    if (!this.formGroup.valid || this.valueInvalid || this.recurrencePeriodInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.formGroup.value.isFree && this.formGroup.value.recurrencePeriod) {
      this.formGroup.patchValue({
        recurrencePeriod: '0',
      })
    }

    if (this.formGroup.value.isFree && this.formGroup.value.value) {
      this.formGroup.patchValue({
        value: 0,
      })
    }

    const data = {
      name: this.formGroup.value.name,
      beginDate: this.formGroup.value.beginDate,
      endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
      value: this.formGroup.value.value
        ? numeral(this.formGroup.value.value).multiply(100).value()
        : 0,
      recurrencePeriod: this.formGroup.value.recurrencePeriod
        ? this.formGroup.value.recurrencePeriod.toString()
        : '0',
      isDefault: this.formGroup.value.isDefault ? 1 : 0,
      isFree: this.formGroup.value.isFree ? 1 : 0,
      isPj: this.formGroup.value.isPj ? 1 : 0,
      isActive: Number(this.formGroup.value.isActive),
    }
    if (this.planId) {
      await this.membershipService.editPlan(this.planId, data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Plano Membership alterado com sucesso.')
        },
        error => {
          this.snackBar.open(error.message)
        },
      )
    } else {
      await this.membershipService.createPlan(data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Plano Membership cadastrado com sucesso.')
        },
        error => {
          this.snackBar.open(error.message)
        },
      )
    }
  }
}
