import { Component, OnInit } from '@angular/core'
import { BannersPagesAppService } from '../../../../services/bannersPagesApp.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-banner-pages-app-list',
  templateUrl: './banner-pages-app-list.component.html',
  styleUrls: ['./banner-pages-app-list.component.scss'],
})
export class BannerPagesAppListComponent implements OnInit {
  public headersTable: string[] = ['page', 'actions']
  public banners: any[] = []
  public idPage: any
  public page: any

  constructor (
    public BannersPagesAppService: BannersPagesAppService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.page = this.route.snapshot.paramMap.get('page')
    this.idPage = this.route.snapshot.paramMap.get('idPage')

    this.list()
  }

  list () {
    this.BannersPagesAppService.list().then(
      response => {
        this.banners = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  goBack () {
    this.location.back()
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Macro grupo?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.BannersPagesAppService.delete(id).then(
            async response => {
              this.list()
              this.snackBar.open('Banner removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
