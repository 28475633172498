import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { Params } from '@angular/router'
import { CompaniesService } from '../../../../../services/companies.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'architect-company-list',
  templateUrl: './architect-company-list.component.html',
  styleUrls: ['./architect-company-list.component.scss'],
})
export class ArchitectCompanyListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'companyName', 'isCpf', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public companiesService: CompaniesService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjArchitectCompany',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'select',
        formControlName: 'isCpfArchitectCompany',
        label: 'Tem CPF cadastrado ?',
        placeholder: 'Tem CPF cadastrado',
        list: [
          { key: 'true', value: 'Sim' },
          { key: 'false', value: 'Não' },
        ],
        optionDefault: 'TODOS',
      },
    ]
  }

  async list () {
    const { companies, TotalRegisters } = await this.companiesService
      .list(`?&page=${this.page}&limit=${this.pageSize}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { companies: [], TotalRegisters: 0 }
      })
    this.dataSource = new MatTableDataSource(companies)
    this.pageTotal = TotalRegisters
    // this.resultsAll = results
  }

  remove (id, preRegistration) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Empresa?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.companiesService.delete(id).then(
            async response => {
              this.list().catch(error => console.log(error))
              this.snackBar.open('Empresa removida com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter.companies
    this.pageTotal = returnFilter.TotalRegisters
    this.dataSource = new MatTableDataSource(returnFilter.companies)
    this.resultsAll = returnFilter.companies
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async report () {
    const filter = 'architects/companies'

    await this.reportService.createReport(filter).then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Relatório de Empresas do arquiteto.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }
}
