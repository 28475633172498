import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var BannerFormComponent = /** @class */ (function () {
    function BannerFormComponent(errorsService, formBuilder, bannersService, bannersPagesAppService, snackBar, router, route, location, _dialog, _cd, categoriesService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.bannersService = bannersService;
        this.bannersPagesAppService = bannersPagesAppService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.location = location;
        this._dialog = _dialog;
        this._cd = _cd;
        this.categoriesService = categoriesService;
        this.showDates = false;
        this.menuIconUrl = '';
    }
    BannerFormComponent.prototype.ngOnInit = function () {
        this.idPage = this.route.snapshot.paramMap.get('idPage');
        this.page = this.route.snapshot.paramMap.get('page');
        this.id = this.route.snapshot.paramMap.get('id');
        this.initForm();
    };
    BannerFormComponent.prototype.initForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            description: [null, [Validators.required]],
            image: [null, []],
            order: [null, [Validators.required]],
            initAt: [null, []],
            endAt: [null, []],
            isFixed: [null, []],
            menuIconUrl: [null, [Validators.required]],
            link: [null, []],
        });
        if (this.id) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
        if (this.page === 'category') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.category = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.page === 'subcategory') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.subcategory = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.page === 'home') {
            this.bannersPagesAppService.findOne(this.idPage).then(function (response) {
                _this.pages = response.page;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
    };
    BannerFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.bannersService.findOne(this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var dateInitAt, dateEndAt;
                            return tslib_1.__generator(this, function (_a) {
                                dateInitAt = response.initAt ? response.initAt.split('T')[0] : null;
                                dateEndAt = response.endAt ? response.endAt.split('T')[0] : null;
                                if (response.isFixed) {
                                    this.showDates = true;
                                }
                                this.formGroup.patchValue({
                                    description: response.description,
                                    // image: response.image,
                                    order: response.order,
                                    initAt: dateInitAt,
                                    endAt: dateEndAt,
                                    isFixed: response.isFixed,
                                    menuIconUrl: response.image,
                                    link: response.link,
                                });
                                this.croppedPartnerBannerImage = response.image;
                                this.menuIconUrl = response.image;
                                console.log(this.menuIconUrl);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BannerFormComponent.prototype.onChange = function () {
        if (this.formGroup.value.isFixed) {
            this.showDates = true;
            this.formGroup.get('endAt').setValidators(null);
            this.formGroup.get('endAt').updateValueAndValidity();
            this.formGroup.get('endAt').setErrors(null);
            this.formGroup.get('initAt').setValidators(null);
            this.formGroup.get('initAt').updateValueAndValidity();
            this.formGroup.get('initAt').setErrors(null);
            this.formGroup.value.endAt = null;
            this.formGroup.value.initAt = null;
        }
        if (!this.formGroup.value.isFixed) {
            this.showDates = false;
        }
    };
    BannerFormComponent.prototype.onChangeDate = function () {
        if (this.formGroup.value.initAt) {
            this.formGroup.get('endAt').setValidators([Validators.required]);
            this.formGroup.get('endAt').updateValueAndValidity();
        }
        if (this.formGroup.value.endAt) {
            this.formGroup.get('initAt').setValidators([Validators.required]);
            this.formGroup.get('initAt').updateValueAndValidity();
        }
    };
    BannerFormComponent.prototype.selectFile = function (event, type) {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append('file', event.target.files[0], event.target.files[0].name);
        this.categoriesService.upload(uploadData).subscribe(function (response) {
            switch (type) {
                case 'menuIconUrl': {
                    _this.formGroup.get('menuIconUrl').setValue(response.url);
                    _this.menuIconUrl = response.url;
                    break;
                }
                default:
                    break;
            }
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    // selectFilePartnerBanner (event: Event) {
    // const originalPartnerBannerImageUrl = (event.target as any).files[0]
    // const reader: FileReader = new FileReader()
    // reader.onloadend = e => {
    //   this.originalPartnerBannerImage = reader.result
    // }
    // try {
    //   reader.readAsDataURL(originalPartnerBannerImageUrl)
    // } catch (e) {
    //   this.snackBar.open(e.message)
    // }
    //   this.croppedPartnerBannerImage = null!
    //   this._dialog
    //     .open<CropperDialogComponent, any>(CropperDialogComponent, {
    //     data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
    //     width: 650,
    //     disableClose: true,
    //   })
    //     .afterClosed.toPromise()
    //     .then(async (result?: { img: ImgCropperEvent, config: any }) => {
    //       if (result) {
    //         this.croppedPartnerBannerImage = result.img.dataURL
    //         this.partnerBannerImagePosition = result.config
    //         this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
    //         this._cd.markForCheck()
    //       }
    //     })
    //     .catch(error => {
    //       this.snackBar.open(error.message)
    //     })
    // }
    BannerFormComponent.prototype.editPartnerBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalPartnerBannerImage) return [3 /*break*/, 1];
                        _a = this.originalPartnerBannerImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.partnerBannerImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 5:
                        _b.partnerBannerImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BannerFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    BannerFormComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'partnerBanner') {
                    this.readUrlPartnerBanner = '';
                    this.originalPartnerBannerImage = undefined;
                    this.croppedPartnerBannerImage = undefined;
                    this.partnerBannerImagePosition = undefined;
                    this.partnerBannerImageData = undefined;
                }
                return [2 /*return*/];
            });
        });
    };
    BannerFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    BannerFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, page, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.value.isFixed &&
                            !this.formGroup.value.initAt &&
                            !this.formGroup.value.endAt) {
                            this.snackBar.open('Preencha as datas ou fixo.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedPartnerBannerImage && this.partnerBannerImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 1:
                        response = _a.sent();
                        this.readUrlPartnerBanner = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)];
                    case 2:
                        _a.sent();
                        this.formGroup.value.image = this.readUrlPartnerBanner;
                        _a.label = 3;
                    case 3:
                        if (!this.formGroup.valid || !this.menuIconUrl) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (this.page === 'category') {
                            page = 'CATEGORIES';
                        }
                        if (this.page === 'subcategory') {
                            page = 'SUBCATEGORIES';
                        }
                        if (this.page === 'home') {
                            page = this.page.toUpperCase();
                        }
                        data = {
                            description: this.formGroup.value.description,
                            image: this.menuIconUrl,
                            order: this.formGroup.value.order,
                            initAt: this.formGroup.value.initAt,
                            endAt: this.formGroup.value.endAt,
                            isFixed: this.formGroup.value.isFixed,
                            categoryId: this.page === 'category' || this.page === 'subcategory' ? this.idPage : undefined,
                            appPageId: this.page === 'home' ? this.idPage : undefined,
                            page: page,
                            link: this.formGroup.value.link,
                        };
                        if (this.id) {
                            this.bannersService.update(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isUpdated) {
                                        this.goBack();
                                        this.snackBar.open('Banner atualizado com sucesso.');
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.bannersService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isCreated) {
                                        this.goBack();
                                        this.snackBar.open('Banner criado com sucesso.');
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return BannerFormComponent;
}());
export { BannerFormComponent };
