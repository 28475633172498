import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import { MacroGroupsService } from '../../services/macro-groups.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-receivables',
  templateUrl: './modal-form-receivables.component.html',
  styleUrls: ['./modal-form-receivables.component.scss'],
})
export class ModalFormReceivablesComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public macroGroupControl = new FormControl()
  public selectedStores: any[] = []
  public selectedMacroGroups: any[] = []
  public filter = ''
  stores: any[] = []
  visible = true
  selectable = true
  filteredStores: Observable<string[]>
  filteredMacroGroups: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public storeSelected: any
  public storesUser: any[] = []
  public userRole: string
  macroGroups: any

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete
  @ViewChild('macroGroupInput', { static: false }) macroGroupInput: ElementRef<HTMLInputElement>
  @ViewChild('autoMacroGroup', { static: false }) matAutocompleteMacroGroup: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormReceivablesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      storeId: string
    },
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    public macroGroupsService: MacroGroupsService,
  ) { }

  ngOnInit () {
    this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
    this.storesUser = JSON.parse(localStorage.getItem('storesUser'))
    this.userRole = localStorage.getItem('userRole')
    this.macroGroupsService
      .list()
      .then(
        response => {
          this.macroGroups = response.results
          console.log(this.macroGroups)
          this.filteredMacroGroups = this.macroGroupControl.valueChanges.pipe(
            startWith(''),
            map((macroGroup: any) =>
              macroGroup ? this._filteredMacroGroups(macroGroup) : this.macroGroups.slice(0, 20),
            ),
          )
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )

    if (!this.storeSelected) {
      this.formGroup = this.formBuilder.group({
        beginDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        storesInput: [null, []],
        macroGroupsInput: [null, []],
      })

      this.reportService.listStores().subscribe(
        response => {
          response.map(item => {
            this.stores.push(item)
          })
        },
        error => {
          console.log(error)
        },
      )

      this.filteredStores = this.storeControl.valueChanges.pipe(
        startWith(''),
        map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
      )
    } else {
      if (this.storesUser.length > 1) {
        this.formGroup = this.formBuilder.group({
          beginDate: [null, [Validators.required]],
          endDate: [null, [Validators.required]],
          storesInput: [null, []],
          macroGroupsInput: [null, []],
        })

        this.filteredStores = this.storeControl.valueChanges.pipe(
          startWith(''),
          map((store: any) =>
            store ? this._filterStoresStorekeeper(store) : this.storesUser.slice(0, 20),
          ),
        )
      } else {
        this.formGroup = this.formBuilder.group({
          beginDate: [null, [Validators.required]],
          endDate: [null, [Validators.required]],
        })
      }
    }
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  private _filteredMacroGroups (macroGroup: string): string[] {
    const remove = String(macroGroup)

    if (typeof remove !== 'string') {
      return
    }

    return this.macroGroups
      .filter(c => !this.selectedMacroGroups.includes(c))
      .filter(c => `${String(c.macroName)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.stores
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  private _filterStoresStorekeeper (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.storesUser
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.storeName)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  remove (value: any): void {
    const index = this.selectedStores.indexOf(value)
    if (index >= 0) {
      this.selectedStores.splice(index, 1)
    }
  }

  removeMacroGroup (value: any): void {
    const index = this.selectedMacroGroups.indexOf(value)
    if (index >= 0) {
      this.selectedMacroGroups.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    this.selectedStores.push(event.option.value)
    this.storeInput.nativeElement.value = ''
    this.storeControl.setValue(null)
  }

  selectedMacroGroup (event: MatAutocompleteSelectedEvent): void {
    if (this.selectedMacroGroups.length < 1) {
      this.selectedMacroGroups.push(event.option.value)
      this.macroGroupInput.nativeElement.value = ''
      this.macroGroupControl.setValue(null)
    }
  }

  submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    if (this.userRole !== 'ADMIN') {
      if (this.storeSelected) {
        if (this.storesUser.length > 1) {
          let storeIds = this.selectedStores.map(c => c.storeId)
          if (!storeIds.length) {
            storeIds = this.storesUser.map(c => c.storeId)
          }
          this.filter = `receivables-storekeeper?storesIds=${storeIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
        } else {
          this.filter = `receivables-storekeeper?storesIds=${this.storeSelected.storeId}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
        }
      }
    } else {
      if (this.selectedStores) {
        const stores = this.selectedStores.map(c => c.id)
        this.filter = `receivables?storesIds=${stores}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        this.filter = `receivables?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      }
      if (this.selectedMacroGroups) {
        this.filter = `receivables?macroGroupId=${this.selectedMacroGroups[0].id}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        this.filter = `receivables?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      }
    }
    this.reportService
      .createReport(this.filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório de Recebíveis.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
