<mat-form-field>
  <mat-label>{{placeholder}}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip *ngIf="!!selectedItem" [selectable]="false" [removable]="true" (removed)="clearSelection()">
      {{ getSelectedItemText()}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input type="text" [placeholder]="!selectedItem ? placeholder : ''" matInput #customerInput
      [disabled]="!!selectedItem" [formControl]="localFormControl" [matAutocomplete]="auto" [matChipInputFor]="chipList" />
    <mat-icon class="spin" *ngIf="loading" style="position: absolute">loop</mat-icon>
  </mat-chip-list>


  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ getOptionDisplayName(option) }}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>