import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ZipCodeService = /** @class */ (function () {
    function ZipCodeService(handler) {
        this.handler = handler;
        this.http = new HttpClient(this.handler);
    }
    ZipCodeService.prototype.getAddress = function (zipCode) {
        var zipCodeNumbers = zipCode && zipCode.match(/\d+/g).join('');
        return this.http.get("" + environment.zipCodeApiUrl + zipCodeNumbers + "/json");
    };
    ZipCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZipCodeService_Factory() { return new ZipCodeService(i0.ɵɵinject(i1.HttpBackend)); }, token: ZipCodeService, providedIn: "root" });
    return ZipCodeService;
}());
export { ZipCodeService };
