import { PipeTransform } from '@angular/core';
var YesNoPipe = /** @class */ (function () {
    function YesNoPipe() {
    }
    YesNoPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return value ? 'Sim' : 'Não';
    };
    return YesNoPipe;
}());
export { YesNoPipe };
