import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManualJcoinCreditService = /** @class */ (function () {
    function ManualJcoinCreditService(http) {
        this.http = http;
        this.manualJcoinCreditCountSubject = new BehaviorSubject(null);
    }
    ManualJcoinCreditService.prototype.list = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/manual-jcoins-credit?" + params).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.request = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiUrl + "/cms/manual-jcoins-credit";
                return [2 /*return*/, this.http.post(url, body).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.review = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiUrl + "/cms/manual-jcoins-credit/" + id + "/review";
                return [2 /*return*/, this.http.put(url, body).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.getPendingManualJcoinCreditsCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiUrl + "/cms/manual-jcoins-credit/get-pending-requests-count";
                return [2 /*return*/, this.http.get(url).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.checkPendingManualJcoinCredits = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastUpdate, lastTime, today, isAfter;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lastUpdate = localStorage.getItem('lastPendingRequestUpdate');
                        lastTime = moment(new Date(Number(lastUpdate)));
                        today = moment();
                        isAfter = today.isAfter(lastTime.add(3, 'minute'));
                        if (!(!lastUpdate || isAfter)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updatePendingRequestsCount()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManualJcoinCreditService.prototype.updatePendingRequestsCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var count;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPendingManualJcoinCreditsCount()];
                    case 1:
                        count = (_a.sent()).count;
                        localStorage.setItem('lastPendingRequestUpdate', new Date().getTime() + '');
                        localStorage.setItem('pendingManualJcoinCreditsCount', count + '');
                        this.manualJcoinCreditCountSubject.next(count);
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualJcoinCreditService.prototype.getManualJcoinCreditCountChange = function () {
        return this.manualJcoinCreditCountSubject.asObservable();
    };
    ManualJcoinCreditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualJcoinCreditService_Factory() { return new ManualJcoinCreditService(i0.ɵɵinject(i1.HttpClient)); }, token: ManualJcoinCreditService, providedIn: "root" });
    return ManualJcoinCreditService;
}());
export { ManualJcoinCreditService };
