import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public loading

  constructor (private readonly http: HttpClient) {}

  async transactionsMonth (storeId) {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/dashboard/transactions-month/store/${storeId}`)
      .toPromise()
  }

  async transactionsYear (storeId) {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/dashboard/transactions-year/store/${storeId}`)
      .toPromise()
  }
}
