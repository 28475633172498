<app-logged class="liquidation-transfer-permissions">
  <header class="liquidation-transfer-permissions__header">
    <h2>Permissões de acesso para as TEDs -  Macro grupos e Parceiros</h2>
    <div class="liquidation-transfer-permissions__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>

  <div>
    <form [formGroup]="settingsForm" (ngSubmit)="submitForm()">
      <mat-card>
        <div class="row">
          <div class="col-12 col-sm-12">
            <h3>Selecione abaixo os macrogrupos onde os parceiros receberão as TEDs automaticamente <br>
            Selecione também os parceiros dentro dos macrogrupos que não receberão as TEDs automáticas </h3>
          </div>
          <div class="col-6 col-sm-12">
            <mat-form-field>
              <input type="text" 
                #input
                placeholder="Pesquisar por Macrogrupos" 
                matInput 
                formControlName="macroGroupSearchInput"
                [matAutocomplete]="macroGroupAuto"             
                
              >
              <mat-autocomplete #macroGroupAuto="matAutocomplete" (optionSelected)="onMacroGroupOptionSelected($event)">
                <mat-option *ngFor="let macroGroup of filteredMacroGroups" [value]="macroGroup">
                  <span [innerHTML]="highlightMatches(macroGroup.macroName, settingsForm.get('macroGroupSearchInput').value)"></span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <h4>Macro Grupos PERMITIDOS para as TEDs de liquidação: <br>(selecione ao menos 1)</h4>
            <div *ngFor="let macroGroup of settingsForm.get('selectedMacroGroups').value; let i = index">
              <span>{{ macroGroup.macroName }}</span>
              <button mat-icon-button (click)="removeMacroGroup(i)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>

          <div class="col-6 col-sm-12">
            <mat-form-field>
              <input type="text" 
                placeholder="Pesquisar por parceiros" 
                matInput 
                formControlName="storeSearchInput"
                [matAutocomplete]="auto"             
                (input)="search($event.target.value)"
              >
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                <mat-option *ngFor="let store of filteredStores | async" [value]="store">
                  <span [innerHTML]="highlightMatches(store.nomeFantasia, settingsForm.get('storeSearchInput').value)"></span> (CNPJ: <span [innerHTML]="highlightMatches(store.cnpj || store.document, settingsForm.get('storeSearchInput').value)"></span>)
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <h4>Parceiros BLOQUEADOS para as TEDs de liquidação:</h4>
            <div *ngFor="let store of settingsForm.get('blackListedStores').value; let i = index">
              <span>{{ store.nomeFantasia }}</span>
              <button mat-icon-button (click)="removeStore(i)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        
          <div class="store-form__actions">
            <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
          </div>
        </div>
      </mat-card>          
    </form>
  </div>


</app-logged>