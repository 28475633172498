import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Params } from '@angular/router';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var BeneficiaryListComponent = /** @class */ (function () {
    function BeneficiaryListComponent(authService, snackBar, dialog) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['name', 'cpf', 'nameBeneficiary', 'cpfBeneficiary', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    BeneficiaryListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'fullNameCustomerBeneficiary',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfCustomerBeneficiary',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'fullNameBeneficiary',
                label: 'Nome do Beneficiário',
                placeholder: 'Nome do Beneficiário',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfBeneficiary',
                label: 'CPF do Beneficiário',
                placeholder: 'CPF do Beneficiário',
            },
        ];
    };
    BeneficiaryListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, items, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .clientsBeneficiaries("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), items = _a.items, total = _a.total;
                        this.dataSource = new MatTableDataSource(items);
                        this.pageTotal = total;
                        this.resultsAll = items;
                        return [2 /*return*/];
                }
            });
        });
    };
    BeneficiaryListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse beneficiário',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.authService.clientsBeneficiaryDelete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (err) { console.log(err); });
                                this.snackBar.open('Beneficiário removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    BeneficiaryListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.items);
        this.resultsAll = returnFilter.items;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return BeneficiaryListComponent;
}());
export { BeneficiaryListComponent };
