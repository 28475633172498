import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { CurrencyPipe } from '@angular/common'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import JsFileDownloader from 'js-file-downloader'
import * as moment from 'moment'
import { ErrorsService } from '../../../../core/services/errors.service'
import { ReportService } from '../../../../services/report.service'
import { ModalFormExportConsolidatedJcoinsComponent } from 'src/app/components/modal-form-export-consolidated-jcoins/modal-form-export-consolidated-jcoins.component'

@Component({
  selector: 'app-store-form',
  templateUrl: './reports-consolidated-jcoins.component.html',
  styleUrls: ['./reports-consolidated-jcoins.component.scss'],
})
export class ConsolidatedJcoinsComponent implements OnInit {
  public formGroup: FormGroup
  public headersTable: string[]
  public dataSourceLastMonth: any
  public typesAccumulation: any
  public fieldsForm: any
  public returnFilterMonthAndYear
  public date: any

  public pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public errorsService: ErrorsService,
    public reportService: ReportService,
    private readonly snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.headersTable = ['name', 'total']

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'reportConsolidatedMonth',
        label: 'Mês',
        placeholder: 'Mês',
        mask: '000.0',
        minLength: 2,
        maxLength: 2,
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'reportConsolidatedYear',
        label: 'Ano',
        placeholder: 'Ano',
        minLength: 4,
        maxLength: 4,
      },
    ]

    this.reportService.consolidatedJcoins().then(
      response => {
        this.dataSourceLastMonth = new MatTableDataSource(response.typesLastMonth)
        const dateTotalLastMonth = response.typesLastMonth.find((t) => t.dateTotalLastMonth)
        this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null

        this.typesAccumulation = new MatTableDataSource(response.typesAccumulation)
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertName (name: string) {
    if (name === 'sumTotalLastMonth') {
      return 'SALDO INICIAL'
    }
    if (name === 'totalBalancePreRegistration') {
      return 'Total Pré-Cadastros'
    }
    if (name === 'totalBalanceCustomer') {
      return 'Total Clientes'
    }
    if (name === 'totalBalanceAirportsCompanies') {
      return 'Total Empresas Aeroporto'
    }
    if (name === 'totalAmount') {
      return 'TOTAL'
    }
    if (name === 'typeAccumulationApp') {
      return 'ACUMULO APP'
    }
    if (name === 'movements') {
      return 'MOVIMENTAÇÕES'
    }
    if (name === 'totalAccumulationApp' || name === 'totalAccumulationCustomers') {
      return 'Clientes'
    }
    if (name === 'totalPreRegistration') {
      return 'Pré-cadastro'
    }
    if (name === 'totalPreRegistrationAccumulationAirportsCompany') {
      return 'Empresas Aeroporto'
    }
    if (name === 'totalAccumulationAirportsCompany') {
      return 'Acumulo Aeroporto'
    }
    if (name === 'totalAccumulationIncorporations') {
      return 'Acumulo Incorporação'
    }
    if (name === 'typeAccumulationManual') {
      return 'ACUMULO FORA APP'
    }
    if (name === 'totalMovements') {
      return 'TOTAL MOVIMENTAÇÕES'
    }
    if (name === 'totalRescue') {
      return 'RESGATE DE JCOINS'
    }
    if (name === 'totalFinal') {
      return 'SALDO FINAL'
    }
    if (name === 'totalPreregistrationsArchitect') {
      return 'Pré-cadastros arquitetos'
    }
    if (name === 'totalArchitect') {
      return 'Clientes arquitetos'
    }
    if (name === 'totalReversed') {
      return 'ESTORNO DE RESGATE'
    }
  }
  export (filterMonthAndYear: any) {
    const month = parseInt(filterMonthAndYear.monthYear.slice(0, 2), 10);
    const year = parseInt(filterMonthAndYear.monthYear.slice(2), 10);
    this.returnFilterMonthAndYear = `?month=${month}&year=${year}`;
  
    this.reportService
      .exportReport(this.returnFilterMonthAndYear)
      .then(async response => {
        if (response) {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response,
            filename: `Relatório_de_Estoque_de_J-Coins_${month}_${year}.xlsx`,
          });
        } else {
          this.snackBar.open("Erro: URL do arquivo não encontrada", "Fechar", { duration: 3000 });
        }
      })
      .catch(error => {
        this.snackBar.open(error.message, "Fechar", { duration: 3000 });
      });
  }  

  reciverFeedback (returnFilter) {
    this.dataSourceLastMonth = new MatTableDataSource(returnFilter.typesLastMonth)
    this.typesAccumulation = new MatTableDataSource(returnFilter.typesAccumulation)
    const dateTotalLastMonth = returnFilter.typesLastMonth.find((t) => t.dateTotalLastMonth)

    this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null
    this.pageTotal = returnFilter.total
    this.returnFilterMonthAndYear = returnFilter.filter
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(ModalFormExportConsolidatedJcoinsComponent, {
      width: '400px',
      data: {}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.returnFilterMonthAndYear = result;
        this.export(this.returnFilterMonthAndYear);
      }
    });
  }
  
}
