import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
var ModalCreateTransactionAnticipatedComponent = /** @class */ (function () {
    function ModalCreateTransactionAnticipatedComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.showInput = false;
        this.totalValue = new FormControl(this.data.amountPayment / 100, []);
    }
    ModalCreateTransactionAnticipatedComponent.prototype.ngOnInit = function () { };
    ModalCreateTransactionAnticipatedComponent.prototype.alterValue = function () {
        this.showInput = true;
    };
    ModalCreateTransactionAnticipatedComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ModalCreateTransactionAnticipatedComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalCreateTransactionAnticipatedComponent.prototype.createTransacation = function () {
        if (this.showInput) {
            this.dialogRef.close({ createTransacation: true, totalNewValue: this.totalValue.value * 100 });
        }
        else {
            this.dialogRef.close({ createTransacation: true, amountPayment: this.data.amountPayment });
        }
    };
    return ModalCreateTransactionAnticipatedComponent;
}());
export { ModalCreateTransactionAnticipatedComponent };
