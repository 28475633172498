import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ErrorsService } from 'src/app/core/services/errors.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AccountBankService } from 'src/app/services/accountBank.service';
import { bankCode } from 'src/app/models/bankCode';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-store-form',
  templateUrl: './store-bank-account-form.component.html',
  styleUrls: ['./store-bank-account-form.component.scss'],
})
export class StoreBankAccountFormComponent implements OnInit {
  public formGroup: FormGroup;
  public bankAccounts: Array<bankCode> = [];
  public bankAccountsOptions: Observable<Array<bankCode>>;
  public storeID = '';

  constructor(
    public errorsService: ErrorsService,
    public formBuilder: FormBuilder,
    public accountBankService: AccountBankService,
    public router: Router,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.initForm();
  }

  get formData () {
    const data = Object.assign({}, this.formGroup.value);
    data.bankCode = typeof data.bankCode == 'object' ? data.bankCode.code : data.bankCode;
    return data;
  }

  ngOnInit() {}

  initForm() {
    this.storeID = this.route.snapshot.paramMap.get('storeID');
    this.formGroup = this.formBuilder.group({
      bankCode: [null, [Validators.required]],
      agency: [null, [Validators.required]],
      accountNumber: [null, [Validators.required]],
      accountDigit: [null, [Validators.required]],
      accountType: [null, [Validators.required]],
    });

    this.bankAccountsOptions = this.formGroup.controls.bankCode.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this.bankAccounts.filter((bankAccount) => {
          const newValue = typeof value == 'object' ? value.name : value;
          return (
            bankAccount.code == value ||
            bankAccount.name.includes(newValue.toUpperCase())
          );
        });
      })
    );

    this.listBankAccounts();
  }

  displayBankCode(bank: bankCode): string {
    return bank ? `${bank.code} - ${bank.name}` : '';
  }

  listBankAccounts() {
    this.accountBankService.listBankCodes().subscribe(
      async (response) => {
        this.bankAccounts = response.sort((a, b) => {
          a.name = a.name.toUpperCase();
          b.name = b.name.toUpperCase();

          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }

  async submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.accountBankService.add(this.formData, this.storeID).subscribe(
      async (response) => {
        if (response.isCreated) {
          this.snackBar.open('Conta bancária criada com sucesso.');
          this.router.navigate([
            './dashboard/stores/' + this.storeID + '/bank-account',
          ]);
        }
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message);
      }
    );
  }
}
