<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; ">
    <div style="overflow-y: auto;">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h5>Relatório de Transferencias de Liquidação Detalhado</h5>              
              <div class="col-12 col-sm-12">    

                <mat-form-field>
                  <mat-label>CNPJ</mat-label>
                  <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
                  <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select
                    formControlName="isSuccess"                    
                  >
                    <mat-option [value]="undefined">Todos</mat-option>
                    <mat-option [value]="true">Concluído</mat-option>
                    <mat-option [value]="false">Erro</mat-option>
                    <mat-option [value]="''">Pendente</mat-option>
                  </mat-select>
                  <mat-error *ngIf="formGroup.controls.isSuccess?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.isSuccess) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Tipo</mat-label>
                  <mat-select
                    formControlName="reason"                    
                  >
                    <mat-option>Todos</mat-option>
                    <mat-option [value]="'liquidation'">Liquidação de Parceiros</mat-option>
                    <mat-option [value]="'administrative_tax'">Pagamento de Taxa administrativa</mat-option>
                    
                  </mat-select>
                  <mat-error *ngIf="formGroup.controls.reason?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.reason) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field >
                  <input
                    matInput
                    formControlName="startDueDate"
                    placeholder="Agendado para - Data Início"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.startDueDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.startDueDate) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field >
                  <input
                    matInput
                    formControlName="endDueDate"
                    placeholder="Agendado para - Data Final"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.endDueDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.endDueDate) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field >
                  <input
                    matInput
                    formControlName="startPaidAt"
                    placeholder="Pagamento - Data Início"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.startPaidAt?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.startPaidAt) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field >
                  <input
                    matInput
                    formControlName="endPaidAt"
                    placeholder="Pagamento - Data Final"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.endPaidAt?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.endPaidAt) }}
                  </mat-error>
                </mat-form-field>
                

               
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
