import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop'
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material'
import clonedeep from 'lodash.clonedeep'
import { Location } from '@angular/common'
import * as _ from 'lodash'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { QuestionsService } from '../../../../../services/questions.service'
import { QuizService } from '../../../../../services/quiz.service'
import { UploadAdapter } from '../../../../../services/uploadAdapter.service'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.scss'],
})
export class QuestionsListComponent implements OnInit {
  public quizID = ''
  public name = ''
  public image = ''
  public description = ''
  public redirectUrl = false
  public headersTable: string[]
  public pageTotal: number
  public Editor = ClassicEditor
  public config: any
  public dataSourceData: any
  dataSource: any
  fieldsForm: any
  public params = { formGroupValue: [] }

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public questionsService: QuestionsService,
    public quizService: QuizService,
    public location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.config = {}
    this.headersTable = ['ordination', 'ask', 'type', 'view', 'actions']
    this.quizID = this.route.snapshot.paramMap.get('quizID')

    if (this.quizID) {
      this.quizService.findOne(this.quizID).then(
        response => {
          this.name = response.name
          this.image = response.image
          response.description = response.description.replace('<p>', '')
          response.description = response.description.replace('</p>', '')
          this.description = response.description
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }

    this.questionsList()

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameQuestion',
        label: 'Perguntas ',
        placeholder: 'Perguntas',
      },
    ]
  }

  goBack () {
    this.location.back()
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  showImage () {
    localStorage.setItem('imageQuestion', this.image)
    window.open('/dashboard/quiz/image', '_blank')
  }

  dragging (event: CdkDragStart) {
    const maxStep = _.max(this.dataSource.data.filter(i => i.group).map(i => Number(i.group)))
    this.dataSourceData = this.dataSource.data = this.dataSource.data.concat([
      { group: maxStep + 1 },
    ])
  }

  drop (event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      return
    }

    const previousIndex = this.dataSource.data.findIndex(row => row === event.item.data)

    if (event.currentIndex === previousIndex) {
      let lastItem
      do {
        lastItem = _.last(event.container.data)
        if (lastItem.group) {
          event.container.data.pop()
        }
      } while (lastItem.group)

      this.dataSource.data = event.container.data
      return
    }

    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja salvar alteração na ordem das Perguntas ?',
          buttonConfirmText: 'Salvar',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          if (event.currentIndex) {
            moveItemInArray(event.container.data, previousIndex, event.currentIndex)
          }
          let lastItem
          do {
            lastItem = _.last(event.container.data)
            if (lastItem.group) {
              event.container.data.pop()
            }
          } while (lastItem.group)

          this.reorder()

          const newWorldOrder = this.dataSource.data
            .filter(i => !i.group)
            .map(i => ({ id: i.id, step: i.step, ordination: i.ordination }))

          this.questionsService.changeOrder(this.quizID, newWorldOrder).then(
            async response => {
              this.snackBar.open('Ordem alterada com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        } else {
          let lastItem
          do {
            lastItem = _.last(event.container.data)
            if (lastItem.group) {
              event.container.data.pop()
            }
          } while (lastItem.group)

          this.dataSource.data = event.container.data
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reorder () {
    let step = 0
    let ordination = 0
    const data = []
    for (const item of this.dataSource.data) {
      if (item.group) {
        const next = this.dataSource.data[this.dataSource.data.indexOf(item) + 1]
        if (!next.group) {
          step++
          item.group = step
          data.push(item)
        }
      } else {
        ordination++
        item.ordination = ordination
        item.step = step
        data.push(item)
      }
    }
    this.dataSource.data = data
  }

  questionsList () {
    this.questionsService.list(this.quizID).then(
      async response => {
        this.pageTotal = response.total
        response.results.sort((a, b) => {
          return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0
        })
        this.dataSource = new MatTableDataSource(this.groupStep(response.results))
        this.dataSourceData = this.dataSource.data
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  groupStep (data) {
    const groups = _(data)
      .groupBy(v => v.step)
      .value()
    const result = []
    for (const group in groups) {
      result.push({ group })
      for (const item of groups[group]) {
        result.push(item)
      }
    }
    return result
  }

  isGroup (index, item): boolean {
    return !!item.group
  }

  convertType (type) {
    switch (type) {
      case 'TEXT':
        return 'Texto'
        break
      case 'NUMBER':
        return 'Número'
        break
      case 'DATE':
        return 'Data'
        break
      case 'SINGLE-CHOICE':
        return 'Única escolha'
        break
      case 'MULTIPLE-CHOICE':
        return 'Mútipla escolha'
        break
      default:
        return ''
    }
  }

  convertView (view) {
    switch (view) {
      case 'SINGLE-LINE':
        return 'Única linha'
        break
      case 'MULTIPLE-LINES':
        return 'Múltiplas linhas'
        break
      case 'INPUT':
        return 'Livre digitação'
        break
      case 'BUTTONS-DEGREE':
        return 'Botões +/-'
        break
      case 'DATE':
        return 'Data'
        break
      case 'TIME':
        return 'Hora'
        break
      case 'DATE-TIME':
        return 'Data e Hora'
        break
      case 'BUTTONS':
        return 'Botões'
        break
      case 'CHECKBOX':
        return 'Checkbox'
        break
      case 'RADIOBOX':
        return 'Radiobox'
        break
      default:
        return ''
    }
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Pergunta?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.questionsService.delete(this.quizID, id).then(
            async response => {
              this.questionsList()
              this.snackBar.open('Pergunta removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    returnFilter.results.sort((a, b) => {
      return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0
    })
    this.dataSource = new MatTableDataSource(this.groupStep(returnFilter.results))
  }
}
