<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Cadastrar Beneficiário</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/beneficiary">
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="customer" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ customer.firstName }} {{ customer.lastName }}</div>
      <div><b>CPF: </b>{{ customer.cpf | mask: '000.000.000-00' }}</div>
    </mat-card>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <!-- <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" />
              <mat-error *ngIf="formGroup.controls.cpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cpf) }}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field class="customer-autocomplete">
              <mat-label>CPF ou Nome do Cliente Beneficiário</mat-label>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let customer of selectedCustomers"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="removeCustomer(customer)"
                >
                  {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  #customerInput
                  [formControl]="customerControl"
                  formControlName="customersInput"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                  {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="customerInvalid"
              >Campo deve ser preenchido</mat-error
            >
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
