<main class="modal">
  <a class="link-cancel" mat-icon-button matTooltip="Cancelar" (click)="cancel()" matTooltipPosition="left">
    <mat-icon>close</mat-icon>
  </a>

  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h3>Deseja realmente continuar?</h3>
        <h4>Os parceiros a seguir já estão vinculados a campanha</h4>
        <div class="col-12 col-sm-12 modal__card_content">
          <mat-card-content>
            <div *ngFor="let campaign of data.results, index as i ">
              <small>{{ campaign.description}}</small>
              <div class="macro-viewport">
                <div *ngFor="let partner of campaign.stores">
                  <div class="macro-item" *ngIf="!partner.disabled">
                    <input type="checkbox" [value]="partner.id" [checked]="partner.checked"
                      (change)="changeFromStoreListCheckbox($event, i)" />
                    <mat-label> &nbsp;{{partner.nomeFantasia}}</mat-label>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="row">
        <div mat-dialog-actions class="modal__card_button">
          <button class="btn-primary-light" type="submit" (click)="submit()" mat-button>Alterar</button>
        </div>
        <div mat-dialog-actions class="modal__card_button">
          <button class="btn-primary-light" (click)="cancel()" mat-button>Cancelar</button>
        </div>
      </div>
    </mat-card>
  </div>
</main>
