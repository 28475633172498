import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public loading

  constructor (private readonly http: HttpClient) { }

  listCampaigns (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/campaigns/${filter || ''}`).pipe(
      tap(_ => this.log('cms/campaigns')),
      catchError(this.handleError),
    )
  }

  fetchCampaignsReport (storeId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/campaigns/reports/${storeId}`).pipe(
      tap(_ => this.log('cms/campaigns/reports')),
      catchError(this.handleError),
    )
  }

  async create (body: any): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/campaigns/`, body).toPromise()
  }

  async update (id: string, body: any): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/campaigns/${id}`, body).toPromise()
  }

  findOne (id: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/campaigns/${id}`).pipe(
      tap(_ => this.log(`cms/campaigns/${id}`)),
      catchError(this.handleError),
    )
  }

  verifyStores (body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/campaigns-verify-stores/`, body).pipe(
      tap(_ => this.log('cms/campaigns-verify-stores/')),
      catchError(this.handleError),
    )
  }

  async delete (id: string): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/campaigns/${id}`).toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) { }
}
