import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import numeral from 'numeral'
import { ModalCancelTransactionComponent } from '../../../../components/modal-cancel-transaction/modal-cancel-transaction.component'
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component'
import { MembershipService } from '../../../../services/membership.service'
import { TransactionService } from '../../../../services/transaction.service'
@Component({
  selector: 'app-membership-plan-transactions-customer-list',
  templateUrl: './plan-transactions-customer-list.component.html',
  styleUrls: ['./plan-transactions-customer-list.component.scss'],
})
export class MembershipPlanTransactionsCustomerListComponent implements OnInit {
  planId: string
  customerId: string
  dataSource: any
  listStatusDefault: any[] = []
  listStatus: any[] = []
  planName: string
  fullName: string
  typeTransaction: string
  public pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  fieldsForm: any

  public headersTable = [
    'orderNumber',
    'storeName',
    'customerCpf',
    'createdAt',
    'totalValue',
    'state',
    'actions',
  ]

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    public transactionService: TransactionService,
    public membershipService: MembershipService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit () {
    this.planId = this.route.snapshot.paramMap.get('planID')
    this.customerId = this.route.snapshot.paramMap.get('customerID')

    this.membershipService.listPlansById(this.planId).then(
      async response => {
        this.planName = response.name
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )

    this.transactionService
      .transactionsMembershipPlanByCustomer(
        this.planId,
        this.customerId,
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
      )
      .then(
        async response => {
          this.dataSource = new MatTableDataSource(response.results)
          this.pageTotal = response.results.length
          const infos = response.results.find(() => true)
          this.fullName = infos.fullName
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )

    this.transactionService
      .getStatus()
      .toPromise()
      .then(response => {
        Object.keys(response).map(k => {
          this.listStatusDefault = response
          if (k !== 'PENDING') {
            this.listStatus.push({ key: k, value: this.listStatusDefault[k] })
          }
        })
        this.listStatus.sort((a, b) => {
          return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
        })
      })
      .catch(error => {
        this.snackBar.open(error.error.message)
      })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'number',
        formControlName: 'numberTransactionPlanMembership',
        label: 'Número da Transação',
        placeholder: 'Número da Transação',
      },
      {
        field: 'input',
        type: 'date',
        formControlName: 'dateTransactionPlanMembership',
      },
      {
        field: 'select',
        formControlName: 'statusPlanMembership',
        label: 'Status',
        placeholder: 'Status',
        list: this.listStatus,
        optionDefault: 'TODOS',
      },
    ]
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  goBack () {
    this.location.back()
  }

  convertStatusTransactions (transaction) {
    if (transaction.isManual && transaction.state == 'PENDING') {
      return 'AGUARDANDO APROVAÇÃO DA JHSF'
    } else {
      return this.listStatusDefault[transaction.state]
    }
  }

  view (transaction) {
    if (transaction.isManual) {
      localStorage.setItem('isManual', 'true')
    } else {
      localStorage.setItem('isManual', 'false')
    }
  }

  openDialogCancelTransaction (transaction): void {
    const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.cancel(transaction.id, result)
      }
    })
  }

  cancel (transactionID, reason) {
    const data = {
      reason: reason,
    }
    this.transactionService
      .cancelTransaction(transactionID, data)
      .toPromise()
      .then(
        async response => {
          this.snackBar.open('Transação cancelada com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  detail (transacationId) {
    this.transactionService
      .listTransactionsById(`${transacationId}?showManualDetails=true`)
      .toPromise()
      .then(
        response => {
          const dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
            data: {
              customerBalance: response.customerBalance,
              customerCpf: response.customerCpf,
              customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
              customerBirthDate: response.customerBirthDate,
              customerEmailAddress: response.customerEmailAddress,
              customerPhoneNumber: response.customerPhoneNumber,
              customerGender: response.customerGender,
            },
          })
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.results.length
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
