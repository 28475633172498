import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StoreDocumentService = /** @class */ (function () {
    function StoreDocumentService(http) {
        this.http = http;
    }
    StoreDocumentService.prototype.list = function (storeID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/" + storeID + "/documents").pipe(tap(function (_) { return _this.log('/cms/documents'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.add = function (storeID, params) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/cms/stores/" + storeID + "/documents/upload?type=" + params)
            .pipe(tap(function (_) { return _this.log('cms/documents'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.upload = function (pathAwsUpload, data) {
        var _this = this;
        var headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.http
            .put("" + pathAwsUpload, data, { headers: headers })
            .pipe(tap(function (_) { return _this.log('cms/categories/images'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.put = function (pathAwsUpload, data) {
        var _this = this;
        return this.http.put(pathAwsUpload, data).pipe(tap(function (_) { return _this.log('/cms/documents'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.delete = function (accountID, storeID) {
        var _this = this;
        return this.http
            .delete(environment.apiUrl + "/cms/stores/" + storeID + "/documents/" + accountID)
            .pipe(tap(function (_) { return _this.log('cms/documents'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.create = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/stores/documents/", data).pipe(tap(function (_) { return _this.log('cms/documents'); }), catchError(this.handleError));
    };
    StoreDocumentService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    StoreDocumentService.prototype.log = function (message) { };
    StoreDocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreDocumentService_Factory() { return new StoreDocumentService(i0.ɵɵinject(i1.HttpClient)); }, token: StoreDocumentService, providedIn: "root" });
    return StoreDocumentService;
}());
export { StoreDocumentService };
