import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import JsFileDownloader from 'js-file-downloader';
import { ModalFormExportConsolidatedJcoinsComponent } from 'src/app/components/modal-form-export-consolidated-jcoins/modal-form-export-consolidated-jcoins.component';
var ConsolidatedJcoinsComponent = /** @class */ (function () {
    function ConsolidatedJcoinsComponent(errorsService, reportService, snackBar, currencyPipe, dialog) {
        this.errorsService = errorsService;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.dialog = dialog;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ConsolidatedJcoinsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.headersTable = ['name', 'total'];
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'reportConsolidatedMonth',
                label: 'Mês',
                placeholder: 'Mês',
                mask: '000.0',
                minLength: 2,
                maxLength: 2,
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'reportConsolidatedYear',
                label: 'Ano',
                placeholder: 'Ano',
                minLength: 4,
                maxLength: 4,
            },
        ];
        this.reportService.consolidatedJcoins().then(function (response) {
            _this.dataSourceLastMonth = new MatTableDataSource(response.typesLastMonth);
            var dateTotalLastMonth = response.typesLastMonth.find(function (t) { return t.dateTotalLastMonth; });
            _this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null;
            _this.typesAccumulation = new MatTableDataSource(response.typesAccumulation);
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ConsolidatedJcoinsComponent.prototype.convertName = function (name) {
        if (name === 'sumTotalLastMonth') {
            return 'SALDO INICIAL';
        }
        if (name === 'totalBalancePreRegistration') {
            return 'Total Pré-Cadastros';
        }
        if (name === 'totalBalanceCustomer') {
            return 'Total Clientes';
        }
        if (name === 'totalBalanceAirportsCompanies') {
            return 'Total Empresas Aeroporto';
        }
        if (name === 'totalAmount') {
            return 'TOTAL';
        }
        if (name === 'typeAccumulationApp') {
            return 'ACUMULO APP';
        }
        if (name === 'movements') {
            return 'MOVIMENTAÇÕES';
        }
        if (name === 'totalAccumulationApp' || name === 'totalAccumulationCustomers') {
            return 'Clientes';
        }
        if (name === 'totalPreRegistration') {
            return 'Pré-cadastro';
        }
        if (name === 'totalPreRegistrationAccumulationAirportsCompany') {
            return 'Empresas Aeroporto';
        }
        if (name === 'totalAccumulationAirportsCompany') {
            return 'Acumulo Aeroporto';
        }
        if (name === 'totalAccumulationIncorporations') {
            return 'Acumulo Incorporação';
        }
        if (name === 'typeAccumulationManual') {
            return 'ACUMULO FORA APP';
        }
        if (name === 'totalMovements') {
            return 'TOTAL MOVIMENTAÇÕES';
        }
        if (name === 'totalRescue') {
            return 'RESGATE DE JCOINS';
        }
        if (name === 'totalFinal') {
            return 'SALDO FINAL';
        }
        if (name === 'totalPreregistrationsArchitect') {
            return 'Pré-cadastros arquitetos';
        }
        if (name === 'totalArchitect') {
            return 'Clientes arquitetos';
        }
        if (name === 'totalReversed') {
            return 'ESTORNO DE RESGATE';
        }
    };
    ConsolidatedJcoinsComponent.prototype.export = function (filterMonthAndYear) {
        var _this = this;
        var month = parseInt(filterMonthAndYear.monthYear.slice(0, 2), 10);
        var year = parseInt(filterMonthAndYear.monthYear.slice(2), 10);
        this.returnFilterMonthAndYear = "?month=" + month + "&year=" + year;
        this.reportService
            .exportReport(this.returnFilterMonthAndYear)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (response) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response,
                        filename: "Relat\u00F3rio_de_Estoque_de_J-Coins_" + month + "_" + year + ".xlsx",
                    });
                }
                else {
                    this.snackBar.open("Erro: URL do arquivo não encontrada", "Fechar", { duration: 3000 });
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message, "Fechar", { duration: 3000 });
        });
    };
    ConsolidatedJcoinsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.dataSourceLastMonth = new MatTableDataSource(returnFilter.typesLastMonth);
        this.typesAccumulation = new MatTableDataSource(returnFilter.typesAccumulation);
        var dateTotalLastMonth = returnFilter.typesLastMonth.find(function (t) { return t.dateTotalLastMonth; });
        this.date = dateTotalLastMonth ? dateTotalLastMonth.dateTotalLastMonth : null;
        this.pageTotal = returnFilter.total;
        this.returnFilterMonthAndYear = returnFilter.filter;
    };
    ConsolidatedJcoinsComponent.prototype.openFilterModal = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalFormExportConsolidatedJcoinsComponent, {
            width: '400px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.returnFilterMonthAndYear = result;
                _this.export(_this.returnFilterMonthAndYear);
            }
        });
    };
    return ConsolidatedJcoinsComponent;
}());
export { ConsolidatedJcoinsComponent };
