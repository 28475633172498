import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ReceivablesService } from 'src/app/services/receivables.service'
import { ClosingsService } from 'src/app/services/closings.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { PrepaymentsService } from 'src/app/services/prepayments.service'
import { ModalDeleteClosingComponent } from 'src/app/components/modal-delete-closing/modal-delete-closing.component'
import { ModalClosingsComponent } from 'src/app/components/modal-closings/modal-closings.component'

@Component({
  selector: 'app-store-list',
  templateUrl: './closings-list.component.html',
  styleUrls: ['./closings-list.component.scss'],
})
export class ClosingsListComponent implements OnInit {
  public headersTable: string[] = ['id', 'createdAt', 'period', 'paymentDate', 'actions']
  public closings: Array<any> = []
  public closingsResponse: {} = {}
  public closingsSelecteds: Array<any> = []
  public formFilter: FormGroup
  public form: FormGroup
  private closingsDate: {} = {}

  constructor(
    private closingsService: ClosingsService,
    public prepaymentsService: PrepaymentsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      paymentDate: [null, [Validators.required]],
    })

    this.form = this.formBuilder.group({
      paymentDate: [null, [Validators.required]],
      closings: [null, [Validators.required]],
    })

    this.listAll()
  }

  create(result) {
    this.closingsService.create(result).subscribe(
      async response => {
        this.listAll()
        this.snackBar.open('Fechamento criado com sucesso')
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  listAll() {
    this.closings = []

    this.closingsService.listAll().subscribe(
      async response => {
        this.closingsResponse = response
        this.closings = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalClosingsComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.create(result)
      }
    })
  }

  openDialog(closingsID) {
    const dialogRef = this.dialog.open(ModalDeleteClosingComponent, {
      width: '500px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.closingsService.delete(closingsID).subscribe(
          async response => {
            this.snackBar.open('Fechamento deletado com sucesso.')
            this.listAll()
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )
      }
    })
  }

  convertCentsToReal(value) {
    return value / 100
  }
}
