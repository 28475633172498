<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; height: calc(100vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card-content>
            <div class="row">
              <h5>Relatório de clientes únicos</h5>
              <div class="col-12 col-sm-12">
                <mat-radio-group formControlName="kind" (change)="onChange()">
                  <mat-radio-button value="macroGroup">Macro Grupo</mat-radio-button>
                  <mat-radio-button value="subStore">Subempresa</mat-radio-button>
                </mat-radio-group>
                <mat-error
                  style="padding-top: 5px; padding-bottom: 9px; font-size: 12px"
                  *ngIf="kindInvalid"
                  >Campo deve ser preenchido</mat-error
                >
                <mat-form-field style="padding-top: 15px">
                  <input
                    matInput
                    formControlName="beginDate"
                    placeholder="Data Inicial"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.beginDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.beginDate) }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="endDate" placeholder="Data Final" type="date" />
                  <mat-error *ngIf="formGroup.controls.endDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.endDate) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
