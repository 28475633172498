import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-store-export-to-zoop',
  templateUrl: './store-export-to-zoop.html',
  styleUrls: ['./store-export-to-zoop.scss'],
})
export class StoreExportToZoopComponent implements OnInit {
  exportRunning: boolean = false;
  exportData;
  interval;
  whatchRunning: boolean = false
  from: number = 0
  quantity : number = 4

  constructor(private readonly http: HttpClient) { }

  executeStartExport(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/start-export-to-zoop`, { params: { quantity: `${this.quantity}`, from: `${this.from}` }, headers: { noloading: 'true' } }).toPromise()
  }


  ngOnInit() {
  }

  async startExportClick() {

    if (this.exportRunning) return

    this.exportRunning = true
    try {
      await this.executeStartExport()
    } catch (error) {
      console.log("ERRO AO EXPORTAR", error)
    }
    this.exportRunning = false
  }


  getExportDataList(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/get-zoop-exported-data`, { headers: { noloading: 'true' } }).toPromise()
  }

  startWatchData() {
    this.exportRunning = true
    this.interval = setInterval(async () => {
      const res = await this.getExportDataList()
      this.exportData = res
    }, 3000)
  }

  stopWatchData() {
    this.exportRunning = false
    clearInterval(this.interval)
  }

}