import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
interface DialogData {
  cnpj: string
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-closings.component.html',
  styleUrls: ['./modal-closings.component.scss'],
})
export class ModalClosingsComponent implements OnInit {
  public formGroup: FormGroup

  constructor(
    public dialogRef: MatDialogRef<ModalClosingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) {
    console.log('data', data)
  }

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  submit() {
    console.log(this.formGroup.value)
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    this.dialogRef.close(this.formGroup.value)
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      paymentDate: [null, [Validators.required]],
    })
  }
}
