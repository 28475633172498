import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var AirportsStoresTaxCashbackListComponent = /** @class */ (function () {
    function AirportsStoresTaxCashbackListComponent(airportsService, snackBar, dialog) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['cnpj', 'storeName', 'taxCashback', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    AirportsStoresTaxCashbackListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
    };
    AirportsStoresTaxCashbackListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .storesTaxCashbackList()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [] };
                        })];
                    case 1:
                        results = (_a.sent()).results;
                        console.log(results);
                        this.dataSource = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsStoresTaxCashbackListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa taxa do parceiro de origem ?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.deleteStoresTaxCashback(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (error) { return console.log(error); });
                                this.snackBar.open('Taxa removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    AirportsStoresTaxCashbackListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return AirportsStoresTaxCashbackListComponent;
}());
export { AirportsStoresTaxCashbackListComponent };
