var ModalVerifyInitialCampaignDateComponent = /** @class */ (function () {
    function ModalVerifyInitialCampaignDateComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    ModalVerifyInitialCampaignDateComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalVerifyInitialCampaignDateComponent.prototype.submit = function () {
        this.dialogRef.close('continue');
    };
    return ModalVerifyInitialCampaignDateComponent;
}());
export { ModalVerifyInitialCampaignDateComponent };
