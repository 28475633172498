import { Component, Inject, Input, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service';

@Component({
  selector: 'app-modal-transfer-logs',
  templateUrl: './modal-transfer-logs.component.html',
  styleUrls: ['./modal-transfer-logs.component.scss'],
})
export class ModalTransferLogs implements OnInit {

  

  public logs
  public headersTable =  ['createdAt','operation','result', 'requestPayload','responsePayload','success']
  
  constructor (
    public dialogRef: MatDialogRef<ModalTransferLogs>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    console.log('data', data)
    this.logs = data
  }

  ngOnInit () {
    // empty
  }

  getResult(responsePayload: any) {
  
    try{
      const object = JSON.parse(responsePayload)

      if(object){
        if(object.resultado && !`${object.resultado}`.startsWith('STR'))
          return object.resultado
        else if(object.descricaostatus)
          return object.descricaostatus
        else if(object.mensagem)
          return object.mensagem
      }
    } catch(e) {
    }

  }
  
 
}
