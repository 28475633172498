import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
interface DialogData {
  results: any[]
}

@Component({
  selector: 'app-modal-partner-edition-macro-binding-information',
  templateUrl: './modal-partner-edition-macro-binding-information.component.html',
  styleUrls: ['./modal-partner-edition-macro-binding-information.component.scss'],
})
export class ModalPartnerEditionMacroBindingInformation implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<ModalPartnerEditionMacroBindingInformation>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar) {
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  confirm () {
    this.dialogRef.close('submit')
  }

  ngOnInit () {
  }
}
