import { Component, OnInit } from '@angular/core'
import { CustomerAttendantService } from '../../../../services/customer-attendant.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-customer-attendant-list',
  templateUrl: './customer-attendant-list.component.html',
  styleUrls: ['./customer-attendant-list.component.scss'],
})
export class CustomerAttendantListComponent implements OnInit {
  public headersTable: string[] = ['firstName', 'lastName', 'phoneNumber', 'actions']
  public attendant: any[] = []

  constructor (
    public customerAttendantService: CustomerAttendantService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.attendants()
  }

  attendants () {
    this.customerAttendantService.list().then(
      response => {
        this.attendant = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Atendente?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.customerAttendantService.delete(id).then(
            async response => {
              this.attendants()
              this.snackBar.open('Atendente removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
