import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { lyl } from '@alyle/ui';
import { LyDialogRef } from '@alyle/ui/dialog';
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { STYLES as CROPPER_STYLES, LyImageCropper, } from '@alyle/ui/image-cropper';
var STYLES = function (_theme, ref) {
    ref.renderStyleSheet(SLIDER_STYLES);
    ref.renderStyleSheet(CROPPER_STYLES);
    var slider = ref.selectorsOf(SLIDER_STYLES);
    var cropper = ref.selectorsOf(CROPPER_STYLES);
    return {
        root: lyl(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["{\n      ", " {\n        max-width: calc(40 * 16px)\n        height: calc(40 * 9px)\n      }\n    }"], ["{\n      ", " {\n        max-width: calc(40 * 16px)\n        height: calc(40 * 9px)\n      }\n    }"])), cropper.root),
        sliderContainer: lyl(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["{\n      position: relative\n      ", " {\n        width: 80%\n        position: absolute\n        left: 0\n        right: 0\n        margin: auto\n        top: -32px\n      }\n    }"], ["{\n      position: relative\n      ", " {\n        width: 80%\n        position: absolute\n        left: 0\n        right: 0\n        margin: auto\n        top: -32px\n      }\n    }"])), slider.root),
        slider: lyl(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["{\n      padding: 1em\n    }"], ["{\n      padding: 1em\n    }"]))),
    };
};
var ɵ0 = STYLES;
var CropperDialogComponent = /** @class */ (function () {
    function CropperDialogComponent(event, sRenderer, dialogRef, imageCompress, snackBar) {
        this.event = event;
        this.sRenderer = sRenderer;
        this.dialogRef = dialogRef;
        this.imageCompress = imageCompress;
        this.snackBar = snackBar;
        this.classes = this.sRenderer.renderSheet(STYLES, 'root');
        this.config = {};
        this.myConfig = {
            width: 38 * 16,
            height: 38 * 9,
            // type: 'image/png',
            keepAspectRatio: true,
            output: {
                width: 38 * 16,
                height: 38 * 9,
            },
            resizableArea: false,
            extraZoomOut: true,
        };
        if ('width' in this.event.config) {
            this.myConfig.width = this.event.config.width;
            this.myConfig.output.width = this.event.config.width;
        }
        if ('height' in this.event.config) {
            this.myConfig.height = this.event.config.height;
            this.myConfig.output.height = this.event.config.height;
        }
    }
    CropperDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.event.img.type) {
            this.dialogRef.afterOpened.subscribe(function () {
                _this.cropper.selectInputEvent(_this.event.img);
            });
        }
        else {
            if (this.event.config &&
                'scale' in this.event.config &&
                'xOrigin' in this.event.config &&
                'yOrigin' in this.event.config) {
                this.cropper.setImageUrl(this.event.img, function () {
                    _this.cropper.setScale(_this.event.config.scale, true);
                    _this.cropper.updatePosition(_this.event.config.xOrigin, _this.event.config.yOrigin);
                });
            }
            else {
                this.cropper.setImageUrl(this.event.img);
            }
        }
    };
    CropperDialogComponent.prototype.close = function () {
        var result = { img: this.cropper.crop(), config: this.config };
        this.dialogRef.close(result);
    };
    CropperDialogComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    CropperDialogComponent.prototype.onCropped = function (e) {
        var _this = this;
        this.config = { xOrigin: e.xOrigin, yOrigin: e.yOrigin, scale: e.scale };
        this.imageCompress
            .compressFile(e.dataURL, -2, 50, 50)
            .then(function (result) {
            _this.imgResultAfterCompress = result;
        })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    CropperDialogComponent.prototype.onLoaded = function (e) {
        console.log('img loaded', e);
    };
    CropperDialogComponent.prototype.onError = function (e) {
        // this.snackBar.open(error.message)
        console.warn("'" + e.name + "' is not a valid image", e);
        // Close the dialog if it fails
        this.dialogRef.close();
    };
    return CropperDialogComponent;
}());
export { CropperDialogComponent };
export { ɵ0 };
var templateObject_1, templateObject_2, templateObject_3;
