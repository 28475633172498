import { PipeTransform } from '@angular/core';
var TransactionsLengthPipe = /** @class */ (function () {
    function TransactionsLengthPipe() {
    }
    TransactionsLengthPipe.prototype.transform = function (value) {
        if (!value) {
            return '-';
        }
        try {
            var parsedArray = JSON.parse(value);
            return Array.isArray(parsedArray) ?
                parsedArray.filter(function (transaction) { return transaction.isInternational === true; }).length + " de " + parsedArray.length : '-';
        }
        catch (e) {
            console.error('Invalid JSON string:', value);
            return '-';
        }
    };
    return TransactionsLengthPipe;
}());
export { TransactionsLengthPipe };
