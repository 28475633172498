import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
var AnswerCustomerListComponent = /** @class */ (function () {
    function AnswerCustomerListComponent(router, route, snackBar, dialog, quizService, storeService, location) {
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.quizService = quizService;
        this.storeService = storeService;
        this.location = location;
        this.customerID = '';
        this.quizID = '';
        this.nameQuiz = '';
        this.fullName = '';
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    AnswerCustomerListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.customerID = this.route.snapshot.paramMap.get('customerID');
        this.quizID = this.route.snapshot.paramMap.get('quizID');
        if (this.quizID) {
            this.quizService.findOne(this.quizID).then(function (response) {
                _this.nameQuiz = response.name;
            }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.customerID) {
            this.storeService.listUsersCustomers("/" + this.customerID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.fullName = response.firstName + "  " + response.lastName;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
        this.quizanswersAndQuestions();
    };
    AnswerCustomerListComponent.prototype.convertAnswer = function (view, answer) {
        switch (view) {
            case 'DATE':
                return moment(answer).format('LL');
                break;
            case 'DATE-TIME':
                return moment(answer).format('LLL');
                break;
            case 'TIME':
                return moment(answer).format('LT');
                break;
            default:
                return answer;
        }
    };
    AnswerCustomerListComponent.prototype.quizanswersAndQuestions = function () {
        var _this = this;
        this.quizService.listanswersAndQuestions(this.quizID, "?customerId=" + this.customerID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.answersAndQuestions = response.questions;
                this.pageTotal = this.answersAndQuestions.length;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    AnswerCustomerListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    AnswerCustomerListComponent.prototype.backPage = function () {
        this.location.back();
    };
    return AnswerCustomerListComponent;
}());
export { AnswerCustomerListComponent };
