import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { QuizService } from '../../../../services/quiz.service'
import { MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss'],
})
export class TriggerComponent implements OnInit {
  public formGroup: FormGroup
  public quizzes: any[]
  public headersTable: string[]
  public trigger = 'CADASTRO DO USUÁRIO'

  constructor (
    private readonly formBuilder: FormBuilder,
    public quizService: QuizService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) {}

  ngOnInit () {
    this.headersTable = ['triggers', 'quizzes']
    this.formGroup = this.formBuilder.group({
      quizID: [null, [Validators.required]],
    })

    this.quizzesList()
    this.getTrigger()
  }

  quizzesList () {
    this.quizService.list().then(
      async response => {
        this.quizzes = response.results.filter(q => q.questionCount > 0)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  getTrigger () {
    this.quizService.getTrigger().then(
      async response => {
        const id = response.map(c => c.quizId)
        this.formGroup.patchValue({
          quizID: id[0],
        })
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    const data = {
      trigger: 'SIGNUP',
      quizId: this.formGroup.value.quizID,
    }

    this.quizService.createTrigger(data).then(
      async response => {
        this.snackBar.open('Gatilho cadastrado com sucesso.')
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
