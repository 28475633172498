<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVA CONTA BANCÁRIA</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores/{{ storeID }}/bank-account"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <input type="text"
                placeholder="Banco"
                matInput
                formControlName="bankCode"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBankCode">
                <mat-option *ngFor="let bank of bankAccountsOptions | async" [value]="bank">
                  {{ bank.code }} - {{ bank.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="formGroup.controls.bankCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Agência</mat-label>
              <input matInput formControlName="agency" />
              <mat-error *ngIf="formGroup.controls.agency?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.agency) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Conta</mat-label>
              <input matInput formControlName="accountNumber" />
              <mat-error *ngIf="formGroup.controls.accountNumber?.invalid">
                {{
                  errorsService.messageErrorFor(
                    formGroup.controls.accountNumber
                  )
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Dígito</mat-label>
              <input matInput formControlName="accountDigit" />
              <mat-error *ngIf="formGroup.controls.accountDigit?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.accountDigit)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="accountType">
                <mat-option [value]="'CHECKING'">Corrente</mat-option>
                <mat-option [value]="'SAVINGS'">Poupança</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.accountType?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.accountType)
                }}
              </mat-error>
            </mat-form-field>

          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>
          Salvar
        </button>
      </div>
    </mat-card>
  </form>
</app-logged>
