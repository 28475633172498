<main class="modal">
  <a class="link-cancel" mat-icon-button matTooltip="Cancelar" (click)="cancel()" matTooltipPosition="left">
    <mat-icon>close</mat-icon>
  </a>

  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h4>Qual campanha será aplicada ao parceiro?</h4>
        <form [formGroup]="formGroup" (ngSubmit)="submit()">
          <div class="col-12 col-sm-12">
            <mat-card-content>
              <mat-form-field>
                <span>Campanhas padrão</span>
                <mat-label>Campanhas padrão</mat-label>
                <mat-select formControlName="defaultCampaignId" required>
                  <mat-option *ngFor="let campaign of data.campaigns.default" [value]="campaign.id">
                    {{ campaign.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card-content>
          </div>
          <div class="col-12 col-sm-12">
            <mat-card-content>
              <span>Campanhas extras</span>
              <mat-form-field>
                <mat-label>Campanhas extras</mat-label>
                <mat-select formControlName="campaignId">
                  <mat-option *ngFor="let campaign of data.campaigns.extra" [value]="campaign.id">
                    {{ campaign.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card-content>
          </div>
          <div mat-dialog-actions>
            <button class="btn-primary-light" type="submit" mat-button>Enviar</button>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
</main>
