<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Subcategoria</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput formControlName="heading" />
              <mat-error *ngIf="formGroup.controls.heading?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.heading) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Posição / Ordem</mat-label>
              <input type="number" matInput formControlName="order" />
              <mat-error *ngIf="formGroup.controls.order?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.order) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Ícone da Subcategoria</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputMenuIconUrl.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img *ngIf="menuIconUrl != ''" width="200" src="{{ menuIconUrl }}" />
              <input
                hidden
                (change)="selectFile($event, 'menuIconUrl')"
                #fileInputMenuIconUrl
                type="file"
                id="file"
              />
              <!-- <mat-error *ngIf="formGroup.controls.menuIconUrl?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.menuIconUrl) }}
              </mat-error> -->
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="menuIconUrlInvalid"
              >Campo deve ser preenchido</mat-error
            >

            <mat-form-field>
              <mat-label>Banner da Subcategoria</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputPartnerBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="editPartnerBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="removePartnerBannerImage()"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedPartnerBannerImage != ''"
                width="590"
                src="{{ croppedPartnerBannerImage }}"
              />
              <input
                hidden
                (change)="selectFilePartnerBanner($event)"
                #fileInputPartnerBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner link</mat-label>
              <input matInput formControlName="bannerLink" />
              <mat-error *ngIf="formGroup.controls.bannerLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bannerLink) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>flash sales da Subcategoria</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputFlashSales.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedFlashSalesImage"
                matSuffix
                mat-icon-button
                (click)="editFlashSalesImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedFlashSalesImage"
                matSuffix
                mat-icon-button
                (click)="removeFlashSalesImage()"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedFlashSalesImage != ''"
                width="590"
                src="{{ croppedFlashSalesImage }}"
              />
              <input
                hidden
                (change)="selectFileFlashSales($event)"
                #fileInputFlashSales
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>flash sales link</mat-label>
              <input matInput formControlName="flashSalesLink" />
              <mat-error *ngIf="formGroup.controls.flashSalesLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.flashSalesLink) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <div class="check">
            <input formControlName="isActive" type="checkbox" />
            <mat-label> &nbsp;Ativo</mat-label>
          </div>
          <div class="check">
            <input formControlName="showMain" type="checkbox" />
            <mat-label> &nbsp;Exibir Principais Parceiros</mat-label>
          </div>
          <div class="check">
            <input formControlName="isParking" type="checkbox" />
            <mat-label> &nbsp;Estacionamento</mat-label>
          </div>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
