import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'

import { AuthService } from '../../../../../services/auth.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { Location } from '@angular/common'
@Component({
  selector: 'app-client-address-list',
  templateUrl: './client-address-list.component.html',
  styleUrls: ['./client-address-list.component.scss'],
})
export class ClientAddressListComponent implements OnInit {
  public headersTable: string[] = ['addressTitle', 'address', 'kind', 'actions']
  public customerID: string
  public customer: string
  public architect: any
  dataSource: any
  public params = { formGroupValue: [] }

  constructor (
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    public router: Router,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.customerID = this.route.snapshot.paramMap.get('customerID')
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.authService.customerUserInfo(this.customerID).subscribe(
      async response => {
        this.customer = response.firstName + ' ' + response.lastName
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    this.clientAddressList()
  }

  clientAddressList () {
    this.authService.clientAddressList(this.customerID).then(
      async response => {
        this.dataSource = new MatTableDataSource(response)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertKind (kind) {
    switch (kind) {
      case 'HOME':
        return 'Residencial'
        break
      case 'COMPANY':
        return 'Comercial'
        break
      default:
        return ''
    }
  }

  goBack () {
    // this.location.back()
    if (this.architect) {
      this.router.navigate(['./dashboard/clients-architect/architect'])
    } else {
      this.router.navigate(['./dashboard/clients'])
    }
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse endereço?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.delete(id, this.customerID).then(
            async response => {
              this.clientAddressList()
              this.snackBar.open('Endereço removido com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
