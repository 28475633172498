<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">CADASTRO DA PERGUNTA</h2>
    <h2 *ngIf="id">EDIÇÃO DA PERGUNTA</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/quiz/{{ quizID }}/questions/list"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações da Pergunta</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Pergunta</mat-label>
              <input matInput formControlName="question" placeholder="Pergunta" id="question" />
              <mat-error *ngIf="formGroup.controls.question?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.question) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tipo de Resposta</mat-label>
              <mat-select
                formControlName="typeQuestion"
                (selectionChange)="changeTypeQuestion($event)"
                id="typeQuestion"
              >
                <mat-option [value]="'TEXT'">Texto</mat-option>
                <mat-option [value]="'NUMBER'">Número</mat-option>
                <mat-option [value]="'DATE'">Data</mat-option>
                <mat-option [value]="'SINGLE-CHOICE'">Única escolha</mat-option>
                <mat-option [value]="'MULTIPLE-CHOICE'">Mútipla escolha</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.typeQuestion?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.typeQuestion) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeQuestionSelected && textOption">
              <mat-label>Forma de Exibição</mat-label>
              <mat-select formControlName="view" id="view">
                <mat-option [value]="'SINGLE-LINE'">Única linha</mat-option>
                <mat-option [value]="'MULTIPLE-LINES'">Múltiplas linhas</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.view?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.view) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeQuestionSelected && numberOption">
              <mat-label>Forma de Exibição</mat-label>
              <mat-select formControlName="view" id="view">
                <mat-option [value]="'INPUT'">Livre digitação</mat-option>
                <mat-option [value]="'BUTTONS-DEGREE'">Botões +/-</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.view?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.view) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeQuestionSelected && dateOption">
              <mat-label>Forma de Exibição</mat-label>
              <mat-select formControlName="view" id="view">
                <mat-option [value]="'DATE'">Data</mat-option>
                <mat-option [value]="'TIME'">Hora</mat-option>
                <mat-option [value]="'DATE-TIME'">Data e Hora</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.view?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.view) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeQuestionSelected && singleChoiceOption">
              <mat-label>Forma de Exibição</mat-label>
              <mat-select formControlName="view" id="view">
                <mat-option [value]="'BUTTONS'">Botões</mat-option>
                <mat-option [value]="'RADIOBOX'">Radiobox</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.view?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.view) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeQuestionSelected && multipleChoiceOption">
              <mat-label>Forma de Exibição</mat-label>
              <mat-select formControlName="view" id="view">
                <mat-option [value]="'BUTTONS'">Botões</mat-option>
                <mat-option [value]="'CHECKBOX'">Checkbox</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.view?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.view) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="typeOption" class="example-chip-list">
              <mat-label>Opções</mat-label>
              <mat-chip-list #chipList aria-label="Option selection">
                <mat-chip
                  *ngFor="let option of options"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(option)"
                >
                  {{ option }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  id="options"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)"
                />
              </mat-chip-list>
            </mat-form-field>
            <mat-error *ngIf="!optionInvalid">Campo deve ser preenchido</mat-error>
            <!-- <mat-form-field>
              <mat-label>Step</mat-label>
              <input type="number" matInput formControlName="step" placeholder="Step" id="step" />
              <mat-error *ngIf="formGroup.controls.step?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.step) }}
              </mat-error>
            </mat-form-field> -->
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
