import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var CustomerAttendantListComponent = /** @class */ (function () {
    function CustomerAttendantListComponent(customerAttendantService, snackBar, dialog) {
        this.customerAttendantService = customerAttendantService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['firstName', 'lastName', 'phoneNumber', 'actions'];
        this.attendant = [];
    }
    CustomerAttendantListComponent.prototype.ngOnInit = function () {
        this.attendants();
    };
    CustomerAttendantListComponent.prototype.attendants = function () {
        var _this = this;
        this.customerAttendantService.list().then(function (response) {
            _this.attendant = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    CustomerAttendantListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Atendente?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.customerAttendantService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.attendants();
                                this.snackBar.open('Atendente removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return CustomerAttendantListComponent;
}());
export { CustomerAttendantListComponent };
