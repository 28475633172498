<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">Cadastro de Empresa</h2>
    <h2 *ngIf="id">Editar Empresa</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-companies">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" required />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome da Empresa</mat-label>
              <input matInput formControlName="companyName" required />
              <mat-error *ngIf="formGroup.controls.companyName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.companyName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome do responsavel</mat-label>
              <input matInput formControlName="responsibleName" required />
              <mat-error *ngIf="formGroup.controls.responsibleName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.responsibleName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
                (change)="onChangePhone()"
              >
              </ngx-mat-intl-tel-input>
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="phoneNumberInvalid"
              >Campo deve ser preenchido</mat-error
            >

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" required />
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input matInput formControlName="zipCode" (blur)="loadAddress()" mask="00000-000" />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="addressNumber" />
              <mat-error *ngIf="formGroup.controls.addressNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.addressNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="complement" />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Estado</mat-label>
              <input matInput formControlName="state" />
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field> -->
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbr">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Contrato social (PDF)</mat-label>
              <input matInput disabled required />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <span *ngIf="approveDoc" class="filename-placeholder">
                <mat-icon>description</mat-icon> {{ this.approveDoc }}
              </span>
              <div>
                <span class="remove-doc-wrapper" style="margin-right: 5px; margin-left: 20px">
                  <button
                    class="btn-primary-light"
                    mat-button
                    *ngIf="documentUrl"
                    (click)="download($event)"
                  >
                    Download
                  </button>
                </span>
                <span class="remove-doc-wrapper">
                  <button
                    class="btn-primary-light"
                    mat-button
                    *ngIf="approveDoc"
                    (click)="removeDocument()"
                  >
                    <mat-icon>cancel</mat-icon> Remover documento
                  </button>
                </span>
              </div>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="socialContractInvalid"
              >Campo deve ser preenchido</mat-error
            >
            <mat-form-field>
              <mat-label>Tempo de atuação</mat-label>
              <mat-select formControlName="operatingTime" required>
                <mat-option [value]="'0to5'">Até 05 anos</mat-option>
                <mat-option [value]="'06to10'">De 06 á 10 anos</mat-option>
                <mat-option [value]="'11to15'">De 11 á 15 anos</mat-option>
                <mat-option [value]="'16to20'">De 16 á 20 anos</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.operatingTime?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.operatingTime) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Telefone comercial</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="commercialPhone"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
                required
                (change)="onChange()"
              >
              </ngx-mat-intl-tel-input>
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="commercialPhoneInvalid"
              >Campo deve ser preenchido</mat-error
            >
            <mat-form-field>
              <mat-label>Segmento</mat-label>
              <mat-select formControlName="segment" required (selectionChange)="others($event.value)">
                <mat-option [value]="'ARCHITECTURE'">Arquitetura</mat-option>
                <mat-option [value]="'DECORATION'">Decoração</mat-option>
                <mat-option [value]="'INTERIOR_DESIGN'">Design de interiores</mat-option>
                <mat-option [value]="'LANDSCAPING'">Paisagismo</mat-option>
                <mat-option [value]="'OTHERS'">Outros</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.segment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.segment) }}
              </mat-error>
            </mat-form-field>


            <mat-form-field *ngIf="otherSegment">
              <mat-label>Outro Segmento</mat-label>
              <input matInput formControlName="otherSegment" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Indicação</mat-label>
              <input matInput disabled formControlName="storeReferralName" style="color: black" />
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
