import { Component, OnInit, enableProdMode } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatChipInputEvent } from '@angular/material/chips'
import { Location } from '@angular/common'

import { ErrorsService } from '../../../../../../../src/app/core/services/errors.service'
import { QuestionsService } from '../../../../../services/questions.service'

@Component({
  selector: 'app-questions-form',
  templateUrl: './questions-form.component.html',
  styleUrls: ['./questions-form.component.scss'],
})
export class QuestionsFormComponent implements OnInit {
  public formGroup: FormGroup
  public quizID = ''
  public id = ''
  public typeQuestionSelected = false
  public typeOption = false
  public dateOption = false
  public textOption = false
  public singleChoiceOption = false
  public multipleChoiceOption = false
  public numberOption = false
  public optionInvalid = true

  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA]
  options: any[] = []

  constructor(
    private readonly formBuilder: FormBuilder,
    public router: Router,
    private readonly route: ActivatedRoute,
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    public questionsService: QuestionsService,
    public location: Location,
  ) {}

  ngOnInit() {
    this.quizID = this.route.snapshot.paramMap.get('quizID')
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      question: [null, [Validators.required]],
      typeQuestion: [null, [Validators.required]],
      view: [null, [Validators.required]],
      option: [null, []],
      // step: [null, [Validators.required]],
    })

    if (this.id) {
      this.getById()
    }
  }

  changeTypeQuestion(event) {
    this.typeQuestionSelected = true

    if (
      event.value === 'SINGLE-CHOICE' ||
      event.value === 'MULTIPLE-CHOICE' ||
      event === 'SINGLE-CHOICE' ||
      event === 'MULTIPLE-CHOICE'
    ) {
      this.typeOption = true
    } else {
      this.typeOption = false
    }

    switch (event.value || event) {
      case 'TEXT':
        this.textOption = true
        this.numberOption = false
        this.dateOption = false
        this.singleChoiceOption = false
        this.multipleChoiceOption = false
        break
      case 'NUMBER':
        this.textOption = false
        this.numberOption = true
        this.dateOption = false
        this.singleChoiceOption = false
        this.multipleChoiceOption = false
        break
      case 'DATE':
        this.textOption = false
        this.numberOption = false
        this.dateOption = true
        this.singleChoiceOption = false
        this.multipleChoiceOption = false
        break
      case 'SINGLE-CHOICE':
        this.textOption = false
        this.numberOption = false
        this.dateOption = false
        this.singleChoiceOption = true
        this.multipleChoiceOption = false
        break
      case 'MULTIPLE-CHOICE':
        this.textOption = false
        this.numberOption = false
        this.dateOption = false
        this.singleChoiceOption = false
        this.multipleChoiceOption = true
        break
    }
  }

  getById() {
    this.questionsService.findOne(this.quizID, this.id).then(
      response => {
        this.changeTypeQuestion(response.type)
        this.formGroup.patchValue({
          question: response.ask,
          typeQuestion: response.type,
          view: response.view,
          step: response.step,
        })
        this.options = response.options.length ? response.options.split(';') : []
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  submit() {
    if (
      (this.formGroup.value.typeQuestion === 'SINGLE-CHOICE' && this.options.length === 0) ||
      (this.formGroup.value.typeQuestion === 'MULTIPLE-CHOICE' && this.options.length === 0)
    ) {
      this.optionInvalid = false
    }

    if (!this.formGroup.valid || !this.optionInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    const data = {
      ask: this.formGroup.value.question,
      type: this.formGroup.value.typeQuestion,
      view: this.formGroup.value.view,
      options: this.options.length ? this.options.join(';') : '',
      isActive: true,
    }
    if (this.id) {
      this.questionsService.update(this.quizID, this.id, data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Pergunta alterada com sucesso.')
        },
        error => {
          this.snackBar.open(error.message)
        },
      )
    } else {
      this.questionsService.create(this.quizID, data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Pergunta cadastrada com sucesso.')
        },
        error => {
          this.snackBar.open(error.message)
        },
      )
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    if (value) {
      this.optionInvalid = true
      this.options.push(value.trim())
    }

    if (input) {
      input.value = ''
    }
  }

  remove(option): void {
    const index = this.options.indexOf(option)

    if (index >= 0) {
      this.options.splice(index, 1)
    }
  }
}
