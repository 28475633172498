import * as tslib_1 from "tslib";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoriesService = /** @class */ (function () {
    function CategoriesService(http) {
        this.http = http;
    }
    CategoriesService.prototype.listCategories = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/categories").pipe(tap(function (_) { return _this.log('*/categories'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.viewCategories = function (categoriesID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/categories/" + categoriesID).pipe(tap(function (_) { return _this.log('*/categories'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.addCategories = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/categories", data).pipe(tap(function (_) { return _this.log('cms/categories'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.upload = function (data) {
        var _this = this;
        var headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.http
            .post(environment.apiUrl + "/cms/categories/images", data, { headers: headers })
            .pipe(tap(function (_) { return _this.log('cms/categories/images'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.updateCategories = function (data, categoriesID) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/categories/" + categoriesID, data).pipe(tap(function (_) { return _this.log('cms/categories'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.deleteCategories = function (categoriesID) {
        var _this = this;
        return this.http.delete(environment.apiUrl + "/cms/categories/" + categoriesID).pipe(tap(function (_) { return _this.log('cms/categories'); }), catchError(this.handleError));
    };
    CategoriesService.prototype.getS3Url = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/categories/s3-slot").toPromise()];
            });
        });
    };
    CategoriesService.prototype.uploadToS3 = function (pathAwsUpload, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + pathAwsUpload, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CategoriesService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/categories/" + id).toPromise()];
            });
        });
    };
    CategoriesService.prototype.listSubCategories = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/categories/" + id + "/subcategories")
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.subcategoryFindOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/subcategories/" + id).toPromise()];
            });
        });
    };
    CategoriesService.prototype.deleteSubCategories = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/subcategories/" + id).toPromise()];
            });
        });
    };
    CategoriesService.prototype.addSubCategories = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/subcategories", data).toPromise()];
            });
        });
    };
    CategoriesService.prototype.updateSubCategories = function (data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/subcategories/" + id, data).toPromise()];
            });
        });
    };
    CategoriesService.prototype.listExclusives = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/category/" + id + "/exclusive/icons")
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.deleteExclusive = function (exclusiveId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/category/exclusive/" + exclusiveId)
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.exclusiveGetS3Url = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/categories/exclusive/s3-slot").toPromise()];
            });
        });
    };
    CategoriesService.prototype.addExclusive = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/category/" + id + "/exclusive/icon", data)
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.exclusiveFindOne = function (exclusiveId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/category/exclusive/" + exclusiveId)
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.updateExclusive = function (exclusiveId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/category/exclusive/" + exclusiveId, data)
                        .toPromise()];
            });
        });
    };
    CategoriesService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    CategoriesService.prototype.log = function (message) { };
    CategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoriesService, providedIn: "root" });
    return CategoriesService;
}());
export { CategoriesService };
