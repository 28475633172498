import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
var NotificationCustomersComponent = /** @class */ (function () {
    function NotificationCustomersComponent(notificationService, snackBar, route, location) {
        this.notificationService = notificationService;
        this.snackBar = snackBar;
        this.route = route;
        this.location = location;
        this.headersTable = ['cpf', 'name'];
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    NotificationCustomersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.architect = this.route.snapshot.paramMap.get('architect');
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfNotificationCustomers',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameNotificationCustomers',
                label: 'Nome',
                placeholder: 'Nome',
            },
        ];
        this.notificationId = this.route.snapshot.paramMap.get('notificationID');
        this.notificationService.findOne(this.notificationId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.nameNotification = response.title;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.notificationCustomers();
    };
    NotificationCustomersComponent.prototype.notificationCustomers = function () {
        var _this = this;
        this.notificationService
            .notificationCustomersList(this.notificationId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = new MatTableDataSource(response.results);
                this.pageTotal = response.total;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    NotificationCustomersComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return NotificationCustomersComponent;
}());
export { NotificationCustomersComponent };
