import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Router } from '@angular/router'
@Component({
  selector: 'app-modal-installments',
  templateUrl: './modal-installments.component.html',
  styleUrls: ['./modal-installments.component.scss'],
})
export class ModalInstallmentsComponent {
  public readonly tableHeader = [
    'installmentNumber',
    'createdAt',
    'amount',
    'installmentValue',
  ]

  constructor (
    public dialogRef: MatDialogRef<ModalInstallmentsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      totalInstallments: number
      installments: Array<{
        flow: string
        state: string
        amount: number
        installmentNumber: number
        createdAt: string
        installmentValue: string
      }>
    },
    public router: Router,
  ) { }

  cancel (): void {
    this.dialogRef.close('cancel')
  }
}
