<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">CADASTRO DO TIPO DE NOTIFICAÇÃO</h2>
    <h2 *ngIf="id">EDIÇÃO DO TIPO DE NOTIFICAÇÃO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/notification-types">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" required />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="type" required>
                <mat-option [value]="'MANUAL'">Manual</mat-option>
                <mat-option [value]="'AUTOMATIC'">Automatico</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.type?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.type) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
