import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-transactions-c2c',
  templateUrl: './modal-form-transactions-c2c.component.html',
  styleUrls: ['./modal-form-transactions-c2c.component.scss'],
})
export class ModalFormTransactionsc2cComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public selectedStores: any[] = []
  public statusControl = new FormControl()
  public filter: string
  stores: any[] = []
  visible = true
  selectable = true
  filteredStores: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormTransactionsc2cComponent>,
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      // storesInput: [null, []],
    })

    // this.reportService.listStores().subscribe(
    //   response => {
    //     response.map(item => {
    //       this.stores.push(item)
    //     })
    //   },
    //   error => {
    //     console.log(error)
    //   },
    // )

    // this.filteredStores = this.storeControl.valueChanges.pipe(
    //   startWith(''),
    //   map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
    // )
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  // private _filterStores (store: string): string[] {
  //   const remove = String(store)

  //   if (typeof remove !== 'string') {
  //     return
  //   }

  //   return this.stores
  //     .filter(c => !this.selectedStores.includes(c))
  //     .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
  //     .slice(0, 20)
  // }

  // remove (value: any): void {
  //   if (value.nomeFantasia) {
  //     const index = this.selectedStores.indexOf(value)
  //     if (index >= 0) {
  //       this.selectedStores.splice(index, 1)
  //     }
  //   }
  // }

  // selected (event: MatAutocompleteSelectedEvent): void {
  //   if (event.option.value.nomeFantasia) {
  //     this.selectedStores.push(event.option.value)
  //     this.storeInput.nativeElement.value = ''
  //     this.storeControl.setValue(null)
  //   }
  // }

  submit () {
    // const storesIds = this.selectedStores.map(c => c.id)

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    console.log(this.filter)
    this.reportService
      .createReport(`transactions-c2c?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório de Transferência de Jcoins.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
