import { Component, OnInit } from '@angular/core'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-customersUnique',
  templateUrl: './modal-form-customersUnique.component.html',
  styleUrls: ['./modal-form-customersUnique.component.scss'],
})
export class ModalCustomersUniqueComponent implements OnInit {
  public formGroup: FormGroup
  public kindInvalid = false
  public filter: string
  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalCustomersUniqueComponent>,
  ) {}

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      endDate: [null, [Validators.required]],
      beginDate: [null, [Validators.required]],
      kind: [null, []],
    })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  onChange () {
    this.kindInvalid = !this.formGroup.value.kind
  }

  submit () {
    this.kindInvalid = !this.formGroup.value.kind
    if (!this.formGroup.valid || this.kindInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.formGroup.value.kind === 'subStore') {
      this.filter = `customer-unique?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}&subStore=true`
    } else if (this.formGroup.value.kind === 'macroGroup') {
      this.filter = `customer-unique?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}&macroGroup=true`
    }
    this.reportService
      .createReport(this.filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return (
              'Relatório de clientes únicos.' + response.fileUrl.split('.').splice(5, 5).join('.')
            )
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
