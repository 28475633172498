import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var ExclusiveListComponent = /** @class */ (function () {
    function ExclusiveListComponent(categoriesService, snackBar, location, dialog, route) {
        this.categoriesService = categoriesService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.route = route;
        this.headersTable = ['description', 'status', 'actions'];
        this.exclusives = [];
    }
    ExclusiveListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.categoryId = this.route.snapshot.paramMap.get('categoryID');
        this.categoriesService.findOne(this.categoryId).then(function (response) {
            _this.category = response.heading;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
        this.listCategories();
    };
    ExclusiveListComponent.prototype.listCategories = function () {
        var _this = this;
        this.categoriesService.listExclusives(this.categoryId).then(function (response) {
            _this.exclusives = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ExclusiveListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Icone ?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.categoriesService.deleteExclusive(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listCategories();
                                this.snackBar.open('Icone removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return ExclusiveListComponent;
}());
export { ExclusiveListComponent };
