<div>
  <div>
    Nome: {{transfer.storeName}}
  </div>
  <div>
    Cnpj: {{transfer.storeCnpj | mask : '00.000.000/0000-00'}}
  </div>

  <div>
    Valor: {{transfer.totalValue | currency : 'R$'}}
  </div>
  <div>
    Valor Bruto: {{transfer.grossValue | currency : 'R$'}}
  </div>
  <div>
    Status da transferencia:  {{ transfer.success === true ?
      "Concluído" : (
        transfer.success === false ?
        "Erro"
      : "Pendente" )
    }}
  </div>
  <div *ngIf="transfer.executedAt">
    Executado em: {{transfer.executedAt | date : 'dd/MM/yyyy'}}
  </div>


  <div>
    Banco emissor: {{transfer.integrationThrough}}
  </div>

  <div>Dados bancários do parceiro</div>
  <div>
    Número do banco: {{transfer.bankNumber}}
  </div>
  <div>
    Número da conta: {{transfer.bankAccountNumber}} - {{transfer.bankAccountDigit}}
  </div>
 
  <div>
    Agência: {{transfer.bankAgency}}
  </div>
  <div>
    Tipo da conta: {{transfer.bankAccountType}}
  </div>

</div>
