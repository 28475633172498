import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
import * as XLSX from 'xlsx';
var DocumentsEligibleListComponent = /** @class */ (function () {
    function DocumentsEligibleListComponent(airportsService, snackBar, dialog, router) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.router = router;
        this.headersTable = ['prefix', 'actions'];
        this.dynamicMask = '';
        this.isImport = false;
    }
    DocumentsEligibleListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
    };
    DocumentsEligibleListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var items;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .documentsEligibles()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        items = _a.sent();
                        this.dataSource = new MatTableDataSource(items.results);
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsEligibleListComponent.prototype.addMask = function (document) {
        var apenasNumeros = document.replace(/\D/g, '');
        if (apenasNumeros.length === 11) {
            return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        else if (apenasNumeros.length > 11) {
            return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        return apenasNumeros;
    };
    DocumentsEligibleListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse prefixo da aeronave',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.documentsEligibleDelete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (err) { console.log(err); });
                                this.snackBar.open('Prefixo da aeronave removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    DocumentsEligibleListComponent.prototype.onFileChange = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workBook, jsonData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                workBook = null;
                jsonData = null;
                this.reader = new FileReader();
                this.file = ev.target.files[0];
                if (this.file) {
                    this.reader.onload = function (event) {
                        var data = _this.reader.result;
                        workBook = XLSX.read(data, { type: 'binary' });
                        jsonData = workBook.SheetNames.reduce(function (initial, name) {
                            var sheet = workBook.Sheets[name];
                            initial = XLSX.utils.sheet_to_json(sheet);
                            return initial;
                        }, {});
                        _this.isImport = true;
                        _this.prefixImport = jsonData.map(function (item) { return ({ prefix: item.PREFIXOS.toUpperCase() }); });
                        _this.dataSource = new MatTableDataSource(_this.prefixImport);
                    };
                    this.reader.readAsBinaryString(this.file);
                }
                return [2 /*return*/];
            });
        });
    };
    DocumentsEligibleListComponent.prototype.saveFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.airportsService.importimportPrefix({ prefixs: this.prefixImport }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(response === true)) return [3 /*break*/, 2];
                                this.snackBar.open('Prefixos cadastrados com sucesso.');
                                return [4 /*yield*/, this.router.navigate(['./dashboard/airports/not-eligible'])];
                            case 1:
                                _a.sent();
                                this.clear().catch(function (err) { return console.log(err); });
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    DocumentsEligibleListComponent.prototype.clear = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.list().catch(function (err) { console.log(err); });
                this.isImport = false;
                this.InputVar.nativeElement.value = '';
                return [2 /*return*/];
            });
        });
    };
    return DocumentsEligibleListComponent;
}());
export { DocumentsEligibleListComponent };
