import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
    }
    AuthService.prototype.signin = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/auth/cms/signin", data).pipe(tap(function (_) { return _this.log('auth/signin'); }), catchError(this.handleError));
    };
    AuthService.prototype.passwordRecover = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/auth/cms/password/recover", data).pipe(tap(function (_) { return _this.log('auth/cms/password/recover'); }), catchError(this.handleError));
    };
    AuthService.prototype.resendCredentials = function (userID) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/cms/profiles/customers/" + userID + "/reset-password", {})
            .pipe(tap(function (_) { return _this.log('auth/cms/reset-password'); }), catchError(this.handleError));
    };
    AuthService.prototype.passwordUpdate = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/auth/cms/password/update", data).pipe(tap(function (_) { return _this.log('auth/cms/password/update'); }), catchError(this.handleError));
    };
    AuthService.prototype.emailConfirmation = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/auth/cms/signin", data).pipe(tap(function (_) { return _this.log('auth/signin'); }), catchError(this.handleError));
    };
    AuthService.prototype.signup = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/users", data).pipe(tap(function (_) { return _this.log('cms/users'); }), catchError(this.handleError));
    };
    AuthService.prototype.update = function (data, userID) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/users/" + userID, data).pipe(tap(function (_) { return _this.log('auth/update'); }), catchError(this.handleError));
    };
    AuthService.prototype.signupClient = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/profiles/signup", data).pipe(tap(function (_) { return _this.log('cms/profiles/signup'); }), catchError(this.handleError));
    };
    AuthService.prototype.userInfo = function (userID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/" + userID).pipe(tap(function (_) { return _this.log('cms/profiles'); }), catchError(this.handleError));
    };
    AuthService.prototype.getStoresByUser = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/stores").pipe(tap(function (_) { return _this.log('cms/profiles/stores'); }), catchError(this.handleError));
    };
    AuthService.prototype.customerUserInfo = function (customerID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/profiles/customers/" + customerID).pipe(tap(function (_) { return _this.log('cms/profiles/customers'); }), catchError(this.handleError));
    };
    AuthService.prototype.customerUpdate = function (customerID, data) {
        var _this = this;
        return this.http
            .put(environment.apiUrl + "/cms/profiles/customers/" + customerID, data)
            .pipe(tap(function (_) { return _this.log('cms/profiles/customers/update'); }), catchError(this.handleError));
    };
    AuthService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    AuthService.prototype.log = function (message) { };
    AuthService.prototype.clientAddressList = function (customerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/addresses/" + customerID)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.createAddress = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/addresses/", data).toPromise()];
            });
        });
    };
    AuthService.prototype.delete = function (addressId, customerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/addresses/" + addressId + "/customer/" + customerId)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.addressFindOne = function (addressesID, customerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/addresses/" + addressesID + "/customer/" + customerID)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.updateAddress = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/addresses/" + id, data).toPromise()];
            });
        });
    };
    AuthService.prototype.listMovementJcoins = function (cpf, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/customers/movement-jcoins/" + cpf + (filter || ''))
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.deleteCustomer = function (customerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/profiles/customer/" + customerId + "/remove")
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.lisCards = function (customerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/cards/costumer/" + customerId).toPromise()];
            });
        });
    };
    AuthService.prototype.getS3Url = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/profiles/s3-slot/" + type).toPromise()];
            });
        });
    };
    AuthService.prototype.uploadToS3 = function (pathAwsUpload, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + pathAwsUpload, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.clientKindList = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/profiles/customers/kind" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.clientKindDelete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/profiles/customers/kind/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.ClientKindFindOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/profiles/customers/kind/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.ClientKindUpdate = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/profiles/customers/kind/" + id, data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.ClientKindCreate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/profiles/customers/kind", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.importCustomers = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/profiles/customers/import-excel", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.clientsBeneficiaries = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/customers/beneficiaries" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.clientsBeneficiaryDelete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/customer/beneficiary/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.createBeneficiary = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/customer/beneficiary", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.updateBeneficiary = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/customer/beneficiary/" + id, data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.findOneBeneficiary = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/customer/beneficiary/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.clientsIncorporations = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/incorporations" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.closingsIntegrations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/incorporations/closings")
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.closingsAirportsIntegrations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/airports/closings")
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.closingIntegrationCreate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/incorporations/closings", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.closingAirportCreate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/airports/closings", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.closingsIntegrationsIncorporations = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/incorporations/closing-incorporations/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.deleteClosingIncorporations = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/incorporations/closing/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.deleteClosingAirport = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/airports/closing/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.documentsNotIntegration = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/cpf-incorporations")
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.createDocumentsNotIntegration = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/cpf-incorporation", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.updateDocumentsNotIntegration = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/cpf-incorporation/" + id, data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.findOneDocumentsNotIntegration = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/cpf-incorporation/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.deleteDocumentsNotIntegration = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/cpf-incorporation/" + id)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.importCustomersPreRegister = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/company-airport-customer/import-excel", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.deleteProfile = function (userId, storeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/profile?userId=" + userId + "&storeId=" + storeId).toPromise()];
            });
        });
    };
    AuthService.prototype.listProfilesDeleted = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/profiles-deleted/store/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.unrelateUserWithStoreBusinessUnit = function (userId, storeBusinessUnitId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/profile/store-business-units?userId=" + userId + "&storeBusinessUnitId=" + storeBusinessUnitId).toPromise()];
            });
        });
    };
    AuthService.prototype.findOneCustomerArchitect = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/customer-architects/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.customerUpdateArchitect = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/customer-architects/" + id, data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.listCustomerTerms = function (cpf) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/customer/terms/" + cpf).toPromise()];
            });
        });
    };
    AuthService.prototype.createCustomerTerm = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/customer/terms", data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.findOneCustomerTerm = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/customer/term/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.updateCustomerTerm = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/customer/term/" + id, data)
                        .toPromise()];
            });
        });
    };
    AuthService.prototype.deleteCustomerTerm = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/customer/term/" + id).toPromise()];
            });
        });
    };
    AuthService.prototype.searchCustomers = function (searchTerm, isArchitect) {
        var _this = this;
        if (isArchitect === void 0) { isArchitect = false; }
        return this.http.get(environment.apiUrl + "/cms/customers/search?searchTerm=" + searchTerm + "&isArchitect=" + isArchitect, {
            headers: new HttpHeaders({ noloading: 'true' }),
        })
            .pipe(tap(function (_) { return _this.log('cms/architect-invoices/comission-receivers-search'); }), catchError(this.handleError));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
