<app-logged class="store-list">
  <header class="store-list__header">
    <h2>NOTIFICAÇÕES DE FATURAS AMEX</h2>
  </header>
  <app-form-filter
    maskExample="000.000.000-00"
    actions="form"
    type="billPaymentNotification"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>IDs</th>
        <td mat-cell *matCellDef="let element">
          <p><b>Notificação:</b> {{ element.id }}</p>
          <p><b>Fatura:</b> {{ element.invoiceId }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="customerDocument">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.customerDocument | mask: '000.000.000-00' }}</td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Vencimento</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.dueDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="paidDate">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Pagamento</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.paidDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="invoiceValue">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Valor Fatura</th>
        <td mat-cell *matCellDef="let element" style="text-align: right">{{ element.invoiceValue / 100 | currency: 'R$' }}</td>
      </ng-container>
      <ng-container matColumnDef="paidValue">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Valor Pago</th>
        <td mat-cell *matCellDef="let element" style="text-align: right">{{ element.paidValue / 100 | currency: 'R$' }}</td>
      </ng-container>
      
      <ng-container matColumnDef="transactions">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Transações<br>Internacionais</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.transactions | transactionsLength }}</td>
      </ng-container>
      
      <ng-container matColumnDef="processedAt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Status</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <b [ngStyle]="{ 'color': element.processedAt ? 'green' : 'red' }" (click)="openModalErrorDetails(element)">
            {{ element.processedAt ? 'PROCESSADO' : 'FALHA' }}
            
            <button mat-icon-button *ngIf="!element.processedAt" matTooltip="{{ element.errorMessage }}">
              <mat-icon class="smallicon">info</mat-icon>
            </button>
          </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalInvoiceValuePaidAt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Valor total<br>pago</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <mat-icon *ngIf="element.totalInvoiceValuePaidAt" matTooltip="Pago em {{element.totalInvoiceValuePaidAt | date: 'dd/MM/yyyy'}}">
            check_circle_outline
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      actions="pagination"
      type="billPaymentNotification"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
