import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component';
import { ModalCancelTransactionComponent } from '../../../../components/modal-cancel-transaction/modal-cancel-transaction.component';
import { ModalCreateTransactionAnticipatedComponent } from '../../../../components/modal-create-transaction-anticipated/modal-create-transaction-anticipated.component';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var StorePaymentTransactionsListComponent = /** @class */ (function () {
    function StorePaymentTransactionsListComponent(location, route, transactionService, snackBar, dialog, router) {
        this.location = location;
        this.route = route;
        this.transactionService = transactionService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.router = router;
        this.paymentId = '';
        this.storeID = '';
        this.listStatusDefault = [];
        this.infoTransations = [];
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] };
    }
    StorePaymentTransactionsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paymentId = this.route.snapshot.paramMap.get('paymentID');
        this.userRole = localStorage.getItem('userRole');
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
            _this.params.itComesFromNewTransaction = params.itComesFromNewTransaction;
        });
        this.listInit().catch(function (err) { return console.log(err); });
        this.headersTable = [
            'transactionOrderNumber',
            'description',
            'transactionDate',
            'totalValue',
            'state',
            'actions',
        ];
        this.headersTableReserve = [
            'nomeFantasia',
            'cpf',
            'nameCustomer',
            'descriptionTransaction',
            'paymentDate',
            'totalValueAnticipated',
            'totalValue',
            'state',
        ];
        this.transactionService.getStatus().subscribe(function (response) {
            var status = response;
            var wateringConfrmation = { WateringConfrmation: 'AGUARDANDO CONFIRMAÇÕES' };
            _this.listStatusDefault = Object.assign(status, wateringConfrmation);
        });
    };
    StorePaymentTransactionsListComponent.prototype.listInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.transactionService.transactionsReserveFindOne(this.paymentId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                response.results.map(function (i) {
                                    _this.totalValue = i.totalValue;
                                    _this.reserveInCents = i.reserveInCents;
                                    _this.paymentDate = i.paymentDate;
                                    _this.fullName = i.fullName;
                                    _this.cpf = i.cpf;
                                    _this.maximumInstallmentsTransaction = i.maximumInstallmentsTransaction;
                                    _this.descriptionPayment = i.descriptionPayment;
                                    _this.storeID = i.storeId;
                                    _this.isMembershipActive = i.isMembershipActive;
                                    _this.isPayActive = i.isPayActive;
                                });
                                this.dataSourceReserve = new MatTableDataSource(response.results);
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.transactionService.transactionsListByReserve(this.paymentId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var totalTransaction;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    totalTransaction = 0;
                                    response.results.map(function (i) {
                                        _this.state = i.state;
                                        _this.transactionType = i.transactionType;
                                        _this.totalValueTransaction = i.totalValueTransaction;
                                        _this.maximumInstallments = i.maximumInstallments;
                                        _this.infoTransations.push(i);
                                        totalTransaction = numeral(totalTransaction).add(i.totalValueTransaction).value();
                                    });
                                    this.amountPayment = numeral(this.totalValue).subtract(totalTransaction).value();
                                    if (this.amountPayment > 0 &&
                                        this.state === 'APPROVED' &&
                                        this.transactionType === 'RESERVE') {
                                        this.infoTransations.push({
                                            transactionOrderNumber: '',
                                            description: this.descriptionPayment,
                                            transactionDate: this.paymentDate,
                                            totalValueTransaction: this.amountPayment,
                                            state: 'WateringConfrmation',
                                        });
                                    }
                                    this.dataSource = this.infoTransations;
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StorePaymentTransactionsListComponent.prototype.goBack = function () {
        if (this.params.itComesFromNewTransaction) {
            this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions', 'new'], { queryParams: this.params, skipLocationChange: true });
        }
        else {
            if (this.params.storeID) {
                this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions'], { queryParams: this.params, skipLocationChange: true });
            }
            else {
                this.router.navigate(['dashboard', 'transactions'], { queryParams: this.params, skipLocationChange: true });
            }
        }
    };
    StorePaymentTransactionsListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    StorePaymentTransactionsListComponent.prototype.view = function (transaction) {
        if (transaction.isManual) {
            localStorage.setItem('isManual', 'true');
        }
        else {
            localStorage.setItem('isManual', 'false');
        }
    };
    StorePaymentTransactionsListComponent.prototype.convertStatusTransactions = function (transaction) {
        if (transaction.isManual && transaction.state == 'PENDING') {
            return 'AGUARDANDO APROVAÇÃO DA JHSF';
        }
        else {
            return this.listStatusDefault[transaction.state];
        }
    };
    StorePaymentTransactionsListComponent.prototype.detail = function (transacationId, fullName, customerCpf) {
        var _this = this;
        if (this.userRole === 'ADMIN') {
            this.transactionService
                .listTransactionsById(transacationId + "?showManualDetails=true")
                .toPromise()
                .then(function (response) {
                _this.dialog.open(ModalCustomerInfoComponent, {
                    data: {
                        customerBalance: response.customerBalance,
                        customerCpf: response.customerCpf,
                        customerFullName: response.customerFirstName + " " + response.customerLastName,
                        customerBirthDate: response.customerBirthDate,
                        customerEmailAddress: response.customerEmailAddress,
                        customerPhoneNumber: response.customerPhoneNumber,
                        customerGender: response.customerGender,
                        user: _this.userRole,
                    },
                });
            }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            var dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    customerCpf: customerCpf,
                    customerFullName: fullName,
                    user: this.userRole,
                },
            });
        }
    };
    StorePaymentTransactionsListComponent.prototype.cancel = function (transactionID, reason) {
        var _this = this;
        var data = {
            reason: reason,
        };
        this.transactionService
            .cancelTransaction(transactionID, data)
            .toPromise()
            .then(function (response) {
            _this.snackBar.open('Transação cancelada com sucesso.');
            window.location.reload();
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StorePaymentTransactionsListComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.cancel(transaction.transactionId, result);
            }
        });
    };
    StorePaymentTransactionsListComponent.prototype.createTransaction = function (e) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCreateTransactionAnticipatedComponent, {
            width: '500px',
            data: {
                paymentDate: this.paymentDate,
                cpf: this.cpf,
                amountPayment: this.amountPayment,
                descriptionPayment: this.descriptionPayment,
                fullName: this.fullName,
            },
        });
        dialogRef
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var totalValue, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.createTransacation) return [3 /*break*/, 4];
                        totalValue = 0;
                        if (result.totalNewValue) {
                            totalValue = result.totalNewValue;
                            this.newValue = numeral(this.totalValueTransaction).add(result.totalNewValue).value();
                        }
                        else {
                            totalValue = this.amountPayment;
                        }
                        data = {
                            customerCpf: this.cpf,
                            totalValue: totalValue,
                            description: this.descriptionPayment,
                            maximumInstallments: parseInt(this.maximumInstallmentsTransaction),
                            isMembershipActive: this.isMembershipActive,
                            isPayActive: this.isPayActive,
                            storeId: this.storeID,
                            paymentIdTransacation: this.paymentId,
                        };
                        return [4 /*yield*/, this.transactionService
                                .addTransaction(data)
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (response.isCreated) {
                                        this.snackBar.open('Transação criada com sucesso.');
                                        this.router.navigate(['./dashboard/transactions']);
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        if (!this.newValue) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.transactionService
                                .paymentAlter(this.paymentId, this.newValue)
                                .then()
                                .catch(function (error) { return _this.snackBar.open(error.error.message); })];
                    case 2: return [4 /*yield*/, _a.sent()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (err) { return console.log(err); });
    };
    StorePaymentTransactionsListComponent.prototype.openDialogSendNotification = function (e) {
        var _this = this;
        var transactionId = e.transactionId, cpf = e.cpf, totalValue = e.totalValueTransaction;
        var dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: '400px',
            data: {
                text: 'Deseja reenviar essa transação para o cliente ?',
                buttonConfirmText: 'Sim',
            },
        });
        dialogRef
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result && result != 'cancel')) return [3 /*break*/, 2];
                        data = {
                            totalValue: totalValue,
                            customerCpf: cpf,
                        };
                        return [4 /*yield*/, this.transactionService.transactionSendEvent(transactionId, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Notificação enviada com sucesso.');
                                    this.router.navigate(['./dashboard/transactions']);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    return StorePaymentTransactionsListComponent;
}());
export { StorePaymentTransactionsListComponent };
