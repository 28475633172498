import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RegulationService } from 'src/app/services/regulation.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-store-list',
  templateUrl: './regulation-list.component.html',
  styleUrls: ['./regulation-list.component.scss'],
})
export class RegulationListComponent implements OnInit {
  public headersTable: string[] = ['heading', 'subtitle', 'createdAt', 'updatedAt', 'actions'];
  public regulations: Array<any> = [];

  constructor(
    public regulationService: RegulationService,    
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() { 
    localStorage.removeItem('regulationsInfos');
    this.listRegulations();
  }

  listRegulations() {
    this.regulationService.listRegulations().subscribe(
      async (response) => {
        this.regulations = response.results;
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message);
      }
    );
  }

  viewRegulation(regulation) {
    localStorage.setItem('regulationsInfos', JSON.stringify(regulation));
  }

  removeRegulation(regulationID) {

    this.regulationService.deleteRegulation(regulationID).subscribe(
      async (response) => {
        this.listRegulations();
        this.snackBar.open("Regulamento removido com sucesso.");
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message);
      }
    );
  }

  openRemoveDialog(regulationID) {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '500px',
      data: {
        text: 'Deseja remover esse regulamento?',
        buttonConfirmText: 'Remover'
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if ( result && result != 'cancel') {
        this.removeRegulation(regulationID)
      }
    })
  }
}

