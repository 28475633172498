import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-liquidation-transfer-detailed-report',
  templateUrl: './modal-form-liquidation-transfer-detailed-report.component.html',
  styleUrls: ['./modal-form-liquidation-transfer-detailed-report.component.scss'],
})
export class ModalFormLiquidationTransferDetailedReport implements OnInit {
  public formGroup: FormGroup
  

 

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormLiquidationTransferDetailedReport>,
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      startDueDate: [undefined, []],
      endDueDate: [undefined, []],
      startPaidAt: [undefined, []],
      endPaidAt: [undefined, []],
      isSuccess: [undefined, []],
      reason: [undefined, []],
      cnpj: [undefined, []],
    })   
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  async submit () {
    
    const data = {
      startDueDate: this.formGroup.value.startDueDate,
      endDueDate: this.formGroup.value.endDueDate,
      startPaidAt: this.formGroup.value.startPaidAt,
      endPaidAt: this.formGroup.value.endPaidAt,      
      reason: this.formGroup.value.reason,
      cnpj: this.formGroup.value.cnpj,
    }

    const filters: any =  Object.keys(data).reduce((acc, cur) => {
      if (!data[cur]) {
        return acc
      }
      return {
        ...acc,
        [cur]: data[cur],
      }
    }, {})

    if(this.formGroup.value.isSuccess !== undefined && this.formGroup.value.isSuccess !== null )
      filters.isSuccess = this.formGroup.value.isSuccess

    const result = await this.reportService.liquidationTransferDetailedReport(filters)
    new JsFileDownloader({
      url: result.fileUrl,     
    }).start()
  }
}
