import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { Params, Router, ActivatedRoute } from '@angular/router'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { ModalUserComponent } from 'src/app/components/modal-user/modal-user.component'
import { CompaniesService } from '../../../../../services/companies.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { AuthService } from '../../../../../services/auth.service'
import { StoreService } from 'src/app/services/store.service'

@Component({
  selector: 'architect-stores-user-list',
  templateUrl: './architect-stores-user-list.component.html',
  styleUrls: ['./architect-stores-user-list.component.scss'],
})
export class ArchitectStoresUserListComponent implements OnInit {
  public headersTable: string[] = ['fullName', 'phoneNumber', 'emailAddress', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public usersStoresId: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public companiesService: CompaniesService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    public storeService: StoreService,
  ) { }

  ngOnInit () {
    this.usersStoresId = this.route.snapshot.paramMap.get('id')
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'fullNameUserArchitectStores',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'emailUserArchitectStores',
        label: 'Email',
        placeholder: 'Email',
      },
    ]
  }

  async list () {
    const { usersStores, totalCount } = await this.companiesService
      .usersListStoresBusinessUnits(this.usersStoresId, `?&page=${this.page}&limit=${this.pageSize}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { usersStores: [], totalCount: 0 }
      })
    this.dataSource = new MatTableDataSource(usersStores)    
    this.pageTotal = totalCount
  }

  openDialogNewUser () {
    const dialogRef = this.dialog.open(ModalUserComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.storeService.getUserInfo(`email=${encodeURIComponent(result)}`).toPromise()
          .then(res => {            
            this.router.navigate([`dashboard/architect-stores/${this.usersStoresId}/users/${res.userId}/edit`])            
          })
          .catch(err => {
            this.router.navigate(['./dashboard/architect-stores/' + this.usersStoresId + '/users/new/' + result])
          })
      }
    })
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Usuário?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.unrelateUserWithStoreBusinessUnit(id,this.usersStoresId).then(
            async response => {
              this.list().catch(error => console.log(error))
              this.snackBar.open('Usuário removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  openDialog (): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.router.navigate(['./dashboard/architect-store/new/' + result])
      }
    })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter.usersStores
    this.pageTotal = returnFilter.totalCount
    this.dataSource = new MatTableDataSource(returnFilter.usersStores)
    this.resultsAll = returnFilter.usersStores
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
