import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router) {
        this.router = router;
        this.isAuthenticated = false;
    }
    AuthGuardService.prototype.canActivate = function () {
        var token = localStorage.getItem('token');
        if (token) {
            this.isAuthenticated = true;
            return this.isAuthenticated;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
