import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var TriggerComponent = /** @class */ (function () {
    function TriggerComponent(formBuilder, quizService, snackBar, errorsService) {
        this.formBuilder = formBuilder;
        this.quizService = quizService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.trigger = 'CADASTRO DO USUÁRIO';
    }
    TriggerComponent.prototype.ngOnInit = function () {
        this.headersTable = ['triggers', 'quizzes'];
        this.formGroup = this.formBuilder.group({
            quizID: [null, [Validators.required]],
        });
        this.quizzesList();
        this.getTrigger();
    };
    TriggerComponent.prototype.quizzesList = function () {
        var _this = this;
        this.quizService.list().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.quizzes = response.results.filter(function (q) { return q.questionCount > 0; });
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    TriggerComponent.prototype.getTrigger = function () {
        var _this = this;
        this.quizService.getTrigger().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var id;
            return tslib_1.__generator(this, function (_a) {
                id = response.map(function (c) { return c.quizId; });
                this.formGroup.patchValue({
                    quizID: id[0],
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    TriggerComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var data = {
            trigger: 'SIGNUP',
            quizId: this.formGroup.value.quizID,
        };
        this.quizService.createTrigger(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Gatilho cadastrado com sucesso.');
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return TriggerComponent;
}());
export { TriggerComponent };
