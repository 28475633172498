import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var BannerPagesAppFormComponent = /** @class */ (function () {
    function BannerPagesAppFormComponent(errorsService, formBuilder, bannersService, snackBar, router, route, location, _dialog, _cd, categoriesService, bannersPagesAppService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.bannersService = bannersService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.location = location;
        this._dialog = _dialog;
        this._cd = _cd;
        this.categoriesService = categoriesService;
        this.bannersPagesAppService = bannersPagesAppService;
        this.showDates = false;
    }
    BannerPagesAppFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.initForm();
    };
    BannerPagesAppFormComponent.prototype.initForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            page: [null, [Validators.required]],
        });
        if (this.id) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
        if (this.page === 'category') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.category = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.page === 'subcategory') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.subcategory = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
    };
    BannerPagesAppFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.bannersPagesAppService.findOne(this.id).then(function (response) {
                    _this.formGroup.patchValue({
                        page: response.page,
                    });
                }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    BannerPagesAppFormComponent.prototype.onChange = function () {
        if (this.formGroup.value.isFixed) {
            this.showDates = true;
            this.formGroup.get('endAt').setValidators(null);
            this.formGroup.get('endAt').updateValueAndValidity();
            this.formGroup.get('endAt').setErrors(null);
            this.formGroup.get('initAt').setValidators(null);
            this.formGroup.get('initAt').updateValueAndValidity();
            this.formGroup.get('initAt').setErrors(null);
            this.formGroup.value.endAt = null;
            this.formGroup.value.initAt = null;
        }
        if (!this.formGroup.value.isFixed) {
            this.showDates = false;
        }
    };
    BannerPagesAppFormComponent.prototype.onChangeDate = function () {
        if (this.formGroup.value.initAt) {
            this.formGroup.get('endAt').setValidators([Validators.required]);
            this.formGroup.get('endAt').updateValueAndValidity();
        }
        if (this.formGroup.value.endAt) {
            this.formGroup.get('initAt').setValidators([Validators.required]);
            this.formGroup.get('initAt').updateValueAndValidity();
        }
    };
    BannerPagesAppFormComponent.prototype.selectFilePartnerBanner = function (event) {
        var _this = this;
        var originalPartnerBannerImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalPartnerBannerImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalPartnerBannerImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedPartnerBannerImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 1:
                        _a.partnerBannerImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    BannerPagesAppFormComponent.prototype.editPartnerBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalPartnerBannerImage) return [3 /*break*/, 1];
                        _a = this.originalPartnerBannerImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.partnerBannerImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 5:
                        _b.partnerBannerImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BannerPagesAppFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    BannerPagesAppFormComponent.prototype.removeImage = function (input) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (input === 'partnerBanner') {
                    this.readUrlPartnerBanner = '';
                    this.originalPartnerBannerImage = undefined;
                    this.croppedPartnerBannerImage = undefined;
                    this.partnerBannerImagePosition = undefined;
                    this.partnerBannerImageData = undefined;
                }
                return [2 /*return*/];
            });
        });
    };
    BannerPagesAppFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    BannerPagesAppFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    page: this.formGroup.value.page,
                };
                if (this.id) {
                    this.bannersPagesAppService.update(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.isUpdated) {
                                this.goBack();
                                this.snackBar.open('Página atualizada com sucesso.');
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.bannersPagesAppService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.isCreated) {
                                this.goBack();
                                this.snackBar.open('Página criada com sucesso.');
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return BannerPagesAppFormComponent;
}());
export { BannerPagesAppFormComponent };
