import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var BannerListComponent = /** @class */ (function () {
    function BannerListComponent(bannersService, categoriesService, bannersPagesAppService, snackBar, dialog, route, location) {
        this.bannersService = bannersService;
        this.categoriesService = categoriesService;
        this.bannersPagesAppService = bannersPagesAppService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.location = location;
        this.headersTable = ['order', 'description', 'initAt', 'endAt', 'isFixed', 'actions'];
        this.banners = [];
    }
    BannerListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.page = this.route.snapshot.paramMap.get('page');
        this.idPage = this.route.snapshot.paramMap.get('idPage');
        this.list();
        if (this.page === 'category') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.category = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.page === 'subcategory') {
            this.categoriesService.findOne(this.idPage).then(function (response) {
                _this.subcategory = response.heading;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
        if (this.page === 'home') {
            this.bannersPagesAppService.findOne(this.idPage).then(function (response) {
                _this.pages = response.page;
            }, function (error) {
                console.log(error);
                _this.snackBar.open(error.error.message);
            });
        }
    };
    BannerListComponent.prototype.list = function () {
        var _this = this;
        var filter;
        if (this.page === 'category') {
            filter = "?categoryId=" + this.idPage;
        }
        if (this.page === 'subcategory') {
            filter = "?categoryId=" + this.idPage;
        }
        if (this.page === 'home') {
            filter = "?appPageId=" + this.idPage;
        }
        this.bannersService.list(filter).then(function (response) {
            _this.banners = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    BannerListComponent.prototype.goBack = function () {
        this.location.back();
    };
    BannerListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Banner?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.bannersService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list();
                                this.snackBar.open('Banner removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return BannerListComponent;
}());
export { BannerListComponent };
