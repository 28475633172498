<app-logged class="campaign-form">
  <header class="campaign-form__header">
    <h2 *ngIf="!campaignID">CADASTRO DE CAMPANHA</h2>
    <h2 *ngIf="campaignID">EDIÇÃO DE CAMPANHA</h2>
    <div class="campaign-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/campaign"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="verifyCampaignInitialDate()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações da Campanha</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
              <mat-error *ngIf="formGroup.controls.description?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.description) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipos de campanha</mat-label>
              <mat-select formControlName="campaignTypes" required>
                <mat-option [value]="'ACCUMULATION'">Acúmulo</mat-option>
                <mat-option [value]="'REDEEM'">Resgate</mat-option>
                <mat-option [value]="'CREATE_CUSTOMER'">Cadastro de cliente</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Converter valor para dólar</mat-label>
              <mat-select
                formControlName="isConvertDollar"
                (selectionChange)="changeConvertDollarEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isConvertDollar?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isConvertDollar) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="formGroup.value.isConvertDollar">
              <mat-label>Fator de conversão</mat-label>
              <input mask="0.999" matInput formControlName="convertionFactor" />
              <mat-error *ngIf="formGroup.controls.convertionFactor?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.convertionFactor) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Permitir fator escalonado por valor de gasto</mat-label>
              <mat-select
                formControlName="spendingFactor"
                (selectionChange)="changeSpendingFactorEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.spendingFactor?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.spendingFactor) }}
              </mat-error>
            </mat-form-field>

            <div class="campaign-form__ckeditor" *ngIf="formGroup.value.spendingFactor">
              <mat-label>Fator escalonado por valor de gasto: </mat-label>
              <ckeditor
                [config]="config"
                [editor]="Editor"
                formControlName="spendingValue"
                (change)="onChange($event)"
                (ready)="onReady($event)"
              >
              </ckeditor>
            </div>

            <mat-form-field>
              <mat-label>Pontos fixos</mat-label>
              <mat-select
                formControlName="fixedPoints"
                (selectionChange)="changeFixedPointsEnabled($event)"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.fixedPoints?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.fixedPoints) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="formGroup.value.fixedPoints">
              <mat-label>Quantidade de pontos</mat-label>
              <input type="number" matInput formControlName="fixedPointsAmount" />
              <mat-error *ngIf="formGroup.controls.fixedPointsAmount?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.fixedPointsAmount) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipos de campanha</mat-label>
              <mat-select formControlName="confirmationCampaignType" required>
                <mat-option [value]="'PENDING_POINTS'">Pontos pendentes</mat-option>
                <mat-option [value]="'CONFIRMED'">Confirmados</mat-option>
                <!-- <mat-option [value]="'PENDING_POINTS_CONFIRMATION'"
                  >Confirmação de pontos pendentes</mat-option
                > -->
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Fator de conversão</mat-label>
              <input
                matInput
                formControlName="convertionFactor"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
              />
              <mat-error *ngIf="formGroup.controls.convertionFactor?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.convertionFactor) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Campanha de confirmação</mat-label>
              <mat-select formControlName="confirmationCampaign" required>
                <mat-option [value]="'ALLOW_PARTIALS'">Permite parciais</mat-option>
                <mat-option [value]="'PARTIAL_ONCE'">Parcial apenas uma vez</mat-option>
                <mat-option [value]="'DENY_PARTIAL'">Não permite parcial</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Quantidade de dias para expiração</mat-label>
              <input type="number" matInput formControlName="expirationDaysAmount" />
              <mat-error *ngIf="formGroup.controls.expirationDaysAmount?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.expirationDaysAmount) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Quantidade de dias para expirar os pontos após a confirmação</mat-label>
              <input type="number" matInput formControlName="amountDayAfterConfirmated" />
              <mat-error *ngIf="formGroup.controls.amountDayAfterConfirmated?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.amountDayAfterConfirmated) }}
              </mat-error>
            </mat-form-field>

            <div class="check">
              <input
                formControlName="isDefault"
                (change)="handleCampaignInfoChanging()"
                type="checkbox"
              />
              <mat-label> &nbsp;Campanha padrão</mat-label>
            </div>

            <mat-form-field>
              <mat-label>Data de expiração de pontos</mat-label>
              <mat-select
                formControlName="isAllowedExpirationPointsDate"
                (selectionChange)="changeIsAllowedExpirationPointsDateEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isAllowedExpirationPointsDate?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.isAllowedExpirationPointsDate)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="formGroup.value.isAllowedExpirationPointsDate">
              <mat-label>Data</mat-label>
              <input type="date" matInput formControlName="pointsExpirationDate" />
            </mat-form-field>

            <mat-form-field *ngIf="formGroup.value.isAllowedExpirationPointsDate">
              <mat-label>Hora</mat-label>
              <input type="time" matInput formControlName="pointsExpirationTime" />
              <mat-error *ngIf="formGroup.controls.pointsExpirationTime?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsExpirationTime) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de início da campanha</mat-label>
              <input
                type="date"
                (change)="handleCampaignInfoChanging()"
                matInput
                formControlName="campaignInitialDate"
                required
              />
              <mat-error *ngIf="formGroup.controls.campaignInitialDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.campaignInitialDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Hora de início da campanha</mat-label>
              <input type="time" matInput formControlName="campaignInitialTime" required />
              <mat-error *ngIf="formGroup.controls.campaignInitialTime?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.campaignInitialTime) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!formGroup.value.isDefault">
              <mat-label>Data de expiração da campanha</mat-label>
              <input
                type="date"
                (change)="handleCampaignInfoChanging()"
                matInput
                formControlName="campaignEndDate"
              />
            </mat-form-field>

            <mat-form-field *ngIf="!formGroup.value.isDefault">
              <mat-label>Hora de expiração da campanha</mat-label>
              <input type="time" matInput formControlName="campaignEndTime" />
              <mat-error *ngIf="formGroup.controls.campaignEndTime?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.campaignEndTime) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Parceiros por Macro Grupo</mat-label>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let macroGroup of selectedMacroGroups; index as i"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="removeMacroGroup(macroGroup, i)"
                >
                  {{ macroGroup.heading }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  #macroGroupsInput
                  [formControl]="macroGroupControl"
                  formControlName="macroGroupsInput"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let macroGroup of filteredMacroGroups" [value]="macroGroup">
                  {{ macroGroup.heading }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div *ngFor="let selectedMacroGroup of selectedMacroGroupsPartnersList; index as i">
              <mat-label *ngIf="selectedMacroGroup.partners.length">
                {{ selectedMacroGroup.partners[0].macroGroupName }}
              </mat-label>
              <mat-form-field *ngIf="selectedMacroGroup.partners.length > 3">
                <input matInput [formControlName]="selectedMacroGroup.id" />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              <div class="macro-viewport" *ngIf="selectedMacroGroup.partners.length">
                <div
                  *ngFor="
                    let partner of selectedMacroGroup.partners
                      | campaignFilter: formGroup.value[selectedMacroGroup.id]
                  "
                  class="macro-item"
                >
                  <input
                    type="checkbox"
                    [value]="partner.storeId"
                    [checked]="partner.checked"
                    (change)="changePartnerCheckbox($event, i)"
                  />
                  <mat-label> &nbsp;{{ partner.nomeFantasia }}</mat-label>
                </div>
              </div>
            </div>

            <mat-form-field *ngIf="partnersList.length">
              <mat-label>Parceiros</mat-label>
              <mat-icon matSuffix>search</mat-icon>
              <input matInput formControlName="partnersSearchText" placeholder="Nome fantasia" />
            </mat-form-field>

            <div class="macro-viewport" *ngIf="partnersList.length">
              <div
                *ngFor="
                  let partner of partnersList | campaignFilter: formGroup.value.partnersSearchText
                "
              >
                <div class="macro-item" *ngIf="!partner.disabled">
                  <input
                    type="checkbox"
                    [value]="partner.id"
                    [checked]="partner.checked"
                    (change)="changeFromStoreListCheckbox($event)"
                  />
                  <mat-label> &nbsp;{{ partner.nomeFantasia }}</mat-label>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>

      <div class="campaign-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
