import * as tslib_1 from "tslib";
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { ModalCustomerInfoComponent } from '../../../../../components/modal-customer-info/modal-customer-info.component';
var FundAssociatesListComponent = /** @class */ (function () {
    function FundAssociatesListComponent(fundService, customersAssociatesService, snackBar, route, router, dialog, location, authService) {
        this.fundService = fundService;
        this.customersAssociatesService = customersAssociatesService;
        this.snackBar = snackBar;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.location = location;
        this.authService = authService;
        this.headersTable = ['cpf', 'name', 'infoCostumer', 'actions'];
        this.fund = {};
        this.fundId = '';
        this.customerId = '';
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    FundAssociatesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.userRole = localStorage.getItem('userRole');
        this.fundId = this.route.snapshot.paramMap.get('fundId');
        this.customerId = this.route.snapshot.paramMap.get('customerId');
        if (!this.customerId) {
            this.fieldsForm = [
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfFundCustomersAssociates',
                    label: 'CPF',
                    placeholder: 'CPF',
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'nameFundCustomersAssociates',
                    label: 'Nome',
                    placeholder: 'Nome',
                },
            ];
            this.listAssociates();
        }
        else {
            this.associateFindOne(this.customerId);
        }
        this.currentFund();
    };
    FundAssociatesListComponent.prototype.currentFund = function () {
        var _this = this;
        this.fundService
            .find(this.fundId)
            .then(function (response) {
            _this.fund = response;
        })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundAssociatesListComponent.prototype.listAssociates = function () {
        var _this = this;
        this.customersAssociatesService
            .list(this.fundId, "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .then(function (response) {
            _this.pageTotal = response.total;
            _this.dataSource = new MatTableDataSource(response.results);
        })
            .catch(function (err) {
            _this.snackBar.open(err.error.message);
        });
    };
    FundAssociatesListComponent.prototype.associateFindOne = function (customerId) {
        var _this = this;
        this.customersAssociatesService
            .list(this.fundId, "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ", \"filters\":[{\"fields\":[\"customer_id\"], \"op\": \"=\", \"value\":\"" + customerId + "\"}]}")
            .then(function (response) {
            response.results.map(function (user) {
                _this.cpf = user.cpf;
            });
            _this.fieldsForm = [
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'cpfFundCustomersAssociates',
                    label: 'CPF',
                    placeholder: 'CPF',
                    valueDefault: _this.cpf,
                },
                {
                    field: 'input',
                    type: 'text',
                    formControlName: 'nameFundCustomersAssociates',
                    label: 'Nome',
                    placeholder: 'Nome',
                },
            ];
            _this.pageTotal = response.total;
            _this.dataSource = new MatTableDataSource(response.results);
        })
            .catch(function (err) {
            _this.snackBar.open(err.error.message);
        });
    };
    FundAssociatesListComponent.prototype.addAssociate = function () {
        var _this = this;
        this.router.navigate(["./dashboard/funds/" + this.fundId + "/associates/form"]).catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundAssociatesListComponent.prototype.removeAssociate = function (associatedId) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse associado?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.customersAssociatesService
                            .remove(this.fundId, associatedId)
                            .then(function (response) {
                            if (response.success) {
                                _this.snackBar.open('Usuário desassociado com sucesso.');
                                window.location.reload();
                            }
                            else {
                                _this.snackBar.open('Falha ao desassociar o usuário.');
                            }
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundAssociatesListComponent.prototype.detail = function (customerId) {
        var _this = this;
        if (this.userRole === 'ADMIN') {
            this.authService
                .customerUserInfo(customerId)
                .toPromise()
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    console.log(response);
                    this.dialog.open(ModalCustomerInfoComponent, {
                        data: {
                            customerBalance: response.balance,
                            customerCpf: response.cpf,
                            customerFullName: response.firstName + " " + response.lastName,
                            customerBirthDate: response.birthDate,
                            customerEmailAddress: response.emailAddress,
                            customerPhoneNumber: response.phoneNumber,
                            customerGender: response.gender,
                            customerId: response.id,
                            user: this.userRole,
                        },
                    });
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
    };
    FundAssociatesListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return FundAssociatesListComponent;
}());
export { FundAssociatesListComponent };
