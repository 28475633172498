import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from '../../core/services/errors.service'
import JsFileDownloader from 'js-file-downloader'
import { StoreService } from 'src/app/services/store.service'
@Component({
  selector: 'app-modal-form-statementByCustomer',
  templateUrl: './modal-form-statementByCustomer.component.html',
  styleUrls: ['./modal-form-statementByCustomer.component.scss'],
})
export class ModalFormStatementByCustomerComponent implements OnInit {
  public formGroup: FormGroup
  public selectedCustomers: any[] = []
  public customerControl = new FormControl()
  public customerInvalid = false
  public beginDateInvalid = false
  public endDateInvalid = false
  public customersCPF: any
  customers: any[] = []
  id: string[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public isArchitect

  filteredCustomers: Observable<string[]>

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalFormStatementByCustomerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cpf: string
      fullName: string
      type: any
      architect: boolean
    },
    public storeService: StoreService,
  ) { }

  ngOnInit () {
    this.isArchitect = !!this.data.architect
    this.formGroup = this.formBuilder.group({
      customersInput: [null, []],
      beginDate: [null, []],
      endDate: [null, []],
    })

    if (this.isArchitect) {
      this.storeService
        .listUsersCustomers('?filters={"filters":[{"fields":["architect_jhsfid_architect"], "op": "=", "value":"true"}]}',
        )
        .toPromise()
        .then(
          async response => {
            response.items.map(item => {
              item.name = `${item.firstName} ${item.lastName}`
              this.customers.push(item)
            })
          },
          error => {
            console.log(error)
            alert(error.error.message)
          },
        )
    } else {
      this.reportService.listCustomers().subscribe(
        response => {
          response.map(item => {
            this.customers.push(item)
          })
        },
        error => {
          console.log(error)
        },
      )
    }

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )
  }

  private _filter (customer: string): string[] {
    if (!this.selectedCustomers.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers.includes(c))
        .filter(c =>
          `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }
  }

  removeCustomer (customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedCustomers.length) {
      this.selectedCustomers.push(event.option.value)
      this.customerInput.nativeElement.value = ''
      this.customerControl.setValue(null)
      event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true)
    }
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  changeBeginDate (e, cpf) {
    if (!cpf) {
      this.beginDateInvalid = !this.formGroup.value.beginDate
    } else if (this.formGroup.value.endDate) {
      this.formGroup.value.beginDate && this.beginDateInvalid
        ? (this.beginDateInvalid = false)
        : (this.beginDateInvalid = true)
    } else if (!this.formGroup.value.endDateDate && !this.formGroup.value.beginDate) {
      this.beginDateInvalid = false
      this.endDateInvalid = false
    }
  }

  changeEndDate (e, cpf) {
    if (!cpf) {
      this.endDateInvalid = !this.formGroup.value.endDate
    } else if (this.formGroup.value.beginDate) {
      this.formGroup.value.endDate && this.endDateInvalid
        ? (this.endDateInvalid = false)
        : (this.endDateInvalid = true)
    } else if (!this.formGroup.value.beginDate && !this.formGroup.value.endDate) {
      this.beginDateInvalid = false
      this.endDateInvalid = false
    }
  }

  submit (cpf?, type?) {
    if (!cpf) {
      this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true)
      this.beginDateInvalid = !this.formGroup.value.beginDate
      this.endDateInvalid = !this.formGroup.value.endDate

      this.customersCPF = this.selectedCustomers.map(c => c.cpf)
      if (this.beginDateInvalid || this.endDateInvalid || this.customerInvalid) {
        this.snackBar.open('Preencha corretamente os campos e tente novamente.')
        return false
      }
    }
    let filter = ''
    let url: string
    if (type === 'new') {
      url = 'statement-customer-new'
    } else {
      url = 'statement-customer'
    }
    if (cpf) {
      if (this.formGroup.value.beginDate || this.formGroup.value.endDate) {
        this.beginDateInvalid = !this.formGroup.value.beginDate
        this.endDateInvalid = !this.formGroup.value.endDate
        if (this.beginDateInvalid || this.endDateInvalid) {
          this.snackBar.open('Preencha corretamente os campos e tente novamente.')
          return false
        }
        filter = `${url}?cpf=${cpf}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        filter = `${url}?cpf=${cpf}`
      }
    } else {
      filter = `${url}?cpf=${this.customersCPF}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    }
    this.reportService
      .createReport(filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Extrato por Cliente.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
