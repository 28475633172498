<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content class="content">
    <mat-card>
      <div class="row">
        <h3 *ngIf="data.text" >{{data.text}}</h3>
        <div *ngIf="data.html" [innerHTML]="getSanitizedHtml(data.html)" ></div>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="actions">
      <button *ngIf="data.buttonNot" mat-button (click)="not()" class="btn-submit-dark">
        {{ data.buttonNot }}
      </button>
      <button *ngIf="data.buttonConfirmText" mat-button (click)="confirm()" class="btn-submit-dark">
        {{ data.buttonConfirmText }}
      </button>
    </div>
  </div>
</main>
