import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { BusinessPartner } from '../models/businessPartner'

@Injectable({
  providedIn: 'root',
})
export class BusinessPartnersService {
  public loading

  constructor (private readonly http: HttpClient) {}

  async list (categoryID: string, filter?): Promise<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners${filter || ''}`,
    )
      .toPromise()
  }

  async findOne (categoryID: string, id: string): Promise<BusinessPartner> {
    return this.http
      .get<BusinessPartner>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners/${id}`,
    )
      .toPromise()
  }

  async create (categoryID: string, data): Promise<BusinessPartner> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .post<BusinessPartner>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners`,
        formData,
        { headers },
    )
      .toPromise()
  }

  async update (categoryID: string, id: string, data): Promise<BusinessPartner[]> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .put<BusinessPartner[]>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners/${id}`,
        formData,
        { headers },
    )
      .toPromise()
  }

  async delete (categoryID: string, businessPartnersID: string): Promise<BusinessPartner> {
    return this.http
      .delete<BusinessPartner>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners/${businessPartnersID}`,
    )
      .toPromise()
  }

  async getS3Url (categoryID: string): Promise<BusinessPartner> {
    return this.http
      .get<BusinessPartner>(
        `${environment.apiUrl}/cms/categories/${categoryID}/business-partners/s3-slot`,
    )
      .toPromise()
  }

  async changeOrderPartners (id: string, data): Promise<BusinessPartner> {
    return this.http
      .patch<BusinessPartner>(
        `${environment.apiUrl}/cms/categories/${id}/business-partners/change-order`,
        data,
    )
      .toPromise()
  }

  async uploadToS3 (pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }
}
