<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Aeroporto</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/airports">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput formControlName="title" />
              <mat-error *ngIf="formGroup.controls.title?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.title) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Banner do parceiro</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a *ngIf="croppedBannerImage" matSuffix mat-icon-button (click)="editBannerImage()">
                <mat-icon>edit</mat-icon>
              </a>
              <a *ngIf="croppedBannerImage" matSuffix mat-icon-button (click)="removeBannerImage()">
                <mat-icon>delete</mat-icon>
              </a>
              <img *ngIf="croppedBannerImage != ''" width="590" src="{{ croppedBannerImage }}" />
              <input
                hidden
                (change)="selectFileBanner($event)"
                #fileInputBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="imgInvalid"
              >Campo deve ser preenchido</mat-error
            >

            <ckeditor
              [config]="config"
              [editor]="Editor"
              formControlName="description"
              (change)="onChange($event)"
              (ready)="onReady($event)"
            ></ckeditor>
            <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="descriptionInvalid"
              >Campo deve ser preenchido</mat-error
            >

            <mat-form-field>
              <mat-label>Mensagem do rodapé</mat-label>
              <input matInput formControlName="footerMsg" />
              <mat-error *ngIf="formGroup.controls.footerMsg?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.footerMsg) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Título da Informação</mat-label>
              <input matInput formControlName="infoTitle" />
              <mat-error *ngIf="formGroup.controls.infoTitle?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.infoTitle) }}
              </mat-error>
            </mat-form-field>

            <div>
              <input formControlName="isActive" type="checkbox" (change)="checkAirport($event)" />
              <mat-label> &nbsp;Ativo</mat-label>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
