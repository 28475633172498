import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
var ModalFormChargeBackComponent = /** @class */ (function () {
    function ModalFormChargeBackComponent(errorsService, snackBar, formBuilder, reportService, storeService, dialogRef, data) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.storeService = storeService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.storeControl = new FormControl();
        this.storesUser = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.stores = [];
        this.selectedStores = [];
    }
    ModalFormChargeBackComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            description: [null, [Validators.required]],
        });
    };
    ModalFormChargeBackComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormChargeBackComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        this.storeService.performChargeBack({
            walletId: this.data.walletId,
            description: this.formGroup.controls.description.value,
        })
            .subscribe(function (response) {
            _this.dialogRef.close(response);
        }, function (error) {
            console.log(error);
            _this.dialogRef.close(error);
        });
    };
    return ModalFormChargeBackComponent;
}());
export { ModalFormChargeBackComponent };
