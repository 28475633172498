import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
import JsFileDownloader from 'js-file-downloader';
var ArchitectInvoiceListComponent = /** @class */ (function () {
    function ArchitectInvoiceListComponent(architectInvoiceService, snackBar, permissionsService, location, reportService) {
        this.architectInvoiceService = architectInvoiceService;
        this.snackBar = snackBar;
        this.permissionsService = permissionsService;
        this.location = location;
        this.reportService = reportService;
        this.headersTable = ['invoiceNumbber', 'invoiceSerial', 'receiver', 'amount', 'createdAt', 'approved', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected'));
        this.storeBusinessUnitId = this.selectedStoreBusinessUnit.storeId;
    }
    ArchitectInvoiceListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'select',
                type: 'text',
                formControlName: 'architectInvoiceState',
                label: 'Status',
                placeholder: 'Status',
                list: [
                    { key: 'APPROVED', value: 'PAGO' },
                    { key: 'CANCELED', value: 'CANCELADO' },
                    { key: 'PENDING', value: 'PEDIDO PENDENTE' },
                    { key: 'WAITING_VALIDATION', value: 'AGUARDANDO VALIDAÇÃO' },
                    { key: 'INVALIDATED', value: 'PEDIDO RECUSADO' },
                    { key: '', value: 'TODOS' },
                ],
                optionDefault: '',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Nota Criada a partir de',
                formControlName: 'architectInvoiceStartDate',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Nota Criada até',
                formControlName: 'architectInvoiceEndDate',
            },
            {
                field: 'input',
                type: 'text',
                label: 'CPF/CNPJ do recebedor dos jcoins',
                formControlName: 'architectInvoiceTargetDocument',
            },
        ];
    };
    ArchitectInvoiceListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, invoices, TotalRegisters;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.architectInvoiceService
                            .list({
                            storeBusinessUnitId: this.selectedStoreBusinessUnit.storeId,
                            filter: "?&page=" + this.page + "&limit=" + this.pageSize,
                        })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { invoices: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), invoices = _a.invoices, TotalRegisters = _a.TotalRegisters;
                        this.dataSource = new MatTableDataSource(invoices);
                        this.pageTotal = TotalRegisters;
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceListComponent.prototype.getInvoiceStatus = function (invoice) {
        return this.architectInvoiceService.getInvoiceStatus(invoice);
    };
    ArchitectInvoiceListComponent.prototype.filterCallback = function (returnFilter) {
        this.returnedFilters = returnFilter.invoices;
        this.pageTotal = returnFilter.TotalRegisters;
        this.dataSource = new MatTableDataSource(returnFilter.invoices);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    ArchitectInvoiceListComponent.prototype.cancelInvoice = function (invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, res, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        data = {
                            invoices: [invoiceId],
                        };
                        return [4 /*yield*/, this.architectInvoiceService.cancelInvoice(data)];
                    case 1:
                        res = _a.sent();
                        this.list();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.snackBar.open('Não foi possível cancelar essa Nota fiscal');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceListComponent.prototype.showEditButton = function (invoiceData) {
        if (invoiceData.approved || invoiceData.canceled)
            return false;
        var hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_create_update);
        if (!hasPermission)
            return false;
        return true;
    };
    ArchitectInvoiceListComponent.prototype.showCancelButton = function (invoiceData) {
        if (invoiceData.approved || invoiceData.canceled)
            return false;
        var hasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.architect_invoice_cancel);
        if (!hasPermission)
            return false;
        return true;
    };
    ArchitectInvoiceListComponent.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectInvoiceListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ArchitectInvoiceListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.createReport('architects/invoices').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Relatório de Clientes.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ArchitectInvoiceListComponent;
}());
export { ArchitectInvoiceListComponent };
