import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ModalDeleteClosingComponent } from 'src/app/components/modal-delete-closing/modal-delete-closing.component'
import { ModalIntegrationsClosingsComponent } from 'src/app/components/modal-integrations-closings/modal-closings.component'
import JsFileDownloader from 'js-file-downloader'
import { AuthService } from '../../../../services/auth.service'
import { ReportService } from '../../../../services/report.service'

@Component({
  selector: 'app-closings-airports',
  templateUrl: './closings-airports.component.html',
  styleUrls: ['./closings-airports.component.scss'],
})
export class ClosingsAirportsComponent implements OnInit {
  public headersTable: string[] = ['closingNumber', 'startDateEndendDate', 'actions']
  public closings: any[] = []
  public closingsSelecteds: any[] = []
  public formFilter: FormGroup
  public form: FormGroup

  constructor (
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly reportService: ReportService,
  ) { }

  ngOnInit () {
    this.formFilter = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      paymentDate: [null, [Validators.required]],
    })

    this.listAll()
  }

  create (result) {
    this.authService.closingAirportCreate(result).then(
      async response => {
        this.listAll()
        this.snackBar.open('Fechamento criado com sucesso')
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  listAll () {
    this.closings = []

    this.authService.closingsAirportsIntegrations().then(
      async response => {
        this.closings = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalIntegrationsClosingsComponent, {
      width: '450px',
      data: {},
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'cancel') {
        this.create(result);
      } else {
        console.log('Modal fechado sem ações.');
      }
    });
  }
  

  delete (id) {
    const dialogRef = this.dialog.open(ModalDeleteClosingComponent, {
      width: '500px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.authService.deleteClosingAirport(id).then(
          async response => {
            this.snackBar.open('Fechamento deletado com sucesso.')
            this.listAll()
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )
      }
    })
  }

  async export (id) {
    const fileUrl = await this.reportService.closingAirportsExport(id)
     
    new JsFileDownloader({
      url: fileUrl.trim(),
      nameCallback: () => {
        return `Fechamento Financeiro.xlsx`;
      },
    });
  }
}
