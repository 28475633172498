<app-logged class="schedule-list">
  <header class="schedule-list__header">
    <h2>TEDs de Recebíveis agendados</h2>

    <div class="schedule-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>

  <div class="row" *ngIf="schedules">
    <div class="col-sm-12">
      <div class="table-responsive">
        <app-form-filter          
          actions="form"
          type="transferScheduleListFromStore"
          [fieldsForm]="fieldsForm"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
          [storeID]="this.storeSelected.storeId"
        >
        </app-form-filter>
        <table mat-table [dataSource]="schedules">        

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>Data para o pagamento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dueDate | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ totalValue(element.transfer) | currency: 'BRL':'R$' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paidAt">
            <th mat-header-cell *matHeaderCellDef>Pago em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.paidAt | date: 'dd/MM/yyyy HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Transações"
                matTooltipPosition="left"
                routerLink="/dashboard/liquidation-transctions/{{ element.id }}"
              >
                <mat-icon>swap_horiz</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
        <app-form-filter
          actions="pagination"
          type="transferScheduleListFromStore"
          [length]="pageTotal"
          [pageSizeOptions]="pageSizeOptions"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
          [storeID]="this.storeSelected.storeId"
        ></app-form-filter>
      </div>
    </div>
  </div>
</app-logged>
