<app-logged class="store-list">
  <header class="store-list__header">
    <h2>LISTA DO TIPO DA NOTIFICAÇÃO</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        mat-button
        routerLink="/dashboard/notification-types/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <!-- <app-form-filter actions="form" type="notification" [fieldsForm]="fieldsForm" [formGroupValue]="formGroup"
    (returnFilter)="reciverFeedback($event)"></app-form-filter> -->
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome do Tipo</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.type }}" class="cellText">
          {{ element.type }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/notification-types/{{ element.id }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
