import * as tslib_1 from "tslib";
import { MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component';
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component';
var FundTransactionsComponent = /** @class */ (function () {
    function FundTransactionsComponent(fundService, customersAssociatesService, transactionService, snackBar, dialog, route, router, location) {
        this.fundService = fundService;
        this.customersAssociatesService = customersAssociatesService;
        this.transactionService = transactionService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.location = location;
        this.headersTable = [
            'orderNumber',
            'storeName',
            'fullName',
            'customerCpf',
            'description',
            'createdAt',
            'totalValue',
            'state',
            'actions',
        ];
        this.listStatus = [];
        this.listStatusDefault = [];
        this.fund = {};
        this.fundId = '';
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    FundTransactionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.userRole = localStorage.getItem('userRole');
        this.init().catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundTransactionsComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fieldsForm = [
                            {
                                field: 'input',
                                type: 'number',
                                formControlName: 'numberFundTransaction',
                                label: 'Número da Transação',
                                placeholder: 'Número da Transação',
                            },
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'cpfFundTransaction',
                                label: 'CPF do Cliente',
                                placeholder: 'CPF do Cliente',
                            },
                            {
                                field: 'input',
                                type: 'date',
                                formControlName: 'dateFundTransaction',
                            },
                            {
                                field: 'select',
                                formControlName: 'statusFundTransaction',
                                label: 'Status',
                                placeholder: 'Status',
                                list: this.listStatus,
                                optionDefault: 'TODOS',
                            },
                        ];
                        this.fundId = this.route.snapshot.paramMap.get('fundId');
                        return [4 /*yield*/, Promise.all([this.fetchTransactionsStatus(), this.currentFund(), this.listTransactions()])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FundTransactionsComponent.prototype.currentFund = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.fundService.find(this.fundId)];
                    case 1:
                        _a.fund = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FundTransactionsComponent.prototype.fetchTransactionsStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.transactionService.getStatus().toPromise()];
                    case 1:
                        _a.listStatusDefault = _b.sent();
                        Object.keys(this.listStatusDefault).map(function (k) {
                            if (k !== 'PENDING') {
                                _this.listStatus.push({ key: k, value: _this.listStatusDefault[k] });
                            }
                        });
                        this.listStatus.sort(function (a, b) {
                            return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    FundTransactionsComponent.prototype.listTransactions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.fundService.getTransactions(this.fundId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.pageTotal = total;
                        this.dataSource = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    FundTransactionsComponent.prototype.detail = function (transacationId, fullName, customerCpf) {
        var _this = this;
        if (this.userRole === 'ADMIN') {
            this.transactionService
                .listTransactionsById(transacationId + "?showManualDetails=true")
                .subscribe(function (response) {
                _this.dialog.open(ModalCustomerInfoComponent, {
                    data: {
                        customerBalance: response.customerBalance,
                        customerCpf: response.customerCpf,
                        customerFullName: response.customerFirstName + " " + response.customerLastName,
                        customerBirthDate: response.customerBirthDate,
                        customerEmailAddress: response.customerEmailAddress,
                        customerPhoneNumber: response.customerPhoneNumber,
                        customerGender: response.customerGender,
                        customerId: response.customerId,
                        user: _this.userRole,
                    },
                });
            }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    customerCpf: customerCpf,
                    customerFullName: fullName,
                    user: this.userRole,
                },
            });
        }
    };
    FundTransactionsComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result !== 'cancel') {
                _this.cancel(transaction.id, result, transaction.type);
            }
        });
    };
    FundTransactionsComponent.prototype.convertStatusTransactions = function (transaction) {
        if (transaction.isManual && transaction.state === 'PENDING') {
            return 'AGUARDANDO APROVAÇÃO DA JHSF';
        }
        else {
            return this.listStatusDefault[transaction.state];
        }
    };
    FundTransactionsComponent.prototype.view = function (transaction) {
        if (transaction.isManual) {
            localStorage.setItem('isManual', 'true');
        }
        else {
            localStorage.setItem('isManual', 'false');
        }
    };
    FundTransactionsComponent.prototype.cancel = function (transactionID, reason, transactionType) {
        var _this = this;
        var data = {
            reason: reason,
        };
        var cancelObservable;
        if (transactionType && transactionType.toLowerCase() === 'c2c')
            cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data);
        else
            cancelObservable = this.transactionService.cancelTransaction(transactionID, data);
        cancelObservable.toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação cancelada com sucesso.');
                return [2 /*return*/, this.listTransactions()];
            });
        }); })
            .catch(function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    FundTransactionsComponent.prototype.voltar = function () {
        this.location.back();
    };
    FundTransactionsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return FundTransactionsComponent;
}());
export { FundTransactionsComponent };
