import { Component, OnInit } from '@angular/core'
import { Transaction } from 'src/app/models/transaction'
import { ActivatedRoute } from '@angular/router'
import { CurrencyPipe } from '@angular/common'
import { AccountBankService } from 'src/app/services/accountBank.service'
import { bankAccount } from 'src/app/models/bankAccount'
import { MatSnackBar } from '@angular/material'
import { StoreDocumentService } from 'src/app/services/storeDocument'
import { StoreDocument } from 'src/app/models/storeDocument'
import { StoreService } from 'src/app/services/store.service'
import { Store } from 'src/app/models/store'

@Component({
  selector: 'app-store-list',
  templateUrl: './store-documents-list.component.html',
  styleUrls: ['./store-documents-list.component.scss'],
})
export class StoreDocumentsListComponent implements OnInit {
  public headersTable: string[] = ['type', 'status', 'link']
  public documents: StoreDocument[] = []
  public storeID = ''
  public store: Store
  public params = { storeFormGroupValue: [] }

  constructor (
    public storeDocuments: StoreDocumentService,
    public storeService: StoreService,
    private readonly route: ActivatedRoute,
    public currencyPipe: CurrencyPipe,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.loadStoreInfo()
    this.list()
  }

  list () {
    this.storeDocuments.list(this.storeID).subscribe(
      async response => {
        this.documents = response
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  refresh (): void {
    window.location.reload()
  }

  loadStoreInfo () {
    this.storeService.getStore(this.storeID).subscribe(
      async response => {
        this.store = response
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.storeDocuments.delete(id, this.storeID).subscribe(
      async response => {
        this.snackBar.open('Categoria removida com sucesso.')
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertStatus (status) {
    switch (status) {
      case 'CHECKING':
        return 'Corrente'
        break
      case 'SAVINGS':
        return 'Poupança'
        break
      default:
        return ''
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }

  openDocument (document) {
    window.open(document, '_blank')
  }

  convertType (type) {
    switch (type) {
      case 'IDENTIFICATION':
        return 'Identificação'
        break
      case 'ACTIVITY':
        return 'Atividade'
        break
      case 'ADDRESS':
        return 'Endereço'
        break
      case 'CNPJ':
        return 'CNPJ'
        break
      default:
        return ''
    }
  }

  convertStatusDocument (status) {
    switch (status) {
      case 'APPROVED':
        return 'Aprovada'
        break
      case 'REJECTED':
        return 'Rejeitada'
        break
      case 'PENDING':
        return 'Pendente'
        break
      default:
        return ''
    }
  }
}
