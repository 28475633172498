import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
var ModalConfirmComponent = /** @class */ (function () {
    function ModalConfirmComponent(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        console.log('data', data);
    }
    ModalConfirmComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalConfirmComponent.prototype.confirm = function () {
        this.dialogRef.close(1);
    };
    ModalConfirmComponent.prototype.not = function () {
        this.dialogRef.close('not');
    };
    ModalConfirmComponent.prototype.ngOnInit = function () {
        // empty
    };
    return ModalConfirmComponent;
}());
export { ModalConfirmComponent };
