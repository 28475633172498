<app-logged class="store-form">
  <header class="store-form__header">
    <h2>CADASTRAR NOVA TARIFA DE CARTÃO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Cadastro de nova Taxa</mat-card-title>
          <mat-card-content>
           
            <mat-form-field>
              <mat-label>Meio de pagamento</mat-label>
              <mat-select formControlName="paymentProvider" required>
                <mat-option value="GETNET">GETNET</mat-option>
                <mat-option value="PAGBANK">PAGBANK</mat-option>
                <mat-option value="ZOOP">ZOOP</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bandeira do cartão</mat-label>
              <mat-select formControlName="cardBrand" required>
                <mat-option value="Mastercard">Mastercard</mat-option>
                <mat-option value="Visa">Visa</mat-option>
                <mat-option value="Discover">Discover</mat-option>
                <mat-option value="Diners">Diners</mat-option>
              </mat-select>
            </mat-form-field>
            
            <mat-form-field>
              <mat-label>Taxa para 1 parcela</mat-label>
              <input type="number" step=".01" matInput formControlName="installments1" />
              <mat-error *ngIf="formGroup.controls.installments1?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.installments1) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa para 2 a 6 parcelas</mat-label>
              <input type="number" step=".01" matInput formControlName="installments2to6" />
              <mat-error *ngIf="formGroup.controls.installments2to6?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.installments2to6) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa para 7 a 12 parcelas</mat-label>
              <input type="number" step=".01" matInput formControlName="installments7to12" />
              <mat-error *ngIf="formGroup.controls.installments7to12?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.installments7to12) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa de débito</mat-label>
              <input type="number" step=".01" matInput formControlName="debit" />
              <mat-error *ngIf="formGroup.controls.debit?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.debit) }}
              </mat-error>
            </mat-form-field>

          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>

    </mat-card>
  </form>

</app-logged>