import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'src/app/services/uploadAdapter.service';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var AirportsFormComponent = /** @class */ (function () {
    function AirportsFormComponent(errorsService, formBuilder, categoriesService, airportsService, authService, snackBar, router, route, _dialog, _cd, imageCompress, dialog) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.categoriesService = categoriesService;
        this.airportsService = airportsService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.dialog = dialog;
        this.Editor = ClassicEditor;
        this.textDescription = '';
        this.bannerUrl = '';
        this.descriptionInvalid = false;
        this.imgInvalid = false;
        this.config = {
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'imageUpload',
                    'blockQuote',
                    'undo',
                    'redo',
                ],
            },
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
    }
    AirportsFormComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.paramMap.get('airportID')) {
            this.airportID = this.route.snapshot.paramMap.get('airportID');
        }
        this.initForm();
    };
    AirportsFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            title: [null, [Validators.required]],
            bannerImage: [null, []],
            description: [null, []],
            footerMsg: [null, [Validators.required]],
            infoTitle: [null, [Validators.required]],
            isActive: [null, []],
        });
        if (this.airportID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    AirportsFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.findOne(this.airportID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    title: response.title,
                                    description: response.description,
                                    footerMsg: response.footerMsg,
                                    infoTitle: response.infoTitle,
                                    isActive: response.isActive,
                                });
                                this.croppedBannerImage = response.bannerUrl;
                                if (response.description)
                                    this.descriptionInvalid = false;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsFormComponent.prototype.onChange = function (_a) {
        var editor = _a.editor;
        this.descriptionInvalid = !this.formGroup.value.description;
    };
    AirportsFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    AirportsFormComponent.prototype.selectFileBanner = function (event) {
        var _this = this;
        var originalBannerImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalBannerImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalBannerImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedBannerImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedBannerImage = result.img.dataURL;
                        this.bannerImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerImage)];
                    case 1:
                        _a.bannerImageData = _b.sent();
                        this.imgInvalid = !this.croppedBannerImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    AirportsFormComponent.prototype.editBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalBannerImage) return [3 /*break*/, 1];
                        _a = this.originalBannerImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedBannerImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.bannerImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedBannerImage = result.img.dataURL;
                        this.bannerImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedBannerImage)];
                    case 5:
                        _b.bannerImageData = _c.sent();
                        this.imgInvalid = !this.croppedBannerImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AirportsFormComponent.prototype.removeBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.readUrlBanner = '';
                this.originalBannerImage = undefined;
                this.croppedBannerImage = undefined;
                this.bannerImagePosition = undefined;
                this.bannerImageData = undefined;
                this.imgInvalid = true;
                return [2 /*return*/];
            });
        });
    };
    AirportsFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    AirportsFormComponent.prototype.checkAirport = function (e) {
        var _this = this;
        if (this.formGroup.value.isActive) {
            this.dialog
                .open(ModalConfirmComponent, {
                width: '500px',
                data: {
                    text: 'Deseja esse Aeroporto como Ativo no App?',
                    buttonConfirmText: 'Sim',
                },
            })
                .afterClosed()
                .toPromise()
                .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (result === 'cancel') {
                        this.formGroup.get('isActive').setValue(false);
                    }
                    return [2 /*return*/];
                });
            }); })
                .catch(function (err) {
                _this.snackBar.open(err.message);
            });
        }
    };
    AirportsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.descriptionInvalid = !this.formGroup.value.description;
                        this.imgInvalid = !this.croppedBannerImage;
                        if (!this.formGroup.valid || this.descriptionInvalid || this.imgInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedBannerImage && this.bannerImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.airportsService.getS3Url()];
                    case 1:
                        response = _a.sent();
                        this.readUrlBanner = response.readUrl;
                        return [4 /*yield*/, this.airportsService.uploadToS3(response.uploadUrl, this.bannerImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        data = {
                            title: this.formGroup.value.title,
                            bannerUrl: this.readUrlBanner,
                            description: this.formGroup.value.description,
                            footerMsg: this.formGroup.value.footerMsg,
                            infoTitle: this.formGroup.value.infoTitle,
                            isActive: this.formGroup.value.isActive,
                        };
                        if (this.airportID) {
                            this.airportsService.updateAirport(this.airportID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/airports'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Aeroporto atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.airportsService.createAirport(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isCreated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/airports'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Aeroporto criado com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return AirportsFormComponent;
}());
export { AirportsFormComponent };
