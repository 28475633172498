import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalConfirmationInternationalTransactionComponent = /** @class */ (function () {
    function ModalConfirmationInternationalTransactionComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ModalConfirmationInternationalTransactionComponent.prototype.ngOnInit = function () { };
    ModalConfirmationInternationalTransactionComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ModalConfirmationInternationalTransactionComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalConfirmationInternationalTransactionComponent.prototype.createTransacation = function () {
        this.dialogRef.close({ createTransacation: true, idInternationalPaymentIntent: this.data.id });
    };
    return ModalConfirmationInternationalTransactionComponent;
}());
export { ModalConfirmationInternationalTransactionComponent };
