import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
interface DialogData {
  cnpj: string
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-resend-credentials.component.html',
  styleUrls: ['./modal-resend-credentials.component.scss'],
})
export class ModalResendCredentialsComponent implements OnInit {
  public formGroup: FormGroup

  constructor (
    public dialogRef: MatDialogRef<ModalResendCredentialsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string
    },
    public snackBar: MatSnackBar,
  ) {
    console.log('data', data)
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  confirm () {
    this.dialogRef.close(1)
  }

  ngOnInit () {}
}
