import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RegulationService = /** @class */ (function () {
    function RegulationService(http) {
        this.http = http;
    }
    RegulationService.prototype.listRegulations = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/regulations")
            .pipe(tap(function (_) { return _this.log('cms/regulations?page=0&range=10'); }), catchError(this.handleError));
    };
    RegulationService.prototype.addRegulation = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/regulations", data)
            .pipe(tap(function (_) { return _this.log('cms/regulations'); }), catchError(this.handleError));
    };
    RegulationService.prototype.updateRegulation = function (data, regulationID) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/regulations/" + regulationID, data)
            .pipe(tap(function (_) { return _this.log('cms/regulations'); }), catchError(this.handleError));
    };
    RegulationService.prototype.deleteRegulation = function (regulationID) {
        var _this = this;
        return this.http.delete(environment.apiUrl + "/cms/regulations/" + regulationID)
            .pipe(tap(function (_) { return _this.log('cms/regulations'); }), catchError(this.handleError));
    };
    RegulationService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    RegulationService.prototype.log = function (message) {
    };
    RegulationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegulationService_Factory() { return new RegulationService(i0.ɵɵinject(i1.HttpClient)); }, token: RegulationService, providedIn: "root" });
    return RegulationService;
}());
export { RegulationService };
