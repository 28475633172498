import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
  MatDialog,
  MatSnackBar,
  MatTableDataSource,
} from '@angular/material'
import * as XLSX from 'xlsx'
import { AuthService } from '../../../../../app/services/auth.service'
import { cnpj } from 'cpf-cnpj-validator'
@Component({
  selector: 'app-client-import',
  templateUrl: './client-import.component.html',
  styleUrls: ['./client-import.component.scss'],
})
export class ClientImportComponent implements OnInit {
  dataSource: any
  dataSourcePreRegister: any
  cnpjError: any

  public headersTable: string[] = [
    'name',
    'cpf',
    'cnpj',
    'jcoins',
  ]

  public headersTablePreRegister: string[] = [
    'cnpj',
    'name',
    'cnpjOrigem',
    'jcoins',
  ]

  dataString: any
  reader: any
  file: any

  dataStringPreRegister: any
  readerPreRegister: any
  filePreRegister: any

  hideFile = true
  hideFilePreRegister = true

  @ViewChild('inputFile', { static: false }) InputVar: ElementRef
  @ViewChild('inputFilePreRegister', { static: false }) InputVarPreRegister: ElementRef

  constructor (
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit () { }

  async onFileChange (ev) {
    this.hideFilePreRegister = false
    let workBook = null
    let jsonData = null
    this.reader = new FileReader()
    this.file = ev.target.files[0]
    if (this.file) {
      this.reader.onload = (event) => {
        const data = this.reader.result
        workBook = XLSX.read(data, { type: 'binary' })
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name]
          initial = XLSX.utils.sheet_to_json(sheet)
          return initial
        }, {})
        this.dataString = jsonData
        this.dataSource = new MatTableDataSource(this.dataString)
      }
      this.reader.readAsBinaryString(this.file)
    }
  }

  async saveFile () {
    this.authService.importCustomers({ customers: this.dataString }).then(
      async response => {
        if (response.import === true) {
          this.snackBar.open('Clientes cadastrados com sucesso.')
          this.clear().catch(err => console.log(err))
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async clear () {
    this.dataSource = null
    this.dataString = null
    this.InputVar.nativeElement.value = ''
    this.hideFilePreRegister = true
  }

  async clearPreRegister () {
    this.dataSourcePreRegister = null
    this.dataStringPreRegister = null
    this.InputVarPreRegister.nativeElement.value = ''
    this.hideFile = true
    this.cnpjError = false
  }

  async onFileChangePreRegister (ev) {
    this.hideFile = false
    let workBook = null
    let jsonData = null
    this.cnpjError = false
    this.readerPreRegister = new FileReader()
    this.filePreRegister = ev.target.files[0]
    if (this.filePreRegister) {
      this.readerPreRegister.onload = (event) => {
        const data = this.readerPreRegister.result
        workBook = XLSX.read(data, { type: 'binary' })
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name]
          initial = XLSX.utils.sheet_to_json(sheet)
          return initial
        }, {})
        this.dataStringPreRegister = jsonData
        for (const d of jsonData) {
          if (!cnpj.isValid(d.CNPJ)) {
            d.cnpjError = true
            this.cnpjError = true
          } else {
            d.cnpjError = false
          }
        }
        this.dataSourcePreRegister = new MatTableDataSource(this.dataStringPreRegister)
      }
      this.readerPreRegister.readAsBinaryString(this.filePreRegister)
    }
  }

  async saveFilePreRegister () {
    if (this.cnpjError) {
      this.snackBar.open('O CNPJ em vermelho está inválido')
      return false
    }
    this.authService.importCustomersPreRegister({ companies: this.dataStringPreRegister }).then(
      async response => {
        if (response.import === true) {
          this.snackBar.open('Clientes CNPJ cadastrados com sucesso.')
          this.clearPreRegister().catch(err => console.log(err))
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
