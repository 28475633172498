<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!isUpdate">NOVO PARCEIRO INTERNACIONAL</h2>
    <h2 *ngIf="isUpdate">EDITAR PARCEIRO INTERNACIONAL</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="openDialogCampaignType()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-12" *ngIf="storeID != '' && storeID != null">
          <qrcode
            [qrdata]="valueQrCode"
            id="print-section"
            [width]="256"
            [elementType]="elementType"
            [errorCorrectionLevel]="'L'"
          ></qrcode>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Trade Name</mat-label>
              <input matInput formControlName="nomeFantasia" />
              <mat-error *ngIf="formGroup.controls.nomeFantasia?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.nomeFantasia) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Company Name</mat-label>
              <input matInput formControlName="razaoSocial" />
              <mat-error *ngIf="formGroup.controls.razaoSocial?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.razaoSocial) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Document</mat-label>
              <input matInput formControlName="document"/>
              <mat-error *ngIf="formGroup.controls.document?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.document) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['us']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label> Opening Date</mat-label>
              <input type="date" matInput formControlName="openingDate" />
              <mat-error *ngIf="formGroup.controls.openingDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.openingDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Subsidiary?</mat-label>
              <mat-select
                formControlName="isSubStore"
                (selectionChange)="changeIsSubStore($event.value)"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isSubStore?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isSubStore) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-6">
          <mat-card-title>Endereço</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>ZIP Code</mat-label>
              <input matInput formControlName="zipCode"/>
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>State</mat-label>
              <input matInput formControlName="state">
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>
            

            <mat-form-field>
              <mat-label>City</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Neighborhood</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Street</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Number</mat-label>
              <input matInput formControlName="number" />
              <mat-error *ngIf="formGroup.controls.number?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.number) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Additional Info</mat-label>
              <textarea matInput formControlName="complement"></textarea>
              <mat-error *ngIf="formGroup.controls.complement?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.complement) }}
              </mat-error>
            </mat-form-field>
            <p style="font-weight: bold; margin-bottom: 0px !important; margin-top: 0px !important">
              Subsidiary??
            </p>

            <div formArrayName="subStores">
              <div
                style="display: flex"
                *ngFor="let subStore of formGroup.get('subStores')['controls']; let i = index"
                [formGroupName]="i"
              >
                <div style="margin-right: 10px">
                  <mat-form-field *ngIf="isSubStore" style="margin-right: 1rem">
                    <input
                      matInput
                      formControlName="subStoreName"
                      placeholder="Sub-empresa"
                      autocomplete="subStore"
                      (keypress)="keyPress($event)"
                      (blur)="validStoreName($event)"
                    />
                  </mat-form-field>
                  <mat-error
                    style="padding-bottom: 9px; font-size: 11px"
                    *ngIf="subStoreNameInvalid"
                    >Campo deve ser preenchido</mat-error
                  >
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Macro Group</mat-label>
                    <mat-select
                      (selectionChange)="changeMacroGroup($event)"
                      formControlName="macroGroupId"
                    >
                      <mat-option *ngFor="let mg of macroGroups" [value]="mg.id">
                        {{ mg.macroName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error
                    style="padding-bottom: 9px; font-size: 11px"
                    *ngIf="macroGroupIdInvalid"
                    >Campo deve ser preenchido</mat-error
                  >
                </div>
                <div style="display: flex">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="removeOrClearMacroGroup($event, i)"
                    matTooltip="Remover"
                  >
                    <mat-icon>highlight_off</mat-icon>
                  </button>
                </div>
              </div>
              <button
                mat-icon-button
                color="primary"
                (click)="addMacroGroupFormGroup($event)"
                matTooltip="Adicionar"
              >
                Adicionar <mat-icon>add_circle_outline</mat-icon>
              </button>
              <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="subAndMacroInvalid"
                >Campo deve ser preenchido</mat-error
              >
            </div>
          </mat-card-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Configurações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Membership Ativo?</mat-label>
              <mat-select
                formControlName="isMembershipActive"
                [disabled]="true"
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isMembershipActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isMembershipActive) }}
              </mat-error>
            </mat-form-field>
            

            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select
                formControlName="typePayment"
                [disabled]="true"
              >
                <mat-option value="cardPaymentAndJcoinsPayment">Acúmulo e Resgate</mat-option>
                <mat-option value="cardPayment">Acúmulo</mat-option>
                <mat-option value="jcoinsPayment" selected>Resgate</mat-option>
                <mat-option value="jcoinsPriorityPayment">Prioridade para Resgate</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.typePayment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.typePayment) }}
              </mat-error>
            </mat-form-field>
            
            <mat-form-field>
              <mat-label>Select Currency</mat-label>
              <mat-select formControlName="currency">
                <mat-option *ngFor="let currency of enumInternationalCurrency" [value]="currency">
                  {{ currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.currency?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.currency) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Dias para a liquidação (padrão: 30)</mat-label>
              <input type="number" matInput formControlName="liquidationDays" />
              <mat-error *ngIf="formGroup.controls.liquidationDays?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.liquidationDays) }}
              </mat-error>
            </mat-form-field>

            <div *ngIf="isUpdate">
              <p style="margin-top: 4px; margin-bottom: 5px"><b>Integrações:&nbsp;</b></p>
              <mat-label>
                <div style="display: flex">
                  <p style="margin-top: 3px; margin-bottom: 5px"><b>Roadpass&nbsp;</b></p>
                  <mat-icon
                    [ngStyle]="{ color: 'green' }"
                    *ngIf="this.isActiveRoadpass"
                    matTooltip="Criado na RoadPass"
                  >
                    check
                  </mat-icon>
                  <mat-icon
                    matTooltip="Erro ao criar na RoadPass"
                    [ngStyle]="{ color: 'red' }"
                    *ngIf="!this.isActiveRoadpass"
                  >
                    close
                  </mat-icon>
                </div>
                <p
                  *ngIf="!this.isActiveRoadpass"
                  style="margin-top: 0px; font-size: 12px; color: red"
                >
                  Click em Salvar para reenviar o cadastro para <u>RoadPass</u>
                </p>
              </mat-label>

              <mat-label>
                <div style="display: flex">
                  <p style="margin-top: 3px; margin-bottom: 5px"><b>Zoop&nbsp;</b></p>
                  <mat-icon
                    [ngStyle]="{ color: 'green' }"
                    *ngIf="this.isZoopEnabled"
                    matTooltip="Criado na Zoop"
                  >
                    check
                  </mat-icon>
                  <mat-icon
                    matTooltip="Erro ao criar na Zoop"
                    [ngStyle]="{ color: 'red' }"
                    *ngIf="!this.isZoopEnabled"
                  >
                    close
                  </mat-icon>
                </div>
                <p *ngIf="!this.isZoopEnabled" style="margin-top: 0px; font-size: 12px; color: red">
                  Click em Salvar para reenviar o cadastro para <u>Zoop</u>
                </p>
              </mat-label>

              <p style="font-size: 20px; margin-bottom: 0px">Campanha Ativa</p>
              <p style="margin-bottom: 5px; text-decoration: underline">
                <b>ID da Campanha:</b> {{ idCampaign }}
              </p>
              <p style="margin-top: 5px; text-decoration: underline">
                <b>Descrição:</b> {{ descriptionCampaign }}
              </p>
            </div>
          </mat-card-content>
        </div>

        <div class="col-12 col-sm-6">
          <mat-card-title>Taxas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Quanto vale um J-Coin na saída?</mat-label>
              <input
                matInput
                type="text"
                formControlName="pointsConversionFactorOut"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.pointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.pointsConversionFactorOut) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa Transação J-Coins</mat-label>
              <input
                matInput
                formControlName="taxRewardsTransaction"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
                [dropSpecialCharacters]="false"
              />
              <mat-error *ngIf="formGroup.controls.taxRewardsTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxRewardsTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Imposto sobre transações com Jcoins</mat-label>
              <input
                matInput
                formControlName="internationalPartnerJcoinTransactionTax"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
                [dropSpecialCharacters]="false"
              />
              <mat-error *ngIf="formGroup.controls.internationalPartnerJcoinTransactionTax?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.internationalPartnerJcoinTransactionTax) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Conta Bancária</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Bank</mat-label>
              <input type="text" matInput formControlName="bankName" />
              <mat-error *ngIf="formGroup.controls.bankName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.bankName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Agency</mat-label>
              <input matInput formControlName="agency" />
              <mat-error *ngIf="formGroup.controls.agency?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.agency) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Account Number</mat-label>
              <input matInput formControlName="accountNumber" />
              <mat-error *ngIf="formGroup.controls.accountNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Digit</mat-label>
              <input matInput formControlName="accountDigit" />
              <mat-error *ngIf="formGroup.controls.accountDigit?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountDigit) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Type</mat-label>
              <mat-select formControlName="accountType">
                <mat-option [value]="'CHECKING'">Corrente</mat-option>
                <mat-option [value]="'SAVINGS'">Poupança</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.accountType?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountType) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
