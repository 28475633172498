import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
import * as _ from 'lodash';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var MembershipsPlansListComponent = /** @class */ (function () {
    function MembershipsPlansListComponent(snackBar, membershipService, dialog, route) {
        this.snackBar = snackBar;
        this.membershipService = membershipService;
        this.dialog = dialog;
        this.route = route;
        this.headersTable = [
            'name',
            'beginDate',
            'endDate',
            'value',
            'recurrencePeriod',
            'active',
            'type',
            'actions',
        ];
        this.headersTableIsFree = [
            'name',
            'beginDate',
            'endDate',
            'value',
            'recurrencePeriod',
            'active',
            'actions',
        ];
        this.headersTableIsDefault = [
            'name',
            'beginDate',
            'endDate',
            'value',
            'recurrencePeriod',
            'active',
            'actions',
        ];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    MembershipsPlansListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                if (_this.planId) {
                    _this.planFindOne();
                }
                _this.initForm();
            }
            else {
                if (_this.planId) {
                    _this.planFindOne();
                }
                else {
                    _this.listPlans();
                    _this.listPlansOthers();
                    _this.initForm();
                }
            }
        });
        this.planId = this.route.snapshot.paramMap.get('planID');
    };
    MembershipsPlansListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'namePlanMembership',
                label: 'Plano Membership',
                placeholder: 'Plano Membership',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Início',
                formControlName: 'beginDatePlanMembership',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Final',
                formControlName: 'endDatePlanMembership',
            },
            {
                field: 'select',
                formControlName: 'activePlanMembership',
                label: 'Status',
                placeholder: 'Status',
                list: [
                    { key: 'ATIVO', value: 'ATIVO' },
                    { key: 'TODOS', value: 'TODOS' },
                    { key: 'INATIVO', value: 'INATIVO' },
                ],
            },
            {
                field: 'select',
                formControlName: 'typePlanMembership',
                label: 'Tipo',
                placeholder: 'Tipo',
                list: [
                    { key: 'recurrent', value: 'RECORRENTE' },
                    { key: 'pj', value: 'PJ' },
                    { key: 'all', value: 'TODOS' },
                ],
            },
        ];
    };
    MembershipsPlansListComponent.prototype.listPlans = function () {
        var _this = this;
        this.membershipService
            .listPlans("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"isActive\"], \"op\": \"=\", \"value\":\"1\", \"fields\":[\"isFree\"], \"op\": \"=\", \"value\":\"1\"}]}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSourceIsFree = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.membershipService
            .listPlans("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"isActive\"], \"op\": \"=\", \"value\":\"1\", \"fields\":[\"isDefault\"], \"op\": \"=\", \"value\":\"1\"}]}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSourceIsDefault = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipsPlansListComponent.prototype.listPlansOthers = function () {
        var _this = this;
        this.membershipService
            .listPlansOthers("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"isActive\"], \"op\": \"=\", \"value\":\"1\"}]}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipsPlansListComponent.prototype.planFindOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.membershipService
                    .listPlans("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"id\"], \"op\": \"=\", \"value\":\"" + this.planId + "\"}]}")
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var plan;
                    return tslib_1.__generator(this, function (_a) {
                        plan = response.results.find(function () { return true; });
                        this.fieldsForm = [
                            {
                                field: 'input',
                                type: 'text',
                                formControlName: 'namePlanMembership',
                                label: 'Plano Membership',
                                placeholder: 'Plano Membership',
                                valueDefault: plan.name,
                            },
                            {
                                field: 'input',
                                type: 'date',
                                label: 'Data Início',
                                formControlName: 'beginDatePlanMembership',
                            },
                            {
                                field: 'input',
                                type: 'date',
                                label: 'Data Final',
                                formControlName: 'endDatePlanMembership',
                            },
                            {
                                field: 'select',
                                formControlName: 'activePlanMembership',
                                label: 'Status',
                                placeholder: 'Status',
                                list: [
                                    { key: 'ATIVO', value: 'ATIVO' },
                                    { key: 'TODOS', value: 'TODOS' },
                                    { key: 'INATIVO', value: 'INATIVO' },
                                ],
                            },
                            {
                                field: 'select',
                                formControlName: 'typePlanMembership',
                                label: 'Tipo',
                                placeholder: 'Tipo',
                                list: [
                                    { key: 'recurrent', value: 'RECORRENTE' },
                                    { key: 'pj', value: 'PJ' },
                                    { key: 'all', value: 'TODOS' },
                                ],
                            },
                        ];
                        if (plan.isDefault) {
                            this.dataSourceIsDefault = new MatTableDataSource(response.results);
                        }
                        else if (plan.isFree) {
                            this.dataSourceIsFree = new MatTableDataSource(response.results);
                        }
                        else {
                            this.pageTotal = response.total;
                            this.dataSource = new MatTableDataSource(response.results);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    MembershipsPlansListComponent.prototype.group = function (data) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        var isPj = data.filter(function (p) { return p.isPj === 1; });
        var isDefault = data.filter(function (p) { return p.isDefault === 1; });
        var isFree = data.filter(function (p) { return p.isFree === 1; });
        var others = data.filter(function (p) { return p.isFree !== 1 && p.isDefault !== 1 && p.isPj !== 1; });
        var groupsPj = _(isPj)
            .groupBy(function (v) { return v.isPj; })
            .value();
        var groupsDefault = _(isDefault)
            .groupBy(function (v) { return v.isDefault; })
            .value();
        var groupsFree = _(isFree)
            .groupBy(function (v) { return v.isFree; })
            .value();
        var groupsOthers = _(others)
            .groupBy(function (v) { return v.others; })
            .value();
        var result = [];
        for (var group in groupsDefault) {
            result.push({ group: 'Plano Recorrente Default' });
            try {
                for (var _e = tslib_1.__values(groupsDefault[group]), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var item = _f.value;
                    result.push(item);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        for (var group in groupsFree) {
            result.push({ group: 'Free' });
            try {
                for (var _g = tslib_1.__values(groupsFree[group]), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var item = _h.value;
                    result.push(item);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        for (var group in groupsPj) {
            result.push({ group: 'PJ' });
            try {
                for (var _j = tslib_1.__values(groupsPj[group]), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var item = _k.value;
                    result.push(item);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        for (var group in groupsOthers) {
            result.push({ group: 'Outros' });
            try {
                for (var _l = tslib_1.__values(groupsOthers[group]), _m = _l.next(); !_m.done; _m = _l.next()) {
                    var item = _m.value;
                    result.push(item);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return result;
    };
    MembershipsPlansListComponent.prototype.isGroup = function (index, item) {
        return !!item.group;
    };
    MembershipsPlansListComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    MembershipsPlansListComponent.prototype.month = function (recurrencePeriod) {
        if (recurrencePeriod == 0) {
            return '';
        }
        else if (recurrencePeriod > 1) {
            return recurrencePeriod + " meses";
        }
        else {
            return recurrencePeriod + " m\u00EAs";
        }
    };
    MembershipsPlansListComponent.prototype.typePlan = function (type) {
        if (type) {
            return 'PJ';
        }
        else {
            return 'Recorrente';
        }
    };
    MembershipsPlansListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja inativar esse Plano Membership?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 2];
                        data = {
                            isActive: false,
                        };
                        return [4 /*yield*/, this.membershipService.editPlan(id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.listPlans();
                                    this.snackBar.open('Plano Membership inativo.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    MembershipsPlansListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.listPlans();
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return MembershipsPlansListComponent;
}());
export { MembershipsPlansListComponent };
