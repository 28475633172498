import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { AuthService } from 'src/app/services/auth.service'
import { CampaignService } from 'src/app/services/campaign.service'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import { UploadAdapter } from 'src/app/services/uploadAdapter.service'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MacroGroupsService } from 'src/app/services/macro-groups.service'
import { StoreService } from './../../../../services/store.service'
import numeral from 'numeral'
import { ModalVerifyStoreCampaignTypeComponent } from './../../../../components/modal-verify-store-campaign-type/modal-verify-store-campaign-type.component'
import { ModalVerifyInitialCampaignDateComponent } from 'src/app/components/modal-verify-initial-campaign-date/modal-verify-initial-campaign-date.component'

type MacroGroup = {
  id: string
  heading: string
}
@Component({
  selector: 'app-campaigns-form',
  templateUrl: './campaigns-form.component.html',
  styleUrls: ['./campaigns-form.component.scss'],
})
export class CampaignsFormComponent implements OnInit {
  partnersSearchText = ''
  public formGroup: FormGroup
  public campaignID: string
  public Editor = ClassicEditor
  public params = { formGroupValue: [] }
  public contentInvalid = false
  public initalized = false
  public config: any
  public macroGroups: any[] = []
  public partnersList: any[] = []
  public titleCategory: string
  public macroGroupControl = new FormControl()
  separatorKeysCodes: number[] = [ENTER, COMMA]
  filteredMacroGroups: any
  public selectedMacroGroups: any[] = []
  public selectedMacroGroupsPartnersList: any = []
  public parsedStoresIdsWithMacroGroups: any = []
  public parsedStoresIdsWithOutMacroGroups: any = []
  public isCampaignInfoChanged: boolean

  @ViewChild('macroGroupsInput', { static: false }) macroGroupsInput: ElementRef<HTMLInputElement>

  constructor (
    public macroGroupsService: MacroGroupsService,
    public storeService: StoreService,
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public campaignService: CampaignService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    // deploy
    this.isCampaignInfoChanged = false
    this.campaignID = this.route.snapshot.paramMap.get('campaignID')
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    if (this.campaignID) {
      this.loadInfos()
    } else {
      this.loadStores()
      this.loadMacroGroups()
    }
    this.initForm().catch(err => console.log(err))
    this.config = {
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }
  }

  async initForm () {
    this.formGroup = this.formBuilder.group({
      description: [null, []],
      campaignTypes: ['ACCUMULATION', [Validators.required]],
      isConvertDollar: [false, [Validators.required]],
      convertionFactor: [null, [Validators.required]],
      spendingFactor: [false, [Validators.required]],
      spendingValue: [null, []],
      fixedPoints: [false, []],
      fixedPointsAmount: [null, []],
      confirmationCampaignType: ['PENDING_POINTS', [Validators.required]],
      confirmationCampaign: ['DENY_PARTIAL', [Validators.required]],
      expirationDaysAmount: [null, []],
      amountDayAfterConfirmated: [null, []],
      isAllowedExpirationPointsDate: [false, [Validators.required]],
      pointsExpirationDate: [null, []],
      pointsExpirationTime: [null, []],
      campaignInitialDate: [null, [Validators.required]],
      campaignInitialTime: [null, [Validators.required]],
      campaignEndDate: [null, []],
      campaignEndTime: [null, []],
      partnersSearchText: ['', []],
      isDefault: [false, [Validators.required]],
    })
  }

  loadInfos () {
    this.campaignService.findOne(this.campaignID).subscribe(response => {
      const parsedPoinstExpirationDate =
        response.pointsExpirationDate ? response.pointsExpirationDate.split('T')[0] : null
      const parsedPointsExpirationTime =
        response.pointsExpirationDate ? this.getTime(response.pointsExpirationDate) : null
      const parsedCampaignInitialDate =
        response.campaignInitialDate ? response.campaignInitialDate.split('T')[0] : null
      const parsedCampaignInitialTime =
        response.campaignInitialDate ? this.getTime(response.campaignInitialDate) : null
      const parsedCampaignEndDate =
        response.campaignEndDate ? response.campaignEndDate.split('T')[0] : null
      const parsedCampaignEndTime =
        response.campaignEndDate ? this.getTime(response.campaignEndDate) : null
      this.formGroup.patchValue({
        description: response.description,
        campaignTypes: response.campaignTypes,
        isConvertDollar: response.isConvertDollar,
        convertionFactor: numeral(response.convertionFactor).multiply(100).value(),
        spendingFactor: !!response.spendingValue,
        spendingValue: response.spendingValue,
        fixedPoints: !!response.fixedPoints,
        fixedPointsAmount: response.fixedPoints,
        confirmationCampaignType: response.confirmationCampaignType,
        confirmationCampaign: response.confirmationCampaign,
        expirationDaysAmount: response.expirationDaysAmount,
        amountDayAfterConfirmated: response.amountDayAfterConfirmated,
        isAllowedExpirationPointsDate: !!response.pointsExpirationDate,
        pointsExpirationDate: parsedPoinstExpirationDate,
        pointsExpirationTime: parsedPointsExpirationTime,
        campaignInitialDate: parsedCampaignInitialDate,
        campaignInitialTime: parsedCampaignInitialTime,
        campaignEndDate: parsedCampaignEndDate,
        campaignEndTime: parsedCampaignEndTime,
        isDefault: response.isDefault,
      })
      this.changeConvertDollarEnabled({ value: response.isConvertDollar })
      this.changeSpendingFactorEnabled({ value: !!response.spendingValue })
      this.changeFixedPointsEnabled({ value: !!response.fixedPoints })
      this.changeIsAllowedExpirationPointsDateEnabled({ value: !!response.pointsExpirationDate })

      const { storesIds } = response
      const storesWithMacro = []
      const macroGroups = []
      const storesWithoutMacro = []
      storesIds.map((store) => {
        if (store.macroGroupId) {
          if (!macroGroups.includes(store.macroGroupId)) {
            macroGroups.push(store.macroGroupId)
          }
          storesWithMacro.push(store.storesId)
        } else {
          storesWithoutMacro.push(store.storesId)
        }
      })
      this.loadStores(storesWithoutMacro)
      this.loadMacroGroups(storesWithMacro, macroGroups)
      this.isCampaignInfoChanged = false
    })
  }

  loadMacroGroups (storesWithMacro?: string[], macroGroups?: string[]) {
    this.macroGroupsService.list().then(
      response => {
        this.macroGroups = response.results
        const alreadySelectedMacroGroupsList = []
        this.filteredMacroGroups = this.macroGroups
          .map(macro => {
            if (macroGroups && macroGroups.includes(macro.id)) {
              alreadySelectedMacroGroupsList.push({ id: macro.id, heading: macro.macroName })
            }
            return { id: macro.id, heading: macro.macroName }
          })
        alreadySelectedMacroGroupsList.map(macro =>
          this.chargeSelectedMacroGroups(
            macro,
            storesWithMacro),
        )
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  onChange ({ editor }: ChangeEvent) {
    if (!this.initalized) {
      return
    }
    this.contentInvalid = !this.formGroup.value.spendingValue
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  changeConvertDollarEnabled (event) {
    if (event.value === true) {
      this.formGroup.get('convertionFactor').setValidators([Validators.required])
    } else {
      this.formGroup.get('convertionFactor').setValidators(null)
    }
  }

  changeSpendingFactorEnabled (event) {
    if (event.value === true) {
      this.formGroup.get('spendingValue').setValidators([Validators.required])
      this.formGroup.get('spendingValue').updateValueAndValidity()
    } else {
      this.formGroup.get('spendingValue').setValidators(null)
      this.formGroup.get('spendingValue').setErrors(null)
    }
  }

  changeFixedPointsEnabled (event) {
    if (event.value === true) {
      this.formGroup.get('fixedPointsAmount').setValidators([Validators.required])
      this.formGroup.get('fixedPointsAmount').updateValueAndValidity()
    } else {
      this.formGroup.get('fixedPointsAmount').setValidators(null)
      this.formGroup.get('fixedPointsAmount').setErrors(null)
    }
  }

  changeIsAllowedExpirationPointsDateEnabled (event) {
    if (event.value === true) {
      this.formGroup.get('pointsExpirationDate').setValidators([Validators.required])
      this.formGroup.get('pointsExpirationDate').updateValueAndValidity()
      this.formGroup.get('pointsExpirationTime').setValidators([Validators.required])
      this.formGroup.get('pointsExpirationTime').updateValueAndValidity()
    } else {
      this.formGroup.get('pointsExpirationDate').setValidators(null)
      this.formGroup.get('pointsExpirationDate').setErrors(null)
      this.formGroup.get('pointsExpirationTime').setValidators(null)
      this.formGroup.get('pointsExpirationTime').setErrors(null)
    }
  }

  removeMacroGroup (macroGroup: any, macroGroupIndex: string): void {
    const index = this.selectedMacroGroups.indexOf(macroGroup)
    if (index >= 0) {
      this.selectedMacroGroups.splice(index, 1)
      this.filteredMacroGroups.push(macroGroup)
      this.selectedMacroGroupsPartnersList[macroGroupIndex]
        .partners.map((partnerMacro) => {
          this.partnersList.map(partner => {
            if (partner.id === partnerMacro.storeId) {
              partner.disabled = false
            }
          })
        })
      this.formGroup.removeControl(macroGroup.id)
      this.selectedMacroGroupsPartnersList.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    this.isCampaignInfoChanged = true
    if (event.option.value.id) {
      this.selectedMacroGroups.push(event.option.value)
      this.macroGroupsInput.nativeElement.value = ''
      this.macroGroupControl.setValue(null)
    } else {
      this.selectedMacroGroups.push(event.option.value)
      this.macroGroupsInput.nativeElement.value = ''
      this.macroGroupControl.setValue(null)
    }
    this.filteredMacroGroups = this.filteredMacroGroups
      .filter(item => item.id !== event.option.value.id)
    this.loadMacroGroupPartners(event.option.value.id)
  }

  chargeSelectedMacroGroups (macro: MacroGroup, selectedStoresIds?: string[]) {
    if (macro.id) {
      this.selectedMacroGroups.push(macro)
      this.macroGroupsInput.nativeElement.value = ''
      this.macroGroupControl.setValue(null)
    } else {
      this.selectedMacroGroups.push(macro)
      this.macroGroupsInput.nativeElement.value = ''
      this.macroGroupControl.setValue(null)
    }
    this.filteredMacroGroups = this.filteredMacroGroups
      .filter(item => item.id !== macro.id)
    this.loadMacroGroupPartners(macro.id, selectedStoresIds)
  }

  loadStores (storesWithoutMacro?: string[]) {
    this.storeService
      .listStores()
      .toPromise().then(
        async response => {
          if (storesWithoutMacro) {
            this.partnersList = response
              .map(partner => {
                if (storesWithoutMacro.includes(partner.id)) {
                  return {
                    id: partner.id,
                    nomeFantasia: partner.nomeFantasia,
                    checked: true,
                    disabled: false,
                  }
                }
                return {
                  id: partner.id,
                  nomeFantasia: partner.nomeFantasia,
                  checked: false,
                  disabled: false,
                }
              },
              )
          } else {
            this.partnersList = response
              .map(partner => (
                {
                  id: partner.id,
                  nomeFantasia: partner.nomeFantasia,
                  checked: false,
                  disabled: false,
                }))
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  loadMacroGroupPartners (id: string, selectedStoresIds?: string[]) {
    this.macroGroupsService.fetchMacroGroupPartners(id)
      .then(response => {
        const parsedPartners = response.results.map(partner => {
          if (selectedStoresIds && this.campaignID) {
            if (selectedStoresIds.includes(partner.storeId)) {
              return { ...partner, checked: true }
            }
            return { ...partner, checked: false }
          }
          return { ...partner, checked: true }
        },
        )
        this.selectedMacroGroupsPartnersList.push({ id: id, partners: parsedPartners })
        this.formGroup.addControl(id, new FormControl('', []))
      })
      .catch(err => console.log(err))
  }

  changePartnerCheckbox (event, macroGroupIndex: string) {
    this.isCampaignInfoChanged = true
    this.selectedMacroGroupsPartnersList[macroGroupIndex].partners
      .map((partner) => {
        if (partner.storeId === event.target.value) {
          partner.checked = event.target.checked
        }
      })
  }

  changeFromStoreListCheckbox (event) {
    this.isCampaignInfoChanged = true
    this.partnersList
      .map((partner) => {
        if (partner.id === event.target.value) {
          partner.checked = event.target.checked
        }
      })
  }

  getTime (datetime: Date) {
    return `${new Date(datetime).getHours().toString()}:${(new Date(datetime).getMinutes() < 10 ? '0' : '')}${new Date(datetime).getMinutes().toString()}`
  }

  getConvertionFactor (convertionFactor: string) {
    if (convertionFactor) {
      return parseFloat(`0.${convertionFactor.substring(1)}`)
    }
    return 0.0
  }

  async openDialogStoresVerificationType (data) {
    const dialogRef = this.dialog.open(ModalVerifyStoreCampaignTypeComponent, {
      width: '500px',
      data: data,
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        // result is returned with an array of partners to be removed
        this.parsedStoresIdsWithMacroGroups
          .map((macro, i) => {
            macro[Object.keys(macro)[0]].map(store => {
              result.forEach(item => item.map(i => {
                if (i.id === store) {
                  // Filtering every item other than store
                  this.parsedStoresIdsWithMacroGroups
                    .forEach((m, index) => {
                      m[Object.keys(m)[0]] =
                        m[Object.keys(m)[0]].filter(str => str !== store)
                    },
                    )
                }
              }))
            })
          },
          )
        if (this.parsedStoresIdsWithOutMacroGroups.length) {
          this.parsedStoresIdsWithOutMacroGroups
            .map(store => {
              result.forEach(item => item.map(i => {
                if (i.id === store) {
                  // Filtering every item other than store
                  this.parsedStoresIdsWithOutMacroGroups =
                    this.parsedStoresIdsWithOutMacroGroups
                      .filter(str => str !== store)
                }
              }),
              )
            })
        }
        this.submit()
      }
    })
  }

  async openDialogVerifyInitialCampaignDate () {
    const dialogRef = this.dialog.open(ModalVerifyInitialCampaignDateComponent, {
      width: '500px',
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'continue') {
        this.verifyStores()
      }
    })
  }

  handleCampaignInfoChanging () {
    this.isCampaignInfoChanged = true
  }

  verifyCampaignInitialDate () {
    const initialDate = new Date(`${this.formGroup.get('campaignInitialDate').value} ${this.formGroup.get('campaignInitialTime').value}`)

    const differenceInTime = initialDate.getTime() - new Date().getTime()

    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24))

    if (differenceInDays <= 4) {
      this.openDialogVerifyInitialCampaignDate().catch(err => console.log(err))
    } else {
      this.verifyStores()
    }
  }

  verifyStores () {
    if (this.formGroup.value.isDefault) {
      this.formGroup.patchValue({
        ...this.formGroup,
        campaignEndDate: null,
        campaignEndTime: null,
      })
    }
    //  Filtering only checked partners and creating a macro group id key based Object from all selected macro groups
    this.parsedStoresIdsWithMacroGroups = this.selectedMacroGroupsPartnersList.map((macro) => {
      const obj = Object()
      const ids = macro.partners.filter((partner) => (partner.checked))
        .map((partner) => (partner.storeId))
      obj[macro.id] = ids
      return obj
    })
    // Filtering only checked partners from non macro group list for campaigns binding verification purpose
    const parsedStoreIdsList = this.partnersList
      .filter(partner => partner.checked)
      .map(partner => ({ id: partner.id, nomeFantasia: partner.nomeFantasia }))
    this.parsedStoresIdsWithOutMacroGroups = parsedStoreIdsList.map((store) => store.id)

    console.log(this.formGroup.value.campaignInitialTime)

    if (this.isCampaignInfoChanged) {
      const body = {
        storesIdsWithMacroGroups: this.parsedStoresIdsWithMacroGroups,
        storesIds: this.parsedStoresIdsWithOutMacroGroups,
        isDefault: this.formGroup.value.isDefault,
        campaignId: this.campaignID ? this.campaignID : undefined,
        campaignInitialDate: this.formGroup.value.campaignInitialDate ? `${this.formGroup.value.campaignInitialDate} ${this.formGroup.value.campaignInitialTime}:00` : null,
        campaignEndDate: this.formGroup.value.campaignEndDate ? `${this.formGroup.value.campaignEndDate} ${this.formGroup.value.campaignEndTime}:00` : null,
      }

      this.campaignService.verifyStores(body)
        .toPromise().then(
          async response => {
            if (response.results && response.results.length) {
              response.results = response.results
                .map(campaign =>
                  ({
                    description: campaign.description,
                    stores: campaign.stores
                      .map(store => (
                        { id: store.id, nomeFantasia: store.nomeFantasia, checked: true })),
                  })
                  ,
                )
              const body = {
                results: response.results,
              }
              this.openDialogStoresVerificationType(
                body,
              ).catch(err => console.log(err))
            } else {
              this.submit().catch(err => console.log(err))
            }
          },
          error => console.log(error),
        )
    } else {
      this.submit().catch(err => console.log(err))
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const parsedPointsExpirationDate =
      this.formGroup.value.isAllowedExpirationPointsDate ? `${this.formGroup.value.pointsExpirationDate} ${this.formGroup.value.pointsExpirationTime}:00` : null
    const parsedcampaignInitialDate =
      this.formGroup.value.campaignInitialDate ? `${this.formGroup.value.campaignInitialDate} ${this.formGroup.value.campaignInitialTime}:00` : null
    const parsedCampaignEndDate =
      this.formGroup.value.campaignEndDate ? `${this.formGroup.value.campaignEndDate} ${this.formGroup.value.campaignEndTime}:00` : null
    // const parsedConvertionFactor = this.getConvertionFactor(this.formGroup.value.convertionFactor)
    let data
    data = {
      description: this.formGroup.value.description,
      campaignTypes: this.formGroup.value.campaignTypes,
      isConvertDollar: this.formGroup.value.isConvertDollar,
      convertionFactor: numeral(Number(this.formGroup.value.convertionFactor)).divide(100).value(),
      spendingValue: this.formGroup.value.spendingValue,
      fixedPoints: this.formGroup.value.fixedPointsAmount,
      confirmationCampaignType: this.formGroup.value.confirmationCampaignType,
      confirmationCampaign: this.formGroup.value.confirmationCampaign,
      expirationDaysAmount: this.formGroup.value.expirationDaysAmount,
      amountDayAfterConfirmated: this.formGroup.value.amountDayAfterConfirmated,
      pointsExpirationDate: parsedPointsExpirationDate,
      campaignInitialDate: parsedcampaignInitialDate,
      campaignEndDate: parsedCampaignEndDate,
      storesIdsWithMacroGroups: this.parsedStoresIdsWithMacroGroups,
      storesIds: this.parsedStoresIdsWithOutMacroGroups,
      isDefault: this.formGroup.value.isDefault,
    }
    if (this.campaignID) {
      await this.campaignService.update(this.campaignID, data).then(async response => {
        this.snackBar.open('Campanha editada com sucesso.')
        this.router.navigate(['./dashboard/campaign'])
      },
      error => {
        this.snackBar.open(error.error.message)
      },
      )
    } else {
      await this.campaignService.create(data).then(async response => {
        if (response.isCreated) {
          this.snackBar.open('Campanha criada com sucesso.')
          this.router.navigate(['./dashboard/campaign'])
        }
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
      )
    }
  }
}
