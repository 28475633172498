import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class PrepaymentsService {
  public loading

  constructor (private readonly http: HttpClient) {}

  listAll (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/prepayments`).pipe(
      tap(_ => this.log('/prepayments')),
      catchError(this.handleError),
    )
  }

  postPrepayment (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/prepayments`, data).pipe(
      tap(_ => this.log('/prepayments')),
      catchError(this.handleError),
    )
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}
}
