import { OnInit } from '@angular/core';
var QuestionsShowImageComponent = /** @class */ (function () {
    function QuestionsShowImageComponent() {
        this.imageQuestion = '';
    }
    QuestionsShowImageComponent.prototype.ngOnInit = function () {
        this.imageQuestion = localStorage.getItem('imageQuestion');
    };
    return QuestionsShowImageComponent;
}());
export { QuestionsShowImageComponent };
