import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { QuizService } from '../../../../services/quiz.service'
import { StoreService } from '../../../../services/store.service'
import { Location } from '@angular/common'
import * as moment from 'moment'
@Component({
  selector: 'app-answer-customer-list',
  templateUrl: './answer-customer-list.component.html',
  styleUrls: ['./answer-customer-list.component.scss'],
})
export class AnswerCustomerListComponent implements OnInit {
  public headersTable: string[]
  public pageTotal: number
  public customerID = ''
  public quizID = ''
  public answersAndQuestions: any[]
  public nameQuiz = ''
  public fullName = ''
  dataSource: any
  fieldsForm: any
  public params = { formGroupValue: [] }

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quizService: QuizService,
    public storeService: StoreService,
    public location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.customerID = this.route.snapshot.paramMap.get('customerID')
    this.quizID = this.route.snapshot.paramMap.get('quizID')

    if (this.quizID) {
      this.quizService.findOne(this.quizID).then(
        response => {
          this.nameQuiz = response.name
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }

    if (this.customerID) {
      this.storeService.listUsersCustomers(`/${this.customerID}`).subscribe(
        async response => {
          this.fullName = `${response.firstName}  ${response.lastName}`
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
    }

    this.quizanswersAndQuestions()
  }

  convertAnswer (view, answer) {
    switch (view) {
      case 'DATE':
        return moment(answer).format('LL')
        break
      case 'DATE-TIME':
        return moment(answer).format('LLL')
        break
      case 'TIME':
        return moment(answer).format('LT')
        break
      default:
        return answer
    }
  }

  quizanswersAndQuestions () {
    this.quizService.listanswersAndQuestions(this.quizID, `?customerId=${this.customerID}`).then(
      async response => {
        this.answersAndQuestions = response.questions
        this.pageTotal = this.answersAndQuestions.length
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }

  backPage () {
    this.location.back()
  }
}
