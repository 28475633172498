import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PrepaymentsService = /** @class */ (function () {
    function PrepaymentsService(http) {
        this.http = http;
    }
    PrepaymentsService.prototype.listAll = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/prepayments").pipe(tap(function (_) { return _this.log('/prepayments'); }), catchError(this.handleError));
    };
    PrepaymentsService.prototype.postPrepayment = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/prepayments", data).pipe(tap(function (_) { return _this.log('/prepayments'); }), catchError(this.handleError));
    };
    PrepaymentsService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    PrepaymentsService.prototype.log = function (message) { };
    PrepaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrepaymentsService_Factory() { return new PrepaymentsService(i0.ɵɵinject(i1.HttpClient)); }, token: PrepaymentsService, providedIn: "root" });
    return PrepaymentsService;
}());
export { PrepaymentsService };
