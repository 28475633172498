import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomersAssociatesService = /** @class */ (function () {
    function CustomersAssociatesService(http) {
        this.http = http;
    }
    CustomersAssociatesService.prototype.list = function (fundId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/funds/" + fundId + "/customers-associates" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    CustomersAssociatesService.prototype.add = function (fundId, customerIdList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/funds/" + fundId + "/customers-associates", { customerIdList: customerIdList })
                        .toPromise()];
            });
        });
    };
    CustomersAssociatesService.prototype.remove = function (fundId, associatedId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/funds/" + fundId + "/customers-associates/" + associatedId)
                        .toPromise()];
            });
        });
    };
    CustomersAssociatesService.prototype.listCustomers = function (fundId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/funds/" + fundId + "/customers").toPromise()];
            });
        });
    };
    CustomersAssociatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomersAssociatesService_Factory() { return new CustomersAssociatesService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomersAssociatesService, providedIn: "root" });
    return CustomersAssociatesService;
}());
export { CustomersAssociatesService };
