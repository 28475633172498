import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  public loading

  constructor (private readonly http: HttpClient) {}

  listCategories (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/categories`).pipe(
      tap(_ => this.log('*/categories')),
      catchError(this.handleError),
    )
  }

  viewCategories (categoriesID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/categories/${categoriesID}`).pipe(
      tap(_ => this.log('*/categories')),
      catchError(this.handleError),
    )
  }

  addCategories (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/categories`, data).pipe(
      tap(_ => this.log('cms/categories')),
      catchError(this.handleError),
    )
  }

  upload (data): Observable<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    return this.http
      .post<any>(`${environment.apiUrl}/cms/categories/images`, data, { headers })
      .pipe(
        tap(_ => this.log('cms/categories/images')),
        catchError(this.handleError),
      )
  }

  updateCategories (data, categoriesID): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/categories/${categoriesID}`, data).pipe(
      tap(_ => this.log('cms/categories')),
      catchError(this.handleError),
    )
  }

  deleteCategories (categoriesID): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/categories/${categoriesID}`).pipe(
      tap(_ => this.log('cms/categories')),
      catchError(this.handleError),
    )
  }

  async getS3Url (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/categories/s3-slot`).toPromise()
  }

  async uploadToS3 (pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }

  async findOne (id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/categories/${id}`).toPromise()
  }

  async listSubCategories (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/categories/${id}/subcategories`)
      .toPromise()
  }

  async subcategoryFindOne (id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/subcategories/${id}`).toPromise()
  }

  async deleteSubCategories (id): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/subcategories/${id}`).toPromise()
  }

  async addSubCategories (data): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/subcategories`, data).toPromise()
  }

  async updateSubCategories (data, id): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/subcategories/${id}`, data).toPromise()
  }

  async listExclusives (id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/category/${id}/exclusive/icons`)
      .toPromise()
  }

  async deleteExclusive (exclusiveId): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/category/exclusive/${exclusiveId}`)
      .toPromise()
  }

  async exclusiveGetS3Url (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/categories/exclusive/s3-slot`).toPromise()
  }

  async addExclusive (id, data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/category/${id}/exclusive/icon`, data)
      .toPromise()
  }

  async exclusiveFindOne (exclusiveId): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/category/exclusive/${exclusiveId}`)
      .toPromise()
  }

  async updateExclusive (exclusiveId, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/category/exclusive/${exclusiveId}`, data)
      .toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}
}
