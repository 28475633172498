import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import JsFileDownloader from 'js-file-downloader'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import { ArchitectClosingsService } from 'src/app/services/architectClosings.service'
import numeral from 'numeral'

@Component({
  selector: 'architect-closings-list-for-store',
  templateUrl: './architect-closing-list-for-store.component.html',
  styleUrls: ['./architect-closing-list-for-store.component.scss'],
})
export class ArchitectClosingListForStore implements OnInit {
  public headersTable: string[] = ['closingNumber', 'startDate', 'endDate', 'totalNdTax', 'totalNfTax', 'totalPay', 'totalInvoices', 'totalInvoicesValue', 'actions']
  public closingsList

  public selectedStoreBusinessUnit: any
  public storeBusinessUnitId: any

  pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    private readonly architectClosingsService: ArchitectClosingsService,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected'))
    this.storeBusinessUnitId = this.selectedStoreBusinessUnit.storeId
  }

  ngOnInit () {
    this.loadClosings()
  }

  async loadClosings () {
    const { total, results } = await this.architectClosingsService.findAllClosingsByStore({
      page: this.page,
      range: this.pageSize,
      storeBusinessUnitId: this.storeBusinessUnitId,
    })

    this.pageTotal = total
    this.closingsList = new MatTableDataSource(results)
  }

  filterCallback (returnFilter) {
    const { total, results } = returnFilter
    this.pageTotal = total
    this.closingsList = new MatTableDataSource(results)
  }

  goBack () {
    this.location.back()
  }

  async export (architectClosingId) {
    try {
      const response = await this.architectClosingsService.exportClosingByStore(architectClosingId, this.storeBusinessUnitId)
      if (response.report) {
        new JsFileDownloader({
          url: response.report,
          nameCallback: (fileName) => {
            return (
              'Relatório de Fechamento Arquitetos.' +
              (fileName.split('.').pop() || 'xls')
            )
          },
        })
      }
    } catch (error) {
      this.snackBar.open(error.error.message)
    }
  }

  convertCentsToReal (value) {
    if (value) { return value / 100 } else return value
  }

  totalPay (taxCashback: number, taxAdm: number) {
    const total = Number(taxCashback) + Number(taxAdm)
    return numeral(Number(total)).divide(100).value()
  }
}
