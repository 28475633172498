<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">Cadastro da taxa cashback do parceiro de origem</h2>
    <h2 *ngIf="id">Editar da taxa cashback do parceiro de origem</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/airports/tax-cashback">
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="store" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME FANTASIA: </b>{{ store.nomeFantasia }}</div>
      <div><b>CNPJ: </b>{{ store.cnpj | mask: '00.000.000/0000-00' }}</div>
    </mat-card>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <mat-form-field class="example-chip-list">
              <mat-label>Parceiro</mat-label>
              <mat-chip-list #chipListStore>
                <mat-chip
                  *ngFor="let store of selectedStores"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="removeStore(store)"
                >
                  {{ store.nomeFantasia }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  matInput
                  #storeInput
                  [formControl]="storeControl"
                  [matAutocomplete]="autoStore"
                  [matChipInputFor]="chipListStore"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoStore="matAutocomplete"
                (optionSelected)="selectedStore($event)"
              >
                <mat-option *ngFor="let store of filteredStores | async" [value]="store">
                  {{ store.nomeFantasia }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field>
              <mat-label>Parceiro</mat-label>
              <input matInput formControlName="storeId" (input)="valueInput($event)" />
              <mat-error *ngIf="formGroup.controls.storeId?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.storeId) }}
              </mat-error>
            </mat-form-field> -->
            <mat-form-field>
              <mat-label>Taxa Cashback</mat-label>
              <input
                matInput
                formControlName="taxCashback"
                mask="separator.4"
                decimalMarker=","
                suffix="%"
                [dropSpecialCharacters]="false"
              />
              <mat-error *ngIf="formGroup.controls.taxCashback?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.taxCashback) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
