import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public loading

  public

  constructor (private readonly http: HttpClient) { }

  create (filter?): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/${filter}`, {
      responseType: 'blob' as 'json',
    })
  }

  storeSellsReport (query): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/transactions-of-period?${query}`)
  }

  async createReport (filter?: any): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/${filter}`).toPromise()
  }

  async exportReport(filter: string): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/cms/consolidated-jcoins/export${filter}`, {
        responseType: 'text'
      })
      .toPromise();
  }
  

  async createReportPreRegistration (filter?: string): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/pre-registration${filter}`).toPromise()
  }

  listCustomers (): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/customer`)
  }

  listCustomersArchitects (): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/customer-architects`)
  }

  listStores (): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/stores`)
  }

  getStatus (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/transactions/status`)
  }

  closingIncorporations (query): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/incorporations/closing-incorporations?closingIncorporationsId=${query}`)
  }

  async closingAirportsExport (query): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/airports/closing-airports/${query}/export`, { responseType: 'text' }).toPromise();
  }

  async incorporationsConsult (): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/incorporations/closing-incorporations/consult`).toPromise()
  }

  async incorporationsExport(filters: { startDate: string, endDate: string, status: string }): Promise<any> {
    const params = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status
    };
  
    return this.http.get(`${environment.apiUrl}/cms/reports/incorporations/closing-incorporations/export`, { params }).toPromise();
  }

  async storesWithMacroGroup (): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/stores`).toPromise()
  }

  async preRegistrationCompaniesAirports (): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/reports/pre-registration-companies-airports`).toPromise()
  }

  async monthBalance (filter?: any): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/month-balance${filter || ''}`).toPromise()
  }

  async stockClosing (filter?: any): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/stock-closing${filter || ''}`).toPromise()
  }

  liquidationTransferDetailedReport(filters): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/reports/liquidation-transfer-detailed-report`,{ params: filters }).toPromise()
  }

  async consolidatedJcoins (filter?: any): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/consolidated-jcoins${filter || ''}`).toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) { }
}
