<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">CADASTRO DE NOTIFICAÇÃO</h2>
    <h2 *ngIf="id">EDIÇÃO DE NOTIFICAÇÃO</h2>
    <div class="store-form__header__actions">
      <button
        *ngIf="!architect"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/notifications"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
      <button
        *ngIf="architect"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/notifications-architect/architect"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <mat-card>
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações da Notificação</mat-card-title>

          <mat-card-content>
            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput formControlName="title" placeholder="Título" />
              <mat-error *ngIf="formGroup.controls.title?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.title) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" placeholder="Descrição" />
              <mat-error *ngIf="formGroup.controls.description?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.description) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Imagem (Opcional)</mat-label>
              <input matInput disabled />
              <a
                mat-icon-button
                matSuffix
                (click)="fileInputBanner.click()"
                *ngIf="!isManual && !redirectUrl"
              >
                <mat-icon>attach_file</mat-icon>
              </a>
              <img
                *ngIf="imageUrl != ''"
                width="200"
                src="{{ imageUrl }}"
                (error)="imgLoadError()"
              />
              <div class="btn-remove-img">
                <button
                  class="btn-primary-light"
                  mat-button
                  *ngIf="imageUrl && !isManual && !redirectUrl"
                  (click)="removeImage()"
                >
                  REMOVER IMAGEM <mat-icon>cancel</mat-icon>
                </button>
              </div>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
            <ckeditor
              *ngIf="!redirectUrl"
              [config]="config"
              [editor]="Editor"
              formControlName="content"
              (change)="onChange($event)"
              (ready)="onReady($event)"
            ></ckeditor>
            <mat-error *ngIf="contentInvalid">Campo deve ser preenchido</mat-error>

            <button
              *ngIf="redirectUrl"
              mat-icon-button
              matTooltip="Visualizar"
              matTooltipPosition="left"
              routerLink="{{ routeRedirectUrl }}"
            >
              <b>{{ nameRedirectUrl }}</b>
              <mat-icon>launch</mat-icon>
            </button>

            <div class="check">
              <input formControlName="sendPush" type="checkbox" />
              <mat-label> &nbsp;Enviar push</mat-label>
            </div>
            <div class="check">
              <input formControlName="scheduledPush" type="checkbox" />
              <mat-label> &nbsp;Agendar push</mat-label>
            </div>
            <mat-form-field *ngIf="formGroup.value.scheduledPush">
              <mat-label>Data</mat-label>
              <input type="date" matInput formControlName="scheduledDate" />
              <mat-error *ngIf="formGroup.controls.scheduledDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.scheduledDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="formGroup.value.scheduledPush">
              <mat-label>Horário</mat-label>
              <input type="time" matInput formControlName="scheduledTime" />
              <mat-error *ngIf="formGroup.controls.scheduledTime?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.scheduledTime) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipo notificação</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let listType of listTypes" [value]="listType.id">{{
                  listType.name
                }}</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.type?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.type) }}
              </mat-error>
            </mat-form-field>

            <div class="customer-autocomplete">
              <mat-form-field appearance="fill">
                <mat-label>Escolher usuários</mat-label>
                <mat-select formControlName="options">
                  <mat-option [value]="'checkAllCustomers'" (click)="checkAllCustomers($event)">
                    Notificar todos os usuários
                  </mat-option>
                  <mat-option [value]="'checkCustomers'" (click)="checkCustomers($event)"
                    >Selecionar usuários
                  </mat-option>
                  <mat-option *ngIf="!architect" [value]="'checkSegmentation'" (click)="checkSegmentation($event)">
                    Usar segmentação
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.options?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.options) }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-form-field *ngIf="checkListUsers">
              <mat-label *ngIf="!isManual && !redirectUrl">CPF ou Nome do Cliente</mat-label>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let customer of selectedCustomers"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="removeCustomer(customer)"
                >
                  {{ customer.cpf | mask: '000.000.000-00' }}
                  <mat-icon matChipRemove *ngIf="!isManual && !redirectUrl">cancel</mat-icon>
                </mat-chip>
                <input
                  *ngIf="!isManual && !redirectUrl"
                  matInput
                  #customerInput
                  [formControl]="customerControl"
                  formControlName="customersInput"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                  {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="customerInvalid && checkListUsers"
              >Campo deve ser preenchido</mat-error
            >

            <mat-form-field *ngIf="checkListSegmentation">
              <mat-label *ngIf="!isManual && !redirectUrl">Segmentação</mat-label>
              <mat-chip-list #chipListSegmentation>
                <mat-chip
                  *ngFor="let segmentation of selectedSegmentation"
                  [selectable]="false"
                  [removable]="true"
                  (removed)="removeSegmentation(segmentation)"
                >
                  {{ segmentation.name }}
                  <mat-icon matChipRemove *ngIf="!isManual && !redirectUrl">cancel</mat-icon>
                </mat-chip>
                <input
                  *ngIf="!isManual && !redirectUrl"
                  matInput
                  #segmentationInput
                  [formControl]="segmentationControl"
                  formControlName="segmentationsInput"
                  [matAutocomplete]="autoSegmentation"
                  [matChipInputFor]="chipListSegmentation"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autoSegmentation="matAutocomplete"
                (optionSelected)="selected($event)"
              >
                <mat-option
                  *ngFor="let segmentation of filteredSegmentation | async"
                  [value]="segmentation"
                >
                  {{ segmentation.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error *ngIf="segmentationInvalid && checkListSegmentation"
              >Campo deve ser preenchido</mat-error
            >
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button
          class="btn-primary-light"
          type="submit"
          mat-button
          *ngIf="!isManual && !redirectUrl"
        >
          Salvar
        </button>
      </div>
    </form>
  </mat-card>
</app-logged>
