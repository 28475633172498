<app-logged class="store-list">
  <header class="store-list__header">
    <h2>ANTECIPAÇÕES</h2>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="prepayments">  
      <ng-container matColumnDef="paymentDate">
        <th mat-header-cell *matHeaderCellDef>Data do Pagamento</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
    
      <ng-container matColumnDef="zoopFee">
        <th mat-header-cell *matHeaderCellDef>Taxa da ZOOP</th>
        <td mat-cell *matCellDef="let element">{{ element.zoopFee }} %</td>
      </ng-container>

      <ng-container matColumnDef="jhsfFee">
        <th mat-header-cell *matHeaderCellDef>Taxa da JHSF</th>
        <td mat-cell *matCellDef="let element">{{ element.jhsfFee }} %</td>
      </ng-container>

      <ng-container matColumnDef="paymentGrossAmount">
        <th mat-header-cell *matHeaderCellDef>Valor Bruto</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentGrossAmount | currency: 'R$' }}</td>
      </ng-container>

      <ng-container matColumnDef="paymentNetAmount">
        <th mat-header-cell *matHeaderCellDef>Valor Líquido</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentNetAmount | currency: 'R$' }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Situação</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>