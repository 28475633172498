import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'

import { MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { PaymentProviderCardFeeService } from 'src/app/services/paymentProviderCardFee.service'

@Component({
  selector: 'payment-provider-card-fee-form',
  templateUrl: './payment-provider-card-fee-form.component.html',
  styleUrls: ['./payment-provider-card-fee-form.component.scss'],
})
export class PaymentProviderCardFeeFormComponent implements OnInit {
  public formGroup: FormGroup


  constructor(
    private readonly paymentProviderCardFeeService: PaymentProviderCardFeeService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly location: Location,
    public errorsService: ErrorsService,
  ) {
    this.initForm()
  }

  ngOnInit() { }

  initForm() {
   
    this.formGroup = this.formBuilder.group({
      paymentProvider: [null, [Validators.required]],
      cardBrand: [null, [Validators.required]],
      installments1: [null, [Validators.required]],
      installments2to6: [null, [Validators.required]],
      installments7to12: [null, [Validators.required]],
      debit: [null, [Validators.required]],
    })
  }

  async submit() {

    if(!this.formGroup.valid){
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return 
    }

    const data = {
      paymentProvider: this.formGroup.value.paymentProvider,
      cardBrand: this.formGroup.value.cardBrand,
      installments1: this.formGroup.value.installments1,
      installments2to6: this.formGroup.value.installments2to6,
      installments7to12: this.formGroup.value.installments7to12,
      debit: this.formGroup.value.debit,
    }

    try {
      await this.paymentProviderCardFeeService.addNewFee(data)
      this.snackBar.open('Taxa adicionada com sucesso.')
      this.goBack()
    } catch(e) {
      console.log(e)
      this.snackBar.open(e.message)
    }
    
  }

  goBack() {
    this.location.back()
  }


}
