import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AuthService } from '../../../../../services/auth.service'
import { Params } from '@angular/router'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-documents-not-integration-list',
  templateUrl: './documents-not-integration.component.html',
  styleUrls: ['./documents-not-integration.component.scss'],
})
export class DocumentsNotIntegrationListComponent implements OnInit {
  public headersTable: string[] = ['cpf', 'actions']
  dataSource: any
  public formGroup: FormGroup
  public params: Params

  constructor (
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    // deploy
    this.list().catch(err => { console.log(err) })
  }

  async list () {
    const items = await this.authService
      .documentsNotIntegration()
      .catch(err => {
        this.snackBar.open(err.message)
      })

    this.dataSource = new MatTableDataSource(items)
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse CPF',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.deleteDocumentsNotIntegration(id).then(
            async response => {
              this.list().catch(err => { console.log(err) })
              this.snackBar.open('CPF removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
