import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalTransferLogs = /** @class */ (function () {
    function ModalTransferLogs(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.headersTable = ['createdAt', 'operation', 'result', 'requestPayload', 'responsePayload', 'success'];
        console.log('data', data);
        this.logs = data;
    }
    ModalTransferLogs.prototype.ngOnInit = function () {
        // empty
    };
    ModalTransferLogs.prototype.getResult = function (responsePayload) {
        try {
            var object = JSON.parse(responsePayload);
            if (object) {
                if (object.resultado && !("" + object.resultado).startsWith('STR'))
                    return object.resultado;
                else if (object.descricaostatus)
                    return object.descricaostatus;
                else if (object.mensagem)
                    return object.mensagem;
            }
        }
        catch (e) {
        }
    };
    return ModalTransferLogs;
}());
export { ModalTransferLogs };
