/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questions-show-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./questions-show-image.component";
var styles_QuestionsShowImageComponent = [i0.styles];
var RenderType_QuestionsShowImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionsShowImageComponent, data: {} });
export { RenderType_QuestionsShowImageComponent as RenderType_QuestionsShowImageComponent };
export function View_QuestionsShowImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "margin: 1rem"], ["width", "200"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imageQuestion, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_QuestionsShowImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questions-show-image", [], null, null, null, View_QuestionsShowImageComponent_0, RenderType_QuestionsShowImageComponent)), i1.ɵdid(1, 114688, null, 0, i2.QuestionsShowImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuestionsShowImageComponentNgFactory = i1.ɵccf("app-questions-show-image", i2.QuestionsShowImageComponent, View_QuestionsShowImageComponent_Host_0, {}, {}, []);
export { QuestionsShowImageComponentNgFactory as QuestionsShowImageComponentNgFactory };
