<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Macro Grupo</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/client-kind">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Segmentação</mat-label>
              <input matInput formControlName="kind" />
              <mat-error *ngIf="formGroup.controls.kind?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.kind) }}
              </mat-error>
            </mat-form-field>
            <div class="check">
              <input formControlName="isDefault" type="checkbox" />
              <mat-label> &nbsp;Default</mat-label>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
