<app-logged class="segmentation-list">
  <header class="segmentation-list__header">
    <h2>Segmentações</h2>
    <div class="segmentation-list__header__actions">
      <button
        mat-button
        matTooltip="Reprocessar todos"
        class="btn-primary-light"
        (click)="reprocessAll(resultsAll)"
      >
        <mat-icon>cached</mat-icon>
      </button>
      <button class="btn-primary-light" mat-button (click)="addSegmentation()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter
    actions="form"
    type="segmentation"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="filterCount">
        <th mat-header-cell *matHeaderCellDef width="150px">Filtros (QTDE)</th>
        <td mat-cell *matCellDef="let element">{{ element.filterCount }}</td>
      </ng-container>
      <ng-container matColumnDef="customerCount">
        <th mat-header-cell *matHeaderCellDef width="150px">Clientes (QTDE)</th>
        <td mat-cell *matCellDef="let element">{{ element.customerCount }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="250px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Reprocessar"
            matTooltipPosition="left"
            (click)="reprocess(element.id)"
          >
            <mat-icon>cached</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/segmentation/form/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Clientes"
            matTooltipPosition="left"
            routerLink="/dashboard/segmentation/{{ element.id }}/customers"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>contact_page</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="removeSegmentation(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter
      actions="pagination"
      type="segmentation"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
