import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var AirportInfoFieldsListComponent = /** @class */ (function () {
    function AirportInfoFieldsListComponent(airportsService, snackBar, location, dialog, router, route) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.router = router;
        this.route = route;
        this.headersTable = ['order', 'fieldTitle', 'fieldValue', 'fieldLabel', 'actions'];
        this.fields = [];
    }
    AirportInfoFieldsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.informationID = this.route.snapshot.paramMap.get('informationID');
        this.airportID = this.route.snapshot.paramMap.get('airportID');
        this.airportsService.informationFindOne(this.informationID).then(function (response) {
            _this.titleInfo = response.title;
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.listFields();
    };
    AirportInfoFieldsListComponent.prototype.listFields = function () {
        var _this = this;
        this.airportsService.listInfoFields(this.informationID).then(function (response) {
            _this.fields = response;
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    AirportInfoFieldsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa coluna?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.deleteField(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listFields();
                                this.snackBar.open('Coluna removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return AirportInfoFieldsListComponent;
}());
export { AirportInfoFieldsListComponent };
