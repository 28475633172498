import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'
import { Location } from '@angular/common'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { Store } from 'src/app/models/store'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ConfigService } from 'src/app/services/config.service'
import { ModalChooseStoreComponent } from '../../../../components/modal-choose-store/modal-choose-store.component'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'
import { UtilService } from 'src/app/services/util.service'
import { PermissionsService } from 'src/app/services/permissions.service'


const getEnumKeyByEnumValue = UtilService.getEnumKeyByEnumValue;
const initializePermissionOptions = function (permissionsArray: enumCmsPermissions[]) {
  return permissionsArray
    .map(p => getEnumKeyByEnumValue(enumCmsPermissions, p))
    .reduce((acc, key) => ({ [key]: enumCmsPermissions[key], ...acc }), {})
}

@Component({
  selector: 'app-store-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: string
  public userStoreID: string
  public userID = ''
  public stores: Store[] = []
  public storesSelected: any[] = []
  public headersTable: string[] = [
    'cnpj',
    'nomeFantasia',
    'razaoSocial',
    'mccCode',
    'mccCategory',
    'actions',
  ]

  public email = ''
  public msgErrorNotStores = ''
  public storeIdValidation = true
  public roleAdmin = false
  public mainStoreId: string
  public isCustomerService = false
  public storeUser: any
  public showPermissions: boolean = false;

  public architectPermissions: { [key: string]: enumCmsPermissions } = initializePermissionOptions([
    enumCmsPermissions.architect_invoice_cancel,
    enumCmsPermissions.architect_invoice_delete,
    enumCmsPermissions.architect_invoice_approve,
    enumCmsPermissions.architect_invoice_list,
    enumCmsPermissions.architect_admin_menu,
    enumCmsPermissions.architect_reports,
    enumCmsPermissions.architect_notifications,

  ])

  public transactionPermissions: { [key: string]: enumCmsPermissions } = initializePermissionOptions([
    enumCmsPermissions.manual_credit_maker,
    enumCmsPermissions.manual_credit_revisor,
  ])

  public otherPermissions: { [key: string]: enumCmsPermissions } = initializePermissionOptions([
    enumCmsPermissions.integrator,
    enumCmsPermissions.internal_user_managment,
  ])

  public selectedPermissions: { [permissionKey: string]: boolean } = {}
  public userRole;

  constructor(
    public permissionsService: PermissionsService,
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly location: Location,
    public configService: ConfigService,
  ) {

    this.selectedPermissions = Object.keys({
      ...this.otherPermissions,
      ...this.architectPermissions,
      ...this.transactionPermissions,
    }).reduce((acc, permissionKey) => ({ [permissionKey]: false, ...acc }), {})

    this.initForm().catch(err => console.log(err))
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole')
  }

  async initForm() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.storeUser = userInfo.storeId
    this.getStore(userInfo.storeId);

    if (this.route.snapshot.paramMap.get('storeID')) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
    }

    if (this.route.snapshot.paramMap.get('userID')) {
      this.userID = this.route.snapshot.paramMap.get('userID')
    }

    if (this.route.snapshot.paramMap.get('email')) {
      this.email = this.route.snapshot.paramMap.get('email')
    }

    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      emailAddress: [this.email != '' ? this.email : null, [Validators.required, Validators.email]],
      phoneNumber: [null, [Validators.required]],
      storeId: [null, [Validators.required]],
      otherStoreIds: [null, []],
      role: [null, [Validators.required]],
    })

    if (this.userID) {
      await this.authService
        .userInfo(this.userID)
        .toPromise()
        .then(
          response => {
            this.formGroup.patchValue({
              firstName: response.firstName,
              lastName: response.lastName,
              emailAddress: response.emailAddress,
              phoneNumber: response.phoneNumber,
              storeId: response.storeId,
              role: response.role,
            })
            this.storeID = this.storeID ? this.storeID : response.storeId
            this.userStoreID = response.storeId
            this.loadStores(response)
            response.permissions.forEach(permissionKey => {
              this.selectedPermissions[permissionKey] = true
            })
            this.showPermissions = response.role === 'ADMIN' || response.role === 'EMPTY';
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      this.loadStores()
    }
    await this.config()
  }

  getStore(storeId: number) {
    this.storeService.getStore(storeId)
      .subscribe(
        async response => {
          let userHasPermission = this.permissionsService.userHasPermission(enumCmsPermissions.internal_user_managment);
          if (response.isMainStore && !userHasPermission) {
            alert('Você não tem permissão para utilizar esse recurso');
            this.router.navigate(['/dashboard/stores']);
            return;
          }
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  isChecked(id) {
    if (id === this.userStoreID) {
      return 'checked'
    }
  }

  getStoreById() {
    for (const s of this.storesSelected) {
      if (s.id === this.storeID) {
        return
      }
    }
    this.storeService.getStore(this.storeID).subscribe(
      async response => {
        const data = {
          id: response.id,
          nomeFantasia: response.nomeFantasia,
        }
        this.storesSelected.push(data)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  loadStores(res?) {
    this.storeService.listStores().subscribe(
      async response => {
        this.stores = response

        if (this.userID) {
          /**
           * this.stores.forEach(store => {
            if(store.id == this.formGroup.value.storeId) {
              this.storesSelected.push(store);
            }
          });
           */
          let data = {}
          res.allStoreIds.forEach(store => {
            data = {
              id: store.storeId,
              nomeFantasia: store.storeName,
            }

            this.storesSelected.push(data)
          })

          this.formGroup.patchValue({
            otherStoreIds: this.storesSelected,
          })
        }
        this.getStoreById()
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  changeStoreMain(event) {
    if (this.mainStoreId === event.target.value) {
      this.roleAdmin = true
    } else {
      this.roleAdmin = false
      this.formGroup.get('role').setValue(null)
      this.formGroup.get('role').setValidators([Validators.required])
      this.formGroup.get('role').updateValueAndValidity()
    }
    this.storeIdValidation = true
    this.formGroup.get('storeId').setValue(event.target.value)
  }

  changePermissions(evt) {
    const permissionKey = evt.target.value
    this.selectedPermissions[permissionKey] = evt.target.checked
  }

  onRoleChange(event: any) {
    const selectedRole = event.value;
    this.showPermissions = selectedRole === 'ADMIN' || selectedRole === 'EMPTY';

    if (!this.showPermissions) {
      for (let key in this.selectedPermissions) {
        if (typeof this.selectedPermissions[key] === 'boolean')
          this.selectedPermissions[key] = false;
      }
    }
  }

  async submit() {
    const otherStoreIds: string[] = []

    if (this.storesSelected.length > 0) {
      this.msgErrorNotStores = ''
      if (this.formGroup.value.storeId == null || this.formGroup.value.storeId == '') {
        this.storeIdValidation = false
      } else {
        this.storeIdValidation = true
      }
    } else {
      this.msgErrorNotStores = 'Campo deve ser preenchido'
      return false
    }

    if (this.storesSelected.length > 1) {
      this.storesSelected.forEach(store => {
        if (store.id != this.formGroup.value.storeId) {
          otherStoreIds.push(store.id)
        }
      })
    }

    const data: any = {
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: this.formGroup.value.phoneNumber,
      storeId: this.formGroup.value.storeId,
      otherStoreIds: otherStoreIds,
      role: this.formGroup.value.role
    }

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.userID) {

      if (this.roleAdmin) {
        data.permissionsToAdd = Object.entries(this.selectedPermissions)
          .filter(([permissionName, checked]) => checked)
          .map(([permissionName, checked]) => permissionName)
        data.permissionsToRemove = Object.entries(this.selectedPermissions)
          .filter(([permissionName, checked]) => !checked)
          .map(([permissionName, checked]) => permissionName)
      }

      this.authService.update(data, this.userID).subscribe(
        async response => {
          this.snackBar.open('Usuário atualizado com sucesso.')
          this.location.back()
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {

      if (this.roleAdmin) {
        data.permissions = Object.entries(this.selectedPermissions)
          .filter(([permissionName, checked]) => checked)
          .map(([permissionName, checked]) => permissionName)
      }

      this.authService.signup(data).subscribe(
        async response => {
          if (response.isCreated) {
            this.snackBar.open('Usuário criado com sucesso.')
            this.location.back()
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  goBack() {
    this.location.back()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalChooseStoreComponent, {
      width: '500px',
      data: {
        stores: this.stores,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.msgErrorNotStores = ''

        this.showStores(result)
      }
    })
  }

  showStores(store) {
    this.storesSelected.push(store)

    this.stores = this.stores.filter(f => !this.storesSelected.includes(f))
  }

  removeStore(store) {
    if (store.id == this.formGroup.value.storeId || this.storesSelected.length == 0) {
      this.formGroup.get('storeId').setValue('')
    }
    this.storesSelected = this.storesSelected.filter(item => item.id !== store.id)
    this.stores.push(store)
  }

  config() {
    this.configService
      .view()
      .toPromise()
      .then(
        async response => {
          this.mainStoreId = response.results.mainStoreId
          if (response.results.mainStoreId === this.storeID) {
            this.roleAdmin = true
            this.formGroup.get('role').setValidators(null)
            this.formGroup.get('role').updateValueAndValidity()
            this.formGroup.get('role').setErrors(null)
          }
          console.log(response.results.mainStoreId)
          console.log(this.storeUser)
          if (response.results.mainStoreId === this.storeUser) this.isCustomerService = true
          console.log(this.isCustomerService)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }
}
