import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
var StoreDocumentsFormComponent = /** @class */ (function () {
    function StoreDocumentsFormComponent(errorsService, formBuilder, storeDocumentService, router, route, snackBar, currencyPipe) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.storeDocumentService = storeDocumentService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.storeID = '';
        this.documentUrl = '';
        this.pathAwsUpload = '';
        this.initForm();
    }
    StoreDocumentsFormComponent.prototype.ngOnInit = function () { };
    StoreDocumentsFormComponent.prototype.initForm = function () {
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.formGroup = this.formBuilder.group({
            documentType: [null, [Validators.required]],
        });
    };
    StoreDocumentsFormComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.fileUrl = event.target.files[0];
                this.fileName = event.target.files[0].name;
                if (event.target.files && event.target.files[0]) {
                    reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = function (evt) {
                        // called once readAsDataURL is completed
                        _this.documentUrl = evt.target.result;
                    };
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    StoreDocumentsFormComponent.prototype.imgLoadError = function () {
        this.documentUrl = null;
    };
    StoreDocumentsFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.fileName = undefined;
        this.fileInputBanner.nativeElement.value = '';
    };
    StoreDocumentsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid || !this.fileUrl) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.storeDocumentService
                                .add(this.storeID, this.formGroup.value.documentType)
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.urlUploadS3 = response.url;
                                    this.uploadData = new FormData();
                                    this.uploadData.append('file', this.fileUrl, this.fileName);
                                    this.s3Path = response.s3Path;
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.storeDocumentService.upload(this.urlUploadS3, this.uploadData).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.storeDocumentService
                                .create({ s3Path: this.s3Path })
                                .toPromise()
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Documento enviado para análise.');
                                    this.router.navigate(['./dashboard/stores/' + this.storeID + '/documents']);
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return StoreDocumentsFormComponent;
}());
export { StoreDocumentsFormComponent };
