<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="id">Banner da Página</h2>
    <h2 *ngIf="!id">Banner da Página</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome da Página</mat-label>
              <input matInput formControlName="page" />
              <mat-error *ngIf="formGroup.controls.page?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.page) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Banner</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputPartnerBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="editPartnerBannerImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedPartnerBannerImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('partnerBanner')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedPartnerBannerImage != ''"
                width="590"
                src="{{ croppedPartnerBannerImage }}"
              />
              <input
                hidden
                (change)="selectFilePartnerBanner($event)"
                #fileInputPartnerBanner
                type="file"
                accept="image/*"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>Ordem</mat-label>
              <input type="number" matInput formControlName="order" />
              <mat-error *ngIf="formGroup.controls.order?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.order) }}
              </mat-error>
            </mat-form-field>

            <div *ngIf="!showDates">
              <mat-form-field>
                <mat-label>Data Inicio</mat-label>
                <input type="date" matInput formControlName="initAt" (change)="onChangeDate()" />
                <mat-error *ngIf="formGroup.controls.initAt?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.initAt) }}
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="!showDates">
              <mat-form-field>
                <mat-label>Data fim</mat-label>
                <input type="date" matInput formControlName="endAt" (change)="onChangeDate()" />
                <mat-error *ngIf="formGroup.controls.endAt?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.endAt) }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <div class="check" style="padding-left: 5px; font-size: 13px">
                <input formControlName="isFixed" type="checkbox" (change)="onChange()" />
                <mat-label style="margin-bottom: 1px; font-size: 12px"> &nbsp;Fixo</mat-label>
              </div>
            </div> -->
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
