import { OnInit, TemplateRef, EventEmitter, ElementRef } from "@angular/core";
import { ControlValueAccessor, FormControl } from "@angular/forms";
import { of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
var CustomerSearchInputComponent = /** @class */ (function () {
    function CustomerSearchInputComponent(authService, maskPipe) {
        this.authService = authService;
        this.maskPipe = maskPipe;
        this.debounceTime = 800;
        this.minLength = 3;
        this.placeholder = 'Buscar por nome ou CPF...';
        this.searchOnlyArchitects = false;
        this.search = new EventEmitter();
        this.onSelect = new EventEmitter();
        this.loading = false;
        this.localFormControl = new FormControl();
    }
    CustomerSearchInputComponent.prototype.writeValue = function (obj) {
    };
    CustomerSearchInputComponent.prototype.registerOnChange = function (fn) {
    };
    CustomerSearchInputComponent.prototype.registerOnTouched = function (fn) {
    };
    CustomerSearchInputComponent.prototype.setDisabledState = function (isDisabled) {
    };
    CustomerSearchInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.localFormControl) {
            this.filteredOptions = this.localFormControl.valueChanges.pipe(debounceTime(this.debounceTime), distinctUntilChanged(), tap(function (value) { return _this.loading = _this.shouldSearch(value); }), switchMap(function (value) {
                if (_this.shouldSearch(value)) {
                    _this.search.emit(value);
                    return _this.searchCustomers(value).pipe(tap(function () { return _this.loading = false; }));
                }
                _this.loading = false;
                return of([]);
            }));
        }
    };
    CustomerSearchInputComponent.prototype.shouldSearch = function (value) {
        return value && value.length >= this.minLength;
    };
    CustomerSearchInputComponent.prototype.searchCustomers = function (term) {
        if (this.searchFunction)
            return this.searchFunction(term);
        return this.authService.searchCustomers(term, this.searchOnlyArchitects);
    };
    CustomerSearchInputComponent.prototype.onOptionSelected = function (event) {
        var selectedValue = event.option.value;
        this.selectedItem = selectedValue;
        this.customerInput.nativeElement.value = '';
        this.localFormControl.setValue(null);
        this.formControl.setValue(selectedValue);
        this.onSelect.emit(selectedValue);
    };
    CustomerSearchInputComponent.prototype.clearSelection = function () {
        this.customerInput.nativeElement.value = '';
        this.selectedItem = null;
        this.formControl.reset();
        this.localFormControl.setValue(null);
        this.onSelect.emit(null);
    };
    CustomerSearchInputComponent.prototype.getSelectedItemText = function () {
        if (this.onSelectedItemRender)
            return this.onSelectedItemRender(this.selectedItem);
        return this.selectedItem.firstName + ' ' + this.selectedItem.lastName + ' - CPF: ' + this.maskPipe.transform(this.selectedItem.cpf, '000.000.000-00');
    };
    CustomerSearchInputComponent.prototype.getOptionDisplayName = function (option) {
        if (this.onDisplayItemRender)
            return this.onDisplayItemRender(option);
        return option.firstName + ' ' + option.lastName + ' - CPF: ' + this.maskPipe.transform(option.cpf, '000.000.000-00');
    };
    return CustomerSearchInputComponent;
}());
export { CustomerSearchInputComponent };
