import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
var FundsListComponent = /** @class */ (function () {
    function FundsListComponent(fundService, snackBar, router, route, permissionsService) {
        this.fundService = fundService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.permissionsService = permissionsService;
        this.headersTable = ['cnpj', 'name', 'jcoinBalance', 'membership', 'active', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.cmsPermissions = enumCmsPermissions;
    }
    FundsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userRole = localStorage.getItem('userRole');
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                _this.initForm();
            }
            else {
                _this.initForm();
                _this.listFunds();
            }
        });
    };
    FundsListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjFund',
                label: 'CNPJ',
                placeholder: 'CNPJ',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameFund',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'select',
                formControlName: 'activeFund',
                label: 'Status',
                placeholder: 'Status',
                list: [
                    { key: '1', value: 'ATIVO' },
                    { key: '0', value: 'INATIVO' },
                ],
                optionDefault: 'TODOS',
            },
        ];
    };
    FundsListComponent.prototype.listFunds = function () {
        var _this = this;
        this.fundService
            .list("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .then(function (response) {
            _this.pageTotal = response.total;
            _this.dataSource = new MatTableDataSource(response.results);
        })
            .catch(function (err) {
            _this.snackBar.open(err.error.message);
        });
    };
    FundsListComponent.prototype.addFund = function () {
        var _this = this;
        this.router.navigate(['./dashboard/funds/form']).catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundsListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return FundsListComponent;
}());
export { FundsListComponent };
