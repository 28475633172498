<main class="modal-customer-info">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <h2>Informações</h2>
      <ul class="heroes" *ngIf="data.user === 'ADMIN' && data.customerCpf">
        <li>
          <span class="badge"><b>Nome do Cliente</b></span
          >: {{ data.customerFullName }}
        </li>
        <li>
          <span class="badge"><b>CPF</b></span
          >: {{ data.customerCpf | mask: '000.000.000-00' }}
        </li>
        <li>
          <span class="badge"><b>Data de Nascimento</b></span
          >: {{ data.customerBirthDate | date: 'dd/MM/yyyy' }}
        </li>
        <li>
          <span class="badge"><b>Telefone</b></span
          >: {{ data.customerPhoneNumber | mask: '00 (00) 00000-0000' }}
        </li>
        <li>
          <span class="badge"><b>E-mail</b></span
          >: {{ data.customerEmailAddress }}
        </li>
        <li *ngIf="data.customerGender === 'female'">
          <span class="badge"><b>Gênero</b> </span>: Feminino
        </li>
        <li *ngIf="data.customerGender === 'male'">
          <span class="badge"><b>Gênero</b> </span>: Masculino
        </li>
        <li *ngIf="data.customerGender === 'none'">
          <span class="badge"><b>Gênero</b> </span>: Prefiro não falar
        </li>
        <li *ngIf="data.customerGender === 'other'">
          <span class="badge"><b>Gênero</b> </span>: Outro
        </li>
        <li>
          <span class="badge"><b>Saldo de J-Coins</b></span
          >:
          {{ data.customerBalance }}
        </li>
      </ul>
      <ul class="heroes" *ngIf="data.user === 'ADMIN' && data.cnpj">
        <li>
          <span class="badge"><b>CNPJ</b></span
          >: {{ data.cnpj | mask: '00.000.000/0000-00' }}
        </li>
        <li>
          <span class="badge"><b>Fundo</b></span
          >: {{ data.fundName }}
        </li>
        <li>
          <span class="badge"><b>E-mail</b></span
          >: {{ data.fundEmailAddress }}
        </li>
        <li>
          <span class="badge"><b>Saldo de J-Coins</b></span
          >: {{ data.fundJcoinBalance }}
        </li>
      </ul>
      <ul class="heroes" *ngIf="data.user !== 'ADMIN' && data.customerCpf">
        <li>
          <span class="badge"><b>Nome do Cliente</b></span
          >: {{ data.customerFullName }}
        </li>
        <li>
          <span class="badge"><b>CPF</b></span
          >: {{ data.customerCpf | mask: '000.000.000-00' }}
        </li>
      </ul>
    </mat-card>
  </div>
  <div *ngIf="data.customerId && data.user === 'ADMIN'" mat-dialog-actions>
    <div class="login__actions">
      <button mat-button (click)="infoClient(data.customerId)" class="btn-submit-dark">
        Informações do Cliente
      </button>
    </div>
  </div>
  <div *ngIf="data.associateFundCustomerId" mat-dialog-actions>
    <div class="login__actions">
      <button
        mat-button
        (click)="infoClientAssociateFund(data.associateFundCustomerId, data.fundId)"
        class="btn-submit-dark"
      >
        Cliente Associado
      </button>
    </div>
  </div>
</main>
