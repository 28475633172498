<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; height: calc(90vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit(data.storeId)">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h5>Transações</h5>
              <div class="col-12 col-sm-12">
                <mat-card-content *ngIf="storesUser.length > 1">
                  <mat-form-field class="example-chip-list">
                    <mat-label>Parceiro</mat-label>
                    <mat-chip-list #chipListStore>
                      <mat-chip
                        *ngFor="let store of selectedStores"
                        [selectable]="false"
                        [removable]="true"
                        (removed)="remove(store)"
                      >
                        {{ store.storeName }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input
                        matInput
                        #storeInput
                        [formControl]="storeControl"
                        formControlName="storesInput"
                        [matAutocomplete]="autoStore"
                        [matChipInputFor]="chipListStore"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      />
                    </mat-chip-list>
                    <mat-autocomplete
                      #autoStore="matAutocomplete"
                      (optionSelected)="selected($event)"
                    >
                      <mat-option *ngFor="let store of filteredStores | async" [value]="store">
                        {{ store.storeName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </mat-card-content>

                <mat-form-field>
                  <input
                    matInput
                    formControlName="beginDate"
                    placeholder="Data Inicial"
                    type="date"
                  />
                  <mat-error *ngIf="formGroup.controls.beginDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.beginDate) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="endDate" placeholder="Data Final" type="date" />
                  <mat-error *ngIf="formGroup.controls.endDate?.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.endDate) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button
              mat-button
              class="btn-submit-dark"
              (click)="typeFile('excel')"
              style="margin-right: 10px"
            >
              Exportar Excel
            </button>
            <button mat-button class="btn-submit-dark" (click)="typeFile('pdf')">
              Exportar pdf
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
