import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { SegmentationService } from 'src/app/services/segmentation.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { MacroGroupsService } from 'src/app/services/macro-groups.service'

@Component({
  selector: 'app-segmentation-form',
  templateUrl: './segmentation-form.component.html',
  styleUrls: ['./segmentation-form.component.scss'],
})
export class SegmentationFormComponent implements OnInit {
  headersTable: string[] = ['field', 'op', 'value', 'actions']
  resultCols: any[] = []
  resultColsKeys: string[] = []
  filters: any = []
  results: any
  limit: any
  formGroup: FormGroup
  segmentationId = ''
  operators = ['=', '>', '>=', '<', '<=', '!=', 'like', 'Ordem']
  public pageTotal: number
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public macroGroups: any[] = []
  public params = { formGroupValue: [] }

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    private readonly segmentationService: SegmentationService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly macroGroupsService: MacroGroupsService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.segmentationId = this.route.snapshot.paramMap.get('id')
    this.initForm().catch(err => {
      this.snackBar.open(err.message)
    })
  }

  async initForm () {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      limit: [undefined, []],
      field: [[], []],
      op: [[], []],
      value: [[], []],
    })

    if (this.segmentationId) {
      const { name, limit, filters } = await this.segmentationService.find(this.segmentationId)
      this.formGroup.patchValue({ name, limit })
      this.filters = filters
    }

    await this.macroGroupsService.list().then(
      response => {
        this.macroGroups = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
    // await this.fetchResults()
    const isMacroGroup = this.filters.filter(f => f.field === 'macroGroups')

    this.filters.map(f => this.macroGroups.filter(
      (mg) => {
        if (mg.id === f.value) {
          f.name = mg.macroName
        }
      }),
    )

    if (isMacroGroup.length) {
      await this.fetchResultsMacroGroup()
    } else {
      await this.fetchResults()
    }

    this.filters = this.filters.map(f => ({
      field: f.field,
      fieldTitle: this.resultCols.find(c => c.key === f.field).field,
      fieldType: this.resultCols.find(c => c.key === f.field).type,
      op: f.op,
      value: f.value,
      name: f.name || undefined,
    }))
  }

  async submit () {
    const data = {
      name: this.formGroup.value.name,
      limit: this.formGroup.value.limit || undefined,
      filters: this.filters.map(f => ({ field: f.field, op: f.op, value: f.value })),
    }

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.segmentationId) {
      this.segmentationService
        .edit(this.segmentationId, data)
        .then(async response => {
          if (response.success) {
            this.snackBar.open('Segmentação editada com sucesso.')
            this.router.navigate(['dashboard', 'segmentation'], { queryParams: this.params })
              .catch((err) => console.log(err))
          } else {
            this.snackBar.open('Falha ao editar a segmentação.')
          }
        })
        .catch(err => {
          this.snackBar.open(err.message)
        })
    } else {
      this.segmentationService
        .create(data)
        .then(async response => {
          if (response.success) {
            this.snackBar.open('Segmentação criada com sucesso.')
            this.router.navigate(['dashboard', 'segmentation'], { queryParams: this.params })
              .catch((err) => console.log(err))
          } else {
            this.snackBar.open('Falha ao adicionar a segmentação.')
          }
        })
        .catch(err => {
          this.snackBar.open(err.message)
        })
    }
  }

  limitChanged () {
    this.fetchResults().catch(err => {
      this.snackBar.open(err.message)
    })
  }

  removeFilter (filter: any) {
    this.filters = this.filters.filter(f => f !== filter)
    this.fetchResults().catch(err => {
      this.snackBar.open(err.message)
    })
  }

  async fetchResults () {
    this.limit = this.formGroup.value.limit
    const { fields, data }: any = await this.segmentationService.listResult(
      this.filters.map(f => ({ field: f.field, op: f.op, value: f.value })),
      1,
      10,
      this.limit,
    )

    this.resultCols = fields
    this.resultColsKeys = fields.map(f => f.key)
    this.results = data.results
    this.pageTotal = data.total
  }

  selectedField (e) {
    if (e.value.key !== 'macroGroups') {
      this.formGroup.get('value').setValue(null)
      this.formGroup.get('value').updateValueAndValidity()
    }
  }

  async addFilter () {
    const filter = {
      field: (this.formGroup.value.field || {}).key,
      fieldTitle: (this.formGroup.value.field || {}).field,
      fieldType: (this.formGroup.value.field || {}).type,
      op: this.formGroup.value.op,
      value: this.formGroup.value.value,
    }
    if (
      !filter.field ||
      !filter.field.length ||
      !filter.op ||
      !filter.op.length ||
      !filter.value ||
      !filter.value.length
    ) {
      this.snackBar.open('Preencha corretamente os filtros e tente novamente.')
      return false
    }

    const isMacroGroup = this.filters.filter(f => f.field === 'macroGroups')

    if (this.formGroup.value.field.key === 'macroGroups' || isMacroGroup.length) {
      this.formGroup.patchValue({ field: '', op: '', value: '' })
      const dataFilter = [...this.filters]
      dataFilter.push(filter)
      this.filters = dataFilter

      this.fetchResultsMacroGroup().catch(err => {
        this.snackBar.open(err.message)
      })

      this.filters.map(f => this.macroGroups.filter(
        (mg) => {
          if (mg.id === f.value) {
            f.name = mg.macroName
          }
        }),
      )
    } else {
      this.formGroup.patchValue({ field: '', op: '', value: '' })
      const data = [...this.filters]
      data.push(filter)
      this.filters = data
      this.fetchResults().catch(err => {
        this.snackBar.open(err.message)
      })
    }
  }

  async fetchResultsMacroGroup () {
    const { fields, data }: any =
      await this.segmentationService.listCustomersMacroGroup(
        this.filters.map(f => ({ field: f.field, op: f.op, value: f.value })),
        1,
        10,
        this.limit,
      )

    this.resultCols = fields
    this.resultColsKeys = fields.map(f => f.key)
    this.results = data.results
    this.pageTotal = data.total
  }

  drop (event: CdkDragDrop<any[]>) {
    if (event.previousContainer !== event.container) {
      return
    }
    const previousIndex = this.filters.findIndex(row => row === event.item.data)
    const data = [...this.filters]
    moveItemInArray(data, previousIndex, event.currentIndex)
    this.filters = data
  }

  reciverFeedback (returnFilter) {
    this.resultCols = returnFilter.fields
    this.resultColsKeys = returnFilter.fields.map(f => f.key)
    this.results = returnFilter.data.results
    this.pageTotal = returnFilter.data.total
  }
}
