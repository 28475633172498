<app-logged class="store-form">
  <header class="store-form__header">
    <h2>REGULAMENTO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/regulations">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput  formControlName="heading"/>
              <mat-error *ngIf="formGroup.controls.heading?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.heading)
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Subtítulo</mat-label>
              <input matInput  formControlName="subtitle"/>
              <mat-error *ngIf="formGroup.controls.subtitle?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.subtitle)
                }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <ckeditor (change)="change($event)" [config]="config" [editor]="Editor" [data]="textDescription" (ready)="onReady($event)"></ckeditor>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>
          Salvar
        </button>
      </div>
    </mat-card>
  </form>
</app-logged>
