import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
var ModalStoreBankAccountChangedComponent = /** @class */ (function () {
    function ModalStoreBankAccountChangedComponent(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
    }
    ModalStoreBankAccountChangedComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalStoreBankAccountChangedComponent.prototype.confirm = function () {
        this.dialogRef.close('confirm');
    };
    ModalStoreBankAccountChangedComponent.prototype.ngOnInit = function () {
    };
    return ModalStoreBankAccountChangedComponent;
}());
export { ModalStoreBankAccountChangedComponent };
