<app-logged class="store-list">
  <header class="store-list__header">
    <h2>DETALHE DO FECHAMENTO</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">Voltar</button>
      <button class="btn-primary-light" mat-button (click)="updateStatus()">ATUALIZAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="stores">
      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>
          Nome Fantasia <br />
          (Parceiro)
        </th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>
          Valor Bruto Transações <br />
          (Valor Total)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="netValue">
        <th mat-header-cell *matHeaderCellDef>
          Valor Líquido Transações <br />
          (Valor Líquido)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.netValue) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="pointsApplied">
        <th mat-header-cell *matHeaderCellDef>
          Valor Total à Pagar J-Coins <br />
          (Valor Líquido)
        </th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.pointsValue) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Exportar"
            matTooltipPosition="left"
            (click)="export(element.id)"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
