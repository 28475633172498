import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalFormExportConsolidatedJcoinsComponent = /** @class */ (function () {
    function ModalFormExportConsolidatedJcoinsComponent(dialogRef, data, formBuilder, snackBar, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
    }
    ModalFormExportConsolidatedJcoinsComponent.prototype.ngOnInit = function () {
        this.filterForm = this.formBuilder.group({
            monthYear: [null, [Validators.required, this.validMonthYear]],
        });
    };
    ModalFormExportConsolidatedJcoinsComponent.prototype.validMonthYear = function (control) {
        var value = control.value;
        if (!value)
            return null;
        var regex = /^\d{6}$/;
        if (!regex.test(value)) {
            return { invalidFormat: true };
        }
        var month = parseInt(value.slice(0, 2), 10);
        var year = parseInt(value.slice(2), 10);
        if (month < 1 || month > 12) {
            return { invalidMonth: true };
        }
        if (year < 1900 || year > new Date().getFullYear()) {
            return { invalidYear: true };
        }
        return null;
    };
    ModalFormExportConsolidatedJcoinsComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    ModalFormExportConsolidatedJcoinsComponent.prototype.submit = function () {
        var monthYearValue = this.filterForm.value.monthYear;
        if (!this.filterForm.valid) {
            console.log("Erros no formulário:", this.filterForm.controls.monthYear.errors);
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        var filters = {
            monthYear: monthYearValue,
        };
        this.dialogRef.close(filters);
    };
    return ModalFormExportConsolidatedJcoinsComponent;
}());
export { ModalFormExportConsolidatedJcoinsComponent };
