<app-logged class="store-form">
  <header class="store-form__header">
    <h2>CONFIGURAÇÕES</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>

  <mat-card class="margin-bottom">
    <div class="row">
      <div class="col-12 col-sm-6">
        Reiniciar sessão dos clientes no App?
        <button class="btn-primary-light" (click)="resetCustomersSessions()" mat-button>
          Reiniciar sessões
        </button>
      </div>
    </div>
  </mat-card>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="mainStoreCnpj" mask="00.000.000/0000-00" />
              <mat-error *ngIf="formGroup.controls.mainStoreCnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.mainStoreCnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Versão do APP</mat-label>
              <input matInput formControlName="appVersion" />
              <mat-error *ngIf="formGroup.controls.appVersion?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.appVersion) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Telefone do Primeiro acesso</mat-label>
              <input matInput formControlName="firstAccessPhoneNumber" mask="(00)00000-0000" />
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Telefone do Primeiro acesso</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="firstAccessPhoneNumber"
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Telefone do gerente da conta</mat-label>
              <input matInput formControlName="accountManagerPhoneNumber" mask="(00)00000-0000" />
              <mat-error *ngIf="formGroup.controls.accountManagerPhoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountManagerPhoneNumber) }}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Telefone atendimento JHSF ID</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="accountManagerPhoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.accountManagerPhoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountManagerPhoneNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone atendimento Arquitetos</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="accountManagerPhoneNumberArchitect"
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-Mail do gerente da conta</mat-label>
              <input matInput formControlName="accountManagerEmailAddress" />
              <mat-error *ngIf="formGroup.controls.accountManagerEmailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountManagerEmailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Mensagem padrão</mat-label>
              <input matInput formControlName="accountManagerDefaultMessage" />
              <mat-error *ngIf="formGroup.controls.accountManagerDefaultMessage?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.accountManagerDefaultMessage) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Título da moeda interna</mat-label>
              <input matInput formControlName="rewardsAlias" />
              <mat-error *ngIf="formGroup.controls.rewardsAlias?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tempo de expiração da intenção de pagamento (em minutos)</mat-label>
              <input
                matInput
                type="number"
                formControlName="paymentIntentExpirationTimeInMinutes"
              />
              <mat-error *ngIf="formGroup.controls.paymentIntentExpirationTimeInMinutes?.invalid">
                {{
                  errorsService.messageErrorFor(
                    formGroup.controls.paymentIntentExpirationTimeInMinutes
                  )
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tempo de expiração dos pontos (em dias)</mat-label>
              <input matInput type="number" formControlName="jcoinsExpirationInDays" />
              <mat-error *ngIf="formGroup.controls.jcoinsExpirationInDays?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.jcoinsExpirationInDays) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Período da recorrência</mat-label>
              <input matInput type="number" formControlName="recurrencePeriod" />
              <mat-error *ngIf="formGroup.controls.recurrencePeriod?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.recurrencePeriod) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Valor da recorrência</mat-label>
              <input
                matInput
                type="text"
                formControlName="recurrenceValue"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.recurrenceValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.recurrenceValue) }}
              </mat-error>
            </mat-form-field> -->

            <mat-form-field>
              <mat-label>Pagamento com cartão de crédito</mat-label>

              <mat-select formControlName="isPaymentActive">
                <mat-option [value]="false">Inativo</mat-option>
                <mat-option [value]="true">Ativo</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isPaymentActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isPaymentActive) }}
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field>
              <mat-label>Taxa de Conversão (JCoins)</mat-label>
              <input matInput formControlName="taxConversion" />
            </mat-form-field> -->
            <mat-form-field>
              <mat-label>Botão de excluir conta no APP</mat-label>

              <mat-select formControlName="isAppSelfDeleteButtonActive">
                <mat-option [value]="false">Inativo</mat-option>
                <mat-option [value]="true">Ativo</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isAppSelfDeleteButtonActive?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isAppSelfDeleteButtonActive) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Imposto sobre transações com Jcoins de parceiros internacionais</mat-label>
              <input
                matInput
                formControlName="internationalPurchaseFeePercent"
                suffix="%"
                mask="separator.4"
                decimalMarker=","
              />
              <mat-error *ngIf="formGroup.controls.internationalPurchaseFeePercent?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.internationalPurchaseFeePercent)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>IOF Store ID</mat-label>
              <input matInput formControlName="iofStoreId" />
              <mat-error *ngIf="formGroup.controls.iofStoreId?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Taxa IOF de Cashback</mat-label>
              <input
                matInput
                formControlName="iofTaxCashback"
                suffix="%"
                mask="separator.4"
                decimalMarker=","
              />
              <mat-error *ngIf="formGroup.controls.iofTaxCashback?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor mínimo para geração de JCOINS</mat-label>
              <input
                matInput
                formControlName="minValueToGenerateJCoins"
                prefix="R$ "
                mask="separator.4"
                decimalMarker=","
              />
              <mat-error *ngIf="formGroup.controls.minValueToGenerateJCoins?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Fator de conversão de cashback (IN)</mat-label>
              <input
                matInput
                formControlName="cashbackPointsConversionFactorIn"
                mask="separator.4"
                decimalMarker=","
              />
              <mat-error *ngIf="formGroup.controls.cashbackPointsConversionFactorIn?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Fator de conversão de cashbask (OUT)</mat-label>
              <input
                matInput
                formControlName="cashbackPointsConversionFactorOut"
                mask="separator.4"
                decimalMarker=","
              />
              <mat-error *ngIf="formGroup.controls.cashbackPointsConversionFactorOut?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.rewardsAlias) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Gateway de pagamento em uso: (zoop ou roadpass)</mat-label>

              <mat-select formControlName="currentPaymentProvider">
                <mat-option [value]="'roadpass'">Pagare</mat-option>
                <mat-option [value]="'zoop'">Zoop</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.currentPaymentProvider?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.currentPaymentProvider) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>(AEROPORTO) Regra do elegíveis | EMPRESA 1</mat-label>
              <input matInput formControlName="airportEligible1" mask="00.000.000/0000-00" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>(AEROPORTO) Regra do elegíveis | EMPRESA 2</mat-label>
              <input matInput formControlName="airportEligible2" mask="00.000.000/0000-00" />
            </mat-form-field>
          </mat-card-content>
          <p style="font-size: 12px; margin-bottom: 5px; margin-top: 0px">Título da Categoria</p>
          <ckeditor
            [config]="config"
            [editor]="Editor"
            formControlName="titleCategory"
            (ready)="onReady($event)"
          ></ckeditor>

          <div>
            <p style="margin-bottom: 1px; font-size: 12px">
              Serviços de pagamentos (somente desabilite uma opção caso algum desses serviços for
              desativado. A partir desse momento, nenhuma integração como por exemplo novos
              cadastros irá funcionar.)
            </p>
            <div class="check" style="padding-left: 5px; font-size: 13px">
              <input formControlName="paymentZoop" type="checkbox" (change)="onChange($event)" />
              <mat-label> &nbsp;Zoop</mat-label>
            </div>
            <div class="check" style="padding-left: 5px; font-size: 13px">
              <input
                formControlName="paymentRoadpass"
                type="checkbox"
                (change)="onChange($event)"
              />
              <mat-label> &nbsp;Pagare</mat-label>
            </div>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="gatewayPayment"
              >Campo deve ser preenchido</mat-error
            >
          </div>
          
          <div formArrayName="paymentHubEarlyAdopters" class="col-12" style="margin-top: 20px;">
            <mat-label>CPFs de usuários que transacionarão pelo PaymentHub:</mat-label>
            <br>
            <div>
              <button mat-button type="button" (click)="addCpfEarlyAdopter()">+ Adicionar CPF</button>
            </div>
            <br>
            <ng-container *ngFor="let cpf of paymentHubEarlyAdoptersControls.controls; let i = index">
            
              <mat-form-field class="col-6 col-sm-6">
                <mat-label>Digite o cpf</mat-label>
                <input matInput [formControlName]="i" mask="000.000.000-00"/>
                <button mat-icon-button matSuffix  (click)="removeCpfEarlyAdopter(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-form-field>
              
            </ng-container>           
          
          </div> 
          

          <!-- <ckeditor
            [config]="config"
            [editor]="Editor"
            formControlName="textWellcome"
            (ready)="onReady($event)"
          ></ckeditor> -->
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
