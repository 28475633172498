import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var StoreBankAccountListComponent = /** @class */ (function () {
    function StoreBankAccountListComponent(accountBankService, route, currencyPipe, snackBar) {
        this.accountBankService = accountBankService;
        this.route = route;
        this.currencyPipe = currencyPipe;
        this.snackBar = snackBar;
        this.headersTable = [
            'bankCode',
            'agency',
            'accountNumber',
            'accountDigit',
            'accountType',
            'zoopBankAccountId',
            'actions',
        ];
        this.accounts = [];
        this.storeID = '';
        this.params = { storeFormGroupValue: [] };
    }
    StoreBankAccountListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.listBanckAccounts();
    };
    StoreBankAccountListComponent.prototype.listBanckAccounts = function () {
        var _this = this;
        this.accounts = [];
        if (this.storeID != '') {
            // ?state=PENDING&rewardsState=FUTURE
            this.accountBankService.list(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.accounts = response;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                // this.snackBar.open(error.error.message);
            });
        }
        else {
            this.accountBankService.list(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.accounts = response;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                // this.snackBar.open(error.error.message);
            });
        }
    };
    StoreBankAccountListComponent.prototype.remove = function (id) {
        var _this = this;
        this.accountBankService.delete(id, this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.listBanckAccounts();
                this.snackBar.open('Conta bancária removida com sucesso.');
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreBankAccountListComponent.prototype.convertStatus = function (status) {
        switch (status) {
            case 'CHECKING':
                return 'Corrente';
                break;
            case 'SAVINGS':
                return 'Poupança';
                break;
            default:
                return '';
        }
    };
    StoreBankAccountListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return StoreBankAccountListComponent;
}());
export { StoreBankAccountListComponent };
