<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content class="content">
    <mat-card>
      <div class="row">
        <h3>
          Existem algumas notas fiscais pendentes de pagamento que são anteriores a data seleciona, 
          mas que ainda não foram incluídas em nenhum fechamento anteriormente.
        </h3>
        <h3>
          Se desejar, selecione abaixo as notas que deseja incluir nesse fechamento:
        </h3>
      </div>
        
      <div class="table-responsive">    

        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="selector">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <input  type="checkbox"
              [checked]="selectedInvoices[element.id]"
              [value]="element.id"
              (change)="onInvoiceSelect($event.target.checked, element.id)"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="storeName">
            <th mat-header-cell *matHeaderCellDef>Parceiro/Loja</th>
            <td mat-cell *matCellDef="let element">{{ element.stores?.nomeFantasia }}</td>                
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef>Número da Nota fiscal</th>
            <td mat-cell *matCellDef="let element">{{ element.invoiceNumber }}</td>
            
          </ng-container>     
    
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Valor total</th>
            <td mat-cell *matCellDef="let element">{{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}</td>
            
          </ng-container> 
          
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: 'dd/MM/yyyy':'GMT -0300' }}
            </td>
            
          </ng-container>     

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
      </div>
      
      
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="actions">      
      <button mat-button (click)="confirm()" class="btn-submit-dark">
        Criar Fechamento com as Notas fiscais selecionadas
      </button>
    </div>
  </div>
</main>
