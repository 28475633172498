import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalLiquidationTransferDetails = /** @class */ (function () {
    function ModalLiquidationTransferDetails(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        console.log('data', data);
        this.transfer = data;
    }
    ModalLiquidationTransferDetails.prototype.ngOnInit = function () {
        // empty
    };
    return ModalLiquidationTransferDetails;
}());
export { ModalLiquidationTransferDetails };
