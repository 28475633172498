import { Segmentation } from './../../../../models/segmentation'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { BusinessPartnersService } from '../../../../services/businessPartners.service'
import { CategoriesService } from '../../../../services/categories.service'
import { UploadAdapter } from '../../../../services/uploadAdapter.service'
import { NotificationService } from '../../../../services/notification.service'
import { SegmentationService } from '../../../../services/segmentation.service'

import { Location } from '@angular/common'

import { ReportService } from '../../../../services/report.service'
import { Observable } from 'rxjs'
import { startWith, map, filter } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'

@Component({
  selector: 'app-notication-form',
  templateUrl: './notication-form.component.html',
  styleUrls: ['./notication-form.component.scss'],
})
export class NoticationFormComponent implements OnInit {
  public formGroup: FormGroup
  public uploadData: FormData
  public id = ''
  public approveDoc: string
  public imageUrl
  public config: any
  public contentInvalid = false
  private initalized = false
  public Editor = ClassicEditor
  public selectedCustomers: any[] = []
  public selectedSegmentation: any[] = []
  public customerControl = new FormControl()
  public segmentationControl = new FormControl()
  public checkListUsers = false
  public checkListSegmentation = false
  public segmentationInvalid = false
  public nameRedirectUrl: string
  public routeRedirectUrl: string
  public customerInvalid = false
  public forAll = false
  isManual = false
  public redirectUrl = false
  return = ''
  idCustomer: string[] = []
  visible = true
  selectable = true
  public removable = true
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public params = { formGroupValue: [] }

  filteredCustomers: Observable<string[]>
  filteredSegmentation: Observable<string[]>
  customers: any[] = []
  segmentation: any[] = []
  private customerListPromise: Promise<any>
  private segmentationListPromise: Promise<any>
  private listTypesPromise: Promise<any>
  listTypes: any
  architect: any

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  @ViewChild('segmentationInput', { static: false }) segmentationInput: ElementRef<HTMLInputElement>
  @ViewChild('autoSegmentation', { static: false }) matAutocompleteSegmentation: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    public businessPartnersService: BusinessPartnersService,
    public reportService: ReportService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    public categoriesService: CategoriesService,
    public location: Location,
    public notificationService: NotificationService,
    public segmentationService: SegmentationService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.id = this.route.snapshot.paramMap.get('id')
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.initPromise().catch(error => {
      console.log(error)
    })
    this.initForm()

    this.config = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'undo',
          'redo',
          '|',
        ],
      },
    }
  }

  async initPromise () {
    if (this.architect) {
      this.customerListPromise = this.reportService
        .listCustomersArchitects()
        .toPromise()
        .then(
          response => {
            response.map(item => {
              this.customers.push(item)
            })
          },
          error => {
            console.log(error)
          },
        )
    } else {
      this.customerListPromise = this.reportService
        .listCustomers()
        .toPromise()
        .then(
          response => {
            response.map(item => {
              this.customers.push(item)
            })
          },
          error => {
            console.log(error)
          },
        )
    }

    this.segmentationListPromise = this.segmentationService.list().then(
      response => {
        response.results.map(item => {
          this.segmentation.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers = await this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.filteredSegmentation = await this.segmentationControl.valueChanges.pipe(
      startWith(''),
      map((segmentation: any) =>
        segmentation ? this._filterSegmentation(segmentation) : this.segmentation.slice(0, 20),
      ),
    )

    if (this.id) {
      await this.getById()
    } else {
      this.listTypesPromise = this.notificationService.listTypes('?type=MANUAL').then(
        response => {
          this.listTypes = response
        },
        error => {
          console.log(error)
        },
      )
    }
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      imageUrl: [null, []],
      content: [null, [Validators.required]],
      sendPush: [null, []],
      scheduledPush: [null, []],
      scheduledDate: [null, [Validators.required]],
      scheduledTime: [null, [Validators.required]],
      sendForAllCustomers: [null, []],
      sendCustomers: [null, []],
      customer: [null, [Validators.required]],
      name: [null, [Validators.required]],
      customersInput: [null, [Validators.required]],
      segmentationsInput: [null, []],
      useSegmentation: [null, []],
      options: [null, [Validators.required]],
      type: [null, [Validators.required]],
    })
  }

  getTime (datetime) {
    return `${new Date(datetime).getHours().toString()}:${(new Date(datetime).getMinutes() < 10 ? '0' : '')}${new Date(datetime).getMinutes().toString()}`
  }

  getById () {
    this.notificationService.findOne(this.id).then(
      async response => {
        if (response.scheduledPush && response.scheduledDateTime) {
          const date = response.scheduledDateTime.split('T')[0]
          const time = this.getTime(response.scheduledDateTime)
          this.formGroup.patchValue({
            title: response.title,
            description: response.description,
            imageUrl: response.image,
            content: response.content,
            sendPush: response.sendPush,
            scheduledPush: response.scheduledPush,
            scheduledDate: date,
            scheduledTime: time,
            useSegmentation: response.segmentations,
            sendForAllCustomers: response.forAll,
            type: response.eventsTypeSegmentationId,
          })
        } else {
          this.formGroup.patchValue({
            title: response.title,
            description: response.description,
            imageUrl: response.image,
            content: response.content,
            sendPush: response.sendPush,
            scheduledPush: response.scheduledPush,
            useSegmentation: response.segmentations,
            sendForAllCustomers: response.forAll,
            type: response.eventsTypeSegmentationId,
          })
        }
        this.forAll = !!response.forAll
        this.imageUrl = response.image
        this.isManual = response.type === 'AUTOMATIC'

        const filter = response.type === 'AUTOMATIC' ? '?type=AUTOMATIC' : '?type=MANUAL'

        this.listTypesPromise = this.notificationService.listTypes(filter).then(
          response => {
            this.listTypes = response
          },
          error => {
            console.log(error)
          },
        )

        if (response.type === 'AUTOMATIC' || response.redirectUrl) {
          this.formGroup.get('sendPush').disable()
          this.formGroup.get('customer').disable()
          this.formGroup.get('sendForAllCustomers').disable()
          this.formGroup.get('title').disable()
          this.formGroup.get('description').disable()
          this.formGroup.get('content').disable()
          this.formGroup.get('customersInput').disable()
          this.formGroup.get('useSegmentation').disable()
          this.formGroup.get('segmentationsInput').disable()
          this.formGroup.get('options').disable()
          this.formGroup.get('type').disable()
          this.formGroup.get('scheduledPush').disable()
        }
        if (response.forAll) {
          this.formGroup.patchValue({
            options: 'checkAllCustomers',
          })
        } else if (response.segmentations) {
          this.checkListSegmentation = true
          this.formGroup.patchValue({
            options: 'checkSegmentation',
          })
          if (response.segmentations) {
            await this.segmentationListPromise
            const filterSegmentations = this.segmentation.filter(item => {
              return response.segmentations.includes(item.id)
            })
            filterSegmentations.map(item => {
              this.selectedSegmentation.push(item)
            })
          }
        } else {
          this.checkListUsers = true
          this.checkListSegmentation = false
          this.formGroup.patchValue({
            options: 'checkCustomers',
          })
          if (response.customers) {
            await this.customerListPromise
            const filterCustomer = this.customers.filter(item => {
              return response.customers.includes(item.customerId)
            })
            filterCustomer.map(item => {
              this.selectedCustomers.push(item)
            })
          }
        }

        this.redirectUrl = !!response.redirectUrl

        if (response.redirectUrl) {
          const array = response.redirectUrl.split('/').filter(item => item) as string

          if (array[1] === 'quizzes') {
            this.nameRedirectUrl = 'Questionário'
            this.routeRedirectUrl = `/dashboard/quiz/${array[2]}/questions/list`
          }
          if (array[1] === 'transaction') {
            this.nameRedirectUrl = 'Transação'
            this.routeRedirectUrl = `/dashboard/transactions/detail/${array[2]}`
          }
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  checkAllCustomers (event) {
    this.forAll = true
    this.segmentationInvalid = false
    this.customerInvalid = false
    this.checkListUsers = false
    this.checkListSegmentation = false
    this.selectedCustomers = []
    this.selectedSegmentation = []
    this.formGroup.patchValue({
      useSegmentation: false,
      sendCustomers: false,
    })
  }

  checkCustomers (event) {
    this.forAll = false
    this.segmentationInvalid = false
    this.checkListUsers = true
    this.checkListSegmentation = false
    this.selectedSegmentation = []
    this.formGroup.patchValue({
      useSegmentation: false,
    })
  }

  checkSegmentation (event) {
    this.forAll = false
    this.customerInvalid = false
    this.checkListSegmentation = true
    this.checkListUsers = false
    this.selectedCustomers = []
    this.formGroup.patchValue({
      sendCustomers: false,
    })
  }

  selectFile (event) {
    const file = event.target.files[0]
    this.uploadData = file

    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result
    }
  }

  removeImage () {
    this.imageUrl = undefined
    this.uploadData = null
    this.approveDoc = undefined
  }

  imgLoadError () {
    this.imageUrl = undefined
  }

  onChange ({ editor }: ChangeEvent) {
    if (!this.initalized) {
      return
    }
    this.contentInvalid = !this.formGroup.value.content
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  submit () {
    let imageUrl = this.imageUrl
    let data
      ; (async () => {
      this.contentInvalid = !this.formGroup.value.content
      this.initalized = !this.formGroup.value.content

      !this.selectedCustomers.length && this.checkListUsers
        ? (this.customerInvalid = true)
        : (this.customerInvalid = false)
      !this.selectedSegmentation.length && this.checkListSegmentation
        ? (this.segmentationInvalid = true)
        : (this.segmentationInvalid = false)
      if (
        !this.formGroup.value.title ||
          !this.formGroup.value.description ||
          this.contentInvalid ||
          this.segmentationInvalid ||
          this.customerInvalid
      ) {
        this.snackBar.open('Preencha corretamente os campos e tente novamente.')
        return false
      }

      if (this.formGroup.value.scheduledPush) {
        if (!this.formGroup.value.scheduledDate || !this.formGroup.value.scheduledTime) {
          this.snackBar.open('Preencha corretamente os campos e tente novamente.')
          return false
        }
      }

      if (this.uploadData) {
        const { readUrl, uploadUrl } = await this.notificationService.getS3Url()
        imageUrl = readUrl
        await this.notificationService.uploadToS3(uploadUrl, this.uploadData)
      }
      let scheduledDate = null
      if (this.formGroup.value.scheduledPush) {
        scheduledDate = `${this.formGroup.value.scheduledDate} ${this.formGroup.value.scheduledTime}:00`
      }

      data = {
        title: this.formGroup.value.title,
        description: this.formGroup.value.description,
        image: imageUrl,
        content: this.formGroup.value.content,
        sendPush: !!this.formGroup.value.sendPush,
        scheduledPush: !!this.formGroup.value.scheduledPush,
        scheduledDateTime: scheduledDate,
        customers:
            !this.forAll && !this.selectedCustomers.length
              ? undefined
              : JSON.stringify(this.forAll ? [] : this.selectedCustomers.map(c => c.customerId)),
        segmentations: JSON.stringify(this.selectedSegmentation.map(c => c.id)),
        forAll: this.forAll,
        eventsTypeSegmentationId: this.formGroup.value.type,
        isArchitect: !!this.architect,
      }

      if (this.id) {
        this.notificationService.update(data, this.id).then(
          async response => {
            this.snackBar.open('Notificação alterada com sucesso.')
            if (this.architect) {
              this.router.navigate(['./dashboard/notifications-architect/architect'])
            } else {
              this.router.navigate(['./dashboard/notifications'])
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        this.notificationService.create(data).then(
          async response => {
            this.snackBar.open('Notificação cadastrada com sucesso.')
            if (this.architect) {
              this.router.navigate(['./dashboard/notifications-architect/architect'])
            } else {
              this.router.navigate(['./dashboard/notifications'])
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      }
    })().catch(error => this.snackBar.open(error.error.message))
  }

  private _filter (customer: string): string[] {
    const remove = String(customer).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.customers
      .filter(c => !this.selectedCustomers.includes(c))
      .filter(c =>
        `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
      )
      .slice(0, 20)
  }

  private _filterSegmentation (segmentation: string): string[] {
    const remove = String(segmentation).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.segmentation
      .filter(c => !this.selectedSegmentation.includes(c))
      .filter(c => `${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  removeCustomer (customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  removeSegmentation (segmentation: any): void {
    const index = this.selectedSegmentation.indexOf(segmentation)
    if (index >= 0) {
      this.selectedSegmentation.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.id) {
      this.selectedSegmentation.push(event.option.value)
      this.segmentationInput.nativeElement.value = ''
      this.segmentationControl.setValue(null)
      this.segmentationInvalid = false
    } else {
      this.selectedCustomers.push(event.option.value)
      this.customerInput.nativeElement.value = ''
      this.customerControl.setValue(null)
      this.customerInvalid = false
    }
  }
}
