import { Component, OnInit, ViewChild } from '@angular/core'
import { NotificationService } from '../../../../services/notification.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { Location } from '@angular/common'
import * as moment from 'moment'
import * as _ from 'lodash'
import { PageEvent } from '@angular/material/paginator'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  public headersTable: string[]
  public pageTotal: number
  dataSource: any
  fieldsForm: any
  formGroup: FormGroup
  architect: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  params: Params

  pageEvent: PageEvent

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor (
    public notificationService: NotificationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        this.initForm()
      } else {
        this.initForm()
        this.notificationList()
      }
    })
  }

  initForm () {
    this.headersTable = ['title', 'description', 'date', 'actions']
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'titleNotification',
        label: 'Título',
        placeholder: 'Título',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'descriptionNotification',
        label: 'Descrição',
        placeholder: 'Descrição',
      },
      {
        field: 'input',
        type: 'date',
        formControlName: 'dateNotification',
      },
      {
        field: 'select',
        formControlName: 'typeNotification',
        label: 'Tipo',
        list: [
          {
            key: 'MANUAL',
            value: 'Manual',
          },
          {
            key: 'AUTOMATIC',
            value: 'Automático',
          },
        ],
      },
    ]
  }

  notificationList () {
    if (this.architect) {
      this.notificationService
        .list(`?filters={"page": ${this.page}, "pageSize": 10,"filters":[{"fields":["customerOrigin"], "op": "=", "value":"ARCHITECT"}]}`)
        .then(
          async response => {
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(this.groupNotification(response.results))
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      this.notificationService
        .list(`?filters={"page": ${this.page}, "pageSize": 10,"filters":[{"fields":["customerOrigin"], "op": "=", "value":"JHSFID"}]}`)
        .then(
          async response => {
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(this.groupNotification(response.results))
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    }
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Notificação?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.notificationService.delete(id).then(
            async response => {
              this.notificationList()
              this.snackBar.open('Notificação removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  groupNotification (data) {
    const groups = _(data)
      .groupBy(v => moment(v.createdAt).format('LL'))
      .value()
    const result = []
    for (const group in groups) {
      result.push({ group })
      for (const item of groups[group]) {
        result.push(item)
      }
    }
    return result
  }

  isGroup (index, item): boolean {
    return !!item.group
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(this.groupNotification(returnFilter.results))
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
