import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
var LiquidationTransferPermissions = /** @class */ (function () {
    function LiquidationTransferPermissions(formBuilder, storeService, macroGroupsService, snackBar, liquidationTransferService) {
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.macroGroupsService = macroGroupsService;
        this.snackBar = snackBar;
        this.liquidationTransferService = liquidationTransferService;
        this.macroGroups = [];
        this.searchTerms = new Subject();
    }
    LiquidationTransferPermissions.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsForm = this.formBuilder.group({
            selectedMacroGroups: [[]],
            blackListedStores: [[]],
            storeSearchInput: [''],
            macroGroupSearchInput: [''],
        });
        this.filteredStores = this.searchTerms.pipe(debounceTime(1000), switchMap((function (term) {
            if (term.length > 3) {
                return _this.getFilteredStores(term);
            }
            else {
                return of([]);
            }
        })), switchMap(function (result) {
            var selectedStores = _this.settingsForm.get('blackListedStores').value;
            if (selectedStores && selectedStores.length > 0) {
                var filteredStores = result.filter(function (store) { return !selectedStores.find(function (selectedStore) { return selectedStore.id === store.id; }); });
                return of(filteredStores);
            }
            return of(result);
        }));
        this.settingsForm.get('macroGroupSearchInput').valueChanges.subscribe(function (value) {
            try {
                var filterValue_1 = value.toLowerCase();
                var filteredMacroGroups = _this.macroGroups.filter(function (macroGroup) {
                    return macroGroup.macroName.toLowerCase().includes(filterValue_1);
                });
                _this.setFilteredMacrogroups(filteredMacroGroups);
            }
            catch (e) {
            }
        });
        this.loadSelectedResources();
        this.loadMacroGroups();
    };
    LiquidationTransferPermissions.prototype.loadMacroGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.macroGroupsService.list()];
                    case 1:
                        results = (_a.sent()).results;
                        this.macroGroups = results;
                        this.setFilteredMacrogroups(this.macroGroups);
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferPermissions.prototype.setFilteredMacrogroups = function (filteredMacroGroups) {
        var _this = this;
        this.filteredMacroGroups = filteredMacroGroups.filter(function (macroGroup) {
            return !_this.settingsForm.get('selectedMacroGroups').value.find(function (selectedMacroGroup) { return selectedMacroGroup.id === macroGroup.id; });
        });
    };
    LiquidationTransferPermissions.prototype.loadSelectedResources = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, macroGroupsEnabled, storesBlackListed;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.getLiquidationTransferPermissions().toPromise()];
                    case 1:
                        _a = _b.sent(), macroGroupsEnabled = _a.macroGroupsEnabled, storesBlackListed = _a.storesBlackListed;
                        this.settingsForm.patchValue({
                            selectedMacroGroups: macroGroupsEnabled,
                            blackListedStores: storesBlackListed
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferPermissions.prototype.getFilteredStores = function (term) {
        return this.storeService.getStoreByCnpjOrName(term);
    };
    LiquidationTransferPermissions.prototype.search = function (term) {
        if (term.length > 3) {
            this.searchTerms.next(term);
        }
    };
    LiquidationTransferPermissions.prototype.searchMacroGroup = function (term) {
        if (term.length > 3) {
            this.searchTerms.next(term);
        }
    };
    LiquidationTransferPermissions.prototype.highlightMatches = function (text, term) {
        if (!term) {
            return text;
        }
        var re = new RegExp(term, 'gi');
        return text.replace(re, function (match) { return "<strong>" + match + "</strong>"; });
    };
    // Event handler for when an option is selected in mat-autocomplete
    LiquidationTransferPermissions.prototype.onOptionSelected = function (event) {
        var selectedStore = event.option.value;
        var stores = this.settingsForm.get('blackListedStores').value || [];
        stores.push(selectedStore);
        this.settingsForm.get('blackListedStores').setValue(stores);
        this.settingsForm.get('storeSearchInput').setValue('');
        event.source.options.reset([]);
    };
    LiquidationTransferPermissions.prototype.onMacroGroupOptionSelected = function (event) {
        var selectedMacroGroup = event.option.value;
        var macroGroups = this.settingsForm.get('selectedMacroGroups').value || [];
        macroGroups.push(selectedMacroGroup);
        this.settingsForm.get('selectedMacroGroups').setValue(macroGroups);
        this.settingsForm.get('macroGroupSearchInput').setValue('');
        this.setFilteredMacrogroups(this.macroGroups);
        this.input.nativeElement.blur();
    };
    // Event handler for removing a store from the blackListedStores
    LiquidationTransferPermissions.prototype.removeStore = function (index) {
        var stores = this.settingsForm.get('blackListedStores').value || [];
        stores.splice(index, 1);
        this.settingsForm.get('blackListedStores').setValue(stores);
    };
    LiquidationTransferPermissions.prototype.removeMacroGroup = function (index) {
        var macroGroups = this.settingsForm.get('selectedMacroGroups').value || [];
        macroGroups.splice(index, 1);
        this.settingsForm.get('selectedMacroGroups').setValue(macroGroups);
        this.settingsForm.get('macroGroupSearchInput').setValue(this.settingsForm.get('macroGroupSearchInput').value);
    };
    LiquidationTransferPermissions.prototype.submitForm = function () {
        var _this = this;
        var selectedMacroGroups = this.settingsForm.get('selectedMacroGroups').value;
        var blackListedStores = this.settingsForm.get('blackListedStores').value;
        var data = {
            macroGroupIds: selectedMacroGroups.map(function (m) { return m.id; }),
            storesBlackListedIds: blackListedStores.map(function (s) { return s.id; }),
        };
        if (data.macroGroupIds.length === 0) {
            this.snackBar.open('Selecione pelo menos um MacroGrupo');
            return;
        }
        this.liquidationTransferService
            .updateLiquidationTransferPermissions(data)
            .toPromise()
            .then(function (result) {
            _this.snackBar.open('Configurações atualizadas com sucesso');
        })
            .catch(function (err) {
            _this.snackBar.open('Problema ao atualizar as informações');
        });
    };
    return LiquidationTransferPermissions;
}());
export { LiquidationTransferPermissions };
