<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; height: calc(90vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h2>Estorno</h2>
              <div class="col-12 col-sm-12">
                <h5>
                  Deseja realmente estornar {{ data.jcoinValue }} J COINS do crédito manual
                  realizado no dia {{ data.transactionDate | date: 'dd/MM/yyyy' }} do parceiro
                  {{ data.partnerName }}
                </h5>
                <mat-form-field>
                  <input matInput formControlName="description" placeholder="Descrição" />
                  <mat-error *ngIf="formGroup.controls.description.invalid">
                    {{ errorsService.messageErrorFor(formGroup.controls.description) }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button type="submit" class="btn-submit-dark">Estornar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
