<app-logged class="store-list">
  <header class="store-list__header">
    <h2>PARCEIROS</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>
      <button class="btn-primary-light" mat-button (click)="updateStatus()">ATUALIZAR</button>
      <button class="btn-primary-light" mat-button (click)="openDialog()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter maskExample="00.000.000/0000-00" actions="form" type="store" [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)" [formGroupValue]="formGroup"></app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj }}</td>
        <!-- <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td> -->
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome Fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Relatório" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/reports" [queryParams]="params" skipLocationChange="true">
            <mat-icon>assignment</mat-icon>
          </button>
          <button mat-icon-button matTooltip="QR Code" matTooltipPosition="left"
            routerLink="/dashboard/store/{{ element.id }}/qrcode" [queryParams]="params" skipLocationChange="true">
            <mat-icon>qr_code</mat-icon>
          </button>
          <!-- <button mat-icon-button matTooltip="Conta(s) Bancária(s)" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/bank-account" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>account_balance</mat-icon>
          </button> -->
          <button mat-icon-button matTooltip="Documentos" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/documents" [queryParams]="params" skipLocationChange="true">
            <mat-icon>folder</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Editar" matTooltipPosition="left" (click)="navigateToEdit(element)"
            [queryParams]="params" skipLocationChange="true">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Transações" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/transactions" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button mat-icon-button [disabled]="!element.manualPaymentEnabled"
            matTooltip="Transações manuais pendentes de aprovação" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/transactions/pending" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>pending_actions</mat-icon>
          </button>
          <button
            [disabled]="element.isMainStore && !permissionsService.userHasPermission(cmsPermissions.internal_user_managment)"
            mat-icon-button matTooltip="Usuários" matTooltipPosition="left"
            routerLink="/dashboard/stores/{{ element.id }}/users" [queryParams]="params" skipLocationChange="true">
            <mat-icon>supervisor_account</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter type="store" actions="pagination" [length]="pageTotal" [pageSizeOptions]="pageSizeOptions"
      [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)" [formGroupValue]="formGroup">
    </app-form-filter>
  </div>
</app-logged>