import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { AirportsService } from '../../../../services/airports.service'

@Component({
  selector: 'app-information-airports-form',
  templateUrl: './information-form.component.html',
  styleUrls: ['./information-form.component.scss'],
})
export class AirportInformationFormComponent implements OnInit {
  public formGroup: FormGroup
  public airportID: string
  public informationID: string

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public airportsService: AirportsService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.airportID = this.route.snapshot.paramMap.get('airportID')
    this.informationID = this.route.snapshot.paramMap.get('informationID')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      title: [null, [Validators.required]],
      order: [null, [Validators.required]],
      footerMsg: [null, [Validators.required]],
    })
    if (this.informationID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.airportsService.informationFindOne(this.informationID).then(async response => {
      this.formGroup.patchValue({
        title: response.title,
        order: response.order,
        footerMsg: response.footerMsg,
      })
      this.airportID = response.airportId
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      title: this.formGroup.value.title,
      order: this.formGroup.value.order,
      footerMsg: this.formGroup.value.footerMsg,
    }

    if (this.informationID) {
      this.airportsService.updateInformation(this.airportID, this.informationID, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/airport/' + this.airportID + '/informations'])
            this.snackBar.open('Informação atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.airportsService.createInformation(this.airportID, data).then(
        async response => {
          console.log(response)
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airport/' + this.airportID + '/informations'])
            this.snackBar.open('Informação criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
