import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var TermsListComponent = /** @class */ (function () {
    function TermsListComponent(termService, snackBar) {
        this.termService = termService;
        this.snackBar = snackBar;
        this.headersTable = ['heading', 'subtitle', 'createdAt', 'updatedAt'];
        this.terms = [];
    }
    TermsListComponent.prototype.ngOnInit = function () {
        this.list();
    };
    TermsListComponent.prototype.list = function () {
        var _this = this;
        this.termService.list().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.terms = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    return TermsListComponent;
}());
export { TermsListComponent };
