import { Component, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { CampaignService } from 'src/app/services/campaign.service'

@Component({
  selector: 'app-store-campaign-report',
  templateUrl: './store-campaign-report.component.html',
  styleUrls: ['./store-campaign-report.component.scss'],
})
export class StoreCampaignReportComponent implements OnInit {
  public storeID: string

  public parsedActiveCampaigns: any
  public parsedInactiveCampaigns: any
  public parsedFutureCampaigns: any
  public parsedDefaultCampaigns: any

  public headersTableReportInfo: string[] = [
    'CampaignId',
    'CampaignDesc',
    'CampaignInitialDate',
    'CampaignEndDate',
    'macroGroup',
  ]

  public headersTableReportDefaultCampaignInfo: string[] = [
    'CampaignId',
    'CampaignDesc',
    'CampaignInitialDate',
    'macroGroup',
  ]

  constructor (
    public campaignService: CampaignService,
    public readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.campaignService.fetchCampaignsReport(this.storeID)
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        async response => {
          const activeCampaigns = []
          const inactiveCampaigns = []
          const futureCampaigns = []
          const defaultCampaigns = []
          response.report.map(item => {
            if (item.macroGroupId) {
              item.campaigns = {
                ...item.campaigns,
                macroGroup: item.macroGroups.macroName,
              }
            } else {
              item.campaigns = {
                ...item.campaigns,
                macroGroup: 'N/A',
              }
            }
            if (item.campaigns.isDefault) {
              defaultCampaigns.push(item.campaigns)
            } else {
              if (Date.parse(item.campaigns.campaignEndDate) >= Date.now()
                && Date.parse(item.campaigns.campaignInitialDate) <= Date.now()) {
                activeCampaigns.push(item.campaigns)
              } else if (Date.parse(item.campaigns.campaignEndDate) < Date.now()) {
                inactiveCampaigns.push(item.campaigns)
              } else if (Date.parse(item.campaigns.campaignInitialDate) > Date.now()) {
                futureCampaigns.push(item.campaigns)
              }
            }
          })
          this.parsedActiveCampaigns = new MatTableDataSource(activeCampaigns)
          this.parsedInactiveCampaigns = new MatTableDataSource(inactiveCampaigns)
          this.parsedFutureCampaigns = new MatTableDataSource(futureCampaigns)
          this.parsedDefaultCampaigns = new MatTableDataSource(defaultCampaigns)
        },
        error => console.log(error),
      )
  }
}
