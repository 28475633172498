import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LiquidationTransferService = /** @class */ (function () {
    function LiquidationTransferService(http) {
        this.http = http;
    }
    LiquidationTransferService.prototype.listShedules = function (filters) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/schedule" + (filters || ''))
            .pipe(tap(function (_) { return _this.log('/schedule'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.listShedulesfromStore = function (storeId, filters) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/schedule-from-store/" + storeId + (filters || ''))
            .pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/schedule-from-store'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.getScheduleDetail = function (id) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/schedule/" + id)
            .pipe(tap(function (_) { return _this.log('/schedule/:id'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.retryPayments = function (retryPaymentData) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/liquidation-transfer/transfer/retry-payments", retryPaymentData)
            .pipe(tap(function (_) { return _this.log('/schedule/:id'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.retrySendToApproval = function (retryPaymentData) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/liquidation-transfer/transfer/retry-approval-send", retryPaymentData)
            .pipe(tap(function (_) { return _this.log('/schedule/:id'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.getLiquidationTransferPermissions = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/liquidation-transfer-permissions").pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/liquidation-transfer-permissions'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.updateLiquidationTransferPermissions = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/liquidation-transfer/update-liquidation-transfer-permissions", data).pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/update-liquidation-transfer-permissions'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.updateLiquidationTransferSettings = function (data) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/liquidation-transfer/settings", data).pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/settings'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.getLiquidationTransferSettings = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/settings").pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/settings'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.updateScheduledLiquidationTransfersBankAccount = function (storeId) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/liquidation-transfer/update-scheduled-bank-account?storeId=" + storeId, {}).pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/update-scheduled-bank-account'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.checkIfFutureSchedulesExistsForStore = function (storeId) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/liquidation-transfer/check-future-schedules?storeId=" + storeId).pipe(tap(function (_) { return _this.log('cms/liquidation-transfer/check-future-schedules'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.downloadReport = function (filters) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/reports/liquidation-transfer-general-report" + (filters || ''))
            .pipe(tap(function (_) { return _this.log('cms/reports/liquidation-transfer-general-report'); }), catchError(this.handleError));
    };
    LiquidationTransferService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    LiquidationTransferService.prototype.log = function (message) {
    };
    LiquidationTransferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LiquidationTransferService_Factory() { return new LiquidationTransferService(i0.ɵɵinject(i1.HttpClient)); }, token: LiquidationTransferService, providedIn: "root" });
    return LiquidationTransferService;
}());
export { LiquidationTransferService };
