import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
var ModalFormNotificationQuizComponent = /** @class */ (function () {
    function ModalFormNotificationQuizComponent(formBuilder, reportService, notificationService, segmentationService, dialogRef, snackBar, data) {
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.notificationService = notificationService;
        this.segmentationService = segmentationService;
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.data = data;
        this.customerControl = new FormControl();
        this.segmentationControl = new FormControl();
        this.selectedCustomers = [];
        this.selectedSegmentation = [];
        this.checkUser = false;
        this.checkUseSegmentation = false;
        this.segmentationInvalid = false;
        this.customers = [];
        this.segmentation = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormNotificationQuizComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            sendPush: [null, [Validators.required]],
            sendForAllCustomers: [null, []],
            customersInput: [null, [Validators.required]],
            segmentationsInput: [null, []],
            useSegmentation: [null, []],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.segmentationService.list().then(function (response) {
            response.results.map(function (item) {
                _this.segmentation.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        this.filteredSegmentation = this.segmentationControl.valueChanges.pipe(startWith(''), map(function (segmentation) {
            return segmentation ? _this._filterSegmentation(segmentation) : _this.segmentation.slice(0, 20);
        }));
    };
    ModalFormNotificationQuizComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormNotificationQuizComponent.prototype.checkCustomers = function (event) {
        if (this.formGroup.value.sendForAllCustomers) {
            this.selectedCustomers = [];
            this.checkUser = true;
        }
        else {
            this.checkUser = false;
        }
    };
    ModalFormNotificationQuizComponent.prototype.checkSegmentation = function (event) {
        if (this.formGroup.value.useSegmentation) {
            this.checkUseSegmentation = true;
        }
        else {
            this.checkUseSegmentation = false;
            this.segmentationInvalid = false;
        }
    };
    ModalFormNotificationQuizComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    ModalFormNotificationQuizComponent.prototype._filterSegmentation = function (segmentation) {
        var _this = this;
        var remove = String(segmentation).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.segmentation
            .filter(function (c) { return !_this.selectedSegmentation.includes(c); })
            .filter(function (c) { return ("" + String(c.name)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormNotificationQuizComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    ModalFormNotificationQuizComponent.prototype.removeSegmentation = function (segmentation) {
        var index = this.selectedSegmentation.indexOf(segmentation);
        if (index >= 0) {
            this.selectedSegmentation.splice(index, 1);
        }
    };
    ModalFormNotificationQuizComponent.prototype.selected = function (event) {
        if (event.option.value.id) {
            this.selectedSegmentation.push(event.option.value);
            this.segmentationInput.nativeElement.value = '';
            this.segmentationControl.setValue(null);
            this.segmentationInvalid = false;
        }
        else {
            this.selectedCustomers.push(event.option.value);
            this.customerInput.nativeElement.value = '';
            this.customerControl.setValue(null);
        }
    };
    ModalFormNotificationQuizComponent.prototype.submit = function () {
        var _this = this;
        !this.selectedSegmentation.length && this.checkUseSegmentation
            ? (this.segmentationInvalid = true)
            : (this.segmentationInvalid = false);
        if (this.segmentationInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var data = {
            title: this.data.name,
            description: this.data.name,
            content: '',
            sendPush: this.formGroup.value.sendPush,
            customers: !this.formGroup.value.sendForAllCustomers && !this.selectedCustomers.length
                ? undefined
                : JSON.stringify(this.formGroup.value.sendForAllCustomers
                    ? []
                    : this.selectedCustomers.map(function (c) { return c.customerId; })),
            redirectUrl: "jhsfid://quizzes/" + this.data.id,
            segmentations: JSON.stringify(this.selectedSegmentation.map(function (c) { return c.id; })),
        };
        this.notificationService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.cancel();
                this.snackBar.open('Notificação cadastrada com sucesso.');
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return ModalFormNotificationQuizComponent;
}());
export { ModalFormNotificationQuizComponent };
