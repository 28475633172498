import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import * as queryString from 'query-string'
import JsFileDownloader from 'js-file-downloader'
import { Observable } from 'rxjs'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { startWith, map } from 'rxjs/operators'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from '../../core/services/errors.service'

@Component({
  selector: 'app-modal-form-transactions',
  templateUrl: './modal-form-transactions.component.html',
  styleUrls: ['./modal-form-transactions.component.scss'],
})
export class ModalFormTransactionsComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public file: string
  public storesUser: any[] = []
  filteredStores: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public storeSelected: any
  stores: any[] = []
  public selectedStores: any[] = []

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalFormTransactionsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      storeId: string
    },
  ) {}

  ngOnInit () {
    this.storesUser = JSON.parse(localStorage.getItem('storesUser'))

    if (this.storesUser.length > 1) {
      this.formGroup = this.formBuilder.group({
        beginDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        storesInput: [null, []],
      })

      this.filteredStores = this.storeControl.valueChanges.pipe(
        startWith(''),
        map((store: any) => (store ? this._filterStores(store) : this.storesUser.slice(0, 20))),
      )
    } else {
      this.formGroup = this.formBuilder.group({
        beginDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
      })
    }
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.storesUser
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.storeName)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  remove (value: any): void {
    const index = this.selectedStores.indexOf(value)
    if (index >= 0) {
      this.selectedStores.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    this.selectedStores.push(event.option.value)
    this.storeInput.nativeElement.value = ''
    this.storeControl.setValue(null)
  }

  downloadFile (url) {
    window.open(url, '_blank', 'width=800,height=500,menubar=no,location=no')
  }

  typeFile (type) {
    this.file = type
  }

  submit (storeId) {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    if (this.file === 'pdf') {
      let data
      if (this.storesUser.length) {
        let storeIds = this.selectedStores.map(c => c.storeId).join(',')
        if (!storeIds.length) {
          storeIds = this.storesUser.map(c => c.storeId).join(',')
        }
        console.log(storeIds)
        data = {
          endDate: this.formGroup.value.endDate,
          startDate: this.formGroup.value.beginDate,
          storeId: storeIds,
        }
      } else {
        data = {
          endDate: this.formGroup.value.endDate,
          startDate: this.formGroup.value.beginDate,
          storeId: storeId,
        }
      }

      const query = queryString.stringify(data)

      this.reportService
        .storeSellsReport(query)
        .toPromise()
        .then(
          async response => {
            this.downloadFile(response)
          },
          error => {
            // o backend retorna um http status 302, e o browser automaticamente tenta redirecionar pra url do pdf
            // isso resulta nesse erro, pois está sendo enviado pro AWS todos os nossos headers, e entao ocorre um erro 400
            // aqui é verificado se a url do erro é a do aws, se sim ele simplesmente abre uma janela com a url, e funciona
            if (error.url && error.url.indexOf('amazonaws') != -1) {
              this.downloadFile(error.url)
            } else {
              this.snackBar.open(error.error.message)
            }
          },
        )
    }
    if (this.file === 'excel') {
      let filter = ''
      if (this.storesUser.length) {
        let storeIds = this.selectedStores.map(c => c.storeId)
        if (!storeIds.length) {
          storeIds = this.storesUser.map(c => c.storeId)
        }

        filter = `transactions?storesIds=${storeIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        filter = `transactions?storesIds=${storeId}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      }
      this.reportService
        .createReport(filter)
        .then(async response => {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.fileUrl,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return 'Transações.' + response.fileUrl.split('.').splice(5, 5).join('.')
            },
          })
        })
        .catch(error => {
          this.snackBar.open(error.message)
        })
    }
  }
}
