<app-logged class="store-list">
  <header class="store-list__header">
    <h2>GESTÃO DAS TRANSAÇÕES MANUAIS PENDENTES</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores" [queryParams]="params"
        skipLocationChange="true" routerLinkActive="router-link-active">VOLTAR</button>
      <button *ngIf="store" class="btn-primary-light" mat-button (click)="onAddTransactionClick()">
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter maskExample="000.000.000-00" [storeID]="storeID" actions="form" type="transactionsPending"
    [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)"></app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">{{ element.storeName }}</td>
      </ng-container>
      <ng-container matColumnDef="customerCpf">
        <th mat-header-cell *matHeaderCellDef>CPF do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerCpf | mask: '000.000.000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description != 'dynamic' ? element.description : 'QRcode' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }">
          {{ convertStatus(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Visualizar" (click)="view(element)" matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.id }}">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <!--<button *ngIf="element.canBeCanceled && !element.isManual" mat-icon-button matTooltip="Cancelar" (click)="openDialogCancelTransaction(element)" matTooltipPosition="left">
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button *ngIf="element.canBeCanceled && element.isManual && element.state ==  'PENDING'" mat-icon-button matTooltip="Cancelar" (click)="openDialogCancelTransaction(element)" matTooltipPosition="left">
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button *ngIf="element.isManual && element.state ==  'PENDING'" mat-icon-button matTooltip="Aprovar" (click)="openDialogConfirmation(element)" matTooltipPosition="left">
            <mat-icon class="approvedtransaction">add_task</mat-icon>
          </button>-->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter [storeID]="storeID" type="transactionsPending" actions="pagination" [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
