import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ClosingsService {
  public loading;

  constructor(private http: HttpClient) {}

  create(params): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/closings`, params)
      .pipe(
        tap((_) => this.log('/closings/create')),
        catchError(this.handleError)
      );
  }

  delete(params): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/closings/${params}`)
      .pipe(
        tap((_) => this.log('/closings/delete')),
        catchError(this.handleError)
      );
  }

  listAll(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/closings`)
      .pipe(
        tap((_) => this.log('/closings')),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    return throwError(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {}
}
