import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
interface DialogData {
  email: string
}

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styleUrls: ['./modal-user.component.scss'],
})
export class ModalUserComponent implements OnInit {
  public formGroup: FormGroup

  constructor(
    public dialogRef: MatDialogRef<ModalUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
  ) {}

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente o campo e tente novamente.')
      return false
    }

    this.dialogRef.close(this.formGroup.value.email)
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    })
  }
}
