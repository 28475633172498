import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-client-export-to-zoop',
  templateUrl: './client-export-to-zoop.html',
  styleUrls: ['./client-export-to-zoop.scss'],
})
export class ClientExportToZoopComponent implements OnInit {  
  exportRunning: boolean = false;
  exportData;
  interval;
  whatchRunning: boolean = false
  from: number = 0
  quantity : number = 4

  constructor (private readonly http: HttpClient) { }

  executeStartExport (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profile/start-export-to-zoop`, { params: { quantity: `${this.quantity}`, from: `${this.from}` }, headers: {noloading: 'true'} } ).toPromise()    
  }


  ngOnInit() {
  }

  async startExportClick(){

    if(this.exportRunning) return

    this.exportRunning = true
    try {
      await this.executeStartExport()
    } catch (error) {
      console.log("ERRO AO EXPORTAR", error)
    }
    this.exportRunning = false
  }


  getExportDataList (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profile/get-zoop-exported-data`, { headers: {noloading: 'true'} } ).toPromise()    
  }

  startWatchData(){
    this.exportRunning = true
    this.interval = setInterval(async () => {
      const res = await this.getExportDataList()
      this.exportData = res
    }, 3000)    
  }

  stopWatchData() {
    this.exportRunning = false
    clearInterval(this.interval)
  }

}