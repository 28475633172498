import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import numeral from 'numeral'

import { TransactionService } from '../../../../services/transaction.service'
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component'
import { ModalCancelTransactionComponent } from '../../../../components/modal-cancel-transaction/modal-cancel-transaction.component'
import { ModalCreateTransactionAnticipatedComponent } from '../../../../components/modal-create-transaction-anticipated/modal-create-transaction-anticipated.component'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { catchError } from 'rxjs/operators'

@Component({
  selector: 'app-store-payment-transactions-list',
  templateUrl: './store-payment-transactions-list.component.html',
  styleUrls: ['./store-payment-transactions-list.component.scss'],
})
export class StorePaymentTransactionsListComponent implements OnInit {
  public headersTable: string[]
  public headersTableReserve: string[]
  public paymentId = ''
  public storeID = ''
  public userRole
  dataSource: any
  dataSourceReserve: any
  listStatusDefault: any[] = []
  infoTransations: any[] = []
  amountPayment: number
  paymentDate: string
  fullName: string
  cpf: string
  descriptionPayment: string
  isMembershipActive: boolean
  isPayActive: boolean
  maximumInstallmentsTransaction: string
  totalValue: number
  reserveInCents: number
  state: string
  transactionType: string
  totalValueTransaction: number
  newValue: number
  maximumInstallments: number
  public params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] }

  constructor (
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    public transactionService: TransactionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.paymentId = this.route.snapshot.paramMap.get('paymentID')
    this.userRole = localStorage.getItem('userRole')
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
      this.params.formGroupValue = params.formGroupValue
      this.params.previousRoute = params.previousRoute
      this.params.storeID = params.storeID
      this.params.itComesFromNewTransaction = params.itComesFromNewTransaction
    })
    this.listInit().catch(err => console.log(err))
    this.headersTable = [
      'transactionOrderNumber',
      'description',
      'transactionDate',
      'totalValue',
      'state',
      'actions',
    ]
    this.headersTableReserve = [
      'nomeFantasia',
      'cpf',
      'nameCustomer',
      'descriptionTransaction',
      'paymentDate',
      'totalValueAnticipated',
      'totalValue',
      'state',
    ]

    this.transactionService.getStatus().subscribe(response => {
      const status = response
      const wateringConfrmation = { WateringConfrmation: 'AGUARDANDO CONFIRMAÇÕES' }
      this.listStatusDefault = Object.assign(status, wateringConfrmation)
    })
  }

  async listInit () {
    await this.transactionService.transactionsReserveFindOne(this.paymentId).then(
      async response => {
        response.results.map(i => {
          this.totalValue = i.totalValue
          this.reserveInCents = i.reserveInCents
          this.paymentDate = i.paymentDate
          this.fullName = i.fullName
          this.cpf = i.cpf
          this.maximumInstallmentsTransaction = i.maximumInstallmentsTransaction
          this.descriptionPayment = i.descriptionPayment
          this.storeID = i.storeId
          this.isMembershipActive = i.isMembershipActive
          this.isPayActive = i.isPayActive
        })
        this.dataSourceReserve = new MatTableDataSource(response.results)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    await this.transactionService.transactionsListByReserve(this.paymentId).then(
      async response => {
        let totalTransaction = 0
        response.results.map(i => {
          this.state = i.state
          this.transactionType = i.transactionType
          this.totalValueTransaction = i.totalValueTransaction
          this.maximumInstallments = i.maximumInstallments
          this.infoTransations.push(i)
          totalTransaction = numeral(totalTransaction).add(i.totalValueTransaction).value()
        })

        this.amountPayment = numeral(this.totalValue).subtract(totalTransaction).value()

        if (
          this.amountPayment > 0 &&
          this.state === 'APPROVED' &&
          this.transactionType === 'RESERVE'
        ) {
          this.infoTransations.push({
            transactionOrderNumber: '',
            description: this.descriptionPayment,
            transactionDate: this.paymentDate,
            totalValueTransaction: this.amountPayment,
            state: 'WateringConfrmation',
          })
        }

        this.dataSource = this.infoTransations
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  goBack () {
    if (this.params.itComesFromNewTransaction) {
      this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions', 'new'], { queryParams: this.params, skipLocationChange: true })
    } else {
      if (this.params.storeID) {
        this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions'], { queryParams: this.params, skipLocationChange: true })
      } else {
        this.router.navigate(['dashboard', 'transactions'], { queryParams: this.params, skipLocationChange: true })
      }
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }

  view (transaction) {
    if (transaction.isManual) {
      localStorage.setItem('isManual', 'true')
    } else {
      localStorage.setItem('isManual', 'false')
    }
  }

  convertStatusTransactions (transaction) {
    if (transaction.isManual && transaction.state == 'PENDING') {
      return 'AGUARDANDO APROVAÇÃO DA JHSF'
    } else {
      return this.listStatusDefault[transaction.state]
    }
  }

  detail (transacationId, fullName, customerCpf) {
    if (this.userRole === 'ADMIN') {
      this.transactionService
        .listTransactionsById(`${transacationId}?showManualDetails=true`)
        .toPromise()
        .then(
          response => {
            this.dialog.open(ModalCustomerInfoComponent, {
              data: {
                customerBalance: response.customerBalance,
                customerCpf: response.customerCpf,
                customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
                customerBirthDate: response.customerBirthDate,
                customerEmailAddress: response.customerEmailAddress,
                customerPhoneNumber: response.customerPhoneNumber,
                customerGender: response.customerGender,
                user: this.userRole,
              },
            })
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    } else {
      const dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
        data: {
          customerCpf: customerCpf,
          customerFullName: fullName,
          user: this.userRole,
        },
      })
    }
  }

  cancel (transactionID, reason) {
    const data = {
      reason: reason,
    }
    this.transactionService
      .cancelTransaction(transactionID, data)
      .toPromise()
      .then(
        response => {
          this.snackBar.open('Transação cancelada com sucesso.')
          window.location.reload()
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  openDialogCancelTransaction (transaction): void {
    const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.cancel(transaction.transactionId, result)
      }
    })
  }

  createTransaction (e) {
    const dialogRef = this.dialog.open(ModalCreateTransactionAnticipatedComponent, {
      width: '500px',
      data: {
        paymentDate: this.paymentDate,
        cpf: this.cpf,
        amountPayment: this.amountPayment,
        descriptionPayment: this.descriptionPayment,
        fullName: this.fullName,
      },
    })

    dialogRef
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result.createTransacation) {
          let totalValue = 0
          if (result.totalNewValue) {
            totalValue = result.totalNewValue
            this.newValue = numeral(this.totalValueTransaction).add(result.totalNewValue).value()
          } else {
            totalValue = this.amountPayment
          }

          const data = {
            customerCpf: this.cpf,
            totalValue: totalValue,
            description: this.descriptionPayment,
            maximumInstallments: parseInt(this.maximumInstallmentsTransaction),
            isMembershipActive: this.isMembershipActive,
            isPayActive: this.isPayActive,
            storeId: this.storeID,
            paymentIdTransacation: this.paymentId,
          }
          await this.transactionService
            .addTransaction(data)
            .toPromise()
            .then(
              async response => {
                if (response.isCreated) {
                  this.snackBar.open('Transação criada com sucesso.')
                  this.router.navigate(['./dashboard/transactions'])
                }
              },
              error => {
                this.snackBar.open(error.error.message)
              },
            )
          if (this.newValue) {
            await await this.transactionService
              .paymentAlter(this.paymentId, this.newValue)
              .then()
              .catch(error => this.snackBar.open(error.error.message))
          }
        }
      })
      .catch(err => console.log(err))
  }

  openDialogSendNotification (e) {
    const { transactionId, cpf, totalValueTransaction: totalValue } = e
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '400px',
      data: {
        text: 'Deseja reenviar essa transação para o cliente ?',
        buttonConfirmText: 'Sim',
      },
    })

    dialogRef
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result != 'cancel') {
          const data = {
            totalValue,
            customerCpf: cpf,
          }
          await this.transactionService.transactionSendEvent(transactionId, data).then(
            async response => {
              this.snackBar.open('Notificação enviada com sucesso.')
              this.router.navigate(['./dashboard/transactions'])
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(error => this.snackBar.open(error.error.message))
  }
}
