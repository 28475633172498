import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '../../../../services/uploadAdapter.service';
var CategoriesListComponent = /** @class */ (function () {
    function CategoriesListComponent(categoriesService, snackBar, location, dialog) {
        this.categoriesService = categoriesService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.headersTable = ['heading', 'order', 'status', 'actions'];
        this.categories = [];
        this.Editor = ClassicEditor;
    }
    CategoriesListComponent.prototype.ngOnInit = function () {
        this.listCategories();
        this.config = { isReadOnly: true };
    };
    CategoriesListComponent.prototype.listCategories = function () {
        var _this = this;
        this.categoriesService.listCategories().subscribe(function (response) {
            localStorage.setItem('categories', JSON.stringify(response.results));
            _this.categories = response.results;
            _this.titleCategory = response.titleCategory;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    CategoriesListComponent.prototype.viewCategories = function (categories) {
        localStorage.setItem('categoriesInfos', JSON.stringify(categories));
    };
    CategoriesListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Categoria?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.categoriesService
                            .deleteCategories(id)
                            .toPromise()
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listCategories();
                                this.snackBar.open('Categoria removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    CategoriesListComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    return CategoriesListComponent;
}());
export { CategoriesListComponent };
