<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <h4>{{data.title}}</h4>
          <div class="col-12 col-sm-12">
            <mat-card-content>
              <mat-form-field>
                <mat-label>{{data.reasonText}}</mat-label>
                <input matInput formControlName="reason" />
              </mat-form-field>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="submit" class="btn-submit-dark">{{data.buttonText}}</button>
      </div>
    </div>
  </form>
</main>
