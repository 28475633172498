var UploadAdapter = /** @class */ (function () {
    function UploadAdapter(loader) {
        this.loader = loader;
    }
    UploadAdapter.prototype.upload = function () {
        return this.loader.file
            .then(function (file) { return new Promise(function (resolve, reject) {
            var myReader = new FileReader();
            myReader.onloadend = function (e) {
                resolve({ default: myReader.result });
            };
            myReader.readAsDataURL(file);
        }); });
    };
    ;
    return UploadAdapter;
}());
export { UploadAdapter };
