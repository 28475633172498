import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-choose-transaction-type-modal',
  templateUrl: './modal-choose-transaction-type.component.html',
  styleUrls: ['./modal-choose-transaction-type.component.scss'],
})
export class ModalChooseTransactionTypeComponent implements OnInit {
  public formGroup: FormGroup

  constructor (
    public dialogRef: MatDialogRef<ModalChooseTransactionTypeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      futurePaymentEnabled: boolean
      manualPaymentEnabled: boolean
      isMembershipActive: boolean
    },
  ) {}

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  onNormalButtonClick () {
    this.dialogRef.close('normal')
  }

  onManualButtonClick () {
    this.dialogRef.close('manual')
  }

  onPaymentAnticipatedButtonClick () {
    this.dialogRef.close('paymentAnticipated')
  }

  ngOnInit () {}
}
