import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var ExclusiveFormComponent = /** @class */ (function () {
    function ExclusiveFormComponent(errorsService, formBuilder, categoriesService, authService, snackBar, router, route, _dialog, _cd, imageCompress) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.categoriesService = categoriesService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.invalidImage = false;
    }
    ExclusiveFormComponent.prototype.ngOnInit = function () {
        this.categoryID = this.route.snapshot.paramMap.get('categoryID');
        this.exclusiveID = this.route.snapshot.paramMap.get('exclusiveID');
        this.initForm();
    };
    ExclusiveFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            exclusiveIconUrl: [null, []],
            exclusiveLink: [null, [Validators.required]],
            description: [null, [Validators.required]],
            isActive: [null, []],
        });
        if (this.exclusiveID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    ExclusiveFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.categoriesService.exclusiveFindOne(this.exclusiveID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    description: response.description,
                                    exclusiveLink: response.exclusiveLink,
                                    isActive: response.isActive,
                                });
                                this.croppedImage = response.exclusiveIconUrl;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ExclusiveFormComponent.prototype.selectFile = function (event) {
        var _this = this;
        var originalImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedImage = result.img.dataURL;
                        this.imagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedImage)];
                    case 1:
                        _a.imageData = _b.sent();
                        this.invalidImage = !this.croppedImage;
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    ExclusiveFormComponent.prototype.editImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalImage) return [3 /*break*/, 1];
                        _a = this.originalImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.imagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedImage = result.img.dataURL;
                        this.imagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedImage)];
                    case 5:
                        _b.imageData = _c.sent();
                        this.invalidImage = !this.croppedImage;
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ExclusiveFormComponent.prototype.removeImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.readUrl = '';
                this.originalImage = undefined;
                this.croppedImage = undefined;
                this.imagePosition = undefined;
                this.imageData = undefined;
                return [2 /*return*/];
            });
        });
    };
    ExclusiveFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    ExclusiveFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.invalidImage = !this.croppedImage;
                        if (!this.formGroup.valid || !this.croppedImage) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedImage && this.imageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.categoriesService.exclusiveGetS3Url()];
                    case 1:
                        response = _a.sent();
                        this.readUrl = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.imageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        data = {
                            description: this.formGroup.value.description,
                            exclusiveIconUrl: this.readUrl,
                            exclusiveLink: this.formGroup.value.exclusiveLink,
                            isActive: !!this.formGroup.value.isActive,
                        };
                        if (this.exclusiveID) {
                            this.categoriesService.updateExclusive(this.exclusiveID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate([
                                                    './dashboard/categories/' + this.categoryID + '/exclusives',
                                                ])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Icone atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.categoriesService.addExclusive(this.categoryID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isCreated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate([
                                                    './dashboard/categories/' + this.categoryID + '/exclusives',
                                                ])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Icone criado com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return ExclusiveFormComponent;
}());
export { ExclusiveFormComponent };
