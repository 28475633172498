import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import numeral from 'numeral';
var AirportsStoresTaxCashbackFormComponent = /** @class */ (function () {
    function AirportsStoresTaxCashbackFormComponent(errorsService, snackBar, formBuilder, airportsService, route, router, reportService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.airportsService = airportsService;
        this.route = route;
        this.router = router;
        this.reportService = reportService;
        this.selectedStores = [];
        this.stores = [];
        this.storeControl = new FormControl();
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    AirportsStoresTaxCashbackFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get('id');
        this.reportService.listStores().subscribe(function (response) {
            response.map(function (item) {
                _this.stores.push(item);
                _this.filteredStores = _this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 3)); }));
            });
        }, function (error) {
            console.log(error);
        });
        this.formGroup = this.formBuilder.group({
            taxCashback: [null, [Validators.required]],
            storeId: [null, []],
        });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    AirportsStoresTaxCashbackFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneStoresTaxCashback(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.store = response.stores;
                                this.formGroup.patchValue({
                                    taxCashback: numeral(response.percentage).multiply(100).value(),
                                });
                                this.selectedStores.push(response.stores);
                                this.formGroup.get('storeId').disable();
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsStoresTaxCashbackFormComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 3);
    };
    AirportsStoresTaxCashbackFormComponent.prototype.selectedStore = function (event) {
        if (event.option.value.nomeFantasia && this.selectedStores.length < 1) {
            this.selectedStores.push(event.option.value);
            this.storeInput.nativeElement.value = '';
            this.storeControl.setValue(null);
        }
    };
    AirportsStoresTaxCashbackFormComponent.prototype.removeStore = function (value) {
        if (value.nomeFantasia) {
            var index = this.selectedStores.indexOf(value);
            if (index >= 0) {
                this.selectedStores.splice(index, 1);
            }
        }
    };
    AirportsStoresTaxCashbackFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        data = {
                            percentage: numeral(this.formGroup.value.taxCashback).divide(100).value(),
                            storeId: this.selectedStores.find(function () { return true; }).id,
                        };
                        if (!this.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.airportsService.updateStoresTaxCashback(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(['./dashboard/airports/tax-cashback'])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('taxa atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.airportsService.createStoresTaxCashback(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!response.isCreated) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.router.navigate(['./dashboard/airports/tax-cashback'])];
                                    case 1:
                                        _a.sent();
                                        this.snackBar.open('Taxa criada com sucesso.');
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AirportsStoresTaxCashbackFormComponent;
}());
export { AirportsStoresTaxCashbackFormComponent };
