import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var AirportInformationsListComponent = /** @class */ (function () {
    function AirportInformationsListComponent(airportsService, snackBar, location, dialog, router, route) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.router = router;
        this.route = route;
        this.headersTable = ['title', 'order', 'actions'];
        this.airport = [];
    }
    AirportInformationsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.airportID = this.route.snapshot.paramMap.get('airportID');
        this.airportsService.findOne(this.airportID).then(function (response) {
            _this.titleAirport = response.title;
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.listInformations();
    };
    AirportInformationsListComponent.prototype.listInformations = function () {
        var _this = this;
        this.airportsService.listInformations(this.airportID).then(function (response) {
            _this.airport = response;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    AirportInformationsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa informação?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.deleteInformation(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listInformations();
                                this.snackBar.open('Informação removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return AirportInformationsListComponent;
}());
export { AirportInformationsListComponent };
