<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Exportar parceiros para Zoop (Via Jhsfpay)</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/clients">VOLTAR</button>
    </div>
  </header>
  <div>
    <mat-form-field>
      <mat-label>Iniciar do índice</mat-label>
      <input matInput [(ngModel)]="from" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Quantidade para processar</mat-label>
      <input matInput [(ngModel)]="quantity"/>
    </mat-form-field>
  </div>
  <button class="btn-primary-light" mat-button (click)="startExportClick()">Iniciar exportação</button>

  <div>
    <br>
    <button class="btn-primary-light" mat-button (click)="exportRunning ? stopWatchData() : startWatchData()">
      {{exportRunning? "Parar" : "Pegar informações da migração"}}
    </button>
    &nbsp;<mat-icon *ngIf="exportRunning" class="spin">loop</mat-icon>
    
    <table  *ngIf="exportData && exportData.length > 0" class="tabela">
      <thead>
        <tr >
          <td>CNPJ</td>        
          <td>Importado com Sucesso</td>        
          <td>Conta bancária?</td>
          <td>Resposta Pay</td>
          <td>Data</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of exportData" >
          <td>{{item.cnpj}}</td>   
          <td>
            <mat-icon *ngIf="item.success" style="color:green">check_circle</mat-icon>
            <mat-icon *ngIf="!item.success" style="color:red">cancel</mat-icon>          
          </td>  
          <td>
            <mat-icon *ngIf="item.hasBankAccount" style="color:green">check_circle</mat-icon>
            <mat-icon *ngIf="!item.hasBankAccount" style="color:red">cancel</mat-icon>          
          </td>     
          <td width="20%" >
            <textarea>
              {{item.result}}
            </textarea>
          </td>
          <td>
            {{item.createdAt | date: 'dd/MM/yyyy hh:mm:ss'}}
          </td>
        </tr>
      <tbody>

    </table>
    
  </div>


</app-logged>
