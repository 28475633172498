<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content class="content">
    <mat-card>
      <div class="row">
        <h4>{{ data.text }}</h4>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="actions">
      <button *ngIf="data.buttonNot" mat-button (click)="not()" class="btn-submit-dark">
        {{ data.buttonNot ? data.buttonNot : 'NÃO' }}
      </button>
      <button mat-button (click)="confirm()" class="btn-submit-dark">
        {{ data.buttonConfirmText ? data.buttonConfirmText : 'Sim' }}
      </button>
    </div>
  </div>
</main>
