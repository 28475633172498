import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Quizzes } from '../models/quiz'
import { FormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (filter?): Promise<{ formGroup: FormGroup, results: Quizzes[], total: number }> {
    return this.http
      .get<{ formGroup: FormGroup, results: Quizzes[], total: number }>(
        `${environment.apiUrl}/cms/quizzes${filter || ''}`,
    )
      .toPromise()
  }

  async listCustomers (id, filter?): Promise<{ results: Quizzes[], total: number }> {
    return this.http
      .get<{ results: Quizzes[], total: number }>(
        `${environment.apiUrl}/cms/customers/${id}/quizzes${filter || ''}`,
    )
      .toPromise()
  }

  async listanswersAndQuestions (id, filter?): Promise<{ questions: Quizzes[], total: number }> {
    return this.http
      .get<{ questions: Quizzes[], total: number }>(
        `${environment.apiUrl}/cms/quizzes/${id}/answers/${filter || ''}`,
    )
      .toPromise()
  }

  async listQuizClient (id, filter?): Promise<{ results: Quizzes[], total: number }> {
    return this.http
      .get<{ results: Quizzes[], total: number }>(
        `${environment.apiUrl}/cms/quizzes/${id}/customers${filter || ''}`,
    )
      .toPromise()
  }

  async findOne (id: string): Promise<Quizzes> {
    return this.http.get<Quizzes>(`${environment.apiUrl}/cms/quizzes/${id}`).toPromise()
  }

  async create (data): Promise<Quizzes> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .post<Quizzes>(`${environment.apiUrl}/cms/quizzes`, formData, { headers })
      .toPromise()
  }

  async update (id: string, data): Promise<Quizzes[]> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .put<Quizzes[]>(`${environment.apiUrl}/cms/quizzes/${id}`, formData, { headers })
      .toPromise()
  }

  async delete (quizID: string): Promise<Quizzes> {
    return this.http.delete<Quizzes>(`${environment.apiUrl}/cms/quizzes/${quizID}`).toPromise()
  }

  async getS3Url (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/events/s3-slot`).toPromise()
  }

  async uploadToS3 (pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }

  async createTrigger (data): Promise<void> {
    return this.http.post<any>(`${environment.apiUrl}/cms/trigger`, data).toPromise()
  }

  async getTrigger (): Promise<Quizzes[]> {
    return this.http.get<Quizzes[]>(`${environment.apiUrl}/cms/trigger`).toPromise()
  }
}
