<main class="modal">
  <a class="link-cancel" mat-icon-button matTooltip="Cancelar" (click)="cancel()" matTooltipPosition="left">
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <h3><strong>AVISO</strong></h3>
      <div class="row" *ngFor="let macro of data.results">
        <p *ngIf="macro.participatingCampaignWithMacroGroup.macroGroups.id"><strong>{{
            macro.participatingCampaignWithMacroGroup.macroGroups.macroName}}</strong> está
          vinculado
          a campanha <strong>{{ macro.participatingCampaignWithMacroGroup.campaigns.description}}</strong>
        </p>
        <p><strong>{{ macro.replaceCampaign.macroGroups.macroName}}</strong> está vinculado
          a campanha <strong>{{ macro.replaceCampaign.campaigns.description}}</strong>
        </p>
      </div>
      <div class="row">
        <h4>Para alterar o vinculo do parceiro para essa nova campanha, efetue a alteração diretamente na página de
          edição da campanha desejada.</h4>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="btn-submit-dark">
      <button (click)="confirm()" mat-button class="btn-submit-dark">Fechar</button>
    </div>
  </div>
</main>
