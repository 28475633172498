import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Fund } from '../models/fund'
import { environment } from '../../environments/environment'
import { FormGroup } from '@angular/forms'

@Injectable({ providedIn: 'root' })
export class FundService {
  constructor (private readonly http: HttpClient) { }

  async list (filter?): Promise<{ formGroup: FormGroup, results: Fund[], total: number }> {
    return this.http
      .get<{ formGroup: FormGroup, results: Fund[], total: number }>(`${environment.apiUrl}/cms/funds${filter || ''}`)
      .toPromise()
  }

  async find (fundId: string): Promise<Fund> {
    return this.http.get<Fund>(`${environment.apiUrl}/cms/funds/${fundId}`).toPromise()
  }

  async create (fund: Partial<Fund>): Promise<{ success: boolean, id: string }> {
    return this.http
      .post<{ success: boolean, id: string }>(`${environment.apiUrl}/cms/funds`, fund)
      .toPromise()
  }

  async edit (fundId: string, fund: Partial<Fund>): Promise<{ success: boolean, id: string }> {
    return this.http
      .put<{ success: boolean, id: string }>(`${environment.apiUrl}/cms/funds/${fundId}`, fund)
      .toPromise()
  }

  async delete (fundId: string): Promise<{ success: boolean, id: string }> {
    return this.http
      .delete<{ success: boolean, id: string }>(`${environment.apiUrl}/cms/funds/${fundId}`)
      .toPromise()
  }

  async getExtract (fundId: string, filter?): Promise<any> {
    return this.http
      .get<Fund[]>(`${environment.apiUrl}/cms/funds/${fundId}/extract${filter || ''}`)
      .toPromise()
  }

  async getTransactions (fundId: string, filter?): Promise<any> {
    return this.http
      .get<Fund[]>(`${environment.apiUrl}/cms/funds/${fundId}/transactions${filter || ''}`)
      .toPromise()
  }
}
