import { PipeTransform } from '@angular/core';
var CampaignFilterPipe = /** @class */ (function () {
    function CampaignFilterPipe() {
    }
    CampaignFilterPipe.prototype.transform = function (items, searchText) {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLocaleLowerCase();
        return items.filter(function (it) {
            return it.nomeFantasia.toLocaleLowerCase().includes(searchText);
        });
    };
    return CampaignFilterPipe;
}());
export { CampaignFilterPipe };
