import * as tslib_1 from "tslib";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
    }
    ConfigService.prototype.view = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/settings").pipe(tap(function (_) { return _this.log('cms/settings/view'); }), catchError(this.handleError));
    };
    ConfigService.prototype.update = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/settings", data).toPromise()];
            });
        });
    };
    ConfigService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    ConfigService.prototype.log = function (message) { };
    ConfigService.prototype.resetAccessTokens = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/reset-access-tokens", {}).toPromise()];
            });
        });
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
