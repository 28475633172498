import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalCustomerCpfComponent = /** @class */ (function () {
    function ModalCustomerCpfComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ModalCustomerCpfComponent.prototype.ngOnInit = function () { };
    ModalCustomerCpfComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    return ModalCustomerCpfComponent;
}());
export { ModalCustomerCpfComponent };
