<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; height: calc(80vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h5>Relatório de balanço J-Coins</h5>
              <div class="col-12 col-sm-12">
                <div *ngIf="!allCpf">
                  <mat-form-field class="customer-autocomplete">
                    <mat-label>CPF ou Nome do Cliente</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip
                        *ngFor="let customer of selectedCustomers"
                        [selectable]="false"
                        [removable]="true"
                        (removed)="removeCustomer(customer)"
                      >
                        {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input
                        matInput
                        #customerInput
                        [formControl]="customerControl"
                        formControlName="customersInput"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      />
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option
                        *ngFor="let customer of filteredCustomers | async"
                        [value]="customer"
                      >
                        {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="customerInvalid"
                    >Campo deve ser preenchido</mat-error
                  >
                </div>
                <div class="check">
                  <input type="checkbox" (change)="checkAllCpf()" />
                  <mat-label> &nbsp;Todos CPF</mat-label>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
