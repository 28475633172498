import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { Location } from '@angular/common'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { PreRegistrationService } from '../../../../../services/preRegistration.service'
import { ZipCodeService } from '../../../../../core/services/zip-code.service'

@Component({
  selector: 'app-pre-registration-address-form',
  templateUrl: './pre-registration-address-form.component.html',
  styleUrls: ['./pre-registration-address-form.component.scss'],
})
export class preRegistrationAddressFormComponent implements OnInit {
  public formGroup: FormGroup
  private readonly initalized = false
  public customerID: string
  public preRegistrationID: string
  public addressID: string

  constructor (
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    private readonly route: ActivatedRoute,
    public preRegistrationService: PreRegistrationService,
    private readonly zipCodeService: ZipCodeService,
    public snackBar: MatSnackBar,
    public location: Location,
  ) {}

  ngOnInit () {
    this.addressID = this.route.snapshot.paramMap.get('addressID')
    this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID')
    this.formGroup = this.formBuilder.group({
      addressTitle: [null, [Validators.required]],
      street: [null, []],
      addressNumber: [null, []],
      complement: [null, []],
      zipCode: [null, []],
      neighborhood: [null, []],
      city: [null, []],
      state: [null, []],
      country: [null, []],
      kind: [null, []],
    })

    if (this.addressID) {
      this.addressFindOne()
    }
  }

  addressFindOne () {
    this.preRegistrationService.addressFindOne(this.addressID).then(
      async response => {
        this.formGroup.patchValue({
          addressTitle: response.addressTitle,
          street: response.street,
          addressNumber: response.addressNumber,
          complement: response.complement,
          zipCode: response.zipCode,
          neighborhood: response.neighborhood,
          city: response.city,
          state: response.state,
          country: response.country,
          kind: response.kind,
        })
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  getAddress () {
    if (this.formGroup.value.zipCode) {
      this.zipCodeService.getAddress(this.formGroup.value.zipCode).subscribe((address: any) => {
        this.formGroup.patchValue({
          state: address.uf,
          city: address.localidade,
          neighborhood: address.bairro,
          street: address.logradouro,
        })
      })
    }
  }

  submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      addressTitle: this.formGroup.value.addressTitle,
      street: this.formGroup.value.street,
      addressNumber: this.formGroup.value.addressNumber,
      complement: this.formGroup.value.complement ? this.formGroup.value.complement : '',
      zipCode: this.formGroup.value.zipCode,
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      country: this.formGroup.value.country,
      customerId: this.customerID,
      kind: this.formGroup.value.kind ? this.formGroup.value.kind : undefined,
    }

    if (this.addressID) {
      this.preRegistrationService.updateAddress(this.addressID, data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Endereço cadastrado com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.preRegistrationService.createAddress(data, this.preRegistrationID).then(
        async response => {
          this.location.back()
          this.snackBar.open('Endereço cadastrado com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
