import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import moment from "moment";
import { ModalLateArchitectInvoices } from "src/app/components/modal-late-architect-invoices/modal-late-architect-invoices.component";
var ArchitectClosingForm = /** @class */ (function () {
    function ArchitectClosingForm(formBuilder, location, snackBar, architectClosingsService, errorsService, dialog) {
        this.formBuilder = formBuilder;
        this.location = location;
        this.snackBar = snackBar;
        this.architectClosingsService = architectClosingsService;
        this.errorsService = errorsService;
        this.dialog = dialog;
        this.disableSubmit = false;
        this.initForm();
    }
    ArchitectClosingForm.prototype.ngOnInit = function () {
    };
    ArchitectClosingForm.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });
    };
    ArchitectClosingForm.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectClosingForm.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, lateInvoices, modalResult, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        data = {
                            startDate: this.formGroup.value.startDate,
                            endDate: this.formGroup.value.endDate,
                        };
                        if (moment(data.startDate).isAfter(moment(data.endDate))) {
                            this.snackBar.open('A data inicial é posterior a data final');
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        this.disableSubmit = true;
                        return [4 /*yield*/, this.architectClosingsService.getLateInvoicesForClosing(data.startDate)];
                    case 2:
                        lateInvoices = _a.sent();
                        if (!(lateInvoices.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.openLateInvoiceSelectionModal(lateInvoices)];
                    case 3:
                        modalResult = _a.sent();
                        if (!modalResult || modalResult == 'cancel')
                            return [2 /*return*/];
                        data.lateInvoiceIds = modalResult;
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.architectClosingsService.createClosing(data)];
                    case 5:
                        response = _a.sent();
                        if (response) {
                            this.snackBar.open('Fechamento criado com sucesso.');
                            this.location.back();
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 8];
                    case 7:
                        this.disableSubmit = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingForm.prototype.openLateInvoiceSelectionModal = function (lateInvoices) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.dialog
                                .open(ModalLateArchitectInvoices, {
                                width: '800px',
                                data: lateInvoices,
                            })
                                .afterClosed()
                                .toPromise()];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                    case 2:
                        err_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ArchitectClosingForm;
}());
export { ArchitectClosingForm };
