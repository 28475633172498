<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Segmentações de Clientes</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/client-kind/new">
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="kinds">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Segmentação</th>
        <td mat-cell *matCellDef="let element">{{ element.kindName }}</td>
      </ng-container>
      <ng-container matColumnDef="isDefault">
        <th mat-header-cell *matHeaderCellDef>Default</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isDefault" matTooltip="Ativo">check_circle_outline</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/client-kind/{{ element.id }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
