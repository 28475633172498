<div class="row" *ngIf="logs">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table mat-table [dataSource]="logs">
                   
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element">
              <strong>{{ element.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}</strong>
              <!-- <strong>{{ element.createdAt | date: 'dd/MM/yyyy' }}</strong> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef>Operação</th>
            <td mat-cell *matCellDef="let element">
              {{ element.operation  }}
            </td>
          </ng-container>

          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Resultado</th>
            <td mat-cell *matCellDef="let element">
              {{ getResult(element.responsePayload)  }}
            </td>
          </ng-container>
         

          <ng-container matColumnDef="requestPayload">
            <th mat-header-cell *matHeaderCellDef>Dados Enviados</th>
            <td mat-cell *matCellDef="let element">
              
              <textarea readonly rows="2" cols="20">
                {{element.requestPayload}}
              </textarea>
            </td>
          </ng-container>

          <ng-container matColumnDef="responsePayload">
            <th mat-header-cell *matHeaderCellDef>Dados Recebidos</th>
            <td mat-cell *matCellDef="let element">
              <textarea readonly rows="2" cols="20">
                {{element.responsePayload}}
              </textarea>
            </td>
          </ng-container>

          <ng-container matColumnDef="success">
            <th mat-header-cell *matHeaderCellDef>Sucesso</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.success" style="color:green">check_circle</mat-icon>
              <mat-icon *ngIf="!element.success" style="color:red">cancel</mat-icon> 
            </td>
          </ng-container>
          

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>

      </div>
    </div>
</div>