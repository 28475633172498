import { Component, OnInit, ViewChild } from '@angular/core'
import { Transaction } from 'src/app/models/transaction'
import { TransactionService } from 'src/app/services/transaction.service'
import { ActivatedRoute, Router } from '@angular/router'
import { CurrencyPipe, Location } from '@angular/common'
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component'
import { PageEvent } from '@angular/material/paginator'

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatTableDataSource,
  MatPaginator,
  MatSort,
} from '@angular/material'
import { StoreService } from 'src/app/services/store.service'
import { Store } from 'src/app/models/store'
import { ModalChooseTransactionTypeComponent } from 'src/app/components/modal-choose-transaction-type/modal-choose-transaction-type.component'
import { ModalConfirmationComponent } from 'src/app/components/modal-confirmation/modal-confirmation.component'
import { ModalRejectComponent } from 'src/app/components/modal-reject/modal-reject.component'
import { UtilService } from 'src/app/services/util.service'
@Component({
  selector: 'app-store-list',
  templateUrl: './store-transaction-pending-list.component.html',
  styleUrls: ['./store-transaction-pending-list.component.scss'],
})
export class StoreTransactionPendingListComponent implements OnInit {
  public headersTable: string[] = [
    'orderNumber',
    'storeName',
    'customerCpf',
    'description',
    'createdAt',
    'totalValue',
    'state',
    'actions',
  ]

  public store: Store
  public storeID = ''
  fieldsForm: any
  dataSource: any
  public params = { storeFormGroupValue: [] }

  public pageTotal: number

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  pageEvent: PageEvent

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor (
    public transactionService: TransactionService,
    public storeService: StoreService,
    private readonly route: ActivatedRoute,
    public router: Router,
    public currencyPipe: CurrencyPipe,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    public utilService: UtilService,
  ) { }

  ngOnInit () {
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.listTransactions()
    this.storeService.getStore(this.storeID).subscribe(async response => {
      this.store = response
    })
    this.initForm()
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'number',
        formControlName: 'numberTransactionPending',
        label: 'Número da Transação',
        placeholder: 'Número da Transação',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfTransactionPending',
        label: 'CPF do Cliente',
        placeholder: 'CPF do Cliente',
      },
      {
        field: 'input',
        type: 'date',
        formControlName: 'dateTransactionPending',
      },
    ]
  }

  listTransactions () {
    if (this.storeID != '') {
      // ?state=PENDING&rewardsState=FUTURE
      this.transactionService
        .listTransactions(
          `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}, {"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
        )
        .subscribe(
          async response => {
            response.results.map(item => {
              item.createdAt = item.createdAt.slice(0, 10)
              delete item.updatedAt
              delete item.paidAt
            })
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(response.results)
          },
          error => {
            console.log(error)
            alert(error.error.message)
          },
        )
    } else {
      this.transactionService
        // .listTransactions(
        //   '?state=PENDING&rewardsState=FUTURE',
        // )
        .listTransactions(
          `?filters={""page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
        )
        .subscribe(
          async response => {
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(response.results)
          },
          error => {
            console.log(error)
            alert(error.error.message)
          },
        )
    }
  }

  view (transaction) {
    if (transaction.isManual) {
      localStorage.setItem('isManual', 'true')
    } else {
      localStorage.setItem('isManual', 'false')
    }
  }

  openDialogCancelTransaction (transaction) {
    if (!transaction.isManual) {
      const balance = transaction.manualDetails.balance
      const pointsApplied = Math.trunc(
        transaction.totalValue / transaction.pointsConversionFactorOut,
      )
      if (balance < pointsApplied) {
        this.snackBar.open('Saldo insuficiente.')
        return false
      }

      const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
        width: '500px',
        data: {
          transaction: transaction,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'cancel') {
          if (!transaction.isManual) {
            this.cancel(transaction.id, result)
          } else {
            this.rejectTransactionManual(transaction.id, result)
          }
        }
      })
    } else {
      const dialogRef = this.dialog.open(ModalRejectComponent, {
        width: '500px',
        data: {
          transaction: transaction,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'cancel') {
          this.rejectTransactionManual(transaction.id, result)
        }
      })
    }
  }

  openDialogConfirmation (transaction): void {
    if (transaction.isManual) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '500px',
        data: {
          transaction: transaction,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'cancel') {
          this.approvedTransactionManual(transaction.id)
        }
      })
    }
  }

  onAddTransactionClick () {
    if (this.store.manualPaymentEnabled) {
      // this.openDialogTransactionType()
      this.goToManualTransaction()
    } else {
      this.goToNormalTransaction()
    }
  }

  openDialogTransactionType () {
    const dialogRef = this.dialog.open(ModalChooseTransactionTypeComponent, {
      width: '500px',
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'normal') {
        this.goToNormalTransaction()
      } else if (result == 'manual') {
        this.goToManualTransaction()
      }
    })
  }

  goToNormalTransaction () {
    this.router.navigateByUrl(`/dashboard/stores/${this.storeID}/transactions/new`)
  }

  goToManualTransaction () {
    this.router.navigateByUrl(`/dashboard/stores/${this.storeID}/transactions/new-manual`)
  }

  cancel (transactionID, reason) {
    const data = {
      reason: reason,
    }
    this.transactionService.cancelTransaction(transactionID, data).subscribe(
      async response => {
        this.snackBar.open('Transação cancelada com sucesso.')
        this.listTransactions()
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  rejectTransactionManual (transactionID, reason) {
    const data = {
      nextState: 'REJECTED',
      reason,
    }
    this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(
      async response => {
        this.snackBar.open('Transação rejeitada com sucesso.')
        this.listTransactions()
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  approvedTransactionManual (transactionID) {
    const data = {
      nextState: 'APPROVED',
    }
    this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(
      async response => {
        this.snackBar.open('Transação aprovada com sucesso.')
        this.listTransactions()
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  convertStatus (transaction) {
    switch (transaction.state) {
      case 'PENDING':
        return transaction.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente'
        break
      case 'APPROVED':
        return 'Aprovado'
        break
      case 'REJECTED':
        return 'Rejeitado'
        break
      case 'CANCELED':
        return 'Cancelado'
        break
      default:
        return ''
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
