<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Cadastro de Arquiteto</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-companies">
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="company" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>EMPRESA: </b>{{ company.companyName }}</div>
      <div><b>CNPJ: </b>{{ company.cnpj | mask: '00.000.000/0000-00' }}</div>
    </mat-card>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="store-form__actions">
        <button class="btn-primary-light" (click)="addCpf($event)" mat-button>ADICIONAR</button>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <div>
              <mat-form-field matTooltipPosition="left">
                <mat-label>
                  Arquiteto &nbsp;
                  <mat-icon class="spin" *ngIf="loadingArchitects" style="position: absolute"
                    >loop</mat-icon
                  >
                </mat-label>

                <mat-chip-list #chipList>
                  <mat-chip
                    *ngIf="formGroup.value.cpf"
                    [selectable]="false"
                    [removable]="true"                 
                    (removed)="removeSelectedArchitect()"  
                  >
                    {{ formGroup.value.fullName }} {{ formGroup.value.cpf | mask: '000.000.000-00' }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    type="text"
                    [placeholder]="!this.formGroup.value.cpf ? 'Digite o CPF ou Nome do arquiteto para pesquisar' : ''"
                    matInput      
                    #customerInput1          
                    [disabled]="!!this.formGroup.value.cpf"    
                    [formControl]="customerControl1"
                    [matAutocomplete]="auto1"
                    [matChipInputFor]="chipList"                    
                  />
                </mat-chip-list>
               

                <mat-autocomplete #auto1="matAutocomplete">
                  <mat-option
                    *ngFor="let target of targets$ | async"
                    (blur)="onArchitectChange(target)"
                    [value]="target"
                  >
                    {{ getArchitectDisplayNames(target) }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="formGroup.controls.targetDocument?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.targetDocument) }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  formControlName="percentage"
                  placeholder="Porcentagem"
                  mask="separator.4"
                  decimalMarker=","
                  suffix="%"
                />
              </mat-form-field>

              <p style="color: red" *ngIf="tempPercentageCalc !== 0 && tempPercentageCalc < 0">
                Máximo 100%
              </p>
              <p style="color: red" *ngIf="tempPercentageCalc > 0 && tempPercentageCalc < 100">
                Restam: {{ tempPercentageCalc }}%
              </p>
              <p style="color: green" *ngIf="tempPercentageCalc === 0 && selectedData.length > 0">
                Total: 100% <mat-icon matTooltip="Ok">check_circle_outline </mat-icon>
              </p>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="table-responsive" *ngIf="selectedData.length">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="cpf">
            <th mat-header-cell *matHeaderCellDef>CPF</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cpf | mask: '000.000.000-00' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">
              {{ element.fullName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="comissionFee">
            <th mat-header-cell *matHeaderCellDef>Porcentagem %</th>
            <td mat-cell *matCellDef="let element">{{ element.comissionFee }} %</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Remover"
                matTooltipPosition="left"
                (click)="remove(element)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <div class="store-form__actions">
            <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
          </div>
          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
      </div>
      <div class="store-form__actions" *ngIf="totalPercentage === 0 && selectedData.length > 0">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
