<app-logged class="funds-list">
  <header class="funds-list__header">
    <h2>Transações do Fundo</h2>
    <div class="funds-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/funds" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card"><b>Fundo:</b> {{ fund.name }}</mat-card>
  </div>
  <br />
  <app-form-filter maskExample="000.000.000-00" [fundId]="fundId" actions="form" type="fundTransaction"
    [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">{{ element.storeName }}</td>
      </ng-container>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Nome do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customerCpf">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" matTooltip="CPF: {{ element.customerCpf | mask: '000.000.000-00' }}">
          <button mat-icon-button (click)="detail(element.id, element.fullName, element.customerCpf)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description != 'dynamic' ? element.description : 'QRcode' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy':'-300' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalValue / 100 | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }">
          {{ convertStatusTransactions(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.canBeCanceled" mat-icon-button matTooltip="Cancelar"
            (click)="openDialogCancelTransaction(element)" matTooltipPosition="left">
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Visualizar" (click)="view(element)" matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.id }}">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter [fundId]="fundId" type="fundTransaction" actions="pagination" [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
