import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
var FundAssociatesFormComponent = /** @class */ (function () {
    function FundAssociatesFormComponent(errorsService, formBuilder, customersAssociatesService, snackBar, location, route) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.customersAssociatesService = customersAssociatesService;
        this.snackBar = snackBar;
        this.location = location;
        this.route = route;
        this.fundId = '';
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        this.customers = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.selectedCustomersInvalid = false;
    }
    FundAssociatesFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fundId = this.route.snapshot.paramMap.get('fundId');
        this.customersAssociatesService.listCustomers(this.fundId).then(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        this.initForm();
    };
    FundAssociatesFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            customersInput: [null, [Validators.required]],
        });
    };
    FundAssociatesFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.selectedCustomers.length) {
                    this.selectedCustomersInvalid = true;
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.customersAssociatesService
                    .add(this.fundId, this.selectedCustomers.map(function (c) { return c.customerId; }))
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.success) {
                            this.snackBar.open('Usuário associado com sucesso.');
                            this.location.back();
                        }
                        else {
                            this.snackBar.open('Falha ao associar o usuário.');
                        }
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (err) {
                    _this.snackBar.open(err.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    FundAssociatesFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    FundAssociatesFormComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    FundAssociatesFormComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
        this.selectedCustomersInvalid = true;
    };
    FundAssociatesFormComponent.prototype.selected = function (event) {
        this.selectedCustomers.push(event.option.value);
        this.customerInput.nativeElement.value = '';
        this.customerControl.setValue(null);
        if (this.selectedCustomers)
            this.selectedCustomersInvalid = false;
    };
    return FundAssociatesFormComponent;
}());
export { FundAssociatesFormComponent };
