import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReceivablesService } from 'src/app/services/receivables.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { PrepaymentsService } from 'src/app/services/prepayments.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './receivables-list.component.html',
  styleUrls: ['./receivables-list.component.scss'],
})
export class ReceivablesListComponent implements OnInit {
  public headersTable: string[] = ['input', 'date', 'amount'];
  public receivables: Array<any> = [];
  public receivablesResponse: {} = {};
  public receivablesSelecteds: Array<any> = [];
  public formFilter: FormGroup;
  public form: FormGroup;
  private receivablesDate: {} = {};

  constructor(
    public receivablesService: ReceivablesService,
    public prepaymentsService: PrepaymentsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      finalDate: [null, [Validators.required]],
    });

    this.form = this.formBuilder.group({
      paymentDate: [null, [Validators.required]],
      receivables: [null, [Validators.required]],
    });
  }

  listAll(dateStart: string, dateEnd: string) {
    this.receivables = [];

    this.receivablesService.listAll({ dateStart, dateEnd }).subscribe(
      async (response) => {
        this.receivablesResponse = response;

        Object.entries(response).forEach(([key]) => {
          const data = {
            date: key,
            data: response[key],
          };

          this.receivables.push(data);
        });
      },
      (error) => {
        console.log(error);
        alert(error.error.message);
      }
    );
  }

  clickReceivableDate(date: string) {
    if (date in this.receivablesDate) {
      delete this.receivablesDate[date]
    } else {
      this.receivablesDate[date] = true;
    }
  }

  totalPrepayments() {
    return Object.keys(this.receivablesDate).map((key) => {
      return this.receivablesResponse[key].total / 100;
    }).reduce((a, b) => a + b, 0);
  }

  async submitFilter() {
    if (!this.formFilter.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.listAll(
      this.formFilter.value.startDate,
      this.formFilter.value.finalDate
    );
  }

  async submit() {
    let receivables = Object.keys(this.receivablesDate).map((key) => {
      return this.receivablesResponse[key].items.map((item: any) => item.id);
    });

    if (receivables.length > 0) {
      receivables = receivables.reduce((prev, current) => {
        return prev.concat(current);
      });
    }

    this.form.patchValue({ receivables });

    if (!this.form.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.prepaymentsService.postPrepayment(this.form.value).subscribe(
      async (response) => {
        if (response.isCreated) {
          this.snackBar.open('Antecipação gerada com sucesso.');
          this.router.navigate(['dashboard', 'prepayments']);
        }
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }

  convertCentsToReal(value) {
    return value / 100;
  }
}
