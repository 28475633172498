import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from '../../core/services/errors.service'
import { StoreService } from 'src/app/services/store.service'

@Component({
  selector: 'app-modal-form-charge-back',
  templateUrl: './modal-form-charge-back.component.html',
  styleUrls: ['./modal-form-charge-back.component.scss'],
})
export class ModalFormChargeBackComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public file: string
  public storesUser: any[] = []
  filteredStores: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public storeSelected: any
  stores: any[] = []
  public selectedStores: any[] = []

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    private readonly storeService: StoreService,
    public dialogRef: MatDialogRef<ModalFormChargeBackComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      jcoinValue: number
      partnerName: string
      transactionDate: string
      walletId: string
    },
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      description: [null, [Validators.required]],
    })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    this.storeService.performChargeBack(
      {
        walletId: this.data.walletId,
        description: this.formGroup.controls.description.value,
      })
      .subscribe((response) => {
        this.dialogRef.close(response)
      }, (error) => {
        console.log(error)
        this.dialogRef.close(error)
      })
  }
}
