import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import * as queryString from 'query-string';
import JsFileDownloader from 'js-file-downloader';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
var ModalFormTransactionsComponent = /** @class */ (function () {
    function ModalFormTransactionsComponent(errorsService, snackBar, formBuilder, reportService, dialogRef, data) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.storeControl = new FormControl();
        this.storesUser = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.stores = [];
        this.selectedStores = [];
    }
    ModalFormTransactionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storesUser = JSON.parse(localStorage.getItem('storesUser'));
        if (this.storesUser.length > 1) {
            this.formGroup = this.formBuilder.group({
                beginDate: [null, [Validators.required]],
                endDate: [null, [Validators.required]],
                storesInput: [null, []],
            });
            this.filteredStores = this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.storesUser.slice(0, 20)); }));
        }
        else {
            this.formGroup = this.formBuilder.group({
                beginDate: [null, [Validators.required]],
                endDate: [null, [Validators.required]],
            });
        }
    };
    ModalFormTransactionsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormTransactionsComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.storesUser
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.storeName)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormTransactionsComponent.prototype.remove = function (value) {
        var index = this.selectedStores.indexOf(value);
        if (index >= 0) {
            this.selectedStores.splice(index, 1);
        }
    };
    ModalFormTransactionsComponent.prototype.selected = function (event) {
        this.selectedStores.push(event.option.value);
        this.storeInput.nativeElement.value = '';
        this.storeControl.setValue(null);
    };
    ModalFormTransactionsComponent.prototype.downloadFile = function (url) {
        window.open(url, '_blank', 'width=800,height=500,menubar=no,location=no');
    };
    ModalFormTransactionsComponent.prototype.typeFile = function (type) {
        this.file = type;
    };
    ModalFormTransactionsComponent.prototype.submit = function (storeId) {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        if (this.file === 'pdf') {
            var data = void 0;
            if (this.storesUser.length) {
                var storeIds = this.selectedStores.map(function (c) { return c.storeId; }).join(',');
                if (!storeIds.length) {
                    storeIds = this.storesUser.map(function (c) { return c.storeId; }).join(',');
                }
                console.log(storeIds);
                data = {
                    endDate: this.formGroup.value.endDate,
                    startDate: this.formGroup.value.beginDate,
                    storeId: storeIds,
                };
            }
            else {
                data = {
                    endDate: this.formGroup.value.endDate,
                    startDate: this.formGroup.value.beginDate,
                    storeId: storeId,
                };
            }
            var query = queryString.stringify(data);
            this.reportService
                .storeSellsReport(query)
                .toPromise()
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.downloadFile(response);
                    return [2 /*return*/];
                });
            }); }, function (error) {
                // o backend retorna um http status 302, e o browser automaticamente tenta redirecionar pra url do pdf
                // isso resulta nesse erro, pois está sendo enviado pro AWS todos os nossos headers, e entao ocorre um erro 400
                // aqui é verificado se a url do erro é a do aws, se sim ele simplesmente abre uma janela com a url, e funciona
                if (error.url && error.url.indexOf('amazonaws') != -1) {
                    _this.downloadFile(error.url);
                }
                else {
                    _this.snackBar.open(error.error.message);
                }
            });
        }
        if (this.file === 'excel') {
            var filter = '';
            if (this.storesUser.length) {
                var storeIds = this.selectedStores.map(function (c) { return c.storeId; });
                if (!storeIds.length) {
                    storeIds = this.storesUser.map(function (c) { return c.storeId; });
                }
                filter = "transactions?storesIds=" + storeIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                filter = "transactions?storesIds=" + storeId + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            this.reportService
                .createReport(filter)
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Transações.' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                    return [2 /*return*/];
                });
            }); })
                .catch(function (error) {
                _this.snackBar.open(error.message);
            });
        }
    };
    return ModalFormTransactionsComponent;
}());
export { ModalFormTransactionsComponent };
