<app-logged class="store-list">
  <div *ngIf="hideFile">
    <header class="store-list__header">
      <h2>IMPORTAR CLIENTES</h2>
      <div class="store-list__header__actions"></div>
    </header>

    <div style="margin-bottom: 0.5rem">
      <input type="file" (change)="onFileChange($event)" id="file" #inputFile />
      <button
        *ngIf="dataSource"
        class="btn-primary-light"
        mat-button
        (click)="clear()"
        style="margin-right: 0.5rem"
      >
        Remover
      </button>
      <button *ngIf="dataSource" class="btn-primary-light" mat-button (click)="saveFile()">
        Salvar
      </button>
    </div>
  </div>

  <div *ngIf="hideFilePreRegister">
    <header class="store-list__header">
      <h2>IMPORTAR CLIENTES CNPJ (AEROPORTO)</h2>
      <div class="store-list__header__actions"></div>
    </header>

    <div style="margin-bottom: 0.5rem">
      <input
        type="file"
        (change)="onFileChangePreRegister($event)"
        id="file"
        #inputFilePreRegister
      />
      <button
        *ngIf="dataSourcePreRegister"
        class="btn-primary-light"
        mat-button
        (click)="clearPreRegister()"
        style="margin-right: 0.5rem"
      >
        Remover
      </button>
      <button
        *ngIf="dataSourcePreRegister"
        class="btn-primary-light"
        mat-button
        (click)="saveFilePreRegister()"
      >
        Salvar
      </button>
    </div>
  </div>

  <div class="table-responsive" *ngIf="dataSource && hideFile">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>NOME</th>
        <td mat-cell *matCellDef="let element">{{ element.NOME }} {{ element.SOBRENOME }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.CPF | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.CNPJ | mask: '00.000.000/0000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="jcoins">
        <th mat-header-cell *matHeaderCellDef>JCOINS</th>
        <td mat-cell *matCellDef="let element">{{ element.JCOINS }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>

  <div class="table-responsive" *ngIf="dataSourcePreRegister && hideFilePreRegister">
    <table mat-table [dataSource]="dataSourcePreRegister">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'make-red': element.cnpjError }">
          {{ element.CNPJ | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cnpjOrigem">
        <th mat-header-cell *matHeaderCellDef>CNPJ ORIGEM</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'make-red': element.cnpjError }">
          {{ element.CNPJORIGEM | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>NOME</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'make-red': element.cnpjError }">
          {{ element.NOME }}
        </td>
      </ng-container>

      <ng-container matColumnDef="jcoins">
        <th mat-header-cell *matHeaderCellDef>JCOINS</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'make-red': element.cnpjError }">
          {{ element.JCOINS }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTablePreRegister"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTablePreRegister"></tr>
    </table>
  </div>
</app-logged>
