<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Termos</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/customer/{{ document }}/term/new/{{ type }}"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="terms">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="documentName">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.documentName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            (click)="download($event, element.documentUrl, element.documentName)"
          >
            <mat-icon>get_app</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/customer/{{ this.document }}/term/{{ element.id }}/edit/{{
              type
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
