import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import numeral from 'numeral'
import * as _ from 'lodash'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { MembershipService } from '../../../../services/membership.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-memberships-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss'],
})
export class MembershipsPlansListComponent implements OnInit {
  public headersTable = [
    'name',
    'beginDate',
    'endDate',
    'value',
    'recurrencePeriod',
    'active',
    'type',
    'actions',
  ]

  public headersTableIsFree = [
    'name',
    'beginDate',
    'endDate',
    'value',
    'recurrencePeriod',
    'active',
    'actions',
  ]

  public headersTableIsDefault = [
    'name',
    'beginDate',
    'endDate',
    'value',
    'recurrencePeriod',
    'active',
    'actions',
  ]

  params: Params
  formGroup: FormGroup
  dataSource: any
  dataSourceIsFree: any
  dataSourceIsDefault: any
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public pageTotal: number
  fieldsForm: any
  planName: string
  beginDate: string
  endDate: string
  recurrencePeriod: string
  value: number
  active: number
  planId: string

  constructor (
    public snackBar: MatSnackBar,
    public membershipService: MembershipService,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        if (this.planId) {
          this.planFindOne()
        }
        this.initForm()
      } else {
        if (this.planId) {
          this.planFindOne()
        } else {
          this.listPlans()
          this.listPlansOthers()
          this.initForm()
        }
      }
    })
    this.planId = this.route.snapshot.paramMap.get('planID')
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'namePlanMembership',
        label: 'Plano Membership',
        placeholder: 'Plano Membership',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Início',
        formControlName: 'beginDatePlanMembership',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Final',
        formControlName: 'endDatePlanMembership',
      },
      {
        field: 'select',
        formControlName: 'activePlanMembership',
        label: 'Status',
        placeholder: 'Status',
        list: [
          { key: 'ATIVO', value: 'ATIVO' },
          { key: 'TODOS', value: 'TODOS' },
          { key: 'INATIVO', value: 'INATIVO' },
        ],
      },
      {
        field: 'select',
        formControlName: 'typePlanMembership',
        label: 'Tipo',
        placeholder: 'Tipo',
        list: [
          { key: 'recurrent', value: 'RECORRENTE' },
          { key: 'pj', value: 'PJ' },
          { key: 'all', value: 'TODOS' },
        ],
      },
    ]
  }

  listPlans () {
    this.membershipService
      .listPlans(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["isActive"], "op": "=", "value":"1", "fields":["isFree"], "op": "=", "value":"1"}]}`,
      )
      .then(
        async response => {
          this.dataSourceIsFree = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )

    this.membershipService
      .listPlans(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["isActive"], "op": "=", "value":"1", "fields":["isDefault"], "op": "=", "value":"1"}]}`,
      )
      .then(
        async response => {
          this.dataSourceIsDefault = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  listPlansOthers () {
    this.membershipService
      .listPlansOthers(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["isActive"], "op": "=", "value":"1"}]}`,
      )
      .then(
        async response => {
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  async planFindOne () {
    this.membershipService
      .listPlans(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["id"], "op": "=", "value":"${this.planId}"}]}`,
      )
      .then(
        async response => {
          const plan = response.results.find(() => true)
          this.fieldsForm = [
            {
              field: 'input',
              type: 'text',
              formControlName: 'namePlanMembership',
              label: 'Plano Membership',
              placeholder: 'Plano Membership',
              valueDefault: plan.name,
            },
            {
              field: 'input',
              type: 'date',
              label: 'Data Início',
              formControlName: 'beginDatePlanMembership',
            },
            {
              field: 'input',
              type: 'date',
              label: 'Data Final',
              formControlName: 'endDatePlanMembership',
            },
            {
              field: 'select',
              formControlName: 'activePlanMembership',
              label: 'Status',
              placeholder: 'Status',
              list: [
                { key: 'ATIVO', value: 'ATIVO' },
                { key: 'TODOS', value: 'TODOS' },
                { key: 'INATIVO', value: 'INATIVO' },
              ],
            },
            {
              field: 'select',
              formControlName: 'typePlanMembership',
              label: 'Tipo',
              placeholder: 'Tipo',
              list: [
                { key: 'recurrent', value: 'RECORRENTE' },
                { key: 'pj', value: 'PJ' },
                { key: 'all', value: 'TODOS' },
              ],
            },
          ]

          if (plan.isDefault) {
            this.dataSourceIsDefault = new MatTableDataSource(response.results)
          } else if (plan.isFree) {
            this.dataSourceIsFree = new MatTableDataSource(response.results)
          } else {
            this.pageTotal = response.total
            this.dataSource = new MatTableDataSource(response.results)
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  group (data) {
    const isPj = data.filter(p => p.isPj === 1)
    const isDefault = data.filter(p => p.isDefault === 1)
    const isFree = data.filter(p => p.isFree === 1)
    const others = data.filter(p => p.isFree !== 1 && p.isDefault !== 1 && p.isPj !== 1)

    const groupsPj = _(isPj)
      .groupBy(v => v.isPj)
      .value()
    const groupsDefault = _(isDefault)
      .groupBy(v => v.isDefault)
      .value()
    const groupsFree = _(isFree)
      .groupBy(v => v.isFree)
      .value()
    const groupsOthers = _(others)
      .groupBy(v => v.others)
      .value()

    const result = []
    for (const group in groupsDefault) {
      result.push({ group: 'Plano Recorrente Default' })
      for (const item of groupsDefault[group]) {
        result.push(item)
      }
    }
    for (const group in groupsFree) {
      result.push({ group: 'Free' })
      for (const item of groupsFree[group]) {
        result.push(item)
      }
    }
    for (const group in groupsPj) {
      result.push({ group: 'PJ' })
      for (const item of groupsPj[group]) {
        result.push(item)
      }
    }
    for (const group in groupsOthers) {
      result.push({ group: 'Outros' })
      for (const item of groupsOthers[group]) {
        result.push(item)
      }
    }
    return result
  }

  isGroup (index, item): boolean {
    return !!item.group
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  month (recurrencePeriod) {
    if (recurrencePeriod == 0) {
      return ''
    } else if (recurrencePeriod > 1) {
      return `${recurrencePeriod} meses`
    } else {
      return `${recurrencePeriod} mês`
    }
  }

  typePlan (type) {
    if (type) {
      return 'PJ'
    } else {
      return 'Recorrente'
    }
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja inativar esse Plano Membership?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          const data = {
            isActive: false,
          }
          await this.membershipService.editPlan(id, data).then(
            async response => {
              this.listPlans()
              this.snackBar.open('Plano Membership inativo.')
            },
            error => {
              this.snackBar.open(error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.listPlans()
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
