import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import JsFileDownloader from 'js-file-downloader';
var ModalFormLiquidationTransferDetailedReport = /** @class */ (function () {
    function ModalFormLiquidationTransferDetailedReport(formBuilder, dialogRef, reportService, snackBar, errorsService) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
    }
    ModalFormLiquidationTransferDetailedReport.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            startDueDate: [undefined, []],
            endDueDate: [undefined, []],
            startPaidAt: [undefined, []],
            endPaidAt: [undefined, []],
            isSuccess: [undefined, []],
            reason: [undefined, []],
            cnpj: [undefined, []],
        });
    };
    ModalFormLiquidationTransferDetailedReport.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormLiquidationTransferDetailedReport.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, filters, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            startDueDate: this.formGroup.value.startDueDate,
                            endDueDate: this.formGroup.value.endDueDate,
                            startPaidAt: this.formGroup.value.startPaidAt,
                            endPaidAt: this.formGroup.value.endPaidAt,
                            reason: this.formGroup.value.reason,
                            cnpj: this.formGroup.value.cnpj,
                        };
                        filters = Object.keys(data).reduce(function (acc, cur) {
                            var _a;
                            if (!data[cur]) {
                                return acc;
                            }
                            return tslib_1.__assign({}, acc, (_a = {}, _a[cur] = data[cur], _a));
                        }, {});
                        if (this.formGroup.value.isSuccess !== undefined && this.formGroup.value.isSuccess !== null)
                            filters.isSuccess = this.formGroup.value.isSuccess;
                        return [4 /*yield*/, this.reportService.liquidationTransferDetailedReport(filters)];
                    case 1:
                        result = _a.sent();
                        new JsFileDownloader({
                            url: result.fileUrl,
                        }).start();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ModalFormLiquidationTransferDetailedReport;
}());
export { ModalFormLiquidationTransferDetailedReport };
