import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import JsFileDownloader from 'js-file-downloader';
import numeral from 'numeral';
var ArchitectClosingListForStore = /** @class */ (function () {
    function ArchitectClosingListForStore(architectClosingsService, location, dialog, snackBar) {
        this.architectClosingsService = architectClosingsService;
        this.location = location;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.headersTable = ['closingNumber', 'startDate', 'endDate', 'totalNdTax', 'totalNfTax', 'totalPay', 'totalInvoices', 'totalInvoicesValue', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected'));
        this.storeBusinessUnitId = this.selectedStoreBusinessUnit.storeId;
    }
    ArchitectClosingListForStore.prototype.ngOnInit = function () {
        this.loadClosings();
    };
    ArchitectClosingListForStore.prototype.loadClosings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, total, results;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.architectClosingsService.findAllClosingsByStore({
                            page: this.page,
                            range: this.pageSize,
                            storeBusinessUnitId: this.storeBusinessUnitId,
                        })];
                    case 1:
                        _a = _b.sent(), total = _a.total, results = _a.results;
                        this.pageTotal = total;
                        this.closingsList = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingListForStore.prototype.filterCallback = function (returnFilter) {
        var total = returnFilter.total, results = returnFilter.results;
        this.pageTotal = total;
        this.closingsList = new MatTableDataSource(results);
    };
    ArchitectClosingListForStore.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectClosingListForStore.prototype.export = function (architectClosingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.architectClosingsService.exportClosingByStore(architectClosingId, this.storeBusinessUnitId)];
                    case 1:
                        response = _a.sent();
                        if (response.report) {
                            new JsFileDownloader({
                                url: response.report,
                                nameCallback: function (fileName) {
                                    return ('Relatório de Fechamento Arquitetos.' +
                                        (fileName.split('.').pop() || 'xls'));
                                },
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingListForStore.prototype.convertCentsToReal = function (value) {
        if (value) {
            return value / 100;
        }
        else
            return value;
    };
    ArchitectClosingListForStore.prototype.totalPay = function (taxCashback, taxAdm) {
        var total = Number(taxCashback) + Number(taxAdm);
        return numeral(Number(total)).divide(100).value();
    };
    return ArchitectClosingListForStore;
}());
export { ArchitectClosingListForStore };
