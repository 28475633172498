<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Informações</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airport/{{ airportID }}/informations"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Título</mat-label>
              <input matInput formControlName="title" />
              <mat-error *ngIf="formGroup.controls.title?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.title) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Ordem</mat-label>
              <input type="number" matInput formControlName="order" />
              <mat-error *ngIf="formGroup.controls.order?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.order) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Mensagem do footer</mat-label>
              <input matInput formControlName="footerMsg" />
              <mat-error *ngIf="formGroup.controls.footerMsg?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.footerMsg) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
