import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'
import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ModalChooseStoreComponent } from '../../../../../components/modal-choose-store/modal-choose-store.component'
import { CompaniesService } from '../../../../../services/companies.service'
import { Location } from '@angular/common'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'
import { UtilService } from 'src/app/services/util.service'

const getEnumKeyByEnumValue = UtilService.getEnumKeyByEnumValue;

@Component({
  selector: 'app-architects-store-user-form.component',
  templateUrl: './architects-store-user-form.component.html',
  styleUrls: ['./architects-store-user-form.component.scss'],
})
export class ArchitectsStoreUserFormComponent implements OnInit {
  public formGroup: FormGroup
  public id: any
  public email: any
  public userId: any
  public storesSelected: any[] = []
  public stores: any[] = []

  public userRole;
 
  public permissions: {[key: string]: enumCmsPermissions} = [
      enumCmsPermissions.architect_invoice_create_update,
      enumCmsPermissions.architect_invoice_list,      
      enumCmsPermissions.architect_invoice_cancel,
      enumCmsPermissions.architect_invoice_delete,
      enumCmsPermissions.architect_closings_menu,
  ].map(p => getEnumKeyByEnumValue(enumCmsPermissions,p))
  .reduce((acc, key) => ({[key]: enumCmsPermissions[key], ...acc}), {})

  public selectedPermissions: {[permissionKey: string]: boolean} ={}

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog,
    public companiesService: CompaniesService,
    private readonly location: Location,
  ) {
    this.selectedPermissions = Object.keys(this.permissions).reduce((acc, permissionKey) => ({[permissionKey]: false, ...acc}), {} )
    this.userRole = localStorage.getItem('userRole')
    this.initForm()
  }

  ngOnInit () {
    // this.id = this.route.snapshot.paramMap.get('id')
    // this.userId = this.route.snapshot.paramMap.get('userId')
    // this.email = this.route.snapshot.paramMap.get('email')

    // this.loadStores().catch(err => { console.log(err) })
    // if (this.userId) {
    //   this.findOne().catch(err => { console.log(err) })
    // }
  }

  async initForm () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.userId = this.route.snapshot.paramMap.get('userId')
    this.email = this.route.snapshot.paramMap.get('email')
   
    this.formGroup = this.formBuilder.group(
      {
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        emailAddress: [this.email, [Validators.required, Validators.email]],
        phoneNumber: [null, [Validators.required]],
        phonestoreBusinessUnitIdsNumber: [null, []],
      },
    )

    await this.loadStores().catch(err => { console.log(err) })
    if (this.userId) {
      await this.findOne().catch(err => { console.log(err) })
    }
    const storeBusinessUnitIds =  this.storesSelected.map( e => e.id)
    if(!storeBusinessUnitIds.includes(this.id))
      this.showStores(this.stores.find( s => s.id === this.id))
  }

  async loadStores () {
    const { stores } = await this.companiesService
      .listStoresBusinessUnits('?page=1&limit=1000000')
      .catch(err => {
        this.snackBar.open(err.message)
        return { stores: [], TotalRegisters: 0 }
      })
    this.stores = stores

    // this.storesSelected = stores.filter(s => s.id === this.id)
    // this.stores = this.stores.filter(item => item.id !== this.id)
  }

  async findOne () {
    const user = await this.authService
      .userInfo(this.userId)
      .toPromise()
      .catch(err => {
        this.snackBar.open(err.message)
      })
    this.formGroup.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
    })

    this.storesSelected = user.storeBusinessUnits   
    user.permissions.forEach( permissionKey => {
      this.selectedPermissions[permissionKey] = true
    })
  }

  isChecked (id) {
    if (id === this.id) {
      return 'checked'
    }
  }

  openDialog (): void {
    const dialogRef = this.dialog.open(ModalChooseStoreComponent, {
      width: '500px',
      data: {
        stores: this.stores,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.showStores(result)
      }
    })
  }

  showStores (store) {
    this.storesSelected.push(store)

    this.stores = this.stores.filter(f => !this.storesSelected.includes(f))
  }

  removeStore (store) {
    if (store.id == this.formGroup.value.storeId || this.storesSelected.length == 0) {
      this.formGroup.get('storeId').setValue('')
    }
    this.storesSelected = this.storesSelected.filter(item => item.id !== store.id)
    this.stores.push(store)
  }

  async submit () {
    const filterIds = this.storesSelected.filter(s => s.id === this.id)
    console.log(filterIds)
    if (!this.formGroup.valid || !this.storesSelected.length) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data: any = {
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      emailAddress: this.formGroup.value.emailAddress,
      phoneNumber: this.formGroup.value.phoneNumber,
      storeBusinessUnitIds: this.storesSelected.map(s => s.id),     
    }

    if (this.userId) {

      data.permissionsToAdd = Object.entries(this.selectedPermissions)
        .filter(([permissionName,checked]) => checked)
        .map( ([permissionName,checked]) => permissionName ),
      data.permissionsToRemove = Object.entries(this.selectedPermissions)
        .filter(([permissionName,checked]) => !checked)
        .map( ([permissionName,checked]) => permissionName )

      this.companiesService.updateStoreBusinessUnitUser(this.userId, data).then(
        async response => {
          if (response.success) {
            this.location.back()
            this.snackBar.open('Usuário atualizado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {

      data.permissions = Object.entries(this.selectedPermissions)
        .filter(([permissionName,checked]) => checked)
        .map( ([permissionName,checked]) => permissionName ),      

      this.companiesService.createStoreBusinessUnitUser(data).then(
        async response => {
          if (response.isCreated) {
            this.location.back()
            this.snackBar.open('Usuário cadastrado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  changePermissions(evt) {
    const permissionKey = evt.target.value
    this.selectedPermissions[permissionKey] = evt.target.checked
  }
  
}
