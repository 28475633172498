export enum enumCmsPermissions {
  internal_user_managment = 'Gerenciamento de usuários internos JHSF ID',
  integrator = 'Integração via API (Totvs, JHSFPay, etc)',
  manual_credit_maker = 'Solicitar crédito manual de JCOINS',
  manual_credit_revisor = 'Revisar crédito manual de JCOINS',
  architect_invoice_create_update = 'Criar/Editar Notas fiscais de arquiteto',
  architect_invoice_list = 'Listar Notas fiscais de arquiteto',
  architect_invoice_approve = 'Aprovar Notas fiscais de arquiteto',
  architect_invoice_cancel = 'Cancelar Notas fiscais de arquiteto',
  architect_invoice_delete = 'Deletar Notas fiscais de arquiteto',
  architect_admin_menu = 'Menu de acesso à unidade de negócio dos arquitetos',
  architect_closings_menu = 'Visualizar os Fechamentos de arquiteto que os parceiros estão inclusos',
  architect_reports = 'Visualizar relatórios',
  architect_notifications = 'Criar notificações',
}
