import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-international-iof-accumulation',
  templateUrl: './modal-form-international-iof-accumulation.component.html',
  styleUrls: ['./modal-form-international-iof-accumulation.component.scss'],
})
export class ModalFormInternationalIofAccumulationComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public selectedStores: any[] = []
  public statusControl = new FormControl()
  public filter: string
  stores: any[] = []
  visible = true
  selectable = true
  filteredStores: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]  

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormInternationalIofAccumulationComponent>,
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string
      architect: boolean
    },
  ) { }

  ngOnInit () {    
    this.formGroup = this.formBuilder.group({
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      selectedCustomer: [null, []],
    })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {    

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    let url = 'internation-iof-accumulation'

    let filter = `beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    if(this.formGroup.value.selectedCustomer && this.formGroup.value.selectedCustomer.id) {
      filter += `&customerId=${this.formGroup.value.selectedCustomer.id}`
    }

    this.reportService
      .createReport(`${url}?${filter}`)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório Fatura AMEX - IOF Jcoins.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
