<app-logged class="store-list">
  <header class="store-list__header">
    <h2>FECHAMENTO FINANCEIRO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/closings-incorporations">
        VOLTAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="closings">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cnpj | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nomeFantasia }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nd">
        <th mat-header-cell *matHeaderCellDef>ND</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalPoints }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nf">
        <th mat-header-cell *matHeaderCellDef>NF</th>
        <td mat-cell *matCellDef="let element">
          {{ calcNF(element.totalPoints) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
