import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup } from '@angular/forms';
var ModalRoadpassUnableToCancel = /** @class */ (function () {
    function ModalRoadpassUnableToCancel(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
    }
    ModalRoadpassUnableToCancel.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    return ModalRoadpassUnableToCancel;
}());
export { ModalRoadpassUnableToCancel };
