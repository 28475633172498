<div *ngIf="actions == 'form'">
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="filterGroup">
      <div class="fields" *ngFor="let fields of fieldsForm">
        <mat-form-field *ngIf="fields.field == 'input'">
          <!-- Verificação de label e placeholder para o campo 'cnpjStore' -->
          <mat-label>
            {{ fields.formControlName === 'cnpjStore' ? 'Documento' : fields.label }}
          </mat-label>
          <div
            *ngIf="
              fields.formControlName == 'cnpjStore' ||
                fields.formControlName == 'cnpjFund' ||
                fields.formControlName == 'cpfFundCustomersAssociates' ||
                fields.formControlName == 'cpfTransaction' ||
                fields.formControlName == 'cpfClient' ||
                fields.formControlName == 'cpfTransactionPending' ||
                fields.formControlName == 'cpfSegmentationResults' ||
                fields.formControlName == 'cpfQuizClient' ||
                fields.formControlName == 'cpfFundTransaction' ||
                fields.formControlName == 'cpfNotificationCustomers' ||
                fields.formControlName == 'cpfPlanMembership' ||
                fields.formControlName == 'cpfPreRegistration' ||
                fields.formControlName == 'cpfCustomerBeneficiary' ||
                fields.formControlName == 'cpfBeneficiary' ||
                fields.formControlName == 'cpfIntegration' ||
                fields.formControlName == 'cnpjAirportCompany' ||
                fields.formControlName == 'cpfAirportCompany' ||
                fields.formControlName == 'cnpjArchitectCompany' ||
                fields.formControlName == 'architectInvoiceStoreCnpj' ||
                fields.formControlName == 'cnpjArchitectStores' ||
                fields.formControlName == 'cnpjTransferScheduleList';
              else inputFields
            "
          >
            <!-- Input para 'cnpjStore' sem máscara e com placeholder "Documento" -->
            <input
              *ngIf="fields.formControlName === 'cnpjStore'"
              matInput
              type="{{ fields.type }}"
              formControlName="{{ fields.formControlName }}"
              placeholder="Documento"
            />

            <!-- Input para outros campos com máscara -->
            <input
              *ngIf="fields.valueDefault && fields.formControlName !== 'cnpjStore'"
              matInput
              type="{{ fields.type }}"
              formControlName="{{ fields.formControlName }}"
              placeholder="{{ fields.placeholder }}"
              mask="{{ fields.mask || maskExample }}"
              value="{{ fields.valueDefault | mask: '000.000.000-00' }}"
            />
            <input
              *ngIf="!fields.valueDefault && fields.formControlName !== 'cnpjStore'"
              matInput
              type="{{ fields.type }}"
              formControlName="{{ fields.formControlName }}"
              placeholder="{{ fields.placeholder }}"
              mask="{{ fields.mask || maskExample }}"
            />
          </div>

          <ng-template #inputFields>
            <input
              *ngIf="!fields.valueDefault"
              [ngStyle]="{
                width:
                  fields.formControlName == 'nameQuiz' ||
                  fields.formControlName == 'nameQuizCustomer' ||
                  fields.formControlName == 'nameQuestion'
                    ? '20rem'
                    : fields.formControlName == 'titleNotification'
                    ? '17rem'
                    : fields.formControlName == 'descriptionNotification'
                    ? '20rem'
                    : ''
              }"
              matInput
              type="{{ fields.type }}"
              formControlName="{{ fields.formControlName }}"
              placeholder="{{ fields.placeholder }}"
              [attr.maxLength]="fields.maxLength ? fields.maxLength : null"
              [attr.minLength]="fields.minLength ? fields.minLength : null"
            />
            <input
              *ngIf="fields.valueDefault"
              matInput
              type="{{ fields.type }}"
              formControlName="{{ fields.formControlName }}"
              placeholder="{{ fields.placeholder }}"
              value="{{ fields.valueDefault }}"
            />
          </ng-template>
        </mat-form-field>
        <div>
          <mat-form-field *ngIf="fields.field == 'select'">
            <mat-label>{{ fields.label }}</mat-label>
            <mat-select formControlName="{{ fields.formControlName }}">
              <mat-option *ngIf="fields.optionDefault" [value]="0">{{
                fields.optionDefault
              }}</mat-option>
              <mat-option *ngFor="let field of fields.list" [value]="field.key">
                {{ field.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <button
        style="margin-bottom: 20px; margin-top: 10px"
        class="btn-primary-light"
        type="submit"
        mat-button
      >
        Pesquisar
      </button>
    </div>
  </form>
</div>
<div *ngIf="actions == 'pagination'">
  <mat-paginator
    type="type"
    [length]="length"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    (page)="pageChanged($event)"
  ></mat-paginator>
</div>
