<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Sub Categorias</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/categories">
        VOLTAR
      </button>
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/categories/{{ categoryId }}/subcategories/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>Categoria: </b>{{ category }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="subCategories">
      <ng-container matColumnDef="heading">
        <th mat-header-cell *matHeaderCellDef>Título</th>
        <td mat-cell *matCellDef="let element">{{ element.heading }}</td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Ordem</th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="Ativo" *ngIf="element.isActive === 1"
            >check_circle_outline</mat-icon
          >
          <mat-icon matTooltip="Inativo" *ngIf="element.isActive === 0">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Parceiros"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.id }}/business-partners"
          >
            <mat-icon>store</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Banner"
            matTooltipPosition="left"
            routerLink="/dashboard/banners/subcategory/{{ element.id }}"
          >
            <mat-icon>image</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ categoryId }}/subcategories/{{ element.id }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
