import { Component, OnInit, Inject, Input } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'modal-confirmation-international-transaction',
  templateUrl: './modal-confirmation-international-transaction.component.html',
  styleUrls: ['./modal-confirmation-international-transaction.component.scss'],
})
export class ModalConfirmationInternationalTransactionComponent implements OnInit {
  

  constructor (
    public dialogRef: MatDialogRef<ModalConfirmationInternationalTransactionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string,
      totalValue: number,
      totalValueWithTaxes: number,
      currency: string,
      internationalCurrencyValue: number,
      currencyQuote: number,
      purchaseFeePercent: number
      purchaseFeeValue: number,
      customerName: string,
      customerCpf: string,
    },
  ) {}

  ngOnInit () {}



  convertCentsToReal (value) {
    return value / 100
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  createTransacation () {   
    this.dialogRef.close({ createTransacation: true, idInternationalPaymentIntent: this.data.id })    
  }
}
