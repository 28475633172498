import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core'
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ReportService } from '../../services/report.service'
import { ErrorsService } from '../../core/services/errors.service'
import JsFileDownloader from 'js-file-downloader'
const UserColumns = [
  {
    key: 'cpf',
    type: 'text',
    label: 'CPF',
    required: true,
  },
  {
    key: 'firstName',
    type: 'text',
    label: 'FirstName',
    required: true,
  },
  {
    key: 'lastName',
    type: 'text',
    label: 'LastName',
    required: true,
  },
  {
    key: 'percentage',
    type: 'text',
    label: 'percentage',
    required: true,
  },
]
@Component({
  selector: 'app-modal-form-addCustomerAirportCompanyr',
  templateUrl: './modal-form-addCustomerAirportCompany.html',
  styleUrls: ['./modal-form-addCustomerAirportCompany.component.scss'],
})
export class ModalFormAddCustomerAirportCompanyComponent implements OnInit {
  public formGroup: FormGroup
  displayedColumns: string[] = UserColumns.map((col) => col.key)
  // columnsSchema: any = UserColumns
  dataSource: any = [
    {
      cpf: '',
      firstName: '',
      lastName: '',
      percentage: '',
    },
  ]

  public selectedCustomers: any[] = []
  public customerControl = new FormControl()
  public customerInvalid = false
  public beginDateInvalid = false
  public endDateInvalid = false
  public customersCPF: any
  customers: any[] = []
  id: string[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]

  filteredCustomers: Observable<string[]>

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public dialogRef: MatDialogRef<ModalFormAddCustomerAirportCompanyComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      width: string
      data: any
    },

    // @Inject(LY_DIALOG_DATA) private readonly event: { img: any, config: any }
  ) { }

  ngOnInit () {
    console.log(this.data)
    this.formGroup = this.formBuilder.group({
      cpf1: [null, []],
      firstName1: [null, []],
      lastName1: [null, []],
      percentage1: [null, []],
      cpf2: [null, []],
      firstName2: [null, []],
      lastName2: [null, []],
      percentage2: [null, []],
      cpf3: [null, []],
      firstName3: [null, []],
      lastName3: [null, []],
      percentage3: [null, []],
      cpf4: [null, []],
      firstName4: [null, []],
      lastName4: [null, []],
      percentage4: [null, []],
    })

    this.reportService.listCustomers().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )
  }

  private _filter (customer: string): string[] {
    if (!this.selectedCustomers.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers.includes(c))
        .filter(c =>
          `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }
  }

  removeCustomer (customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedCustomers.length) {
      this.selectedCustomers.push(event.option.value)
      this.customerInput.nativeElement.value = ''
      this.customerControl.setValue(null)
      event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true)
    }
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit (cpf?) {
    console.log(this.formGroup.value.firstName1)
    console.log(this.formGroup.value.firstName2)
    console.log(this.formGroup.value.firstName3)
    console.log(this.formGroup.value.firstName4)
    console.log(this.formGroup.value.lastName1)
    console.log(this.formGroup.value.lastName2)
    console.log(this.formGroup.value.lastName3)
    console.log(this.formGroup.value.lastName4)
    if (!cpf) {
      this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true)
      this.beginDateInvalid = !this.formGroup.value.beginDate
      this.endDateInvalid = !this.formGroup.value.endDate

      this.customersCPF = this.selectedCustomers.map(c => c.cpf)
      if (this.beginDateInvalid || this.endDateInvalid || this.customerInvalid) {
        this.snackBar.open('Preencha corretamente os campos e tente novamente.')
        return false
      }
    }
    let filter = ''
    if (cpf) {
      if (this.formGroup.value.beginDate || this.formGroup.value.endDate) {
        this.beginDateInvalid = !this.formGroup.value.beginDate
        this.endDateInvalid = !this.formGroup.value.endDate
        if (this.beginDateInvalid || this.endDateInvalid) {
          this.snackBar.open('Preencha corretamente os campos e tente novamente.')
          return false
        }
        filter = `statement-customer?cpf=${cpf}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
      } else {
        filter = `statement-customer?cpf=${cpf}`
      }
    } else {
      filter = `statement-customer?cpf=${this.customersCPF}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    }
    this.reportService
      .createReport(filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Extrato por Cliente.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
