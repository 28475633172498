import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import { BillPaymentNotificationService } from 'src/app/services/bill-payment-notification.service'

@Component({
  selector: 'bill-payment-notifications',
  templateUrl: './bill-payment-notifications.component.html',
  styleUrls: ['./bill-payment-notifications.component.scss'],
})
export class BillPaymentNotificationsComponent implements OnInit {
  headersTable: string[] = [
    'id', 'customerDocument', 'dueDate', 'paidDate', 'invoiceValue', 'paidValue', 'transactions', 'processedAt', 'totalInvoiceValuePaidAt'
  ]
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    private readonly service: BillPaymentNotificationService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.list().catch(err => { console.log(err) })

    let year = new Date().getFullYear();

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'billPaymentCpf',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'select',
        formControlName: 'billPaymentMonth',
        label: 'Mês',
        list: [
          { key: '1', value: '1 - Jan' },
          { key: '2', value: '2 - Fev' },
          { key: '3', value: '3 - Mar' },
          { key: '4', value: '4 - Abr' },
          { key: '5', value: '5 - Mai' },
          { key: '6', value: '6 - Jun' },
          { key: '7', value: '7 - Jul' },
          { key: '8', value: '8 - Ago' },
          { key: '9', value: '9 - Set' },
          { key: '10', value: '10 - Out' },
          { key: '11', value: '11 - Nov' },
          { key: '12', value: '12 - Dez' },
        ],
        optionDefault: '',
      },
      {
        field: 'select',
        formControlName: 'billPaymentYear',
        label: 'Ano',
        list: [
          { key: year.toString(), value: year.toString() },
          { key: (year - 1).toString(), value: (year - 1).toString() },
          { key: (year - 2).toString(), value: (year - 2).toString() },
        ],
        optionDefault: '',
      },
    ]

    this.list()
  }

  async list() {
    // cpf, month, year,
    const params = new URLSearchParams({ 
      page: this.page.toString(), 
      pageSize: this.pageSize.toString() 
    });
    const { results, pagination } = await this.service
      .list(params.toString())
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      });
    
    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = pagination.totalCount
    this.resultsAll = results
  }

  openModalErrorDetails(entity) {
    const errorsToShow = {
      "Erro": entity.errorMessage,
    }
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '520px',
      data: {
        html: Object.entries(errorsToShow).map(([key, val]) => val ? `<div><strong>${key}</strong><br><br><code style='word-break: break-word;'>${val}</code></div>` : '').join(' ')
      }
    })

    return dialogRef.afterClosed().toPromise()
  }

  reciverFeedback(returnFilter) {
    this.returnedFilters = returnFilter.results
    this.pageTotal = returnFilter.pagination.totalCount
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
