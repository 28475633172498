import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { MatSnackBar, MatTableDataSource } from '@angular/material'
import numeral from 'numeral'

import { MembershipService } from '../../../../services/membership.service'

@Component({
  selector: 'app-membership-customers-plan-list',
  templateUrl: './plan-customers-list.component.html',
  styleUrls: ['./plan-customers-list.component.scss'],
})
export class MembershipCustomersPlanListComponent implements OnInit {
  public pageTotal: number
  planId: string
  headersTable = ['cpf', 'fullName', 'beginDate', 'endDate', 'lastPayment', 'actions']
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  fieldsForm: any
  dataSource: any
  planName: string
  beginDate: string
  endDate: string
  recurrencePeriod: string
  value: number
  active: number
  public params = { formGroupValue: [] }

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    public membershipService: MembershipService,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfPlanMembership',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'fullNamePlanMembership',
        label: 'Nome',
        placeholder: 'Nome',
      },
    ]

    this.planId = this.route.snapshot.paramMap.get('planID')

    this.membershipService.listPlansById(this.planId).then(
      async response => {
        this.planName = response.name
        this.beginDate = response.beginDate
        this.endDate = response.endDate
        this.value = response.value
        this.recurrencePeriod = response.recurrencePeriod
        this.active = response.rowActive
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )

    this.membershipService
      .listCustomersByPlan(
        this.planId,
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
      )
      .then(
        async response => {
          this.dataSource = new MatTableDataSource(response.results)
          this.pageTotal = response.total
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  month (recurrencePeriod) {
    if (recurrencePeriod == 0) {
      return ''
    } else if (recurrencePeriod > 1) {
      return `${recurrencePeriod} meses`
    } else {
      return `${recurrencePeriod} mês`
    }
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
