<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!id">CADASTRO DE QUESTIONÁRIO</h2>
    <h2 *ngIf="id">EDIÇÃO DE QUESTIONÁRIO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/quiz/list" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações do Questionário</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Questionário</mat-label>
              <input matInput formControlName="quiz" placeholder="Questionário" />
              <mat-error *ngIf="formGroup.controls.quiz?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.quiz) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <p>Descrição</p>
          <ckeditor [config]="config" [editor]="Editor" formControlName="description" (ready)="onReady($event)">
          </ckeditor>
          <!-- <mat-form-field>
            <mat-label>Imagem (Opcional)</mat-label>
            <input matInput disabled />
            <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
              <mat-icon>attach_file</mat-icon>
            </a>
            <img *ngIf="imageUrl != ''" width="200" src="{{ imageUrl }}" (error)="imgLoadError()" />
            <div class="btn-remove-img">
              <button class="btn-primary-light" mat-button *ngIf="imageUrl" (click)="removeImage()">
                REMOVER IMAGEM <mat-icon>cancel</mat-icon>
              </button>
            </div>
            <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
          </mat-form-field> -->
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
