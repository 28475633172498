import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar } from '@angular/material'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import { CategoriesService } from 'src/app/services/categories.service'
import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'

@Component({
  selector: 'app-exclusive-form',
  templateUrl: './exclusive-form.component.html',
  styleUrls: ['./exclusive-form.component.scss'],
})
export class ExclusiveFormComponent implements OnInit {
  public formGroup: FormGroup
  public categoryID: string
  public exclusiveID: string
  public bannerUrl: string
  private originalImage: any
  public croppedImage: any
  private imagePosition: any
  private imageData: any
  public readUrl: any
  public invalidImage = false

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public categoriesService: CategoriesService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
  ) {}

  ngOnInit () {
    this.categoryID = this.route.snapshot.paramMap.get('categoryID')
    this.exclusiveID = this.route.snapshot.paramMap.get('exclusiveID')

    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      exclusiveIconUrl: [null, []],
      exclusiveLink: [null, [Validators.required]],
      description: [null, [Validators.required]],
      isActive: [null, []],
    })
    if (this.exclusiveID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.categoriesService.exclusiveFindOne(this.exclusiveID).then(async response => {
      this.formGroup.patchValue({
        description: response.description,
        exclusiveLink: response.exclusiveLink,
        isActive: response.isActive,
      })
      this.croppedImage = response.exclusiveIconUrl
    })
  }

  selectFile (event: Event) {
    const originalImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalImage = reader.result
    }
    try {
      reader.readAsDataURL(originalImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedImage = result.img.dataURL
          this.imagePosition = result.config
          this.imageData = await base64ToBlob(this.croppedImage)
          this.invalidImage = !this.croppedImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editImage () {
    try {
      const img = this.originalImage
        ? this.originalImage
        : await this.getBase64ImageFromUrl(this.croppedImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.imagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedImage = result.img.dataURL
        this.imagePosition = result.config
        this.imageData = await base64ToBlob(this.croppedImage)
        this.invalidImage = !this.croppedImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async removeImage () {
    this.readUrl = ''
    this.originalImage = undefined
    this.croppedImage = undefined
    this.imagePosition = undefined
    this.imageData = undefined
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async submit () {
    this.invalidImage = !this.croppedImage
    if (!this.formGroup.valid || !this.croppedImage) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    if (this.croppedImage && this.imageData) {
      const response = await this.categoriesService.exclusiveGetS3Url()
      this.readUrl = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.imageData)
    }

    const data = {
      description: this.formGroup.value.description,
      exclusiveIconUrl: this.readUrl,
      exclusiveLink: this.formGroup.value.exclusiveLink,
      isActive: !!this.formGroup.value.isActive,
    }

    if (this.exclusiveID) {
      this.categoriesService.updateExclusive(this.exclusiveID, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate([
              './dashboard/categories/' + this.categoryID + '/exclusives',
            ])
            this.snackBar.open('Icone atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.categoriesService.addExclusive(this.categoryID, data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate([
              './dashboard/categories/' + this.categoryID + '/exclusives',
            ])
            this.snackBar.open('Icone criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
