import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
// registerLocaleData(br, 'pt-BR');
registerLocaleData(localePt);
var ɵ0 = { floatLabel: 'always' }, ɵ1 = { duration: 5000 };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
