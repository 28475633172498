import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import {  Params } from '@angular/router';
import { ModalFormIntegrationsComponent } from '../../../../components/modal-form-integrations/modal-form-integrations.component';
import { ReportService } from '../../../../services/report.service';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'nomeFantasia', 'cpf', 'name', 'cpfBeneficiary', 'nameBeneficiary', 'contract', 'cnpjCpfTitular', 'totalValue', 'totalPoints', 'tax', 'dateCreatedAt', 'dateCredit', 'isClient'];
  fieldsForm: any;
  public returnedFilters: any;
  public pageTotal: number;
  dataSource: any;
  resultsAll: any;
  public formGroup: FormGroup;
  public params: Params;

  page = 1;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  reportTitle: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public authService: AuthService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public reportService: ReportService,
  ) {}

  ngOnInit() {
    this.list().catch(err => { console.log(err) });

  const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameIntegration',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfIntegration',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Inicio',
        formControlName: 'dateInitIntegration',
        valueDefault: firstDayOfMonth,
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Fim',
        formControlName: 'dateEndIntegration',
        valueDefault: lastDayOfMonth
      },
      {
        field: 'select',
        formControlName: 'percentageIntegration',
        label: '% Jcoins',
        list: [
          { key: '0.05', value: '% > 0.05' },
          { key: 'all', value: 'TODOS' },
        ],
      },
    ];
  }

  async list() {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const { results, total } = await this.authService
      .clientsIncorporations(
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${moment(firstDay).format('YYYY-MM-DD')}"}, {"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${moment(lastDay).format('YYYY-MM-DD')}"}]}`
      )
      .catch(err => {
        this.snackBar.open(err.message);
        return { results: [], total: 0 };
      });

    const sortedResults = results.sort((a, b) => a.contract - b.contract);

    this.dataSource = new MatTableDataSource(sortedResults);
    this.pageTotal = total;
    this.resultsAll = sortedResults;
  }

  receiverFeedback(returnFilter) {
    this.returnedFilters = returnFilter;
    this.pageTotal = returnFilter.total;
  
    const sortedResults = returnFilter.results.sort((a, b) => a.contract - b.contract);
  
    this.dataSource = new MatTableDataSource(sortedResults);
    this.resultsAll = sortedResults;
    
    if (returnFilter && returnFilter.formGroup) {
      this.formGroup = returnFilter.formGroup.value;
    }
  
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    };
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalFormIntegrationsComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(filters => {
      if (filters) {
        this.report(filters);
      }
    });
  }

  async report(filters: { startDate: string, endDate: string, status: string }) {
    try {
      const formatDate = (dateStr: string) => {
        const day = dateStr.substring(0, 2);
        const month = dateStr.substring(2, 4);
        const year = dateStr.substring(4, 8);
  
        return `${day}/${month}/${year}`;
      };
  
      const formattedStartDate = formatDate(filters.startDate);
      const formattedEndDate = formatDate(filters.endDate);
  
      const response = await this.reportService.incorporationsExport(filters);
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          return `Consulta Base - ${formattedStartDate} à ${formattedEndDate}.xlsx`;
        },
      });
    } catch (error) {
      console.error('Erro ao baixar o relatório:', error);
    }
  }  
}
