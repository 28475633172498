import * as i0 from "@angular/core";
var UtilService = /** @class */ (function () {
    function UtilService() {
    }
    UtilService.prototype.extractNumbers = function (value) {
        return value && value.match(/\d+/g).join('');
    };
    UtilService.getEnumKeyByEnumValue = function (myEnum, enumValue) {
        var keys = Object.keys(myEnum).filter(function (x) { return myEnum[x] == enumValue; });
        return keys.length > 0 ? keys[0] : null;
    };
    UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(); }, token: UtilService, providedIn: "root" });
    return UtilService;
}());
export { UtilService };
