import * as tslib_1 from "tslib";
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var SegmentationListComponent = /** @class */ (function () {
    function SegmentationListComponent(segmentationService, snackBar, router, dialog, route) {
        this.segmentationService = segmentationService;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.route = route;
        this.headersTable = ['name', 'filterCount', 'customerCount', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    SegmentationListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                _this.initForm();
            }
            else {
                _this.listSegments().catch(function (err) {
                    _this.snackBar.open(err.message);
                });
                _this.initForm();
            }
        });
    };
    SegmentationListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameSegmentation',
                label: 'Nome',
                placeholder: 'Nome',
            },
        ];
    };
    SegmentationListComponent.prototype.listSegments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.segmentationService
                            .list("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    SegmentationListComponent.prototype.addSegmentation = function () {
        var _this = this;
        this.router.navigate(['./dashboard/segmentation/form']).catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationListComponent.prototype.removeSegmentation = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            data: {
                text: 'Deseja remover essa Segmentação?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.segmentationService
                            .delete(id)
                            .then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.snackBar.open('Segmentação removida com sucesso.');
                                return [2 /*return*/, this.listSegments()];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationListComponent.prototype.reprocess = function (id) {
        var _this = this;
        this.segmentationService
            .reprocess(id)
            .then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.returnedFilters) {
                    return [2 /*return*/, this.reciverFeedback(this.returnedFilters)];
                }
                return [2 /*return*/, this.listSegments()];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationListComponent.prototype.reprocessAll = function (resultsAll) {
        var _this = this;
        var e_1, _a;
        var ids = [];
        try {
            for (var resultsAll_1 = tslib_1.__values(resultsAll), resultsAll_1_1 = resultsAll_1.next(); !resultsAll_1_1.done; resultsAll_1_1 = resultsAll_1.next()) {
                var r = resultsAll_1_1.value;
                ids.push(r.id);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (resultsAll_1_1 && !resultsAll_1_1.done && (_a = resultsAll_1.return)) _a.call(resultsAll_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.segmentationService
            .reprocessSegmentationAll({ ids: ids })
            .then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.listSegments()];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return SegmentationListComponent;
}());
export { SegmentationListComponent };
