import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { AuthService } from '../../../../../services/auth.service'

@Component({
  selector: 'app-client-kind-form',
  templateUrl: './client-kind-form.component.html',
  styleUrls: ['./client-kind-form.component.scss'],
})
export class ClientKindFormComponent implements OnInit {
  public formGroup: FormGroup
  public id: string

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      kind: [null, [Validators.required]],
      isDefault: [null, []],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.authService.ClientKindFindOne(this.id).then(async response => {
      this.formGroup.patchValue({
        kind: response.kindName,
        isDefault: response.isDefault,
      })
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      kindName: this.formGroup.value.kind,
      isDefault: !!this.formGroup.value.isDefault,
    }

    if (this.id) {
      this.authService.ClientKindUpdate(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/client-kind'])
            this.snackBar.open('Segmentação atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.ClientKindCreate(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/client-kind'])
            this.snackBar.open('Segmentação criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
