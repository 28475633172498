<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Beneficiários</h2>
    <!-- <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/beneficiary/new">
        ADICIONAR
      </button>
    </div> -->
  </header>
  <app-form-filter
    maskExample="000.000.000-00"
    actions="form"
    type="beneficiary"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="nameBeneficiary">
        <th mat-header-cell *matHeaderCellDef>Nome do Beneficiário</th>
        <td mat-cell *matCellDef="let element">
          {{
            element.beneficiary
              ? element.beneficiary.customerBeneficiary[0].firstName +
                ' ' +
                element.beneficiary.customerBeneficiary[0].lastName
              : ''
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cpfBeneficiary">
        <th mat-header-cell *matHeaderCellDef>CPF do Beneficiário</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.beneficiary">
            {{ element.beneficiary.customerBeneficiary[0].cpf | mask: '000.000.000-00' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="element.beneficiary"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/beneficiary/{{ element.beneficiary.id }}/customer/{{
              element.customerId
            }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="element.beneficiary"
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.beneficiary.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Cadastrar Beneficiário"
            matTooltipPosition="left"
            routerLink="/dashboard/beneficiary/customer/{{ element.customerId }}/new"
          >
            <mat-icon>add</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>

    <app-form-filter
      actions="pagination"
      type="beneficiary"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
