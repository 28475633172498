import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
var StoreBankAccountFormComponent = /** @class */ (function () {
    function StoreBankAccountFormComponent(errorsService, formBuilder, accountBankService, router, route, snackBar) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.accountBankService = accountBankService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.bankAccounts = [];
        this.storeID = '';
        this.initForm();
    }
    Object.defineProperty(StoreBankAccountFormComponent.prototype, "formData", {
        get: function () {
            var data = Object.assign({}, this.formGroup.value);
            data.bankCode = typeof data.bankCode == 'object' ? data.bankCode.code : data.bankCode;
            return data;
        },
        enumerable: true,
        configurable: true
    });
    StoreBankAccountFormComponent.prototype.ngOnInit = function () { };
    StoreBankAccountFormComponent.prototype.initForm = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.formGroup = this.formBuilder.group({
            bankCode: [null, [Validators.required]],
            agency: [null, [Validators.required]],
            accountNumber: [null, [Validators.required]],
            accountDigit: [null, [Validators.required]],
            accountType: [null, [Validators.required]],
        });
        this.bankAccountsOptions = this.formGroup.controls.bankCode.valueChanges.pipe(startWith(''), map(function (value) {
            return _this.bankAccounts.filter(function (bankAccount) {
                var newValue = typeof value == 'object' ? value.name : value;
                return (bankAccount.code == value ||
                    bankAccount.name.includes(newValue.toUpperCase()));
            });
        }));
        this.listBankAccounts();
    };
    StoreBankAccountFormComponent.prototype.displayBankCode = function (bank) {
        return bank ? bank.code + " - " + bank.name : '';
    };
    StoreBankAccountFormComponent.prototype.listBankAccounts = function () {
        var _this = this;
        this.accountBankService.listBankCodes().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.bankAccounts = response.sort(function (a, b) {
                    a.name = a.name.toUpperCase();
                    b.name = b.name.toUpperCase();
                    if (a.name < b.name) {
                        return -1;
                    }
                    else if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreBankAccountFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.accountBankService.add(this.formData, this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.snackBar.open('Conta bancária criada com sucesso.');
                            this.router.navigate([
                                './dashboard/stores/' + this.storeID + '/bank-account',
                            ]);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return StoreBankAccountFormComponent;
}());
export { StoreBankAccountFormComponent };
