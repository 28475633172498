import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { PasswordValidator } from 'src/app/validators/password.validator';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(errorsService, _snackBar, formBuilder, router, snackBar, authService) {
        this.errorsService = errorsService;
        this._snackBar = _snackBar;
        this.formBuilder = formBuilder;
        this.router = router;
        this.snackBar = snackBar;
        this.authService = authService;
        this.hide = true;
        this.initForm();
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    };
    ChangePasswordComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            currentPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required]],
            confirmNewPassword: [null, [Validators.required]],
        }, {
            validators: PasswordValidator('newPassword', 'confirmNewPassword'),
        });
    };
    ChangePasswordComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    emailAddress: this.userInfo.emailAddress,
                    password: this.formGroup.value.currentPassword,
                    newPassword: this.formGroup.value.newPassword,
                };
                this.authService.passwordUpdate(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('A senha foi alterada com sucesso.');
                        this.getStores();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ChangePasswordComponent.prototype.getStores = function () {
        var _this = this;
        this.authService.getStoresByUser().subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (res) {
                    localStorage.setItem('storesUser', JSON.stringify(res));
                    localStorage.setItem('storeSelected', JSON.stringify(res[0]));
                    this.router.navigate(['./dashboard/home']);
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
