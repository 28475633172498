import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AccountBankService {

  public loading;

  constructor(private http: HttpClient) { }

  list(storeID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/${storeID}/bank-account`)
      .pipe(
        tap(_ => this.log('/bank-account')),
        catchError(this.handleError)
      )
  }

  add(data, storeID): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/stores/${storeID}/bank-account`, data)
      .pipe(
        tap(_ => this.log('cms/bank-accoun')),
        catchError(this.handleError)
      )
  }

  delete(accountID, storeID): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/stores/${storeID}/bank-account/${accountID}`)
      .pipe(
        tap(_ => this.log('cms/bank-accoun')),
        catchError(this.handleError)
      )
  }


  listBankCodes(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/bank-codes`)
      .pipe(
        tap(_ => this.log('/bank-codes')),
        catchError(this.handleError)
      )
  }

  private handleError(error: any) { 
    return throwError(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
  }

  
}
