import { Component, OnInit } from '@angular/core'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ModalFormNotificationQuizComponent } from '../../../../components/modal-form-notification-quiz/modal-form-notification-quiz.component'
import { QuizService } from '../../../../services/quiz.service'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-quiz-ist',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.scss'],
})
export class QuizListComponent implements OnInit {
  public headersTable: string[]
  public pageTotal: number
  public customerID = ''
  dataSource: any
  fieldsForm: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quizService: QuizService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        this.initForm()
      } else {
        this.initForm()
        this.quizList()
      }
    })
  }

  initForm () {
    this.headersTable = ['name', 'amountQuestions', 'actions']
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameQuiz',
        label: 'Questionário',
        placeholder: 'Questionário',
      },
    ]
  }

  quizList () {
    this.quizService.list(`?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`).then(
      async response => {
        this.pageTotal = response.total
        localStorage.setItem('quiz', JSON.stringify(response.results))
        this.dataSource = new MatTableDataSource(response.results)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  sendNotification (id, name) {
    this.dialog.open(ModalFormNotificationQuizComponent, {
      data: {
        id: id,
        name: name,
      },
    })
  }

  remove (id) {
    let text
    let idTrigger
      ; (async () => {
      await this.quizService.getTrigger().then(
        response => {
          const idt = response.map(c => c.quizId)
          idTrigger = idt[0]
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
      if (idTrigger === id) {
        text = 'Este questionário está vinculado no Gatilho, deseja remover ?'
      } else {
        text = 'Deseja remover esse Questionário ?'
      }

      this.dialog
        .open(ModalConfirmComponent, {
          width: '500px',
          data: {
            text: text,
            buttonConfirmText: 'Remover',
          },
        })
        .afterClosed()
        .toPromise()
        .then(async result => {
          if (result && result !== 'cancel') {
            return this.quizService.delete(id).then(
              async response => {
                this.quizList()
                this.snackBar.open('Questionário removido com sucesso.')
              },
              error => {
                this.snackBar.open(error.error.message)
              },
            )
          }
        })
        .catch(err => {
          this.snackBar.open(err.message)
        })
    })().catch(error => this.snackBar.open(error.error.message))
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
