import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { StoreListComponent } from './store/store-list/store-list.component'
import { StoreFormComponent } from './store/store-form/store-form.component'
import { AuthGuardService } from 'src/app/guards/auth-guard.service'
import { HomeComponent } from './home/home.component'
import { StoreUserFormComponent } from './store/store-user-form/store-user-form.component'
import { StoreUserListComponent } from './store/store-user-list/store-user-list.component'
import { StoreTransactionListComponent } from './store/store-transaction-list/store-transaction-list.component'
import { StoreTransactionFormComponent } from './store/store-transaction-form/store-transaction-form.component'
import { UserFormComponent } from './users/user-form/user-form.component'
import { StoreQrcodeComponent } from './store/store-qrcode/store-qrcode.component'
import { ConfigsFormComponent } from './configs/configs-form/configs-form.component'
import { RegulationFormComponent } from './regulation/regulation-form/regulation-form.component'
import { RegulationListComponent } from './regulation/regulation-list/regulation-list.component'
import { CategoriesListComponent } from './categories/categories-list/categories-list.component'
import { CategoriesFormComponent } from './categories/categories-form/categories-form.component'
import { ClientListComponent } from './clients/client-list/client-list.component'
import { TermsListComponent } from './terms/terms-list/terms-list.component'
import { TermsFormComponent } from './terms/terms-form/terms-form.component'
import { StoreBankAccountListComponent } from './store/store-bank-account-list/store-bank-account-list.component'
import { StoreBankAccountFormComponent } from './store/store-bank-account-form/store-bank-account-form.component'
import { StoreDocumentsListComponent } from './store/store-documents-list/store-documents-list.component'
import { StoreDocumentsFormComponent } from './store/store-documents-form/store-documents-form.component'
import { StoreTransactionDetailComponent } from './store/store-transaction-detail/store-transaction-detail.component'
import { ClientFormAddComponent } from './clients/client-form-add/client-form-add.component'
import { ClientFormUpdateComponent } from './clients/client-form-update/client-form-update.component'
import { ReceivablesListComponent } from './receivables/receivables-list/receivables-list.component'
import { ClosingsListComponent } from './closings/closings-list/closings-list.component'
import { PrepaymentsListComponent } from './prepayments/prepayments-list/prepayments-list.component'
import { ReportsFormComponent } from './reports/reports-form/reports-form.component'
import { StoreSellsReportComponent } from './store/store-sells-report/store-sells-report.component'
import { ChangePasswordComponent } from '../auth/change-password/change-password.component'
import { StoreManualTransactionFormComponent } from './store/store-manual-transaction-form/store-manual-transaction-form'
import { StoreTransactionPendingListComponent } from './store/store-transaction-pending-list/store-transaction-pending-list.component'
import { ClosingsTransactionsListComponent } from './closings/closings-transactions-list/closings-transactions-list.component'
import { ClosingsStoreListComponent } from './closings/closings-store-list/closings-store-list.component'
import { FundsListComponent } from './funds/list/funds-list.component'
import { FundFormComponent } from './funds/form/fund-form.component'
import { FundAssociatesListComponent } from './funds/associates/list/associates-list.component'
import { FundAssociatesFormComponent } from './funds/associates/form/associates-form.component'
import { FundExtractComponent } from './funds/extract/extract.component'
import { FundTransactionsComponent } from './funds/transactions/transactions.component'
import { BusinessPartnersListComponent } from './business-partners/business-partners-list/business-partners-list.component'
import { BusinessPartnersFormComponent } from './business-partners/business-partners-form/business-partners-form.component'
import { NotificationListComponent } from './notifications/list/notification-list.component'
import { NoticationFormComponent } from './notifications/form/notication-form.component'
import { QuizListComponent } from './quiz/list/quiz-list.component'
import { QuizFormComponent } from './quiz/form/quiz-form.component'
import { QuestionsListComponent } from './quiz/questions/questions-list/questions-list.component'
import { QuestionsFormComponent } from './quiz/questions/questions-form/questions-form.component'
import { TriggerComponent } from './quiz/trigger/trigger.component'
import { QuizCustomerListComponent } from './quiz/quiz-customer-list/quiz-customer-list.component'
import { AnswerCustomerListComponent } from './quiz/answer-customer-list/answer-customer-list.component'
import { QuizClientListComponent } from './quiz/quiz-client-list/quiz-client-list.component'
import { QuestionsShowImageComponent } from './quiz/questions/questions-show-image/questions-show-image.component'
import { SegmentationListComponent } from './segmentations/list/segmentation-list.component'
import { SegmentationFormComponent } from './segmentations/form/segmentation-form.component'
import { SegmentationResultsComponent } from './segmentations/results/segmentation-results.component'
import { ClientAddressListComponent } from './clients/client-address/list/client-address-list.component'
import { ClientFormAddressComponent } from './clients/client-address/form/client-form-address.component'
import { NotificationCustomersComponent } from './notifications/notification-customers/notification-customers.component'
import { StorePaymentAnticipatedTransactionFormComponent } from './store/store-payment-anticipated-transaction-form/store-payment-anticipated-transaction-form.component'
import { StorePaymentTransactionsListComponent } from './store/store-payment-transactions-list/store-payment-transactions-list.component'
import { FundPointsFormComponent } from './funds/points-form/fund-points-form.component'
import { MembershipsPlansListComponent } from './memberships/plans-list/plans-list.component'
import { MembershipPlansFormComponent } from './memberships/plans-form/plans-form.component'
import { MembershipCustomersPlanListComponent } from './memberships/plan-customers-list/plan-customers-list.component'
import { MembershipPlanTransactionsCustomerListComponent } from './memberships/plan-transactions-customer-list/plan-transactions-customer-list.component'
import { MembershipPlansListCustomerComponent } from './memberships/plans-list-customer/plans-list-customer.component'
import { ClientMovementJcoinsComponent } from './clients/client-movement-jcoins/client-movement-jcoins.component'
import { subCategoriesListComponent } from './categories/subcategories-list/subcategories-list.component'
import { subCategoriesFormComponent } from './categories/subcategories-form/subcategories-form.component'
import { AirportsListComponent } from './airports/airports-list/airports-list.component'
import { AirportsFormComponent } from './airports/airports-form/airports-form.component'
import { AirportInformationsListComponent } from './airports/information-list/airport-informations-list.component'
import { AirportInformationFormComponent } from './airports/information-form/information-form.component'
import { AirportInfoFieldsListComponent } from './airports/info-fields-list/airport-info-fields-list.component'
import { AirportInfoFieldFormComponent } from './airports/info-field-form/info-field-form.component'
import { ExclusiveListComponent } from './categories/exclusive-list/exclusive-list.component'
import { ExclusiveFormComponent } from './categories/exclusive-form/exclusive-form.component'
import { MacroGroupListComponent } from './macro-group/list/macro-group-list.component'
import { MacroGroupFormComponent } from './macro-group/form/macro-group-form.component'
import { PreRegistrationListComponent } from './pre-registration/pre-registration-list/pre-registration-list.component'
import { PreRegistrationFormComponent } from './pre-registration/pre-registration-form/pre-registration-form.component'
import { preRegistrationAddressFormComponent } from './pre-registration/pre-registration-address/form/pre-registration-address-form.component'
import { PreRegistrationAddressListComponent } from './pre-registration/pre-registration-address/list/pre-registration-address-list.component'
import { ClientKindListComponent } from './clients/client-kind/list/client-kind-list.component'
import { ClientKindFormComponent } from './clients/client-kind/form/client-kind-form.component'
import { CustomerAttendantListComponent } from './customer-attendant/list/customer-attendant-list.component'
import { CustomerAttendantFormComponent } from './customer-attendant/form/customer-attendant-form.component'
import { ClientImportComponent } from './clients/client-import/client-import.component'
import { BeneficiaryListComponent } from './beneficiary/list/beneficiary.component'
import { BeneficiaryFormComponent } from './beneficiary/form/beneficiary.component'
import { CampaignComponent } from './campaigns/campaigns-list/campaign.component'
import { CampaignsFormComponent } from './campaigns/campaigns-form/campaigns-form.component'
import { StoreCampaignReportComponent } from './store/store-campaign-report/store-campaign-report.component'
import { IntegrationsListComponent } from './incorporations/list/integrations.component'
import { ClosingsIncorporationsComponent } from './incorporations/closings/closings-incorporations.component'
import { ClosingsIncorporationsIntegrationsComponent } from './incorporations/closings-incorporations/closings-incorporations-integrations.component'
import { FinancialReceivablesListComponent } from './financial-receivables/financial-receivables-list/financial-receivables-list.component'
import { NotificationTypesListComponent } from './notifications/types-list/notification-types-list.component'
import { NoticationTypesFormComponent } from './notifications/types-form/notication-types-form.component'
import { DocumentsNotIntegrationListComponent } from './incorporations/documents-not-integration/list/documents-not-integration.component'
import { DocumentsNotIntegrationFormComponent } from './incorporations/documents-not-integration/form/documents-not-integration.component'
import { AirportCompanyListComponent } from './airports/company/list/airport-company-list.component'
import { preRegisterCompaniesAirportsComponent } from './airports/company/pre-register-companies-airports/pre-register-companies-airports.component'
import { AirportCompanyFormComponent } from './airports/company/form/airport-company-form.component'
import { AirportCompanyCustomersListComponent } from './airports/company/list-customers/airport-company-customers-list.component'
import { AirportCompanyCustomerFormComponent } from './airports/company/form-customer/airport-company-customer-form.component'
import { AirportCompanyHistoricStoresComponent } from './airports/company/historic-stores/airport-company-historic-stores.component'
import { PreRegistrationHistoricStoresComponent } from './pre-registration/pre-registration-historic-stores/pre-registration-historic-stores.component'
import { ClientExportToZoopComponent } from './clients/client-export-to-zoop/client-export-to-zoop'
import { StoreExportToZoopComponent } from './store/store-export-to-zoop/store-export-to-zoop'
import { BannerListComponent } from './banners/banner-list/banner-list.component'
import { BannerFormComponent } from './banners/banner-form/banner-form.component'
import { BannerPagesAppListComponent } from './banners-pages-app/banner-pages-app-list/banner-pages-app-list.component'
import { BannerPagesAppFormComponent } from './banners-pages-app/banner-pages-app-form/banner-pages-app-form.component'
import { ArchitectCompanyFormComponent } from './architects/company/form/architect-company-form.component'
import { ArchitectCompanyListComponent } from './architects/company/list/architect-company-list.component'
import { ArchitectCompanyCustomersListComponent } from './architects/company/list-architects/architect-company-customers-list.component'
import { ArchitectsCompanyCustomerFormComponent } from './architects/company/form-architects/architect-company-customer-form.component'
import { ArchitectInvoiceFormComponent } from './architects/architect-invoice/architect-invoice-form/architect-invoice-form.component'
import { ArchitectInvoiceListComponent } from './architects/architect-invoice/architect-invoice-list/architect-invoice-list.component'
import { ArchitectStoresListComponent } from './architects/stores/list/architect-stores-list.component'
import { ArchitectStoresFormComponent } from './architects/stores/form/architect-stores-form.component'
import { ArchitectsStoreUserFormComponent } from './architects/stores/user-form/architects-store-user-form.component'
import { ArchitectStoresUserListComponent } from './architects/stores/users-list/architect-stores-user-list.component'
import { ArchitectInvoiceListAdminComponent } from './architects/architect-invoice/architect-invoice-list-admin/architect-invoice-list-admin.component'
import { ArchitectInvoiceDetailComponent } from './architects/architect-invoice/architect-invoice-detail/architect-invoice-detail.component'
import { ArchitectClosingList } from './architects/architect-closings/architect-closings-list/architect-closings-list.component'
import { ArchitectClosingForm } from './architects/architect-closings/architect-closings-form/architect-closings-form.component'
import { ArchitectClosingDetails } from './architects/architect-closings/architect-closing-details/architect-closing-details.component'
import { ArchitectClosingListForStore } from './architects/architect-closings/architect-closing-list-for-store/architect-closing-list-for-store.component'
import { ClientTermFormComponent } from './clients/client-terms/form/client-term-form.component'
import { ClientTermsListComponent } from './clients/client-terms/list/client-terms-list.component'
import { ClientExportToSalesforceComponent } from './clients/client-export-to-salesforce/client-export-to-salesforce'
import { LiquidationTransferSchedulesList } from './liquidation-transfer/liquidation-transfer-schedules-list/liquidation-transfer-schedules-list.component'
import { LiquidationTransferScheduleDetails } from './liquidation-transfer/schedule-details/schedule-details.component'
import { LiquidationTransactionsList } from './liquidation-transfer/liquidation-transactions-list/liquidation-transactions-list.component'
import { StoreInternationalTransactionFormComponent } from './store/store-international-transaction-form/store-international-transaction-form.component'
import { LiquidationTransferPermissions } from './liquidation-transfer/liquidation-transfer-permissions/liquidation-transfer-permissions.component'
import { StoreLiquidationTransfersComponent } from './liquidation-transfer/store-liquidation-transfers/store-liquidation-transfers.component'
import { LiquidationTransferSettings } from './liquidation-transfer/liquidation-transfer-settings/liquidation-transfer-settings.component'
import { DocumentsEligibleListComponent } from './airports/documents-eligible/list/documents-eligible.component'
import { DocumentsEligibleFormComponent } from './airports/documents-eligible/form/documents-eligible-form.component'
import { AirportsIntegrationsListComponent } from './airports/list-integrations/airports-integrations.component'
import { AirportsIntegrationsNotEligibleListComponent } from './airports/list-integrations-not-eligible/airports-integrations-not-eligible.component'
import { AirportsStoresTaxCashbackFormComponent } from './airports/stores-tax-cashback/form/airports-stores-tax-cashback-form.component'
import { AirportsStoresTaxCashbackListComponent } from './airports/stores-tax-cashback/list/airports-stores-tax-cashback-list.component'
import { PaymentProviderCardFeeListComponent } from './configs/payment-provider-card-fees/payment-provider-card-fee-list/payment-provider-card-fee-list.component'
import { PaymentProviderCardFeeFormComponent } from './configs/payment-provider-card-fees/payment-provider-card-fee-form/payment-provider-card-fee-form.component'
import { ConsolidatedJcoinsComponent } from './reports/reports-consolidated/reports-consolidated-jcoins.component'
import { BillPaymentNotificationsComponent } from './bill-payment-notifications/bill-payment-notifications.component'
import { StoreIntFormComponent } from './store/store-int-form/store-int-form.component'
import { ManualJcoinCreditListComponent } from './manual-jcoin-credit/list/list.component'
import { ManualJcoinCreditFormComponent } from './manual-jcoin-credit/request-form/request-form.component'
import { ClosingsAirportsComponent } from './airports/closings-airports/closings-airports.component'

const routes: Routes = [
  {
    path: 'auth/change-password',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ChangePasswordComponent,
  },
  {
    path: 'dashboard/campaign',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CampaignComponent,
  },
  {
    path: 'dashboard/campaign/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CampaignsFormComponent,
  },
  {
    path: 'dashboard/campaign/:campaignID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CampaignsFormComponent,
  },
  {
    path: 'dashboard/home',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'dashboard/regulations',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: RegulationListComponent,
  },
  {
    path: 'dashboard/regulations/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: RegulationFormComponent,
  },
  {
    path: 'dashboard/regulations/:regulationID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: RegulationFormComponent,
  },
  {
    path: 'dashboard/terms',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: TermsListComponent,
  },
  {
    path: 'dashboard/terms/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: TermsFormComponent,
  },
  {
    path: 'dashboard/configs',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ConfigsFormComponent,
  },
  {
    path: 'dashboard/reports',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ReportsFormComponent,
  },
  {
    path: 'dashboard/transactions',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreTransactionListComponent,
  },
  {
    path: 'dashboard/stores',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreListComponent,
  },
  {
    path: 'dashboard/stores/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreFormComponent,
  },
  {
    path: 'dashboard/stores/new/:cnpj',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreFormComponent,
  },
  {
    path: 'dashboard/stores/new-international',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreIntFormComponent,
  },
  {
    path: 'dashboard/stores/new-international/:cnpj',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreIntFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/documents',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreDocumentsListComponent,
  },
  {
    path: 'dashboard/stores/:storeID/documents/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreDocumentsFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/bank-account',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreBankAccountListComponent,
  },
  {
    path: 'dashboard/stores/:storeID/bank-account/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreBankAccountFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreTransactionListComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions/pending',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreTransactionPendingListComponent,
  },
  {
    path: 'dashboard/transactions/detail/:transactionID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreTransactionDetailComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreTransactionFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions/new-international',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreInternationalTransactionFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions/new-manual',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreManualTransactionFormComponent,
  },
  {
    path: 'dashboard/store/:storeID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreFormComponent,
  },
  // Update
  {
    path: 'dashboard/international-store/:storeID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreIntFormComponent,
  },

  {
    path: 'dashboard/store/:storeID/qrcode',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreQrcodeComponent,
  },
  {
    path: 'dashboard/stores/:storeID/users',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreUserListComponent,
  },
  {
    path: 'dashboard/stores/:storeID/users/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreUserFormComponent,
  },
  {
    path: 'dashboard/stores/:storeID/reports',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreCampaignReportComponent,
  },
  {
    path: 'dashboard/users/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: UserFormComponent,
  },
  {
    path: 'dashboard/users/new/:email',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: UserFormComponent,
  },
  {
    path: 'dashboard/users/new/:email/stores/:storeID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: UserFormComponent,
  },
  {
    path: 'dashboard/users/:userID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: UserFormComponent,
  },
  {
    path: 'dashboard/users/:userID/edit/stores/:storeID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: UserFormComponent,
  },
  {
    path: 'dashboard/categories',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CategoriesListComponent,
  },
  {
    path: 'dashboard/categories/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CategoriesFormComponent,
  },
  {
    path: 'dashboard/categories/:categoriesID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CategoriesFormComponent,
  },
  {
    path: 'dashboard/clients',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientListComponent,
  },
  {
    path: 'dashboard/clients-architect/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientListComponent,
  },
  {
    path: 'dashboard/clients/export-to-zoop',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientExportToZoopComponent,
  },
  {
    path: 'dashboard/clients/export-to-salesforce',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientExportToSalesforceComponent,
  },
  {
    path: 'dashboard/stores/export-to-zoop',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreExportToZoopComponent,
  },
  {
    path: 'dashboard/clients/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientFormAddComponent,
  },
  {
    path: 'dashboard/clients/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientFormUpdateComponent,
  },
  {
    path: 'dashboard/clients/:customerID/edit/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientFormUpdateComponent,
  },
  {
    path: 'dashboard/manual-jcoin-credit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ManualJcoinCreditListComponent,
  },
  {
    path: 'dashboard/manual-jcoin-credit/request/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ManualJcoinCreditFormComponent,
  },
  {
    path: 'dashboard/receivables',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ReceivablesListComponent,
  },
  {
    path: 'dashboard/financial-receivables',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: FinancialReceivablesListComponent,
  },
  {
    path: 'dashboard/prepayments',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PrepaymentsListComponent,
  },
  {
    path: 'dashboard/store-reports/sells',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreSellsReportComponent,
  },
  {
    path: 'dashboard/closings',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsListComponent,
  },
  {
    path: 'dashboard/closings/:closingsID/transactions',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsTransactionsListComponent,
  },
  {
    path: 'dashboard/closings/:closingsID/stores',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsStoreListComponent,
  },
  {
    path: 'dashboard/funds',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundsListComponent,
  },
  {
    path: 'dashboard/funds/form',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundFormComponent,
  },
  {
    path: 'dashboard/funds/form/:fundId',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundFormComponent,
  },
  {
    path: 'dashboard/funds/:fundId/associates',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundAssociatesListComponent,
  },
  {
    path: 'dashboard/funds/:fundId/associates/form',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundAssociatesFormComponent,
  },
  {
    path: 'dashboard/funds/:fundId/extract',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundExtractComponent,
  },
  {
    path: 'dashboard/funds/:fundId/transactions',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundTransactionsComponent,
  },
  {
    path: 'dashboard/funds/:fundId/points',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundPointsFormComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/business-partners',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BusinessPartnersListComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/business-partners/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BusinessPartnersFormComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/business-partners/form/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BusinessPartnersFormComponent,
  },
  {
    path: 'dashboard/notifications',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NotificationListComponent,
  },
  {
    path: 'dashboard/notifications-architect/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NotificationListComponent,
  },
  {
    path: 'dashboard/notifications/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationFormComponent,
  },
  {
    path: 'dashboard/notifications-architect/form/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationFormComponent,
  },
  {
    path: 'dashboard/notifications/form/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationFormComponent,
  },
  {
    path: 'dashboard/notifications-architect/form/:architect/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationFormComponent,
  },
  {
    path: 'dashboard/quiz/list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizListComponent,
  },
  {
    path: 'dashboard/quiz/list/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizListComponent,
  },
  {
    path: 'dashboard/quiz/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizFormComponent,
  },
  {
    path: 'dashboard/quiz/form/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizFormComponent,
  },
  {
    path: 'dashboard/quiz/:quizID/questions/list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuestionsListComponent,
  },
  {
    path: 'dashboard/quiz/:quizID/questions/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuestionsFormComponent,
  },
  {
    path: 'dashboard/quiz/:quizID/questions/form/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuestionsFormComponent,
  },
  {
    path: 'dashboard/quiz/trigger',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: TriggerComponent,
  },
  {
    path: 'dashboard/quiz/customer/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizCustomerListComponent,
  },

  {
    path: 'dashboard/quiz/:quizID/answers/customer/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AnswerCustomerListComponent,
  },
  {
    path: 'dashboard/quiz/:quizID/client',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuizClientListComponent,
  },
  {
    path: 'dashboard/quiz/image',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: QuestionsShowImageComponent,
  },
  {
    path: 'dashboard/segmentation',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: SegmentationListComponent,
  },
  {
    path: 'dashboard/segmentation/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: SegmentationFormComponent,
  },
  {
    path: 'dashboard/segmentation/form/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: SegmentationFormComponent,
  },
  {
    path: 'dashboard/segmentation/:segmentationId/customers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: SegmentationResultsComponent,
  },
  {
    path: 'dashboard/clients/:customerID/form/address',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientFormAddressComponent,
  },
  {
    path: 'dashboard/clients/:customerID/form/address/:addressID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientFormAddressComponent,
  },
  {
    path: 'dashboard/clients/:customerID/list/address',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientAddressListComponent,
  },
  {
    path: 'dashboard/clients/:customerID/list/address/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientAddressListComponent,
  },
  {
    path: 'dashboard/notifications/:notificationID/customers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NotificationCustomersComponent,
  },
  {
    path: 'dashboard/notifications/:notificationID/customers/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NotificationCustomersComponent,
  },
  {
    path: 'dashboard/stores/:storeID/transactions/payment-anticipated',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StorePaymentAnticipatedTransactionFormComponent,
  },
  {
    path: 'dashboard/transactions/payment/:paymentID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StorePaymentTransactionsListComponent,
  },
  {
    path: 'dashboard/memberships/plans',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipsPlansListComponent,
  },
  {
    path: 'dashboard/memberships/plan/form',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipPlansFormComponent,
  },
  {
    path: 'dashboard/memberships/plan/form/:planID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipPlansFormComponent,
  },
  {
    path: 'dashboard/memberships/plan/:planID/customers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipCustomersPlanListComponent,
  },
  {
    path: 'dashboard/memberships/transactions/plan/:planID/customer/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipPlanTransactionsCustomerListComponent,
  },
  {
    path: 'dashboard/memberships/plans/customer/:customerID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipPlansListCustomerComponent,
  },
  {
    path: 'dashboard/client/:customerID/movement-jcoins',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientMovementJcoinsComponent,
  },
  {
    path: 'dashboard/client/:customerID/movement-jcoins/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientMovementJcoinsComponent,
  },
  {
    path: 'dashboard/clients/:customerID/info',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientListComponent,
  },
  {
    path: 'dashboard/funds/:fundId/associate/client/:customerId',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    component: FundAssociatesListComponent,
  },
  {
    path: 'dashboard/memberships/plans/:planID',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MembershipsPlansListComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/subcategories',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: subCategoriesListComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/subcategories/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: subCategoriesFormComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/subcategories/:subCategoryID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: subCategoriesFormComponent,
  },
  {
    path: 'dashboard/airports',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsListComponent,
  },
  {
    path: 'dashboard/airports/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsFormComponent,
  },
  {
    path: 'dashboard/airports/:airportID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsFormComponent,
  },
  {
    path: 'dashboard/airport/:airportID/informations',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInformationsListComponent,
  },
  {
    path: 'dashboard/airport/:airportID/information/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInformationFormComponent,
  },
  {
    path: 'dashboard/airport/information/:informationID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInformationFormComponent,
  },
  {
    path: 'dashboard/airport/:airportID/information/:informationID/fields',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInfoFieldsListComponent,
  },
  {
    path: 'dashboard/airport/:airportID/information/:informationID/field/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInfoFieldFormComponent,
  },
  {
    path: 'dashboard/airport/:airportID/information/:informationID/field/:fieldID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportInfoFieldFormComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/exclusives',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ExclusiveListComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/exclusive/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ExclusiveFormComponent,
  },
  {
    path: 'dashboard/categories/:categoryID/exclusive/:exclusiveID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ExclusiveFormComponent,
  },
  {
    path: 'dashboard/macro-group',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MacroGroupListComponent,
  },
  {
    path: 'dashboard/macro-group/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MacroGroupFormComponent,
  },
  {
    path: 'dashboard/macro-group/:macroGroupID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: MacroGroupFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationListComponent,
  },
  {
    path: 'dashboard/pre-registration',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationListComponent,
  },
  {
    path: 'dashboard/pre-registration-architects/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationListComponent,
  },
  {
    path: 'dashboard/pre-registration/:type/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationFormComponent,
  },
  {
    path: 'dashboard/pre-registration-architect/:type/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/edit/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/address/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: preRegistrationAddressFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/address/:addressID/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: preRegistrationAddressFormComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/address',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationAddressListComponent,
  },
  {
    path: 'dashboard/pre-registration/:preRegistrationID/address/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationAddressListComponent,
  },
  {
    path: 'dashboard/client-kind',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientKindListComponent,
  },
  {
    path: 'dashboard/client-kind/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientKindFormComponent,
  },
  {
    path: 'dashboard/client-kind/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientKindFormComponent,
  },
  {
    path: 'dashboard/attendant',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CustomerAttendantListComponent,
  },
  {
    path: 'dashboard/attendant/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CustomerAttendantFormComponent,
  },
  {
    path: 'dashboard/attendant/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: CustomerAttendantFormComponent,
  },
  {
    path: 'dashboard/client-import',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientImportComponent,
  },
  {
    path: 'dashboard/beneficiary',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BeneficiaryListComponent,
  },
  {
    path: 'dashboard/beneficiary/customer/:customerId/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BeneficiaryFormComponent,
  },
  {
    path: 'dashboard/beneficiary/:id/customer/:customerId/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BeneficiaryFormComponent,
  },
  {
    path: 'dashboard/integrations',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: IntegrationsListComponent,
  },
  {
    path: 'dashboard/closings-incorporations',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsIncorporationsComponent,
  },
  {
    path: 'dashboard/closings-incorporations-integrations/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsIncorporationsIntegrationsComponent,
  },
  {
    path: 'dashboard/notification-types',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NotificationTypesListComponent,
  },
  {
    path: 'dashboard/notification-types/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationTypesFormComponent,
  },
  {
    path: 'dashboard/notification-types/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: NoticationTypesFormComponent,
  },
  {
    path: 'dashboard/documents-not-integration',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsNotIntegrationListComponent,
  },
  {
    path: 'dashboard/documents-not-integration/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsNotIntegrationFormComponent,
  },
  {
    path: 'dashboard/documents-not-integration/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsNotIntegrationFormComponent,
  },
  {
    path: 'dashboard/airport-companies',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyListComponent,
  },
  {
    path: 'dashboard/pre-register-airports-companies',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: preRegisterCompaniesAirportsComponent,
  },
  {
    path: 'dashboard/airport-company/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyFormComponent,
  },
  {
    path: 'dashboard/airport-company/pre-register/:preRegisterId/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyFormComponent,
  },
  {
    path: 'dashboard/airport-company/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyFormComponent,
  },
  {
    path: 'dashboard/airport-company/:id/customers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyCustomersListComponent,
  },
  {
    path: 'dashboard/airport-company/:id/customer/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyCustomerFormComponent,
  },
  {
    path: 'dashboard/airport-company/pre-register/:preRegisterId/customer/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyCustomerFormComponent,
  },
  {
    path: 'dashboard/airport-company/:id/historic-stores/:cnpj',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportCompanyHistoricStoresComponent,
  },
  // {
  //   path: 'dashboard/pre-registration/historic-stores/:cpf/:type',
  //   canActivate: [AuthGuardService],
  //   pathMatch: 'full',
  //   component: PreRegistrationHistoricStoresComponent,
  // },
  {
    path: 'dashboard/pre-registration/historic-stores/:cpf',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PreRegistrationHistoricStoresComponent,
  },
  {
    path: 'dashboard/banners/:page/:idPage',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerListComponent,
  },
  {
    path: 'dashboard/bill-payment-notifications',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BillPaymentNotificationsComponent,
  },
  {
    path: 'dashboard/banner/:page/:idPage/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerFormComponent,
  },
  {
    path: 'dashboard/banner/:id/:page/:idPage/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerFormComponent,
  },
  {
    path: 'dashboard/banners-pages-app',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerPagesAppListComponent,
  },
  {
    path: 'dashboard/banner-page/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerPagesAppFormComponent,
  },
  {
    path: 'dashboard/banner-page/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: BannerPagesAppFormComponent,
  },
  {
    path: 'dashboard/architect-company/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectCompanyFormComponent,
  },
  {
    path: 'dashboard/architect-company/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectCompanyFormComponent,
  },
  {
    path: 'dashboard/architect-companies',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectCompanyListComponent,
  },
  {
    path: 'dashboard/architect-company/:id/customers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectCompanyCustomersListComponent
    ,
  },
  {
    path: 'dashboard/architect-company/:id/customers/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectsCompanyCustomerFormComponent
    ,
  },
  {
    path: 'dashboard/architect-company/:id/customers/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectsCompanyCustomerFormComponent,
  },
  {
    path: 'dashboard/architect-invoice/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectInvoiceFormComponent,
  },
  {
    path: 'dashboard/architect-invoice/:architectInvoiceId/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectInvoiceFormComponent,
  },
  {
    path: 'dashboard/architect-invoice/:architectInvoiceId/detail',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectInvoiceDetailComponent,
  },
  {
    path: 'dashboard/architect-invoice-list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectInvoiceListComponent,
  },
  {
    path: 'dashboard/admin-architect-invoice-list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectInvoiceListAdminComponent,
  },
  {
    path: 'dashboard/architect-stores',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectStoresListComponent
    ,
  },
  {
    path: 'dashboard/architect-store/new/:cnpj',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectStoresFormComponent
    ,
  },
  {
    path: 'dashboard/architect-store/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectStoresFormComponent
    ,
  },
  {
    path: 'dashboard/architect-store/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectStoresFormComponent
    ,
  },
  {
    path: 'dashboard/architect-stores/:id/users/new/:email',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectsStoreUserFormComponent
    ,
  },
  {
    path: 'dashboard/architect-stores/:id/users/:userId/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectsStoreUserFormComponent
    ,
  },
  {
    path: 'dashboard/architect-store-users/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectStoresUserListComponent
    ,
  },
  {
    path: 'dashboard/architect-closings/list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectClosingList,
  },
  {
    path: 'dashboard/architect-closings/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectClosingForm,
  },
  {
    path: 'dashboard/architect-closings/:id',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectClosingDetails,
  },
  {
    path: 'dashboard/architect-closings/store/list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ArchitectClosingListForStore,
  },
  {
    path: 'dashboard/customer/:document/term/new/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientTermFormComponent,
  },
  {
    path: 'dashboard/customer/:document/term/:id/edit/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientTermFormComponent,
  },
  {
    path: 'dashboard/customer/:document/terms',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientTermsListComponent,
  },
  {
    path: 'dashboard/customer/:document/terms/:type',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClientTermsListComponent,
  },
  // {
  //   path: 'dashboard/reports/fund-jcoins',
  //   canActivate: [AuthGuardService],
  //   pathMatch: 'full',
  //   component: ReportsFundJcoinsComponent,
  // },
  // {
  //   path: 'dashboard/reports/stock-closing',
  //   canActivate: [AuthGuardService],
  //   pathMatch: 'full',
  //   component: ReportsStockClosingComponent,
  // },
  {
    path: 'dashboard/liquidation-transfer-schedules-list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: LiquidationTransferSchedulesList,
  },
  {
    path: 'dashboard/liquidation-transfer-schedule/details/:scheduleId',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: LiquidationTransferScheduleDetails,
  },
  {
    path: 'dashboard/liquidation-transctions/:scheduleId',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: LiquidationTransactionsList,
  },
  {
    path: 'dashboard/liquidation-transfer-permissions',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: LiquidationTransferPermissions,
  },
  {
    path: 'dashboard/store-liquidation-transfers',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: StoreLiquidationTransfersComponent,
  },
  {
    path: 'dashboard/liquidation-transfer-settings',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: LiquidationTransferSettings,
  },
  {
    path: 'dashboard/reports/:architect',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ReportsFormComponent,
  },
  {
    path: 'dashboard/airports/not-eligible',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsEligibleListComponent,
  },
  {
    path: 'dashboard/airports/not-eligible/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsEligibleFormComponent,
  },
  {
    path: 'dashboard/airports/not-eligible/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: DocumentsEligibleFormComponent,
  },
  {
    path: 'dashboard/airports/integrations',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsIntegrationsListComponent,
  },
  {
    path: 'dashboard/airports/integrations/not-eligible',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsIntegrationsNotEligibleListComponent,
  },
  {
    path: 'dashboard/airports/tax-cashback',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsStoresTaxCashbackListComponent,
  },
  {
    path: 'dashboard/airports/tax-cashback/new',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsStoresTaxCashbackFormComponent,
  },
  {
    path: 'dashboard/airports/tax-cashback/:id/edit',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: AirportsStoresTaxCashbackFormComponent,
  },
  {
    path: 'dashboard/payment-provider-card-fee-list',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PaymentProviderCardFeeListComponent,
  },
  {
    path: 'dashboard/payment-provider-card-fee-add',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: PaymentProviderCardFeeFormComponent,
  },
  {
    path: 'dashboard/reports/consolidated/stock-jcoins',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ConsolidatedJcoinsComponent,
  },
  {
    path: 'dashboard/airports/closings-airports',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    component: ClosingsAirportsComponent,
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
