/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/logged/logged.component.ngfactory";
import * as i3 from "../../../components/logged/logged.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../services/permissions.service";
import * as i7 from "../../../services/loader.service";
import * as i8 from "../../../services/manual-jcoin-credit.service";
import * as i9 from "./home.component";
import * as i10 from "../../../services/dashboard.service";
import * as i11 from "@angular/material/snack-bar";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartM: 0 }), i1.ɵqud(402653184, 2, { chartY: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "app-logged", [["class", "home"]], null, null, null, i2.View_LoggedComponent_0, i2.RenderType_LoggedComponent)), i1.ɵdid(3, 245760, null, 0, i3.LoggedComponent, [i4.Router, i5.MatDialog, i6.PermissionsService, i7.LoaderService, i8.ManualJcoinCreditService], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "header", [["class", "home__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i9.HomeComponent, [i10.DashboardService, i11.MatSnackBar, i5.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i9.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
