import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Location } from '@angular/common';
import JsFileDownloader from 'js-file-downloader';
var ClosingsStoreListComponent = /** @class */ (function () {
    function ClosingsStoreListComponent(router, route, storeService, dialog, snackBar, location) {
        this.router = router;
        this.route = route;
        this.storeService = storeService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.location = location;
        this.headersTable = [
            'nomeFantasia',
            'totalValue',
            'netValue',
            'pointsApplied',
            'actions',
        ];
        this.stores = [];
    }
    ClosingsStoreListComponent.prototype.ngOnInit = function () {
        this.closingsID = this.route.snapshot.paramMap.get('closingsID');
        this.listStores();
    };
    ClosingsStoreListComponent.prototype.listStores = function () {
        var _this = this;
        this.storeService.listStoresByClosing(this.closingsID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.stores = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsStoreListComponent.prototype.export = function (storeID) {
        var _this = this;
        this.storeService.export(storeID, this.closingsID).subscribe(function (response) {
            if (response.report) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.report,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return ('Relatório de Fechamento por Parceiro.' +
                            response.report.split('.').splice(6, 6).join('.'));
                    },
                });
            }
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsStoreListComponent.prototype.goBack = function () {
        this.location.back();
    };
    ClosingsStoreListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ClosingsStoreListComponent.prototype.updateStatus = function () {
        this.listStores();
    };
    ClosingsStoreListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result > 0) {
                _this.router.navigate(['./dashboard/stores/new/' + result]);
            }
        });
    };
    return ClosingsStoreListComponent;
}());
export { ClosingsStoreListComponent };
