import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatSnackBar, MatTableDataSource } from "@angular/material";
import JsFileDownloader from "js-file-downloader";
import { ModalGenericComponent } from "src/app/components/modal-generic/modal-generic.component";
import { ArchitectClosingsService } from "src/app/services/architectClosings.service";

@Component({
  selector: 'architect-closings-list',
  templateUrl: './architect-closings-list.component.html',
  styleUrls: ['./architect-closings-list.component.scss'],
})
export class ArchitectClosingList implements OnInit {
  public headersTable: string[] = ['closingNumber', 'startDate', 'endDate', 'totalInvoices', 'totalInvoicesValue', 'actions']
  public closingsList

  pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor(
    private architectClosingsService: ArchitectClosingsService,
    private location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ){

  }

  ngOnInit() {
    this.loadClosings()
  }

  async loadClosings() {
    const { total, results } = await this.architectClosingsService.findAll({
      page: this.page,
      range: this.pageSize
    })

    this.pageTotal = total
    this.closingsList = new MatTableDataSource(results)
  }


  filterCallback (returnFilter) {
    const { total, results } = returnFilter
    this.pageTotal = total
    this.closingsList = new MatTableDataSource(results)    
  }

  goBack() {
    this.location.back()
  }

  async export(architectClosingId){
    try {
      const response = await this.architectClosingsService.exportClosing(architectClosingId)        
      if (response.report) {
        new JsFileDownloader({
          url: response.report,
          nameCallback: (fileName) => {               
            return (
              'Relatório de Fechamento Arquitetos.' +
              (fileName.split('.').pop() || 'xls')
            )
          },
        })
      }        
    } catch(error) {
        this.snackBar.open(error.error.message)
    }
        
  }

  async removeClosing(architectClosingId) {
    try{
      const result = await this.dialog.open(ModalGenericComponent, {
        width: '500px',
        data: {        
          text: 'Deseja remover esse fechamento?',
          buttonConfirmText: 'Remover'
        }
      }).afterClosed().toPromise()

      if(result && result !== 'cancel') {
        await this.architectClosingsService.removeClosing(architectClosingId)
        this.snackBar.open('Fechamento removido com sucesso')
        this.loadClosings()
      }
      
    } catch(err) {
      this.snackBar.open('Problema ao remover o fechamento')      
    }    
  }

  convertCentsToReal (value) {
    return value / 100
  }

}