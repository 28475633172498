<app-logged class="architect-invoice-form">
  <header class="architect-invoice-form__header">
    <h2 *ngIf="!architectInvoiceId">NOVA NOTA FISCAL</h2>
    <h2 *ngIf="architectInvoiceId">EDITAR NOTA FISCAL</h2>
    <div class="architect-invoice-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Número da nota</mat-label>
              <input matInput type="number" formControlName="invoiceNumber" />
              <mat-error *ngIf="formGroup.controls.invoiceNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.invoiceNumber) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Número de série</mat-label>
              <input matInput formControlName="invoiceSerial" />
            </mat-form-field>

            <mat-form-field [matTooltip]="updateTooltipMessage" matTooltipPosition="left">
              <mat-label>Valor total da nota</mat-label>
              <input
                currencyMask
                matInput
                formControlName="totalValue"
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.totalValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.totalValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field [matTooltip]="updateTooltipMessage" matTooltipPosition="left">
              <mat-label>Tipo do recebedor </mat-label>
              <mat-select formControlName="type" required (blur)="onTargetTypeChange()">
                <mat-option value="PF">Pessoa física</mat-option>
                <mat-option value="PJ">Pessoa jurídica</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field [matTooltip]="updateTooltipMessage" matTooltipPosition="left">
              <mat-label>
                Recebedor da comissao &nbsp;
                <mat-icon class="spin" *ngIf="loadingReceivers" style="position: absolute"
                  >loop</mat-icon
                >
              </mat-label>
              <input
                type="text"
                placeholder="Recebedor da comissao"
                matInput
                formControlName="targetDisplayName"
                [matAutocomplete]="auto"
              />

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let target of targets$ | async"
                  (blur)="onTargetChange(target)"
                  [value]="target"
                >
                  {{ getReceiverDisplayNames(target) }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="formGroup.controls.targetDocument?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.targetDocument) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="architectInvoiceId">
              <mat-label>Documento Atual</mat-label>
              <input matInput disabled />
              <span>
                <a *ngIf="currentPdfUrl != ''" target="_blank" [href]="currentPdfUrl">
                  <img
                    *ngIf="documentUrl != '' && documentUrl != null"
                    width="200"
                    src="{{ currentPdfUrl }}"
                    (error)="imgLoadError()"
                  />

                  <span *ngIf="documentUrl == null" class="filename-placeholder">
                    <mat-icon>description</mat-icon> {{ this.currentFilename }}
                  </span>
                </a>
              </span>
              <span *ngIf="!documentIsChanged">
                <button class="btn-primary-light" mat-button (click)="changeCurrentDocument()">
                  <mat-icon>swap_horiz</mat-icon> Trocar documento
                </button>
              </span>
            </mat-form-field>
            <mat-form-field *ngIf="!architectInvoiceId || documentIsChanged">
              <mat-label *ngIf="!documentIsChanged">Documento</mat-label>
              <mat-label *ngIf="documentIsChanged">Escolha um novo documento</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInput.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img
                *ngIf="documentUrl != '' && documentUrl != null"
                width="200"
                src="{{ documentUrl }}"
                (error)="imgLoadError()"
              />
              <span *ngIf="documentUrl == null && fileName" class="filename-placeholder">
                <mat-icon>description</mat-icon> {{ this.fileName }}
              </span>
              <span class="remove-doc-wrapper" *ngIf="!documentIsChanged">
                <button
                  class="btn-primary-light"
                  mat-button
                  *ngIf="fileName"
                  (click)="removeDocument()"
                >
                  <mat-icon>cancel</mat-icon> Remover documento
                </button>
              </span>
              <div class="remove-doc-wrapper" *ngIf="documentIsChanged">
                <button class="btn-primary-light" mat-button (click)="cancelDocumentChange()">
                  <mat-icon>cancel</mat-icon> Cancelar troca de documento
                </button>
              </div>
              <input hidden (change)="selectFile($event)" #fileInput type="file" id="file" />
            </mat-form-field>

            <p style="font-size: 12px; margin-bottom: 5px; margin-top: 0px">Observações</p>
            <ckeditor
              formControlName="observation"
              [config]="config"
              [editor]="Editor"
              (ready)="onReady($event)"
            ></ckeditor>
          </mat-card-content>
        </div>
      </div>

      <div class="architect-invoice-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
