import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ErrorsService } from 'src/app/core/services/errors.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit {
  public formGroup: FormGroup;

  public hide = true;
  public token: string = '';

  constructor(
    public errorsService: ErrorsService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    
  ) {
    
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      token: [this.token, [Validators.required]],
    });

    this.submit();
  }

  async submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Token inválido.');
      return false;
    }

    this.authService.emailConfirmation(this.formGroup.value).subscribe(
      async (response) => {
        this.snackBar.open('E-mail confirmado com sucesso.');
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }
}
