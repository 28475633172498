import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ErrorsService } from 'src/app/core/services/errors.service';

import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { TermService } from 'src/app/services/term.service';

@Component({
  selector: 'app-store-form',
  templateUrl: './terms-form.component.html',
  styleUrls: ['./terms-form.component.scss'],
})
export class TermsFormComponent implements OnInit {
  public formGroup: FormGroup;
  public states: Object[] = [];
  public plans: Object[] = [];
  public configs: Array<any> = [];
  public config: any;
  public Editor = ClassicEditor;

  constructor(
    public errorsService: ErrorsService,
    private formBuilder: FormBuilder,
    public termService: TermService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.initForm();
    this.config = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
    };
  }

  ngOnInit() {

  }

  initForm() {
    this.formGroup = this.formBuilder.group(
      {
        heading: [null, [Validators.required]],
        subtitle: [null, [Validators.required]],
        content: [null, [Validators.required]],
      },
    );

    //this.loadInfos();
  };

  public onReady( editor ) {
    console.log(editor);

  }

  loadInfos() {
    /**
     * this.configService.view().subscribe(
      async (response) => {
        this.configs = response;

        this.formGroup.patchValue({
          mainStoreCnpj: this.configs['results'].regulation,
        });
      },
      (error) => {
        console.log(error);
        alert(error.error.message);
      }
    );
     */
  }

  change({ editor }: ChangeEvent) {
    const EditorData = editor.getData();
    this.formGroup.get('content').setValue(EditorData);
  }



  async submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.termService.add(this.formGroup.value).subscribe(
      async (response) => {
        if (response.isCreated) {
          this.router.navigate(['./dashboard/terms']);
          this.snackBar.open('Termo criado com sucesso.');
        }
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }
}
