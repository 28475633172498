import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import JsFileDownloader from 'js-file-downloader';
var ModalCustomersUniqueComponent = /** @class */ (function () {
    function ModalCustomersUniqueComponent(errorsService, snackBar, formBuilder, reportService, dialogRef) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.kindInvalid = false;
    }
    ModalCustomersUniqueComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            endDate: [null, [Validators.required]],
            beginDate: [null, [Validators.required]],
            kind: [null, []],
        });
    };
    ModalCustomersUniqueComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalCustomersUniqueComponent.prototype.onChange = function () {
        this.kindInvalid = !this.formGroup.value.kind;
    };
    ModalCustomersUniqueComponent.prototype.submit = function () {
        var _this = this;
        this.kindInvalid = !this.formGroup.value.kind;
        if (!this.formGroup.valid || this.kindInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        if (this.formGroup.value.kind === 'subStore') {
            this.filter = "customer-unique?beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate + "&subStore=true";
        }
        else if (this.formGroup.value.kind === 'macroGroup') {
            this.filter = "customer-unique?beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate + "&macroGroup=true";
        }
        this.reportService
            .createReport(this.filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return ('Relatório de clientes únicos.' + response.fileUrl.split('.').splice(5, 5).join('.'));
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalCustomersUniqueComponent;
}());
export { ModalCustomersUniqueComponent };
