import { FundService } from 'src/app/services/fund.service'
import { Location } from '@angular/common'
import { CustomersAssociatesService } from 'src/app/services/customersAssociates.service'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { Component, ViewChild, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component'
import { ModalCustomerInfoComponent } from '../../../../../components/modal-customer-info/modal-customer-info.component'

@Component({
  selector: 'app-funds-associates-list',
  templateUrl: './associates-list.component.html',
  styleUrls: ['./associates-list.component.scss'],
})
export class FundAssociatesListComponent implements OnInit {
  headersTable: string[] = ['cpf', 'name', 'infoCostumer', 'actions']
  dataSource: any
  fieldsForm: any
  fund: any = {}
  public pageTotal: number
  public fundId = ''
  public customerId = ''
  public userRole
  cpf: string
  public params = { formGroupValue: [] }

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public fundService: FundService,
    public customersAssociatesService: CustomersAssociatesService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly location: Location,
    public authService: AuthService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.userRole = localStorage.getItem('userRole')
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    this.customerId = this.route.snapshot.paramMap.get('customerId')
    if (!this.customerId) {
      this.fieldsForm = [
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfFundCustomersAssociates',
          label: 'CPF',
          placeholder: 'CPF',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'nameFundCustomersAssociates',
          label: 'Nome',
          placeholder: 'Nome',
        },
      ]
      this.listAssociates()
    } else {
      this.associateFindOne(this.customerId)
    }
    this.currentFund()
  }

  currentFund () {
    this.fundService
      .find(this.fundId)
      .then(response => {
        this.fund = response
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  listAssociates () {
    this.customersAssociatesService
      .list(this.fundId, `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .then(response => {
        this.pageTotal = response.total
        this.dataSource = new MatTableDataSource(response.results)
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  associateFindOne (customerId) {
    this.customersAssociatesService
      .list(
        this.fundId,
        `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}, "filters":[{"fields":["customer_id"], "op": "=", "value":"${customerId}"}]}`,
      )
      .then(response => {
        response.results.map(user => {
          this.cpf = user.cpf
        })
        this.fieldsForm = [
          {
            field: 'input',
            type: 'text',
            formControlName: 'cpfFundCustomersAssociates',
            label: 'CPF',
            placeholder: 'CPF',
            valueDefault: this.cpf,
          },
          {
            field: 'input',
            type: 'text',
            formControlName: 'nameFundCustomersAssociates',
            label: 'Nome',
            placeholder: 'Nome',
          },
        ]
        this.pageTotal = response.total
        this.dataSource = new MatTableDataSource(response.results)
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  addAssociate () {
    this.router.navigate([`./dashboard/funds/${this.fundId}/associates/form`]).catch(err => {
      this.snackBar.open(err.message)
    })
  }

  removeAssociate (associatedId) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse associado?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.customersAssociatesService
            .remove(this.fundId, associatedId)
            .then(response => {
              if (response.success) {
                this.snackBar.open('Usuário desassociado com sucesso.')
                window.location.reload()
              } else {
                this.snackBar.open('Falha ao desassociar o usuário.')
              }
            })
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  detail (customerId) {
    if (this.userRole === 'ADMIN') {
      this.authService
        .customerUserInfo(customerId)
        .toPromise()
        .then(
          async response => {
            console.log(response)
            this.dialog.open(ModalCustomerInfoComponent, {
              data: {
                customerBalance: response.balance,
                customerCpf: response.cpf,
                customerFullName: `${response.firstName} ${response.lastName}`,
                customerBirthDate: response.birthDate,
                customerEmailAddress: response.emailAddress,
                customerPhoneNumber: response.phoneNumber,
                customerGender: response.gender,
                customerId: response.id,
                user: this.userRole,
              },
            })
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
    }
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
