import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
interface DialogData {
  cnpj: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-delete-closing.component.html',
  styleUrls: ['./modal-delete-closing.component.scss']
})
export class ModalDeleteClosingComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<ModalDeleteClosingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar
    ) {
      console.log('data', data);
     }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    
    this.dialogRef.close(true);
  }

  ngOnInit() {
    

    
  }
}

