<app-logged class="store-list">
  <header class="store-list__header">
    <h2>RECEBÍVEIS</h2>
  </header>

  <form [formGroup]="formFilter" class="row">
    <mat-form-field class="col-sm-6">
      <mat-label>Data inicial</mat-label>
      <input type="date" matInput formControlName="startDate" />
      <mat-error *ngIf="formFilter.controls.startDate?.invalid">
        {{ errorsService.messageErrorFor(formFilter.controls.startDate) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-sm-6">
      <mat-label>Data final</mat-label>
      <input type="date" matInput formControlName="finalDate" />
      <mat-error *ngIf="formFilter.controls.finalDate?.invalid">
        {{ errorsService.messageErrorFor(formFilter.controls.finalDate) }}
      </mat-error>
    </mat-form-field>
    <div class="col-sm-12">
      <button
        type="submit"
        class="btn-primary-light"
        mat-button
        matStepperNext
        (click)="submitFilter()"
      >
        Buscar Recebíveis
      </button>
    </div>
  </form>

  <form
    class="row"
    [formGroup]="form"
    (ngSubmit)="submit()"
    *ngIf="receivables.length > 0"
  >
    <div class="col-sm-6">
      <div class="table-responsive">
        <table mat-table [dataSource]="receivables">
          <ng-container matColumnDef="input">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element">
              <input
                type="checkbox"
                (change)="clickReceivableDate(element.date)"
              />
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Data de Recebimento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.date | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ convertCentsToReal(element.data.total) | currency: 'R$' }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
      </div>
    </div>
    <div class="store-list__block col-sm-6">
      <h4>SOLICITAR ANTECIPAÇÃO</h4>

      <div class="store-list__prepayment-value">
        {{ totalPrepayments() | currency: 'R$' }}
      </div>

      <mat-form-field>
        <mat-label>Data do Pagamento</mat-label>
        <input type="date" matInput formControlName="paymentDate" />
        <mat-error *ngIf="form.controls.paymentDate?.invalid">
          {{ errorsService.messageErrorFor(form.controls.paymentDate) }}
        </mat-error>
      </mat-form-field>
      <button
        type="submit"
        style="margin-top: 20px; float: right"
        class="btn-primary-light"
        mat-button
      >
        GERAR ANTECIPAÇÃO
      </button>
    </div>
  </form>
</app-logged>
