import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PreRegistrationService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration${filter || ''}`)
      .toPromise()
  }

  async create (body: any): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/pre-registration`, body).toPromise()
  }

  async findOne (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/pre-registration/${id}`).toPromise()
  }

  async delete (id: string): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/pre-registration/${id}`).toPromise()
  }

  async update (id: string, body): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/pre-registration/${id}`, body).toPromise()
  }

  async listAddress (id: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration/${id}/address`)
      .toPromise()
  }

  async deleteAddress (addressId: string): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/pre-registration/address/${addressId}`)
      .toPromise()
  }

  async createAddress (body: any, id: string): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/pre-registration/${id}/address`, body)
      .toPromise()
  }

  async updateAddress (addressId: string, body): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/pre-registration/address/${addressId}`, body)
      .toPromise()
  }

  async addressFindOne (addressId: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration/address/${addressId}`)
      .toPromise()
  }

  async listHistoric (cpf): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration-stores/${cpf}`)
      .toPromise()
  }

  async findCpf (cpf): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration/find-cpf/${cpf}`)
      .toPromise()
  }

  async listArchitects (filter: string): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/pre-registration-architects${filter || ''}`)
      .toPromise()
  }

  async findOneArchitect (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/pre-registration-architect/${id}`).toPromise()
  }

  async createArchitect (body: any): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/pre-registration-architect`, body).toPromise()
  }

  async updateArchitect (id: string, body): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/pre-registration-architect/${id}`, body).toPromise()
  }

  async deleteArchitect (id: string): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/pre-registration-architect/${id}`).toPromise()
  }

  searchPreregistrations (searchTerm): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/cms/pre-registration/search?searchTerm=${searchTerm}`,
      {
        headers: new HttpHeaders({ noloading: 'true' }),
      })     
  }
}
