<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-12">
          <mat-card-content>
            <h4>Em caso de dúvidas, por gentileza entrar em contato com:</h4>

            <mat-list class="list-help">
              <mat-list-item class="item-help"> Jesse Milanez </mat-list-item>
              <mat-list-item class="item-help">
                Relacionamento com Clientes e Parceiros
              </mat-list-item>
              <mat-list-item class="item-help"> jessemilanez@jhsf.com.br </mat-list-item>
              <mat-list-item class="item-help">
                <a href="https://api.whatsapp.com/send/?phone=5511984524224" target="_blank"
                  >(11) 98452-4224
                </a>
                <i class="fa fa-whatsapp" aria-hidden="true"></i>
              </mat-list-item>
            </mat-list>

            <mat-list class="list-help">
              <mat-list-item class="item-help"> Carolina Giovanardi </mat-list-item>
              <mat-list-item class="item-help"> Financeiro </mat-list-item>
              <mat-list-item class="item-help"> carolinagiovanardi@jhsf.com.br </mat-list-item>
              <mat-list-item class="item-help">
                <a href="https://api.whatsapp.com/send/?phone=5511989395049" target="_blank"
                  >(11) 98939-5049
                </a>
                <i class="fa fa-whatsapp" aria-hidden="true"></i>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
</main>
