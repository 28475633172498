import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormAccumulationComponent = /** @class */ (function () {
    function ModalFormAccumulationComponent(formBuilder, dialogRef, data, reportService, snackBar, errorsService, companiesService) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.data = data;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.companiesService = companiesService;
        this.storeControl = new FormControl();
        this.selectedStores = [];
        this.statusControl = new FormControl();
        this.selectedStatus = [];
        this.stores = [];
        this.status = [];
        this.visible = true;
        this.selectable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormAccumulationComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isArchitect = !!this.data.architect;
                        this.formGroup = this.formBuilder.group({
                            beginDate: [null, []],
                            endDate: [null, []],
                            beginDateJcoins: [null, []],
                            endDateJcoins: [null, []],
                            storesInput: [null, []],
                            statusInput: [null, []],
                            selectedCustomer: [null, []],
                        });
                        if (!this.isArchitect) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService
                                .listStoresBusinessUnits()
                                .then(function (r) {
                                r.stores.map(function (item) {
                                    _this.stores.push(item);
                                });
                            })
                                .catch(function (err) {
                                console.log(err);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.reportService.listStores().subscribe(function (response) {
                            response.map(function (item) {
                                _this.stores.push(item);
                            });
                        }, function (error) {
                            console.log(error);
                        });
                        _a.label = 3;
                    case 3:
                        this.reportService.getStatus().subscribe(function (response) {
                            Object.keys(response).map(function (k) {
                                if (k === 'APPROVED' || k === 'CANCELED') {
                                    _this.status.push({ key: k, value: response[k] });
                                }
                            });
                            _this.status.sort(function (a, b) {
                                return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                            });
                        });
                        this.filteredStores = this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 20)); }));
                        this.filteredStatus = this.statusControl.valueChanges.pipe(startWith(''), map(function (status) { return (status ? _this._filterStatus(status) : _this.status.slice(0, 20)); }));
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalFormAccumulationComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormAccumulationComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormAccumulationComponent.prototype._filterStatus = function (status) {
        var _this = this;
        var remove = String(status);
        if (typeof remove !== 'string') {
            return;
        }
        return this.status
            .filter(function (c) { return !_this.selectedStatus.includes(c); })
            .filter(function (c) { return ("" + String(c.value)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormAccumulationComponent.prototype.remove = function (value) {
        if (value.nomeFantasia) {
            var index = this.selectedStores.indexOf(value);
            if (index >= 0) {
                this.selectedStores.splice(index, 1);
            }
        }
        if (value.key) {
            var index = this.selectedStatus.indexOf(value);
            if (index >= 0) {
                this.selectedStatus.splice(index, 1);
            }
        }
    };
    ModalFormAccumulationComponent.prototype.selected = function (event) {
        if (event.option.value.nomeFantasia) {
            this.selectedStores.push(event.option.value);
            this.storeInput.nativeElement.value = '';
            this.storeControl.setValue(null);
        }
        if (event.option.value.key) {
            this.selectedStatus.push(event.option.value);
            this.statusInput.nativeElement.value = '';
            this.statusControl.setValue(null);
        }
    };
    ModalFormAccumulationComponent.prototype.submit = function (transaction) {
        var _this = this;
        var storesIds = this.selectedStores.map(function (c) { return c.id; });
        var status = this.selectedStatus.map(function (c) { return c.key; });
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        if (this.formGroup.value.beginDate && this.formGroup.value.beginDateJcoins) {
            this.snackBar.open('Preencha apenas uma data inicial.');
            return false;
        }
        if (this.formGroup.value.endDate && this.formGroup.value.endDateJcoins) {
            this.snackBar.open('Preencha apenas uma data final.');
            return false;
        }
        var architect;
        if (this.isArchitect) {
            architect = 'isArchitect=true';
        }
        else {
            architect = 'isArchitect=false';
        }
        if (!transaction) {
            if (this.selectedStores.length &&
                this.selectedStatus.length &&
                this.formGroup.value.beginDate &&
                this.formGroup.value.endDate) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&transactionsState=" + status + "&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else if (this.selectedStores.length) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else if (this.selectedStatus.length) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&transactionsState=" + status + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                this.filter = "accumulation?" + architect + "&cardMachine=false&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            if (this.selectedStores.length &&
                this.selectedStatus.length &&
                this.formGroup.value.beginDateJcoins &&
                this.formGroup.value.endDateJcoins) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&transactionsState=" + status + "&storesIds=" + storesIds + "&beginDateJcoins=" + this.formGroup.value.beginDateJcoins + "&endDateJcoins=" + this.formGroup.value.endDateJcoins;
            }
            else if (this.selectedStores.length) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&storesIds=" + storesIds + "&beginDateJcoins=" + this.formGroup.value.beginDateJcoins + "&endDateJcoins=" + this.formGroup.value.endDateJcoins;
            }
            else if (this.selectedStatus.length) {
                this.filter = "accumulation?" + architect + "&cardMachine=false&transactionsState=" + status + "&beginDateJcoins=" + this.formGroup.value.beginDateJcoins + "&endDateJcoins=" + this.formGroup.value.endDateJcoins;
            }
            else {
                this.filter = "accumulation?" + architect + "&cardMachine=false&beginDateJcoins=" + this.formGroup.value.beginDateJcoins + "&endDateJcoins=" + this.formGroup.value.endDateJcoins;
            }
        }
        if (transaction) {
            if (this.selectedStores.length &&
                this.selectedStatus.length &&
                this.formGroup.value.beginDate &&
                this.formGroup.value.endDate) {
                this.filter = "accumulation-transactions?" + architect + "&cardMachine=false&transactionsState=" + status + "&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else if (this.selectedStores.length) {
                this.filter = "accumulation-transactions?" + architect + "&cardMachine=false&storesIds=" + storesIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else if (this.selectedStatus.length) {
                this.filter = "accumulation-transactions?" + architect + "&cardMachine=false&transactionsState=" + status + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                this.filter = "accumulation-transactions?" + architect + "&cardMachine=false&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
        }
        if (this.formGroup.value.selectedCustomer && this.formGroup.value.selectedCustomer.id) {
            this.filter += "&customerId=" + this.formGroup.value.selectedCustomer.id;
        }
        this.reportService
            .createReport(this.filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (transaction) {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Relatório de Acúmulo transacional.' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                }
                else {
                    // eslint-disable-next-line no-new
                    new JsFileDownloader({
                        url: response.fileUrl,
                        nameCallback: function () {
                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                            return 'Relatório de Acúmulo.' + response.fileUrl.split('.').splice(5, 5).join('.');
                        },
                    });
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormAccumulationComponent;
}());
export { ModalFormAccumulationComponent };
