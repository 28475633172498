import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd } from '@angular/router';
import { ModalHelpComponent } from '../modal-help/modal-help.component';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
import { filter } from 'rxjs/operators';
var LoggedComponent = /** @class */ (function () {
    function LoggedComponent(router, dialog, permissionsService, loaderService, manualJcoinCreditService) {
        this.router = router;
        this.dialog = dialog;
        this.permissionsService = permissionsService;
        this.loaderService = loaderService;
        this.manualJcoinCreditService = manualJcoinCreditService;
        this.items = ['FINANCEIRO', 'APLICATIVO', 'CLIENTES', 'MARKETING', 'LL LOYALTY', 'INCORPORAÇÃO', 'AEROPORTO', 'ARQUITETOS'];
        this.itemsStorekeeper = ['AEROPORTO'];
        this.expandedIndex = 0;
        this.stores = [];
        this.userPermissions = [];
        this.showButtonsPreRegistration = false;
        this.showButtonsArchitect = false;
        this.showButtonsArchitectPreRegister = false;
        this.cmsPermissions = enumCmsPermissions;
        this.manualJcoinCreditCount = 0;
        this.menuOpened = !!localStorage.getItem('menuOpened');
    }
    LoggedComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activateNavigationChangeListener;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                        this.userRole = localStorage.getItem('userRole');
                        this.stores = JSON.parse(localStorage.getItem('storesUser'));
                        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
                        console.log(this.storeSelected);
                        console.log(this.stores);
                        this.permissionArchitectReports = this.userInfo.permissions ?
                            this.userInfo.permissions.filter(function (p) { return p === 'architect_reports'; }) :
                            null;
                        this.permissionArchitectNotifications = this.userInfo.permissions ?
                            this.userInfo.permissions.filter(function (p) { return p === 'architect_notifications'; }) :
                            null;
                        activateNavigationChangeListener = this.permissionsService.userHasPermission(enumCmsPermissions.manual_credit_revisor);
                        if (!activateNavigationChangeListener) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.listenManualJcoinCreditCount()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.listenNavigationChange()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.setupManualJcoinCreditCount()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoggedComponent.prototype.onChangeStore = function (store) {
        var _this = this;
        console.log(store);
        this.storeSelected = store;
        localStorage.setItem('storeSelected', JSON.stringify(store));
        this.loaderService.isLoading.next(true);
        setTimeout(function () {
            _this.router.navigate(['/dashboard/home']).then(function () {
                window.location.reload();
            });
        }, 1500);
    };
    LoggedComponent.prototype.teste = function () {
        this.router.navigate(['/pagamentos']);
    };
    LoggedComponent.prototype.toggleMenu = function () {
        this.menuOpened = !this.menuOpened;
        this.menuOpened
            ? localStorage.setItem('menuOpened', 'true')
            : localStorage.removeItem('menuOpened');
    };
    LoggedComponent.prototype.openDialogHelp = function () {
        var dialogRef = this.dialog.open(ModalHelpComponent, {
            width: '600px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    LoggedComponent.prototype.showButtons = function (e) {
        this.showButtonsPreRegistration = !this.showButtonsPreRegistration;
    };
    LoggedComponent.prototype.showArchitect = function (e) {
        this.showButtonsArchitect = !this.showButtonsArchitect;
    };
    LoggedComponent.prototype.showArchitectPreRegister = function (e) {
        this.showButtonsArchitectPreRegister = !this.showButtonsArchitectPreRegister;
    };
    LoggedComponent.prototype.listenNavigationChange = function () {
        var _this = this;
        if (!this.routerSubscription) {
            this.routerSubscription = this.router.events
                .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
                .subscribe(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log('Mudança de página detectada para:', event.urlAfterRedirects);
                            return [4 /*yield*/, this.manualJcoinCreditService.checkPendingManualJcoinCredits()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    LoggedComponent.prototype.listenManualJcoinCreditCount = function () {
        var _this = this;
        if (!this.manualJcoinCreditCountSubscription)
            this.manualJcoinCreditCountSubscription = this.manualJcoinCreditService.getManualJcoinCreditCountChange().subscribe(function (count) {
                _this.manualJcoinCreditCount = count ? parseInt(count) : 0;
            });
    };
    LoggedComponent.prototype.setupManualJcoinCreditCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var count;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manualJcoinCreditService.checkPendingManualJcoinCredits()];
                    case 1:
                        _a.sent();
                        count = localStorage.getItem('pendingManualJcoinCreditsCount');
                        this.manualJcoinCreditCount = count ? parseInt(count) : 0;
                        return [2 /*return*/];
                }
            });
        });
    };
    LoggedComponent.prototype.ngOnDestroy = function () {
        if (this.manualJcoinCreditCountSubscription) {
            this.manualJcoinCreditCountSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    };
    return LoggedComponent;
}());
export { LoggedComponent };
