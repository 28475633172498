import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'src/app/services/uploadAdapter.service';
import { base64ToBlob } from 'base64-blob';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
var subCategoriesFormComponent = /** @class */ (function () {
    function subCategoriesFormComponent(errorsService, formBuilder, categoriesService, authService, snackBar, router, route, _dialog, _cd, imageCompress, location) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.categoriesService = categoriesService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.location = location;
        this.Editor = ClassicEditor;
        this.textDescription = '';
        this.bannerUrl = '';
        this.mainIconUrl = '';
        this.menuIconUrl = '';
        this.menuIconUrlInvalid = false;
        this.config = {
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'imageUpload',
                    'blockQuote',
                    'undo',
                    'redo',
                ],
            },
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
    }
    subCategoriesFormComponent.prototype.ngOnInit = function () {
        this.categoryID = this.route.snapshot.paramMap.get('categoryID');
        this.subCategoryID = this.route.snapshot.paramMap.get('subCategoryID');
        this.initForm();
    };
    subCategoriesFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            heading: [null, [Validators.required]],
            menuIconUrl: [null, []],
            partnerBanner: [null, []],
            flashSales: [null, []],
            order: [null, [Validators.required, Validators.min(1)]],
            bannerLink: [null, []],
            flashSalesLink: [null, []],
            showMain: [null, []],
            description: [null, []],
            isActive: [null, []],
            isParking: [null, []],
        });
        if (this.subCategoryID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    subCategoriesFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.categoriesService.findOne(this.subCategoryID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    heading: response.heading,
                                    menuIconUrl: response.menuIconUrl,
                                    order: response.order,
                                    bannerLink: response.bannerLink,
                                    flashSalesLink: response.flashSalesLink,
                                    showMain: response.showMain,
                                    description: response.description,
                                    isActive: !!response.isActive,
                                    isParking: response.isParking,
                                });
                                this.croppedPartnerBannerImage = response.bannerUrl;
                                this.croppedFlashSalesImage = response.flashSalesUrl;
                                this.menuIconUrl = response.menuIconUrl;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    subCategoriesFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    subCategoriesFormComponent.prototype.selectFile = function (event, type) {
        var _this = this;
        var uploadData = new FormData();
        uploadData.append('file', event.target.files[0], event.target.files[0].name);
        this.categoriesService.upload(uploadData).subscribe(function (response) {
            switch (type) {
                case 'menuIconUrl': {
                    _this.formGroup.get('menuIconUrl').setValue(response.url);
                    _this.menuIconUrl = response.url;
                    _this.menuIconUrlInvalid = !response.url;
                    break;
                }
                default:
                    break;
            }
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    subCategoriesFormComponent.prototype.selectFilePartnerBanner = function (event) {
        var _this = this;
        var originalPartnerBannerImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalPartnerBannerImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalPartnerBannerImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedPartnerBannerImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 1:
                        _a.partnerBannerImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    subCategoriesFormComponent.prototype.selectFileFlashSales = function (event) {
        var _this = this;
        var originalFlashSalesImageUrl = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalFlashSalesImage = reader.result;
        };
        try {
            reader.readAsDataURL(originalFlashSalesImageUrl);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        this.croppedFlashSalesImage = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.croppedFlashSalesImage = result.img.dataURL;
                        this.flashSalesImagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedFlashSalesImage)];
                    case 1:
                        _a.flashSalesImageData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    subCategoriesFormComponent.prototype.editPartnerBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalPartnerBannerImage) return [3 /*break*/, 1];
                        _a = this.originalPartnerBannerImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.partnerBannerImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedPartnerBannerImage = result.img.dataURL;
                        this.partnerBannerImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedPartnerBannerImage)];
                    case 5:
                        _b.partnerBannerImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    subCategoriesFormComponent.prototype.removePartnerBannerImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.readUrlPartnerBanner = '';
                this.originalPartnerBannerImage = undefined;
                this.croppedPartnerBannerImage = undefined;
                this.partnerBannerImagePosition = undefined;
                this.partnerBannerImageData = undefined;
                return [2 /*return*/];
            });
        });
    };
    subCategoriesFormComponent.prototype.editFlashSalesImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalFlashSalesImage) return [3 /*break*/, 1];
                        _a = this.originalFlashSalesImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.croppedFlashSalesImage)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: {
                                    img: img,
                                    config: tslib_1.__assign({}, this.flashSalesImagePosition, { width: 38 * 16, height: 10 * 16 }),
                                },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.croppedFlashSalesImage = result.img.dataURL;
                        this.flashSalesImagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.croppedFlashSalesImage)];
                    case 5:
                        _b.flashSalesImageData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_2 = _c.sent();
                        this.snackBar.open(error_2.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    subCategoriesFormComponent.prototype.removeFlashSalesImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.readUrlFlashSales = '';
                this.originalFlashSalesImage = undefined;
                this.croppedFlashSalesImage = undefined;
                this.flashSalesImagePosition = undefined;
                this.flashSalesImageData = undefined;
                return [2 /*return*/];
            });
        });
    };
    subCategoriesFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    subCategoriesFormComponent.prototype.change = function (_a) {
        var editor = _a.editor;
        var EditorData = editor.getData();
        this.formGroup.get('content').setValue(EditorData);
    };
    subCategoriesFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    subCategoriesFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.menuIconUrlInvalid = !this.menuIconUrl;
                        if (!this.formGroup.valid || this.menuIconUrlInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!(this.croppedPartnerBannerImage && this.partnerBannerImageData)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 1:
                        response = _a.sent();
                        this.readUrlPartnerBanner = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!(this.croppedFlashSalesImage && this.flashSalesImageData)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.categoriesService.getS3Url()];
                    case 4:
                        response = _a.sent();
                        this.readUrlFlashSales = response.readUrl;
                        return [4 /*yield*/, this.categoriesService.uploadToS3(response.uploadUrl, this.flashSalesImageData)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        data = {
                            heading: this.formGroup.value.heading,
                            order: this.formGroup.value.order,
                            menuIconUrl: this.formGroup.value.menuIconUrl,
                            bannerUrl: this.readUrlPartnerBanner,
                            bannerLink: this.formGroup.value.bannerLink || '',
                            flashSalesUrl: this.readUrlFlashSales,
                            flashSalesLink: this.formGroup.value.flashSalesLink || '',
                            showMain: !!this.formGroup.value.showMain,
                            description: this.formGroup.value.description,
                            parentId: this.categoryID ? this.categoryID : undefined,
                            isActive: !!this.formGroup.value.isActive,
                            isParking: !!this.formGroup.value.isParking,
                        };
                        if (this.subCategoryID) {
                            this.categoriesService.updateSubCategories(data, this.subCategoryID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isUpdated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.location.back()];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Subcategoria atualizada com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        else {
                            this.categoriesService.addSubCategories(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.isCreated) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.router.navigate(["./dashboard/categories/" + this.categoryID + "/subcategories"])];
                                        case 1:
                                            _a.sent();
                                            this.snackBar.open('Subcategoria criado com sucesso.');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return subCategoriesFormComponent;
}());
export { subCategoriesFormComponent };
