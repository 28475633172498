import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTableDataSource } from '@angular/material';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { from } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
var ArchitectsCompanyCustomerFormComponent = /** @class */ (function () {
    function ArchitectsCompanyCustomerFormComponent(errorsService, snackBar, formBuilder, companiesService, route, router, reportService, preRegistrationService) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.companiesService = companiesService;
        this.route = route;
        this.router = router;
        this.reportService = reportService;
        this.preRegistrationService = preRegistrationService;
        this.customers = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.hideCustomer1 = true;
        this.hideEdit1 = true;
        this.addCustomer1 = true;
        this.showFirstName1 = false;
        this.totalPercentage = 100;
        this.tempPercentageCalc = 100;
        this.selectedCustomers1 = [];
        this.customerControl1 = new FormControl();
        this.loadingArchitects = false;
        this.selectedData = [];
        this.headersTable = ['cpf', 'comissionFee', 'fullName', 'actions'];
    }
    ArchitectsCompanyCustomerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            cpf: [null, []],
            percentage: [null, []],
            fullName: [null, []],
        });
        this.customerControl1.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000), filter(function (targetNameOrDocument) { return !!targetNameOrDocument; })).subscribe(function (targetNameOrDocument) {
            _this.targets$ = from(_this.searchArchitects(targetNameOrDocument));
        });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.searchArchitects = function (termToSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var architects, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingArchitects = true;
                        architects = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.companiesService.searchArchitectsToAddInCompany(termToSearch)];
                    case 2:
                        architects = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.loadingArchitects = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/, architects.filter(function (architect) {
                            return _this.selectedData.findIndex(function (selected) { return selected.cpf === architect.cpf; }) === -1;
                        })];
                }
            });
        });
    };
    ArchitectsCompanyCustomerFormComponent.prototype.onArchitectChange = function (architectResponse) {
        this.formGroup.patchValue({
            cpf: architectResponse.cpf,
            fullName: [architectResponse.firstName, architectResponse.lastName].join(' '),
        });
        this.customerInput1.nativeElement.value = '';
        this.customerControl1.setValue(null);
        this.tempPercentageCalc = this.totalPercentage;
    };
    ArchitectsCompanyCustomerFormComponent.prototype.getArchitectDisplayNames = function (architect) {
        var displayName = architect.firstName + ' ' + architect.lastName + ' - CPF: ' + architect.cpf;
        return displayName;
    };
    ArchitectsCompanyCustomerFormComponent.prototype.removeSelectedArchitect = function () {
        this.formGroup.patchValue({
            cpf: null,
            fullName: null,
        });
        this.customerInput1.nativeElement.value = '';
        this.customerControl1.setValue(null);
        this.tempPercentageCalc = this.totalPercentage;
    };
    ArchitectsCompanyCustomerFormComponent.prototype.addCpf = function (event) {
        event.preventDefault();
        var remaining = Number(this.totalPercentage) - Number(this.formGroup.value.percentage);
        this.tempPercentageCalc = remaining;
        if (this.formGroup.value.percentage && this.formGroup.value.cpf && remaining >= 0) {
            this.selectedData.push({
                cpf: this.formGroup.value.cpf,
                comissionFee: Number(this.formGroup.value.percentage),
                fullName: this.formGroup.value.fullName,
            });
            this.dataSource = new MatTableDataSource(this.selectedData);
            this.totalPercentage = remaining;
            this.formGroup.get('percentage').setValue(null);
            this.customerControl1.setValue('');
            this.removeSelectedArchitect();
            this.targets$ = from([]);
        }
    };
    ArchitectsCompanyCustomerFormComponent.prototype.remove = function (cpf) {
        var e_1, _a;
        var index = this.selectedData.indexOf(cpf);
        if (index >= 0) {
            this.selectedData.splice(index, 1);
            this.totalPercentage = 100;
            try {
                for (var _b = tslib_1.__values(this.selectedData), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var d = _c.value;
                    this.totalPercentage = Number(this.totalPercentage) - Number(d.comissionFee);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.dataSource = new MatTableDataSource(this.selectedData);
        }
        this.tempPercentageCalc = this.totalPercentage;
    };
    ArchitectsCompanyCustomerFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .findOne(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var e_2, _a, _b, _c, c;
                            return tslib_1.__generator(this, function (_d) {
                                this.company = response;
                                this.totalPercentage = 100;
                                try {
                                    for (_b = tslib_1.__values(response.customers), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        c = _c.value;
                                        this.selectedData.push({
                                            cpf: c.customerCpf,
                                            comissionFee: c.comissionFee,
                                            fullName: c.firstName + " " + c.lastName,
                                        });
                                        this.totalPercentage =
                                            Number(this.totalPercentage) - Number(c.comissionFee);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                this.dataSource = new MatTableDataSource(this.selectedData);
                                this.tempPercentageCalc = this.totalPercentage;
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectsCompanyCustomerFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3, _a, total, _b, _c, d;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                total = 0;
                try {
                    for (_b = tslib_1.__values(this.selectedData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        d = _c.value;
                        total = total + Number(d.comissionFee);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                if (this.totalPercentage !== 0 && total !== 100) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.selectedData.map(function (cs) { return delete cs.fullName; });
                this.companiesService.update(this.id, { customers: this.selectedData }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response) {
                            this.snackBar.open('Cliente cadastrado com sucesso.');
                            this.router.navigate(['./dashboard/architect-companies']);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return ArchitectsCompanyCustomerFormComponent;
}());
export { ArchitectsCompanyCustomerFormComponent };
