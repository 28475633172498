import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  public loading

  constructor (private readonly http: HttpClient) {}

  list (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/*/memberships`).pipe(
      tap(_ => this.log('*/memberships')),
      catchError(this.handleError),
    )
  }

  add (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/memberships`, data).pipe(
      tap(_ => this.log('cms/memberships')),
      catchError(this.handleError),
    )
  }

  update (data, membershipsID): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/memberships/${membershipsID}`, data).pipe(
      tap(_ => this.log('cms/memberships')),
      catchError(this.handleError),
    )
  }

  delete (membershipsID): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/memberships/${membershipsID}`).pipe(
      tap(_ => this.log('cms/memberships')),
      catchError(this.handleError),
    )
  }

  async listPlans (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/membership/plans${filter || ''}`)
      .toPromise()
  }

  async listPlansOthers(filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/membership/plans/others${filter || ''}`)
      .toPromise()
  }

  async createPlan (data): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/membership/plans`, data).toPromise()
  }

  async editPlan (planId, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/membership/plans/${planId}`, data)
      .toPromise()
  }

  async deletePlan (planId): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/membership/plans/${planId}`).toPromise()
  }

  async listPlansById (planId): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/membership/plans/${planId}`).toPromise()
  }

  async listCustomersByPlan (planId, filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/membership/plan-customers/${planId}${filter || ''}`)
      .toPromise()
  }

  async listPlansByCustomer (customerID, filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/membership/customer-plans/${customerID}${filter || ''}`)
      .toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}
}
