import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'auth/forget-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: 'auth/change/passowrd',
    pathMatch: 'full',
    component: ChangePasswordComponent
  },
  {
    path: 'auth/email/confirmation/:token',
    pathMatch: 'full',
    component: EmailConfirmationComponent
  },
  {
    path: '',
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
