import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';
var TermsFormComponent = /** @class */ (function () {
    function TermsFormComponent(errorsService, formBuilder, termService, authService, snackBar, router) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.termService = termService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.states = [];
        this.plans = [];
        this.configs = [];
        this.Editor = ClassicEditor;
        this.initForm();
        this.config = {
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    'undo',
                    'redo'
                ]
            },
            image: {
                toolbar: [
                    'imageStyle:full',
                    'imageStyle:side',
                    '|',
                    'imageTextAlternative'
                ]
            },
        };
    }
    TermsFormComponent.prototype.ngOnInit = function () {
    };
    TermsFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            heading: [null, [Validators.required]],
            subtitle: [null, [Validators.required]],
            content: [null, [Validators.required]],
        });
        //this.loadInfos();
    };
    ;
    TermsFormComponent.prototype.onReady = function (editor) {
        console.log(editor);
    };
    TermsFormComponent.prototype.loadInfos = function () {
        /**
         * this.configService.view().subscribe(
          async (response) => {
            this.configs = response;
    
            this.formGroup.patchValue({
              mainStoreCnpj: this.configs['results'].regulation,
            });
          },
          (error) => {
            console.log(error);
            alert(error.error.message);
          }
        );
         */
    };
    TermsFormComponent.prototype.change = function (_a) {
        var editor = _a.editor;
        var EditorData = editor.getData();
        this.formGroup.get('content').setValue(EditorData);
    };
    TermsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.termService.add(this.formGroup.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.router.navigate(['./dashboard/terms']);
                            this.snackBar.open('Termo criado com sucesso.');
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return TermsFormComponent;
}());
export { TermsFormComponent };
