import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var ModalClosingsComponent = /** @class */ (function () {
    function ModalClosingsComponent(dialogRef, data, formBuilder, snackBar, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        console.log('data', data);
    }
    ModalClosingsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalClosingsComponent.prototype.submit = function () {
        console.log(this.formGroup.value);
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        this.dialogRef.close(this.formGroup.value);
    };
    ModalClosingsComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            paymentDate: [null, [Validators.required]],
        });
    };
    return ModalClosingsComponent;
}());
export { ModalClosingsComponent };
