import * as tslib_1 from "tslib";
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
var SegmentationResultsComponent = /** @class */ (function () {
    function SegmentationResultsComponent(segmentationService, snackBar, route, location) {
        this.segmentationService = segmentationService;
        this.snackBar = snackBar;
        this.route = route;
        this.location = location;
        this.headersTable = ['cpf', 'name'];
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    SegmentationResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.segmentationId = this.route.snapshot.paramMap.get('segmentationId');
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.listCustomers().catch(function (err) {
            _this.snackBar.open(err.message);
        });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfSegmentationResults',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameSegmentationResults',
                label: 'Nome',
                placeholder: 'Nome',
            },
        ];
        this.segmentationService.find(this.segmentationId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.name = response.name;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    SegmentationResultsComponent.prototype.listCustomers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.segmentationService
                            .listCustomers(this.segmentationId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        return [2 /*return*/];
                }
            });
        });
    };
    SegmentationResultsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return SegmentationResultsComponent;
}());
export { SegmentationResultsComponent };
