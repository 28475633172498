import * as tslib_1 from "tslib";
import { ElementRef, OnInit, ChangeDetectorRef, } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import { UploadAdapter } from '../../../../services/uploadAdapter.service';
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component';
import { base64ToBlob } from 'base64-blob';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatSnackBar, MatButtonModule, MatButtonToggleModule, } from '@angular/material';
import * as moment from 'moment';
var BusinessPartnersFormComponent = /** @class */ (function () {
    function BusinessPartnersFormComponent(formBuilder, errorsService, businessPartnersService, router, snackBar, dialog, route, categoriesService, location, _dialog, _cd, imageCompress, http, reportService) {
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.businessPartnersService = businessPartnersService;
        this.router = router;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.categoriesService = categoriesService;
        this.location = location;
        this._dialog = _dialog;
        this._cd = _cd;
        this.imageCompress = imageCompress;
        this.http = http;
        this.reportService = reportService;
        this.Editor = ClassicEditor;
        this.textDescription = '';
        this.categoryID = '';
        this.id = '';
        this.imageExt = '';
        this.contentInvalid = false;
        this.imageInvalid = false;
        this.addressInvalid = false;
        this.accountManager = false;
        this.interest = false;
        this.initalized = false;
        this.adressInitalized = false;
        this.MaterialComponents = [MatButtonModule, MatButtonToggleModule];
        this.selectedStores = [];
        this.stores = [];
        this.storeControl = new FormControl();
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    BusinessPartnersFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.categoryID = this.route.snapshot.paramMap.get('categoryID');
                this.id = this.route.snapshot.paramMap.get('id');
                this.initForm();
                this.config = {
                    image: {
                        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
                    },
                };
                this.reportService.listStores().subscribe(function (response) {
                    response.map(function (item) {
                        _this.stores.push(item);
                        _this.filteredStores = _this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 20)); }));
                    });
                }, function (error) {
                    console.log(error);
                });
                if (this.id) {
                    this.getById();
                }
                return [2 /*return*/];
            });
        });
    };
    BusinessPartnersFormComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    BusinessPartnersFormComponent.prototype.selectedStore = function (event) {
        if (event.option.value.nomeFantasia && this.selectedStores.length < 1) {
            this.selectedStores.push(event.option.value);
            this.storeInput.nativeElement.value = '';
            this.storeControl.setValue(null);
        }
    };
    BusinessPartnersFormComponent.prototype.removeStore = function (value) {
        if (value.nomeFantasia) {
            var index = this.selectedStores.indexOf(value);
            if (index >= 0) {
                this.selectedStores.splice(index, 1);
            }
        }
    };
    BusinessPartnersFormComponent.prototype.onUploadImage = function (event) {
        var _this = this;
        this.originalImage = event.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            _this.originalImage = reader.result;
        };
        try {
            reader.readAsDataURL(this.originalImage);
        }
        catch (e) {
            this.snackBar.open(e.message);
        }
        console.log(this.originalImage);
        this.cropped = null;
        this._dialog
            .open(CropperDialogComponent, {
            data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
            width: 650,
            disableClose: true,
        })
            .afterClosed.toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 2];
                        this.cropped = result.img.dataURL;
                        this.imagePosition = result.config;
                        _a = this;
                        return [4 /*yield*/, base64ToBlob(this.cropped)];
                    case 1:
                        _a.uploadData = _b.sent();
                        this._cd.markForCheck();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    BusinessPartnersFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            address: [null, []],
            addressName: [null, []],
            address1GoogleMaps: [null, []],
            address2: [null, []],
            addressName2: [null, []],
            address2GoogleMaps: [null, []],
            content: [null, [Validators.required]],
            accountManager: [null, []],
            isActive: [null, []],
            link: [null, []],
            isMain: [null, []],
            onlineSale: [null, []],
            physicalStore: [null, []],
            contact: [null, []],
            imageUrl: [null, [Validators.required]],
            categoryJoin: [null, []],
            beginDate: [null, []],
            endDate: [null, []],
            about: [null, []],
            userFacebook: [null, []],
            linkFacebook: [null, []],
            userTwitter: [null, []],
            linkTwitter: [null, []],
            userInstagram: [null, []],
            linkInstagram: [null, []],
            userYoutube: [null, []],
            linkYoutube: [null, []],
            isLivelo: [null, []],
        });
    };
    BusinessPartnersFormComponent.prototype.getById = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.businessPartnersService.findOne(this.categoryID, this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var contact, filter;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                contact = [''];
                                if (response.showTwamButton) {
                                    contact = ['accountManager'];
                                    this.accountManager = true;
                                }
                                if (response.showInterestButton) {
                                    contact = ['interest'];
                                    this.interest = true;
                                }
                                this.formGroup.patchValue({
                                    name: response.name,
                                    address: response.address,
                                    addressName: response.addressName,
                                    address1GoogleMaps: response.address1GoogleMaps,
                                    address2: response.address2,
                                    addressName2: response.addressName2,
                                    address2GoogleMaps: response.address2GoogleMaps,
                                    imageUrl: response.image,
                                    content: response.content,
                                    isActive: response.isActive,
                                    link: response.link,
                                    isMain: response.isMain,
                                    onlineSale: response.onlineSale,
                                    physicalStore: response.physicalStore,
                                    contact: contact,
                                    categoryJoin: response.categoryJoinId,
                                    beginDate: response.beginDate ? moment(response.beginDate).format('YYYY-MM-DD') : null,
                                    endDate: response.endDate ? moment(response.endDate).format('YYYY-MM-DD') : null,
                                    about: response.about,
                                    userFacebook: response.userFacebook,
                                    linkFacebook: response.linkFacebook,
                                    userTwitter: response.userTwitter,
                                    linkTwitter: response.linkTwitter,
                                    userInstagram: response.userInstagram,
                                    linkInstagram: response.linkInstagram,
                                    userYoutube: response.userYoutube,
                                    linkYoutube: response.linkYoutube,
                                    isLivelo: response.isLivelo,
                                });
                                this.cropped = response.image;
                                this.imageUrl = response.image;
                                this.initalized = true;
                                if (response.beginDate || response.endDate) {
                                    this.formGroup.get('isActive').disable();
                                }
                                if (response.storeId) {
                                    filter = this.stores.filter(function (item) {
                                        return response.storeId.includes(item.id);
                                    });
                                    filter.map(function (item) {
                                        _this.selectedStores.push(item);
                                    });
                                }
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BusinessPartnersFormComponent.prototype.editImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var img, _a, result, _b, error_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        if (!this.originalImage) return [3 /*break*/, 1];
                        _a = this.originalImage;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.getBase64ImageFromUrl(this.cropped)];
                    case 2:
                        _a = _c.sent();
                        _c.label = 3;
                    case 3:
                        img = _a;
                        return [4 /*yield*/, this._dialog
                                .open(CropperDialogComponent, {
                                data: { img: img, config: this.imagePosition },
                                width: 650,
                                disableClose: true,
                            })
                                .afterClosed.toPromise()];
                    case 4:
                        result = _c.sent();
                        if (!result) return [3 /*break*/, 6];
                        this.cropped = result.img.dataURL;
                        this.imagePosition = result.config;
                        _b = this;
                        return [4 /*yield*/, base64ToBlob(this.cropped)];
                    case 5:
                        _b.uploadData = _c.sent();
                        this._cd.markForCheck();
                        _c.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _c.sent();
                        this.snackBar.open(error_1.message);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BusinessPartnersFormComponent.prototype.getBase64ImageFromUrl = function (imageUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, blob;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(imageUrl, {
                            method: 'GET',
                            mode: 'cors',
                            cache: 'no-cache',
                            keepalive: false,
                            referrer: 'origin-when-cross-origin',
                        })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.blob()];
                    case 2:
                        blob = _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var reader = new FileReader();
                                reader.addEventListener('load', function () {
                                    resolve(reader.result);
                                }, false);
                                reader.readAsDataURL(blob);
                            })];
                }
            });
        });
    };
    BusinessPartnersFormComponent.prototype.selectFile = function (event) {
        var _this = this;
        var file = event.target.files[0];
        this.uploadData = file;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
            _this.imageUrl = e.target.result;
            _this.imageInvalid = !e.target.result;
        };
    };
    BusinessPartnersFormComponent.prototype.removeImage = function () {
        this.imageUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
    };
    BusinessPartnersFormComponent.prototype.imgLoadError = function () {
        this.imageUrl = null;
    };
    BusinessPartnersFormComponent.prototype.changeInput = function () {
        if (!this.adressInitalized) {
            return;
        }
        !this.formGroup.value.address || !this.formGroup.value.link
            ? (this.addressInvalid = false)
            : (this.addressInvalid = true);
    };
    BusinessPartnersFormComponent.prototype.onChange = function (_a) {
        var editor = _a.editor;
        if (!this.initalized) {
            return;
        }
        this.contentInvalid = !this.formGroup.value.content;
    };
    BusinessPartnersFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    BusinessPartnersFormComponent.prototype.toggleChange = function (event) {
        var toggle = event.source;
        if (toggle) {
            switch (toggle.value) {
                case 'accountManager':
                    this.accountManager = true;
                    this.interest = false;
                    break;
                case 'interest':
                    this.interest = true;
                    this.accountManager = false;
                    break;
                default:
                    this.accountManager = false;
                    this.interest = false;
                    break;
            }
            var group = toggle.buttonToggleGroup;
            if (event.value.some(function (item) { return item === toggle.value; })) {
                group.value = [toggle.value];
            }
        }
        else {
            this.accountManager = false;
            this.interest = false;
        }
    };
    BusinessPartnersFormComponent.prototype.alterStatus = function (e) {
        if (e.target.value) {
            this.formGroup.patchValue({
                isActive: false,
            });
            this.formGroup.get('isActive').disable();
        }
        else {
            this.formGroup.get('isActive').enable();
        }
    };
    BusinessPartnersFormComponent.prototype.submit = function () {
        var _this = this;
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var imageUrl, _a, readUrl, uploadUrl, data;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // this.imageInvalid = !this.cropped
                        // this.contentInvalid = !this.formGroup.value.content
                        // this.initalized = !this.formGroup.value.content
                        // this.addressInvalid = !this.formGroup.value.address && !this.formGroup.value.link
                        // this.adressInitalized = !this.formGroup.value.address && !this.formGroup.value.link
                        // !this.formGroup.value.address && !this.formGroup.value.link
                        //   ? (this.addressInvalid = true)
                        //   : (this.addressInvalid = false)
                        if (!this.formGroup.value.content
                        // this.addressInvalid
                        ) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!this.id) return [3 /*break*/, 4];
                        imageUrl = '';
                        if (!this.uploadData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.businessPartnersService.getS3Url(this.categoryID)];
                    case 1:
                        _a = _b.sent(), readUrl = _a.readUrl, uploadUrl = _a.uploadUrl;
                        imageUrl = readUrl;
                        return [4 /*yield*/, this.businessPartnersService.uploadToS3(uploadUrl, this.uploadData)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        data = {
                            name: this.formGroup.value.name,
                            address: this.formGroup.value.address || '',
                            addressName: this.formGroup.value.addressName || '',
                            address1GoogleMaps: this.formGroup.value.address1GoogleMaps || '',
                            address2: this.formGroup.value.address2 || '',
                            addressName2: this.formGroup.value.addressName2 || '',
                            address2GoogleMaps: this.formGroup.value.address2GoogleMaps || '',
                            image: imageUrl || this.imageUrl,
                            content: this.formGroup.value.content,
                            showTwamButton: this.accountManager,
                            isActive: !!this.formGroup.value.isActive,
                            link: this.formGroup.value.link || '',
                            isMain: !!this.formGroup.value.isMain,
                            onlineSale: !!this.formGroup.value.onlineSale,
                            physicalStore: !!this.formGroup.value.physicalStore,
                            showInterestButton: this.interest,
                            beginDate: this.formGroup.value.beginDate ? this.formGroup.value.beginDate : null,
                            endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
                            about: this.formGroup.value.about || '',
                            userFacebook: this.formGroup.value.userFacebook || '',
                            linkFacebook: this.formGroup.value.linkFacebook || '',
                            userTwitter: this.formGroup.value.userTwitter || '',
                            linkTwitter: this.formGroup.value.linkTwitter || '',
                            userInstagram: this.formGroup.value.userInstagram || '',
                            linkInstagram: this.formGroup.value.linkInstagram || '',
                            userYoutube: this.formGroup.value.userYoutube || '',
                            linkYoutube: this.formGroup.value.linkYoutube || '',
                            storeId: this.selectedStores.length ? this.selectedStores[0].id : null,
                            isLivelo: this.formGroup.value.isLivelo,
                        };
                        this.businessPartnersService.update(this.categoryID, this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.location.back();
                                this.snackBar.open('Parceiro alterado com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.businessPartnersService.getS3Url(this.categoryID).then(function (reponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var readUrl, uploadUrl, data;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        readUrl = reponse.readUrl, uploadUrl = reponse.uploadUrl;
                                        return [4 /*yield*/, this.businessPartnersService.uploadToS3(uploadUrl, this.uploadData)];
                                    case 1:
                                        _a.sent();
                                        data = {
                                            name: this.formGroup.value.name,
                                            address: this.formGroup.value.address || '',
                                            addressName: this.formGroup.value.addressName || '',
                                            address1GoogleMaps: this.formGroup.value.address1GoogleMaps || '',
                                            address2: this.formGroup.value.address2 || '',
                                            addressName2: this.formGroup.value.addressName2 || '',
                                            address2GoogleMaps: this.formGroup.value.address2GoogleMaps || '',
                                            image: readUrl,
                                            content: this.formGroup.value.content,
                                            showTwamButton: this.accountManager,
                                            isActive: !!this.formGroup.value.isActive,
                                            link: this.formGroup.value.link || '',
                                            isMain: !!this.formGroup.value.isMain,
                                            onlineSale: !!this.formGroup.value.onlineSale,
                                            physicalStore: !!this.formGroup.value.physicalStore,
                                            showInterestButton: this.interest,
                                            beginDate: this.formGroup.value.beginDate ? this.formGroup.value.beginDate : null,
                                            endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
                                            about: this.formGroup.value.about || '',
                                            userFacebook: this.formGroup.value.userFacebook || '',
                                            linkFacebook: this.formGroup.value.linkFacebook || '',
                                            userTwitter: this.formGroup.value.userTwitter || '',
                                            linkTwitter: this.formGroup.value.linkTwitter || '',
                                            userInstagram: this.formGroup.value.userInstagram || '',
                                            linkInstagram: this.formGroup.value.linkInstagram || '',
                                            userYoutube: this.formGroup.value.userYoutube || '',
                                            linkYoutube: this.formGroup.value.linkYoutube || '',
                                            storeId: this.selectedStores.length ? this.selectedStores[0].id : null,
                                            isLivelo: this.formGroup.value.isLivelo,
                                        };
                                        this.businessPartnersService.create(this.categoryID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                this.location.back();
                                                this.snackBar.open('Parceiro cadastrado com sucesso.');
                                                return [2 /*return*/];
                                            });
                                        }); }, function (error) {
                                            _this.snackBar.open(error.message);
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); })().catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    BusinessPartnersFormComponent.prototype.remove = function (id, event) {
        var _this = this;
        event.preventDefault();
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Parceiro?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.businessPartnersService.delete(this.categoryID, id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.location.back();
                                this.snackBar.open('Parceiro removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return BusinessPartnersFormComponent;
}());
export { BusinessPartnersFormComponent };
