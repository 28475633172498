import { Component, OnInit } from '@angular/core'
import { Validators, FormGroup, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar } from '@angular/material'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup

  hide = true

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
  ) {
    this.initForm()
  }

  ngOnInit () {
    localStorage.removeItem('storeSelected')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userRole')
    localStorage.removeItem('storesUser')
    localStorage.removeItem('token')
    localStorage.removeItem('storesUser')
    localStorage.removeItem('categoriesInfos')
    localStorage.removeItem('regulationsInfos')
    localStorage.removeItem('isManual')
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      emailAddress: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    this.authService.signin(this.formGroup.value).subscribe(
      async response => {
        if (response.token) {
          this.setToken(response)
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  setToken (response) {
    localStorage.setItem('token', response.token)
    localStorage.setItem('userRole', response.profile.role)

    this.authService.userInfo(response.profile.id).subscribe(
      async res => {
        if (res) {
          localStorage.setItem('userInfo', JSON.stringify(res))

          if (!response.validation.isEmailConfirmed) {
            this.router.navigate(['./auth/change/passowrd'])
            return
          }

          this.getStores(response.profile.role)
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  getStores (userRole) {
    this.authService.getStoresByUser().subscribe(
      async res => {
        if (res) {
          if (res.length > 0) {
            localStorage.setItem('storesUser', JSON.stringify(res))
            if (userRole !== 'ADMIN' && userRole !== 'EMPTY') {
              localStorage.setItem('storeSelected', JSON.stringify(res[0]))
            }
            this.router.navigate(['./dashboard/home'])
          } else {
            this.snackBar.open('O usuário não tem nenhum parceiro cadastrado.')
          }
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
