import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Location } from '@angular/common'
import { FundService } from 'src/app/services/fund.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-funds-form',
  templateUrl: './fund-form.component.html',
  styleUrls: ['./fund-form.component.scss'],
})
export class FundFormComponent implements OnInit {
  public formGroup: FormGroup
  public fundId = ''
  public params = { formGroupValue: [] }

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    private readonly fundService: FundService,
    private readonly snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      cnpj: [null, [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      emailAddress: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      active: true,
    })

    if (this.fundId) {
      this.fundService
        .find(this.fundId)
        .then(fund => {
          this.formGroup.patchValue(fund)
          this.formGroup.get('cnpj').disable()
        })
        .catch(err => {
          this.snackBar.open(err.error.message)
        })
    }
  }

  async submit () {
    const data = {
      name: this.formGroup.value.name,
      cnpj: this.formGroup.value.cnpj,
      emailAddress: this.formGroup.value.emailAddress,
      active: Boolean(this.formGroup.value.active),
    }

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.fundId) {
      this.fundService
        .edit(this.fundId, {
          ...data,
          cnpj: undefined,
        })
        .then(async response => {
          if (response.success) {
            this.snackBar.open('Fundo editado com sucesso.')
            this.location.back()
          } else {
            this.snackBar.open('Falha ao editar o fundo.')
          }
        })
        .catch(err => {
          this.snackBar.open(err.error.message)
        })
    } else {
      this.fundService
        .create(data)
        .then(async response => {
          if (response.success) {
            this.snackBar.open('Fundo criado com sucesso.')
            this.location.back()
          } else {
            this.snackBar.open('Falha ao adicionar o fundo.')
          }
        })
        .catch(err => {
          this.snackBar.open(err.error.message)
        })
    }
  }

  excluir () {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse fundo?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.fundService.delete(this.fundId).then(async response => {
            if (response.success) {
              this.snackBar.open('Fundo excluido com sucesso.')
              this.location.back()
            } else {
              this.snackBar.open('Falha ao excluir o fundo.')
            }
          })
        }
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  goBack () {
    this.location.back()
  }
}
