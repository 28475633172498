import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import { CategoriesService } from 'src/app/services/categories.service'
import { AirportsService } from '../../../../services/airports.service'
import { UploadAdapter } from 'src/app/services/uploadAdapter.service'
import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-airports-form',
  templateUrl: './airports-form.component.html',
  styleUrls: ['./airports-form.component.scss'],
})
export class AirportsFormComponent implements OnInit {
  public formGroup: FormGroup
  public airportID: string
  public Editor = ClassicEditor
  public config: any
  public srcResult: any
  public textDescription = ''
  public bannerUrl = ''

  private originalBannerImage: any
  public croppedBannerImage: any
  private bannerImagePosition: any
  private bannerImageData: any
  public descriptionInvalid = false
  public imgInvalid = false

  public readUrlBanner: any

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public categoriesService: CategoriesService,
    public airportsService: AirportsService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    private readonly dialog: MatDialog,
  ) {
    this.config = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'blockQuote',
          'undo',
          'redo',
        ],
      },
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }
  }

  ngOnInit () {
    if (this.route.snapshot.paramMap.get('airportID')) {
      this.airportID = this.route.snapshot.paramMap.get('airportID')
    }

    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      title: [null, [Validators.required]],
      bannerImage: [null, []],
      description: [null, []],
      footerMsg: [null, [Validators.required]],
      infoTitle: [null, [Validators.required]],
      isActive: [null, []],
    })
    if (this.airportID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.airportsService.findOne(this.airportID).then(async response => {
      this.formGroup.patchValue({
        title: response.title,
        description: response.description,
        footerMsg: response.footerMsg,
        infoTitle: response.infoTitle,
        isActive: response.isActive,
      })
      this.croppedBannerImage = response.bannerUrl
      if (response.description) this.descriptionInvalid = false
    })
  }

  onChange ({ editor }: ChangeEvent) {
    this.descriptionInvalid = !this.formGroup.value.description
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  selectFileBanner (event: Event) {
    const originalBannerImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalBannerImage = reader.result
    }
    try {
      reader.readAsDataURL(originalBannerImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedBannerImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedBannerImage = result.img.dataURL
          this.bannerImagePosition = result.config
          this.bannerImageData = await base64ToBlob(this.croppedBannerImage)
          this.imgInvalid = !this.croppedBannerImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editBannerImage () {
    try {
      const img = this.originalBannerImage
        ? this.originalBannerImage
        : await this.getBase64ImageFromUrl(this.croppedBannerImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.bannerImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedBannerImage = result.img.dataURL
        this.bannerImagePosition = result.config
        this.bannerImageData = await base64ToBlob(this.croppedBannerImage)
        this.imgInvalid = !this.croppedBannerImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async removeBannerImage () {
    this.readUrlBanner = ''
    this.originalBannerImage = undefined
    this.croppedBannerImage = undefined
    this.bannerImagePosition = undefined
    this.bannerImageData = undefined

    this.imgInvalid = true
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  checkAirport (e) {
    if (this.formGroup.value.isActive) {
      this.dialog
        .open(ModalConfirmComponent, {
          width: '500px',
          data: {
            text: 'Deseja esse Aeroporto como Ativo no App?',
            buttonConfirmText: 'Sim',
          },
        })
        .afterClosed()
        .toPromise()
        .then(async result => {
          if (result === 'cancel') {
            this.formGroup.get('isActive').setValue(false)
          }
        })
        .catch(err => {
          this.snackBar.open(err.message)
        })
    }
  }

  async submit () {
    this.descriptionInvalid = !this.formGroup.value.description
    this.imgInvalid = !this.croppedBannerImage
    if (!this.formGroup.valid || this.descriptionInvalid || this.imgInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.croppedBannerImage && this.bannerImageData) {
      const response = await this.airportsService.getS3Url()
      this.readUrlBanner = response.readUrl
      await this.airportsService.uploadToS3(response.uploadUrl, this.bannerImageData)
    }

    const data = {
      title: this.formGroup.value.title,
      bannerUrl: this.readUrlBanner,
      description: this.formGroup.value.description,
      footerMsg: this.formGroup.value.footerMsg,
      infoTitle: this.formGroup.value.infoTitle,
      isActive: this.formGroup.value.isActive,
    }

    if (this.airportID) {
      this.airportsService.updateAirport(this.airportID, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/airports'])
            this.snackBar.open('Aeroporto atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.airportsService.createAirport(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airports'])
            this.snackBar.open('Aeroporto criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
