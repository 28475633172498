<app-logged class="store-form">
  <header class="store-form__header">
    <h2>TARIFAS DE CARTÃO DE CRÉDITO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()" >VOLTAR</button>
    </div>
  </header>

  <div>
    <button
        class="btn-primary-light"
        mat-button
        (click)="onAddCardFeeClick()"
      >
        ADICIONAR TARIFA
      </button>
  </div>  
  <br>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="paymentProvider">
        <th mat-header-cell *matHeaderCellDef>Provedor</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentProvider }}</td>
      </ng-container>
      <ng-container matColumnDef="cardBrand">
        <th mat-header-cell *matHeaderCellDef>Bandeira</th>
        <td mat-cell *matCellDef="let element">{{ element.cardBrand }}</td>
      </ng-container>
      <ng-container matColumnDef="startsAt">
        <th mat-header-cell *matHeaderCellDef>Data inicio</th>
        <td mat-cell *matCellDef="let element">{{ element.startsAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="installments1">
        <th mat-header-cell *matHeaderCellDef>Taxa 1 parcela</th>
        <td mat-cell *matCellDef="let element">{{ element.installments1}}%</td>
      </ng-container>
      <ng-container matColumnDef="installments2to6">
        <th mat-header-cell *matHeaderCellDef>Taxa 2 a 6 parcelas</th>
        <td mat-cell *matCellDef="let element">{{ element.installments2to6}}%</td>
      </ng-container>
      <ng-container matColumnDef="installments7to12">
        <th mat-header-cell *matHeaderCellDef>Taxa 7 a 12 parcelas</th>
        <td mat-cell *matCellDef="let element">{{ element.installments7to12}}%</td>
      </ng-container>
      <ng-container matColumnDef="debit">
        <th mat-header-cell *matHeaderCellDef>Taxa débito</th>
        <td mat-cell *matCellDef="let element">{{ element.debit}}%</td>
      </ng-container>
      

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
    

</app-logged>
