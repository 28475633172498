import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var ReceivablesListComponent = /** @class */ (function () {
    function ReceivablesListComponent(receivablesService, prepaymentsService, formBuilder, snackBar, errorsService, router) {
        this.receivablesService = receivablesService;
        this.prepaymentsService = prepaymentsService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.router = router;
        this.headersTable = ['input', 'date', 'amount'];
        this.receivables = [];
        this.receivablesResponse = {};
        this.receivablesSelecteds = [];
        this.receivablesDate = {};
    }
    ReceivablesListComponent.prototype.ngOnInit = function () {
        this.formFilter = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            finalDate: [null, [Validators.required]],
        });
        this.form = this.formBuilder.group({
            paymentDate: [null, [Validators.required]],
            receivables: [null, [Validators.required]],
        });
    };
    ReceivablesListComponent.prototype.listAll = function (dateStart, dateEnd) {
        var _this = this;
        this.receivables = [];
        this.receivablesService.listAll({ dateStart: dateStart, dateEnd: dateEnd }).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.receivablesResponse = response;
                Object.entries(response).forEach(function (_a) {
                    var _b = tslib_1.__read(_a, 1), key = _b[0];
                    var data = {
                        date: key,
                        data: response[key],
                    };
                    _this.receivables.push(data);
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    ReceivablesListComponent.prototype.clickReceivableDate = function (date) {
        if (date in this.receivablesDate) {
            delete this.receivablesDate[date];
        }
        else {
            this.receivablesDate[date] = true;
        }
    };
    ReceivablesListComponent.prototype.totalPrepayments = function () {
        var _this = this;
        return Object.keys(this.receivablesDate).map(function (key) {
            return _this.receivablesResponse[key].total / 100;
        }).reduce(function (a, b) { return a + b; }, 0);
    };
    ReceivablesListComponent.prototype.submitFilter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.formFilter.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.listAll(this.formFilter.value.startDate, this.formFilter.value.finalDate);
                return [2 /*return*/];
            });
        });
    };
    ReceivablesListComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var receivables;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                receivables = Object.keys(this.receivablesDate).map(function (key) {
                    return _this.receivablesResponse[key].items.map(function (item) { return item.id; });
                });
                if (receivables.length > 0) {
                    receivables = receivables.reduce(function (prev, current) {
                        return prev.concat(current);
                    });
                }
                this.form.patchValue({ receivables: receivables });
                if (!this.form.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.prepaymentsService.postPrepayment(this.form.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.snackBar.open('Antecipação gerada com sucesso.');
                            this.router.navigate(['dashboard', 'prepayments']);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ReceivablesListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return ReceivablesListComponent;
}());
export { ReceivablesListComponent };
