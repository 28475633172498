import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
var BillPaymentNotificationsComponent = /** @class */ (function () {
    function BillPaymentNotificationsComponent(service, snackBar, router, dialog, route) {
        this.service = service;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.route = route;
        this.headersTable = [
            'id', 'customerDocument', 'dueDate', 'paidDate', 'invoiceValue', 'paidValue', 'transactions', 'processedAt', 'totalInvoiceValuePaidAt'
        ];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    BillPaymentNotificationsComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        var year = new Date().getFullYear();
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'billPaymentCpf',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'select',
                formControlName: 'billPaymentMonth',
                label: 'Mês',
                list: [
                    { key: '1', value: '1 - Jan' },
                    { key: '2', value: '2 - Fev' },
                    { key: '3', value: '3 - Mar' },
                    { key: '4', value: '4 - Abr' },
                    { key: '5', value: '5 - Mai' },
                    { key: '6', value: '6 - Jun' },
                    { key: '7', value: '7 - Jul' },
                    { key: '8', value: '8 - Ago' },
                    { key: '9', value: '9 - Set' },
                    { key: '10', value: '10 - Out' },
                    { key: '11', value: '11 - Nov' },
                    { key: '12', value: '12 - Dez' },
                ],
                optionDefault: '',
            },
            {
                field: 'select',
                formControlName: 'billPaymentYear',
                label: 'Ano',
                list: [
                    { key: year.toString(), value: year.toString() },
                    { key: (year - 1).toString(), value: (year - 1).toString() },
                    { key: (year - 2).toString(), value: (year - 2).toString() },
                ],
                optionDefault: '',
            },
        ];
        this.list();
    };
    BillPaymentNotificationsComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, _a, results, pagination;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = new URLSearchParams({
                            page: this.page.toString(),
                            pageSize: this.pageSize.toString()
                        });
                        return [4 /*yield*/, this.service
                                .list(params.toString())
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, pagination = _a.pagination;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = pagination.totalCount;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    BillPaymentNotificationsComponent.prototype.openModalErrorDetails = function (entity) {
        var errorsToShow = {
            "Erro": entity.errorMessage,
        };
        var dialogRef = this.dialog.open(ModalGenericComponent, {
            width: '520px',
            data: {
                html: Object.entries(errorsToShow).map(function (_a) {
                    var _b = tslib_1.__read(_a, 2), key = _b[0], val = _b[1];
                    return val ? "<div><strong>" + key + "</strong><br><br><code style='word-break: break-word;'>" + val + "</code></div>" : '';
                }).join(' ')
            }
        });
        return dialogRef.afterClosed().toPromise();
    };
    BillPaymentNotificationsComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter.results;
        this.pageTotal = returnFilter.pagination.totalCount;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return BillPaymentNotificationsComponent;
}());
export { BillPaymentNotificationsComponent };
