import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { CustomerAttendantService } from '../../../../services/customer-attendant.service'
import { MatSnackBar } from '@angular/material'

import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'

@Component({
  selector: 'app-store-form',
  templateUrl: './client-form-add.component.html',
  styleUrls: ['./client-form-add.component.scss'],
})
export class ClientFormAddComponent implements OnInit {
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: any
  public firstPaymentEnabled = false
  public planSelectEnabled = false

  private originalDocumentFrontImage: any
  public croppedDocumentFrontImage: any
  private documentFrontImagePosition: any
  private documentFrontImageData: any
  public readUrlDocumentFront: any

  private originalDocumentBehindImage: any
  public croppedDocumentBehindImage: any
  private documentBehindImagePosition: any
  private documentBehindImageData: any
  public readUrlDocumentBehind: any

  public frontImageInvalid = false
  public behindImageInvalid = false

  public kinds: any[] = []
  public attendants: any[] = []

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    public customerAttendantService: CustomerAttendantService,
  ) {
    this.initForm()
  }

  ngOnInit () {
    // empty
  }

  initForm () {
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.formGroup = this.formBuilder.group({
      cpf: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      blockTransaction: [null, [Validators.required]],
      blockTransactionJcoins: [null, [Validators.required]],
      birthDate: [null, []],
      emailAddress: [null, [Validators.required, Validators.email]],
      billingEmail: [null, [Validators.email]],
      phoneNumber: [null, [Validators.required]],
      membershipEnabled: [null, [Validators.required]],
      plan: [null, [Validators.required]],
      firstPayment: [null, []],
      kind: [null, [Validators.required]],
      attendant: [null, []],
    })

    this.authService.clientKindList().then(
      response => {
        this.kinds = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )

    this.customerAttendantService.list().then(
      response => {
        this.attendants = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  changeTypePlan (event) {
    if (event.value == 'recurrent') {
      this.firstPaymentEnabled = true
      this.formGroup.get('firstPayment').setValidators([Validators.required])
      this.formGroup.get('firstPayment').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.formGroup.get('firstPayment').setValidators(null)
      this.formGroup.get('firstPayment').setErrors(null)
    }
  }

  changeMembershipEnabled (event) {
    if (event.value === true) {
      this.planSelectEnabled = true
      this.formGroup.get('plan').setValidators([Validators.required])
      this.formGroup.get('plan').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.planSelectEnabled = false
      this.formGroup.get('plan').setValidators(null)
      this.formGroup.get('plan').setErrors(null)
      this.formGroup.get('plan').setValue('none')
    }
  }

  selectFileDocumentFront (event: Event) {
    const originalDocumentFrontImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentFrontImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentFrontImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentFrontImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentFrontImage = result.img.dataURL
          this.documentFrontImagePosition = result.config
          this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
          this.frontImageInvalid = !this.croppedDocumentFrontImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentFrontImage () {
    try {
      const img = this.originalDocumentFrontImage
        ? this.originalDocumentFrontImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentFrontImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentFrontImage = result.img.dataURL
        this.documentFrontImagePosition = result.config
        this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
        this.frontImageInvalid = !this.croppedDocumentFrontImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  selectFileDocumentBehind (event: Event) {
    const originalDocumentBehindImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentBehindImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentBehindImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentBehindImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentBehindImage = result.img.dataURL
          this.documentBehindImagePosition = result.config
          this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)

          this.behindImageInvalid = !this.croppedDocumentBehindImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentBehindImage () {
    try {
      const img = this.originalDocumentBehindImage
        ? this.originalDocumentBehindImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentBehindImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentBehindImage = result.img.dataURL
        this.documentBehindImagePosition = result.config
        this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)

        this.behindImageInvalid = !this.croppedDocumentBehindImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async removeImage (input) {
    if (input === 'documentFront') {
      this.readUrlDocumentFront = ''
      this.originalDocumentFrontImage = undefined
      this.croppedDocumentFrontImage = undefined
      this.documentFrontImagePosition = undefined
      this.documentFrontImageData = undefined

      this.frontImageInvalid = true
    }
    if (input === 'documentBehind') {
      this.readUrlDocumentBehind = ''
      this.originalDocumentBehindImage = undefined
      this.croppedDocumentBehindImage = undefined
      this.documentBehindImagePosition = undefined
      this.documentBehindImageData = undefined

      this.behindImageInvalid = true
    }
  }

  async submit () {
    this.frontImageInvalid = !this.croppedDocumentFrontImage
    this.behindImageInvalid = !this.croppedDocumentBehindImage
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.croppedDocumentFrontImage && this.documentFrontImageData) {
      const response = await this.authService.getS3Url('documentFront')
      this.readUrlDocumentFront = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)
    }

    if (this.croppedDocumentBehindImage && this.documentBehindImageData) {
      const response = await this.authService.getS3Url('documentBehind')
      this.readUrlDocumentBehind = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)
    }

    const data: any = {
      cpf: this.formGroup.value.cpf,
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      gender: this.formGroup.value.gender,
      blockTransaction: this.formGroup.value.blockTransaction,
      blockTransactionJcoins: this.formGroup.value.blockTransactionJcoins,
      emailAddress: this.formGroup.value.emailAddress,
      billingEmail: this.formGroup.value.billingEmail,
      phoneNumber: this.formGroup.value.phoneNumber,
      membershipEnabled: this.formGroup.value.membershipEnabled,
      imgDocumentFront: this.readUrlDocumentFront,
      imgDocumentBehind: this.readUrlDocumentBehind,
      customerKindId: this.formGroup.value.kind,
      attendantCustomerId: this.formGroup.value.attendant,
    }

    if (this.formGroup.value.birthDate) {
      data.birthDate = this.formGroup.value.birthDate
    }

    if (this.formGroup.value.membershipEnabled) {
      data.plan = this.formGroup.value.plan
    }

    if (this.formGroup.value.plan == 'recurrent') {
      data.firstPayment = this.formGroup.value.firstPayment
    }
    await this.authService
      .signupClient(data)
      .toPromise()
      .then(
        async response => {
          if (response.isCreated) {
            this.snackBar.open('Cliente criado com sucesso.')
            this.router.navigate(['./dashboard/clients'])
          }
        },
        error => {
          let message = error.error.message
          if (error.error.message.keyword) {
            message = error.error.message.message
          }
          this.snackBar.open(message)
        },
      )
  }
}
