import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { startWith, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { AirportsService } from '../../../../../services/airports.service'
import { ReportService } from '../../../../../services/report.service'

@Component({
  selector: 'app-airport-company-customer-form',
  templateUrl: './airport-company-customer-form.component.html',
  styleUrls: ['./airport-company-customer-form.component.scss'],
})
export class AirportCompanyCustomerFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  company: any
  totalCustomers: any
  customer1: any
  customer2: any
  customer3: any
  customer4: any
  filteredCustomers1: Observable<string[]>
  filteredCustomers2: Observable<string[]>
  filteredCustomers3: Observable<string[]>
  filteredCustomers4: Observable<string[]>
  customers: any[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]

  customerId1: any
  customerId2: any
  customerId3: any
  customerId4: any
  firstName1: any
  firstName2: any
  firstName3: any
  firstName4: any

  hideCustomer1 = true
  hideCustomer2 = true
  hideCustomer3 = true
  hideCustomer4 = true

  hideEdit1 = true
  hideEdit2 = true
  hideEdit3 = true
  hideEdit4 = true

  addCustomer1 = true
  addCustomer2 = true
  addCustomer3 = true
  addCustomer4 = true

  showFirstName1 = false
  showFirstName2 = false
  showFirstName3 = false
  showFirstName4 = false

  public selectedCustomers1: any[] = []
  public selectedCustomers2: any[] = []
  public selectedCustomers3: any[] = []
  public selectedCustomers4: any[] = []
  public customerControl1 = new FormControl()
  public customerControl2 = new FormControl()
  public customerControl3 = new FormControl()
  public customerControl4 = new FormControl()

  percentage1: any
  percentage2: any
  percentage3: any
  percentage4: any

  preRegisterId: any

  @ViewChild('customerInput1', { static: false }) customerInput1: ElementRef<HTMLInputElement>
  @ViewChild('auto1', { static: false }) matAutocomplete1: MatAutocomplete
  @ViewChild('customerInput2', { static: false }) customerInput2: ElementRef<HTMLInputElement>
  @ViewChild('auto2', { static: false }) matAutocomplete2: MatAutocomplete
  @ViewChild('customerInput3', { static: false }) customerInput3: ElementRef<HTMLInputElement>
  @ViewChild('auto3', { static: false }) matAutocomplete3: MatAutocomplete
  @ViewChild('customerInput4', { static: false }) customerInput4: ElementRef<HTMLInputElement>
  @ViewChild('auto4', { static: false }) matAutocomplete4: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public airportsService: AirportsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.preRegisterId = this.route.snapshot.paramMap.get('preRegisterId')

    this.formGroup = this.formBuilder.group({
      firstName1: [null, []],
      lastName1: [null, []],
      cpf1: [null, []],
      percentage1: [null, []],
      firstName2: [null, []],
      lastName2: [null, []],
      cpf2: [null, []],
      percentage2: [null, []],
      firstName3: [null, []],
      lastName3: [null, []],
      cpf3: [null, []],
      percentage3: [null, []],
      firstName4: [null, []],
      lastName4: [null, []],
      cpf4: [null, []],
      percentage4: [null, []],
    })

    this.reportService.listCustomers().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers1 = this.customerControl1.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.filteredCustomers2 = this.customerControl2.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.filteredCustomers3 = this.customerControl3.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.filteredCustomers4 = this.customerControl4.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    if (this.id) {
      this.list().catch(err => { console.log(err) })
      this.findOne().catch(err => console.log(err))
    }
  }

  private _filter (customer: string): string[] {
    if (!this.selectedCustomers1.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers1.includes(c))
        .filter(c =>
          `${String(c.name)} ${String(c.cpf)}`.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }

    if (!this.selectedCustomers2.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers2.includes(c))
        .filter(c =>
          `${String(c.name)} ${String(c.cpf)} `.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }

    if (!this.selectedCustomers3.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers3.includes(c))
        .filter(c =>
          `${String(c.name)} ${String(c.cpf)} `.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }

    if (!this.selectedCustomers4.length) {
      const remove = String(customer).replace(/[.-]/g, '')

      if (typeof remove !== 'string') {
        return
      }

      return this.customers
        .filter(c => !this.selectedCustomers4.includes(c))
        .filter(c =>
          `${String(c.name)} ${String(c.cpf)} `.toLowerCase().includes(remove.toLowerCase()),
        )
        .slice(0, 20)
    }
  }

  removeCustomer (customer: any, input): void {
    if (input === 1) {
      const index = this.selectedCustomers1.indexOf(customer)
      if (index >= 0) {
        this.selectedCustomers1.splice(index, 1)
      }
      this.customerId1 = undefined
      this.hideCustomer1 = true
    }
    if (input === 2) {
      const index = this.selectedCustomers2.indexOf(customer)
      if (index >= 0) {
        this.selectedCustomers2.splice(index, 1)
      }
      this.customerId2 = undefined
      this.hideCustomer2 = true
    }
    if (input === 3) {
      const index = this.selectedCustomers3.indexOf(customer)
      if (index >= 0) {
        this.selectedCustomers3.splice(index, 1)
      }
      this.customerId3 = undefined
      this.hideCustomer3 = true
    }
    if (input === 4) {
      const index = this.selectedCustomers4.indexOf(customer)
      if (index >= 0) {
        this.selectedCustomers4.splice(index, 1)
      }
      this.customerId4 = undefined
      this.hideCustomer4 = true
    }
  }

  selected (event: MatAutocompleteSelectedEvent, input): void {
    if (!this.selectedCustomers1.length && input === 1) {
      this.selectedCustomers1.push(event.option.value)
      this.customerInput1.nativeElement.value = ''
      this.customerControl1.setValue(null)
      this.customerId1 = this.selectedCustomers1[0].customerId
      this.hideCustomer1 = false
    }

    if (!this.selectedCustomers2.length && input === 2) {
      this.selectedCustomers2.push(event.option.value)
      this.customerInput2.nativeElement.value = ''
      this.customerControl2.setValue(null)
      this.customerId2 = this.selectedCustomers2[0].customerId
      this.hideCustomer2 = false
    }

    if (!this.selectedCustomers3.length && input === 3) {
      this.selectedCustomers3.push(event.option.value)
      this.customerInput3.nativeElement.value = ''
      this.customerControl3.setValue(null)
      this.customerId3 = this.selectedCustomers3[0].customerId
      this.hideCustomer3 = false
    }

    if (!this.selectedCustomers4.length && input === 4) {
      this.selectedCustomers4.push(event.option.value)
      this.customerInput4.nativeElement.value = ''
      this.customerControl4.setValue(null)
      this.customerId4 = this.selectedCustomers4[0].customerId
      this.hideCustomer4 = false
    }
  }

  async findOne () {
    await this.airportsService.fideOneCompanyAirport(this.id)
      .then(async response => {
        this.company = response
      })
  }

  async list () {
    const { results, total } = await this.airportsService
      .listCompanyAirportCustomers(this.id)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.totalCustomers = total

    if (results[0]) {
      this.addCustomer1 = false
      this.hideEdit1 = false
      this.formGroup.get('firstName1').disable()
      this.formGroup.get('lastName1').disable()
      this.formGroup.get('cpf1').disable()
      this.formGroup.get('percentage1').disable()

      if (results[0].isPreRegistration) {
        this.showFirstName1 = true

        this.formGroup.patchValue({
          firstName1: results[0].customerFirstName,
          lastName1: results[0].customerLastName,
          cpf1: results[0].customerCpf,
        })
      } else {
        this.hideCustomer1 = false
        const fullName = `${results[0].customerFirstName} ${results[0].customerLastName}`
        this.selectedCustomers1.push({
          cpf: results[0].customerCpf,
          customerId: results[0].customerId,
          name: fullName,
        })
      }
      this.formGroup.patchValue({
        percentage1: results[0].percentage * 100,
      })

      this.percentage1 = results[0].percentage * 100
    }

    if (results[1]) {
      this.addCustomer2 = false
      this.hideEdit2 = false
      this.formGroup.get('firstName2').disable()
      this.formGroup.get('lastName2').disable()
      this.formGroup.get('cpf2').disable()
      this.formGroup.get('percentage2').disable()

      if (results[1].isPreRegistration) {
        this.showFirstName2 = true

        this.formGroup.patchValue({
          firstName2: results[1].customerFirstName,
          lastName2: results[1].customerLastName,
          cpf2: results[1].customerCpf,
        })
      } else {
        this.hideCustomer2 = false
        const fullName = `${results[1].customerFirstName} ${results[1].customerLastName}`
        this.selectedCustomers2.push({
          cpf: results[1].customerCpf,
          customerId: results[1].customerId,
          name: fullName,
        })
      }
      this.formGroup.patchValue({
        percentage2: results[1].percentage * 100,
      })
      this.percentage2 = results[1].percentage * 100
    }
    if (results[2]) {
      this.addCustomer3 = false
      this.hideEdit3 = false
      this.formGroup.get('firstName3').disable()
      this.formGroup.get('lastName3').disable()
      this.formGroup.get('cpf3').disable()
      this.formGroup.get('percentage3').disable()

      if (results[2].isPreRegistration) {
        this.showFirstName3 = true

        this.formGroup.patchValue({
          firstName3: results[2].customerFirstName,
          lastName3: results[2].customerLastName,
          cpf3: results[2].customerCpf,
        })
      } else {
        this.hideCustomer3 = false
        const fullName = `${results[2].customerFirstName} ${results[2].customerLastName}`
        this.selectedCustomers3.push({
          cpf: results[2].customerCpf,
          customerId: results[2].customerId,
          name: fullName,
        })
      }
      this.formGroup.patchValue({
        percentage3: results[2].percentage * 100,
      })
      this.percentage3 = results[2].percentage * 100
    }
    if (results[3]) {
      this.addCustomer4 = false
      this.hideEdit4 = false
      this.formGroup.get('firstName4').disable()
      this.formGroup.get('lastName4').disable()
      this.formGroup.get('cpf4').disable()
      this.formGroup.get('percentage4').disable()

      if (results[3].isPreRegistration) {
        this.showFirstName4 = true

        this.formGroup.patchValue({
          firstName4: results[3].customerFirstName,
          lastName4: results[3].customerLastName,
          cpf4: results[3].customerCpf,
        })
      } else {
        this.hideCustomer4 = false
        const fullName = `${results[3].customerFirstName} ${results[3].customerLastName}`
        this.selectedCustomers1.push({
          cpf: results[3].customerCpf,
          customerId: results[3].customerId,
          name: fullName,
        })
      }
      this.formGroup.patchValue({
        percentage4: results[3].percentage * 100,
      })
      this.percentage4 = results[3].percentage * 100
    }
  }

  valueInputCpf (e, input) {
    if (input === 1 && this.selectedCustomers1.length === 0) {
      this.firstName1 = e.target.value
    }
    if (input === 2 && this.selectedCustomers2.length === 0) {
      this.firstName2 = e.target.value
    }
    if (input === 3 && this.selectedCustomers3.length === 0) {
      this.firstName3 = e.target.value
    }
    if (input === 4 && this.selectedCustomers4.length === 0) {
      this.firstName4 = e.target.value
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    const p1 = this.percentage1 ? this.percentage1 : this.formGroup.value.percentage1
    const p2 = this.percentage2 ? this.percentage2 : this.formGroup.value.percentage2
    const p3 = this.percentage3 ? this.percentage3 : this.formGroup.value.percentage3
    const p4 = this.percentage4 ? this.percentage4 : this.formGroup.value.percentage4

    const percentages =
      Number(p1) +
      Number(p2) +
      Number(p3) +
      Number(p4)

    if (percentages > 100) {
      this.snackBar.open('Total de porcetagem passou de 100%')
      return false
    }
    if (percentages < 100) {
      this.snackBar.open('Total de porcetagem não pode ser menos de 100%')
      return false
    }
    const body = [] as any[]
    if (this.customerId1 && this.addCustomer1) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        customerId: this.customerId1,
        percentage: this.formGroup.value.percentage1 / 100,
      })
    } else if (this.firstName1 && this.addCustomer1) {
      body.push({
        companyAirportId: this.id,
        firstName: this.firstName1,
        preRegisterCompanyAirportId: this.preRegisterId,
        lastName: this.formGroup.value.lastName1,
        cpf: this.formGroup.value.cpf1,
        percentage: this.formGroup.value.percentage1 / 100,
      })
    }

    if (this.customerId2 && this.addCustomer2) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        customerId: this.customerId2,
        percentage: this.formGroup.value.percentage2 / 100,
      })
    } else if (this.firstName2 && this.addCustomer2) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        firstName: this.firstName2,
        lastName: this.formGroup.value.lastName2,
        cpf: this.formGroup.value.cpf2,
        percentage: this.formGroup.value.percentage2 / 100,
      })
    }

    if (this.customerId3) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        customerId: this.customerId3,
        percentage: this.formGroup.value.percentage3 / 100,
      })
    } else if (this.firstName3 && this.addCustomer3) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        firstName: this.firstName3,
        lastName: this.formGroup.value.lastName3,
        cpf: this.formGroup.value.cpf3,
        percentage: this.formGroup.value.percentage3 / 100,
      })
    }

    if (this.customerId4) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        customerId: this.customerId4,
        percentage: this.formGroup.value.percentage4 / 100,
      })
    } else if (this.firstName4 && this.addCustomer4) {
      body.push({
        companyAirportId: this.id,
        preRegisterCompanyAirportId: this.preRegisterId,
        firstName: this.firstName4,
        lastName: this.formGroup.value.lastName4,
        cpf: this.formGroup.value.cpf4,
        percentage: this.formGroup.value.percentage4 / 100,
      })
    }

    this.airportsService.createAirportCompanyCustomer(body).then(
      async response => {
        if (response.isCreated) {
          if (this.id) {
            await this.router.navigate([`./dashboard/airport-company/${this.id}/customers`])
          }
          if (this.preRegisterId) {
            await this.router.navigate(['./dashboard/airport-companies'])
          }
          this.snackBar.open('Cliente cadastrado na Empresa Aeroporto com sucesso.')
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
