<main class="modal">
  <a class="link-cancel" mat-icon-button matTooltip="Cancelar" (click)="cancel()" matTooltipPosition="left">
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <h4>Solicitação de Crédito Manual de JCOINS</h4>

          <div class="details" *ngIf="manualPayment">
            <div>
              <strong>STATUS: </strong>
              <span>
                <b [ngStyle]="{ color: getStatusColor(manualPayment.status) }">
                  {{ getStatusName(manualPayment.status) }}
                </b>
              </span>
            </div>
            <div>
              <strong>CLIENTE: </strong>
              <span>
                {{ manualPayment.customer.firstName }} {{ manualPayment.customer.lastName }}
                ({{ manualPayment.customer.cpf | mask: '000.000.000-00' }})
              </span>
            </div>
            <div>
              <strong>PARCEIRO: </strong>
              <span>
                {{ getStoreObject(manualPayment).razaoSocial }}
                ({{ getStoreObject(manualPayment).cnpj | mask: '00.000.000/0000-00' }})
              </span>
            </div>
            <div>
              <strong>JCOINS: </strong>
              <span>{{ manualPayment.jcoinsQt }}</span>
            </div>
            <hr style="margin: 16px 0" />
            <div>
              <strong>SOLICITADO POR: </strong>
              <span>
                {{ manualPayment.requestedByUser.firstName }}
                {{ manualPayment.requestedByUser.lastName }}
                ({{ manualPayment.requestedByUser.emailAddress }})
              </span>
            </div>
            <div>
              <strong>SOLICITADO EM: </strong>
              <span>{{ manualPayment.requestedAt | date: 'dd/MM/yy HH:mm' }}</span>
            </div>
            <div>
              <strong>DESCRIÇÃO: </strong>
              <span>{{ manualPayment.requestReason }}</span>
            </div>

            <div *ngIf="!isPending()">
              <hr style="margin: 16px 0" />
              <div>
                <strong strong>REVISADO POR: </strong>
                <span>
                  {{ manualPayment.reviewedByUser.firstName }}
                  {{ manualPayment.reviewedByUser.lastName }}
                  ({{ manualPayment.reviewedByUser.emailAddress}})
                </span>
              </div>

              <div>
                <strong strong>REVISADO EM: </strong>
                <span>{{ manualPayment.reviewedAt | date: 'dd/MM/yy HH:mm' }}</span>
              </div>

              <div>
                <strong strong>DESCRIÇÃO REVISÃO: </strong>
                <span>{{ manualPayment.reviewReason }}</span>
              </div>
            </div>
          </div>

          <div *ngIf="
              isPending() &&
              permissionsService.userHasPermission(cmsPermissions.manual_credit_revisor)
            ">
            <div class="col-12 col-sm-12">
              <mat-card-content>
                <mat-radio-group formControlName="isApproved">
                  <mat-radio-button [value]="true" style="margin-right: 16px">
                    APROVADO
                  </mat-radio-button>
                  <mat-radio-button [value]="false"> REPROVADO </mat-radio-button>
                </mat-radio-group>
                <mat-form-field>
                  <mat-label>Motivo</mat-label>
                  <input matInput formControlName="reviewReason" />
                </mat-form-field>
              </mat-card-content>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div class="login__actions">
        <button *ngIf="
            isPending() &&
            permissionsService.userHasPermission(cmsPermissions.manual_credit_revisor)
          " mat-button type="submit" class="btn-submit-dark">
          Revisar
        </button>
      </div>
    </div>
  </form>
</main>