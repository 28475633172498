import { Component, ViewChild, OnInit } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { NotificationService } from '../../../../services/notification.service'

@Component({
  selector: 'app-notification-customers',
  templateUrl: './notification-customers.component.html',
  styleUrls: ['./notification-customers.component.scss'],
})
export class NotificationCustomersComponent implements OnInit {
  public nameNotification: string
  public pageTotal: number
  headersTable: string[] = ['cpf', 'name']
  dataSource: any
  notificationId: string
  fieldsForm: any
  public params = { formGroupValue: [] }
  architect: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public notificationService: NotificationService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfNotificationCustomers',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameNotificationCustomers',
        label: 'Nome',
        placeholder: 'Nome',
      },
    ]
    this.notificationId = this.route.snapshot.paramMap.get('notificationID')

    this.notificationService.findOne(this.notificationId).then(
      async response => {
        this.nameNotification = response.title
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )

    this.notificationCustomers()
  }

  notificationCustomers () {
    this.notificationService
      .notificationCustomersList(
        this.notificationId,
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
      )
      .then(
        async response => {
          this.dataSource = new MatTableDataSource(response.results)
          this.pageTotal = response.total
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
