import { Component } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'jhsf-cms';

  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) {
    
    this.loaderService.isLoading.next(true);

    router.events.pipe(filter(e => e instanceof NavigationEnd), take(1))
      .subscribe((e) => {
        this.loaderService.isLoading.next(false);
      });

  }


  ngOnInit() {
    
  }


  ngOnDestroy() {
    
  }


}
