import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
var MembershipCustomersPlanListComponent = /** @class */ (function () {
    function MembershipCustomersPlanListComponent(router, route, location, membershipService, snackBar) {
        this.router = router;
        this.route = route;
        this.location = location;
        this.membershipService = membershipService;
        this.snackBar = snackBar;
        this.headersTable = ['cpf', 'fullName', 'beginDate', 'endDate', 'lastPayment', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.params = { formGroupValue: [] };
    }
    MembershipCustomersPlanListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfPlanMembership',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'fullNamePlanMembership',
                label: 'Nome',
                placeholder: 'Nome',
            },
        ];
        this.planId = this.route.snapshot.paramMap.get('planID');
        this.membershipService.listPlansById(this.planId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.planName = response.name;
                this.beginDate = response.beginDate;
                this.endDate = response.endDate;
                this.value = response.value;
                this.recurrencePeriod = response.recurrencePeriod;
                this.active = response.rowActive;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.membershipService
            .listCustomersByPlan(this.planId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = new MatTableDataSource(response.results);
                this.pageTotal = response.total;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipCustomersPlanListComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    MembershipCustomersPlanListComponent.prototype.month = function (recurrencePeriod) {
        if (recurrencePeriod == 0) {
            return '';
        }
        else if (recurrencePeriod > 1) {
            return recurrencePeriod + " meses";
        }
        else {
            return recurrencePeriod + " m\u00EAs";
        }
    };
    MembershipCustomersPlanListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return MembershipCustomersPlanListComponent;
}());
export { MembershipCustomersPlanListComponent };
