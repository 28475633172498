import { FundService } from 'src/app/services/fund.service'
import { Location } from '@angular/common'
import { CustomersAssociatesService } from 'src/app/services/customersAssociates.service'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component'
@Component({
  selector: 'app-funds-extract',
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss'],
})
export class FundExtractComponent implements OnInit {
  public fundId = ''
  public pageTotal: number
  public userRole
  public params = { formGroupValue: [] }

  headersTable: string[] = [
    'orderNumber',
    'transactionDetail',
    'date',
    'name',
    'infoCostumer',
    'value',
  ]

  dataSource: any
  fund: any = {}
  fieldsForm: any
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public fundService: FundService,
    public customersAssociatesService: CustomersAssociatesService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    public dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.userRole = localStorage.getItem('userRole')
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameFundExtract',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'date',
        formControlName: 'dateFundExtract',
      },
    ]
    this.currentFund()
    this.fetchExtract()
  }

  currentFund () {
    this.fundService
      .find(this.fundId)
      .then(response => {
        this.fund = response
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  fetchExtract () {
    this.fundService
      .getExtract(this.fundId)
      .then(response => {
        this.pageTotal = response.length
        this.dataSource = new MatTableDataSource(response)
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  voltar () {
    this.location.back()
  }

  detail (customerId, customerFullName, customerCpf) {
    if (this.userRole === 'ADMIN') {
      this.dialog.open(ModalCustomerInfoComponent, {
        data: {
          customerCpf: customerCpf,
          customerFullName: customerFullName,
          associateFundCustomerId: customerId,
          fundId: this.fundId,
        },
      })
    }
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.length
    this.dataSource = new MatTableDataSource(returnFilter)
  }
}
