<app-unlogged>
  <div class="login">
    <div class="login__logo">
      <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
    </div>
    <div class="login__content">
      <form class="login__form" [formGroup]="formGroup" (ngSubmit)="submit()">
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input formControlName="emailAddress" matInput />
          <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Senha</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
          <mat-error *ngIf="formGroup.controls.password?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.password) }}
          </mat-error>
        </mat-form-field>

        <div class="login__actions">
          <a routerLink="/auth/forget-password" class="login__actions__link"
            >Esqueci minha senha</a
          >

          <button mat-button type="submit" class="btn-submit-dark">
            Entrar
          </button>
        </div>
      </form>
    </div>
  </div>
</app-unlogged>
