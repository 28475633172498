<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>

  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h4>Qual transação que você deseja criar?</h4>

        <div class="col-12 col-sm-12">
          <mat-card-content>
            <div class="btns-wrapper">
              <button
                *ngIf="data.isMembershipActive"
                mat-button
                class="btn-primary-light btn-auto"
                (click)="onNormalButtonClick()"
              >
                Normal
              </button>
              <button
                *ngIf="data.manualPaymentEnabled"
                mat-button
                class="btn-primary-light btn-manual"
                (click)="onManualButtonClick()"
              >
                Manual
              </button>
              <button
                *ngIf="data.futurePaymentEnabled"
                mat-button
                class="btn-primary-light btn-future"
                (click)="onPaymentAnticipatedButtonClick()"
              >
                Futura
              </button>
            </div>
          </mat-card-content>
        </div>
        <div class="col-12 col-sm-12">
          <div class="text-obs">
            <p>*Normal: todas as transações padrões</p>
            <p>*Manual: APENAS para casos de exceção quando o cliente está sem o aplicativo</p>
            <p>*Futura: transações em casos de reservas com adiantamento e/ou deposito</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <!-- <div class="login__actions">

        <button mat-button type="submit" class="btn-submit-dark">
          Cancelar Venda
        </button>
      </div> -->
  </div>
</main>
