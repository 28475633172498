import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OnInit } from '@angular/core';
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component';
var FundExtractComponent = /** @class */ (function () {
    function FundExtractComponent(fundService, customersAssociatesService, snackBar, route, router, location, dialog) {
        this.fundService = fundService;
        this.customersAssociatesService = customersAssociatesService;
        this.snackBar = snackBar;
        this.route = route;
        this.router = router;
        this.location = location;
        this.dialog = dialog;
        this.fundId = '';
        this.params = { formGroupValue: [] };
        this.headersTable = [
            'orderNumber',
            'transactionDetail',
            'date',
            'name',
            'infoCostumer',
            'value',
        ];
        this.fund = {};
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    FundExtractComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.userRole = localStorage.getItem('userRole');
        this.fundId = this.route.snapshot.paramMap.get('fundId');
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameFundExtract',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'date',
                formControlName: 'dateFundExtract',
            },
        ];
        this.currentFund();
        this.fetchExtract();
    };
    FundExtractComponent.prototype.currentFund = function () {
        var _this = this;
        this.fundService
            .find(this.fundId)
            .then(function (response) {
            _this.fund = response;
        })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundExtractComponent.prototype.fetchExtract = function () {
        var _this = this;
        this.fundService
            .getExtract(this.fundId)
            .then(function (response) {
            _this.pageTotal = response.length;
            _this.dataSource = new MatTableDataSource(response);
        })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    FundExtractComponent.prototype.voltar = function () {
        this.location.back();
    };
    FundExtractComponent.prototype.detail = function (customerId, customerFullName, customerCpf) {
        if (this.userRole === 'ADMIN') {
            this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    customerCpf: customerCpf,
                    customerFullName: customerFullName,
                    associateFundCustomerId: customerId,
                    fundId: this.fundId,
                },
            });
        }
    };
    FundExtractComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.length;
        this.dataSource = new MatTableDataSource(returnFilter);
    };
    return FundExtractComponent;
}());
export { FundExtractComponent };
