import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
var ModalFormGeneralComponent = /** @class */ (function () {
    function ModalFormGeneralComponent(errorsService, snackBar, formBuilder, reportService, dialogRef) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        this.customerInvalid = false;
        this.customers = [];
        this.id = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormGeneralComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            customersInput: [null, [Validators.required]],
            beginDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
    };
    ModalFormGeneralComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    ModalFormGeneralComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    ModalFormGeneralComponent.prototype.selected = function (event) {
        this.selectedCustomers.push(event.option.value);
        this.customerInput.nativeElement.value = '';
        this.customerControl.setValue(null);
        event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true);
    };
    ModalFormGeneralComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormGeneralComponent.prototype.submit = function () {
        var _this = this;
        this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true);
        var customers = this.selectedCustomers.map(function (c) { return c.customerId; });
        if (this.formGroup.valid || this.customerInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var filter = "general?customersIds=" + customers + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        this.reportService
            .createReport(filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                window.open(response.fileUrl, '_blank');
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormGeneralComponent;
}());
export { ModalFormGeneralComponent };
