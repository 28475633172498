import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public loading

  constructor (private readonly http: HttpClient) {}

  view (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/settings`).pipe(
      tap(_ => this.log('cms/settings/view')),
      catchError(this.handleError),
    )
  }

  async update (data): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/settings`, data).toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}

  async resetAccessTokens (): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/reset-access-tokens`, {}).toPromise()
  }
}
