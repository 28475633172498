import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BusinessPartnersService = /** @class */ (function () {
    function BusinessPartnersService(http) {
        this.http = http;
    }
    BusinessPartnersService.prototype.list = function (categoryID, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.findOne = function (categoryID, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners/" + id)
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.create = function (categoryID, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, formData, key;
            return tslib_1.__generator(this, function (_a) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
                formData = new FormData();
                for (key in data) {
                    if (!Object.prototype.hasOwnProperty.call(data, key)) {
                        continue;
                    }
                    formData.append(key, data[key]);
                }
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners", formData, { headers: headers })
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.update = function (categoryID, id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, formData, key;
            return tslib_1.__generator(this, function (_a) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
                formData = new FormData();
                for (key in data) {
                    if (!Object.prototype.hasOwnProperty.call(data, key)) {
                        continue;
                    }
                    formData.append(key, data[key]);
                }
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners/" + id, formData, { headers: headers })
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.delete = function (categoryID, businessPartnersID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners/" + businessPartnersID)
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.getS3Url = function (categoryID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/categories/" + categoryID + "/business-partners/s3-slot")
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.changeOrderPartners = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch(environment.apiUrl + "/cms/categories/" + id + "/business-partners/change-order", data)
                        .toPromise()];
            });
        });
    };
    BusinessPartnersService.prototype.uploadToS3 = function (pathAwsUpload, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + pathAwsUpload, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BusinessPartnersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessPartnersService_Factory() { return new BusinessPartnersService(i0.ɵɵinject(i1.HttpClient)); }, token: BusinessPartnersService, providedIn: "root" });
    return BusinessPartnersService;
}());
export { BusinessPartnersService };
