import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { AirportsService } from '../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-airport-informations-list',
  templateUrl: './airport-informations-list.component.html',
  styleUrls: ['./airport-informations-list.component.scss'],
})
export class AirportInformationsListComponent implements OnInit {
  public headersTable: string[] = ['title', 'order', 'actions']
  public airport: any[] = []
  public airportID: string
  public titleAirport: string

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.airportID = this.route.snapshot.paramMap.get('airportID')

    this.airportsService.findOne(this.airportID).then(
      response => {
        this.titleAirport = response.title
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    this.listInformations()
  }

  listInformations () {
    this.airportsService.listInformations(this.airportID).then(
      response => {
        this.airport = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa informação?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.deleteInformation(id).then(
            async response => {
              this.listInformations()
              this.snackBar.open('Informação removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
