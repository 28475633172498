<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Icone</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/categories/{{ categoryID }}/exclusives"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
              <mat-error *ngIf="formGroup.controls.description?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.description) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>icone</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInput.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a *ngIf="croppedImage" matSuffix mat-icon-button (click)="editImage()">
                <mat-icon>edit</mat-icon>
              </a>
              <a *ngIf="croppedImage" matSuffix mat-icon-button (click)="removeImage()">
                <mat-icon>delete</mat-icon>
              </a>
              <img *ngIf="croppedImage != ''" width="590" src="{{ croppedImage }}" />
              <input hidden (change)="selectFile($event)" #fileInput type="file" accept="image/*" />
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="invalidImage">
              Campo deve ser preenchido
            </mat-error>

            <mat-form-field>
              <mat-label>link</mat-label>
              <input matInput formControlName="exclusiveLink" />
              <mat-error *ngIf="formGroup.controls.exclusiveLink?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.exclusiveLink) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <div class="check">
            <input formControlName="isActive" type="checkbox" />
            <mat-label> &nbsp;Ativo</mat-label>
          </div>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
