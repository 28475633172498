<app-logged class="architect-invoice-detail" *ngIf="invoice">
  <header class="architect-invoice-detail__header">
    <h2>
      NOTA FISCAL Nº: {{ invoice?.invoiceNumber }} - Parceiro: {{ invoice?.stores.nomeFantasia }}
    </h2>
    <div class="architect-invoice-detail__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <mat-card>
    <div class="row">
      <div class="col-12 col-sm-12">
        <mat-card-title>Informações Básicas</mat-card-title>
        <mat-card-content>
          <p>
            <strong>Valor:</strong> {{ convertCentsToReal(invoice.totalValue) | currency: 'R$' }}
          </p>
          <p><strong>Número de série:</strong> {{ invoice.invoiceSerial }}</p>
          <p><strong>Criada em:</strong> {{ invoice.createdAt | date: 'dd/MM/yyyy HH:mm' }}</p>
          <p>
            <strong>Recebedor dos Jcoins:</strong> {{ invoice.receiverName }}
            -

            <span *ngIf="invoice.type === 'PJ'"
              ><strong>CNPJ:</strong>
              {{ invoice.targetDocument | mask: '00.000.000/0000-00' }}</span
            >
            <span *ngIf="invoice.type === 'PF'"
              ><strong>CPF:</strong> {{ invoice.targetDocument | mask: '000.000.000-00' }}</span
            >
          </p>
          <p><strong>Taxa cashback:</strong> {{ multiplyTo100(invoice.stores?.rateCashback) }} %</p>
          <p>
            <strong>Valor taxa cashback:</strong>
            {{ convertCentsToReal(invoice.taxCashbackAmount) | currency: 'BRL' }}
          </p>
          <p>
            <strong>Taxa administrativa:</strong>
            {{ multiplyTo100(invoice.stores?.rateAdministrative) }} %
          </p>
          <p>
            <strong>Valor taxa administrativa:</strong>
            {{ convertCentsToReal(invoice.taxAdministrativeAmount) | currency: 'BRL' }}
          </p>
          <p>
            <strong>Valor repasse para JHSF:</strong>
            {{
              convertCentsToReal(invoice.taxCashbackAmount + invoice.taxAdministrativeAmount)
                | currency: 'BRL'
            }}
          </p>
          <p>
            <strong>Status:</strong>
            <strong [ngStyle]="{ color: invoiceStatus.color }">
              {{ invoiceStatus.text }}
            </strong>
          </p>
          <p *ngIf="invoice.invalidated && invoice.invalidateReason">
            <strong>Motivo da rejeição:</strong>
            <span>
              {{ invoice.invalidateReason }}
            </span>
          </p>
          <p>
            <a [href]="invoice.pdfUrl" target="_blank">
              Visualizar pdf <mat-icon style="font-size: 12px">open_in_new</mat-icon>
            </a>
          </p>
          <p *ngIf="this.isArchitectAdmin() && invoiceDetails?.architectInvoiceClosing">
            <strong>Fechamento: </strong>
            <a
              routerLink="/dashboard/architect-closings/{{
                invoiceDetails.architectInvoiceClosing.id
              }}"
            >
              Essa nota pertence ao fechamento feito de
              {{ invoiceDetails.architectInvoiceClosing.startDate | date: 'dd/MM/yyyy' }}
              à {{ invoiceDetails.architectInvoiceClosing.endDate | date: 'dd/MM/yyyy' }}
            </a>
          </p>
          <strong>Observações:</strong>
          <div [innerHTML]="invoice.observation"></div>
        </mat-card-content>
      </div>
    </div>
  </mat-card>

  <div *ngIf="isArchitectAdmin()">
    <div *ngIf="showValidateButtons()">
      <br />
      <mat-card>
        <div class="row">
          <div class="col-12">
            <mat-card-title>Ações</mat-card-title>
            <mat-card-content>
              <button class="btn-primary-light" mat-button (click)="validateInvoice()">
                Validar nota fiscal
              </button>
              &nbsp;
              <button class="btn-primary-light" mat-button (click)="invalidateInvoice()">
                Invalidar nota fiscal
              </button>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>

    <br />
    <mat-card>
      <div class="row">
        <div class="col-12">
          <mat-card-title>Recebedor(es) do Cashback (Jcoins)</mat-card-title>

          <mat-card-content>
            <div *ngIf="invoice.company">
              <strong>Nota criada em favor de:</strong> {{ invoice.company.nomeFantasia }}
            </div>

            <div class="table-responsive">
              <table mat-table [dataSource]="invoiceDetails?.architectPaymentsHistories || []">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">{{ getCustomerName(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="fee">
                  <th mat-header-cell *matHeaderCellDef>Participação</th>
                  <td mat-cell *matCellDef="let element">{{ element.comissionFee }} %</td>
                </ng-container>

                <ng-container matColumnDef="jcoins">
                  <th mat-header-cell *matHeaderCellDef>Jcoins à receber</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.paymentHistories?.jcoinsAmount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="cashbackStatus">
                  <th mat-header-cell *matHeaderCellDef>Status Cashback</th>
                  <td mat-cell *matCellDef="let element">{{ getCustomerJcoinStatus(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="customerStatus">
                  <th mat-header-cell *matHeaderCellDef>Status do Cliente</th>
                  <td mat-cell *matCellDef="let element">
                    {{ !element.customer ? 'Aguardando cadastro do cliente' : 'Cliente Ok' }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
              </table>
            </div>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
</app-logged>
