import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { LiquidationTransferService, TransferRetryRequestDTO } from 'src/app/services/liquidationTransfer.service'
import { ModalLiquidationTransferDetails } from '../modal-liquidation-transfer-details/modal-liquidation-transfer-details.component'
import { ModalTransferLogs } from '../modal-transfer-logs/modal-transfer-logs.component'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import { Location } from '@angular/common'
import { enumTransferApprovalStatus } from 'src/app/models/enumTransferApprovalStatus'
import { ModalManualTedRequestComponent } from 'src/app/components/modal-manual-ted-request/modal-manual-ted-request.component'

@Component({
  selector: 'app-transfer-schedule-list',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss'],
})
export class LiquidationTransferScheduleDetails implements OnInit {
  public schedule: any
  public transfers: any
  public retryButtonsClicked: any = {}
  public headersTable: string[] = ['createdAt', 'totalValue', 'status', 'executedAt', 'actions']
  public enumTransferApprovalStatus= enumTransferApprovalStatus
  public scheduleId
  public enableRetryButton = false

  constructor(
    private readonly liquidationTransferService: LiquidationTransferService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private readonly location: Location,
  ) {
    this.scheduleId = this.route.snapshot.paramMap.get('scheduleId')
  }

  ngOnInit(): void {
    this.loadScheduleInfo()
  }

  async loadScheduleInfo() {
    const result = await this.liquidationTransferService.getScheduleDetail(this.scheduleId).toPromise()
    this.schedule = result
    this.transfers = new MatTableDataSource(result.transfer)

    this.enableRetryButton = this.checkIfRetryIsAvailable()
  }

  checkIfRetryIsAvailable() {
    const transfers = this.schedule.transfer;

    if(!transfers) return false

    if(this.schedule.success !== false ) return false

    if(this.schedule.status !== enumTransferApprovalStatus.ERROR) return false

    return true

  }

  async openTransferDetails(transfer) {
    await this.dialog.open(ModalLiquidationTransferDetails, {
      width: '500px',
      data: transfer,
    }).afterClosed().toPromise()
  }

  async openTransferLogs(transfer) {
    await this.dialog.open(ModalTransferLogs, {
      minWidth: '600px',
      width: '80%',
      data: transfer.logs,
    }).afterClosed().toPromise()
  }

  async retrySendToApproval() {

    const transfers = this.schedule ? this.schedule.transfer: null
    let transferData;
    if(transfers) {
      transfers.sort((a, b) => {
        if(!a.createdAt) return 1
        if(!b.createdAt) return -1
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      transferData = transfers[0]      
    } else {
      console.log("Transferencias nao encontradas para realizar a retentativa")
      return
    }
    this.retryButtonsClicked[1] = true    

    const result = await this.dialog.open(ModalManualTedRequestComponent, {
      width: '500px',
      data: {
        text: 'Caso queira atualizar os dados bancários nesse momento, após, será necessário atualizar no cadastro do parceiro.',
        buttonConfirmText: 'enviar',
        buttonNot: 'Cancelar',
        dueDate: this.schedule.dueDate,
        ...transferData,
      },
    }).afterClosed().toPromise()
    if (result && result !== 'not') {      

      const retryPaymentData: TransferRetryRequestDTO = {
        transferId: transferData.id,
        bankAccountNumber: result.bankAccountNumber ?
          result.bankAccountNumber :
          transferData.bankAccountNumber,
        bankNumber: result.bankNumber ? result.bankNumber : transferData.bankNumber,
        bankAccountDigit: result.bankAccountDigit ?
          result.bankAccountDigit :
          transferData.bankAccountDigit,
        bankAgency: result.bankAgency ? result.bankAgency : transferData.bankAgency,
        bankAccountType: transferData.bankAccountType,
        bankPixKey: transferData.bankPixKey,
        propagateChangesToNextTransferSchedules: result.propagateChangesToNextTransferSchedules,
        propagateChangesToStore: result.propagateChangesToStore,
        storeCnpj: transferData.storeCnpj,
        scheduleToNextBusinessDay: result.scheduleToNextBusinessDay,
      } 

      try {
        const result = await this.liquidationTransferService.retrySendToApproval(retryPaymentData).toPromise()
        const currentUrl = this.router.url;
        this.snackBar.open('Solicitação de TED enviada para processamento')
        setTimeout(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
       
      } catch (err) {
        this.retryButtonsClicked[1] = false
        this.snackBar.open('Erro ao tentar realizar a Solicitação de TED')
      }
    } else {
      this.retryButtonsClicked[1] = false 
    }
  }

  goBack(){
    this.location.back()
  }

  getLastStatusUpdateDate(){
    
    const transfers = this.schedule ? this.schedule.transfer: null

    if(transfers) {
      transfers.sort((a, b) => {
        if(!a.statusUpdatedAt) return 1
        if(!b.statusUpdatedAt) return -1
        return new Date(b.statusUpdatedAt).getTime() - new Date(a.statusUpdatedAt).getTime()
      })
    }

    return transfers && transfers[0] ? transfers[0].statusUpdatedAt : null
  }

  getStatus(){
   const mappedText = {
     [enumTransferApprovalStatus.PENDING_APPROVAL]: 'Pendente de aprovação' ,
     [enumTransferApprovalStatus.APPROVAL_GRANTED]: 'Aprovação concedida' ,
     [enumTransferApprovalStatus.APPROVAL_REJECTED]: 'Aprovação rejeitada', 
    }

    return mappedText[this.schedule.status] || ' - '
  }
}
