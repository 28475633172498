import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BillPaymentNotificationService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (params: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/bill-payment-notifications?${params}`).toPromise()
  }

  async findOne (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/bill-payment-notifications/${id}`).toPromise()
  }
}
