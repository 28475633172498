import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'

import { ErrorsService } from '../../../../../core/services/errors.service'
import { AuthService } from '../../../../../services/auth.service'

@Component({
  selector: 'app-documents-not-integration-form',
  templateUrl: './documents-not-integration.component.html',
  styleUrls: ['./documents-not-integration.component.scss'],
})
export class DocumentsNotIntegrationFormComponent implements OnInit {
  public formGroup: FormGroup
  customers: any[] = []
  id: any

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      cpf: [null, [Validators.required]],
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async findOne () {
    await this.authService.findOneDocumentsNotIntegration(this.id)
      .then(async response => {
        this.formGroup.patchValue({
          cpf: response.cpf,
        })
      })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      cpf: this.formGroup.value.cpf,
    }

    if (this.id) {
      this.authService.updateDocumentsNotIntegration(this.id, data).then(
        async response => {
          if (response) {
            await this.router.navigate(['./dashboard/documents-not-integration'])
            this.snackBar.open('CPF atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.createDocumentsNotIntegration(data).then(
        async response => {
          if (response) {
            await this.router.navigate(['./dashboard/documents-not-integration'])
            this.snackBar.open('CPF cadastrado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
