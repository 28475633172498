<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Taxa cashback do parceiro de origem</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airports/tax-cashback/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.stores.cnpj | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Nome fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.stores.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="taxCashback">
        <th mat-header-cell *matHeaderCellDef>taxa Cashback</th>
        <th></th>
        <td mat-cell *matCellDef="let element">
          {{ element.percentage * 100 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!element.preRegistration"
            mat-icon-button
            matTooltip="Editar"
            routerLink="/dashboard/airports/tax-cashback/{{ element.id }}/edit"
            matTooltipPosition="left"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
