<app-unlogged>
  <div class="forgot-password">
    <div class="forgot-password__logo">
      <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
    </div>
    <div class="forgot-password__content">
      <p>Caso tenha esquecido sua senha, digite o e-mail do seu cadastro no campo abaixo.</p>
      <form
        class="forgot-password__form"
        [formGroup]="formGroup"
        (ngSubmit)="submit()"
      >
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input formControlName="emailAddress" matInput />
          <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
          </mat-error>
        </mat-form-field>

        <div class="forgot-password__actions">
          <a routerLink="/login" class="forgot-password__actions__link"
            >Voltar a área de login</a
          >

          <button mat-button type="submit" class="btn-submit-dark">
            Criar nova senha
          </button>
        </div>
      </form>
    </div>
  </div>
</app-unlogged>
