import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { PasswordValidator } from 'src/app/validators/password.validator'
import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { TransactionService } from 'src/app/services/transaction.service'
import { Store } from 'src/app/models/store'
import { MatSnackBar } from '@angular/material'
import { DecimalPipe, CurrencyPipe } from '@angular/common'
import { AccountBankService } from 'src/app/services/accountBank.service'
import { bankCode } from 'src/app/models/bankCode'
import { StoreDocumentService } from 'src/app/services/storeDocument'

@Component({
  selector: 'app-store-form',
  templateUrl: './store-documents-form.component.html',
  styleUrls: ['./store-documents-form.component.scss'],
})
export class StoreDocumentsFormComponent implements OnInit {
  public formGroup: FormGroup
  public storeID = ''
  public documentUrl = ''
  public pathAwsUpload = ''
  public urlUploadS3: any
  public uploadData: any
  public s3Path: any
  fileUrl: any
  fileName: any
  @ViewChild('fileInputBanner', { static: false }) fileInputBanner: ElementRef
  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public storeDocumentService: StoreDocumentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
  ) {
    this.initForm()
  }

  ngOnInit () {}

  initForm () {
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.formGroup = this.formBuilder.group({
      documentType: [null, [Validators.required]],
    })
  }

  async selectFile (event) {
    this.fileUrl = event.target.files[0]
    this.fileName = event.target.files[0].name

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0])
      reader.onload = evt => {
        // called once readAsDataURL is completed
        this.documentUrl = (<any>evt).target.result
      }
    } else {
      this.removeDocument()
    }
  }

  imgLoadError () {
    this.documentUrl = null
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.fileName = undefined
    this.fileInputBanner.nativeElement.value = ''
  }

  async submit () {
    if (!this.formGroup.valid || !this.fileUrl) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    await this.storeDocumentService
      .add(this.storeID, this.formGroup.value.documentType)
      .toPromise()
      .then(
        async response => {
          this.urlUploadS3 = response.url
          this.uploadData = new FormData()
          this.uploadData.append('file', this.fileUrl, this.fileName)
          this.s3Path = response.s3Path
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )

    await this.storeDocumentService.upload(this.urlUploadS3, this.uploadData).toPromise()

    await this.storeDocumentService
      .create({ s3Path: this.s3Path })
      .toPromise()
      .then(
        async response => {
          this.snackBar.open('Documento enviado para análise.')
          this.router.navigate(['./dashboard/stores/' + this.storeID + '/documents'])
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }
}
