import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FundService = /** @class */ (function () {
    function FundService(http) {
        this.http = http;
    }
    FundService.prototype.list = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/funds" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    FundService.prototype.find = function (fundId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/funds/" + fundId).toPromise()];
            });
        });
    };
    FundService.prototype.create = function (fund) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/funds", fund)
                        .toPromise()];
            });
        });
    };
    FundService.prototype.edit = function (fundId, fund) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/funds/" + fundId, fund)
                        .toPromise()];
            });
        });
    };
    FundService.prototype.delete = function (fundId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/funds/" + fundId)
                        .toPromise()];
            });
        });
    };
    FundService.prototype.getExtract = function (fundId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/funds/" + fundId + "/extract" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    FundService.prototype.getTransactions = function (fundId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/funds/" + fundId + "/transactions" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    FundService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundService_Factory() { return new FundService(i0.ɵɵinject(i1.HttpClient)); }, token: FundService, providedIn: "root" });
    return FundService;
}());
export { FundService };
