import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var PrepaymentsListComponent = /** @class */ (function () {
    function PrepaymentsListComponent(prepaymentsService) {
        this.prepaymentsService = prepaymentsService;
        this.headersTable = [
            'paymentDate',
            'paymentGrossAmount',
            'paymentNetAmount',
            'zoopFee',
            'jhsfFee',
            'state',
        ];
        this.prepayments = [];
    }
    PrepaymentsListComponent.prototype.ngOnInit = function () {
        this.listAll();
    };
    PrepaymentsListComponent.prototype.listAll = function () {
        var _this = this;
        this.prepaymentsService.listAll().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.prepayments = response.items.map(function (prepayment) {
                    prepayment.paymentGrossAmount = prepayment.paymentGrossAmount / 100;
                    prepayment.paymentNetAmount = prepayment.paymentNetAmount / 100;
                    return prepayment;
                });
                return [2 /*return*/];
            });
        }); }, function (error) { });
    };
    return PrepaymentsListComponent;
}());
export { PrepaymentsListComponent };
