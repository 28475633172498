import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var DocumentsEligibleFormComponent = /** @class */ (function () {
    function DocumentsEligibleFormComponent(errorsService, snackBar, formBuilder, route, airportsService, router) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.route = route;
        this.airportsService = airportsService;
        this.router = router;
        this.customers = [];
        this.dynamicMask = '000.000.000-00';
    }
    DocumentsEligibleFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            prefix: [null, [Validators.required]],
        });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    DocumentsEligibleFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    DocumentsEligibleFormComponent.prototype.onDocumentInput = function () {
        var documentValue = this.formGroup.get('document').value;
        if (documentValue.length === 14) {
            this.dynamicMask = '00.000.000/0000-00';
        }
    };
    DocumentsEligibleFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    prefix: this.formGroup.value.prefix,
                };
                this.airportsService.documentsEligibleCreate(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.router.navigate(['./dashboard/airports/not-eligible'])];
                            case 1:
                                _a.sent();
                                this.snackBar.open('Prefixo da Aeronave cadastrado com sucesso.');
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return DocumentsEligibleFormComponent;
}());
export { DocumentsEligibleFormComponent };
