import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-verify-store-campaign-type-modal',
  templateUrl: './modal-verify-store-campaign-type.component.html',
  styleUrls: ['./modal-verify-store-campaign-type.component.scss'],
})
export class ModalVerifyStoreCampaignTypeComponent implements OnInit {
  public formGroup: FormGroup
  public verifiedStores: any[] = []

  constructor (
    public dialogRef: MatDialogRef<ModalVerifyStoreCampaignTypeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      results: any
    },
  ) { }

  ngOnInit () { }

  changeFromStoreListCheckbox (event, i) {
    this.data.results[i].stores
      .map((partner) => {
        if (partner.id === event.target.value) {
          partner.checked = event.target.checked
        }
      })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {
    const checkedStores = this.data.results
      .map(campaign => campaign.stores.filter(store => !store.checked))
    this.dialogRef.close(checkedStores)
  }
}
