<main class="modal">
  <a class="link-cancel" mat-icon-button matTooltip="Cancelar" (click)="cancel()" matTooltipPosition="left">
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <h3><strong>AVISO</strong></h3>      
      <div class="row">
       <p>
        A conta bancária do parceiro foi alterada e existem agendamentos para TEDs de liquidação desse parceiro
        que podem ser alterados com os dados atualizados da conta bancária.
       </p>
       <p>
        Clique em "Alterar os agendamentos" para atualizar ou cancelar para ignorar.
       </p>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    
      <button (click)="cancel()" mat-button class="btn-submit-dark">Não alterar os agendamentos</button>
    
      <button (click)="confirm()" mat-button class="btn-submit-dark">Alterar os agendamentos</button>
    
    
  </div>
</main>
