<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Histórico de JCoins</h2>
    <div class="store-list__header__actions">
      <button
        *ngIf="typePreRegistration"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration/INCORPORATION_AIRPORT"
      >
        VOLTAR
      </button>
      <button
        *ngIf="!typePreRegistration"
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/pre-registration"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="preRegistration" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ preRegistration.firstName }} {{ preRegistration.lastName }}</div>
      <div><b>CPF: </b>{{ preRegistration.cpf | mask: '000.000.000-00' }}</div>
      <div><b>TOTAL JCOINS: </b>{{ preRegistration.balance }}</div>
    </mat-card>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cnpj | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>JCOINS</th>
        <td mat-cell *matCellDef="let element">{{ element.balance }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
