import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, } from '@angular/material';
var preRegistrationAddressFormComponent = /** @class */ (function () {
    function preRegistrationAddressFormComponent(formBuilder, errorsService, route, preRegistrationService, zipCodeService, snackBar, location) {
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.route = route;
        this.preRegistrationService = preRegistrationService;
        this.zipCodeService = zipCodeService;
        this.snackBar = snackBar;
        this.location = location;
        this.initalized = false;
    }
    preRegistrationAddressFormComponent.prototype.ngOnInit = function () {
        this.addressID = this.route.snapshot.paramMap.get('addressID');
        this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID');
        this.formGroup = this.formBuilder.group({
            addressTitle: [null, [Validators.required]],
            street: [null, []],
            addressNumber: [null, []],
            complement: [null, []],
            zipCode: [null, []],
            neighborhood: [null, []],
            city: [null, []],
            state: [null, []],
            country: [null, []],
            kind: [null, []],
        });
        if (this.addressID) {
            this.addressFindOne();
        }
    };
    preRegistrationAddressFormComponent.prototype.addressFindOne = function () {
        var _this = this;
        this.preRegistrationService.addressFindOne(this.addressID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.patchValue({
                    addressTitle: response.addressTitle,
                    street: response.street,
                    addressNumber: response.addressNumber,
                    complement: response.complement,
                    zipCode: response.zipCode,
                    neighborhood: response.neighborhood,
                    city: response.city,
                    state: response.state,
                    country: response.country,
                    kind: response.kind,
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    preRegistrationAddressFormComponent.prototype.getAddress = function () {
        var _this = this;
        if (this.formGroup.value.zipCode) {
            this.zipCodeService.getAddress(this.formGroup.value.zipCode).subscribe(function (address) {
                _this.formGroup.patchValue({
                    state: address.uf,
                    city: address.localidade,
                    neighborhood: address.bairro,
                    street: address.logradouro,
                });
            });
        }
    };
    preRegistrationAddressFormComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var data = {
            addressTitle: this.formGroup.value.addressTitle,
            street: this.formGroup.value.street,
            addressNumber: this.formGroup.value.addressNumber,
            complement: this.formGroup.value.complement ? this.formGroup.value.complement : '',
            zipCode: this.formGroup.value.zipCode,
            neighborhood: this.formGroup.value.neighborhood,
            city: this.formGroup.value.city,
            state: this.formGroup.value.state,
            country: this.formGroup.value.country,
            customerId: this.customerID,
            kind: this.formGroup.value.kind ? this.formGroup.value.kind : undefined,
        };
        if (this.addressID) {
            this.preRegistrationService.updateAddress(this.addressID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.location.back();
                    this.snackBar.open('Endereço cadastrado com sucesso.');
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            this.preRegistrationService.createAddress(data, this.preRegistrationID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.location.back();
                    this.snackBar.open('Endereço cadastrado com sucesso.');
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
    };
    return preRegistrationAddressFormComponent;
}());
export { preRegistrationAddressFormComponent };
