<app-logged class="funds-list">
  <header class="funds-list__header">
    <h2>Associados ao Fundo</h2>
    <div class="funds-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/funds" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
      <button class="btn-primary-light" mat-button (click)="addAssociate()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter maskExample="000.000.000-00" actions="form" [fundId]="fundId" type="FundCustomersAssociates"
    [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card"><b>Fundo:</b> {{ fund.name }}</mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="200px">Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="infoCostumer">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" matTooltip="CPF: {{ element.cpf | mask: '000.000.000-00' }}">
          <button *ngIf="element.customerId" mat-icon-button (click)="detail(element.customerId)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="removeAssociate(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter type="FundCustomersAssociates" actions="pagination" [fundId]="fundId" [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
