import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ArchitectInvoiceService {
  constructor (private readonly http: HttpClient) { }

  createInvoice (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/architect-invoices`, data).pipe(
      tap(_ => this.log('cms/architect-invoices')),
      catchError(this.handleError),
    )
  }

  updateInvoice (data, architectInvoiceId): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/architect-invoices/${architectInvoiceId}`, data).pipe(
      tap(_ => this.log('cms/architect-invoices')),
      catchError(this.handleError),
    )
  }

  searchArchitectForInvoice (searchTerm): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/cms/architect-invoices/comission-receivers-search?search=${searchTerm}`,
      {
        headers: new HttpHeaders({ noloading: 'true' }),
      })
      .pipe(
        tap(_ => this.log('cms/architect-invoices/comission-receivers-search')),
        catchError(this.handleError),
      )
  }

  getUploadUrl (storeBusinessUnitId, filename): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/architect-invoices/${storeBusinessUnitId}/create-upload-url`, {
      filename,
    }).pipe(
      tap(_ => this.log(`/cms/architect-invoices/${storeBusinessUnitId}/create-upload-url`)),
      catchError(this.handleError),
    )
  }

  list ({ storeBusinessUnitId, filter }: { storeBusinessUnitId?: string, filter?: string }): Promise<any> {
    if (storeBusinessUnitId) { filter = (filter || '?') + `&storeId=${storeBusinessUnitId}` }

    return this.http
      .get<any>(`${environment.apiUrl}/cms/architect-invoices${filter || ''}`)
      .toPromise()
  }

  get (architectInvoiceId): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/architect-invoices/${architectInvoiceId}`)
      .toPromise()
  }

  getDetails (architectInvoiceId): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/architect-invoices/${architectInvoiceId}/details`)
      .toPromise()
  }

  uploadInvoicePdf (pathAwsUpload, file): Observable<any> {
    const headers = {
      'Content-Type': file.type,
    }
    return this.http
      .put<any>(`${pathAwsUpload}`, file, { headers })
      .pipe(
        tap(_ => this.log('/cms/architect-invoices/')),
        catchError(this.handleError),
      )
  }

  removeInvoicePdf (invoiceId, pdfUrl) {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/architect-invoices/${invoiceId}/remove-pdf?publicUrl=${pdfUrl}`)
      .toPromise()
  }

  cancelInvoice (invoiceId) {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/architect-invoices-cancel`, invoiceId)
      .toPromise()
  }

  approveInvoices (invoiceIds) {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/architect-invoices/approve`, { invoices: invoiceIds })
      .toPromise()
  }

  validateInvoices (invoiceIds) {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/architect-invoices/validate`, { invoices: invoiceIds })
      .toPromise()
  }

  invalidateInvoices (invoiceId, invalidateReason) {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/architect-invoices/invalidate`, { invoiceId, invalidateReason })
      .toPromise()
  }

  getInvoiceStatus (invoice): { text: string, color: string } {
    const {
      canceled,
      approved,
      validated,
      invalidated,
    } = invoice

    if (invalidated) return { text: 'Pedido rejeitado', color: 'red' }
    if (canceled) return { text: 'Cancelada', color: 'red' }
    if (approved) return { text: 'Paga', color: 'green' }
    if (validated) return { text: 'Pedido pendente', color: 'orange' }
    return { text: 'Aguardando validação', color: 'orange' }
  }

  private handleError (error: any) {
    return throwError(error)
  }

  private log (message: string) {
    console.log(message)
  }
}
