<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>

  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h3>Deseja realmente continuar?</h3>
        <h4>
          Devido ao SLA de 3 dias úteis para a LL Loyalty ativar as campanhas, sugerimos que a data
          de início da campanha seja maior que 4 dias úteis a partir da data da criação da campanha
          no JHSF ID para evitar possíveis erros. Deseja criar mesmo assim?
        </h4>
      </div>
      <div class="row">
        <div mat-dialog-actions class="modal__card_button">
          <button class="btn-primary-light" type="submit" (click)="submit()" mat-button>Sim</button>
        </div>
        <div mat-dialog-actions class="modal__card_button">
          <button class="btn-primary-light" (click)="cancel()" mat-button>Não</button>
        </div>
      </div>
    </mat-card>
  </div>
</main>
