import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalTransferManualComponent = /** @class */ (function () {
    function ModalTransferManualComponent(dialogRef, data, snackBar, sanitizer, formBuilder, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        console.log('data', data);
    }
    ModalTransferManualComponent.prototype.cancel = function () {
        this.dialogRef.close('not');
    };
    ModalTransferManualComponent.prototype.confirm = function () {
        this.dialogRef.close({
            bankAgency: this.formGroup.value.bankAgency,
            bankNumber: this.formGroup.value.bankNumber,
            bankAccountNumber: this.formGroup.value.bankAccountNumber,
            bankAccountDigit: this.formGroup.value.bankAccountDigit,
            propagateChangesToNextTransferSchedules: this.formGroup.value.propagateChangesToNextTransferSchedules,
            propagateChangesToStore: this.formGroup.value.propagateChangesToStore,
        });
    };
    ModalTransferManualComponent.prototype.not = function () {
        this.dialogRef.close('not');
    };
    ModalTransferManualComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            bankAgency: [this.data.bankAgency, [Validators.required]],
            bankNumber: [this.data.bankNumber, [Validators.required]],
            bankAccountNumber: [this.data.bankAccountNumber, [Validators.required]],
            bankAccountDigit: [this.data.bankAccountDigit, [Validators.required]],
            propagateChangesToNextTransferSchedules: [false, []],
            propagateChangesToStore: [false, []],
        });
    };
    ModalTransferManualComponent.prototype.getSanitizedHtml = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    ModalTransferManualComponent.prototype.submit = function () {
        console.log();
    };
    return ModalTransferManualComponent;
}());
export { ModalTransferManualComponent };
