import { SegmentationService } from 'src/app/services/segmentation.service'
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material'
import { Component, ViewChild, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-segmentation-results',
  templateUrl: './segmentation-results.component.html',
  styleUrls: ['./segmentation-results.component.scss'],
})
export class SegmentationResultsComponent implements OnInit {
  public name: string
  public pageTotal: number
  headersTable: string[] = ['cpf', 'name']
  dataSource: any
  segmentationId: string
  fieldsForm: any
  public params = { formGroupValue: [] }

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public segmentationService: SegmentationService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.segmentationId = this.route.snapshot.paramMap.get('segmentationId')
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.listCustomers().catch(err => {
      this.snackBar.open(err.message)
    })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfSegmentationResults',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameSegmentationResults',
        label: 'Nome',
        placeholder: 'Nome',
      },
    ]

    this.segmentationService.find(this.segmentationId).then(
      async response => {
        this.name = response.name
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async listCustomers () {
    const { results, total } = await this.segmentationService
      .listCustomers(
        this.segmentationId,
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
      )
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
