import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { CustomerAttendantService } from '../../../../services/customer-attendant.service'

@Component({
  selector: 'app-customer-attendant-form',
  templateUrl: './customer-attendant-form.component.html',
  styleUrls: ['./customer-attendant-form.component.scss'],
})
export class CustomerAttendantFormComponent implements OnInit {
  public formGroup: FormGroup
  public id: string

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public customerAttendantService: CustomerAttendantService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.customerAttendantService.findOne(this.id).then(async response => {
      this.formGroup.patchValue({
        firstName: response.firstName,
        lastName: response.lastName,
        phoneNumber: response.phoneNumber,
      })
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      firstName: this.formGroup.value.firstName,
      lastName: this.formGroup.value.lastName,
      phoneNumber: this.formGroup.value.phoneNumber,
    }

    if (this.id) {
      this.customerAttendantService.update(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/attendant'])
            this.snackBar.open('Atendente atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.customerAttendantService.create(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/attendant'])
            this.snackBar.open('Atendente criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
