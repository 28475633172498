import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-client-export-to-salesforce',
  templateUrl: './client-export-to-salesforce.html',
  styleUrls: ['./client-export-to-salesforce.scss'],
})
export class ClientExportToSalesforceComponent implements OnInit {  
  exportRunning: boolean = false;
  updateRunning: boolean = false;
  watchRunning: boolean = false;
  stopIntervalNextTick: boolean = false;
  exportData;
  interval;
  whatchRunning: boolean = false
  from: number = 0
  quantity : number = 4

  constructor (private readonly http: HttpClient) { }

  executeStartExport (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/salesforce/start-export`, { headers: {noloading: 'true'} } ).toPromise()    
  }

  executeUpdate (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/salesforce/update-exported?qtd=10`, { headers: {noloading: 'true'} } ).toPromise()    
  }

  ngOnInit() {
  }

  async startExportClick(){

    if(this.exportRunning) return

    this.exportRunning = true
    try {
      this.startWatchData()
      await this.executeStartExport()
    } catch (error) {
      console.log("ERRO AO EXPORTAR", error)
    }
    this.exportRunning = false
  }

  async startUpdateClick(){

    if(this.updateRunning) return

    this.updateRunning = true
    try {
      this.startWatchData()
      await this.executeUpdate()
    } catch (error) {
      console.log("ERRO AO EXPORTAR", error)
    }
    this.updateRunning = false
  }


  getExportDataList (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/salesforce/list-exported`, { headers: {noloading: 'true'} } ).toPromise()    
  }

  startWatchData(){
    this.watchRunning = true
    this.interval = setInterval(async () => {
      const res = await this.getExportDataList()
      this.exportData = res
      if(this.stopIntervalNextTick) {
        this.stopIntervalNextTick = false
        this.watchRunning = false
        clearInterval(this.interval)        
      }
    }, 3000)    
  }

  stopWatchData() {
    this.watchRunning = false
    this.stopIntervalNextTick = true
  }

  getJCoinsFromJsonPayload(payload) {
    let saldo = '-'
    if(payload) {
      const obj = JSON.parse(payload)
      saldo = obj.Saldo_jCoins__c
      if(obj.updateJcoins && obj.updateJcoins.data)
        saldo += " | updated: "+obj.updateJcoins.data.Saldo_jCoins__c
    }

    return saldo
  }

}