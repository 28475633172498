import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { TransactionService } from 'src/app/services/transaction.service'
import { Store } from 'src/app/models/store'
import { MatDialog, MatSnackBar } from '@angular/material'
import { DecimalPipe, CurrencyPipe } from '@angular/common'
import { ModalConfirmationInternationalTransactionComponent } from 'src/app/components/modal-confirmation-international-transaction/modal-confirmation-international-transaction.component'

@Component({
  selector: 'app-store-international-transaction-form',
  templateUrl: './store-international-transaction-form.component.html',
  styleUrls: ['./store-international-transaction-form.component.scss'],
})
export class StoreInternationalTransactionFormComponent implements OnInit {
  public userRole: any
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: any
  public store: Store
  public installments: any[] = [1]
  public isValidTotalValue = false
  public isValidTotalValueMsg = ''
  public headersTable: string[] = ['minimumTransactionValue', 'minimumInstallmentValue']
  public transactionsInfo: any[] = []
  public isCardPayment: boolean
  public subStores: any[] = []
  public currencyQuote: number
  public currency: string  
  public params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] }

  constructor (
    public errorsService: ErrorsService,    
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public transactionService: TransactionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
    private readonly dialog: MatDialog,
  ) {
    this.userRole = localStorage.getItem('userRole')
  }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
      this.params.formGroupValue = params.formGroupValue
      this.params.previousRoute = params.previousRoute
      this.params.storeID = params.storeID
      this.storeID = params.storeID
    })
    this.initForm()

  }

  initForm () {
    if (!this.storeID) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
      this.params.storeID = this.storeID
    }

    this.formGroup = this.formBuilder.group({
      customerCpf: [
        null,
        [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
      ],
      internationalCurrencyValue: [null, [Validators.required]],
      description: [null, [Validators.required]],
      maximumInstallments: [1, [Validators.required]],
      isMembershipActive: [null, [Validators.required]],
      isPayActive: [null, [Validators.required]],
      subStoreId: [null, []],
    })

    if (this.storeID != '' && this.storeID != null) {
      const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
      if (storeSelected && this.userRole != 'ADMIN') {
        this.storeID = storeSelected.storeId
      }

      this.storeService.getStore(this.storeID).subscribe(async response => {
        for (const st of response.subStoreAndMacroGroup) {
          if (st.subStore.length) {
            this.subStores.push(st.subStore[0])
          }
        }
        if (this.subStores.length) {
          this.subStores.sort((a, b) => {
            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0
          })
        }
        this.store = response
        this.currency = this.store.currency
        this.formGroup = this.formBuilder.group({
          customerCpf: [
            null,
            [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
          ],
          internationalCurrencyValue: [null, [Validators.required]],
          description: [null, [Validators.required]],          
          isMembershipActive: [this.store.isMembershipActive, [Validators.required]],
          isPayActive: [this.store.isPayActive, [Validators.required]],
          subStoreId: [null, []],
        })

        this.transactionsInfo = [
          {
            minimumTransactionValue: this.store.minimumTransactionValue / 100,
            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
          },
        ]
      })      
            
    }
  }

  convertCentsToMoney (value) {
    const money = this.currencyPipe.transform(value, this.store.currency)
    // this.formGroup.controls['totalValue'].setValue(money);
    console.log(money)
  }

  convertNumberToBoolean (num) {
    if (num == 0) {
      return false
    } else {
      return true
    }
  }

  async openConfirmationModal() {
    try {

      const {
        id,
        valueInReais: totalValue,
        valueInReaisWithTaxes: totalValueWithTaxes,
        currency,
        internationalCurrencyValue,
        currencyQuote,
        purchaseFeePercent,
        purchaseFeeValue,
        customerName,        
      } = await this.submitInternationalPaymentIntent()

      const result = await this.dialog.open(ModalConfirmationInternationalTransactionComponent, {
        width: '500px',
        data: {
          id,
          totalValue,
          totalValueWithTaxes,
          currency,
          internationalCurrencyValue,
          currencyQuote,
          purchaseFeePercent,
          purchaseFeeValue,
          customerName,
          customerCpf: this.formGroup.value.customerCpf,
        },
      }).afterClosed().toPromise()

      if (result.createTransacation === true) {
          await this.submitTransaction(result.idInternationalPaymentIntent)
      }
    } catch (err) {
      this.snackBar.open('Problema ao checar pagamento ND')
    }
  }

  async submitInternationalPaymentIntent () {

    if (!this.formGroup.valid) {
      // this.snackBar.open(error.error.message);
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    const internationalCurrencyValue = this.formGroup.value.internationalCurrencyValue.toFixed(2).replace('.', '')
    const data = {      
      internationalCurrencyValue: parseInt(internationalCurrencyValue),      
      storeId: this.storeID,
      customerCpf: this.formGroup.value.customerCpf,   
    }

    return this.transactionService.createInternationalPaymentIntent(data).toPromise()   

  }

  async submitTransaction (idInternationalPaymentIntent) {
    
    if (!this.formGroup.valid) {
      // this.snackBar.open(error.error.message);
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      id: idInternationalPaymentIntent,
      description: this.formGroup.value.description,
      isPayActive: this.formGroup.value.isPayActive,
      subStoreId: this.formGroup.value.subStoreId,
    }
    this.transactionService.createInternationalTransactionFromIntent(data).subscribe(
      async response => {
        if (response.isCreated) {
          this.snackBar.open('Transação criada com sucesso.')
          this.params = { ...this.params, itComesFromNewTransaction: true }
          this.router.navigate(['./dashboard/transactions/detail/' + response.id], { queryParams: this.params, skipLocationChange: true })
        }
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }
}
