import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var StoreDocumentsListComponent = /** @class */ (function () {
    function StoreDocumentsListComponent(storeDocuments, storeService, route, currencyPipe, snackBar) {
        this.storeDocuments = storeDocuments;
        this.storeService = storeService;
        this.route = route;
        this.currencyPipe = currencyPipe;
        this.snackBar = snackBar;
        this.headersTable = ['type', 'status', 'link'];
        this.documents = [];
        this.storeID = '';
        this.params = { storeFormGroupValue: [] };
    }
    StoreDocumentsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.loadStoreInfo();
        this.list();
    };
    StoreDocumentsListComponent.prototype.list = function () {
        var _this = this;
        this.storeDocuments.list(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.documents = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreDocumentsListComponent.prototype.refresh = function () {
        window.location.reload();
    };
    StoreDocumentsListComponent.prototype.loadStoreInfo = function () {
        var _this = this;
        this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.store = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreDocumentsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.storeDocuments.delete(id, this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Categoria removida com sucesso.');
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    StoreDocumentsListComponent.prototype.convertStatus = function (status) {
        switch (status) {
            case 'CHECKING':
                return 'Corrente';
                break;
            case 'SAVINGS':
                return 'Poupança';
                break;
            default:
                return '';
        }
    };
    StoreDocumentsListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    StoreDocumentsListComponent.prototype.openDocument = function (document) {
        window.open(document, '_blank');
    };
    StoreDocumentsListComponent.prototype.convertType = function (type) {
        switch (type) {
            case 'IDENTIFICATION':
                return 'Identificação';
                break;
            case 'ACTIVITY':
                return 'Atividade';
                break;
            case 'ADDRESS':
                return 'Endereço';
                break;
            case 'CNPJ':
                return 'CNPJ';
                break;
            default:
                return '';
        }
    };
    StoreDocumentsListComponent.prototype.convertStatusDocument = function (status) {
        switch (status) {
            case 'APPROVED':
                return 'Aprovada';
                break;
            case 'REJECTED':
                return 'Rejeitada';
                break;
            case 'PENDING':
                return 'Pendente';
                break;
            default:
                return '';
        }
    };
    return StoreDocumentsListComponent;
}());
export { StoreDocumentsListComponent };
