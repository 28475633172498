<app-logged class="store-list">
  <header class="store-list__header">
    <h2>TRANSAÇÕES</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>
  <div class="row">
    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>Número da Transação</mat-label>
        <input
          matInput
          type="number"
          (keyup)="applyFilter($event.target.value, 'numberTransaction')"
          placeholder="Número da Transação"
        />
      </mat-form-field>
    </div>
    <div class="col-3 col-sm-3" *ngIf="userRole == 'ADMIN' && stores.length > 0">
      <mat-form-field>
        <mat-label>Parceiro</mat-label>
        <mat-select (selectionChange)="applyFilter($event.value, 'store')">
          <mat-option *ngFor="let store of stores" [value]="store.nomeFantasia"
            >{{ store.nomeFantasia }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-3 col-sm-3">
      <mat-form-field>
        <mat-label>CPF do Cliente</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event.target.value, 'cpfClient')"
          mask="000.000.000-00"
          placeholder="CPF do Cliente"
        />
      </mat-form-field>
    </div>

    <div class="col-3">
      <mat-form-field>
        <mat-label></mat-label>
        <input
          type="date"
          matInput
          (change)="applyFilter($event.target.value, 'dateTransaction')"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">{{ element.store.nomeFantasia }}</td>
      </ng-container>
      <ng-container matColumnDef="customerCpf">
        <th mat-header-cell *matHeaderCellDef>CPF do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerCpf | mask: '000.000.000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description != 'dynamic' ? element.description : 'QRcode' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</app-logged>
