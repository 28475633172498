import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalCustomerInfoComponent = /** @class */ (function () {
    function ModalCustomerInfoComponent(dialogRef, data, router) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.router = router;
    }
    ModalCustomerInfoComponent.prototype.ngOnInit = function () { };
    ModalCustomerInfoComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalCustomerInfoComponent.prototype.infoClient = function (customerId) {
        this.dialogRef.close();
        this.router.navigateByUrl("/dashboard/clients/" + customerId + "/info");
    };
    ModalCustomerInfoComponent.prototype.infoClientAssociateFund = function (customerId, fundId) {
        this.dialogRef.close();
        this.router.navigateByUrl("/dashboard/funds/" + fundId + "/associate/client/" + customerId);
    };
    return ModalCustomerInfoComponent;
}());
export { ModalCustomerInfoComponent };
