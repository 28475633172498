import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var ArchitectCompanyCustomersListComponent = /** @class */ (function () {
    function ArchitectCompanyCustomersListComponent(companiesService, snackBar, dialog, route, router) {
        this.companiesService = companiesService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.headersTable = ['cpf', 'fullName', 'actions'];
        this.addNew = true;
    }
    ArchitectCompanyCustomersListComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.list().catch(function (err) { console.log(err); });
        // if (this.id) {
        //   this.findOne().catch(err => console.log(err))
        // }
    };
    // async findOne () {
    //   await this.companiesService.findOne(this.id)
    //     .then(async response => {
    //       this.company = response
    //     })
    // }
    ArchitectCompanyCustomersListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .findOne(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                console.log(response.customers);
                                this.dataSource = new MatTableDataSource(response.customers);
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })
                        // const listDelete = await this.companiesService
                        //   .listCompanyAirportCustomersDelete(this.id)
                        //   .catch(err => {
                        //     this.snackBar.open(err.message)
                        //     return { results: [], total: 0 }
                        //   })
                        // this.dataSourceDelete = new MatTableDataSource(listDelete.results)
                    ];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectCompanyCustomersListComponent.prototype.remove = function (id, companyId) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Ao excluir esse CPF será necessário vincular outro CPF com a mesma porcentagem, ou repassar a porcentagem para outro(s) CPF(s) cadastrado(s).',
                buttonNot: 'Remover',
                buttonConfirmText: 'Vincular outro CPF',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result === 1) {
                    return [2 /*return*/, this.companiesService.delete(id, '?removeTaxa=not').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.list().catch(function (error) { return console.log(error); });
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/airport-company/" + companyId + "/customer/new"])];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return ArchitectCompanyCustomersListComponent;
}());
export { ArchitectCompanyCustomersListComponent };
