<app-logged class="architect-invoice-list">
  <header class="architect-invoice-list__header">
    <h2>NOTAS FISCAIS</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <app-form-filter
    maskExample="00.000.000/0000-00"
    actions="form"
    type="architectInvoices"
    [fieldsForm]="fieldsForm"
    (returnFilter)="filterCallback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">

       <ng-container matColumnDef="bulkSelector">
        <th mat-header-cell *matHeaderCellDef>
          <label matTooltip="Selecionar todos">
            <input type="checkbox"
              [(ngModel)]="allSelected"
              (change)="selectAllToggle($event.target.checked)"/>
          </label>
        </th>
        <td mat-cell *matCellDef="let element">
          <input *ngIf="canApprove(element)" type="checkbox"
            [checked]="selectedInvoices[element.id]"
            [value]="element.id"
            (change)="onInvoiceSelect($event.target.checked, element.id)"
           />
        </td>
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="3">
          <button class="btn-primary-light"
            [disabled]="isAnySelected()"
            mat-button
            (click)="bulkApproveInvoices()"
            >
            Aprovar Selecionados
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="storeCnpj">
        <th mat-header-cell *matHeaderCellDef>Cnpj do parceiro</th>
        <td mat-cell *matCellDef="let element">
          {{ element.stores?.cnpj | mask: '00.000.000/0000-00' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro/Loja</th>
        <td mat-cell *matCellDef="let element">{{ element.stores?.nomeFantasia }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="receiver">
        <th mat-header-cell *matHeaderCellDef>Recebedor dos jcoins</th>
        <td mat-cell *matCellDef="let element">
          <div  class="flex-align-center">
            <mat-icon *ngIf="element.type == 'PF'" matTooltip="Pessoa física" >person</mat-icon>
            <mat-icon *ngIf="element.type == 'PJ'" matTooltip="Empresa">business</mat-icon>
            <span>{{ element.receiverName }} </span>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>



      <ng-container matColumnDef="invoiceNumbber">
        <th mat-header-cell *matHeaderCellDef>Número da Nota fiscal</th>
        <td mat-cell *matCellDef="let element">{{ element.invoiceNumber }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Valor total</th>
        <td mat-cell *matCellDef="let element">{{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>


      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <strong [ngStyle]="{color: getInvoiceStatus(element).color}">
            {{ getInvoiceStatus(element).text}}
          </strong>

        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy':'GMT -0300' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">

          <button
            *ngIf="showCancelButton(element)"
            mat-icon-button
            matTooltip="Cancelar"
            matTooltipPosition="left"
            (click)="cancelInvoice(element.id)"
          >
            <mat-icon>cancel</mat-icon>
          </button>

          <button
            *ngIf="canApprove(element)"
            mat-icon-button
            matTooltip="Aprovar"
            matTooltipPosition="left"
            (click)="approveInvoices([element.id])"
          >
            <mat-icon>check_circle</mat-icon>
          </button>

          <button
          mat-icon-button
          matTooltip="Ver detalhes"
          matTooltipPosition="left"
          routerLink="/dashboard/architect-invoice/{{element.id}}/detail"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>

        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
      <tr mat-footer-row *matFooterRowDef="headersTable"></tr>
    </table>
  </div>
  <!-- Angular 8 pagination -->
  <app-form-filter
    type="architectInvoices"
    actions="pagination"
    [length]="pageTotal"
    [pageSizeOptions]="pageSizeOptions"
    [formGroupValue]="formGroup"
    (returnFilter)="filterCallback($event)"
  >
  </app-form-filter>


</app-logged>
