import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class StoreDocumentService {
  public loading

  constructor (private readonly http: HttpClient) {}

  list (storeID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/${storeID}/documents`).pipe(
      tap(_ => this.log('/cms/documents')),
      catchError(this.handleError),
    )
  }

  add (storeID, params): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/stores/${storeID}/documents/upload?type=${params}`)
      .pipe(
        tap(_ => this.log('cms/documents')),
        catchError(this.handleError),
      )
  }

  upload (pathAwsUpload, data): Observable<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    return this.http
      .put<any>(`${pathAwsUpload}`, data, { headers })
      .pipe(
        tap(_ => this.log('cms/categories/images')),
        catchError(this.handleError),
      )
  }

  put (pathAwsUpload, data): Observable<any> {
    return this.http.put<any>(pathAwsUpload, data).pipe(
      tap(_ => this.log('/cms/documents')),
      catchError(this.handleError),
    )
  }

  delete (accountID, storeID): Observable<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/stores/${storeID}/documents/${accountID}`)
      .pipe(
        tap(_ => this.log('cms/documents')),
        catchError(this.handleError),
      )
  }

  create (data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/stores/documents/`, data).pipe(
      tap(_ => this.log('cms/documents')),
      catchError(this.handleError),
    )
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) {}
}
