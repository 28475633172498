<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVA TRANSAÇÃO FUTURA</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores/{{ storeID }}/transactions"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <h4>Informações Gerais</h4>
  <div class="table-responsive">
    <table mat-table [dataSource]="transactionsInfo">
      <ng-container matColumnDef="minimumTransactionValue">
        <th mat-header-cell *matHeaderCellDef>Valor mínimo da transação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.minimumTransactionValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="minimumInstallmentValue">
        <th mat-header-cell *matHeaderCellDef>Valor mínimo da parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.minimumInstallmentValue | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card style="margin-bottom: 1rem">
      <mat-form-field style="width: 35%">
        <mat-label>Sub-empresas</mat-label>
        <mat-select formControlName="subStoreId">
          <mat-option *ngFor="let mg of subStores" [value]="mg.id">
            {{ mg.subStoreName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações da Transação</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="customerCpf" mask="000.000.000-00" />
              <mat-error *ngIf="formGroup.controls.customerCpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.customerCpf) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor Total</mat-label>
              <input
                matInput
                formControlName="totalValue"
                (blur)="changeValueTotal($event)"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error *ngIf="formGroup.controls.totalValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.totalValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Quantidade Máxima de Parcelas</mat-label>
              <mat-select formControlName="maximumInstallments">
                <mat-option *ngFor="let installment of installments" value="{{ installment }}">
                  {{ installment == 1 ? 'À vista' : installment }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.maximumInstallments?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.maximumInstallments) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
              <mat-error *ngIf="formGroup.controls.description?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.description) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data Pagamento</mat-label>
              <input matInput type="date" formControlName="paymentDate" />
              <mat-error *ngIf="formGroup.controls.paymentDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.paymentDate) }}
              </mat-error>
            </mat-form-field>
            <mat-card-title style="margin-top: 2rem"
              >Informações da Transação da Reserva</mat-card-title
            >
            <mat-form-field>
              <mat-label>Valor Reserva</mat-label>
              <input
                matInput
                (blur)="changeValueReservation()"
                formControlName="reservationValue"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
              />
              <mat-error
                *ngIf="formGroup.controls.reservationValue?.invalid && !reservationValueInvalid"
              >
                {{ errorsService.messageErrorFor(formGroup.controls.reservationValue) }}
              </mat-error>
            </mat-form-field>
            <mat-error style="font-size: 12px" *ngIf="reservationValueInvalid"
              >O valor da reserva é maior que o valor da Transação</mat-error
            >
            <mat-form-field>
              <mat-label>Quantidade Máxima de Parcelas</mat-label>
              <mat-select formControlName="maximumInstallmentsReserve">
                <mat-option *ngFor="let installment of installments" value="{{ installment }}">
                  {{ installment }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.maximumInstallmentsReserve?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.maximumInstallmentsReserve) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
