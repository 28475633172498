import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '../../../../services/uploadAdapter.service';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
var ConfigsFormComponent = /** @class */ (function () {
    function ConfigsFormComponent(errorsService, formBuilder, configService, authService, snackBar, currencyPipe, dialog) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.configService = configService;
        this.authService = authService;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.dialog = dialog;
        this.Editor = ClassicEditor;
        this.gatewayPayment = false;
        this.initForm();
    }
    Object.defineProperty(ConfigsFormComponent.prototype, "paymentHubEarlyAdoptersControls", {
        get: function () {
            return this.formGroup.get('paymentHubEarlyAdopters');
        },
        enumerable: true,
        configurable: true
    });
    ConfigsFormComponent.prototype.ngOnInit = function () { };
    ConfigsFormComponent.prototype.initForm = function () {
        this.config = {
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
        this.formGroup = this.formBuilder.group({
            // recurrenceValue: [null, [Validators.required]],
            paymentIntentExpirationTimeInMinutes: [null, [Validators.required]],
            mainStoreId: [null, [Validators.required]],
            accountManagerPhoneNumber: [null, [Validators.required]],
            mainStoreCnpj: [null, [Validators.required]],
            accountManagerDefaultMessage: [null, [Validators.required]],
            appVersion: [null, [Validators.required]],
            isAppSelfDeleteButtonActive: [null, [Validators.required]],
            isPaymentActive: [null, [Validators.required]],
            jcoinsExpirationInDays: [null, [Validators.required]],
            rewardsAlias: [null, [Validators.required]],
            recurrencePeriod: [null, [Validators.required]],
            accountManagerEmailAddress: [null, [Validators.required]],
            // taxConversion: [null, []],
            // textWellcome: [null, []],
            titleCategory: [null, []],
            firstAccessPhoneNumber: [null, []],
            paymentZoop: [null, []],
            paymentRoadpass: [null, []],
            currentPaymentProvider: [null, [Validators.required]],
            internationalPurchaseFeePercent: [null, [Validators.required]],
            iofStoreId: [null, [Validators.required]],
            iofTaxCashback: [null, [Validators.required]],
            minValueToGenerateJCoins: [null, [Validators.required]],
            cashbackPointsConversionFactorIn: [null, [Validators.required]],
            cashbackPointsConversionFactorOut: [null, [Validators.required]],
            accountManagerPhoneNumberArchitect: [null, []],
            airportEligible1: [null, []],
            airportEligible2: [null, []],
            paymentHubEarlyAdopters: this.formBuilder.array([]),
        });
        this.loadInfos();
    };
    ConfigsFormComponent.prototype.loadInfos = function () {
        var _this = this;
        this.configService
            .view()
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.patchValue({
                    // recurrenceValue: response.results.recurrenceValue / 100,
                    paymentIntentExpirationTimeInMinutes: response.results.paymentIntentExpirationTimeInMinutes,
                    mainStoreId: response.results.mainStoreId,
                    accountManagerPhoneNumber: response.results.accountManagerPhoneNumber,
                    mainStoreCnpj: response.results.mainStoreCnpj,
                    accountManagerDefaultMessage: response.results.accountManagerDefaultMessage,
                    appVersion: response.results.appVersion,
                    isAppSelfDeleteButtonActive: response.results.isAppSelfDeleteButtonActive,
                    internationalPurchaseFeePercent: this.formatToDecimalComma(response.results.internationalPurchaseFeePercent),
                    iofStoreId: response.results.iofStoreId,
                    iofTaxCashback: this.formatToDecimalComma(response.results.iofTaxCashback),
                    minValueToGenerateJCoins: this.formatToDecimalComma(response.results.minValueToGenerateJCoins),
                    cashbackPointsConversionFactorIn: this.formatToDecimalComma(response.results.cashbackPointsConversionFactorIn),
                    cashbackPointsConversionFactorOut: this.formatToDecimalComma(response.results.cashbackPointsConversionFactorOut),
                    isPaymentActive: response.results.isPaymentActive,
                    jcoinsExpirationInDays: response.results.jcoinsExpirationInDays,
                    rewardsAlias: response.results.rewardsAlias,
                    recurrencePeriod: response.results.recurrencePeriod,
                    accountManagerEmailAddress: response.results.accountManagerEmailAddress,
                    // taxConversion: response.results.taxConversion,
                    // textWellcome: response.results.textWellcome,
                    titleCategory: response.results.titleCategory,
                    firstAccessPhoneNumber: response.results.firstAccessPhoneNumber ? response.results.firstAccessPhoneNumber : '',
                    paymentZoop: response.results.paymentZoop,
                    paymentRoadpass: response.results.paymentRoadpass,
                    currentPaymentProvider: response.results.currentPaymentProvider,
                    accountManagerPhoneNumberArchitect: response.results.accountManagerPhoneNumberArchitect,
                    airportEligible1: response.results.airportEligible1,
                    airportEligible2: response.results.airportEligible2,
                });
                if (response.results.paymentHubEarlyAdopters)
                    response.results.paymentHubEarlyAdopters.split(',').filter(function (cpf) { return !!cpf; }).forEach(function (cpf) { return _this.paymentHubEarlyAdoptersControls.push(_this.formBuilder.control(cpf.trim())); });
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    ConfigsFormComponent.prototype.formatToDecimalComma = function (originalString) {
        var commaValue = parseFloat(originalString)
            .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return commaValue;
    };
    ConfigsFormComponent.prototype.formatToDecimalPoint = function (originalString) {
        var cleanedString = originalString.replace(/\s/g, ''); // Remove espaços
        var replacedString = cleanedString.replace(',', '.');
        return parseFloat(replacedString);
    };
    ConfigsFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    ConfigsFormComponent.prototype.convertCentsToMoney = function (value) {
        var money = this.currencyPipe.transform(value, 'BRL');
        // this.formGroup.controls['totalValue'].setValue(money);
        console.log(money);
    };
    ConfigsFormComponent.prototype.onChange = function (e) {
        if (this.formGroup.value.paymentZoop || this.formGroup.value.paymentRoadpass) {
            this.gatewayPayment = false;
        }
        if (!this.formGroup.value.paymentZoop && !this.formGroup.value.paymentRoadpass) {
            this.gatewayPayment = true;
        }
    };
    ConfigsFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cpfArray, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.value.paymentZoop && !this.formGroup.value.paymentRoadpass) {
                    this.gatewayPayment = true;
                }
                if (this.gatewayPayment
                    || !this.formGroup.value.paymentIntentExpirationTimeInMinutes
                    || !this.formGroup.value.accountManagerPhoneNumber
                    || !this.formGroup.value.mainStoreId
                    || !this.formGroup.value.mainStoreCnpj
                    || !this.formGroup.value.accountManagerDefaultMessage
                    || !this.formGroup.value.appVersion
                    || !this.formGroup.value.isAppSelfDeleteButtonActive
                    || !this.formGroup.value.internationalPurchaseFeePercent
                    || !this.formGroup.value.iofStoreId
                    || !this.formGroup.value.iofTaxCashback
                    || !this.formGroup.value.minValueToGenerateJCoins
                    || !this.formGroup.value.cashbackPointsConversionFactorIn
                    || !this.formGroup.value.cashbackPointsConversionFactorOut
                    || !this.formGroup.value.isPaymentActive
                    || this.formGroup.value.jcoinsExpirationInDays >= 0 ? false : true
                    || !this.formGroup.value.rewardsAlias
                    || !this.formGroup.value.recurrencePeriod
                    || !this.formGroup.value.accountManagerEmailAddress
                    || !this.formGroup.value.currentPaymentProvider) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                cpfArray = this.paymentHubEarlyAdoptersControls.value.filter(function (cpf) { return cpf !== ''; });
                data = {
                    mainStoreCnpj: this.formGroup.value.mainStoreCnpj,
                    pointsExpirationTimeInDays: this.formGroup.value.pointsExpirationTimeInDays,
                    appVersion: this.formGroup.value.appVersion,
                    isAppSelfDeleteButtonActive: this.formGroup.value.isAppSelfDeleteButtonActive,
                    internationalPurchaseFeePercent: this.formatToDecimalPoint(this.formGroup.value.internationalPurchaseFeePercent),
                    iofStoreId: this.formGroup.value.iofStoreId,
                    iofTaxCashback: this.formatToDecimalPoint(this.formGroup.value.iofTaxCashback),
                    minValueToGenerateJCoins: this.formatToDecimalPoint(this.formGroup.value.minValueToGenerateJCoins),
                    cashbackPointsConversionFactorIn: this.formatToDecimalPoint(this.formGroup.value.cashbackPointsConversionFactorIn),
                    cashbackPointsConversionFactorOut: this.formatToDecimalPoint(this.formGroup.value.cashbackPointsConversionFactorOut),
                    accountManagerPhoneNumber: this.formGroup.value.accountManagerPhoneNumber,
                    accountManagerPhoneNumberArchitect: this.formGroup.value.accountManagerPhoneNumberArchitect || '',
                    accountManagerDefaultMessage: this.formGroup.value.accountManagerDefaultMessage,
                    paymentIntentExpirationTimeInMinutes: this.formGroup.value
                        .paymentIntentExpirationTimeInMinutes,
                    recurrencePeriod: Number(this.formGroup.value.recurrencePeriod),
                    // recurrenceValue: this.formGroup.value.recurrenceValue * 100,
                    isPaymentActive: this.formGroup.value.isPaymentActive,
                    jcoinsExpirationInDays: this.formGroup.value.jcoinsExpirationInDays,
                    rewardsAlias: this.formGroup.value.rewardsAlias,
                    accountManagerEmailAddress: this.formGroup.value.accountManagerEmailAddress,
                    // taxConversion: this.formGroup.value.taxConversion,
                    // textWellcome: this.formGroup.value.textWellcome,
                    titleCategory: this.formGroup.value.titleCategory,
                    firstAccessPhoneNumber: this.formGroup.value.firstAccessPhoneNumber,
                    paymentZoop: !!this.formGroup.value.paymentZoop,
                    paymentRoadpass: !!this.formGroup.value.paymentRoadpass,
                    currentPaymentProvider: this.formGroup.value.currentPaymentProvider || undefined,
                    airportEligible1: this.formGroup.value.airportEligible1 || undefined,
                    airportEligible2: this.formGroup.value.airportEligible2 || undefined,
                    paymentHubEarlyAdopters: cpfArray.join(','),
                };
                this.configService.update(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isUpdated) {
                            this.snackBar.open('Configurações atualizadas com sucesso.');
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ConfigsFormComponent.prototype.resetCustomersSessions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.dialog
                    .open(ModalConfirmComponent, {
                    width: '500px',
                    data: {
                        text: 'Deseja realmente reiniciar as sessões dos usuários no APP? Isso fará com que '
                            + 'os usuários ativos no app sejam forçados a fazer o login novamente.',
                    },
                })
                    .afterClosed()
                    .toPromise()
                    .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var res;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(result && result !== 'cancel')) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.configService.resetAccessTokens()];
                            case 1:
                                res = _a.sent();
                                if (res.success) {
                                    this.snackBar.open('Sessões reiniciadas com sucesso.');
                                }
                                else {
                                    this.snackBar.open('Falha ao reiniciar sessão o usuário.');
                                }
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    _this.snackBar.open(err.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ConfigsFormComponent.prototype.addCpfEarlyAdopter = function () {
        this.paymentHubEarlyAdoptersControls.push(this.formBuilder.control(''));
    };
    ConfigsFormComponent.prototype.removeCpfEarlyAdopter = function (index) {
        this.paymentHubEarlyAdoptersControls.removeAt(index);
    };
    return ConfigsFormComponent;
}());
export { ConfigsFormComponent };
