import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
var ModalHelpComponent = /** @class */ (function () {
    function ModalHelpComponent(dialogRef, data, formBuilder, snackBar, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        console.log('data', data);
    }
    ModalHelpComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalHelpComponent.prototype.ngOnInit = function () { };
    return ModalHelpComponent;
}());
export { ModalHelpComponent };
