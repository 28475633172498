import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Location } from '@angular/common'

import { ErrorsService } from '../../../../../../src/app/core/services/errors.service'
import { QuizService } from '../../../../services/quiz.service'
import { UploadAdapter } from '../../../../services/uploadAdapter.service'

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
})
export class QuizFormComponent implements OnInit {
  public formGroup: FormGroup
  public uploadData: FormData
  public Editor = ClassicEditor
  public config: any
  public imageUrl
  public id = ''
  public approveDoc: string
  public descriptionInvalid = false
  private readonly initalized = false
  public params = { formGroupValue: [] }

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quizService: QuizService,
    public location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.id = this.route.snapshot.paramMap.get('id')
    this.formGroup = this.formBuilder.group({
      quiz: [null, [Validators.required]],
      description: [null, []],
    })

    this.config = {
      image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
      },
    }
    if (this.id) {
      this.getById()
    }
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }

  selectFile (event) {
    const file = event.target.files[0]
    this.uploadData = file

    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result
    }
  }

  removeImage () {
    this.imageUrl = undefined
    this.uploadData = null
    this.approveDoc = undefined
  }

  imgLoadError () {
    this.imageUrl = undefined
  }

  getById () {
    this.quizService.findOne(this.id).then(
      response => {
        this.formGroup.patchValue({
          quiz: response.name,
          description: response.description,
        })
        this.imageUrl = response.image
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  submit () {
    let imageUrl = this.imageUrl
      ; (async () => {
      if (!this.formGroup.value.quiz) {
        this.snackBar.open('Preencha corretamente os campos e tente novamente.')
        return false
      }

      if (this.uploadData) {
        const { readUrl, uploadUrl } = await this.quizService.getS3Url()
        imageUrl = readUrl
        await this.quizService.uploadToS3(uploadUrl, this.uploadData)
      }
      const data = {
        name: this.formGroup.value.quiz,
        description: this.formGroup.value.description || '',
        image: imageUrl || '',
      }

      if (this.id) {
        this.quizService.update(this.id, data).then(
          async response => {
            this.location.back()
            this.snackBar.open('Questionário alterado com sucesso.')
          },
          error => {
            this.snackBar.open(error.message)
          },
        )
      } else {
        this.quizService.create(data).then(
          async response => {
            this.location.back()
            this.snackBar.open('Questionário cadastrado com sucesso.')
          },
          error => {
            this.snackBar.open(error.message)
          },
        )
      }
    })().catch(error => this.snackBar.open(error.error.message))
  }
}
