import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-questions-show-image',
  templateUrl: './questions-show-image.component.html',
  styleUrls: ['./questions-show-image.component.scss'],
})
export class QuestionsShowImageComponent implements OnInit {
  imageQuestion = ''

  constructor () {}

  ngOnInit () {
    this.imageQuestion = localStorage.getItem('imageQuestion')
  }
}
