<app-logged class="store-form">
  <header class="store-form__header">
    <h2>GATILHOS</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/quiz/list">VOLTAR</button>
    </div>
  </header>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-3 col-sm-3">
        <b>CADASTRADO DO USUÁRIO:</b>
        <mat-form-field style="padding-top: 1rem">
          <mat-label>QUESTIONÁRIOS</mat-label>
          <mat-select formControlName="quizID">
            <mat-option *ngFor="let quiz of quizzes" [value]="quiz.id">{{ quiz.name }} </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls.quizID?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.quizID) }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="store-form__actions">
      <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
    </div>
  </form>
</app-logged>
