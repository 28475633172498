import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var AirportInfoFieldFormComponent = /** @class */ (function () {
    function AirportInfoFieldFormComponent(errorsService, formBuilder, airportsService, snackBar, router, route) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
    }
    AirportInfoFieldFormComponent.prototype.ngOnInit = function () {
        this.airportID = this.route.snapshot.paramMap.get('airportID');
        this.informationID = this.route.snapshot.paramMap.get('informationID');
        this.fieldID = this.route.snapshot.paramMap.get('fieldID');
        this.initForm();
    };
    AirportInfoFieldFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            order: [null, [Validators.required]],
            fieldTitle: [null, []],
            fieldValue: [null, []],
            fieldLabel: [null, []],
        });
        if (this.fieldID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    AirportInfoFieldFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.infoFieldFindOne(this.fieldID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    order: response.order,
                                    fieldTitle: response.fieldTitle,
                                    fieldValue: response.fieldValue,
                                    fieldLabel: response.fieldLabel,
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportInfoFieldFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    order: this.formGroup.value.order,
                    fieldTitle: this.formGroup.value.fieldTitle,
                    fieldValue: this.formGroup.value.fieldValue,
                    fieldLabel: this.formGroup.value.fieldLabel,
                };
                if (this.fieldID) {
                    this.airportsService.updateInfoField(this.informationID, this.fieldID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isUpdated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate([
                                            './dashboard/airport/' +
                                                this.airportID +
                                                '/information/' +
                                                this.informationID +
                                                '/fields',
                                        ])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.airportsService.createInfoField(this.informationID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate([
                                            './dashboard/airport/' +
                                                this.airportID +
                                                '/information/' +
                                                this.informationID +
                                                '/fields',
                                        ])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação criado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return AirportInfoFieldFormComponent;
}());
export { AirportInfoFieldFormComponent };
