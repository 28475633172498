import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalChooseStoreComponent } from '../../../../../components/modal-choose-store/modal-choose-store.component';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
import { UtilService } from 'src/app/services/util.service';
var getEnumKeyByEnumValue = UtilService.getEnumKeyByEnumValue;
var ArchitectsStoreUserFormComponent = /** @class */ (function () {
    function ArchitectsStoreUserFormComponent(errorsService, zipCodeService, formBuilder, storeService, authService, router, route, snackBar, dialog, companiesService, location) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.companiesService = companiesService;
        this.location = location;
        this.storesSelected = [];
        this.stores = [];
        this.permissions = [
            enumCmsPermissions.architect_invoice_create_update,
            enumCmsPermissions.architect_invoice_list,
            enumCmsPermissions.architect_invoice_cancel,
            enumCmsPermissions.architect_invoice_delete,
            enumCmsPermissions.architect_closings_menu,
        ].map(function (p) { return getEnumKeyByEnumValue(enumCmsPermissions, p); })
            .reduce(function (acc, key) {
            var _a;
            return (tslib_1.__assign((_a = {}, _a[key] = enumCmsPermissions[key], _a), acc));
        }, {});
        this.selectedPermissions = {};
        this.selectedPermissions = Object.keys(this.permissions).reduce(function (acc, permissionKey) {
            var _a;
            return (tslib_1.__assign((_a = {}, _a[permissionKey] = false, _a), acc));
        }, {});
        this.userRole = localStorage.getItem('userRole');
        this.initForm();
    }
    ArchitectsStoreUserFormComponent.prototype.ngOnInit = function () {
        // this.id = this.route.snapshot.paramMap.get('id')
        // this.userId = this.route.snapshot.paramMap.get('userId')
        // this.email = this.route.snapshot.paramMap.get('email')
        // this.loadStores().catch(err => { console.log(err) })
        // if (this.userId) {
        //   this.findOne().catch(err => { console.log(err) })
        // }
    };
    ArchitectsStoreUserFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storeBusinessUnitIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.id = this.route.snapshot.paramMap.get('id');
                        this.userId = this.route.snapshot.paramMap.get('userId');
                        this.email = this.route.snapshot.paramMap.get('email');
                        this.formGroup = this.formBuilder.group({
                            firstName: [null, [Validators.required]],
                            lastName: [null, [Validators.required]],
                            emailAddress: [this.email, [Validators.required, Validators.email]],
                            phoneNumber: [null, [Validators.required]],
                            phonestoreBusinessUnitIdsNumber: [null, []],
                        });
                        return [4 /*yield*/, this.loadStores().catch(function (err) { console.log(err); })];
                    case 1:
                        _a.sent();
                        if (!this.userId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.findOne().catch(function (err) { console.log(err); })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        storeBusinessUnitIds = this.storesSelected.map(function (e) { return e.id; });
                        if (!storeBusinessUnitIds.includes(this.id))
                            this.showStores(this.stores.find(function (s) { return s.id === _this.id; }));
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectsStoreUserFormComponent.prototype.loadStores = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stores;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .listStoresBusinessUnits('?page=1&limit=1000000')
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { stores: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        stores = (_a.sent()).stores;
                        this.stores = stores;
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectsStoreUserFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .userInfo(this.userId)
                            .toPromise()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        user = _a.sent();
                        this.formGroup.patchValue({
                            firstName: user.firstName,
                            lastName: user.lastName,
                            emailAddress: user.emailAddress,
                            phoneNumber: user.phoneNumber,
                        });
                        this.storesSelected = user.storeBusinessUnits;
                        user.permissions.forEach(function (permissionKey) {
                            _this.selectedPermissions[permissionKey] = true;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectsStoreUserFormComponent.prototype.isChecked = function (id) {
        if (id === this.id) {
            return 'checked';
        }
    };
    ArchitectsStoreUserFormComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalChooseStoreComponent, {
            width: '500px',
            data: {
                stores: this.stores,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.showStores(result);
            }
        });
    };
    ArchitectsStoreUserFormComponent.prototype.showStores = function (store) {
        var _this = this;
        this.storesSelected.push(store);
        this.stores = this.stores.filter(function (f) { return !_this.storesSelected.includes(f); });
    };
    ArchitectsStoreUserFormComponent.prototype.removeStore = function (store) {
        if (store.id == this.formGroup.value.storeId || this.storesSelected.length == 0) {
            this.formGroup.get('storeId').setValue('');
        }
        this.storesSelected = this.storesSelected.filter(function (item) { return item.id !== store.id; });
        this.stores.push(store);
    };
    ArchitectsStoreUserFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filterIds, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                filterIds = this.storesSelected.filter(function (s) { return s.id === _this.id; });
                console.log(filterIds);
                if (!this.formGroup.valid || !this.storesSelected.length) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    firstName: this.formGroup.value.firstName,
                    lastName: this.formGroup.value.lastName,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: this.formGroup.value.phoneNumber,
                    storeBusinessUnitIds: this.storesSelected.map(function (s) { return s.id; }),
                };
                if (this.userId) {
                    data.permissionsToAdd = Object.entries(this.selectedPermissions)
                        .filter(function (_a) {
                        var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                        return checked;
                    })
                        .map(function (_a) {
                        var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                        return permissionName;
                    }),
                        data.permissionsToRemove = Object.entries(this.selectedPermissions)
                            .filter(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return !checked;
                        })
                            .map(function (_a) {
                            var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                            return permissionName;
                        });
                    this.companiesService.updateStoreBusinessUnitUser(this.userId, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.success) {
                                this.location.back();
                                this.snackBar.open('Usuário atualizado com sucesso.');
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    data.permissions = Object.entries(this.selectedPermissions)
                        .filter(function (_a) {
                        var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                        return checked;
                    })
                        .map(function (_a) {
                        var _b = tslib_1.__read(_a, 2), permissionName = _b[0], checked = _b[1];
                        return permissionName;
                    }),
                        this.companiesService.createStoreBusinessUnitUser(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (response.isCreated) {
                                    this.location.back();
                                    this.snackBar.open('Usuário cadastrado com sucesso.');
                                }
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        });
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectsStoreUserFormComponent.prototype.changePermissions = function (evt) {
        var permissionKey = evt.target.value;
        this.selectedPermissions[permissionKey] = evt.target.checked;
    };
    return ArchitectsStoreUserFormComponent;
}());
export { ArchitectsStoreUserFormComponent };
