import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDeleteClosingComponent } from 'src/app/components/modal-delete-closing/modal-delete-closing.component';
import { ModalClosingsComponent } from 'src/app/components/modal-closings/modal-closings.component';
var ClosingsListComponent = /** @class */ (function () {
    function ClosingsListComponent(closingsService, prepaymentsService, formBuilder, snackBar, errorsService, router, dialog) {
        this.closingsService = closingsService;
        this.prepaymentsService = prepaymentsService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.router = router;
        this.dialog = dialog;
        this.headersTable = ['id', 'createdAt', 'period', 'paymentDate', 'actions'];
        this.closings = [];
        this.closingsResponse = {};
        this.closingsSelecteds = [];
        this.closingsDate = {};
    }
    ClosingsListComponent.prototype.ngOnInit = function () {
        this.formFilter = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            paymentDate: [null, [Validators.required]],
        });
        this.form = this.formBuilder.group({
            paymentDate: [null, [Validators.required]],
            closings: [null, [Validators.required]],
        });
        this.listAll();
    };
    ClosingsListComponent.prototype.create = function (result) {
        var _this = this;
        this.closingsService.create(result).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.listAll();
                this.snackBar.open('Fechamento criado com sucesso');
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsListComponent.prototype.listAll = function () {
        var _this = this;
        this.closings = [];
        this.closingsService.listAll().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.closingsResponse = response;
                this.closings = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsListComponent.prototype.openModal = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalClosingsComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != 'cancel') {
                _this.create(result);
            }
        });
    };
    ClosingsListComponent.prototype.openDialog = function (closingsID) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalDeleteClosingComponent, {
            width: '500px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.closingsService.delete(closingsID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('Fechamento deletado com sucesso.');
                        this.listAll();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
            }
        });
    };
    ClosingsListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return ClosingsListComponent;
}());
export { ClosingsListComponent };
