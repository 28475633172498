import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrepaymentsService } from 'src/app/services/prepayments.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './prepayments-list.component.html',
  styleUrls: ['./prepayments-list.component.scss'],
})
export class PrepaymentsListComponent implements OnInit {
  public headersTable: string[] = [
    'paymentDate',
    'paymentGrossAmount',
    'paymentNetAmount',
    'zoopFee',
    'jhsfFee',
    'state',
  ];
  public prepayments: Array<any> = [];

  constructor(public prepaymentsService: PrepaymentsService) {}

  ngOnInit() {
    this.listAll();
  }

  listAll() {
    this.prepaymentsService.listAll().subscribe(
      async (response) => {
        this.prepayments = response.items.map((prepayment) => {
          prepayment.paymentGrossAmount = prepayment.paymentGrossAmount / 100;
          prepayment.paymentNetAmount = prepayment.paymentNetAmount / 100;
          return prepayment;
        });
      },
      (error) => {}
    );
  }
}
