import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// import { BusinessPartner } from '../models/businessPartner'
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
    }
    NotificationService.prototype.list = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/events" + (filter || '')).toPromise()];
            });
        });
    };
    NotificationService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/events/" + id).toPromise()];
            });
        });
    };
    NotificationService.prototype.create = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, formData, key;
            return tslib_1.__generator(this, function (_a) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
                formData = new FormData();
                for (key in data) {
                    if (!Object.prototype.hasOwnProperty.call(data, key) ||
                        data[key] === undefined ||
                        data[key] === null) {
                        continue;
                    }
                    formData.append(key, data[key]);
                }
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/events", formData, { headers: headers })
                        .toPromise()];
            });
        });
    };
    NotificationService.prototype.update = function (data, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headers, formData, key;
            return tslib_1.__generator(this, function (_a) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                };
                formData = new FormData();
                for (key in data) {
                    if (!Object.prototype.hasOwnProperty.call(data, key) ||
                        data[key] === undefined ||
                        data[key] === null) {
                        continue;
                    }
                    formData.append(key, data[key]);
                }
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/events/" + id, formData, { headers: headers })
                        .toPromise()];
            });
        });
    };
    NotificationService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/events/" + id).toPromise()];
            });
        });
    };
    NotificationService.prototype.getS3Url = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/events/s3-slot").toPromise()];
            });
        });
    };
    NotificationService.prototype.uploadToS3 = function (pathAwsUpload, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + pathAwsUpload, data).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationService.prototype.notificationCustomersList = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/events/" + id + "/customers/" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    NotificationService.prototype.listTypes = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/event/types-segmentation" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    NotificationService.prototype.createType = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/event/type-segmentation", body).toPromise()];
            });
        });
    };
    NotificationService.prototype.updateType = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/event/type-segmentation/" + id, body).toPromise()];
            });
        });
    };
    NotificationService.prototype.findOneType = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/event/types-segmentation/" + id).toPromise()];
            });
        });
    };
    NotificationService.prototype.deleteType = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/event/type-segmentation/" + id).toPromise()];
            });
        });
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
