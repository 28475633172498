import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
var ClosingsTransactionsListComponent = /** @class */ (function () {
    function ClosingsTransactionsListComponent(transactionService, route, router, currencyPipe, snackBar, dialog, location, utilService, storeService) {
        this.transactionService = transactionService;
        this.route = route;
        this.router = router;
        this.currencyPipe = currencyPipe;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.utilService = utilService;
        this.storeService = storeService;
        this.headersTable = [
            'orderNumber',
            'storeName',
            'customerCpf',
            'description',
            'createdAt',
            'totalValue',
        ];
        this.transactions = [];
        this.isAdmin = false;
        this.closingsID = '';
        this.stores = [];
    }
    ClosingsTransactionsListComponent.prototype.ngOnInit = function () {
        this.closingsID = this.route.snapshot.paramMap.get('closingsID');
        this.userRole = localStorage.getItem('userRole');
        this.isAdmin = this.userRole == 'ADMIN';
        this.listTransactions();
    };
    ClosingsTransactionsListComponent.prototype.listTransactions = function () {
        var _this = this;
        this.transactionService.listTransactionsByClosing(this.closingsID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var listTransactions;
            return tslib_1.__generator(this, function (_a) {
                listTransactions = response.map(function (item) {
                    return item.transaction;
                });
                listTransactions.map(function (item) {
                    item.createdAt = item.createdAt.slice(0, 10);
                    item.storeName = item.store.nomeFantasia;
                    delete item.updatedAt;
                    delete item.paidAt;
                });
                this.dataSource = new MatTableDataSource(listTransactions);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.paginator._intl.itemsPerPageLabel = 'Itens por página';
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
        this.storeService.listStores().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.stores = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsTransactionsListComponent.prototype.goBack = function () {
        this.location.back();
    };
    ClosingsTransactionsListComponent.prototype.applyFilter = function (filterValue, type) {
        switch (type) {
            case 'numberTransaction':
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
                break;
            case 'cpfClient':
                this.dataSource.filter = this.utilService.extractNumbers(filterValue);
                break;
            case 'dateTransaction':
                console.log(filterValue);
                this.dataSource.filter = filterValue;
                break;
            case 'status':
                this.dataSource.filter = filterValue;
                break;
            case 'store':
                filterValue = filterValue.trim(); // Remove whitespace
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
                break;
            default:
                filterValue = filterValue.trim(); // Remove whitespace
                filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
                this.dataSource.filter = filterValue;
        }
    };
    ClosingsTransactionsListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return ClosingsTransactionsListComponent;
}());
export { ClosingsTransactionsListComponent };
