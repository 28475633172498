import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material'

@Component({
  selector: 'modal-verify-initial-campaign-date',
  templateUrl: './modal-verify-initial-campaign-date.component.html',
  styleUrls: ['./modal-verify-initial-campaign-date.component.scss'],
})
export class ModalVerifyInitialCampaignDateComponent {
  constructor (
    public dialogRef: MatDialogRef<ModalVerifyInitialCampaignDateComponent>,
  ) { }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {
    this.dialogRef.close('continue')
  }
}
