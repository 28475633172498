import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
var BeneficiaryFormComponent = /** @class */ (function () {
    function BeneficiaryFormComponent(errorsService, snackBar, formBuilder, reportService, route, authService, router) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.route = route;
        this.authService = authService;
        this.router = router;
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        // public cpfControl = new FormControl()
        this.customerInvalid = false;
        this.customers = [];
        this.id = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    BeneficiaryFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.idBeneficiary = this.route.snapshot.paramMap.get('id');
        this.customerId = this.route.snapshot.paramMap.get('customerId');
        this.formGroup = this.formBuilder.group({
            customersInput: [null, [Validators.required]],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.authService
            .customerUserInfo(this.customerId)
            .subscribe(function (response) {
            _this.customer = response;
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
        // this.filteredCustomers = this.cpfControl.valueChanges.pipe(
        //   startWith(''),
        //   map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
        // )
        if (this.idBeneficiary) {
            this.beneficiaryFindOne().catch(function (err) { return console.log(err); });
        }
    };
    BeneficiaryFormComponent.prototype.beneficiaryFindOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.findOneBeneficiary(this.idBeneficiary)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // this.formGroup.patchValue({
                                //   firstName: response.firstName,
                                //   lastName: response.lastName,
                                //   cpf: response.cpf,
                                // })
                                this.selectedCustomers.push({
                                    customerId: response.beneficiary[0].customerBeneficiary[0].id,
                                    cpf: response.beneficiary[0].customerBeneficiary[0].cpf,
                                    name: response.beneficiary[0].customerBeneficiary[0].firstName + " " + response.beneficiary[0].customerBeneficiary[0].lastName,
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BeneficiaryFormComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    BeneficiaryFormComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    BeneficiaryFormComponent.prototype.selected = function (event) {
        if (this.selectedCustomers.length < 1) {
            this.selectedCustomers.push(event.option.value);
        }
        this.customerInput.nativeElement.value = '';
        this.customerControl.setValue(null);
        // this.cpfControl.setValue(null)
        event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true);
    };
    BeneficiaryFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var findCustomers, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true);
                if (this.customerInvalid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                findCustomers = this.selectedCustomers.find(function () { return true; });
                if (this.idBeneficiary) {
                    data = {
                        beneficiaryCustomerId: findCustomers.customerId,
                    };
                }
                else {
                    data = {
                        customerId: this.customerId,
                        beneficiaryCustomerId: findCustomers.customerId,
                    };
                }
                if (this.idBeneficiary) {
                    this.authService.updateBeneficiary(this.idBeneficiary, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.updated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/beneficiary'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Beneficiário atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.authService.createBeneficiary(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.success) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/beneficiary'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Beneficiário cadastrado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return BeneficiaryFormComponent;
}());
export { BeneficiaryFormComponent };
