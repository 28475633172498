<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVO DOCUMENTO</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores/{{ storeID }}/documents"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Tipo</mat-label>
              <mat-select formControlName="documentType">
                <mat-option [value]="'IDENTIFICATION'">Identificação</mat-option>
                <mat-option [value]="'ACTIVITY'">Atividade</mat-option>
                <mat-option [value]="'ADDRESS'">Endereço</mat-option>
                <mat-option [value]="'CNPJ'">CNPJ</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.documentType?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.documentType) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Documento</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img
                *ngIf="documentUrl != ''"
                width="200"
                src="{{ documentUrl }}"
                (error)="imgLoadError()"
              />
              <span *ngIf="documentUrl == null && fileName" class="filename-placeholder">
                <mat-icon>description</mat-icon> {{ this.fileName }}
              </span>
              <span class="remove-doc-wrapper">
                <button
                  class="btn-primary-light"
                  mat-button
                  *ngIf="fileName"
                  (click)="removeDocument()"
                >
                  <mat-icon>cancel</mat-icon> Remover documento
                </button>
              </span>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
