import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { LyDialog } from '@alyle/ui/dialog'
import { Location } from '@angular/common'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { base64ToBlob } from 'base64-blob'
import { BannersService } from '../../../../services/banners.service'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { CategoriesService } from 'src/app/services/categories.service'
import { BannersPagesAppService } from '../../../../services/bannersPagesApp.service'
@Component({
  selector: 'app-banner-pages-app-form',
  templateUrl: './banner-pages-app-form.component.html',
  styleUrls: ['./banner-pages-app-form.component.scss'],
})
export class BannerPagesAppFormComponent implements OnInit {
  public formGroup: FormGroup
  public idPage: any
  public page: any
  public id: any
  public showDates = false
  public category: string
  public subcategory: string

  private originalPartnerBannerImage: any
  public croppedPartnerBannerImage: any
  private partnerBannerImagePosition: any
  private partnerBannerImageData: any
  public readUrlPartnerBanner: any

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public bannersService: BannersService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    public categoriesService: CategoriesService,
    public bannersPagesAppService: BannersPagesAppService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      page: [null, [Validators.required]],
      // image: [null, []],
      // order: [null, [Validators.required]],
      // initAt: [null, []],
      // endAt: [null, []],
      // isFixed: [null, []],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
    if (this.page === 'category') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.category = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.page === 'subcategory') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.subcategory = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  async loadInfos () {
    this.bannersPagesAppService.findOne(this.id).then(
      response => {
        this.formGroup.patchValue({
          page: response.page,
        })
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  onChange () {
    if (this.formGroup.value.isFixed) {
      this.showDates = true

      this.formGroup.get('endAt').setValidators(null)
      this.formGroup.get('endAt').updateValueAndValidity()
      this.formGroup.get('endAt').setErrors(null)

      this.formGroup.get('initAt').setValidators(null)
      this.formGroup.get('initAt').updateValueAndValidity()
      this.formGroup.get('initAt').setErrors(null)

      this.formGroup.value.endAt = null
      this.formGroup.value.initAt = null
    }
    if (!this.formGroup.value.isFixed) {
      this.showDates = false
    }
  }

  onChangeDate () {
    if (this.formGroup.value.initAt) {
      this.formGroup.get('endAt').setValidators([Validators.required])
      this.formGroup.get('endAt').updateValueAndValidity()
    }
    if (this.formGroup.value.endAt) {
      this.formGroup.get('initAt').setValidators([Validators.required])
      this.formGroup.get('initAt').updateValueAndValidity()
    }
  }

  selectFilePartnerBanner (event: Event) {
    const originalPartnerBannerImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalPartnerBannerImage = reader.result
    }
    try {
      reader.readAsDataURL(originalPartnerBannerImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedPartnerBannerImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedPartnerBannerImage = result.img.dataURL
          this.partnerBannerImagePosition = result.config
          this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editPartnerBannerImage () {
    try {
      const img = this.originalPartnerBannerImage
        ? this.originalPartnerBannerImage
        : await this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.partnerBannerImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedPartnerBannerImage = result.img.dataURL
        this.partnerBannerImagePosition = result.config
        this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async removeImage (input) {
    if (input === 'partnerBanner') {
      this.readUrlPartnerBanner = ''
      this.originalPartnerBannerImage = undefined
      this.croppedPartnerBannerImage = undefined
      this.partnerBannerImagePosition = undefined
      this.partnerBannerImageData = undefined
    }
  }

  goBack () {
    this.location.back()
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      page: this.formGroup.value.page,
    }

    if (this.id) {
      this.bannersPagesAppService.update(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            this.goBack()
            this.snackBar.open('Página atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.bannersPagesAppService.create(data).then(
        async response => {
          if (response.isCreated) {
            this.goBack()
            this.snackBar.open('Página criada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
