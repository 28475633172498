/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-customer-cpf.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-mask";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./modal-customer-cpf.component";
import * as i6 from "@angular/material/dialog";
var styles_ModalCustomerCpfComponent = [i0.styles];
var RenderType_ModalCustomerCpfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalCustomerCpfComponent, data: {} });
export { RenderType_ModalCustomerCpfComponent as RenderType_ModalCustomerCpfComponent };
export function View_ModalCustomerCpfComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.MaskPipe, [i2.MaskApplierService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "main", [["class", "modal-customer-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "link-cancel"], ["mat-icon-button", ""], ["matTooltip", "Cancelar"], ["matTooltipPosition", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "mat-card", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["CPF: ", ""])), i1.ɵppd(10, 2)], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).inline; var currVal_1 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.data.customerCpf, "000.000.000-00")); _ck(_v, 9, 0, currVal_2); }); }
export function View_ModalCustomerCpfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-customer-cpf", [], null, null, null, View_ModalCustomerCpfComponent_0, RenderType_ModalCustomerCpfComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModalCustomerCpfComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalCustomerCpfComponentNgFactory = i1.ɵccf("app-modal-customer-cpf", i5.ModalCustomerCpfComponent, View_ModalCustomerCpfComponent_Host_0, {}, {}, []);
export { ModalCustomerCpfComponentNgFactory as ModalCustomerCpfComponentNgFactory };
