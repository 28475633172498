import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
var ModalPartnerEditionMacroBindingInformation = /** @class */ (function () {
    function ModalPartnerEditionMacroBindingInformation(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
    }
    ModalPartnerEditionMacroBindingInformation.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalPartnerEditionMacroBindingInformation.prototype.confirm = function () {
        this.dialogRef.close('submit');
    };
    ModalPartnerEditionMacroBindingInformation.prototype.ngOnInit = function () {
    };
    return ModalPartnerEditionMacroBindingInformation;
}());
export { ModalPartnerEditionMacroBindingInformation };
