import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatTableDataSource } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-modal-late-architect-invoices',
  templateUrl: './modal-late-architect-invoices.component.html',
  styleUrls: ['./modal-late-architect-invoices.component.scss'],
})
export class ModalLateArchitectInvoices implements OnInit {
  public headersTable: string[] = ['selector', 'storeName', 'invoiceNumber','amount', 'createdAt']
  public formGroup: FormGroup

  public selectedInvoices: any = {}
  public dataSource;

  constructor (
    public dialogRef: MatDialogRef<ModalLateArchitectInvoices>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    console.log(data)
    this.dataSource =  new MatTableDataSource(data)
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  confirm () {

    const selectedInvoiceIds = Object.entries(this.selectedInvoices)
      .map(([key,value]) => value? key: null)
      .filter(key => key )
    
    this.dialogRef.close(selectedInvoiceIds)
  }

  not () {
    this.dialogRef.close('not')
  }

  ngOnInit () {
    // empty
  }

  getSanitizedHtml(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  onInvoiceSelect(isChecked, invoiceId) {
    this.selectedInvoices[invoiceId] = isChecked    
  }

  convertCentsToReal (value) {
    if(value)
      return value / 100
    else
      return value
  }
}
