import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var DocumentsNotIntegrationListComponent = /** @class */ (function () {
    function DocumentsNotIntegrationListComponent(authService, snackBar, dialog) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['cpf', 'actions'];
    }
    DocumentsNotIntegrationListComponent.prototype.ngOnInit = function () {
        // deploy
        this.list().catch(function (err) { console.log(err); });
    };
    DocumentsNotIntegrationListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var items;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .documentsNotIntegration()
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        items = _a.sent();
                        this.dataSource = new MatTableDataSource(items);
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsNotIntegrationListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse CPF',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.authService.deleteDocumentsNotIntegration(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (err) { console.log(err); });
                                this.snackBar.open('CPF removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return DocumentsNotIntegrationListComponent;
}());
export { DocumentsNotIntegrationListComponent };
