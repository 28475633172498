<app-logged class="architect-closings">
  <header class="architect-closings__header">
    <h2>Fechamentos Arquitetos</h2>
    <div class="architect-closings__header__actions">
      <a class="btn-primary-light" mat-button (click)="goBack()"> VOLTAR </a>
    </div>
  </header>

  <div class="table-responsive">
    <table mat-table [dataSource]="closingsList">
      <ng-container matColumnDef="closingNumber">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">{{ element.number }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>Data inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.startDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>Data final</th>
        <td mat-cell *matCellDef="let element">{{ element.endDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="totalNdTax">
        <th mat-header-cell *matHeaderCellDef>Taxa Cashback</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalNdTax) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalNfTax">
        <th mat-header-cell *matHeaderCellDef>Taxa Administrativa</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalNfTax) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalPay">
        <th mat-header-cell *matHeaderCellDef>Total à pagar</th>
        <td mat-cell *matCellDef="let element">
          {{ totalPay(element.totalNdTax, element.totalNfTax) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalInvoices">
        <th mat-header-cell *matHeaderCellDef>Qtd de Notas Fiscais</th>
        <td mat-cell *matCellDef="let element">{{ element.totalInvoices }}</td>
      </ng-container>

      <ng-container matColumnDef="totalInvoicesValue">
        <th mat-header-cell *matHeaderCellDef>Valor Notas Fiscais</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalInvoicesValue) | currency: 'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Exportar"
            matTooltipPosition="left"
            (click)="export(element.id)"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>

  <app-form-filter
    type="architectInvoiceClosings"
    actions="pagination"
    [length]="pageTotal"
    [pageSizeOptions]="pageSizeOptions"
    (returnFilter)="filterCallback($event)"
  >
  </app-form-filter>
</app-logged>
