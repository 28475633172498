<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVA TRANSAÇÃO MANUAL</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores/{{storeID}}/transactions"
        [queryParams]="params" skipLocationChange="true">
        VOLTAR
      </button>
    </div>
  </header>
  <h4>Informações Gerais</h4>
  <div class="table-responsive">
    <table mat-table [dataSource]="transactionsInfo">
      <ng-container matColumnDef="minimumTransactionValue">
        <th mat-header-cell *matHeaderCellDef>Valor mínimo da transação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.minimumTransactionValue | currency:"BRL": "R$" }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="minimumInstallmentValue">
        <th mat-header-cell *matHeaderCellDef>Valor mínimo da parcela</th>
        <td mat-cell *matCellDef="let element">{{ element.minimumInstallmentValue | currency:"BRL": "R$"}}
        </td>
      </ng-container> -->
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card style="margin-bottom: 1rem">
      <mat-form-field style="width: 35%">
        <mat-label>Sub-empresas</mat-label>
        <mat-select formControlName="subStoreId">
          <mat-option *ngFor="let mg of subStores" [value]="mg.id">
            {{ mg.subStoreName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações da Transação</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="customerCpf" mask="000.000.000-00" />
              <mat-error *ngIf="formGroup.controls.customerCpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.customerCpf) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor</mat-label>
              <input matInput formControlName="totalValue" (blur)="changeValueTotal($event)" currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', 'align': 'left' }" />
              <mat-error *ngIf="formGroup.controls.totalValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.totalValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="description" />
              <mat-error *ngIf="formGroup.controls.description?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.description) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Documento de autorização (opcional)</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <img *ngIf="documentUrl != ''" width="200" src="{{documentUrl}}" (error)="imgLoadError()" />
              <span *ngIf="documentUrl == null && approveDoc" class="filename-placeholder">
                <mat-icon>description</mat-icon> {{this.approveDoc}}
              </span>
              <span class="remove-doc-wrapper">
                <button class="btn-primary-light" mat-button *ngIf=" approveDoc" (click)="removeDocument();">
                  <mat-icon>cancel</mat-icon> Remover documento
                </button>
              </span>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
