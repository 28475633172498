import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-choose-campaign-type-modal',
  templateUrl: './modal-choose-campaign-type.component.html',
  styleUrls: ['./modal-choose-campaign-type.component.scss'],
})
export class ModalChooseCampaignTypeComponent implements OnInit {
  public formGroup: FormGroup
  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalChooseCampaignTypeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      campaigns: { default: any[], extra: any[] }
    },
  ) { }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      campaignId: [null, []],
      defaultCampaignId: [null, [Validators.required]],
    })
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  submit () {
    if (!this.formGroup.valid) {
      return false
    }
    this.dialogRef.close(
      {
        campaignId: this.formGroup.value.campaignId,
        defaultCampaignId: this.formGroup.value.defaultCampaignId,
      },
    )
  }
}
