import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store } from 'src/app/models/store';
import { TransactionService } from 'src/app/services/transaction.service';
interface DialogData {
  cnpj: string;
  transaction: any
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-cancel-transaction.component.html',
  styleUrls: ['./modal-cancel-transaction.component.scss']
})
export class ModalCancelTransactionComponent implements OnInit {

  public formGroup: FormGroup;
  public userRole: string
  public showLiquidationMessage = false

  constructor(
    public dialogRef: MatDialogRef<ModalCancelTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public transactionService: TransactionService
  ) {
      console.log('data', data);
      this.userRole = localStorage.getItem('userRole')
      
     }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }    
    this.dialogRef.close(this.formGroup.value.reason);
  }



  async ngOnInit() {    

    this.formGroup = this.formBuilder.group(
      {
        reason: [null, [
          Validators.required,
        ]],
      });
    
    if(this.userRole === 'ADMIN'){
      const transactionLiquidationInfo = await this.transactionService.getLiquidationPaymentInfo(this.data.transaction.id)

      if(transactionLiquidationInfo){
        if(transactionLiquidationInfo.administrativeTaxPayment && ![true, false].includes(transactionLiquidationInfo.administrativeTaxPayment.success))
           this.showLiquidationMessage = true
        if(transactionLiquidationInfo.liquidationPayment && ![true, false].includes(transactionLiquidationInfo.liquidationPayment.success))
          this.showLiquidationMessage = true
      }
    }
  }

  
}

