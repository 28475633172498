import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Observable, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'src/app/services/uploadAdapter.service';
var ArchitectInvoiceFormComponent = /** @class */ (function () {
    function ArchitectInvoiceFormComponent(errorsService, formBuilder, http, companiesService, architectInvoiceService, snackBar, location, route, router) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.http = http;
        this.companiesService = companiesService;
        this.architectInvoiceService = architectInvoiceService;
        this.snackBar = snackBar;
        this.location = location;
        this.route = route;
        this.router = router;
        this.documentIsChanged = false;
        this.loadingReceivers = false;
        this.updateTooltipMessage = '';
        this.Editor = ClassicEditor;
        if (this.route.snapshot.paramMap.get('architectInvoiceId')) {
            this.architectInvoiceId = this.route.snapshot.paramMap.get('architectInvoiceId');
        }
        this.updateTooltipMessage = this.architectInvoiceId ? 'Não é possível alterar esses dados. É necessário cancelar e criar uma nova nota fiscal para isso' : '';
        this.initForm();
    }
    ArchitectInvoiceFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var invoice, receiver, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.selectedStoreBusinessUnit = JSON.parse(localStorage.getItem('storeSelected'));
                        this.config = {
                            toolbar: {
                                items: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                    'blockQuote',
                                    'undo',
                                    'redo',
                                    '|',
                                ],
                            },
                        };
                        this.formGroup = this.formBuilder.group({
                            invoiceNumber: [null, [Validators.required]],
                            invoiceSerial: [null, []],
                            totalValue: [null, [Validators.required]],
                            targetDisplayName: [null, [Validators.required]],
                            targetDocument: [null, [Validators.required]],
                            type: ['PF', [Validators.required]],
                            observation: [null, []],
                        });
                        if (!this.architectInvoiceId) return [3 /*break*/, 5];
                        this.formGroup.get('targetDisplayName').disable();
                        this.formGroup.get('type').disable();
                        this.formGroup.get('totalValue').disable();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.architectInvoiceService.get(this.architectInvoiceId)];
                    case 2:
                        invoice = _a.sent();
                        return [4 /*yield*/, this.searchReceiver(invoice.type, invoice.targetDocument)];
                    case 3:
                        receiver = _a.sent();
                        this.formGroup.patchValue({
                            invoiceNumber: invoice.invoiceNumber,
                            invoiceSerial: invoice.invoiceSerial,
                            totalValue: this.convertCentsToReal(invoice.totalValue),
                            targetDocument: invoice.targetDocument,
                            type: invoice.type,
                            targetDisplayName: this.getReceiverDisplayNames(receiver[0], invoice.type),
                            observation: invoice.observation,
                        });
                        this.currentPdfUrl = invoice.pdfUrl;
                        this.currentFilename = invoice.pdfUrl.split('/').pop();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 5];
                    case 5:
                        this.formGroup.get('targetDisplayName').valueChanges.pipe(distinctUntilChanged(), debounceTime(1000), filter(function (targetNameOrDocument) { return !!targetNameOrDocument; })).subscribe(function (targetNameOrDocument) {
                            _this.targets$ = from(_this.searchReceiver(_this.formGroup.get('type').value, targetNameOrDocument));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.ngOnInit = function () {
    };
    ArchitectInvoiceFormComponent.prototype.searchReceiver = function (type, termToSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var receivers, _a, error_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingReceivers = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        if (!(type === 'PF')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getArchitects(termToSearch)];
                    case 2:
                        _a = _b.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.getCompanies(termToSearch)];
                    case 4:
                        _a = _b.sent();
                        _b.label = 5;
                    case 5:
                        receivers = _a;
                        return [3 /*break*/, 8];
                    case 6:
                        error_2 = _b.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        this.loadingReceivers = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, receivers];
                }
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.getReceiverDisplayNames = function (receiver, type) {
        if (type === void 0) { type = undefined; }
        var displayName = '';
        var typeToCheck = type || this.formGroup.get('type').value;
        if (typeToCheck == 'PF') {
            displayName = receiver.firstName + ' ' + receiver.lastName + ' - CPF: ' + receiver.cpf;
        }
        else {
            displayName = receiver.companyName + ' - CNPJ: ' + receiver.cnpj;
        }
        return displayName;
    };
    ArchitectInvoiceFormComponent.prototype.onTargetTypeChange = function () {
        this.targets$ = new Observable();
        this.formGroup.patchValue({
            targetDocument: '',
            targetDisplayName: '',
        });
    };
    ArchitectInvoiceFormComponent.prototype.onTargetChange = function (target) {
        var displayName = this.getReceiverDisplayNames(target);
        var targetDocument = '';
        if (this.formGroup.get('type').value == 'PF') {
            targetDocument = target.cpf;
        }
        else {
            targetDocument = target.cnpj;
        }
        this.formGroup.patchValue({
            targetDocument: targetDocument,
            targetDisplayName: displayName,
        });
    };
    ArchitectInvoiceFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectInvoiceFormComponent.prototype.getArchitects = function (targetNameOrDocument) {
        return this.architectInvoiceService.searchArchitectForInvoice(targetNameOrDocument).toPromise();
    };
    ArchitectInvoiceFormComponent.prototype.getCompanies = function (targetNameOrDocument) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService.searchForCompaniesByNameOrCnpj(targetNameOrDocument)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.architectInvoiceId) {
                    return [2 /*return*/, this.createInvoiceSubmit()];
                }
                else {
                    return [2 /*return*/, this.updateInvoiceSubmit()];
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.createInvoiceSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_3, totalValue, data, response, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid || !this.fileUrl) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.architectInvoiceService.getUploadUrl(this.selectedStoreBusinessUnit.storeId, this.fileName).toPromise()];
                    case 2:
                        response = _a.sent();
                        this.urlUploadS3 = response.url;
                        this.publicUrl = response.publicUrl;
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        this.snackBar.open(error_3.error.message);
                        return [2 /*return*/, false];
                    case 4: return [4 /*yield*/, this.architectInvoiceService.uploadInvoicePdf(this.urlUploadS3, this.fileUrl).toPromise()];
                    case 5:
                        _a.sent();
                        totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '');
                        data = {
                            invoiceNumber: this.formGroup.value.invoiceNumber,
                            invoiceSerial: this.formGroup.value.invoiceSerial,
                            totalValueInCents: parseInt(totalValue),
                            targetDocument: this.formGroup.value.targetDocument,
                            pdfUrl: this.publicUrl,
                            type: this.formGroup.value.type,
                            storeId: this.selectedStoreBusinessUnit.storeId,
                            observation: this.formGroup.value.observation,
                        };
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        _a.label = 6;
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.architectInvoiceService.createInvoice(data).toPromise()];
                    case 7:
                        response = _a.sent();
                        if (response) {
                            this.snackBar.open('Nota fiscal criada com sucesso.');
                            this.router.navigate(['/dashboard/architect-invoice-list']);
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        error_4 = _a.sent();
                        this.snackBar.open(error_4.error.message);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.updateInvoiceSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, response, snackBarMessage, uploadUrlResponse, urlUploadS3, publicUrl, responseUpdatePdfUrl, e_1, error_5, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!this.fileUrl && this.documentIsChanged) {
                            this.snackBar.open('Selecione o novo arquivo para anexar à nota fiscal');
                            return [2 /*return*/, false];
                        }
                        data = {
                            invoiceNumber: this.formGroup.value.invoiceNumber,
                            invoiceSerial: this.formGroup.value.invoiceSerial,
                            observation: this.formGroup.value.observation,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 15, , 16]);
                        return [4 /*yield*/, this.architectInvoiceService.updateInvoice(data, this.architectInvoiceId).toPromise()];
                    case 2:
                        response = _a.sent();
                        snackBarMessage = 'Nota fiscal alterada com sucesso.';
                        if (!(this.documentIsChanged && response)) return [3 /*break*/, 14];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 13, , 14]);
                        return [4 /*yield*/, this.architectInvoiceService.getUploadUrl(this.selectedStoreBusinessUnit.storeId, this.fileName).toPromise()];
                    case 4:
                        uploadUrlResponse = _a.sent();
                        urlUploadS3 = uploadUrlResponse.url;
                        publicUrl = uploadUrlResponse.publicUrl;
                        return [4 /*yield*/, this.architectInvoiceService.uploadInvoicePdf(urlUploadS3, this.fileUrl).toPromise()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.architectInvoiceService.updateInvoice({ pdfUrl: publicUrl }, this.architectInvoiceId).toPromise()];
                    case 6:
                        responseUpdatePdfUrl = _a.sent();
                        if (!responseUpdatePdfUrl) return [3 /*break*/, 11];
                        _a.label = 7;
                    case 7:
                        _a.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.architectInvoiceService.removeInvoicePdf(this.architectInvoiceId, this.currentPdfUrl)];
                    case 8:
                        _a.sent();
                        return [3 /*break*/, 10];
                    case 9:
                        e_1 = _a.sent();
                        console.log('Não foi possivel excluir o arquivo antigo');
                        return [3 /*break*/, 10];
                    case 10: return [3 /*break*/, 12];
                    case 11: throw new Error();
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        error_5 = _a.sent();
                        snackBarMessage = 'Dados da nota fiscal alterados, mas não foi possivel alterar o documento. Tente novamente mais tarde.';
                        return [3 /*break*/, 14];
                    case 14:
                        if (response) {
                            this.snackBar.open(snackBarMessage);
                            this.router.navigate(['/dashboard/architect-invoice-list']);
                        }
                        return [3 /*break*/, 16];
                    case 15:
                        error_6 = _a.sent();
                        this.snackBar.open(error_6.error.message);
                        return [3 /*break*/, 16];
                    case 16: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.imgLoadError = function () {
        console.log('IMAGE ERROR');
        this.documentUrl = null;
    };
    ArchitectInvoiceFormComponent.prototype.selectFile = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reader;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.fileUrl = event.target.files[0];
                this.fileName = event.target.files[0].name;
                if (event.target.files && event.target.files[0]) {
                    reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = function (evt) {
                        // called once readAsDataURL is completed
                        _this.documentUrl = evt.target.result;
                    };
                }
                else {
                    this.removeDocument();
                }
                return [2 /*return*/];
            });
        });
    };
    ArchitectInvoiceFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.fileUrl = null;
        this.fileName = undefined;
        this.fileInput.nativeElement.value = '';
    };
    ArchitectInvoiceFormComponent.prototype.changeCurrentDocument = function () {
        this.documentUrl = null;
        this.documentIsChanged = true;
    };
    ArchitectInvoiceFormComponent.prototype.cancelDocumentChange = function () {
        this.removeDocument();
        this.documentIsChanged = false;
    };
    ArchitectInvoiceFormComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    ArchitectInvoiceFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    return ArchitectInvoiceFormComponent;
}());
export { ArchitectInvoiceFormComponent };
