import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { TransactionService } from '../../services/transaction.service'
import { StoreService } from '../../services/store.service'
import { NotificationService } from '../../services/notification.service'
import { SegmentationService } from '../../services/segmentation.service'
import { BusinessPartnersService } from '../../services/businessPartners.service'
import { QuizService } from '../../services/quiz.service'
import { QuestionsService } from '../../services/questions.service'
import { CustomersAssociatesService } from '../../services/customersAssociates.service'
import { MembershipService } from '../../services/membership.service'
import { AuthService } from '../../services/auth.service'
import { FundService } from '../../services/fund.service'
import { PreRegistrationService } from '../../services/preRegistration.service'
import { PageEvent } from '@angular/material/paginator'
import { CampaignService } from './../../services/campaign.service'
import { AirportsService } from './../../services/airports.service'
import { CompaniesService } from '../../services/companies.service'
import { ReportService } from '../../services/report.service'
import * as moment from 'moment'
import { ArchitectInvoiceService } from 'src/app/services/architectInvoice.service'
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service'
import { BillPaymentNotificationsComponent } from '../../modules/dashboard/bill-payment-notifications/bill-payment-notifications.component'
import { BillPaymentNotificationService } from 'src/app/services/bill-payment-notification.service'
import { ManualJcoinCreditService } from 'src/app/services/manual-jcoin-credit.service'
@Component({
  selector: 'app-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss'],
})
export class FormFilterComponent implements OnInit {
  public initialFormValue = {
    numberTransaction: [''],
    nomeFantasiaTransaction: [''],
    cpfTransaction: [''],
    dateTransaction: [''],
    status: [''],
    cnpjStore: [''],
    nomeFantasiaStore: [''],
    nameClient: [''],
    cpfClient: [''],
    phoneClient: [''],
    emailClient: [''],
    typeRegister: [''],
    nameStoreUser: [''],
    phoneStoreUser: [''],
    emailStoreUser: [''],
    numberTransactionPending: [''],
    cpfTransactionPending: [''],
    dateTransactionPending: [''],
    typeTransaction: [''],
    transactionsTest: [''],
    titleNotification: [''],
    descriptionNotification: [''],
    dateNotification: [''],
    nameSegmentation: [''],
    cpfSegmentationResults: [''],
    nameSegmentationResults: [''],
    nameBusinessPartners: [''],
    activeBusinessPartners: [''],
    nameQuiz: [''],
    nameQuestion: [''],
    nameQuizCustomer: [''],
    nameQuizClient: [''],
    cpfQuizClient: [''],
    cnpjFund: [''],
    nameFund: [''],
    activeFund: [''],
    numberFundTransaction: [''],
    cpfFundTransaction: [''],
    dateFundTransaction: [''],
    statusFundTransaction: [''],
    nameFundExtract: [''],
    dateFundExtract: [''],
    nameFundCustomersAssociates: [''],
    cpfFundCustomersAssociates: [''],
    nameNotificationCustomers: [''],
    cpfNotificationCustomers: [''],
    cpfPlanMembership: [''],
    fullNamePlanMembership: [''],
    dateLastPaymentPlanMembership: [''],
    numberTransactionPlanMembership: [''],
    dateTransactionPlanMembership: [''],
    statusPlanMembership: [''],
    namePlanMembership: [''],
    beginDatePlanMembership: [''],
    endDatePlanMembership: [''],
    activePlanMembership: [''],
    namePlanMembershipCustomer: [''],
    beginDatePlanMembershipCustomer: [''],
    endDatePlanMembershipCustomer: [''],
    baginDateMovementJcoins: [''],
    endDateMovementJcoins: [''],
    typePlanMembership: [''],
    namePreRegistration: [''],
    cpfPreRegistration: [''],
    categoriesBusinessPartners: [''],
    typeNotification: [''],
    fullNameCustomerBeneficiary: [''],
    cpfCustomerBeneficiary: [''],
    cpfBeneficiary: [''],
    fullNameBeneficiary: [''],
    nameIntegration: [''],
    cpfIntegration: [''],
    dateInitIntegration: [''],
    dateEndIntegration: [''],
    percentageIntegration: [''],
    nameAirportCompany: [''],
    cnpjAirportCompany: [''],
    cpfAirportCompany: [''],
    cnpjAirportCompanyAndNameAirportCompany: [''],
    isCpfAirportCompany: [''],
    cnpjArchitectCompany: [''],
    billPaymentCpf: [''],
    billPaymentMonth: [''],
    billPaymentYear: [''],
    manualJcoinCreditStatus: [''],
    manualJcoinCreditCreatedAtFrom: [''],
    manualJcoinCreditCreatedAtTo: [''],
    isCpfArchitectCompany: [''],
    cnpjArchitectStores: [''],
    nomeFantasiaArchitectStores: [''],
    fullNameUserArchitectStores: [''],
    emailUserArchitectStores: [''],
    architectInvoiceState: [''],
    architectInvoiceEndDate: [''],
    architectInvoiceStartDate: [''],
    architectInvoiceStoreCnpj: [''],
    architectInvoiceTargetDocument: [''],
    architectInvoiceStoreName: [''],
    reportFundMonth: [''],
    reportFundYear: [''],
    reportStockClosingMonth: [''],
    reportStockClosingYear: [''],
    reportConsolidatedMonth: [''],
    reportConsolidatedYear: [''],
    cnpjTransferScheduleList: [''],
    startDueDateTransferScheduleList: [''],
    endDueDateTransferScheduleList: [''],
    reasonTransferScheduleList: [''],
    approvalStatusTransferScheduleList: [''],
    startPaidAtDateTransferScheduleList: [''],
    endPaidAtDateTransferScheduleList: [''],
    documentAirportIntegration: [''],
    cnpjOriginAirportIntegration: [''],
    dateAirportInitIntegration: [''],
    dateAirportEndIntegration: [''],
    dateAirportIntegration: [''],
    prefixAirportIntegration: [''],
    documentAirportIntegrationNotEligible: [''],
    cnpjOriginAirportIntegrationNotEligible: [''],
    prefixAirportIntegrationNotEligible: [''],
    dateAirportInitIntegrationNotEligible: [''],
    dateAirportEndIntegrationNotEligible: [''],
    dateAirportIntegrationNotEligible: [''],
  }

  public formGroup: FormGroup
  public campaignType: string
  public idCampaign: string
  public numberTransaction: string
  public nomeFantasiaTransaction: string
  public cpfTransaction: string
  public dateTransaction: string
  public status: string
  public joinFilter: string
  public cnpjStore: string
  public nomeFantasiaStore: string
  public nameClient: string
  public cpfClient: string
  public phoneClient: string
  public emailClient: string
  public typeRegister: string
  public nameStoreUser: string
  public phoneStoreUser: string
  public emailStoreUser: string
  public numberTransactionPending: string
  public cpfTransactionPending: string
  public dateTransactionPending: string
  public typeTransaction: string
  public transactionsTest: string
  public titleNotification: string
  public descriptionNotification: string
  public dateNotification: string
  public nameSegmentation: string
  public cpfSegmentationResults: string
  public nameSegmentationResults: string
  public nameQuiz: string
  public nameQuestion: string
  public nameBusinessPartners: string
  public activeBusinessPartners: string
  public nameQuizCustomer: string
  public nameQuizClient: string
  public cpfQuizClient: string

  public cnpjFund: string
  public nameFund: string
  public activeFund: string
  public numberFundTransaction: string
  public cpfFundTransaction: string
  public dateFundTransaction: string
  public statusFundTransaction: string
  public nameFundExtract: string
  public dateFundExtract: string
  public nameFundCustomersAssociates: string
  public cpfFundCustomersAssociates: string
  public cpfNotificationCustomers: string
  public nameNotificationCustomers: string
  public cpfPlanMembership: string
  public fullNamePlanMembership: string
  public dateLastPaymentPlanMembership: string
  public numberTransactionPlanMembership: string
  public dateTransactionPlanMembership: string
  public statusPlanMembership: string
  public namePlanMembership: string
  public beginDatePlanMembership: string
  public endDatePlanMembership: string
  public activePlanMembership: string
  public namePlanMembershipCustomer: string
  public beginDatePlanMembershipCustomer: string
  public endDatePlanMembershipCustomer: string
  public dateMovementJcoins: string
  public typePlanMembership: string
  public cpfPreRegistration: string
  public namePreRegistration: string
  public categoriesBusinessPartners: string
  public typeNotification: string
  public fullNameCustomerBeneficiary: string
  public cpfCustomerBeneficiary: string
  public cpfBeneficiary: string
  public fullNameBeneficiary: string
  public nameIntegration: string
  public cpfIntegration: string
  public dateInitIntegration: string
  public dateEndIntegration: string
  public percentageIntegration: string
  public nameAirportCompany: string
  public cnpjAirportCompany: string
  public cpfAirportCompany: string
  public cnpjAirportCompanyAndNameAirportCompany: string
  public isCpfAirportCompany: string
  public cnpjArchitectCompany: string
  public billPaymentCpf: string
  public billPaymentMonth: string
  public billPaymentYear: string
  public manualJcoinCreditStatus: string
  public manualJcoinCreditCreatedAtFrom: string
  public manualJcoinCreditCreatedAtTo: string
  public isCpfArchitectCompany: string
  public cnpjArchitectStores: string
  public nomeFantasiaArchitectStores: string
  public fullNameUserArchitectStores: string
  public emailUserArchitectStores: string
  public architectInvoiceState: string
  public architectInvoiceEndDate: string
  public architectInvoiceStartDate: string
  public architectInvoiceStoreCnpj: string
  public architectInvoiceTargetDocument: string
  public architectInvoiceStoreName: string
  public reportFundDate: string
  public reportFundDateReturn: string
  public reportStockClosingDate: string
  public reportStockClosingDateReturn: string
  public reportConsolidatedDate: string
  public reportConsolidatedDateReturn: string
  public cnpjTransferScheduleList: string
  public startDueDateTransferScheduleList: string
  public endDueDateTransferScheduleList: string
  public reasonTransferScheduleList: string
  public approvalStatusTransferScheduleList: string
  public dateTransferScheduleList: string
  public documentAirportIntegration: string
  public cnpjOriginAirportIntegration: string
  public dateAirportInitIntegration: string
  public dateAirportEndIntegration: string
  public dateAirportIntegration: string
  public prefixAirportIntegration: string
  public documentAirportIntegrationNotEligible: string
  public cnpjOriginAirportIntegrationNotEligible: string
  public prefixAirportIntegrationNotEligible: string
  public dateAirportInitIntegrationNotEligible: string
  public dateAirportEndIntegrationNotEligible: string
  public dateAirportIntegrationNotEligible: string
  public filters: any
  filtered: any[] = []

  @Input() fieldsForm: any
  @Input() formGroupValue: any
  @Input() type: any
  @Input() actions: any
  @Input() length: any
  @Input() pageSizeOptions: any
  @Input() storeID: any
  @Input() categoryID: any
  @Input() quizID: any
  @Input() customerID: any
  @Input() fundId: any
  @Input() maskExample: any
  @Input() planId: any
  @Input() segmentationId: any
  @Input() limit: any
  @Input() filtersSegmentation: any
  @Input() notificationId: any
  @Input() cpf: any
  @Input() categoryJoinId: any
  @Input() typePreRegistration: any
  @Output() returnFilter = new EventEmitter()
  @Output() returnFilterReserve = new EventEmitter()
  @Output() returnFilterMain = new EventEmitter()
  @Input() usersStoresId: any
  @Input() storeBusinessUnitId: any

  pageEvent: PageEvent

  page = 1
  pageSize: string

  constructor(
    private readonly formBuilder: FormBuilder,
    public campaignService: CampaignService,
    public transactionService: TransactionService,
    public storeService: StoreService,
    public notificationService: NotificationService,
    public segmentationService: SegmentationService,
    public businessPartnersService: BusinessPartnersService,
    public quizService: QuizService,
    public questionsService: QuestionsService,
    public fundService: FundService,
    public customersAssociatesService: CustomersAssociatesService,
    public membershipService: MembershipService,
    public authService: AuthService,
    public preRegistrationService: PreRegistrationService,
    public airportsService: AirportsService,
    public companiesService: CompaniesService,
    public billPaymentNotificationService: BillPaymentNotificationService,
    public manualJcoinCreditService: ManualJcoinCreditService,
    public architectInvoiceService: ArchitectInvoiceService,
    public reportService: ReportService,
    public liquidationTransferService: LiquidationTransferService,
  ) { }

  ngOnInit() {
    localStorage.removeItem('filter')
    localStorage.removeItem('pageSizeEvent')
    this.initForm()
  }

  initForm() {
    if (this.formGroupValue) {
      this.formGroup = this.formBuilder.group(
        Object.assign(this.initialFormValue, this.formGroupValue),
      )
      // eslint-disable-next-line no-void
      void this.submit()
    } else {
      this.formGroup = this.formBuilder.group(this.initialFormValue)
    }

    if (this.categoryJoinId) {
      this.formGroup.patchValue({
        categoriesBusinessPartners: this.categoryJoinId,
      })
    }
  }

  async submit() {
    if (localStorage.getItem('pageSizeEvent')) {
      this.pageSize = localStorage.getItem('pageSizeEvent')
    } else {
      this.pageSize = '10'
    }
    Object.keys(this.formGroup.value).map((k, i) => {
      if (this.formGroup.value[k]) {
        this.formGroup.value[k] = this.formGroup.value[k].replace('+', '%2B')
        const remove = this.formGroup.value[k].replace(/[#"&]/g, '')
        return (this.formGroup.value[k] = remove.trim())
      }
    })

    this.formGroup.value.campaignType
      ? (this.campaignType = `"fields":["campaign.campaignType"], "op": "like", "value":"${this.formGroup.value.campaignType}"`)
      : (this.campaignType = undefined)
    this.formGroup.value.idCampaign
      ? (this.idCampaign = `"fields":["campaign.idCampaign"], "op": "like", "value":"${this.formGroup.value.idCampaign}"`)
      : (this.idCampaign = undefined)
    this.formGroup.value.numberTransaction
      ? (this.numberTransaction = `"fields":["transactions.order_number"], "op": "like", "value":"${this.formGroup.value.numberTransaction}"`)
      : (this.numberTransaction = undefined)
    this.formGroup.value.nomeFantasiaTransaction
      ? (this.nomeFantasiaTransaction = `"fields":["store.nome_fantasia"], "op": "like", "value":"${this.formGroup.value.nomeFantasiaTransaction}"`)
      : (this.nomeFantasiaTransaction = undefined)
    this.formGroup.value.cpfTransaction
      ? (this.cpfTransaction = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfTransaction}"`)
      : (this.cpfTransaction = undefined)
    this.formGroup.value.dateTransaction
      ? (this.dateTransaction = `"fields":["transactions.created_at"], "op": "like", "value":"${this.formGroup.value.dateTransaction}"`)
      : (this.dateTransaction = undefined)
    this.formGroup.value.numberTransactionPending
      ? (this.numberTransactionPending = `"fields":["transactions.order_number"], "op": "like", "value":"${this.formGroup.value.numberTransactionPending}"`)
      : (this.numberTransactionPending = undefined)
    this.formGroup.value.cpfTransactionPending
      ? (this.cpfTransactionPending = `"fields":["buyer.cpf"], "op": "like", "value":"${this.formGroup.value.cpfTransactionPending}"`)
      : (this.cpfTransactionPending = undefined)
    this.formGroup.value.dateTransactionPending
      ? (this.dateTransactionPending = `"fields":["transactions.created_at"], "op": "like", "value":"${this.formGroup.value.dateTransactionPending}"`)
      : (this.dateTransactionPending = undefined)
    this.formGroup.value.nomeFantasiaStore
      ? (this.nomeFantasiaStore = `"fields":["nome_fantasia"], "op": "like", "value":"${this.formGroup.value.nomeFantasiaStore}"`)
      : (this.nomeFantasiaStore = undefined)
    this.formGroup.value.cnpjStore
      ? (this.cnpjStore = `"fields":["cnpj"], "op": "like", "value":"${this.formGroup.value.cnpjStore}"`)
      : (this.cnpjStore = undefined)

    if (this.formGroup.value.nameClient) {
      this.nameClient = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameClient}"`
    } else {
      this.nameClient = undefined
    }

    this.formGroup.value.cpfClient
      ? (this.cpfClient = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfClient}"`)
      : (this.cpfClient = undefined)
    this.formGroup.value.phoneClient
      ? (this.phoneClient = `"fields":["customers.phone_number"], "op": "like", "value":"${this.formGroup.value.phoneClient}"`)
      : (this.phoneClient = undefined)
    this.formGroup.value.emailClient
      ? (this.emailClient = `"fields":["email_address"], "op": "like", "value":"${this.formGroup.value.emailClient}"`)
      : (this.emailClient = undefined)

    if (this.formGroup.value.typeRegister === 'jhsfid') {
      this.typeRegister = '"fields":["jhsfid"], "op": "like", "value":"true"'
    }
    if (this.formGroup.value.typeRegister === 'architect') {
      this.typeRegister = '"fields":["architect"], "op": "like", "value":"true"'
    }
    if (this.formGroup.value.typeRegister === 'jhsfid_architect') {
      this.typeRegister = '"fields":["jhsfid_architect"], "op": "like", "value":"true"'
    }

    if (this.formGroup.value.nameStoreUser) {
      this.nameStoreUser = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameStoreUser}"`
    } else {
      this.nameStoreUser = undefined
    }

    this.formGroup.value.phoneStoreUser
      ? (this.phoneStoreUser = `"fields":["phone_number"], "op": "like", "value":"${this.formGroup.value.phoneStoreUser}"`)
      : (this.phoneStoreUser = undefined)
    this.formGroup.value.emailStoreUser
      ? (this.emailStoreUser = `"fields":["email_address"], "op": "like", "value":"${this.formGroup.value.emailStoreUser}"`)
      : (this.emailStoreUser = undefined)

    if (this.formGroup.value.status) {
      if (this.formGroup.value.status == 'pendingJHSF') {
        this.status =
          '"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value":"PENDING"'
      } else {
        this.status = `"fields":["isManual"], "op": "=", "value": false}, {"fields":["transactions.state"], "op": "like", "value":"${this.formGroup.value.status}"`
      }
    } else {
      this.status = undefined
    }

    if (this.formGroup.value.typeTransaction) {
      if (this.formGroup.value.typeTransaction === 'MEMBERSHIP') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "=", "value": "MEMBERSHIP"'
      } else if (this.formGroup.value.typeTransaction === 'FUNDO') {
        this.typeTransaction = '"fields":["transactions.fund_id"], "op": "<>", "value": "null"'
      } else if (this.formGroup.value.typeTransaction === 'CARTÃO FÍSICO') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "=", "value": "CARD_MACHINE"'
      } else if (this.formGroup.value.typeTransaction === 'APP') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "<>", "value": "CARD_MACHINE"}, {"fields":["transactions.type"], "op": "<>", "value": "INVOICE"'
      } else if (this.formGroup.value.typeTransaction === 'TRANSFERÊNCIA C2C') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "=", "value": "C2C"'
      } else if (this.formGroup.value.typeTransaction === 'NOTAS FISCAIS') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "=", "value": "INVOICE"'
      } else if (this.formGroup.value.typeTransaction === 'LIVELO') {
        this.typeTransaction = '"fields":["transactions.type"], "op": "=", "value": "LIVELO"'
      }
    } else {
      this.typeTransaction = undefined
    }

    if (this.formGroup.value.transactionsTest) {
      if (this.formGroup.value.transactionsTest === 'true') {
        this.transactionsTest = '"fields":["transactionsTest"], "op": "=", "value": "true"'
      } else if (this.formGroup.value.transactionsTest === 'false') {
        this.transactionsTest = '"fields":["transactionsTest"], "op": "=", "value": "false"'
      }
    }

    this.formGroup.value.titleNotification
      ? (this.titleNotification = `"fields":["title"], "op": "like", "value":"${this.formGroup.value.titleNotification}"`)
      : (this.titleNotification = undefined)
    this.formGroup.value.descriptionNotification
      ? (this.descriptionNotification = `"fields":["description"], "op": "like", "value":"${this.formGroup.value.descriptionNotification}"`)
      : (this.descriptionNotification = undefined)
    this.formGroup.value.dateNotification
      ? (this.dateNotification = `"fields":["created_at"], "op": "like", "value":"${this.formGroup.value.dateNotification}"`)
      : (this.dateNotification = undefined)
    this.formGroup.value.nameBusinessPartners
      ? (this.nameBusinessPartners = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameBusinessPartners}"`)
      : (this.nameBusinessPartners = undefined)
    this.formGroup.value.activeBusinessPartners
      ? (this.activeBusinessPartners = `"fields":["is_active"], "op": "=", "value":"${this.formGroup.value.activeBusinessPartners}"`)
      : (this.activeBusinessPartners = undefined)

    this.formGroup.value.nameQuiz
      ? (this.nameQuiz = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameQuiz}"`)
      : (this.nameQuiz = undefined)

    this.formGroup.value.nameQuestion
      ? (this.nameQuestion = `"fields":["ask"], "op": "like", "value":"${this.formGroup.value.nameQuestion}"`)
      : (this.nameQuestion = undefined)

    this.formGroup.value.nameQuizCustomer
      ? (this.nameQuizCustomer = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameQuizCustomer}"`)
      : (this.nameQuizCustomer = undefined)

    this.formGroup.value.nameQuizClient
      ? (this.nameQuizClient = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameQuizClient}"`)
      : (this.nameQuizClient = undefined)
    this.formGroup.value.cpfQuizClient
      ? (this.cpfQuizClient = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfQuizClient}"`)
      : (this.cpfQuizClient = undefined)

    this.formGroup.value.nameSegmentation
      ? (this.nameSegmentation = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameSegmentation}"`)
      : (this.nameSegmentation = undefined)
    this.formGroup.value.cpfSegmentationResults
      ? (this.cpfSegmentationResults = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfSegmentationResults}"`)
      : (this.cpfSegmentationResults = undefined)
    this.formGroup.value.nameSegmentationResults
      ? (this.nameSegmentationResults = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameSegmentationResults}"`)
      : (this.nameSegmentationResults = undefined)

    this.formGroup.value.cnpjFund
      ? (this.cnpjFund = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cnpjFund}"`)
      : (this.cnpjFund = undefined)
    this.formGroup.value.nameFund
      ? (this.nameFund = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameFund}"`)
      : (this.nameFund = undefined)
    this.formGroup.value.activeFund
      ? (this.activeFund = `"fields":["active"], "op": "=", "value":"${this.formGroup.value.activeFund}"`)
      : (this.activeFund = undefined)

    this.formGroup.value.numberFundTransaction
      ? (this.numberFundTransaction = `"fields":["order_number"], "op": "like", "value":"${this.formGroup.value.numberFundTransaction}"`)
      : (this.numberFundTransaction = undefined)
    this.formGroup.value.cpfFundTransaction
      ? (this.cpfFundTransaction = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfFundTransaction}"`)
      : (this.cpfFundTransaction = undefined)
    this.formGroup.value.dateFundTransaction
      ? (this.dateFundTransaction = `"fields":["transactions.created_at"], "op": "like", "value":"${this.formGroup.value.dateFundTransaction}"`)
      : (this.dateFundTransaction = undefined)
    this.formGroup.value.statusFundTransaction
      ? (this.statusFundTransaction = `"fields":["transactions.state"], "op": "=", "value":"${this.formGroup.value.statusFundTransaction}"`)
      : (this.statusFundTransaction = undefined)

    if (this.formGroup.value.nameFundExtract) {
      this.nameFundExtract = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameFundExtract}"`
    } else {
      this.nameFundExtract = undefined
    }
    this.formGroup.value.dateFundExtract
      ? (this.dateFundExtract = `"fields":["transaction.createdAt"], "op": "like", "value":"${this.formGroup.value.dateFundExtract}"`)
      : (this.dateFundExtract = undefined)

    if (this.formGroup.value.nameFundCustomersAssociates) {
      this.nameFundCustomersAssociates = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameFundCustomersAssociates}"`
    } else {
      this.nameFundCustomersAssociates = undefined
    }
    this.formGroup.value.cpfFundCustomersAssociates
      ? (this.cpfFundCustomersAssociates = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfFundCustomersAssociates}"`)
      : (this.cpfFundCustomersAssociates = undefined)

    this.formGroup.value.cpfNotificationCustomers
      ? (this.cpfNotificationCustomers = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfNotificationCustomers}"`)
      : (this.cpfNotificationCustomers = undefined)

    this.formGroup.value.nameNotificationCustomers
      ? (this.nameNotificationCustomers = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.nameNotificationCustomers}"`)
      : (this.nameNotificationCustomers = undefined)

    this.formGroup.value.cpfPlanMembership
      ? (this.cpfPlanMembership = `"fields":["c.cpf"], "op": "like", "value":"${this.formGroup.value.cpfPlanMembership}"`)
      : (this.cpfPlanMembership = undefined)

    this.formGroup.value.fullNamePlanMembership
      ? (this.fullNamePlanMembership = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.fullNamePlanMembership}"`)
      : (this.fullNamePlanMembership = undefined)

    this.formGroup.value.dateLastPaymentPlanMembership
      ? (this.dateLastPaymentPlanMembership = `"fields":["mp.begin_date"], "op": "like", "value":"${this.formGroup.value.dateLastPaymentPlanMembership}"`)
      : (this.dateLastPaymentPlanMembership = undefined)

    this.formGroup.value.numberTransactionPlanMembership
      ? (this.numberTransactionPlanMembership = `"fields":["transactions.order_number"], "op": "like", "value":"${this.formGroup.value.numberTransactionPlanMembership}"`)
      : (this.numberTransactionPlanMembership = undefined)

    this.formGroup.value.dateTransactionPlanMembership
      ? (this.dateTransactionPlanMembership = `"fields":["transactions.created_at"], "op": "like", "value":"${this.formGroup.value.dateTransactionPlanMembership}"`)
      : (this.dateTransactionPlanMembership = undefined)

    this.formGroup.value.statusPlanMembership
      ? (this.statusPlanMembership = `"fields":["transactions.state"], "op": "like", "value":"${this.formGroup.value.statusPlanMembership}"`)
      : (this.statusPlanMembership = undefined)

    this.formGroup.value.namePlanMembership
      ? (this.namePlanMembership = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.namePlanMembership}"`)
      : (this.namePlanMembership = undefined)

    this.formGroup.value.beginDatePlanMembership
      ? (this.beginDatePlanMembership = `"fields":["beginDate"], "op": "like", "value":"${this.formGroup.value.beginDatePlanMembership}"`)
      : (this.beginDatePlanMembership = undefined)
    this.formGroup.value.endDatePlanMembership
      ? (this.endDatePlanMembership = `"fields":["endDate"], "op": "like", "value":"${this.formGroup.value.endDatePlanMembership}"`)
      : (this.endDatePlanMembership = undefined)

    if (this.formGroup.value.activePlanMembership === 'INATIVO') {
      this.activePlanMembership = '"fields":["isActive"], "op": "=", "value":"0"'
    } else if (this.formGroup.value.activePlanMembership === 'TODOS') {
      this.activePlanMembership = '"fields":["isActive"], "op": ">=", "value":"0"'
    } else if (this.formGroup.value.activePlanMembership === 'ATIVO') {
      this.activePlanMembership = '"fields":["isActive"], "op": "=", "value":"1"'
    } else {
      this.activePlanMembership = undefined
    }

    if (this.formGroup.value.typePlanMembership === 'pj') {
      this.typePlanMembership = '"fields":["isPj"], "op": "=", "value":"1"'
    }

    if (this.formGroup.value.typePlanMembership === 'recurrent') {
      this.typePlanMembership = '"fields":["isPj"], "op": "=", "value":"0"'
    }

    if (this.formGroup.value.typePlanMembership === 'all') {
      this.typePlanMembership = undefined
    }

    this.formGroup.value.namePlanMembershipCustomer
      ? (this.namePlanMembershipCustomer = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.namePlanMembershipCustomer}"`)
      : (this.namePlanMembershipCustomer = undefined)

    this.formGroup.value.beginDatePlanMembershipCustomer
      ? (this.beginDatePlanMembershipCustomer = `"fields":["customers_memberships.beginDate"], "op": "like", "value":"${this.formGroup.value.beginDatePlanMembershipCustomer}"`)
      : (this.beginDatePlanMembershipCustomer = undefined)

    this.formGroup.value.endDatePlanMembershipCustomer
      ? (this.endDatePlanMembershipCustomer = `"fields":["customers_memberships.endDate"], "op": "like", "value":"${this.formGroup.value.endDatePlanMembershipCustomer}"`)
      : (this.endDatePlanMembershipCustomer = undefined)

    if (
      this.formGroup.value.baginDateMovementJcoins &&
      this.formGroup.value.endDateMovementJcoins
    ) {
      this.dateMovementJcoins = `"fields":["date"], "op": "between", "value":"'${this.formGroup.value.baginDateMovementJcoins}' AND '${this.formGroup.value.endDateMovementJcoins}'"`
    } else if (this.formGroup.value.baginDateMovementJcoins) {
      this.dateMovementJcoins = `"fields":["we.created_at"], "op": ">=", "value":"${this.formGroup.value.baginDateMovementJcoins}"`
    } else if (this.formGroup.value.endDateMovementJcoins) {
      this.dateMovementJcoins = `"fields":["we.created_at"], "op": "<=", "value":"${this.formGroup.value.endDateMovementJcoins}"`
    } else {
      this.dateMovementJcoins = undefined
    }

    if (this.typePreRegistration === 'architects') {
      this.formGroup.value.cpfPreRegistration
        ? (this.cpfPreRegistration = `"fields":["preRegistration.cpf"], "op": "like", "value":"${this.formGroup.value.cpfPreRegistration}"`)
        : (this.cpfPreRegistration = undefined)
      this.formGroup.value.namePreRegistration
        ? (this.namePreRegistration = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.namePreRegistration}"`)
        : (this.namePreRegistration = undefined)
    } else if (!this.typePreRegistration || this.typePreRegistration === 'INCORPORATION_AIRPORT') {
      this.formGroup.value.cpfPreRegistration
        ? (this.cpfPreRegistration = `"fields":["cpf"], "op": "like", "value":"${this.formGroup.value.cpfPreRegistration}"`)
        : (this.cpfPreRegistration = undefined)
      this.formGroup.value.namePreRegistration
        ? (this.namePreRegistration = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.namePreRegistration}"`)
        : (this.namePreRegistration = undefined)
    }

    this.formGroup.value.categoriesBusinessPartners
      ? (this.categoriesBusinessPartners = `"fields":["categoryJoin.id"], "op": "=", "value":"${this.formGroup.value.categoriesBusinessPartners}"`)
      : (this.categoriesBusinessPartners = undefined)

    this.formGroup.value.typeNotification
      ? (this.typeNotification = `"fields":["type"], "op": "=", "value":"${this.formGroup.value.typeNotification}"`)
      : (this.typeNotification = undefined)

    this.formGroup.value.fullNameCustomerBeneficiary
      ? (this.fullNameCustomerBeneficiary = `"fields":["fullName"], "op": "like", "value":"${this.formGroup.value.fullNameCustomerBeneficiary}"`)
      : (this.fullNameCustomerBeneficiary = undefined)

    this.formGroup.value.cpfCustomerBeneficiary
      ? (this.cpfCustomerBeneficiary = `"fields":["customers.cpf"], "op": "like", "value":"${this.formGroup.value.cpfCustomerBeneficiary}"`)
      : (this.cpfCustomerBeneficiary = undefined)

    this.formGroup.value.cpfBeneficiary
      ? (this.cpfBeneficiary = `"fields":["beneficiaryCpf"], "op": "like", "value":"${this.formGroup.value.cpfBeneficiary}"`)
      : (this.cpfBeneficiary = undefined)

    this.formGroup.value.fullNameBeneficiary
      ? (this.fullNameBeneficiary = `"fields":["beneficiaryFullName"], "op": "like", "value":"${this.formGroup.value.fullNameBeneficiary}"`)
      : (this.fullNameBeneficiary = undefined)

    this.formGroup.value.nameIntegration
      ? (this.nameIntegration = `"fields":["name"], "op": "like", "value":"${this.formGroup.value.nameIntegration}"`)
      : (this.nameIntegration = undefined)

    this.formGroup.value.cpfIntegration
      ? (this.cpfIntegration = `"fields":["incorporation_integration.cpf"], "op": "like", "value":"${this.formGroup.value.cpfIntegration}"`)
      : (this.cpfIntegration = undefined)

    // this.formGroup.value.dateInitIntegration
    //   ? (this.dateInitIntegration = `"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${this.formGroup.value.dateInitIntegration}"`)
    //   : (this.dateInitIntegration = undefined)
    // this.formGroup.value.dateEndIntegration
    //   ? (this.dateEndIntegration = `"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateEndIntegration}"`)
    //   : (this.dateEndIntegration = undefined)

    if (
      this.formGroup.value.dateInitIntegration &&
      this.formGroup.value.dateEndIntegration
    ) {
      this.dateInitIntegration = `"fields":["date"], "op": "between", "value":"'${this.formGroup.value.dateInitIntegration} 00:00:00' AND '${this.formGroup.value.dateEndIntegration} 23:59:59'"`
    } else if (
      this.formGroup.value.dateInitIntegration &&
      !this.formGroup.value.dateEndIntegration
    ) {
      this.dateInitIntegration = `"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${this.formGroup.value.dateInitIntegration}"`
    } else if (
      this.formGroup.value.dateEndIntegration &&
      !this.formGroup.value.dateInitIntegration
    ) {
      this.dateInitIntegration = `"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateEndIntegration}"`
    } else {
      this.dateInitIntegration = undefined
    }

    this.formGroup.value.percentageIntegration
      ? (this.percentageIntegration = `"fields":["taxJcoinsIntegration"], "op": "like", "value":"${this.formGroup.value.percentageIntegration}"`)
      : (this.percentageIntegration = undefined)

    // this.formGroup.value.nameAirportCompany &&
    //   this.formGroup.value.cnpjAirportCompany &&
    //   this.formGroup.value.isCpfAirportCompany
    //   ? (this.cnpjAirportCompanyAndNameAirportCompany = `?cnpj=${this.formGroup.value.cnpjAirportCompany}&name=${this.formGroup.value.nameAirportCompany}&isCpf=${this.formGroup.value.nameAirportCompany}`)
    //   : (this.cnpjAirportCompanyAndNameAirportCompany = undefined)

    // !this.formGroup.value.nameAirportCompany &&
    //   this.formGroup.value.cnpjAirportCompany &&
    //   this.formGroup.value.isCpfAirportCompany
    //   ? (this.cnpjAirportCompanyAndNameAirportCompany = `?cnpj=${this.formGroup.value.cnpjAirportCompany}&isCpf=${this.formGroup.value.nameAirportCompany}`)
    //   : (this.cnpjAirportCompanyAndNameAirportCompany = undefined)

    // this.formGroup.value.nameAirportCompany &&
    //   !this.formGroup.value.cnpjAirportCompany &&
    //   this.formGroup.value.isCpfAirportCompany
    //   ? (this.cnpjAirportCompanyAndNameAirportCompany = `?name=${this.formGroup.value.nameAirportCompany}&isCpf=${this.formGroup.value.nameAirportCompany}`)
    //   : (this.cnpjAirportCompanyAndNameAirportCompany = undefined)

    // this.formGroup.value.nameAirportCompany &&
    //   this.formGroup.value.cnpjAirportCompany &&
    //   !this.formGroup.value.isCpfAirportCompany
    //   ? (this.cnpjAirportCompanyAndNameAirportCompany = `?name=${this.formGroup.value.nameAirportCompany}`)
    //   : (this.cnpjAirportCompanyAndNameAirportCompany = undefined)

    let query = '?';

    const { nameAirportCompany, cnpjAirportCompany, cpfAirportCompany, isCpfAirportCompany } = this.formGroup.value;
    
    if (nameAirportCompany) {
      query += `name=${nameAirportCompany}&`;
    }
    
    if (cnpjAirportCompany) {
      query += `cnpj=${cnpjAirportCompany}&`;
    }
    console.log(cpfAirportCompany)
    if (cpfAirportCompany) {
      query += `cpf=${cpfAirportCompany}&`;
    }
    
    if (isCpfAirportCompany) {
      query += `isCpf=${isCpfAirportCompany}&`;
    }
    
    query = query.endsWith('&') ? query.slice(0, -1) : query;
    this.cnpjAirportCompanyAndNameAirportCompany = query.length > 1 ? query : undefined;

    this.formGroup.value.nameAirportCompany &&
      !this.formGroup.value.cnpjAirportCompany &&
      !this.cnpjAirportCompanyAndNameAirportCompany &&
      !this.formGroup.value.isCpfAirportCompany
      ? (this.nameAirportCompany = `?name=${this.formGroup.value.nameAirportCompany}`)
      : (this.nameAirportCompany = undefined)

    this.formGroup.value.cnpjAirportCompany &&
      !this.formGroup.value.nameAirportCompany &&
      !this.formGroup.value.isCpfAirportCompany &&
      !this.cnpjAirportCompanyAndNameAirportCompany
      ? (this.cnpjAirportCompany = `?cnpj=${this.formGroup.value.cnpjAirportCompany}`)
      : (this.cnpjAirportCompany = undefined)

    this.formGroup.value.isCpfAirportCompany &&
      !this.formGroup.value.nameAirportCompany &&
      !this.formGroup.value.cnpjAirportCompany &&
      !this.cnpjAirportCompanyAndNameAirportCompany
      ? (this.isCpfAirportCompany = `?isCpf=${this.formGroup.value.isCpfAirportCompany}`)
      : (this.isCpfAirportCompany = undefined)

    this.formGroup.value.cnpjArchitectCompany
      ? (this.cnpjArchitectCompany = `&cnpj=${this.formGroup.value.cnpjArchitectCompany}`)
      : (this.cnpjArchitectCompany = undefined)

    this.formGroup.value.billPaymentCpf
      ? (this.billPaymentCpf = `&cpf=${this.formGroup.value.billPaymentCpf}`)
      : (this.billPaymentCpf = undefined)

    this.formGroup.value.billPaymentMonth
      ? (this.billPaymentMonth = `&month=${this.formGroup.value.billPaymentMonth}`)
      : (this.billPaymentMonth = undefined)

    this.formGroup.value.billPaymentYear
      ? (this.billPaymentYear = `&year=${this.formGroup.value.billPaymentYear}`)
      : (this.billPaymentYear = undefined)

    this.formGroup.value.manualJcoinCreditStatus
      ? (this.manualJcoinCreditStatus = `&status=${this.formGroup.value.manualJcoinCreditStatus}`)
      : (this.manualJcoinCreditStatus = undefined);

    this.formGroup.value.manualJcoinCreditCreatedAtFrom
      ? (this.manualJcoinCreditCreatedAtFrom = `&startDate=${this.formGroup.value.manualJcoinCreditCreatedAtFrom}`)
      : (this.manualJcoinCreditCreatedAtFrom = undefined);

    this.formGroup.value.manualJcoinCreditCreatedAtTo
      ? (this.manualJcoinCreditCreatedAtTo = `&endDate=${this.formGroup.value.manualJcoinCreditCreatedAtTo}`)
      : (this.manualJcoinCreditCreatedAtTo = undefined);

    this.formGroup.value.isCpfArchitectCompany === 'true'
      ? (this.isCpfArchitectCompany = '&haveCpf=true')
      : this.formGroup.value.isCpfArchitectCompany === 'false'
        ? this.isCpfArchitectCompany = '&haveCpf=false'
        : (this.isCpfArchitectCompany = undefined)

    // if (this.formGroup.value.isCpfArchitectCompany === 'false') {
    //   this.isCpfArchitectCompany = '&haveCpf=false'
    // } else if (this.formGroup.value.isCpfArchitectCompany === undefined) {
    //   this.isCpfArchitectCompany = undefined
    // }

    this.formGroup.value.cnpjArchitectStores
      ? (this.cnpjArchitectStores = `&cnpj=${this.formGroup.value.cnpjArchitectStores}`)
      : (this.cnpjArchitectStores = undefined)

    this.formGroup.value.nomeFantasiaArchitectStores
      ? (this.nomeFantasiaArchitectStores = `&nomeFantasia=${this.formGroup.value.nomeFantasiaArchitectStores}`)
      : (this.nomeFantasiaArchitectStores = undefined)

    this.formGroup.value.fullNameUserArchitectStores
      ? (this.fullNameUserArchitectStores = `&fullName=${this.formGroup.value.fullNameUserArchitectStores}`)
      : (this.fullNameUserArchitectStores = undefined)

    this.formGroup.value.emailUserArchitectStores
      ? (this.emailUserArchitectStores = `&emailAddress=${this.formGroup.value.emailUserArchitectStores}`)
      : (this.emailUserArchitectStores = undefined)

    this.formGroup.value.architectInvoiceState
      ? (this.architectInvoiceState = `&status=${this.formGroup.value.architectInvoiceState}`)
      : (this.architectInvoiceState = undefined)

    this.formGroup.value.architectInvoiceEndDate
      ? (this.architectInvoiceEndDate = `&endDate=${this.formGroup.value.architectInvoiceEndDate}`)
      : (this.architectInvoiceEndDate = undefined)

    this.formGroup.value.architectInvoiceStartDate
      ? (this.architectInvoiceStartDate = `&startDate=${this.formGroup.value.architectInvoiceStartDate}`)
      : (this.architectInvoiceStartDate = undefined)

    this.formGroup.value.architectInvoiceStoreCnpj
      ? (this.architectInvoiceStoreCnpj = `&cnpj=${this.formGroup.value.architectInvoiceStoreCnpj}`)
      : (this.architectInvoiceStoreCnpj = undefined)

    if (this.formGroup.value.architectInvoiceTargetDocument) {
      this.formGroup.value.architectInvoiceTargetDocument =
        this.formGroup.value.architectInvoiceTargetDocument.replace(/[^\d]/g, '')
      this.architectInvoiceTargetDocument =
        `&targetDocument=${this.formGroup.value.architectInvoiceTargetDocument}`
    } else {
      this.architectInvoiceTargetDocument = undefined
    }

    this.formGroup.value.architectInvoiceStoreName
      ? (this.architectInvoiceStoreName = `&name=${this.formGroup.value.architectInvoiceStoreName}`)
      : (this.architectInvoiceStoreName = undefined)

    if (this.formGroup.value.reportFundMonth && this.formGroup.value.reportFundYear) {
      this.reportFundDate = `?date=${this.formGroup.value.reportFundMonth}-${this.formGroup.value.reportFundYear}`
      this.reportFundDateReturn = `?month=${this.formGroup.value.reportFundMonth}&year=${this.formGroup.value.reportFundYear}`
    } else {
      this.reportFundDate = undefined
      this.reportFundDateReturn = undefined
    }

    if (this.formGroup.value.reportStockClosingMonth &&
      this.formGroup.value.reportStockClosingYear) {
      this.reportStockClosingDate = `?date=${this.formGroup.value.reportStockClosingMonth}-${this.formGroup.value.reportStockClosingYear}`
      this.reportStockClosingDateReturn = `?month=${this.formGroup.value.reportStockClosingMonth}&year=${this.formGroup.value.reportStockClosingYear}`
    } else {
      this.reportStockClosingDate = undefined
      this.reportStockClosingDateReturn = undefined
    }

    if (this.formGroup.value.reportConsolidatedMonth &&
      this.formGroup.value.reportConsolidatedYear) {
      this.reportConsolidatedDate = `?date=${this.formGroup.value.reportConsolidatedMonth}-${this.formGroup.value.reportConsolidatedYear}`
      this.reportConsolidatedDateReturn = `?month=${this.formGroup.value.reportConsolidatedMonth}&year=${this.formGroup.value.reportConsolidatedYear}`
    } else {
      this.reportConsolidatedDate = undefined
      this.reportConsolidatedDateReturn = undefined
    }

    this.formGroup.value.cnpjTransferScheduleList
      ? (this.cnpjTransferScheduleList = `&cnpj=${this.formGroup.value.cnpjTransferScheduleList}`)
      : (this.cnpjTransferScheduleList = undefined)
    this.formGroup.value.startDueDateTransferScheduleList
      ? (this.startDueDateTransferScheduleList = `&startDueDate=${this.formGroup.value.startDueDateTransferScheduleList}`)
      : (this.startDueDateTransferScheduleList = undefined)
    this.formGroup.value.endDueDateTransferScheduleList
      ? (this.endDueDateTransferScheduleList = `&endDueDate=${this.formGroup.value.endDueDateTransferScheduleList}`)
      : (this.endDueDateTransferScheduleList = undefined)
    this.formGroup.value.reasonTransferScheduleList
      ? (this.reasonTransferScheduleList = `&reason=${this.formGroup.value.reasonTransferScheduleList}`)
      : (this.reasonTransferScheduleList = undefined)
      this.formGroup.value.approvalStatusTransferScheduleList
      ? (this.approvalStatusTransferScheduleList = `&status=${this.formGroup.value.approvalStatusTransferScheduleList}`)
      : (this.approvalStatusTransferScheduleList = undefined)

    if (this.formGroup.value.startPaidAtDateTransferScheduleList &&
      this.formGroup.value.endPaidAtDateTransferScheduleList) {
      this.dateTransferScheduleList =
        `&startPaidAt=${this.formGroup.value.startPaidAtDateTransferScheduleList}&endPaidAt=${this.formGroup.value.endPaidAtDateTransferScheduleList}`
    } else if (this.formGroup.value.startPaidAtDateTransferScheduleList &&
      !this.formGroup.value.endPaidAtDateTransferScheduleList) {
      this.dateTransferScheduleList =
        `&startPaidAt=${this.formGroup.value.startPaidAtDateTransferScheduleList}`
    } else if (!this.formGroup.value.startPaidAtDateTransferScheduleList &&
      this.formGroup.value.endPaidAtDateTransferScheduleList) {
      this.dateTransferScheduleList = `&endPaidAt=${this.formGroup.value.endPaidAtDateTransferScheduleList}`
    } else {
      this.dateTransferScheduleList = undefined
    }

    if (
      this.formGroup.value.dateAirportInitIntegration &&
      this.formGroup.value.dateAirportEndIntegration
    ) {
      this.dateAirportInitIntegration = `"fields":["airports_integration.createdAt"], "op": ">=", "value": "${this.formGroup.value.dateAirportInitIntegration} 00:00:00"}, {"fields":["airports_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateAirportEndIntegration} 23:59:59"`
    } else if (
      this.formGroup.value.dateAirportInitIntegration &&
      !this.formGroup.value.dateAirportEndIntegration
    ) {
      this.dateAirportInitIntegration = `"fields":["airports_integration.createdAt"], "op": ">=", "value":"${this.formGroup.value.dateAirportInitIntegration} 00:00:00"`
    } else if (
      this.formGroup.value.dateAirportEndIntegration &&
      !this.formGroup.value.dateAirportInitIntegration
    ) {
      this.dateAirportInitIntegration = `"fields":["airports_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateAirportEndIntegration} 23:59:59"`
    } else {
      this.dateAirportInitIntegration = undefined
    }

    if (this.formGroup.value.documentAirportIntegration) {
      // if (this.formGroup.value.documentAirportIntegration.length === 14) {
      this.formGroup.value.documentAirportIntegration = this.formGroup.value.documentAirportIntegration.replace(/[^\d]/g, '')
      // }
      this.documentAirportIntegration = `"fields":["cnpjCpf"], "op": "like", "value":"${this.formGroup.value.documentAirportIntegration}"`
    } else {
      this.documentAirportIntegration = undefined
    }

    if (this.formGroup.value.cnpjOriginAirportIntegration) {
      this.formGroup.value.cnpjOriginAirportIntegration = this.formGroup.value.cnpjOriginAirportIntegration.replace(/[^\d]/g, '')
      this.cnpjOriginAirportIntegration = `"fields":["cnpjOrigem"], "op": "like", "value":"${this.formGroup.value.cnpjOriginAirportIntegration}"`
    } else {
      this.cnpjOriginAirportIntegration = undefined
    }

    if (
      this.formGroup.value.dateAirportInitIntegrationNotEligible &&
      this.formGroup.value.dateAirportEndIntegrationNotEligible
    ) {
      this.dateAirportInitIntegrationNotEligible = `"fields":["airports_integration.createdAt"], "op": ">=", "value": "${this.formGroup.value.dateAirportInitIntegrationNotEligible} 00:00:00"}, {"fields":["airports_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateAirportEndIntegrationNotEligible} 23:59:59"`
    } else if (
      this.formGroup.value.dateAirportInitIntegrationNotEligible &&
      !this.formGroup.value.dateAirportEndIntegrationNotEligible
    ) {
      this.dateAirportInitIntegrationNotEligible = `"fields":["airports_integration.createdAt"], "op": ">=", "value":"${this.formGroup.value.dateAirportInitIntegrationNotEligible} 00:00:00"`
    } else if (
      this.formGroup.value.dateEndIntegrationNotEligible &&
      !this.formGroup.value.dateAirportEndIntegrationNotEligible
    ) {
      this.dateAirportInitIntegrationNotEligible = `"fields":["airports_integration.createdAt"], "op": "<=", "value":"${this.formGroup.value.dateAirportEndIntegrationNotEligible} 23:59:59"`
    } else {
      this.dateAirportInitIntegrationNotEligible = undefined
    }

    if (this.formGroup.value.documentAirportIntegrationNotEligible) {
      this.formGroup.value.documentAirportIntegrationNotEligible = this.formGroup.value.documentAirportIntegrationNotEligible.replace(/[^\d]/g, '')
      this.documentAirportIntegrationNotEligible = `"fields":["cnpjCpf"], "op": "like", "value":"${this.formGroup.value.documentAirportIntegrationNotEligible}"`
    } else {
      this.documentAirportIntegrationNotEligible = undefined
    }

    if (this.formGroup.value.cnpjOriginAirportIntegrationNotEligible) {
      this.formGroup.value.cnpjOriginAirportIntegrationNotEligible = this.formGroup.value.cnpjOriginAirportIntegrationNotEligible.replace(/[^\d]/g, '')
      this.cnpjOriginAirportIntegrationNotEligible = `"fields":["cnpjOrigem"], "op": "like", "value":"${this.formGroup.value.cnpjOriginAirportIntegrationNotEligible}"`
    } else {
      this.cnpjOriginAirportIntegrationNotEligible = undefined
    }

    if (this.formGroup.value.prefixAirportIntegrationNotEligible) {
      this.formGroup.value.prefixAirportIntegrationNotEligible =
        this.formGroup.value.prefixAirportIntegrationNotEligible
      this.prefixAirportIntegrationNotEligible = `"fields":["preAircraft"], "op": "like", "value":"${this.formGroup.value.prefixAirportIntegrationNotEligible}"`
    } else {
      this.prefixAirportIntegrationNotEligible = undefined
    }

    if (this.formGroup.value.prefixAirportIntegration) {
      // if (this.formGroup.value.documentAirportIntegration.length === 14) {
      // }
      this.prefixAirportIntegration = `"fields":["preAircraft"], "op": "like", "value":"${this.formGroup.value.prefixAirportIntegration}"`
    } else {
      this.prefixAirportIntegration = undefined
    }

    if (
      !this.formGroup.value.campaignType &&
      !this.formGroup.value.idCampaign &&
      !this.formGroup.value.numberTransaction &&
      !this.formGroup.value.nomeFantasiaTransaction &&
      !this.formGroup.value.cpfTransaction &&
      !this.formGroup.value.dateTransaction &&
      !this.formGroup.value.status &&
      !this.formGroup.value.cnpjStore &&
      !this.formGroup.value.nomeFantasiaStore &&
      !this.formGroup.value.nameClient &&
      !this.formGroup.value.cpfClient &&
      !this.formGroup.value.phoneClient &&
      !this.formGroup.value.emailClient &&
      !this.formGroup.value.typeRegister &&
      !this.formGroup.value.nameStoreUser &&
      !this.formGroup.value.phoneStoreUser &&
      !this.formGroup.value.emailStoreUser &&
      !this.formGroup.value.numberTransactionPending &&
      !this.formGroup.value.cpfTransactionPending &&
      !this.formGroup.value.dateTransactionPending &&
      !this.formGroup.value.typeTransaction &&
      !this.formGroup.value.transactionsTest &&
      !this.formGroup.value.titleNotification &&
      !this.formGroup.value.descriptionNotification &&
      !this.formGroup.value.dateNotification &&
      !this.formGroup.value.nameSegmentation &&
      !this.formGroup.value.cpfSegmentationResults &&
      !this.formGroup.value.nameSegmentationResults &&
      !this.formGroup.value.nameBusinessPartners &&
      !this.formGroup.value.activeBusinessPartners &&
      !this.formGroup.value.nameQuiz &&
      !this.formGroup.value.nameQuestion &&
      !this.formGroup.value.nameQuizCustomer &&
      !this.formGroup.value.nameQuizClient &&
      !this.formGroup.value.cpfQuizClient &&
      !this.formGroup.value.cnpjFund &&
      !this.formGroup.value.nameFund &&
      !this.formGroup.value.activeFund &&
      !this.formGroup.value.numberFundTransaction &&
      !this.formGroup.value.cpfFundTransaction &&
      !this.formGroup.value.dateFundTransaction &&
      !this.formGroup.value.statusFundTransaction &&
      !this.formGroup.value.nameFundExtract &&
      !this.formGroup.value.dateFundExtract &&
      !this.formGroup.value.nameFundCustomersAssociates &&
      !this.formGroup.value.cpfFundCustomersAssociates &&
      !this.formGroup.value.cpfNotificationCustomers &&
      !this.formGroup.value.nameNotificationCustomers &&
      !this.formGroup.value.cpfPlanMembership &&
      !this.formGroup.value.fullNamePlanMembership &&
      !this.formGroup.value.dateLastPaymentPlanMembership &&
      !this.formGroup.value.numberTransactionPlanMembership &&
      !this.formGroup.value.dateTransactionPlanMembership &&
      !this.formGroup.value.statusPlanMembership &&
      !this.formGroup.value.namePlanMembership &&
      !this.formGroup.value.beginDatePlanMembership &&
      !this.formGroup.value.endDatePlanMembership &&
      !this.formGroup.value.activePlanMembership &&
      !this.formGroup.value.namePlanMembershipCustomer &&
      !this.formGroup.value.beginDatePlanMembershipCustomer &&
      !this.formGroup.value.endDatePlanMembershipCustomer &&
      !this.formGroup.value.baginDateMovementJcoins &&
      !this.formGroup.value.endDateMovementJcoins &&
      !this.typePlanMembership &&
      !this.formGroup.value.namePreRegistration &&
      !this.formGroup.value.cpfPreRegistration &&
      !this.formGroup.value.categoriesBusinessPartners &&
      !this.formGroup.value.typeNotification &&
      !this.formGroup.value.fullNameCustomerBeneficiary &&
      !this.formGroup.value.cpfCustomerBeneficiary &&
      !this.formGroup.value.cpfBeneficiary &&
      !this.formGroup.value.fullNameBeneficiary &&
      !this.formGroup.value.nameIntegration &&
      !this.formGroup.value.cpfIntegration &&
      !this.formGroup.value.dateInitIntegration &&
      !this.formGroup.value.dateEndIntegration &&
      !this.formGroup.value.percentageIntegration &&
      !this.formGroup.value.nameAirportCompany &&
      !this.formGroup.value.cnpjAirportCompany &&
      !this.formGroup.value.cpfAirportCompany &&
      !this.formGroup.value.cnpjAirportCompanyAndNameAirportCompany &&
      !this.formGroup.value.isCpfAirportCompany &&
      !this.formGroup.value.cnpjArchitectCompany &&
      !this.formGroup.value.billPaymentCpf &&
      !this.formGroup.value.billPaymentMonth &&
      !this.formGroup.value.billPaymentYear &&
      !this.formGroup.value.manualJcoinCreditStatus &&
      !this.formGroup.value.manualJcoinCreditCreatedAtFrom &&
      !this.formGroup.value.manualJcoinCreditCreatedAtTo &&
      !this.formGroup.value.isCpfArchitectCompany &&
      !this.formGroup.value.cnpjArchitectStores &&
      !this.formGroup.value.nomeFantasiaArchitectStores &&
      !this.formGroup.value.fullNameUserArchitectStores &&
      !this.formGroup.value.emailUserArchitectStores &&
      !this.formGroup.value.architectInvoiceState &&
      !this.formGroup.value.architectInvoiceEndDate &&
      !this.formGroup.value.architectInvoiceStartDate &&
      !this.formGroup.value.architectInvoiceStoreCnpj &&
      !this.formGroup.value.architectInvoiceTargetDocument &&
      !this.formGroup.value.architectInvoiceStoreName &&
      !this.reportFundDate &&
      !this.reportStockClosingDate &&
      !this.reportConsolidatedDate &&
      !this.cnpjTransferScheduleList &&
      !this.startDueDateTransferScheduleList &&
      !this.endDueDateTransferScheduleList &&
      !this.reasonTransferScheduleList &&
      !this.approvalStatusTransferScheduleList &&
      !this.dateTransferScheduleList &&
      !this.documentAirportIntegration &&
      !this.cnpjOriginAirportIntegration &&
      !this.dateAirportInitIntegration &&
      !this.dateAirportEndIntegration &&
      !this.dateAirportIntegration &&
      !this.prefixAirportIntegration &&
      !this.documentAirportIntegrationNotEligible &&
      !this.cnpjOriginAirportIntegrationNotEligible &&
      !this.prefixAirportIntegrationNotEligible &&
      !this.dateAirportInitIntegrationNotEligible &&
      !this.dateAirportEndIntegrationNotEligible &&
      !this.dateAirportIntegrationNotEligible
    ) {
      switch (this.type) {
        case 'campaign':
          localStorage.removeItem('filter')
          this.listCampaigns(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'store':
          localStorage.removeItem('filter')
          this.listStores(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'transaction':
          localStorage.removeItem('filter')
          this.storeID
            ? this.listTransactions(
              `?filters={"page": ${this.page}, "pageSize": 10,"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}]}`,
            )
            : this.listTransactions(`?filters={"page": ${this.page}, "pageSize": 10}`)

          this.storeID
            ? this.listTransactionReserve(
              `?filters={"page": ${this.page}, "pageSize": 5,"filters":[{"fields":["store.id"], "op": "=", "value":"${this.storeID}"}]}`,
            )
            : this.listTransactionReserve(`?filters={"page":${this.page}, "pageSize": 5}`)
          break
        case 'transactionsPending':
          localStorage.removeItem('filter')
          this.listTransactions(
            `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}, {"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
          )
          break
        case 'client':
          localStorage.removeItem('filter')
          this.listUsersCustomers(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'clientArchitect':
          localStorage.removeItem('filter')
          this.listUsersCustomers(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["architect_jhsfid_architect"], "op": "=", "value":"true"} ]}`)
          break
        case 'storeUser':
          localStorage.removeItem('filter')
          this.listUsersByStore(
            `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["users_stores.store_id"], "op": "=", "value":"${this.storeID}"}]}`,
          )
          break
        case 'notification':
          localStorage.removeItem('filter')
          this.listNotification(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["customerOrigin"], "op": "=", "value":"JHSFID"} ]}`)
          break
        case 'notificationArchitect':
          localStorage.removeItem('filter')
          this.listNotification(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["customerOrigin"], "op": "=", "value":"ARCHITECT"} ]}`)
          break
        case 'segmentation':
          localStorage.removeItem('filter')
          this.listSegmentation(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'segmentationResults':
          localStorage.removeItem('filter')
          this.segmentationResults(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'businessPartners':
          localStorage.removeItem('filter')
          this.listBusinessPartners(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["isMain"], "op": "=", "value":"0"}]}`,
          )
          this.listBusinessPartnersMain(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["isMain"], "op": "=", "value":"1"}]}`,
          )
          break
        case 'quiz':
          localStorage.removeItem('filter')
          this.listQuiz(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'question':
          localStorage.removeItem('filter')
          this.listQuestion(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'quizCustomer':
          localStorage.removeItem('filter')
          this.listCustomers(`?filters={"page":${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'quizClient':
          localStorage.removeItem('filter')
          this.listQuizClient(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'fund':
          localStorage.removeItem('filter')
          this.listFunds(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'fundTransaction':
          localStorage.removeItem('filter')
          this.listFundTransaction(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'fundExtract':
          localStorage.removeItem('filter')
          this.listfundExtract(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
        case 'FundCustomersAssociates':
          localStorage.removeItem('filter')
          this.listFundCustomersAssociates(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`,
          )
          break
        case 'notificationCustomers':
          localStorage.removeItem('filter')
          this.listNotificationCustomers(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`,
          )
          break
        case 'membershipPlanCustomers':
          localStorage.removeItem('filter')
          this.membershipPlanCustomers(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`,
          )
          break

        case 'membershipPlanTrasactions':
          localStorage.removeItem('filter')
          this.membershipPlanTrasactions(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`,
          )
          break

        case 'membershipListPlans':
          localStorage.removeItem('filter')
          this.membershipPlans(
            `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["isActive"], "op": "=", "value":"1"}]}`,
          )
          break

        case 'membershipListPlansCustomer':
          localStorage.removeItem('filter')
          this.membershipPlanCustomers(
            `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`,
          )
          break

        case 'clientMovement':
          localStorage.removeItem('filter')
          this.clientAuth(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break

        case 'preRegistration':
          localStorage.removeItem('filter')
          this.typePreRegistration === 'INCORPORATION_AIRPORT'
            ? this.preRegistration(
              `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["typeRegistration"], "op": "=", "value":"INCORPORATION_AIRPORT"}]}`,
            )
            : this.typePreRegistration === 'architects'
              ? this.preRegistrationArchitects(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
              : this.preRegistration(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["typeRegistration"], "op": "<>", "value":"INCORPORATION_AIRPORT"}]}`)
          break

        case 'beneficiary':
          localStorage.removeItem('filter')
          this.listBeneficiaries(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break

        case 'integration':
          localStorage.removeItem('filter')
          this.listIntegrations(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break

        case 'airportCompany':
          localStorage.removeItem('filter')
          this.listAirportCompany()
          break

        case 'architectCompany':
          localStorage.removeItem('filter')
          this.listArchitectCompanies(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'billPaymentNotification':
          localStorage.removeItem('filter')
          this.listBillPaymentNotifications(`page=${this.page}&pageSize=${this.pageSize}`)
          break

        case 'manualJcoinCredit':
          localStorage.removeItem('filter')
          this.listManualJcoinCredits(`page=${this.page}&pageSize=${this.pageSize}`)
          break

        case 'architectStores':
          localStorage.removeItem('filter')
          this.listArchitectStores(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'userArchitectStores':
          localStorage.removeItem('filter')
          this.listUsersArchitectStores(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'architectInvoices':
          localStorage.removeItem('filter')
          this.listArchitectInvoices(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'reportsFundJcoins':
          localStorage.removeItem('filter')
          this.reportsFundJcoins()
          break

        case 'transferScheduleList':
          localStorage.removeItem('filter')
          this.listTransferScheduleList(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'transferScheduleListFromStore':
          localStorage.removeItem('filter')
          this.listTransferScheduleListFromStore(`?page=${this.page}&limit=${this.pageSize}`)
          break

        case 'airportIntegration':
          localStorage.removeItem('filter')
          this.airportsIntegrations(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break

        case 'airportIntegrationNotEligible':
          localStorage.removeItem('filter')
          this.airportsIntegrationsNotEligible(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
          break
      }
    } else {
      this.filters = [
        this.numberTransaction,
        this.nomeFantasiaTransaction,
        this.cpfTransaction,
        this.dateTransaction,
        this.status,
        this.cnpjStore,
        this.nomeFantasiaStore,
        this.nameClient,
        this.cpfClient,
        this.phoneClient,
        this.emailClient,
        this.typeRegister,
        this.nameStoreUser,
        this.phoneStoreUser,
        this.emailStoreUser,
        this.numberTransactionPending,
        this.cpfTransactionPending,
        this.dateTransactionPending,
        this.typeTransaction,
        this.transactionsTest,
        this.titleNotification,
        this.descriptionNotification,
        this.dateNotification,
        this.nameSegmentation,
        this.cpfSegmentationResults,
        this.nameSegmentationResults,
        this.nameBusinessPartners,
        this.activeBusinessPartners,
        this.nameQuiz,
        this.nameQuestion,
        this.nameQuizCustomer,
        this.nameQuizClient,
        this.cpfQuizClient,
        this.cnpjFund,
        this.nameFund,
        this.activeFund,
        this.numberFundTransaction,
        this.cpfFundTransaction,
        this.dateFundTransaction,
        this.statusFundTransaction,
        this.nameFundExtract,
        this.dateFundExtract,
        this.nameFundCustomersAssociates,
        this.cpfFundCustomersAssociates,
        this.cpfNotificationCustomers,
        this.nameNotificationCustomers,
        this.cpfPlanMembership,
        this.fullNamePlanMembership,
        this.dateLastPaymentPlanMembership,
        this.numberTransactionPlanMembership,
        this.dateTransactionPlanMembership,
        this.statusPlanMembership,
        this.namePlanMembership,
        this.beginDatePlanMembership,
        this.endDatePlanMembership,
        this.activePlanMembership,
        this.namePlanMembershipCustomer,
        this.beginDatePlanMembershipCustomer,
        this.endDatePlanMembershipCustomer,
        this.dateMovementJcoins,
        this.typePlanMembership,
        this.namePreRegistration,
        this.cpfPreRegistration,
        this.categoriesBusinessPartners,
        this.typeNotification,
        this.fullNameCustomerBeneficiary,
        this.cpfCustomerBeneficiary,
        this.cpfBeneficiary,
        this.fullNameBeneficiary,
        this.nameIntegration,
        this.cpfIntegration,
        this.dateInitIntegration,
        this.dateEndIntegration,
        this.percentageIntegration,
        this.nameAirportCompany,
        this.cnpjAirportCompany,
        this.cpfAirportCompany,
        this.cnpjAirportCompanyAndNameAirportCompany,
        this.isCpfAirportCompany,
        this.cnpjArchitectCompany,
        this.billPaymentCpf,
        this.billPaymentMonth,
        this.billPaymentYear,
        this.manualJcoinCreditStatus,
        this.manualJcoinCreditCreatedAtFrom,
        this.manualJcoinCreditCreatedAtTo,
        this.isCpfArchitectCompany,
        this.cnpjArchitectStores,
        this.nomeFantasiaArchitectStores,
        this.fullNameUserArchitectStores,
        this.emailUserArchitectStores,
        this.architectInvoiceState,
        this.architectInvoiceEndDate,
        this.architectInvoiceStartDate,
        this.architectInvoiceStoreCnpj,
        this.architectInvoiceTargetDocument,
        this.architectInvoiceStoreName,
        this.reportFundDate,
        this.reportStockClosingDate,
        this.reportConsolidatedDate,
        this.cnpjTransferScheduleList,
        this.startDueDateTransferScheduleList,
        this.endDueDateTransferScheduleList,
        this.reasonTransferScheduleList,
        this.approvalStatusTransferScheduleList,
        this.dateTransferScheduleList,
        this.documentAirportIntegration,
        this.cnpjOriginAirportIntegration,
        this.dateAirportInitIntegration,
        this.dateAirportEndIntegration,
        this.dateAirportIntegration,
        this.prefixAirportIntegration,
        this.documentAirportIntegrationNotEligible,
        this.cnpjOriginAirportIntegrationNotEligible,
        this.prefixAirportIntegrationNotEligible,
        this.dateAirportInitIntegrationNotEligible,
        this.dateAirportEndIntegrationNotEligible,
        this.dateAirportIntegrationNotEligible,
      ]

      this.filtered = this.filters.filter(e => {
        return e != null
      })
      this.joinFilter = this.filtered.join('}, {')
      if (this.joinFilter) {
        switch (this.type) {
          case 'store':
            localStorage.setItem('filter', this.joinFilter)
            this.listStores(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'transaction':
            localStorage.setItem('filter', this.joinFilter)
            this.storeID
              ? this.listTransactions(
                `?filters={"page": ${this.page}, "pageSize": 10,"filters":[{${this.joinFilter}}, {"fields":["storeId"], "op": "=", "value":"${this.storeID}"}]}`,
              )
              : this.listTransactions(
                `?filters={"page":${this.page}, "pageSize": 10,"filters":[{${this.joinFilter}}]}`,
              )
            this.storeID
              ? this.listTransactionReserve(
                `?filters={"page": ${this.page}, "pageSize": 5,"filters":[{${this.joinFilter}}, {"fields":["store.id"], "op": "=", "value":"${this.storeID}"}]}`,
              )
              : this.listTransactionReserve(
                `?filters={"page":${this.page}, "pageSize": 5,"filters":[{${this.joinFilter}}]}`,
              )
            break
          case 'transactionsPending':
            localStorage.setItem('filter', this.joinFilter)

            this.listTransactions(
              `?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{${this.joinFilter}}, {"fields":["storeId"], "op": "=", "value":"${this.storeID}"}, {"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
            )
            break
          case 'client':
            localStorage.setItem('filter', this.joinFilter)
            this.listUsersCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'clientArchitect':
            localStorage.setItem('filter', this.joinFilter)
            this.listUsersCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["architect_jhsfid_architect"], "op": "=", "value":"true"}, {${this.joinFilter}}]}`,
            )
            break
          case 'storeUser':
            localStorage.setItem('filter', this.joinFilter)
            this.listUsersByStore(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}},{"fields":["users_stores.store_id"], "op": "=", "value":"${this.storeID}"} ]}`,
            )
            break
          case 'notification':
            localStorage.setItem('filter', this.joinFilter)
            this.listNotification(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}},{"fields":["customerOrigin"], "op": "=", "value":"JHSFID"}]}`,
            )
            break
          case 'notificationArchitect':
            localStorage.setItem('filter', this.joinFilter)
            this.listNotification(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}},{"fields":["customerOrigin"], "op": "=", "value":"ARCHITECT"}]}`,
            )
            break
          case 'segmentation':
            localStorage.setItem('filter', this.joinFilter)
            this.listSegmentation(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'segmentationResults':
            localStorage.setItem('filter', this.joinFilter)
            this.segmentationResults(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'businessPartners':
            localStorage.setItem('filter', this.joinFilter)
            this.listBusinessPartners(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize}, "filters":[{"fields":["is_main"], "op": "=", "value":"0"}, {${this.joinFilter}}]}`,
            )
            this.listBusinessPartnersMain(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize}, "filters":[{"fields":["is_main"], "op": "=", "value":"1"}, {${this.joinFilter}}]}`,
            )
            break
          case 'quiz':
            localStorage.setItem('filter', this.joinFilter)
            this.listQuiz(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'question':
            localStorage.setItem('filter', this.joinFilter)
            this.listQuestion(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'quizCustomer':
            localStorage.setItem('filter', this.joinFilter)
            this.listCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'quizClient':
            localStorage.setItem('filter', this.joinFilter)
            this.listQuizClient(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'fund':
            localStorage.setItem('filter', this.joinFilter)
            this.listFunds(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'fundTransaction':
            localStorage.setItem('filter', this.joinFilter)
            this.listFundTransaction(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'fundExtract':
            localStorage.setItem('filter', this.joinFilter)
            this.listfundExtract(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'FundCustomersAssociates':
            localStorage.setItem('filter', this.joinFilter)
            this.listFundCustomersAssociates(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'notificationCustomers':
            localStorage.setItem('filter', this.joinFilter)
            this.listNotificationCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'membershipPlanCustomers':
            localStorage.setItem('filter', this.joinFilter)
            this.membershipPlanCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'membershipPlanTrasactions':
            localStorage.setItem('filter', this.joinFilter)
            this.membershipPlanTrasactions(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'membershipListPlans':
            localStorage.setItem('filter', this.joinFilter)
            this.membershipPlans(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'membershipListPlansCustomer':
            localStorage.setItem('filter', this.joinFilter)
            this.membershipPlanCustomers(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'clientMovement':
            localStorage.setItem('filter', this.joinFilter)
            this.clientAuth(
              `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'preRegistration':
            localStorage.setItem('filter', this.joinFilter)
            this.typePreRegistration === 'INCORPORATION_AIRPORT'
              ? this.preRegistration(
                `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}},{"fields":["typeRegistration"], "op": "=", "value":"INCORPORATION_AIRPORT"}]}`,
              )
              : this.typePreRegistration === 'architects'
                ? this.preRegistrationArchitects(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`)
                : this.preRegistration(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}},{"fields":["typeRegistration"], "op": "<>", "value":"INCORPORATION_AIRPORT"}]}`)
            break

          case 'beneficiary':
            localStorage.setItem('filter', this.joinFilter)
            this.listBeneficiaries(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break

          case 'integration':
            localStorage.setItem('filter', this.joinFilter)

            if (this.joinFilter.includes('incorporation_integration.createdAt') || this.joinFilter.includes('date')) {
              this.listIntegrations(
                `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
              )
            } else {
              this.listIntegrations(
                `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}, {"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD')}"}, {"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD')}"}]}`,
              )
            }
            break
          case 'airportCompany':
            localStorage.setItem('filter', this.joinFilter)
            this.listAirportCompany(
              this.joinFilter,
            )
            break
          case 'architectStores':
            localStorage.setItem('filter', this.joinFilter)
            this.listArchitectStores(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'userArchitectStores':
            localStorage.setItem('filter', this.joinFilter)
            this.listUsersArchitectStores(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'architectInvoices':
            localStorage.setItem('filter', this.joinFilter)
            this.listArchitectInvoices(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'architectCompany':
            localStorage.setItem('filter', this.joinFilter)
            this.listArchitectCompanies(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'billPaymentNotification':
            localStorage.setItem('filter', this.joinFilter)
            this.listBillPaymentNotifications(
              `page=${this.page}&pageSize=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'manualJcoinCredit':
            localStorage.setItem('filter', this.joinFilter)
            this.listManualJcoinCredits(
              `page=${this.page}&pageSize=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'reportsFundJcoins':
            localStorage.setItem('filter', this.joinFilter)
            this.reportsFundJcoins(
              `${this.joinFilter}`,
              this.reportFundDateReturn,
            )
            break
          case 'reportsStockClosing':
            localStorage.setItem('filter', this.joinFilter)
            this.reportsStockClosing(
              `${this.joinFilter}`,
              this.reportStockClosingDateReturn,
            )
            break
          case 'transferScheduleList':
            localStorage.setItem('filter', this.joinFilter)
            this.listTransferScheduleList(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break

          case 'transferScheduleListFromStore':
            localStorage.setItem('filter', this.joinFilter)
            this.listTransferScheduleListFromStore(
              `?page=${this.page}&limit=${this.pageSize}${this.joinFilter}`,
            )
            break
          case 'airportIntegration':
            localStorage.setItem('filter', this.joinFilter)
            this.airportsIntegrations(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'airportIntegrationNotEligible':
            localStorage.setItem('filter', this.joinFilter)
            this.airportsIntegrationsNotEligible(
              `?filters={"page":${this.page}, "pageSize": ${this.pageSize},"filters":[{${this.joinFilter}}]}`,
            )
            break
          case 'reportsConsolidated':
            localStorage.setItem('filter', this.joinFilter)
            this.reportsConsolidatedJcoins(
              `${this.joinFilter}`,
              this.reportConsolidatedDateReturn,
            )
            break
        }
      }
    }
  }

  listStores(filter?) {
    this.storeService.filterStores(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listCampaigns(filter?) {
    this.campaignService.listCampaigns(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listTransactions(filter?) {
    this.transactionService.listTransactions(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listUsersCustomers(filter?) {
    this.storeService.listUsersCustomers(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listUsersByStore(filter?) {
    this.storeService.listUsersByStore(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listNotification(filter?) {
    this.notificationService.list(`${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listSegmentation(filter?) {
    this.segmentationService.list(`${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listFunds(filter?) {
    this.fundService.list(`${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listBusinessPartners(filter?) {
    this.businessPartnersService.list(this.categoryID, `${filter}`).then(
      async response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listBusinessPartnersMain(filter?) {
    this.businessPartnersService.list(this.categoryID, `${filter}`).then(
      async response => {
        response.formGroup = this.formGroup
        response.results.push(
          { nameInput: this.formGroup.value.nameBusinessPartners },
          { activeInput: this.formGroup.value.activeBusinessPartners },
        )
        this.returnFilterMain.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listQuiz(filter?) {
    this.quizService.list(`${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listQuestion(filter?) {
    this.questionsService.list(this.quizID, `${filter}`).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  segmentationResults(filter?) {
    this.segmentationService.listCustomers(this.segmentationId, `${filter}`).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listQuizClient(filter?) {
    this.quizService.listQuizClient(this.quizID, `${filter}`).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listFundTransaction(filter?) {
    this.fundService.getTransactions(this.fundId, `${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listSegmentationForm(page, pageSize) {
    this.segmentationService.listResult(this.filtersSegmentation, page, pageSize, this.limit).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listfundExtract(filter?) {
    this.fundService.getExtract(this.fundId, `${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listCustomers(filter?) {
    this.quizService.listCustomers(this.customerID, `${filter}`).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listFundCustomersAssociates(filter?) {
    this.customersAssociatesService.list(this.fundId, `${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listNotificationCustomers(filter?) {
    this.notificationService.notificationCustomersList(this.notificationId, `${filter}`).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listTransactionReserve(filter?) {
    this.transactionService.listTransactionsReservation(`${filter}`).subscribe(
      response => {
        response.formGroup = this.formGroup
        this.returnFilterReserve.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  membershipPlanCustomers(filter?) {
    if (this.customerID) {
      this.membershipService.listPlansByCustomer(this.customerID, `${filter}`).then(
        async response => {
          response.formGroup = this.formGroup
          this.returnFilter.emit(response)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
    } else {
      this.membershipService.listCustomersByPlan(this.planId, `${filter}`).then(
        async response => {
          response.formGroup = this.formGroup
          this.returnFilter.emit(response)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
    }
  }

  membershipPlanTrasactions(filter?) {
    this.transactionService
      .transactionsMembershipPlanByCustomer(this.planId, this.customerID, `${filter}`)
      .then(
        async response => {
          response.formGroup = this.formGroup
          this.returnFilter.emit(response)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  membershipPlans(filter?) {
    this.membershipService.listPlansOthers(`${filter}`).then(
      async response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  clientAuth(filter?) {
    this.authService.listMovementJcoins(this.cpf, `${filter}`).then(
      async response => {
        this.returnFilter.emit(response)
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  preRegistration(filter?) {
    this.preRegistrationService.list(`${filter}`).then(
      async response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  preRegistrationArchitects(filter?) {
    this.preRegistrationService.listArchitects(`${filter}`).then(
      async response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        alert(error.error.message)
      },
    )
  }

  listBeneficiaries(filter) {
    this.authService.clientsBeneficiaries(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listIntegrations(filter) {
    this.authService.clientsIncorporations(filter).then(
      response => {
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listAirportCompany(filter?) {
    this.airportsService.listCompanyAirport(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listArchitectCompanies(filter?) {
    if (filter) { filter = filter.replaceAll('}, {', '') }
    this.companiesService.list(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listBillPaymentNotifications(filter?) {
    if (filter) { filter = filter.replaceAll('}, {', '') }
    this.billPaymentNotificationService.list(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listManualJcoinCredits(filter?) {
    if (filter) { filter = filter.replaceAll('}, {', '') }
    this.manualJcoinCreditService.list(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listArchitectStores(filter?) {
    this.companiesService.listStoresBusinessUnits(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listUsersArchitectStores(filter?) {
    if (filter) { filter = filter.replaceAll('}, {', '') }
    this.companiesService.usersListStoresBusinessUnits(this.usersStoresId, filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listArchitectInvoices(filter?) {
    if (filter) { filter = filter.replaceAll('}, {', '') }
    this.architectInvoiceService.list({ storeBusinessUnitId: this.storeBusinessUnitId, filter }).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  reportsFundJcoins(filter?, reportFundDateReturn?) {
    this.reportService.monthBalance(filter).then(
      response => {
        response.formGroup = this.formGroup
        response.filter = reportFundDateReturn
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listTransferScheduleList(filter?) {
    this.liquidationTransferService.listShedules(filter).toPromise().then(
      response => {
        response.formGroup = this.formGroup
        response.joinFilter = this.joinFilter
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  listTransferScheduleListFromStore(filter?) {
    this.liquidationTransferService.listShedulesfromStore(this.storeID, filter).toPromise().then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  reportsStockClosing(filter?, reportFundDateReturn?) {
    this.reportService.stockClosing(filter).then(
      response => {
        response.formGroup = this.formGroup
        response.filter = reportFundDateReturn
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  reportsConsolidatedJcoins(filter?, reportFundDateReturn?) {
    this.reportService.consolidatedJcoins(filter).then(
      response => {
        response.formGroup = this.formGroup
        response.filter = reportFundDateReturn
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  airportsIntegrations(filter?) {
    this.airportsService.integrationsList(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  airportsIntegrationsNotEligible(filter?) {
    this.airportsService.integrationsNotEligible(filter).then(
      response => {
        response.formGroup = this.formGroup
        this.returnFilter.emit(response)
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  pageChanged(event) {
    localStorage.setItem('pageSizeEvent', event.pageSize)
    const joinFilter = localStorage.getItem('filter')
    const page = String(event.pageIndex + 1)

    switch (this.type) {
      case 'campaign':
        joinFilter
          ? this.listCampaigns(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listCampaigns(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break
      case 'store':
        joinFilter
          ? this.listStores(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listStores(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break
      case 'transaction':
        if (this.storeID) {
          joinFilter
            ? this.listTransactions(
              `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{${joinFilter}}, {"fields":["storeId"], "op": "=", "value":"${this.storeID}"}]}`,
            )
            : this.listTransactions(
              `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}]}`,
            )
        } else {
          joinFilter
            ? this.listTransactions(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
            )
            : this.listTransactions(`?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["transactions.type"], "op": "<>", "value": "CARD_MACHINE"}, {"fields":["transactionsTest"], "op": "=", "value": "false"}]}`)
        }
        break
      case 'transactionsPending':
        joinFilter
          ? this.listTransactions(
            `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{${joinFilter}}, {"fields":["storeId"], "op": "=", "value":"${this.storeID}"}, {"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
          )
          : this.listTransactions(
            `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["storeId"], "op": "=", "value":"${this.storeID}"}, {"fields":["isManual"], "op": "=", "value": true}, {"fields":["transactions.state"], "op": "=", "value": "PENDING"}]}`,
          )

        break
      case 'client':
        joinFilter
          ? this.listUsersCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listUsersCustomers(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break
      case 'clientArchitect':
        joinFilter
          ? this.listUsersCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listUsersCustomers(`?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["architect_jhsfid_architect"], "op": "=", "value":"true"} ]}`)

        break
      case 'storeUser':
        joinFilter
          ? this.listUsersByStore(
            `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}},{"fields":["users_stores.store_id"], "op": "=", "value":"${this.storeID}"}]}`,
          )
          : this.listUsersByStore(
            `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["users_stores.store_id"], "op": "=", "value":"${this.storeID}"}]}`,
          )

        break
      case 'notification':
        joinFilter
          ? this.listNotification(
            `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}},{"fields":["customerOrigin"], "op": "=", "value":"JHSFID"}]}`,
          )
          : this.listNotification(`?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["customerOrigin"], "op": "=", "value":"JHSFID"}]}`)
        break
      case 'notificationArchitect':
        joinFilter
          ? this.listNotification(
            `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}},{"fields":["customerOrigin"], "op": "=", "value":"ARCHITECT"}]}`,
          )
          : this.listNotification(`?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["customerOrigin"], "op": "=", "value":"ARCHITECT"}]}`)
        break
      case 'businessPartners':
        if (joinFilter) {
          const isTrue = joinFilter.includes('"categoryJoin.id"')
          if (this.categoryJoinId && !isTrue) {
            this.listBusinessPartners(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["is_main"], "op": "=", "value":"0"}, {"fields":["categoryJoin.id"], "op": "=", "value":"${this.categoryJoinId}"},{${joinFilter}}]}`,
            )
          } else {
            this.listBusinessPartners(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["is_main"], "op": "=", "value":"0"}, {${joinFilter}}]}`,
            )
          }
        } else {
          if (this.categoryJoinId) {
            this.listBusinessPartners(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize}, "filters":[{"fields":["is_main"], "op": "=", "value":"0"}, {"fields":["categoryJoin.id"], "op": "=", "value":"${this.categoryJoinId}"}]}`,
            )
          } else {
            this.listBusinessPartners(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize}, "filters":[{"fields":["is_main"], "op": "=", "value":"0"}]}`,
            )
          }
        }

        break
      case 'quiz':
        joinFilter
          ? this.listQuiz(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listQuiz(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break
      case 'question':
        joinFilter
          ? this.listQuestion(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listQuestion(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'quizCustomer':
        joinFilter
          ? this.listCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listCustomers(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'quizClient':
        joinFilter
          ? this.listQuizClient(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listQuizClient(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'segmentation':
        joinFilter
          ? this.listSegmentation(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listSegmentation(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'segmentationResults':
        joinFilter
          ? this.segmentationResults(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.segmentationResults(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'segmentationForm':
        this.listSegmentationForm(page, event.pageSize)
        break

      case 'fund':
        joinFilter
          ? this.listFunds(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listFunds(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'fundTransaction':
        joinFilter
          ? this.listFundTransaction(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listFundTransaction(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'fundExtract':
        joinFilter
          ? this.listfundExtract(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listfundExtract(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'FundCustomersAssociates':
        joinFilter
          ? this.listFundCustomersAssociates(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listFundCustomersAssociates(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
          )

        break

      case 'notificationCustomers':
        joinFilter
          ? this.listNotificationCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listNotificationCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
          )

        break

      case 'transactionReserve':
        if (this.storeID) {
          joinFilter
            ? this.listTransactionReserve(
              `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{${joinFilter}}, {"fields":["store.id"], "op": "=", "value":"${this.storeID}"}]}`,
            )
            : this.listTransactionReserve(
              `?filters={"page": ${page}, "pageSize":${event.pageSize},"filters":[{"fields":["store.id"], "op": "=", "value":"${this.storeID}"}]}`,
            )
        } else {
          joinFilter
            ? this.listTransactionReserve(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
            )
            : this.listTransactionReserve(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
            )
        }
        break

      case 'membershipPlanCustomers':
        joinFilter
          ? this.membershipPlanCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.membershipPlanCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
          )
        break

      case 'membershipPlanTrasactions':
        joinFilter
          ? this.membershipPlanTrasactions(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.membershipPlanTrasactions(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
          )
        break

      case 'membershipListPlans':
        joinFilter
          ? this.membershipPlans(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.membershipPlans(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}, "filters":[{"fields":["isActive"], "op": "=", "value":"1"}]}`,
          )
        break

      case 'membershipListPlansCustomer':
        joinFilter
          ? this.membershipPlanCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.membershipPlanCustomers(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize}}`,
          )
        break

      case 'clientMovement':
        joinFilter
          ? this.clientAuth(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.clientAuth(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)
        break

      case 'preRegistration':
        joinFilter
          ? this.typePreRegistration === 'INCORPORATION_AIRPORT' ?
            this.preRegistration(
              `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}},{"fields":["typeRegistration"], "op": "=", "value":"INCORPORATION_AIRPORT"}]}`,
            )
            : this.typePreRegistration === 'architects'
              ? this.preRegistrationArchitects(
                `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
              )
              : this.preRegistration(
                `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}},{"fields":["typeRegistration"], "op": "<>", "value":"INCORPORATION_AIRPORT"}]}`,
              )
          : this.typePreRegistration === 'INCORPORATION_AIRPORT'
            ? this.preRegistration(`?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["typeRegistration"], "op": "=", "value":"INCORPORATION_AIRPORT"}]}`)
            : this.typePreRegistration === 'architects'
              ? this.preRegistrationArchitects(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)
              : this.preRegistration(`?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["typeRegistration"], "op": "<>", "value":"INCORPORATION_AIRPORT"}]}`)

        break

      case 'beneficiary':
        joinFilter
          ? this.listBeneficiaries(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.listBeneficiaries(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'integration':
        if (joinFilter) {
          if (joinFilter.includes('incorporation_integration.createdAt') || joinFilter.includes('date')) {
            this.listIntegrations(
              `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
            )
          } else {
            this.listIntegrations(
              `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}, {"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD')}"}, {"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD')}"}]}`,
            )
          }
        } else {
          this.listIntegrations(
            `?filters={"page":${page}, "pageSize": ${event.pageSize},"filters":[{"fields":["incorporation_integration.createdAt"], "op": ">=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD')}"}, {"fields":["incorporation_integration.createdAt"], "op": "<=", "value":"${moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD')}"}]}`,
          )
        }
        break

      case 'architectCompany':
        joinFilter
          ? this.listArchitectCompanies(
            `?page=${page}&limit=${this.pageSize}${joinFilter}`,
          )
          : this.listArchitectCompanies(`?page=${page}&limit=${event.pageSize}`)

        break

      case 'billPaymentNotification':
        joinFilter
          ? this.listBillPaymentNotifications(
            `page=${page}&pageSize=${this.pageSize}${joinFilter}`,
          )
          : this.listBillPaymentNotifications(`page=${page}&pageSize=${event.pageSize}`)

        break

      case 'manualJcoinCredit':
        joinFilter
          ? this.listManualJcoinCredits(
            `page=${page}&pageSize=${this.pageSize}${joinFilter}`,
          )
          : this.listManualJcoinCredits(`page=${page}&pageSize=${event.pageSize}`)

        break

      case 'architectStores':
        joinFilter
          ? this.listArchitectStores(
            `?page=${page}&limit=${this.pageSize}${joinFilter}`,
          )
          : this.listArchitectStores(`?page=${page}&limit=${event.pageSize}`)

        break

      case 'userArchitectStores':
        joinFilter
          ? this.listUsersArchitectStores(
            `?page=${page}&limit=${this.pageSize}${joinFilter}`,
          )
          : this.listUsersArchitectStores(`?page=${page}&limit=${event.pageSize}`)

        break
      case 'architectInvoices':
        const queryString = joinFilter
          ? `?page=${page}&limit=${event.pageSize}${joinFilter}`
          : `?page=${page}&limit=${event.pageSize}`

        this.listArchitectInvoices(queryString)
        break
      case 'transferScheduleList':
        const queryStr = joinFilter
          ? `?page=${page}&limit=${event.pageSize}${joinFilter}`
          : `?page=${page}&limit=${event.pageSize}`

        this.listTransferScheduleList(queryStr)
        break
      case 'transferScheduleListFromStore':
        this.listTransferScheduleListFromStore(
          joinFilter
            ? `?page=${page}&limit=${event.pageSize}${joinFilter}`
            : `?page=${page}&limit=${event.pageSize}`,
        )
        break

      case 'airportIntegration':
        joinFilter
          ? this.airportsIntegrations(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.airportsIntegrations(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break

      case 'airportIntegrationNotEligible':
        joinFilter
          ? this.airportsIntegrationsNotEligible(
            `?filters={"page": ${page}, "pageSize": ${event.pageSize},"filters":[{${joinFilter}}]}`,
          )
          : this.airportsIntegrationsNotEligible(`?filters={"page": ${page}, "pageSize": ${event.pageSize}}`)

        break
    }
  }
}
