import { SegmentationService } from 'src/app/services/segmentation.service'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { Component, ViewChild, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { FormGroup } from '@angular/forms'
@Component({
  selector: 'app-segmentation-list',
  templateUrl: './segmentation-list.component.html',
  styleUrls: ['./segmentation-list.component.scss'],
})
export class SegmentationListComponent implements OnInit {
  headersTable: string[] = ['name', 'filterCount', 'customerCount', 'actions']
  public pageTotal: number
  fieldsForm: any
  dataSource: any
  resultsAll: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public formGroup: FormGroup
  public params: Params
  public returnedFilters: any

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public segmentationService: SegmentationService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
        this.initForm()
      } else {
        this.listSegments().catch(err => {
          this.snackBar.open(err.message)
        })
        this.initForm()
      }
    })
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameSegmentation',
        label: 'Nome',
        placeholder: 'Nome',
      },
    ]
  }

  async listSegments () {
    const { results, total } = await this.segmentationService
      .list(`?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
    this.resultsAll = results
  }

  addSegmentation () {
    this.router.navigate(['./dashboard/segmentation/form']).catch(err => {
      this.snackBar.open(err.message)
    })
  }

  removeSegmentation (id: string) {
    this.dialog
      .open(ModalConfirmComponent, {
        data: {
          text: 'Deseja remover essa Segmentação?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.segmentationService
            .delete(id)
            .then(async () => {
              this.snackBar.open('Segmentação removida com sucesso.')
              return this.listSegments()
            })
            .catch(err => {
              this.snackBar.open(err.message)
            })
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reprocess (id: string) {
    this.segmentationService
      .reprocess(id)
      .then(async () => {
        if (this.returnedFilters) {
          return this.reciverFeedback(this.returnedFilters)
        }
        return this.listSegments()
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reprocessAll (resultsAll) {
    const ids = []
    for (const r of resultsAll) {
      ids.push(r.id)
    }
    this.segmentationService
      .reprocessSegmentationAll({ ids })
      .then(async () => {
        return this.listSegments()
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
