import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Associates } from '../models/associates'
import { environment } from '../../environments/environment'
import { FormGroup } from '@angular/forms'

@Injectable({ providedIn: 'root' })
export class CustomersAssociatesService {
  constructor (private readonly http: HttpClient) { }

  async list (fundId: string, filter?):
  Promise<{ formGroup: FormGroup, results: Associates[], total: number }> {
    return this.http
      .get<{ formGroup: FormGroup, results: Associates[], total: number }>(
        `${environment.apiUrl}/cms/funds/${fundId}/customers-associates${filter || ''}`,
    )
      .toPromise()
  }

  async add (
    fundId: string,
    customerIdList: string[],
  ): Promise<{ success: boolean, fundId: string, customerIdsList: string[] }> {
    return this.http
      .post<{ success: boolean, fundId: string, customerIdsList: string[] }>(
        `${environment.apiUrl}/cms/funds/${fundId}/customers-associates`,
        { customerIdList },
    )
      .toPromise()
  }

  async remove (
    fundId: string,
    associatedId: string,
  ): Promise<{ success: boolean, fundId: string, customerId: string }> {
    return this.http
      .delete<{ success: boolean, fundId: string, customerId: string }>(
        `${environment.apiUrl}/cms/funds/${fundId}/customers-associates/${associatedId}`,
    )
      .toPromise()
  }

  async listCustomers (fundId): Promise<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/cms/funds/${fundId}/customers`).toPromise()
  }
}
