import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorsService } from 'src/app/core/services/errors.service';

interface DialogData {
  startDate: string;
  endDate: string;
  status: string;
}

@Component({
  selector: 'app-modal-form-integrations',
  templateUrl: './modal-form-integrations.component.html',
  styleUrls: ['./modal-form-integrations.component.scss']
})
export class ModalFormIntegrationsComponent implements OnInit {

  public filterForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalFormIntegrationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) { }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: ['COMPUTED']
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.filterForm.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }

    const formattedStartDate = this.formatDate(this.filterForm.value.startDate);
    const formattedEndDate = this.formatDate(this.filterForm.value.endDate);
  
    const filters = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      status: this.filterForm.value.status
    };
  
    this.dialogRef.close(filters);
  }

  formatDate(date: string | Date): string {
    const parsedDate = new Date(date);
    const utcDate = new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60000);
    const day = this.padZero(utcDate.getUTCDate());
    const month = this.padZero(utcDate.getUTCMonth() + 1);
    const year = utcDate.getUTCFullYear();
    return `${day}${month}${year}`;
  }
  

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
