import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui'
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog'
import { STYLES as SLIDER_STYLES } from '@alyle/ui/slider'
import {
  STYLES as CROPPER_STYLES,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent,
  ImgOutput,
} from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'

import { MatSnackBar } from '@angular/material'

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES)
  ref.renderStyleSheet(CROPPER_STYLES)
  const slider = ref.selectorsOf(SLIDER_STYLES)
  const cropper = ref.selectorsOf(CROPPER_STYLES)

  return {
    root: lyl`{
      ${cropper.root} {
        max-width: calc(40 * 16px)
        height: calc(40 * 9px)
      }
    }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
      padding: 1em
    }`,
  }
}

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss'],
})
export class CropperDialogComponent implements WithStyles, AfterViewInit {
  readonly classes = this.sRenderer.renderSheet(STYLES, 'root')
  ready: boolean
  scale: number
  minScale: number
  croppedImage?: string
  imgResultAfterCompress: string
  uploadImg: any
  config = {}

  myConfig: ImgCropperConfig = {
    width: 38 * 16,
    height: 38 * 9,
    // type: 'image/png',
    keepAspectRatio: true,
    output: {
      width: 38 * 16,
      height: 38 * 9,
    },
    resizableArea: false,
    extraZoomOut: true,
  }

  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper
  constructor (
    @Inject(LY_DIALOG_DATA) private readonly event: { img: any, config: any },
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
    private readonly imageCompress: NgxImageCompressService,
    public snackBar: MatSnackBar,
  ) {
    if ('width' in this.event.config) {
      this.myConfig.width = this.event.config.width
      ;(this.myConfig.output as ImgOutput).width = this.event.config.width
    }
    if ('height' in this.event.config) {
      this.myConfig.height = this.event.config.height
      ;(this.myConfig.output as ImgOutput).height = this.event.config.height
    }
  }

  ngAfterViewInit () {
    if (this.event.img.type) {
      this.dialogRef.afterOpened.subscribe(() => {
        this.cropper.selectInputEvent(this.event.img)
      })
    } else {
      if (
        this.event.config &&
        'scale' in this.event.config &&
        'xOrigin' in this.event.config &&
        'yOrigin' in this.event.config
      ) {
        this.cropper.setImageUrl(this.event.img, () => {
          this.cropper.setScale(this.event.config.scale, true)
          this.cropper.updatePosition(this.event.config.xOrigin, this.event.config.yOrigin)
        })
      } else {
        this.cropper.setImageUrl(this.event.img)
      }
    }
  }

  close () {
    const result = { img: this.cropper.crop(), config: this.config }
    this.dialogRef.close(result)
  }

  cancel () {
    this.dialogRef.close()
  }

  onCropped (e: ImgCropperEvent) {
    this.config = { xOrigin: e.xOrigin, yOrigin: e.yOrigin, scale: e.scale }

    this.imageCompress
      .compressFile(e.dataURL, -2, 50, 50)
      .then(result => {
        this.imgResultAfterCompress = result
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  onLoaded (e: ImgCropperEvent) {
    console.log('img loaded', e)
  }

  onError (e: ImgCropperErrorEvent) {
    // this.snackBar.open(error.message)
    console.warn(`'${e.name}' is not a valid image`, e)
    // Close the dialog if it fails
    this.dialogRef.close()
  }
}
