import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import numeral from 'numeral';
var StoreQrcodeComponent = /** @class */ (function () {
    function StoreQrcodeComponent(errorsService, zipCodeService, formBuilder, storeService, router, route, snackBar) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.states = [];
        this.storeID = '';
        this.cnpjSelected = null;
        this.title = 'app';
        this.elementType = 'string';
        this.valueQrCode = '';
        this.segmentos = [];
        this.bankCodes = [];
        this.params = { storeFormGroupValue: [] };
        this.isMembershipActive = false;
        this.isPayActive = false;
        this.initForm();
        this.states = [
            {
                abbr: 'AC',
                name: 'Acre',
            },
            {
                abbr: 'AL',
                name: 'Alagoas',
            },
            {
                name: 'Amapá',
                abbr: 'AP',
            },
            {
                name: 'Amazonas',
                abbr: 'AM',
            },
            {
                name: 'Bahia',
                abbr: 'BA',
            },
            {
                name: 'Ceará',
                abbr: 'CE',
            },
            {
                name: 'Distrito Federal',
                abbr: 'DF',
            },
            {
                name: 'Espírito Santo',
                abbr: 'ES',
            },
            {
                name: 'Goiás',
                abbr: 'GO',
            },
            {
                name: 'Maranhão',
                abbr: 'MA',
            },
            {
                name: 'Mato Grosso',
                abbr: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                abbr: 'MS',
            },
            {
                name: 'Minas Gerais',
                abbr: 'MG',
            },
            {
                name: 'Pará',
                abbr: 'PA',
            },
            {
                name: 'Paraíba',
                abbr: 'PB',
            },
            {
                name: 'Paraná',
                abbr: 'PR',
            },
            {
                name: 'Pernambuco',
                abbr: 'PE',
            },
            {
                name: 'Piauí',
                abbr: 'PI',
            },
            {
                name: 'Rio de Janeiro',
                abbr: 'RJ',
            },
            {
                name: 'Rio Grande do Norte',
                abbr: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                abbr: 'RS',
            },
            {
                name: 'Rondônia',
                abbr: 'RO',
            },
            {
                name: 'Roraima',
                abbr: 'RR',
            },
            {
                name: 'Santa Catarina',
                abbr: 'SC',
            },
            {
                name: 'São Paulo',
                abbr: 'SP',
            },
            {
                name: 'Sergipe',
                abbr: 'SE',
            },
            {
                name: 'Tocantins',
                abbr: 'T',
            },
        ];
    }
    StoreQrcodeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
    };
    StoreQrcodeComponent.prototype.initForm = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.valueQrCode = this.storeID;
        this.formGroup = this.formBuilder.group({
            nomeFantasia: [{ value: null, disabled: true }, [Validators.required]],
            razaoSocial: [{ value: null, disabled: true }, [Validators.required]],
            complement: [{ value: null, disabled: true }, []],
            cnpj: [
                { value: null, disabled: true },
                [Validators.required, Validators.minLength(14), Validators.maxLength(14)],
            ],
            phoneNumber: [{ value: null, disabled: true }, [Validators.required]],
            emailAddress: [{ value: null, disabled: true }, [Validators.required]],
            mcc: [{ value: null, disabled: true }, [Validators.required]],
            mccCategory: [{ value: null, disabled: true }, [Validators.required]],
            mccDescription: [{ value: null, disabled: true }, [Validators.required]],
            openingDate: [{ value: null, disabled: true }, [Validators.required]],
            liquidationDays: [{ value: null, disabled: true }, [Validators.required]],
            isMembershipActive: [{ value: null, disabled: true }, [Validators.required]],
            isPayActive: [{ value: null, disabled: true }, [Validators.required]],
            manualPaymentEnabled: [{ value: null, disabled: true }, [Validators.required]],
            futurePaymentEnabled: [{ value: null, disabled: true }, [Validators.required]],
            taxCashback: [{ value: null, disabled: true }, []],
            taxAdministrative: [{ value: null, disabled: true }, []],
            taxRewardsTransaction: [{ value: null, disabled: true }, []],
            taxCreditCardTransaction: [{ value: null, disabled: true }, []],
            pointsConversionFactorIn: [{ value: null, disabled: true }, []],
            pointsConversionFactorOut: [{ value: null, disabled: true }, []],
            pointsExpiration: [{ value: null, disabled: true }, []],
            maximumInstallmentsPerTransaction: [{ value: null, disabled: true }, [Validators.required]],
            minimumInstallmentValue: [{ value: null, disabled: true }, [Validators.required]],
            // maximumPointsPerTransaction: [null, []],
            minimumTransactionValue: [{ value: null, disabled: true }, [Validators.required]],
            street: [{ value: null, disabled: true }, [Validators.required]],
            number: [{ value: null, disabled: true }, [Validators.required]],
            neighborhood: [{ value: null, disabled: true }, [Validators.required]],
            city: [{ value: null, disabled: true }, [Validators.required]],
            state: [{ value: null, disabled: true }, [Validators.required]],
            zipCode: [
                { value: null, disabled: true },
                [Validators.required, Validators.minLength(8), Validators.maxLength(8)],
            ],
        });
        if (this.storeID != '' && this.storeID != null) {
            this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.store = response;
                    this.isMembershipActive = this.store.isMembershipActive;
                    this.store.isPayActive = this.store.isPayActive;
                    this.formGroup.patchValue({
                        nomeFantasia: this.store.nomeFantasia,
                        razaoSocial: this.store.razaoSocial,
                        complement: this.store.complement,
                        cnpj: this.store.cnpj,
                        emailAddress: this.store.emailAddress,
                        phoneNumber: this.store.phoneNumber,
                        openingDate: this.store.openingDate.substring(0, 10),
                        liquidationDays: this.store.liquidationDays,
                        mcc: this.store.mccCategory +
                            ' - ' +
                            this.store.mccDescription,
                        mccCategory: this.store.mccCategory,
                        mccDescription: this.store.mccDescription,
                        isMembershipActive: this.store.isMembershipActive,
                        isPayActive: this.store.isPayActive,
                        manualPaymentEnabled: this.store.manualPaymentEnabled,
                        futurePaymentEnabled: this.store.futurePaymentEnabled,
                        maximumInstallmentsPerTransaction: this.store.maximumInstallmentsPerTransaction,
                        minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
                        // maximumPointsPerTransaction: this.store.maximumPointsPerTransaction,
                        taxCreditCardTransaction: this.store.taxCreditCardTransaction,
                        pointsExpiration: this.store.pointsExpiration,
                        minimumTransactionValue: this.store.minimumTransactionValue / 100,
                        pointsConversionFactorIn: this.store.pointsConversionFactorIn / 100,
                        pointsConversionFactorOut: this.store.pointsConversionFactorOut / 100,
                        taxCashback: numeral(this.store.taxCashback).multiply(100).value(),
                        taxAdministrative: numeral(this.store.taxAdministrative).multiply(100).value(),
                        taxRewardsTransaction: numeral(this.store.taxRewardsTransaction).multiply(100).value(),
                        street: this.store.street,
                        number: this.store.number,
                        neighborhood: this.store.neighborhood,
                        city: this.store.city,
                        state: this.store.state,
                        zipCode: this.store.zipCode,
                    });
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
    };
    StoreQrcodeComponent.prototype.onChange = function (event) {
        this.formGroup.patchValue({
            mccCategory: event.category,
            mccDescription: event.description,
        });
    };
    StoreQrcodeComponent.prototype.loadSegmentos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService.getSegmentos().subscribe(function (response) {
                    _this.segmentos = response.sort(function (a, b) {
                        a.category = a.category.toUpperCase();
                        b.category = b.category.toUpperCase();
                        if (a.category < b.category) {
                            return -1;
                        }
                        else if (a.category > b.category) {
                            return 1;
                        }
                        return 0;
                    });
                    /**
                     * this.segmentos = response.sort((i1, i2) => {
                      const s1 = `${ i1.category} - ${ i1.description }`;
                      const s2 = `${ i2.category} - ${ i2.description }`;
                      if (s1 > s2) {
                        return 1;
                      }
                      if (s1 < s2) {
                        return -1;
                      }
                      return 0;
                    });
                     */
                });
                return [2 /*return*/];
            });
        });
    };
    StoreQrcodeComponent.prototype.changeIsPayActive = function (event) {
        this.isPayActive = event.value;
        if (!this.isPayActive) {
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
            // this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null);
            // this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null);
            // this.formGroup.get('minimumTransactionValue').setValidators(null);
            // this.formGroup.get('minimumTransactionValue').setErrors(null);
        }
        else {
            this.formGroup.get('taxCreditCardTransaction').setValidators(null);
            this.formGroup.get('taxCreditCardTransaction').setErrors(null);
            // this.formGroup.get('maximumInstallmentsPerTransaction').setValidators(null);
            // this.formGroup.get('maximumInstallmentsPerTransaction').setErrors(null);
            // this.formGroup.get('minimumTransactionValue').setValidators(null);
            // this.formGroup.get('minimumTransactionValue').setErrors(null);
        }
    };
    StoreQrcodeComponent.prototype.displaySegment = function (seg) {
        console.log(seg);
        return seg ? "" + seg : '';
    };
    StoreQrcodeComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                data = {
                    nomeFantasia: this.formGroup.value.nomeFantasia,
                    razaoSocial: this.formGroup.value.razaoSocial,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: '+55' + this.formGroup.value.phoneNumber,
                    openingDate: this.formGroup.value.openingDate,
                    liquidationDays: this.formGroup.value.liquidationDays,
                    mccCategory: this.formGroup.value.mccCategory,
                    mccDescription: this.formGroup.value.mccDescription,
                    taxCashback: this.convertPercentToNumber(this.formGroup.value.taxCashback),
                    taxAdministrative: this.convertPercentToNumber(this.formGroup.value.taxAdministrative),
                    taxRewardsTransaction: this.convertPercentToNumber(this.formGroup.value.taxRewardsTransaction),
                    taxCreditCardTransaction: this.convertPercentToNumber(this.formGroup.value.taxCreditCardTransaction),
                    pointsExpiration: this.formGroup.value.pointsExpiration,
                    street: this.formGroup.value.street,
                    number: this.formGroup.value.number,
                    neighborhood: this.formGroup.value.neighborhood,
                    city: this.formGroup.value.city,
                    state: this.formGroup.value.state,
                    zipCode: this.formGroup.value.zipCode,
                    complement: this.formGroup.value.complement,
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    maximumInstallmentsPerTransaction: this.formGroup.value.maximumInstallmentsPerTransaction,
                    // "maximumPointsPerTransaction": this.formGroup.value.maximumPointsPerTransaction,
                    minimumTransactionValue: this.formGroup.value.minimumTransactionValue * 100,
                    minimumInstallmentValue: this.formGroup.value.minimumInstallmentValue * 100,
                    pointsConversionFactorIn: this.formGroup.value.pointsConversionFactorIn * 100,
                    pointsConversionFactorOut: this.formGroup.value.pointsConversionFactorOut * 100,
                };
                if (this.cnpjSelected > 0) {
                    data.cnpj = this.formGroup.value.cnpj;
                }
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (this.cnpjSelected > 0) {
                    this.storeService.addStore(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.router.navigate(['./dashboard/stores']);
                            this.snackBar.open('Parceiro cadastrado com sucesso.');
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.storeService.updateStore(data, this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.router.navigate(['./dashboard/stores']);
                            this.snackBar.open('Parceiro atualizado com sucesso.');
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    StoreQrcodeComponent.prototype.changeIsMembershipActive = function (event) {
        this.isMembershipActive = event.value;
        if (!this.isMembershipActive) {
            this.formGroup.get('taxCashback').setValidators(null);
            this.formGroup.get('taxCashback').setErrors(null);
            this.formGroup.get('taxAdministrative').setValidators(null);
            this.formGroup.get('taxAdministrative').setErrors(null);
            this.formGroup.get('taxRewardsTransaction').setValidators(null);
            this.formGroup.get('taxRewardsTransaction').setErrors(null);
            this.formGroup.get('pointsConversionFactorIn').setValidators(null);
            this.formGroup.get('pointsConversionFactorIn').setErrors(null);
            this.formGroup.get('pointsConversionFactorOut').setValidators(null);
            this.formGroup.get('pointsConversionFactorOut').setErrors(null);
            this.formGroup.get('pointsExpiration').setValidators(null);
            this.formGroup.get('pointsExpiration').setErrors(null);
            // this.formGroup.get('maximumPointsPerTransaction').setValidators(null);
            // this.formGroup.get('maximumPointsPerTransaction').setErrors(null);
        }
        else {
            this.formGroup.get('taxCashback').setValidators([Validators.required]);
            this.formGroup.get('taxCashback').updateValueAndValidity();
            this.formGroup.get('taxAdministrative').setValidators([Validators.required]);
            this.formGroup.get('taxAdministrative').updateValueAndValidity();
            this.formGroup.get('taxRewardsTransaction').setValidators([Validators.required]);
            this.formGroup.get('taxRewardsTransaction').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required]);
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
            this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required]);
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
            this.formGroup.get('pointsExpiration').setValidators([Validators.required]);
            this.formGroup.get('pointsExpiration').updateValueAndValidity();
            // this.formGroup.get('maximumPointsPerTransaction').setValidators([Validators.required]);
            // this.formGroup.get('maximumPointsPerTransaction').updateValueAndValidity();
        }
    };
    StoreQrcodeComponent.prototype.loadAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var zipCode;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                zipCode = this.formGroup.controls.zipCode.value;
                if (zipCode.length === 8) {
                    this.zipCodeService.getAddress(zipCode).subscribe(function (address) {
                        _this.formGroup.patchValue({
                            state: address.uf,
                            city: address.localidade,
                            neighborhood: address.bairro,
                            street: address.logradouro,
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    StoreQrcodeComponent.prototype.convertPercentToNumber = function (value) {
        if (value) {
            return numeral(Number(value)).divide(100).value();
        }
        else {
            return (value = 0);
        }
    };
    return StoreQrcodeComponent;
}());
export { StoreQrcodeComponent };
