import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { moveItemInArray } from '@angular/cdk/drag-drop';
var SegmentationFormComponent = /** @class */ (function () {
    function SegmentationFormComponent(errorsService, formBuilder, segmentationService, snackBar, router, route, dialog, macroGroupsService) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.segmentationService = segmentationService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.macroGroupsService = macroGroupsService;
        this.headersTable = ['field', 'op', 'value', 'actions'];
        this.resultCols = [];
        this.resultColsKeys = [];
        this.filters = [];
        this.segmentationId = '';
        this.operators = ['=', '>', '>=', '<', '<=', '!=', 'like', 'Ordem'];
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.macroGroups = [];
        this.params = { formGroupValue: [] };
    }
    SegmentationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.segmentationId = this.route.snapshot.paramMap.get('id');
        this.initForm().catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, name_1, limit, filters, isMacroGroup;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.formGroup = this.formBuilder.group({
                            name: [null, [Validators.required]],
                            limit: [undefined, []],
                            field: [[], []],
                            op: [[], []],
                            value: [[], []],
                        });
                        if (!this.segmentationId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.segmentationService.find(this.segmentationId)];
                    case 1:
                        _a = _b.sent(), name_1 = _a.name, limit = _a.limit, filters = _a.filters;
                        this.formGroup.patchValue({ name: name_1, limit: limit });
                        this.filters = filters;
                        _b.label = 2;
                    case 2: return [4 /*yield*/, this.macroGroupsService.list().then(function (response) {
                            _this.macroGroups = response.results;
                        }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })
                        // await this.fetchResults()
                    ];
                    case 3:
                        _b.sent();
                        isMacroGroup = this.filters.filter(function (f) { return f.field === 'macroGroups'; });
                        this.filters.map(function (f) { return _this.macroGroups.filter(function (mg) {
                            if (mg.id === f.value) {
                                f.name = mg.macroName;
                            }
                        }); });
                        if (!isMacroGroup.length) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.fetchResultsMacroGroup()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, this.fetchResults()];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7:
                        this.filters = this.filters.map(function (f) { return ({
                            field: f.field,
                            fieldTitle: _this.resultCols.find(function (c) { return c.key === f.field; }).field,
                            fieldType: _this.resultCols.find(function (c) { return c.key === f.field; }).type,
                            op: f.op,
                            value: f.value,
                            name: f.name || undefined,
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    SegmentationFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                data = {
                    name: this.formGroup.value.name,
                    limit: this.formGroup.value.limit || undefined,
                    filters: this.filters.map(function (f) { return ({ field: f.field, op: f.op, value: f.value }); }),
                };
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (this.segmentationId) {
                    this.segmentationService
                        .edit(this.segmentationId, data)
                        .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.success) {
                                this.snackBar.open('Segmentação editada com sucesso.');
                                this.router.navigate(['dashboard', 'segmentation'], { queryParams: this.params })
                                    .catch(function (err) { return console.log(err); });
                            }
                            else {
                                this.snackBar.open('Falha ao editar a segmentação.');
                            }
                            return [2 /*return*/];
                        });
                    }); })
                        .catch(function (err) {
                        _this.snackBar.open(err.message);
                    });
                }
                else {
                    this.segmentationService
                        .create(data)
                        .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.success) {
                                this.snackBar.open('Segmentação criada com sucesso.');
                                this.router.navigate(['dashboard', 'segmentation'], { queryParams: this.params })
                                    .catch(function (err) { return console.log(err); });
                            }
                            else {
                                this.snackBar.open('Falha ao adicionar a segmentação.');
                            }
                            return [2 /*return*/];
                        });
                    }); })
                        .catch(function (err) {
                        _this.snackBar.open(err.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    SegmentationFormComponent.prototype.limitChanged = function () {
        var _this = this;
        this.fetchResults().catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationFormComponent.prototype.removeFilter = function (filter) {
        var _this = this;
        this.filters = this.filters.filter(function (f) { return f !== filter; });
        this.fetchResults().catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    SegmentationFormComponent.prototype.fetchResults = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, fields, data;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.limit = this.formGroup.value.limit;
                        return [4 /*yield*/, this.segmentationService.listResult(this.filters.map(function (f) { return ({ field: f.field, op: f.op, value: f.value }); }), 1, 10, this.limit)];
                    case 1:
                        _a = _b.sent(), fields = _a.fields, data = _a.data;
                        this.resultCols = fields;
                        this.resultColsKeys = fields.map(function (f) { return f.key; });
                        this.results = data.results;
                        this.pageTotal = data.total;
                        return [2 /*return*/];
                }
            });
        });
    };
    SegmentationFormComponent.prototype.selectedField = function (e) {
        if (e.value.key !== 'macroGroups') {
            this.formGroup.get('value').setValue(null);
            this.formGroup.get('value').updateValueAndValidity();
        }
    };
    SegmentationFormComponent.prototype.addFilter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter, isMacroGroup, dataFilter, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                filter = {
                    field: (this.formGroup.value.field || {}).key,
                    fieldTitle: (this.formGroup.value.field || {}).field,
                    fieldType: (this.formGroup.value.field || {}).type,
                    op: this.formGroup.value.op,
                    value: this.formGroup.value.value,
                };
                if (!filter.field ||
                    !filter.field.length ||
                    !filter.op ||
                    !filter.op.length ||
                    !filter.value ||
                    !filter.value.length) {
                    this.snackBar.open('Preencha corretamente os filtros e tente novamente.');
                    return [2 /*return*/, false];
                }
                isMacroGroup = this.filters.filter(function (f) { return f.field === 'macroGroups'; });
                if (this.formGroup.value.field.key === 'macroGroups' || isMacroGroup.length) {
                    this.formGroup.patchValue({ field: '', op: '', value: '' });
                    dataFilter = tslib_1.__spread(this.filters);
                    dataFilter.push(filter);
                    this.filters = dataFilter;
                    this.fetchResultsMacroGroup().catch(function (err) {
                        _this.snackBar.open(err.message);
                    });
                    this.filters.map(function (f) { return _this.macroGroups.filter(function (mg) {
                        if (mg.id === f.value) {
                            f.name = mg.macroName;
                        }
                    }); });
                }
                else {
                    this.formGroup.patchValue({ field: '', op: '', value: '' });
                    data = tslib_1.__spread(this.filters);
                    data.push(filter);
                    this.filters = data;
                    this.fetchResults().catch(function (err) {
                        _this.snackBar.open(err.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    SegmentationFormComponent.prototype.fetchResultsMacroGroup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, fields, data;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.segmentationService.listCustomersMacroGroup(this.filters.map(function (f) { return ({ field: f.field, op: f.op, value: f.value }); }), 1, 10, this.limit)];
                    case 1:
                        _a = _b.sent(), fields = _a.fields, data = _a.data;
                        this.resultCols = fields;
                        this.resultColsKeys = fields.map(function (f) { return f.key; });
                        this.results = data.results;
                        this.pageTotal = data.total;
                        return [2 /*return*/];
                }
            });
        });
    };
    SegmentationFormComponent.prototype.drop = function (event) {
        if (event.previousContainer !== event.container) {
            return;
        }
        var previousIndex = this.filters.findIndex(function (row) { return row === event.item.data; });
        var data = tslib_1.__spread(this.filters);
        moveItemInArray(data, previousIndex, event.currentIndex);
        this.filters = data;
    };
    SegmentationFormComponent.prototype.reciverFeedback = function (returnFilter) {
        this.resultCols = returnFilter.fields;
        this.resultColsKeys = returnFilter.fields.map(function (f) { return f.key; });
        this.results = returnFilter.data.results;
        this.pageTotal = returnFilter.data.total;
    };
    return SegmentationFormComponent;
}());
export { SegmentationFormComponent };
