import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup } from '@angular/forms';
var ModalConfirmationComponent = /** @class */ (function () {
    function ModalConfirmationComponent(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        console.log('data', data);
    }
    ModalConfirmationComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalConfirmationComponent.prototype.confirm = function () {
        this.dialogRef.close(1);
    };
    ModalConfirmationComponent.prototype.ngOnInit = function () {
    };
    return ModalConfirmationComponent;
}());
export { ModalConfirmationComponent };
