import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { DomSanitizer } from '@angular/platform-browser'
import moment from 'moment'
interface DialogData {
  text: string
  html: string
  buttonConfirmText: string
  buttonNot: string,
  bankAgency: string,
  bankNumber: string,
  bankAccountNumber: string,
  bankAccountDigit: string,
  dueDate: string,
}

@Component({
  selector: 'app-modal-manual-ted-request',
  templateUrl: './modal-manual-ted-request.component.html',
  styleUrls: ['./modal-manual-ted-request.component.scss'],
})
export class ModalManualTedRequestComponent implements OnInit {
  public formGroup: FormGroup
  public dueDateIsSameOrBeforeToday: boolean = false

  constructor (
    public dialogRef: MatDialogRef<ModalManualTedRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar,
    private readonly sanitizer: DomSanitizer,
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
  ) {
    console.log('data', data)
  }

  cancel (): void {
    this.dialogRef.close('not')
  }

  confirm () {
    this.dialogRef.close({
      bankAgency: this.formGroup.value.bankAgency,
      bankNumber: this.formGroup.value.bankNumber,
      bankAccountNumber: this.formGroup.value.bankAccountNumber,
      bankAccountDigit: this.formGroup.value.bankAccountDigit,      
      propagateChangesToStore: this.formGroup.value.propagateChangesToStore,
      scheduleToNextBusinessDay: this.formGroup.value.scheduleToNextBusinessDay,
    })
  }

  not () {
    this.dialogRef.close('not')
  }

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      bankAgency: [this.data.bankAgency, [Validators.required]],
      bankNumber: [this.data.bankNumber, [Validators.required]],
      bankAccountNumber: [this.data.bankAccountNumber, [Validators.required]],
      bankAccountDigit: [this.data.bankAccountDigit, [Validators.required]],      
      propagateChangesToStore: [false, []],
      scheduleToNextBusinessDay: [false, []],
    })

    console.log(this.data)
    this.dueDateIsSameOrBeforeToday = moment(this.data.dueDate).isSameOrBefore(moment(), 'day')
  }

  getSanitizedHtml (html) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  submit () {
    console.log()
  }
}
