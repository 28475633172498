<app-unlogged>
  <div class="change-password">
    <div class="change-password__logo">
      <img src="assets/images/logo.svg" alt="Logo JHSF ID" />
    </div>
    <div class="change-password__content">
      <form class="change-password__form" [formGroup]="formGroup" (ngSubmit)="submit()">

        <mat-form-field>
          <mat-label>Senha atual</mat-label>
          <input matInput formControlName="currentPassword" [type]="hide ? 'password' : 'text'" />
          <mat-error *ngIf="formGroup.controls.currentPassword?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.currentPassword) }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Nova senha</mat-label>
          <input matInput formControlName="newPassword" [type]="hide ? 'password' : 'text'" />
          <mat-error *ngIf="formGroup.controls.newPassword?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.newPassword) }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Confirmar nova senha</mat-label>
          <input matInput formControlName="confirmNewPassword" [type]="hide ? 'password' : 'text'" />
          <mat-error *ngIf="formGroup.controls.confirmNewPassword?.invalid">
            {{ errorsService.messageErrorFor(formGroup.controls.confirmNewPassword) }}
          </mat-error>
        </mat-form-field>

        <div class="change-password__actions">
          <button mat-button type="submit" class="btn-submit-dark">
            Alterar
          </button>
        </div>
      </form>
    </div>
  </div>
</app-unlogged>
