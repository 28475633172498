export enum JcoinsMovementState {
  'RESERVED' = 'RESERVADO',
  'DEDUCTED' = 'DEDUZIDO',
  'FAILED' = 'FALHOU',
  'FUTURE' = 'FUTURO',
  'COMPUTED' = 'COMPUTADO',
  'CANCELED' = 'CANCELADO',
  'EXPIRED' = 'EXPIRADO',
  'REVERSED' = 'ESTORNADO'

}
