import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(dashboardService, snackBar, dialog) {
        this.dashboardService = dashboardService;
        this.snackBar = snackBar;
        this.dialog = dialog;
    }
    HomeComponent.prototype.ngOnInit = function () {
        this.userRole = localStorage.getItem('userRole');
        // if (this.userRole === 'STOREKEEPER' || this.userRole === 'STOREKEEPER_SALES') {
        // this.dialog
        //   .open(ModalConfirmComponent, {
        //     width: '500px',
        //     data: {
        //       text:
        //         `Caro Parceiro,
        //           informamos que o programa JHSF ID MEMBERSHIP está em atualização técnica.
        //           Por esse motivo algumas oscilações na parte de transações e pagamentos podem ocorrer.
        //           Estamos atuando, e qualquer dúvida ou informação nossa equipe está à disposição.`,
        //       buttonConfirmText: 'Fechar',
        //     },
        //   })
        // }
        // descomenta aqui
        // this.userRole = localStorage.getItem('userRole')
        // if (this.userRole === 'ADMIN') {
        //   this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        //   this.storeID = this.userInfo.storeId
        // } else {
        //   const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
        //   this.storeID = storeSelected.storeId
        // }
        // this.transactionsMonth().catch(err => {
        //   this.snackBar.open(err.error)
        // })
        // this.transactionsYear().catch(err => {
        //   this.snackBar.open(err.error)
        // })
    };
    HomeComponent.prototype.createChart = function (el, cfg) {
        Highcharts.chart(el, cfg);
        Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.',
            },
        });
    };
    HomeComponent.prototype.transactionsMonth = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var myOptions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dashboardService
                            .transactionsMonth(this.storeID)
                            .then(function (response) {
                            _this.month = moment().format('MMMM');
                            myOptions = {
                                chart: {
                                    type: 'column',
                                },
                                title: {
                                    text: "Transa\u00E7\u00F5es de " + _this.month,
                                },
                                xAxis: {
                                    type: 'category',
                                    labels: {
                                        style: {
                                            fontSize: '13px',
                                            fontFamily: 'Verdana, sans-serif',
                                        },
                                    },
                                },
                                yAxis: {
                                    visible: false,
                                    enabled: false,
                                },
                                legend: {
                                    enabled: false,
                                },
                                tooltip: {
                                    pointFormat: "Total do m\u00EAs de " + _this.month + ": <b>{point.y:,2f}</b>",
                                },
                                credits: {
                                    enabled: false,
                                },
                                series: [
                                    {
                                        name: 'Valores das Transações',
                                        data: [
                                            ['Valores Cartão R$', Number(response.totalCard)],
                                            ['Quantidade de Jcoins', Number(response.totalJcoins)],
                                        ],
                                        dataLabels: [
                                            {
                                                enabled: true,
                                                inside: true,
                                                style: {
                                                    fontSize: '16px',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            };
                        })
                            .catch(function (err) {
                            _this.snackBar.open(err.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.createChart(this.chartM.nativeElement, myOptions)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HomeComponent.prototype.transactionsYear = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var myOptions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dashboardService
                            .transactionsYear(this.storeID)
                            .then(function (response) {
                            _this.year = moment().format('YYYY');
                            myOptions = {
                                chart: {
                                    zoomType: 'xy',
                                },
                                title: {
                                    text: "Transa\u00E7\u00F5es de " + _this.year,
                                },
                                xAxis: [
                                    {
                                        categories: [
                                            'Jan',
                                            'Fev',
                                            'Mar',
                                            'Abr',
                                            'Mai',
                                            'Jun',
                                            'Jul',
                                            'Ago',
                                            'Set',
                                            'Out',
                                            'Nov',
                                            'Dez',
                                        ],
                                        crosshair: true,
                                    },
                                ],
                                yAxis: [{ visible: false }, { visible: false }],
                                tooltip: {
                                    shared: true,
                                },
                                credits: {
                                    enabled: false,
                                },
                                series: [
                                    {
                                        name: 'Valor total',
                                        type: 'column',
                                        yAxis: 1,
                                        data: response.values,
                                    },
                                    {
                                        name: 'Quantidade de transações',
                                        type: 'spline',
                                        data: response.qtds,
                                    },
                                ],
                            };
                        })
                            .catch(function (err) {
                            _this.snackBar.open(err.error.message);
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.createChart(this.chartY.nativeElement, myOptions)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
