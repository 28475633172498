<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Banners</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/banner/{{ page }}/{{ idPage }}/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div *ngIf="page === 'category'"><b>Categoria: </b>{{ category }}</div>
      <div *ngIf="page === 'subcategory'"><b>Categoria: </b>{{ subcategory }}</div>
      <div *ngIf="page === 'home'"><b>Página: </b>{{ pages }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="banners">
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Ordem</th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>
      <ng-container matColumnDef="initAt">
        <th mat-header-cell *matHeaderCellDef>Data inicio</th>
        <td mat-cell *matCellDef="let element">{{ element.initAt | date: 'dd/MM/yyyy':'-300' }}</td>
      </ng-container>
      <ng-container matColumnDef="endAt">
        <th mat-header-cell *matHeaderCellDef>Data fim</th>
        <td mat-cell *matCellDef="let element">{{ element.endAt | date: 'dd/MM/yyyy':'-300' }}</td>
      </ng-container>
      <ng-container matColumnDef="isFixed">
        <th mat-header-cell *matHeaderCellDef>Fixo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isFixed" matTooltip="Fixo">check_circle_outline</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/banner/{{ element.id }}/{{ page }}/{{ idPage }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
