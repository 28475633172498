<div ly-dialog-content [lyPx]="1">
  <div>
    <button (click)="cropper.zoomIn()" ly-button appearance="icon">
      <ly-icon>zoom_in</ly-icon>
    </button>
    <button (click)="cropper.zoomOut()" ly-button appearance="icon">
      <ly-icon>zoom_out</ly-icon>
    </button>
    <button (click)="cropper.center()" ly-button appearance="icon">
      <ly-icon>filter_center_focus</ly-icon>
    </button>
    <!-- <button (click)="cropper.rotate(-90)" ly-button appearance="icon">
      <ly-icon>rotate_90_degrees_ccw</ly-icon>
    </button> -->
    <button (click)="cropper.fit()" ly-button>Fit</button>
    <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button>
  </div>

  <ly-img-cropper
    [config]="myConfig"
    [(scale)]="scale"
    (ready)="ready = true"
    (cleaned)="ready = false"
    (minScale)="minScale = $event"
    (cropped)="onCropped($event)"
    (loaded)="onLoaded($event)"
    (error)="onError($event)"
  >
    <span>Drag and drop image</span>
  </ly-img-cropper>

  <!--
  <div [className]="classes.sliderContainer">
    <div [class]="classes.slider">
      <ly-slider
        [thumbVisible]="false"
        [min]="minScale"
        [max]="1"
        [(ngModel)]="scale"
        (input)="scale = $event.value"
        step="0.000001"
      ></ly-slider>
    </div>
  </div> -->

  <div container [lyJustifyContent]="'flex-end'" ly-dialog-actions>
    <button ly-button color="primary" (click)="cancel()">CANCELAR</button>
    <button ly-button color="primary" (click)="close()">PRONTO</button>
  </div>
</div>
