import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ManualJcoinCreditService } from 'src/app/services/manual-jcoin-credit.service'
import { Location } from '@angular/common';
import { enumManualJcoinCredit } from 'src/app/models/enumManualJcoinCredit'
import { ModalReviewFormComponent } from '../modal-review-form/modal-review-form.component'

@Component({
  selector: 'manual-jcoin-credit-list',
  templateUrl: './list.html',
  styleUrls: ['./list.scss'],
})
export class ManualJcoinCreditListComponent implements OnInit {
  headersTable: string[] = [
    'customerName', 'storeName', 'requestedAt', 'reviewedAt', 'jcoinsQt', 'status', 'buttons'
  ]
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor(
    private readonly service: ManualJcoinCreditService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.list();

    this.fieldsForm = [
      {
        field: 'select',
        formControlName: 'manualJcoinCreditStatus',
        label: 'Status',
        list: [
          { key: '', value: 'Todos' },
          { key: enumManualJcoinCredit.PENDING, value: 'Pedente' },
          { key: enumManualJcoinCredit.APPROVED, value: 'Aprovado' },
          { key: enumManualJcoinCredit.DENIED, value: 'Reprovado' }
        ],
        optionDefault: '',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'manualJcoinCreditCreatedAtFrom',
        label: 'Solicitado a partir de',
        placeholder: 'dd/mm/yyyy',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'manualJcoinCreditCreatedAtTo',
        label: 'Até',
        placeholder: 'dd/mm/yyyy',
      },
    ]
  }

  async list() {
    const params = new URLSearchParams({
      page: this.page.toString(),
      pageSize: this.pageSize.toString()
    });
    const { results, pagination } = await this.service
      .list(params.toString())
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      });

    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = pagination.totalCount
    this.resultsAll = results
  }

  getStatusColor(status: string): string {
    switch (status) {
      case enumManualJcoinCredit.APPROVED:
        return 'green';
      case enumManualJcoinCredit.DENIED:
        return 'red';
      case enumManualJcoinCredit.PENDING:
      default:
        return 'grey';
    }
  }

  getStatusName(status: string): string {
    switch (status) {
      case enumManualJcoinCredit.APPROVED:
        return 'APROVADO';
      case enumManualJcoinCredit.DENIED:
        return 'REPROVADO';
      case enumManualJcoinCredit.PENDING:
        return 'PENDENTE';
      default:
        return 'DESCONHECIDO';
    }
  }

  getOriginName(name) {
    switch (name) {
      case 'JHSFID':
        return 'JHSF ID';
      case 'ARCHITECT':
        return 'ARQUITETO';
      case 'JHSFID_ARCHITECT':
        return 'JHSF ID + ARQUITETO';
      default:
        return name;
    }
  }

  openReviewModal(manualJcoinCredit): void {
    console.info(`Review modal opened for: ${manualJcoinCredit.id}`)
    const dialogRef = this.dialog.open(ModalReviewFormComponent, {
      width: '700px',
      data: {
        manualJcoinCredit: manualJcoinCredit,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      this.list();
    })
  }

  reciverFeedback(returnFilter) {
    this.returnedFilters = returnFilter.results
    this.pageTotal = returnFilter.pagination.totalCount
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  getStoreObject(element) {
    if (element.store) return element.store
    if (element.storeBusinessUnit) return element.storeBusinessUnit
  }
}
