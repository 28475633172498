import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var PreRegistrationAddressListComponent = /** @class */ (function () {
    function PreRegistrationAddressListComponent(route, preRegistrationService, snackBar, dialog, router) {
        this.route = route;
        this.preRegistrationService = preRegistrationService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.router = router;
        this.headersTable = ['addressTitle', 'address', 'kind', 'actions'];
        this.isAddress = false;
        this.params = { formGroupValue: [] };
    }
    PreRegistrationAddressListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID');
        this.type = this.route.snapshot.paramMap.get('type');
        // this.authService.customerUserInfo(this.customerID).subscribe(
        //   async response => {
        //     this.customer = response.firstName + ' ' + response.lastName
        //   },
        //   error => {
        //     this.snackBar.open(error.error.message)
        //   },
        // )
        this.clientAddressList();
    };
    PreRegistrationAddressListComponent.prototype.clientAddressList = function () {
        var _this = this;
        this.preRegistrationService.listAddress(this.preRegistrationID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isAddress = !!response.length;
                this.dataSource = new MatTableDataSource(response);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    PreRegistrationAddressListComponent.prototype.convertKind = function (kind) {
        switch (kind) {
            case 'HOME':
                return 'Residencial';
                break;
            case 'COMPANY':
                return 'Comercial';
                break;
            default:
                return '';
        }
    };
    PreRegistrationAddressListComponent.prototype.goBack = function () {
        if (this.type === 'architects') {
            this.router.navigate(['./dashboard/pre-registration-architects/architects']);
        }
        else if (this.type === 'INCORPORATION_AIRPORT') {
            this.router.navigate(['./dashboard/pre-registration/INCORPORATION_AIRPORT']);
        }
        else {
            this.router.navigate(['./dashboard/pre-registration']);
        }
    };
    PreRegistrationAddressListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse endereço?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.preRegistrationService.deleteAddress(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.clientAddressList();
                                this.snackBar.open('Endereço removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return PreRegistrationAddressListComponent;
}());
export { PreRegistrationAddressListComponent };
