<app-logged class="store-list">
  <header class="store-list__header">
    <h2>TRANSAÇÕES</h2>
    <div class="store-list__header__actions">
      <a class="btn-primary-light" mat-button (click)="goBack()"> VOLTAR </a>
    </div>
  </header>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSourceReserve">
      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nomeFantasia }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nameCustomer">
        <th mat-header-cell *matHeaderCellDef>Nome do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" matTooltip="CPF: {{ element.cpf | mask: '000.000.000-00' }}">
          <button mat-icon-button (click)="detail(element.transactionId, element.fullName, element.cpf)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="descriptionTransaction">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.descriptionPayment }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentDate">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.paymentDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="totalValueAnticipated">
        <th mat-header-cell *matHeaderCellDef>Valor Antecipado</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.reserveInCents) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor Total</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }">
          {{ convertStatusTransactions(element) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableReserve"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReserve"></tr>
    </table>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="transactionOrderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionOrderNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor Total</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValueTransaction) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            wateringconfrmationtransaction: element.state == 'WateringConfrmation',
            smalltext: element.state == 'PENDING' && element.isManual
          }">
          {{ convertStatusTransactions(element) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.type != 'CARD_MACHINE' && element.type != 'BILL_PAYMENT' && (element.state == 'REJECTED' || element.state == 'PENDING')" mat-icon-button
            matTooltip="Enviar notificação" (click)="openDialogSendNotification(element)" matTooltipPosition="left">
            <mat-icon>swap_horiz</mat-icon>
          </button>

          <button *ngIf="element.canBeCanceled && element.transactionId" mat-icon-button matTooltip="Cancelar"
            (click)="openDialogCancelTransaction(element)" matTooltipPosition="left">
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>

          <button *ngIf="!element.transactionId" mat-icon-button matTooltip="Criar transação"
            (click)="createTransaction(element)" matTooltipPosition="left">
            <mat-icon>swap_horiz</mat-icon>
          </button>

          <button *ngIf="element.transactionId" mat-icon-button matTooltip="Visualizar" (click)="view(element)"
            matTooltipPosition="left" routerLink="/dashboard/transactions/detail/{{ element.transactionId }}">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
