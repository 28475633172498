<app-logged>
  <header class="report-list__header">
    <h2>RELATÓRIO DE CAMPANHAS </h2>
    <div class="report-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores" skipLocationChange="true">
        VOLTAR
      </button>
    </div>
  </header>

  <div class="table-responsive">
    <h4>Campanhas Padrão</h4>
    <table mat-table [dataSource]="parsedDefaultCampaigns">
      <ng-container matColumnDef="CampaignId">
        <th mat-header-cell *matHeaderCellDef>ID Campanha</th>
        <td mat-cell *matCellDef="let element">{{ element.idCampaign }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignDesc">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="CampaignInitialDate">
        <th mat-header-cell *matHeaderCellDef>Data inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignInitialDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="macroGroup">
        <th mat-header-cell *matHeaderCellDef>MacroGroup</th>
        <td mat-cell *matCellDef="let element">{{ element.macroGroup }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableReportDefaultCampaignInfo"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReportDefaultCampaignInfo"></tr>
    </table>
  </div>

  <div class="table-responsive">
    <h4>Campanhas Ativas</h4>
    <table mat-table [dataSource]="parsedActiveCampaigns">
      <ng-container matColumnDef="CampaignId">
        <th mat-header-cell *matHeaderCellDef>ID Campanha</th>
        <td mat-cell *matCellDef="let element">{{ element.idCampaign }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignDesc">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="CampaignInitialDate">
        <th mat-header-cell *matHeaderCellDef>Data inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignInitialDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignEndDate">
        <th mat-header-cell *matHeaderCellDef>Data final</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignEndDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="macroGroup">
        <th mat-header-cell *matHeaderCellDef>MacroGroup</th>
        <td mat-cell *matCellDef="let element">{{ element.macroGroup }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableReportInfo"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReportInfo"></tr>
    </table>
  </div>

  <div class="table-responsive">
    <h4>Campanhas Inativas</h4>
    <table mat-table [dataSource]="parsedInactiveCampaigns">
      <ng-container matColumnDef="CampaignId">
        <th mat-header-cell *matHeaderCellDef>ID Campanha</th>
        <td mat-cell *matCellDef="let element">{{ element.idCampaign }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignDesc">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="CampaignInitialDate">
        <th mat-header-cell *matHeaderCellDef>Data inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignInitialDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignEndDate">
        <th mat-header-cell *matHeaderCellDef>Data final</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignEndDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="macroGroup">
        <th mat-header-cell *matHeaderCellDef>MacroGroup</th>
        <td mat-cell *matCellDef="let element">{{ element.macroGroup }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableReportInfo"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReportInfo"></tr>
    </table>
  </div>

  <div class="table-responsive">
    <h4>Campanhas Futuras</h4>
    <table mat-table [dataSource]="parsedFutureCampaigns">
      <ng-container matColumnDef="CampaignId">
        <th mat-header-cell *matHeaderCellDef>ID Campanha</th>
        <td mat-cell *matCellDef="let element">{{ element.idCampaign }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignDesc">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="CampaignInitialDate">
        <th mat-header-cell *matHeaderCellDef>Data inicial</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignInitialDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="CampaignEndDate">
        <th mat-header-cell *matHeaderCellDef>Data final</th>
        <td mat-cell *matCellDef="let element">{{ element.campaignEndDate | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="macroGroup">
        <th mat-header-cell *matHeaderCellDef>MacroGroup</th>
        <td mat-cell *matCellDef="let element">{{ element.macroGroup }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTableReportInfo"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReportInfo"></tr>
    </table>
  </div>
</app-logged>
