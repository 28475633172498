<app-logged class="fund-form">
  <header class="fund-form__header">
    <h2 *ngIf="!fundId">NOVO FUNDO</h2>
    <h2 *ngIf="fundId">EDITAR FUNDO</h2>
    <div class="fund-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/funds" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" mask="00.000.000/0000-00" />
              <mat-error *ngIf="formGroup.controls.cnpj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cnpj) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" type="email" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <div class="check">
              <input name="active" formControlName="active" type="checkbox" />
              <mat-label for="active"> &nbsp;Ativo</mat-label>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="fund-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
        &nbsp;
        <button type="button" mat-button color="warn" (click)="excluir()" *ngIf="fundId">
          Excluir
        </button>
      </div>
    </mat-card>
  </form>
</app-logged>
