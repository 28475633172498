<app-logged class="store-list">
  <header class="store-list__header">
    <h2>LISTA DE PERGUNTAS RESPONDIDAS</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/quiz/list" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div style="padding-bottom: 3px"><b>QUESTIONÁRIO: </b>{{ nameQuiz }}</div>
      <div><b>CLIENTE: </b>{{ fullName }}</div>
    </mat-card>
  </div>
  <div style="margin-bottom: 1rem" *ngFor="let answerAndQuetion of answersAndQuestions">
    <mat-card class="quizCard">
      <p class="question">{{ answerAndQuetion.ask }}</p>
      <p class="answer">
        Resposta: {{ convertAnswer(answerAndQuetion.view, answerAndQuetion.answer) }}
      </p>
    </mat-card>
  </div>
</app-logged>
