import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AuthService } from '../../../../services/auth.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryListComponent implements OnInit {
  public headersTable: string[] = ['name', 'cpf', 'nameBeneficiary', 'cpfBeneficiary', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'fullNameCustomerBeneficiary',
        label: 'Nome',
        placeholder: 'Nome',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfCustomerBeneficiary',
        label: 'CPF',
        placeholder: 'CPF',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'fullNameBeneficiary',
        label: 'Nome do Beneficiário',
        placeholder: 'Nome do Beneficiário',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cpfBeneficiary',
        label: 'CPF do Beneficiário',
        placeholder: 'CPF do Beneficiário',
      },
    ]
  }

  async list () {
    const { items, total } = await this.authService
      .clientsBeneficiaries(`?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSource = new MatTableDataSource(items)
    this.pageTotal = total
    this.resultsAll = items
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse beneficiário',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.clientsBeneficiaryDelete(id).then(
            async response => {
              this.list().catch(err => { console.log(err) })
              this.snackBar.open('Beneficiário removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.items)
    this.resultsAll = returnFilter.items
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
