import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import * as Highcharts from 'highcharts'
import { MatSnackBar, MatDialog } from '@angular/material'
import * as moment from 'moment'

import { DashboardService } from '../../../services/dashboard.service'
import { ModalConfirmComponent } from '../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  data: any
  month: any
  year: any
  storeID: any
  userRole: any
  userInfo: any

  @ViewChild('chartsMonth', { static: true }) public chartM: ElementRef
  @ViewChild('chartsYear', { static: true }) public chartY: ElementRef

  constructor (
    public dashboardService: DashboardService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.userRole = localStorage.getItem('userRole')

    // if (this.userRole === 'STOREKEEPER' || this.userRole === 'STOREKEEPER_SALES') {
    // this.dialog
    //   .open(ModalConfirmComponent, {
    //     width: '500px',
    //     data: {
    //       text:
    //         `Caro Parceiro,
    //           informamos que o programa JHSF ID MEMBERSHIP está em atualização técnica.
    //           Por esse motivo algumas oscilações na parte de transações e pagamentos podem ocorrer.
    //           Estamos atuando, e qualquer dúvida ou informação nossa equipe está à disposição.`,
    //       buttonConfirmText: 'Fechar',
    //     },
    //   })
    // }

    // descomenta aqui
    // this.userRole = localStorage.getItem('userRole')
    // if (this.userRole === 'ADMIN') {
    //   this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    //   this.storeID = this.userInfo.storeId
    // } else {
    //   const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
    //   this.storeID = storeSelected.storeId
    // }
    // this.transactionsMonth().catch(err => {
    //   this.snackBar.open(err.error)
    // })
    // this.transactionsYear().catch(err => {
    //   this.snackBar.open(err.error)
    // })
  }

  createChart (el, cfg) {
    Highcharts.chart(el, cfg)

    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    })
  }

  async transactionsMonth () {
    let myOptions
    await this.dashboardService
      .transactionsMonth(this.storeID)
      .then(response => {
        this.month = moment().format('MMMM')
        myOptions = {
          chart: {
            type: 'column',
          },
          title: {
            text: `Transações de ${this.month}`,
          },
          xAxis: {
            type: 'category',
            labels: {
              style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif',
              },
            },
          },
          yAxis: {
            visible: false,
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            pointFormat: `Total do mês de ${this.month}: <b>{point.y:,2f}</b>`,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'Valores das Transações',
              data: [
                ['Valores Cartão R$', Number(response.totalCard)],
                ['Quantidade de Jcoins', Number(response.totalJcoins)],
              ],
              dataLabels: [
                {
                  enabled: true,
                  inside: true,
                  style: {
                    fontSize: '16px',
                  },
                },
              ],
            },
          ],
        }
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })

    await this.createChart(this.chartM.nativeElement, myOptions)
  }

  async transactionsYear () {
    let myOptions
    await this.dashboardService
      .transactionsYear(this.storeID)
      .then(response => {
        this.year = moment().format('YYYY')
        myOptions = {
          chart: {
            zoomType: 'xy',
          },
          title: {
            text: `Transações de ${this.year}`,
          },
          xAxis: [
            {
              categories: [
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
                'Dez',
              ],
              crosshair: true,
            },
          ],
          yAxis: [{ visible: false }, { visible: false }],
          tooltip: {
            shared: true,
          },

          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'Valor total',
              type: 'column',
              yAxis: 1,
              data: response.values,
            },
            {
              name: 'Quantidade de transações',
              type: 'spline',
              data: response.qtds,
            },
          ],
        }
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })

    await this.createChart(this.chartY.nativeElement, myOptions)
  }
}
