import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AirportsService } from '../../../../../services/airports.service'
import { Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import * as XLSX from 'xlsx'
@Component({
  selector: 'app-documents-eligible-list',
  templateUrl: './documents-eligible.component.html',
  styleUrls: ['./documents-eligible.component.scss'],
})
export class DocumentsEligibleListComponent implements OnInit {
  public headersTable: string[] = ['prefix', 'actions']
  dataSource: any
  public formGroup: FormGroup
  public params: Params
  dynamicMask = ''
  reader: any
  file: any
  isImport = false
  prefixImport: any

  @ViewChild('inputFile', { static: false }) InputVar: ElementRef

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })
  }

  async list () {
    const items = await this.airportsService
      .documentsEligibles()
      .catch(err => {
        this.snackBar.open(err.message)
      })
    this.dataSource = new MatTableDataSource(items.results)
  }

  addMask (document) {
    const apenasNumeros = document.replace(/\D/g, '')
    if (apenasNumeros.length === 11) {
      return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else if (apenasNumeros.length > 11) {
      return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
    return apenasNumeros
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse prefixo da aeronave',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.documentsEligibleDelete(id).then(
            async response => {
              this.list().catch(err => { console.log(err) })
              this.snackBar.open('Prefixo da aeronave removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  async onFileChange (ev) {
    let workBook = null
    let jsonData = null
    this.reader = new FileReader()
    this.file = ev.target.files[0]
    if (this.file) {
      this.reader.onload = (event) => {
        const data = this.reader.result
        workBook = XLSX.read(data, { type: 'binary' })
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name]
          initial = XLSX.utils.sheet_to_json(sheet)
          return initial
        }, {})

        this.isImport = true

        this.prefixImport = jsonData.map(item => ({ prefix: item.PREFIXOS.toUpperCase() }))
        this.dataSource = new MatTableDataSource(this.prefixImport)
      }
      this.reader.readAsBinaryString(this.file)
    }
  }

  async saveFile () {
    this.airportsService.importimportPrefix({ prefixs: this.prefixImport }).then(
      async response => {
        if (response === true) {
          this.snackBar.open('Prefixos cadastrados com sucesso.')
          await this.router.navigate(['./dashboard/airports/not-eligible'])
          this.clear().catch(err => console.log(err))
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  async clear () {
    this.list().catch(err => { console.log(err) })
    this.isImport = false
    this.InputVar.nativeElement.value = ''
  }
}
