import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
var StoreManualTransactionFormComponent = /** @class */ (function () {
    function StoreManualTransactionFormComponent(errorsService, zipCodeService, formBuilder, storeService, transactionService, router, route, snackBar, currencyPipe) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.transactionService = transactionService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.states = [];
        this.plans = [];
        this.installments = [1];
        this.isValidTotalValue = false;
        this.isValidTotalValueMsg = '';
        this.headersTable = ['minimumTransactionValue'];
        this.transactionsInfo = [];
        this.subStores = [];
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', storeFormGroupValue: [] };
        this.initForm();
        this.userRole = localStorage.getItem('userRole');
    }
    StoreManualTransactionFormComponent.prototype.ngOnInit = function () { };
    StoreManualTransactionFormComponent.prototype.initForm = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
        });
        if (!this.storeID) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
            this.params.storeID = this.storeID;
        }
        this.formGroup = this.formBuilder.group({
            customerCpf: [
                null,
                [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
            ],
            totalValue: [null, [Validators.required]],
            description: [null, [Validators.required]],
            maximumInstallments: [1, [Validators.required]],
            isMembershipActive: [null, [Validators.required]],
            isPayActive: [null, [Validators.required]],
            documentUrl: [null, []],
            subStoreId: [null, []],
        });
        if (this.storeID != '' && this.storeID != null) {
            this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var e_1, _a, _b, _c, st;
                return tslib_1.__generator(this, function (_d) {
                    try {
                        for (_b = tslib_1.__values(response.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                            st = _c.value;
                            if (st.subStore.length) {
                                this.subStores.push(st.subStore[0]);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (this.subStores.length) {
                        this.subStores.sort(function (a, b) {
                            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0;
                        });
                    }
                    this.store = response;
                    this.formGroup.controls.isMembershipActive.setValue(this.store.isMembershipActive);
                    this.formGroup.controls.isPayActive.setValue(this.store.isPayActive);
                    this.transactionsInfo = [
                        {
                            minimumTransactionValue: this.store.minimumTransactionValue / 100,
                            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
                        },
                    ];
                    return [2 /*return*/];
                });
            }); });
        }
    };
    StoreManualTransactionFormComponent.prototype.selectFile = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = function (evt) {
                // called once readAsDataURL is completed
                _this.documentUrl = evt.target.result;
            };
            this.uploadData = new FormData();
            this.uploadData.append('file', event.target.files[0], event.target.files[0].name);
            this.approveDoc = event.target.files[0].name;
        }
        else {
            this.removeDocument();
        }
    };
    StoreManualTransactionFormComponent.prototype.imgLoadError = function () {
        this.documentUrl = null;
    };
    StoreManualTransactionFormComponent.prototype.removeDocument = function () {
        this.documentUrl = null;
        this.uploadData = null;
        this.approveDoc = undefined;
        this.fileInputBanner.nativeElement.value = '';
    };
    StoreManualTransactionFormComponent.prototype.changeValueTotal = function (event) {
        this.isValidTotalValue = false;
        if (this.formGroup.value.totalValue * 100 >= this.store.minimumTransactionValue) {
            this.isValidTotalValueMsg = "O valor m\u00EDnimo da transa\u00E7\u00E3o \u00E9 de R$ " + this.currencyPipe.transform(this.store.minimumTransactionValue, 'BRL');
            this.isValidTotalValue = true;
            this.calcTotalInstallments();
        }
    };
    StoreManualTransactionFormComponent.prototype.calcTotalInstallments = function () {
        this.installments = [1];
        for (var index = 2; index <= this.store.maximumInstallmentsPerTransaction; index++) {
            if ((this.formGroup.value.totalValue * 100) / index >= this.store.minimumInstallmentValue) {
                this.installments.push(index);
            }
        }
    };
    StoreManualTransactionFormComponent.prototype.convertCentsToMoney = function (value) {
        var money = this.currencyPipe.transform(value, 'BRL');
        // this.formGroup.controls['totalValue'].setValue(money);
        console.log(money);
    };
    StoreManualTransactionFormComponent.prototype.convertNumberToBoolean = function (num) {
        if (num == 0) {
            return false;
        }
        else {
            return true;
        }
    };
    StoreManualTransactionFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var totalValue, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.formGroup.value.totalValue * 100 < this.store.minimumTransactionValue) {
                    this.formGroup.controls.totalValue.setErrors({ transactionNotMatch: true });
                }
                else {
                    this.formGroup.controls.totalValue.setErrors(null);
                }
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '');
                data = {
                    customerCpf: this.formGroup.value.customerCpf,
                    totalValue: parseInt(totalValue),
                    description: this.formGroup.value.description,
                    maximumInstallments: this.formGroup.value.maximumInstallments
                        ? parseInt(this.formGroup.value.maximumInstallments)
                        : 0,
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    isManual: true,
                    approveDoc: this.approveDoc,
                    storeId: this.storeID,
                    subStoreId: this.formGroup.value.subStoreId,
                };
                this.transactionService.addTransaction(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var e_2;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.isCreated) return [3 /*break*/, 4];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.uploadToS3(response.approveDoc)];
                            case 2:
                                _a.sent();
                                this.finishCreate('Transação criada com sucesso.');
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.finishCreate('A transação foi criada, mas não foi possível salvar o documento de aprovação.');
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StoreManualTransactionFormComponent.prototype.finishCreate = function (message) {
        this.snackBar.open(message);
        if (this.userRole == 'ADMIN') {
            this.router.navigate(['./dashboard/stores/' + this.storeID + '/transactions/pending'], { queryParams: this.params, skipLocationChange: true });
        }
        else {
            this.router.navigate(['./dashboard/stores/' + this.storeID + '/transactions'], { queryParams: this.params, skipLocationChange: true });
        }
    };
    StoreManualTransactionFormComponent.prototype.uploadToS3 = function (s3Url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.uploadData) return [3 /*break*/, 2];
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                _this.transactionService.uploadAuthorizationDocument(s3Url, _this.uploadData).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    return tslib_1.__generator(this, function (_a) {
                                        resolve(response);
                                        return [2 /*return*/];
                                    });
                                }); }, function (error) {
                                    reject(error);
                                });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    return StoreManualTransactionFormComponent;
}());
export { StoreManualTransactionFormComponent };
