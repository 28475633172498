import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var UserColumns = [
    {
        key: 'cpf',
        type: 'text',
        label: 'CPF',
        required: true,
    },
    {
        key: 'firstName',
        type: 'text',
        label: 'FirstName',
        required: true,
    },
    {
        key: 'lastName',
        type: 'text',
        label: 'LastName',
        required: true,
    },
    {
        key: 'percentage',
        type: 'text',
        label: 'percentage',
        required: true,
    },
];
var ModalFormAddCustomerAirportCompanyComponent = /** @class */ (function () {
    function ModalFormAddCustomerAirportCompanyComponent(errorsService, snackBar, formBuilder, reportService, dialogRef, data) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.displayedColumns = UserColumns.map(function (col) { return col.key; });
        // columnsSchema: any = UserColumns
        this.dataSource = [
            {
                cpf: '',
                firstName: '',
                lastName: '',
                percentage: '',
            },
        ];
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        this.customerInvalid = false;
        this.beginDateInvalid = false;
        this.endDateInvalid = false;
        this.customers = [];
        this.id = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormAddCustomerAirportCompanyComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.data);
        this.formGroup = this.formBuilder.group({
            cpf1: [null, []],
            firstName1: [null, []],
            lastName1: [null, []],
            percentage1: [null, []],
            cpf2: [null, []],
            firstName2: [null, []],
            lastName2: [null, []],
            percentage2: [null, []],
            cpf3: [null, []],
            firstName3: [null, []],
            lastName3: [null, []],
            percentage3: [null, []],
            cpf4: [null, []],
            firstName4: [null, []],
            lastName4: [null, []],
            percentage4: [null, []],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
    };
    ModalFormAddCustomerAirportCompanyComponent.prototype._filter = function (customer) {
        var _this = this;
        if (!this.selectedCustomers.length) {
            var remove_1 = String(customer).replace(/[.-]/g, '');
            if (typeof remove_1 !== 'string') {
                return;
            }
            return this.customers
                .filter(function (c) { return !_this.selectedCustomers.includes(c); })
                .filter(function (c) {
                return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove_1.toLowerCase());
            })
                .slice(0, 20);
        }
    };
    ModalFormAddCustomerAirportCompanyComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    ModalFormAddCustomerAirportCompanyComponent.prototype.selected = function (event) {
        if (!this.selectedCustomers.length) {
            this.selectedCustomers.push(event.option.value);
            this.customerInput.nativeElement.value = '';
            this.customerControl.setValue(null);
            event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true);
        }
    };
    ModalFormAddCustomerAirportCompanyComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormAddCustomerAirportCompanyComponent.prototype.submit = function (cpf) {
        var _this = this;
        console.log(this.formGroup.value.firstName1);
        console.log(this.formGroup.value.firstName2);
        console.log(this.formGroup.value.firstName3);
        console.log(this.formGroup.value.firstName4);
        console.log(this.formGroup.value.lastName1);
        console.log(this.formGroup.value.lastName2);
        console.log(this.formGroup.value.lastName3);
        console.log(this.formGroup.value.lastName4);
        if (!cpf) {
            this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true);
            this.beginDateInvalid = !this.formGroup.value.beginDate;
            this.endDateInvalid = !this.formGroup.value.endDate;
            this.customersCPF = this.selectedCustomers.map(function (c) { return c.cpf; });
            if (this.beginDateInvalid || this.endDateInvalid || this.customerInvalid) {
                this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                return false;
            }
        }
        var filter = '';
        if (cpf) {
            if (this.formGroup.value.beginDate || this.formGroup.value.endDate) {
                this.beginDateInvalid = !this.formGroup.value.beginDate;
                this.endDateInvalid = !this.formGroup.value.endDate;
                if (this.beginDateInvalid || this.endDateInvalid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return false;
                }
                filter = "statement-customer?cpf=" + cpf + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                filter = "statement-customer?cpf=" + cpf;
            }
        }
        else {
            filter = "statement-customer?cpf=" + this.customersCPF + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        }
        this.reportService
            .createReport(filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Extrato por Cliente.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormAddCustomerAirportCompanyComponent;
}());
export { ModalFormAddCustomerAirportCompanyComponent };
