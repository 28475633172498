import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
var RegulationListComponent = /** @class */ (function () {
    function RegulationListComponent(regulationService, snackBar, dialog) {
        this.regulationService = regulationService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['heading', 'subtitle', 'createdAt', 'updatedAt', 'actions'];
        this.regulations = [];
    }
    RegulationListComponent.prototype.ngOnInit = function () {
        localStorage.removeItem('regulationsInfos');
        this.listRegulations();
    };
    RegulationListComponent.prototype.listRegulations = function () {
        var _this = this;
        this.regulationService.listRegulations().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.regulations = response.results;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    RegulationListComponent.prototype.viewRegulation = function (regulation) {
        localStorage.setItem('regulationsInfos', JSON.stringify(regulation));
    };
    RegulationListComponent.prototype.removeRegulation = function (regulationID) {
        var _this = this;
        this.regulationService.deleteRegulation(regulationID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.listRegulations();
                this.snackBar.open("Regulamento removido com sucesso.");
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    RegulationListComponent.prototype.openRemoveDialog = function (regulationID) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse regulamento?',
                buttonConfirmText: 'Remover'
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.removeRegulation(regulationID);
            }
        });
    };
    return RegulationListComponent;
}());
export { RegulationListComponent };
