import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { omitBy } from 'lodash'
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public loading

  constructor (private readonly http: HttpClient) { }

  listStoresByClosing (storeID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/closings/${storeID}/stores`).pipe(
      tap(_ => this.log('cms/closings/stores')),
      catchError(this.handleError),
    )
  }

  export (storeID, closingID): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/closings/${closingID}/stores/${storeID}/export`)
      .pipe(
        tap(_ => this.log('cms/closings/export')),
        catchError(this.handleError),
      )
  }

  listStores (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores${filter || ''}`).pipe(
      tap(_ => this.log('cms/stores')),
      catchError(this.handleError),
    )
  }

  filterStores (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/filter${filter || ''}`).pipe(
      tap(_ => this.log('cms/stores')),
      catchError(this.handleError),
    )
  }

  listUsers (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles`).pipe(
      tap(_ => this.log('cms/profiles')),
      catchError(this.handleError),
    )
  }

  listUsersCustomers (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/customers${filter || ''}`).pipe(
      tap(_ => this.log('cms/profiles/customers')),
      catchError(this.handleError),
    )
  }

  listUsersByStore (filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/sellers${filter || ''}`).pipe(
      tap(_ => this.log('cms/profiles')),
      catchError(this.handleError),
    )
  }

  getUserInfo (query): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/users/?${query}`).pipe(
      tap(_ => this.log('cms/profiles')),
      catchError(this.handleError),
    )
  }

  listUsersSellers (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/sellers?page=0&range=100`).pipe(
      tap(_ => this.log('cms/profiles')),
      catchError(this.handleError),
    )
  }

  addStore (data): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/cms/stores`,
        omitBy(data, p => p === ''),
    )
      .pipe(
        tap(_ => this.log('cms/stores')),
        catchError(this.handleError),
      )
  }

  addInternationalStore (data): Observable<any> {
    console.log(data)
    return this.http
      .post<any>(
        `${environment.apiUrl}/cms/international-stores`,
        omitBy(data, p => p === ''),
    )
      .pipe(
        tap(_ => this.log('cms/international-stores')),
        catchError(this.handleError),
      )
  }

  updateStore (data, storeID): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}/cms/stores/${storeID}`,
        omitBy(data, p => p === ''),
    )
      .pipe(
        tap(_ => this.log('cms/stores')),
        catchError(this.handleError),
      )
  }

  updateInternationalStore (data, storeID): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}/cms/international-stores/${storeID}`,
        omitBy(data, p => p === ''),
    )
      .pipe(
        tap(_ => this.log('cms/international-stores')),
        catchError(this.handleError),
      )
  }

  getStore (storeID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/` + storeID).pipe(
      tap(_ => this.log('cms/stores')),
      catchError(this.handleError),
    )
  }

  getStoreByCnpj (cnpj): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/search?cnpj=` + cnpj).pipe(
      tap(_ => this.log('cms/stores/search')),
      catchError(this.handleError),
    )
  }

  getStoreByDocument (document): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/international-stores/search?cnpj=` + document).pipe(
      tap(_ => this.log('cms/international-stores/search')),
      catchError(this.handleError),
    )
  }

  getStoreByCnpjOrName (term): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/auto-complete-search?searchTerm=` + term, { headers: {noloading: 'true'}}).pipe(
      tap(_ => this.log('cms/stores/auto-complete-search')),
      catchError(this.handleError),
    )
  }

  performChargeBack (
    body: { walletId: string, description: string }): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-type': 'application/json' }),
      observer: 'body',
      body: { description: body.description },
    }
    return this.http.delete<any>(`${environment.apiUrl}/cms/customers/jcoins/${body.walletId}`, options).pipe(
      tap(_ => this.log(`cms/customers/jcoins/${body.walletId}`)),
      catchError(this.handleError),
    )
  }

  getSegmentos (): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/segmentos`).pipe(
      tap(_ => this.log('cms/stores/segmentos')),
      catchError(this.handleError),
    )
  }

  async listMacroGroups (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/macro-groups`).toPromise()
  }

  async listSubStores (id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/${id}/sub-store`).toPromise()
  }

  async delete (id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cms/stores/${id}`).toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) { }
}
