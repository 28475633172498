import { Store } from 'src/app/models/store'
import { StoreService } from 'src/app/services/store.service'
import { ReportService } from '../../../../services/report.service'
import { PageEvent } from '@angular/material/paginator'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { Sort } from '@angular/material/sort'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'


import { Component, ViewChild, OnInit } from '@angular/core'

import { UtilService } from 'src/app/services/util.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { FormGroup } from '@angular/forms'
import JsFileDownloader from 'js-file-downloader'
import { PermissionsService } from 'src/app/services/permissions.service'

interface DialogData {
  cnpj: string
}
@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss'],
})
export class StoreListComponent implements OnInit {
  public formGroup: FormGroup;
  public isInternationalPartner: boolean | null = null;
  cnpj: string;
  headersTable: string[] = ['cnpj', 'nomeFantasia', 'actions'];
  dataSource: any;
  dataForm: any;
  fieldsForm: any;

  public pageTotal: number;

  public cmsPermissions = enumCmsPermissions
  page = 1;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  public params: any;

  pageEvent: PageEvent;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public permissionsService: PermissionsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public storeService: StoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public utilService: UtilService,
    public reportService: ReportService,
  ) { }

  ngOnInit() {
    this.params = {
      previousRoute: 'store-list',
    };
    this.route.queryParams.subscribe((params) => {
      if (params.storeFormGroupValue) {
        this.formGroup = JSON.parse(params.storeFormGroupValue);
        this.initForm();
      } else {
        this.listStores();
        this.initForm();
      }
    });
  }

  initForm() {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjStore',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'nomeFantasiaStore',
        label: 'Nome Fantasia',
        placeholder: 'Nome Fantasia',
      },
    ]
  }

  listStores() {
    this.storeService
      .filterStores(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .subscribe(
        async response => {
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  updateStatus() {
    this.listStores()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'cancel') {
        const { document, isInternationalPartner } = result;
        this.isInternationalPartner = isInternationalPartner;

        if (isInternationalPartner) {
          this.router.navigate(['./dashboard/stores/new-international/' + document]);
        } else {
          this.router.navigate(['./dashboard/stores/new/' + document]);
        }
      }
    });
  }

  remove(id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Parceiro?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.storeService.delete(id).then(
            async response => {
              this.listStores()
              this.snackBar.open('Parceiro removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback(returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      previousRoute: 'store-list',
      storeFormGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async report() {
    await this.reportService.storesWithMacroGroup().then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Parceiros com Macro grupos.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }

  navigateToEdit(element: any) {
    console.log('element', element)
    if (element.isInternational) {
      this.router.navigate(['/dashboard/international-store', element.id], { queryParams: this.params, skipLocationChange: true });
    } else {
      this.router.navigate(['/dashboard/store', element.id], { queryParams: this.params, skipLocationChange: true });
    }
  }
}
