import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { Params, Router } from '@angular/router'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { CompaniesService } from '../../../../../services/companies.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'architect-stores-list',
  templateUrl: './architect-stores-list.component.html',
  styleUrls: ['./architect-stores-list.component.scss'],
})
export class ArchitectStoresListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'nomeFantasia', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public companiesService: CompaniesService,
    public snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjArchitectStores',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'nomeFantasiaArchitectStores',
        label: 'Nome fantasia',
        placeholder: 'Nome fantasia',
      },
    ]
  }

  async list () {
    const { stores, TotalRegisters } = await this.companiesService
      .listStoresBusinessUnits(`?&page=${this.page}&limit=${this.pageSize}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { stores: [], TotalRegisters: 0 }
      })
    this.dataSource = new MatTableDataSource(stores)
    this.pageTotal = TotalRegisters
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Parceiro?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.companiesService.deleteStoresBusinessUnits(id).then(
            async response => {
              this.list().catch(error => console.log(error))
              this.snackBar.open('Parceiro removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  openDialog (): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.router.navigate(['./dashboard/architect-store/new/' + result])
      }
    })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter.stores
    this.pageTotal = returnFilter.TotalRegisters
    this.dataSource = new MatTableDataSource(returnFilter.stores)
    this.resultsAll = returnFilter.stores
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
