import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import numeral from 'numeral';
import { ModalChooseCampaignTypeComponent } from '../../../../components/modal-choose-campaign-type/modal-choose-campaign-type.component';
import { ModalPartnerEditionMacroBindingInformation } from '../../../../components/modal-partner-edition-macro-binding-information/modal-partner-edition-macro-binding-information.component';
import { enumInternationalCurrency } from 'src/app/models/enumInternationalCurrency';
import { ModalStoreBankAccountChangedComponent } from 'src/app/components/modal-store-bank-account-changed/modal-store-bank-account-changed.component';
var StoreIntFormComponent = /** @class */ (function () {
    function StoreIntFormComponent(errorsService, zipCodeService, formBuilder, storeService, router, route, snackBar, datePipe, configService, dialog, campaignService, liquidationTrasnferService, cdr) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.datePipe = datePipe;
        this.configService = configService;
        this.dialog = dialog;
        this.campaignService = campaignService;
        this.liquidationTrasnferService = liquidationTrasnferService;
        this.cdr = cdr;
        this.states = [];
        this.storeID = '';
        this.cnpjSelected = null;
        this.title = 'app';
        this.elementType = 'string';
        this.valueQrCode = '';
        this.segmentos = [];
        this.bankCodes = [];
        this.configs = [];
        this.params = { storeFormGroupValue: [] };
        this.campaigns = [];
        this.isMembershipActive = false;
        this.isPayActive = false;
        this.isCardPayment = false;
        this.isJcoinsPayment = true;
        this.macroGroups = [];
        this.groups = [];
        this.subStoreNone = [];
        this.subStoreNameInvalid = false;
        this.macroGroupIdInvalid = false;
        this.messageMacroGroupError = false;
        this.messageSubError = false;
        this.subAndMacroInvalid = false;
        this.isUpdate = false;
        this.enumInternationalCurrency = Object.values(enumInternationalCurrency);
        this.isInternational = false;
        this.initForm();
    }
    StoreIntFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadCampaigns();
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = localStorage.getItem('userRole');
    };
    StoreIntFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cnpjSelected = this.route.snapshot.paramMap.get('cnpj');
                        this.storeID = this.route.snapshot.paramMap.get('storeID');
                        this.isUpdate = !!this.storeID;
                        this.valueQrCode = this.storeID;
                        this.formGroup = this.formBuilder.group({
                            nomeFantasia: [null, [Validators.required]],
                            razaoSocial: [null, [Validators.required]],
                            complement: ['', []],
                            document: [
                                this.cnpjSelected,
                                [Validators.required, Validators.minLength(5)],
                            ],
                            phoneNumber: [null, [Validators.required]],
                            emailAddress: [null, [Validators.required]],
                            mcc: [null, []],
                            mccDescription: ['PARCEIRO INTERNATIONAL', []],
                            mccCategory: ['PARCEIRO INTERNATIONAL', []],
                            mccCode: ['000', []],
                            openingDate: [null, [Validators.required]],
                            liquidationDays: [30, [Validators.required]],
                            isMembershipActive: [true],
                            isPayActive: [false],
                            manualPaymentEnabled: [false],
                            futurePaymentEnabled: [false],
                            isConciergePaymentStore: [false],
                            taxCashback: [null, []],
                            internationalPartnerJcoinTransactionTax: [null, [Validators.required]],
                            taxAdministrative: [null, []],
                            taxRewardsTransaction: [null, [Validators.required]],
                            taxCreditCardTransaction: [null, []],
                            pointsConversionFactorIn: [null, []],
                            pointsConversionFactorOut: [null, [Validators.required]],
                            pointsExpiration: [null, []],
                            maximumInstallmentsPerTransaction: [null, []],
                            minimumInstallmentValue: [null, []],
                            minimumTransactionValue: [null, []],
                            street: [null, [Validators.required]],
                            number: [null, [Validators.required]],
                            neighborhood: [null, [Validators.required]],
                            city: [null, [Validators.required]],
                            state: [null, [Validators.required]],
                            zipCode: [null, [Validators.required, Validators.minLength(2), Validators.pattern('^[0-9]*$')]],
                            typePayment: ['jcoinsPayment', []],
                            isSubStore: [null, []],
                            macroGroup: [null, []],
                            subStores: this.formBuilder.array([this.createMacroGroupFormGroup()]),
                            bankCode: [null, []],
                            bankName: [null, [Validators.required]],
                            agency: [null, [Validators.required]],
                            accountNumber: [null, [Validators.required]],
                            accountDigit: [null, []],
                            accountType: [null, []],
                            isInternational: [true, []],
                            currency: [enumInternationalCurrency.USD, [Validators.required]],
                            zoopRevenue: [1000000, []],
                        });
                        if (this.cnpjSelected > 0) {
                            this.formGroup.get('document').enable();
                            this.loadInfosConfigs();
                        }
                        else {
                            this.formGroup.get('document').disable();
                        }
                        if (!(this.cnpjSelected > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getStoreByDocument()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.loadInfosStore()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.listMacroGroups();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreIntFormComponent.prototype.listMacroGroups = function () {
        var _this = this;
        this.storeService.listMacroGroups().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.macroGroups = response.results;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreIntFormComponent.prototype.changeIsSubStore = function (e) {
        var e_1, _a, e_2, _b;
        this.isSubStore = e;
        var subStores = this.formGroup.get('subStores');
        if (!e) {
            try {
                for (var _c = tslib_1.__values(subStores.value), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var s = _d.value;
                    s.subStoreName = null;
                    s.subStoreId = null;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                for (var _e = tslib_1.__values(subStores.controls), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var s = _f.value;
                    s.value.subStoreName = null;
                    s.value.subStoreId = null;
                    this.subStoreNone.push(s.value);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this.subStoreNameInvalid = false;
            this.macroGroupIdInvalid = false;
            this.subAndMacroInvalid = false;
            this.messageSubError = false;
        }
    };
    StoreIntFormComponent.prototype.keyPress = function (e) {
        var subStores = this.formGroup.get('subStores');
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName;
    };
    StoreIntFormComponent.prototype.validStoreName = function (e) {
        var subStores = this.formGroup.get('subStores');
        this.subStoreNameInvalid = !subStores.controls[0].value.subStoreName;
    };
    StoreIntFormComponent.prototype.changeMacroGroup = function (e) {
        var e_3, _a;
        var subStores = this.formGroup.get('subStores');
        try {
            for (var _b = tslib_1.__values(subStores.value), _c = _b.next(); !_c.done; _c = _b.next()) {
                var s = _c.value;
                s.id = !s.id ? null : s.id;
                s.macroGroupId = !s.macroGroupId ? null : s.macroGroupId;
                s.subStoreId = !s.subStoreId ? null : s.subStoreId;
                s.subStoreName = !s.subStoreName ? null : s.subStoreName;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.macroGroupIdInvalid = !subStores.controls[0].value.macroGroupId;
    };
    StoreIntFormComponent.prototype.addMacroGroupFormGroup = function (event) {
        event.preventDefault();
        var subStores = this.formGroup.get('subStores');
        this.subAndMacroInvalid = false;
        subStores.push(this.createMacroGroupFormGroup());
    };
    StoreIntFormComponent.prototype.removeOrClearMacroGroup = function (event, i) {
        event.preventDefault();
        var subStores = this.formGroup.get('subStores');
        if (i >= 1) {
            subStores.controls[i].value.subStoreName = null;
            subStores.controls[i].value.macroGroupId = null;
            subStores.controls[i].value.subStoreId = null;
            this.subStoreNone.push(subStores.controls[i].value);
            subStores.removeAt(i);
        }
        else {
            subStores.controls[i].value.subStoreName = null;
            subStores.controls[i].value.macroGroupId = null;
            subStores.controls[i].value.subStoreId = null;
            this.subStoreNone.push(subStores.controls[i].value);
            subStores.reset();
        }
    };
    StoreIntFormComponent.prototype.createMacroGroupFormGroup = function () {
        return new FormGroup({
            subStoreName: new FormControl(undefined),
            subStoreId: new FormControl(undefined),
            macroGroupId: new FormControl(undefined),
            id: new FormControl(undefined),
        });
    };
    StoreIntFormComponent.prototype.getStoreByDocument = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService.getStoreByDocument(this.cnpjSelected).toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.store = response;
                        this.isUpdate = !!response;
                        this.setInfosStore();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StoreIntFormComponent.prototype.loadInfosStore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.store = response;
                        this.setInfosStore();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StoreIntFormComponent.prototype.loadInfosConfigs = function () {
        var _this = this;
        this.configService.view().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.configs = response;
                this.formGroup.patchValue({
                    pointsExpiration: this.configs.results.jcoinsExpirationInDays,
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            alert(error.error.message);
        });
    };
    StoreIntFormComponent.prototype.setInfosStore = function () {
        var _this = this;
        var e_4, _a;
        console.log('store', this.store);
        if (typeof this.store !== 'object' || !this.store) {
            console.error('Store is not an object or is undefined:', this.store);
            return;
        }
        this.isSubStore = this.store.isSubStore;
        this.isMembershipActive = this.store.isMembershipActive;
        this.isPayActive = this.store.isPayActive ? this.store.isPayActive : false;
        this.isActiveRoadpass = this.store.isActiveRoadpass;
        this.isZoopEnabled = this.store.isZoopEnabled;
        this.idCampaign = this.store.idCampaign;
        this.descriptionCampaign = this.store.descriptionCampaign;
        this.isInternational = this.store.isInternational;
        this.typePayment = 'jcoinsPayment';
        var subStores = this.formGroup.get('subStores');
        this.groups = [];
        if (Array.isArray(this.store.subStoreAndMacroGroup)) {
            try {
                for (var _b = tslib_1.__values(this.store.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var sm = _c.value;
                    subStores.push(this.createMacroGroupFormGroup());
                    var mg = sm.macroGroup.map(function (mg) { return mg.id; });
                    var sb = sm.subStore.map(function (sb) { return sb.subStoreName; });
                    var sbId = sm.subStore.map(function (sb) { return sb.id; });
                    this.groups.push({
                        subStoreName: sb[0],
                        subStoreId: sbId[0],
                        macroGroupId: mg[0],
                        id: sm.id,
                    });
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        if (!this.store.mccCode) {
            var filterMcc = this.segmentos.filter(function (s) { return s.description === _this.store.mccDescription; });
            if (filterMcc.length) {
                this.store.mccCode = filterMcc[0].code;
            }
        }
        this.formGroup.patchValue({
            nomeFantasia: this.store.nomeFantasia,
            razaoSocial: this.store.razaoSocial,
            complement: this.store.complement != null ? this.store.complement : '',
            document: this.store.document,
            emailAddress: this.store.emailAddress,
            phoneNumber: this.store.phoneNumber,
            openingDate: this.store.openingDate.substring(0, 10),
            liquidationDays: this.store.liquidationDays,
            mcc: this.store.mccCategory + ' - ' + this.store.mccDescription,
            mccCategory: this.store.mccCategory,
            mccDescription: this.store.mccDescription,
            mccCode: this.store.mccCode,
            isMembershipActive: this.store.isMembershipActive,
            isPayActive: this.store.isPayActive,
            manualPaymentEnabled: this.store.manualPaymentEnabled,
            futurePaymentEnabled: this.store.futurePaymentEnabled,
            isConciergePaymentStore: this.store.isConciergePaymentStore || false,
            maximumInstallmentsPerTransaction: this.store.maximumInstallmentsPerTransaction,
            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
            taxCreditCardTransaction: numeral(this.store.taxCreditCardTransaction).multiply(100).value(),
            pointsExpiration: this.store.pointsExpiration,
            minimumTransactionValue: this.store.minimumTransactionValue / 100,
            pointsConversionFactorIn: this.store.pointsConversionFactorIn / 100,
            pointsConversionFactorOut: this.store.pointsConversionFactorOut / 100,
            taxCashback: numeral(this.store.taxCashback).multiply(100).value(),
            internationalPartnerJcoinTransactionTax: numeral(this.store.internationalPartnerJcoinTransactionTax).multiply(100).value(),
            taxAdministrative: numeral(this.store.taxAdministrative).multiply(100).value(),
            taxRewardsTransaction: numeral(this.store.taxRewardsTransaction).multiply(100).value(),
            street: this.store.street,
            number: this.store.number,
            neighborhood: this.store.neighborhood,
            city: this.store.city,
            state: this.store.state,
            zipCode: this.store.zipCode,
            typePayment: this.typePayment,
            isSubStore: this.store.isSubStore,
            subStores: this.groups,
            // Verificação ajustada para bankAccounts e bank
            bankName: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
                ? this.store.bankAccounts[0].bankName
                : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
                    ? this.store.bankAccounts.bankName
                    : this.store.bank
                        ? this.store.bank.bankName
                        : null,
            agency: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
                ? this.store.bankAccounts[0].agency
                : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
                    ? this.store.bankAccounts.agency
                    : this.store.bank
                        ? this.store.bank.agency
                        : null,
            accountNumber: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
                ? this.store.bankAccounts[0].accountNumber
                : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
                    ? this.store.bankAccounts.accountNumber
                    : this.store.bank
                        ? this.store.bank.accountNumber
                        : null,
            accountDigit: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
                ? this.store.bankAccounts[0].accountDigit
                : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
                    ? this.store.bankAccounts.accountDigit
                    : this.store.bank
                        ? this.store.bank.accountDigit
                        : null,
            accountType: Array.isArray(this.store.bankAccounts) && this.store.bankAccounts.length > 0
                ? this.store.bankAccounts[0].accountType
                : this.store.bankAccounts && typeof this.store.bankAccounts === 'object'
                    ? this.store.bankAccounts.accountType
                    : this.store.bank
                        ? this.store.bank.accountType
                        : null,
            isInternational: this.store.isInternational,
            currency: this.store.currency,
            zoopRevenue: this.store.zoopRevenue,
        });
        if (this.store.pointsConversionFactorIn) {
            this.formGroup.get('pointsConversionFactorIn').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorIn').updateValueAndValidity();
        }
        if (this.store.pointsConversionFactorOut) {
            this.formGroup.get('pointsConversionFactorOut').setValidators([Validators.required, Validators.min(1)]);
            this.formGroup.get('pointsConversionFactorOut').updateValueAndValidity();
        }
    };
    StoreIntFormComponent.prototype.displaySegment = function (seg) {
        return seg ? "" + seg : '';
    };
    StoreIntFormComponent.prototype.convertPercentToNumber = function (value) {
        if (value) {
            return numeral(Number(value)).divide(100).value();
        }
        else {
            return (value = 0);
        }
    };
    StoreIntFormComponent.prototype.loadCampaigns = function () {
        var _this = this;
        this.campaignService
            .listCampaigns()
            .subscribe(function (response) {
            _this.campaigns = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    StoreIntFormComponent.prototype.verifyFormValidation = function () {
        if (!this.formGroup.valid ||
            this.subStoreNameInvalid ||
            this.macroGroupIdInvalid ||
            this.subAndMacroInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        return true;
    };
    StoreIntFormComponent.prototype.openDialogCampaignType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var macroGroupsIds_1, defaultCampaignsList_1, extraCampaignsList_1, dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.verifyFormValidation()) {
                    if (this.isCurrencyInputValueValid()) {
                        if (this.storeID) {
                            macroGroupsIds_1 = [];
                            this.formGroup.get('subStores')
                                .value.map(function (macro) { return macroGroupsIds_1.push(macro.macroGroupId); });
                            this.campaignService.verifyStores({ macroGroupsIds: macroGroupsIds_1, storesId: this.storeID })
                                .toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var parsedBindedMacroList, dialogRef;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    parsedBindedMacroList = [];
                                    response.results.forEach(function (macro) {
                                        if (parsedBindedMacroList
                                            .includes(macro.participatingCampaignWithMacroGroup.macroGroups.id)) {
                                            macro.participatingCampaignWithMacroGroup.macroGroups = {};
                                        }
                                        else {
                                            parsedBindedMacroList
                                                .push(macro.participatingCampaignWithMacroGroup.macroGroups.id);
                                        }
                                        if (parsedBindedMacroList
                                            .includes(macro.participatingCampaignWithMacroGroup.campaigns.id)) {
                                            macro.participatingCampaignWithMacroGroup.campaigns = {};
                                        }
                                        else {
                                            parsedBindedMacroList
                                                .push(macro.participatingCampaignWithMacroGroup.campaigns.id);
                                        }
                                    });
                                    if (response.results.length > 0) {
                                        dialogRef = this.dialog.open(ModalPartnerEditionMacroBindingInformation, {
                                            width: '500px',
                                            data: response,
                                        });
                                        dialogRef.afterClosed().subscribe(function (result) {
                                            if (result !== 'cancel') {
                                                _this.submit().catch(function (err) { return console.log(err); });
                                            }
                                        });
                                    }
                                    else {
                                        this.submit().catch(function (err) { return console.log(err); });
                                    }
                                    return [2 /*return*/];
                                });
                            }); }, function (error) { return console.log(error); });
                        }
                        else {
                            defaultCampaignsList_1 = [];
                            extraCampaignsList_1 = [];
                            this.campaigns.map(function (campaign) {
                                if (campaign.isDefault) {
                                    defaultCampaignsList_1.push(campaign);
                                }
                                else {
                                    extraCampaignsList_1.push(campaign);
                                }
                            });
                            dialogRef = this.dialog.open(ModalChooseCampaignTypeComponent, {
                                width: '500px',
                                data: {
                                    campaigns: { default: defaultCampaignsList_1, extra: extraCampaignsList_1 },
                                },
                            });
                            dialogRef.afterClosed().subscribe(function (result) {
                                if (result !== 'cancel') {
                                    _this.campaignId = result.campaignId;
                                    _this.defaultCampaignId = result.defaultCampaignId;
                                    _this.submit().catch(function (err) { return console.log(err); });
                                }
                            });
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    StoreIntFormComponent.prototype.isCurrencyInputValueValid = function () {
        var parsedPointsConvertionFactorIn = Math.round(this.formGroup.value.pointsConversionFactorIn * 100);
        var parsedPointsConvertionFactorOut = Math.round(this.formGroup.value.pointsConversionFactorOut * 100);
        if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
            (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
            this.snackBar.open('Escolha um valor maior ou igual a R$1,00');
            return false;
        }
        return true;
    };
    StoreIntFormComponent.prototype.changeIsInternationalFlag = function (e) {
        this.isInternational = e.value;
    };
    StoreIntFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_5, _a, taxAdm, taxAdm, taxAdm, taxAdm, parsedMinimunTransactionValue, parsedMinimunInstallmentValue, parsedPointsConvertionFactorIn, parsedPointsConvertionFactorOut, data, unique, subStores_1, _b, _c, a, subStores;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                if (typeof this.formGroup.value.taxAdministrative === 'string') {
                    taxAdm = this.formGroup.value.taxAdministrative.replace(',', '.');
                    this.formGroup.value.taxAdministrative = Number(taxAdm);
                }
                if (typeof this.formGroup.value.taxRewardsTransaction === 'string') {
                    taxAdm = this.formGroup.value.taxRewardsTransaction.replace(',', '.');
                    this.formGroup.value.taxRewardsTransaction = Number(taxAdm);
                }
                if (typeof this.formGroup.value.taxCashback === 'string') {
                    taxAdm = this.formGroup.value.taxCashback.replace(',', '.');
                    this.formGroup.value.taxCashback = Number(taxAdm);
                }
                if (typeof this.formGroup.value.internationalPartnerJcoinTransactionTax === 'string') {
                    taxAdm = this.formGroup.value.internationalPartnerJcoinTransactionTax.replace(',', '.');
                    this.formGroup.value.internationalPartnerJcoinTransactionTax = Number(taxAdm);
                }
                parsedMinimunTransactionValue = Math.round(this.formGroup.value.minimumTransactionValue * 100);
                parsedMinimunInstallmentValue = Math.round(this.formGroup.value.minimumInstallmentValue * 100);
                parsedPointsConvertionFactorIn = Math.round(this.formGroup.value.pointsConversionFactorIn * 100);
                parsedPointsConvertionFactorOut = Math.round(this.formGroup.value.pointsConversionFactorOut * 100);
                if ((parsedPointsConvertionFactorIn > 0 && parsedPointsConvertionFactorIn < 100) ||
                    (parsedPointsConvertionFactorOut > 0 && parsedPointsConvertionFactorOut < 100)) {
                    this.snackBar.open('Escolha um valor maior ou igual a R$1,00');
                    return [2 /*return*/, false];
                }
                data = {
                    nomeFantasia: this.formGroup.value.nomeFantasia,
                    razaoSocial: this.formGroup.value.razaoSocial,
                    emailAddress: this.formGroup.value.emailAddress,
                    phoneNumber: this.formGroup.value.phoneNumber ? this.formGroup.value.phoneNumber : null,
                    openingDate: this.formGroup.value.openingDate,
                    liquidationDays: this.formGroup.value.liquidationDays,
                    mccCategory: this.formGroup.value.mccCategory,
                    mccDescription: this.formGroup.value.mccDescription,
                    mccCode: this.formGroup.value.mccCode,
                    taxCashback: this.convertPercentToNumber(this.formGroup.value.taxCashback),
                    internationalPartnerJcoinTransactionTax: this.convertPercentToNumber(this.formGroup.value.internationalPartnerJcoinTransactionTax),
                    taxAdministrative: this.convertPercentToNumber(this.formGroup.value.taxAdministrative),
                    taxRewardsTransaction: this.convertPercentToNumber(this.formGroup.value.taxRewardsTransaction),
                    taxCreditCardTransaction: this.convertPercentToNumber(this.formGroup.value.taxCreditCardTransaction),
                    pointsExpiration: this.formGroup.value.pointsExpiration,
                    street: this.formGroup.value.street,
                    number: this.formGroup.value.number,
                    neighborhood: this.formGroup.value.neighborhood,
                    city: this.formGroup.value.city,
                    state: this.formGroup.value.state,
                    zipCode: this.formGroup.value.zipCode,
                    complement: this.formGroup.value.complement,
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    manualPaymentEnabled: this.formGroup.value.manualPaymentEnabled,
                    futurePaymentEnabled: this.formGroup.value.futurePaymentEnabled,
                    isConciergePaymentStore: this.formGroup.value.isConciergePaymentStore,
                    maximumInstallmentsPerTransaction: this.formGroup.value.maximumInstallmentsPerTransaction
                        ? this.formGroup.value.maximumInstallmentsPerTransaction
                        : 0,
                    // "maximumPointsPerTransaction": this.formGroup.value.maximumPointsPerTransaction,
                    minimumTransactionValue: parsedMinimunTransactionValue,
                    minimumInstallmentValue: parsedMinimunInstallmentValue,
                    pointsConversionFactorIn: parsedPointsConvertionFactorIn,
                    pointsConversionFactorOut: parsedPointsConvertionFactorOut,
                    isCardPayment: this.isCardPayment,
                    isJcoinsPayment: this.isJcoinsPayment,
                    isJcoinsPriorityPayment: this.isJcoinsPriorityPayment,
                    macro: this.formGroup.value.subStores,
                    isSubStore: this.isSubStore,
                    bank: {
                        bankName: this.formGroup.value.bankName,
                        agency: this.formGroup.value.agency,
                        account: this.formGroup.value.accountNumber,
                        digit: this.formGroup.value.accountDigit,
                        accountType: this.formGroup.value.accountType,
                    },
                    defaultCampaignId: this.defaultCampaignId,
                    campaignId: this.campaignId,
                    document: '',
                    isInternational: this.formGroup.value.isInternational,
                    currency: this.formGroup.value.isInternational ? this.formGroup.value.currency : null,
                    zoopRevenue: this.formGroup.value.zoopRevenue
                };
                if (this.subStoreNone.length) {
                    data.macro = Array.from(new Set(data.macro.concat(this.subStoreNone)));
                }
                unique = Array.from(new Set(data.macro));
                data.macro = unique;
                if (this.isSubStore) {
                    subStores_1 = this.formGroup.get('subStores');
                    if (subStores_1.controls.length) {
                        this.subStoreNameInvalid = !subStores_1.controls[0].value.subStoreName;
                        this.macroGroupIdInvalid = !subStores_1.controls[0].value.macroGroupId;
                        try {
                            for (_b = tslib_1.__values(subStores_1.controls), _c = _b.next(); !_c.done; _c = _b.next()) {
                                a = _c.value;
                                if (a.value.subStoreName && !a.value.macroGroupId) {
                                    this.messageMacroGroupError = true;
                                }
                                else {
                                    this.messageMacroGroupError = false;
                                }
                                if (this.isSubStore && !a.value.subStoreName && a.value.macroGroupId) {
                                    this.messageSubError = true;
                                }
                                else {
                                    this.messageSubError = false;
                                }
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                    }
                    else {
                        this.subAndMacroInvalid = true;
                    }
                }
                if (this.cnpjSelected > 0) {
                    data.document = this.formGroup.value.document;
                }
                if (this.messageMacroGroupError) {
                    this.snackBar.open('Escolha um Macro Grupo para Sub-empresa');
                    return [2 /*return*/, false];
                }
                if (this.messageSubError) {
                    this.snackBar.open('Escolha uma Sub-empresa para o Macro Grupo');
                    return [2 /*return*/, false];
                }
                subStores = this.formGroup.get('subStores');
                this.macroGroupIdInvalid = !subStores.value.find(function () { return true; }).macroGroupId;
                if (!this.formGroup.value.futurePaymentEnabled &&
                    !this.formGroup.value.manualPaymentEnabled &&
                    !this.formGroup.value.isMembershipActive) {
                    this.snackBar.open('Escolher opção de configuração');
                    return [2 /*return*/, false];
                }
                this.verifyFormValidation();
                if (!this.storeID) {
                    this.storeService.addInternationalStore(data).subscribe(function (_) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.snackBar.open('Parceiro cadastrado com sucesso.');
                            this.router.navigate(['dashboard', 'stores']);
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.storeService.updateInternationalStore(data, this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.checkIfBankAccountChanged(response);
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        console.log(error.error.message);
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    StoreIntFormComponent.prototype.checkIfBankAccountChanged = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hasScheduledTransfers, dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response.isBankAccountChanged) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.liquidationTrasnferService.checkIfFutureSchedulesExistsForStore(this.storeID).toPromise()];
                    case 1:
                        hasScheduledTransfers = _a.sent();
                        if (hasScheduledTransfers) {
                            dialogRef = this.dialog.open(ModalStoreBankAccountChangedComponent, {
                                width: '500px',
                                data: response,
                            });
                            dialogRef.afterClosed().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(result === 'confirm')) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.liquidationTrasnferService.updateScheduledLiquidationTransfersBankAccount(this.storeID).toPromise()];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            this.onUpdateSuccess();
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        this.onUpdateSuccess();
                        return [2 /*return*/];
                }
            });
        });
    };
    StoreIntFormComponent.prototype.onUpdateSuccess = function () {
        this.snackBar.open('Parceiro atualizado com sucesso.');
        this.router.navigate(['dashboard', 'stores']);
    };
    StoreIntFormComponent.prototype.onChange = function (event) {
        this.formGroup.patchValue({
            mccCategory: event.category,
            mccDescription: event.description,
            mccCode: event.code,
        });
    };
    StoreIntFormComponent.prototype.updatePhoneInput = function () {
        this.cdr.detectChanges();
    };
    return StoreIntFormComponent;
}());
export { StoreIntFormComponent };
