import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var AirportCompanyFormComponent = /** @class */ (function () {
    function AirportCompanyFormComponent(errorsService, snackBar, formBuilder, airportsService, route, router) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.airportsService = airportsService;
        this.route = route;
        this.router = router;
    }
    AirportCompanyFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.preRegisterId = this.route.snapshot.paramMap.get('preRegisterId');
        this.formGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            cnpj: [null, [Validators.required]],
        });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
        if (this.preRegisterId) {
            this.findOnePreRegister().catch(function (err) { return console.log(err); });
        }
    };
    AirportCompanyFormComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneCompanyAirport(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                this.formGroup.patchValue({
                                    name: response.name,
                                    cnpj: response.cnpj,
                                });
                                this.formGroup.get('cnpj').disable();
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyFormComponent.prototype.findOnePreRegister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneCompanyAirportPreRegister(this.preRegisterId)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                this.formGroup.patchValue({
                                    name: response.name,
                                    cnpj: response.cnpj,
                                });
                                this.formGroup.get('cnpj').disable();
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    name: this.formGroup.value.name,
                    cnpj: this.formGroup.value.cnpj,
                };
                if (this.preRegisterId) {
                    this.airportsService.updatePreRegisterAirportCompany(this.preRegisterId, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/airport-companies'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Pre cadastro atualizado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                if (this.id) {
                    this.airportsService.updateAirportCompany(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/airport-companies'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Empresa Aeroporto atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else if (!this.preRegisterId) {
                    this.airportsService.createPreRegisterAirportCompany(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/airport-companies'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Empresa Aeroporto cadastrado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return AirportCompanyFormComponent;
}());
export { AirportCompanyFormComponent };
