import { Component, OnInit, Inject } from '@angular/core'
import { Store } from 'src/app/models/store'
import { StoreService } from 'src/app/services/store.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Router, ActivatedRoute } from '@angular/router'
import { CurrencyPipe, Location } from '@angular/common'
import JsFileDownloader from 'js-file-downloader'
interface DialogData {
  cnpj: string
}
@Component({
  selector: 'app-store-list',
  templateUrl: './closings-store-list.component.html',
  styleUrls: ['./closings-store-list.component.scss'],
})
export class ClosingsStoreListComponent implements OnInit {
  public headersTable: string[] = [
    'nomeFantasia',
    'totalValue',
    'netValue',
    'pointsApplied',
    'actions',
  ]

  public stores: Store[] = []

  public cnpj: string

  public closingsID: string

  constructor (
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public storeService: StoreService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public location: Location,
  ) {}

  ngOnInit () {
    this.closingsID = this.route.snapshot.paramMap.get('closingsID')
    this.listStores()
  }

  listStores () {
    this.storeService.listStoresByClosing(this.closingsID).subscribe(
      async response => {
        this.stores = response
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  export (storeID) {
    this.storeService.export(storeID, this.closingsID).subscribe(
      response => {
        if (response.report) {
          // eslint-disable-next-line no-new
          new JsFileDownloader({
            url: response.report,
            nameCallback: () => {
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              return (
                'Relatório de Fechamento por Parceiro.' +
                response.report.split('.').splice(6, 6).join('.')
              )
            },
          })
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  goBack () {
    this.location.back()
  }

  convertCentsToReal (value) {
    return value / 100
  }

  updateStatus () {
    this.listStores()
  }

  openDialog (): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '450px',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.router.navigate(['./dashboard/stores/new/' + result])
      }
    })
  }
}
