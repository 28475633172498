import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class MacroGroupsService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/macro-groups`).toPromise()
  }

  async create (body): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/macro-group`, body).toPromise()
  }

  async update (id: string, body): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/macro-group/${id}`, body).toPromise()
  }

  async findOne (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/macro-group/${id}`).toPromise()
  }

  async fetchMacroGroupPartners (macroGroupId: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/stores/macro-group?macroGroupIds=${macroGroupId}`).toPromise()
  }

  async delete (id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cms/macro-group/${id}`).toPromise()
  }
}
