import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ArchitectClosingsService {

  constructor (private readonly http: HttpClient) { }

  findAll({page, range}): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/architect-invoice-closings/?page=${page}&range=${range}`)
      .toPromise()
  }

  createClosing(data): Promise<any> {
    return this.http.post(`${environment.apiUrl}/cms/architect-invoice-closings/`, data).toPromise()
  }

  getClosing(closingId): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}`).toPromise()
  }

  removeClosing(closingId): Promise<any> {
    return this.http.delete(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}`).toPromise()
  }

  checkNDPayment(closingId, storeBusinessUnitId): Promise<any> {
    return this.http.put(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}/check-nd-paymnent/${storeBusinessUnitId}`,{}).toPromise()
  }

  checkNFPayment(closingId, storeBusinessUnitId): Promise<any> {
    return this.http.put(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}/check-nf-paymnent/${storeBusinessUnitId}`, {}).toPromise()
  }

  approveAllStoreBusinessInvoices(closingId, storeBusinessUnitId): Promise<any> {
    return this.http.put(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}/approve-invoices/${storeBusinessUnitId}`, {}).toPromise()
  }

  exportClosing(closingId): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}/export`).toPromise()
  }

  exportStoreClosing(closingId, storeBusinessUnitId): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/architect-invoice-closings/${closingId}/export/${storeBusinessUnitId}`).toPromise()
  }

  findAllClosingsByStore({page, range, storeBusinessUnitId}): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/architect-invoice-closings/store/${storeBusinessUnitId}?page=${page}&range=${range}`)
      .toPromise()
  }

  exportClosingByStore(closingId, storeBusinessUnitId): Promise<any> {
    return this.http.get(`${environment.apiUrl}/cms/architect-invoice-closings/store/${storeBusinessUnitId}/closing/${closingId}/export/`).toPromise()
  }

  getLateInvoicesForClosing(startDate) {
    return this.http.get(`${environment.apiUrl}/cms/architect-invoice-closings/late-invoices?startDate=${encodeURIComponent(startDate)}`).toPromise()
  }
  
}