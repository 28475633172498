<app-logged class="segmentation-list">
  <header class="segmentation-list__header">
    <h2>Resultado da Segmentação</h2>
    <div class="segmentation-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/segmentation" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>

  <app-form-filter maskExample="000.000.000-00" actions="form" type="segmentationResults"
    [segmentationId]="segmentationId" [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)">
  </app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category"><b>SEGMENTAÇÃO: </b>{{ name }}</mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef width="150px">CPF</th>
        <td mat-cell *matCellDef="let element">{{ element.cpf | mask: '000.000.000-00' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter actions="pagination" type="segmentationResults" [segmentationId]="segmentationId"
      [length]="pageTotal" [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)">
    </app-form-filter>
  </div>
</app-logged>
