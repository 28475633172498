import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormReceivablesComponent = /** @class */ (function () {
    function ModalFormReceivablesComponent(formBuilder, dialogRef, data, reportService, snackBar, errorsService, macroGroupsService) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.data = data;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.macroGroupsService = macroGroupsService;
        this.storeControl = new FormControl();
        this.macroGroupControl = new FormControl();
        this.selectedStores = [];
        this.selectedMacroGroups = [];
        this.filter = '';
        this.stores = [];
        this.visible = true;
        this.selectable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.storesUser = [];
    }
    ModalFormReceivablesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
        this.storesUser = JSON.parse(localStorage.getItem('storesUser'));
        this.userRole = localStorage.getItem('userRole');
        this.macroGroupsService
            .list()
            .then(function (response) {
            _this.macroGroups = response.results;
            console.log(_this.macroGroups);
            _this.filteredMacroGroups = _this.macroGroupControl.valueChanges.pipe(startWith(''), map(function (macroGroup) {
                return macroGroup ? _this._filteredMacroGroups(macroGroup) : _this.macroGroups.slice(0, 20);
            }));
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
        if (!this.storeSelected) {
            this.formGroup = this.formBuilder.group({
                beginDate: [null, [Validators.required]],
                endDate: [null, [Validators.required]],
                storesInput: [null, []],
                macroGroupsInput: [null, []],
            });
            this.reportService.listStores().subscribe(function (response) {
                response.map(function (item) {
                    _this.stores.push(item);
                });
            }, function (error) {
                console.log(error);
            });
            this.filteredStores = this.storeControl.valueChanges.pipe(startWith(''), map(function (store) { return (store ? _this._filterStores(store) : _this.stores.slice(0, 20)); }));
        }
        else {
            if (this.storesUser.length > 1) {
                this.formGroup = this.formBuilder.group({
                    beginDate: [null, [Validators.required]],
                    endDate: [null, [Validators.required]],
                    storesInput: [null, []],
                    macroGroupsInput: [null, []],
                });
                this.filteredStores = this.storeControl.valueChanges.pipe(startWith(''), map(function (store) {
                    return store ? _this._filterStoresStorekeeper(store) : _this.storesUser.slice(0, 20);
                }));
            }
            else {
                this.formGroup = this.formBuilder.group({
                    beginDate: [null, [Validators.required]],
                    endDate: [null, [Validators.required]],
                });
            }
        }
    };
    ModalFormReceivablesComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormReceivablesComponent.prototype._filteredMacroGroups = function (macroGroup) {
        var _this = this;
        var remove = String(macroGroup);
        if (typeof remove !== 'string') {
            return;
        }
        return this.macroGroups
            .filter(function (c) { return !_this.selectedMacroGroups.includes(c); })
            .filter(function (c) { return ("" + String(c.macroName)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormReceivablesComponent.prototype._filterStores = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.stores
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.nomeFantasia)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormReceivablesComponent.prototype._filterStoresStorekeeper = function (store) {
        var _this = this;
        var remove = String(store);
        if (typeof remove !== 'string') {
            return;
        }
        return this.storesUser
            .filter(function (c) { return !_this.selectedStores.includes(c); })
            .filter(function (c) { return ("" + String(c.storeName)).toLowerCase().includes(remove.toLowerCase()); })
            .slice(0, 20);
    };
    ModalFormReceivablesComponent.prototype.remove = function (value) {
        var index = this.selectedStores.indexOf(value);
        if (index >= 0) {
            this.selectedStores.splice(index, 1);
        }
    };
    ModalFormReceivablesComponent.prototype.removeMacroGroup = function (value) {
        var index = this.selectedMacroGroups.indexOf(value);
        if (index >= 0) {
            this.selectedMacroGroups.splice(index, 1);
        }
    };
    ModalFormReceivablesComponent.prototype.selected = function (event) {
        this.selectedStores.push(event.option.value);
        this.storeInput.nativeElement.value = '';
        this.storeControl.setValue(null);
    };
    ModalFormReceivablesComponent.prototype.selectedMacroGroup = function (event) {
        if (this.selectedMacroGroups.length < 1) {
            this.selectedMacroGroups.push(event.option.value);
            this.macroGroupInput.nativeElement.value = '';
            this.macroGroupControl.setValue(null);
        }
    };
    ModalFormReceivablesComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        if (this.userRole !== 'ADMIN') {
            if (this.storeSelected) {
                if (this.storesUser.length > 1) {
                    var storeIds = this.selectedStores.map(function (c) { return c.storeId; });
                    if (!storeIds.length) {
                        storeIds = this.storesUser.map(function (c) { return c.storeId; });
                    }
                    this.filter = "receivables-storekeeper?storesIds=" + storeIds + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
                }
                else {
                    this.filter = "receivables-storekeeper?storesIds=" + this.storeSelected.storeId + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
                }
            }
        }
        else {
            if (this.selectedStores) {
                var stores = this.selectedStores.map(function (c) { return c.id; });
                this.filter = "receivables?storesIds=" + stores + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                this.filter = "receivables?beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            if (this.selectedMacroGroups) {
                this.filter = "receivables?macroGroupId=" + this.selectedMacroGroups[0].id + "&beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
            else {
                this.filter = "receivables?beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
            }
        }
        this.reportService
            .createReport(this.filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Relatório de Recebíveis.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormReceivablesComponent;
}());
export { ModalFormReceivablesComponent };
