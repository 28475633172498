import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SegmentationService = /** @class */ (function () {
    function SegmentationService(http) {
        this.http = http;
    }
    SegmentationService.prototype.list = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/segmentations" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.listContexts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/segmentations/contexts")
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.listResult = function (filters, page, pageSize, limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/segmentations/results?filters=" + encodeURI(JSON.stringify(filters)) + "&page=" + String(page) + "&pageSize=" + String(pageSize) + "&limit=" + (limit || ''))
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.find = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/segmentations/" + id).toPromise()];
            });
        });
    };
    SegmentationService.prototype.reprocess = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/segmentations/" + id, {})
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.reprocessSegmentationAll = function (segmentationAll) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/segmentations/reprocess-segmentation-all", segmentationAll)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.create = function (segmentation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/segmentations", segmentation)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.edit = function (id, segmentation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/segmentations/" + id, segmentation)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/segmentations/" + id)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.listCustomers = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/segmentations/" + id + "/customers" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.addField = function (segmentationId, fields) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/segmentations/" + segmentationId + "/fields", fields)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.deleteField = function (segmentationId, fieldId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/segmentations/" + segmentationId + "/fields/" + fieldId)
                        .toPromise()];
            });
        });
    };
    SegmentationService.prototype.listCustomersMacroGroup = function (filters, page, pageSize, limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/segmentations/customers/macro-group?filters=" + encodeURI(JSON.stringify(filters)) + "&page=" + String(page) + "&pageSize=" + String(pageSize) + "&limit=" + (limit || ''))
                        .toPromise()];
            });
        });
    };
    SegmentationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SegmentationService_Factory() { return new SegmentationService(i0.ɵɵinject(i1.HttpClient)); }, token: SegmentationService, providedIn: "root" });
    return SegmentationService;
}());
export { SegmentationService };
