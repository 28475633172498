import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var PaymentProviderCardFeeListComponent = /** @class */ (function () {
    function PaymentProviderCardFeeListComponent(paymentProviderCardFeeService, snackBar, router, location) {
        this.paymentProviderCardFeeService = paymentProviderCardFeeService;
        this.snackBar = snackBar;
        this.router = router;
        this.location = location;
        this.headersTable = ['paymentProvider', 'cardBrand', 'startsAt', 'installments1', 'installments2to6', 'installments7to12', 'debit'];
        this.loadFeeList();
    }
    PaymentProviderCardFeeListComponent.prototype.ngOnInit = function () { };
    PaymentProviderCardFeeListComponent.prototype.loadFeeList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.paymentProviderCardFeeService.list()];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        this.dataSource = new MatTableDataSource(response);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.snackBar.open("Erro ao listar as taxas");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PaymentProviderCardFeeListComponent.prototype.onAddCardFeeClick = function () {
        this.router.navigateByUrl('/dashboard/payment-provider-card-fee-add');
    };
    PaymentProviderCardFeeListComponent.prototype.goBack = function () {
        this.location.back();
    };
    return PaymentProviderCardFeeListComponent;
}());
export { PaymentProviderCardFeeListComponent };
