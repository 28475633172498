import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Location } from '@angular/common'
import { AirportsService } from '../../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'

@Component({
  selector: 'app-pre-register-companies-airports',
  templateUrl: './pre-register-companies-airports.component.html',
  styleUrls: ['./pre-register-companies-airports.component.scss'],
})
export class preRegisterCompaniesAirportsComponent implements OnInit {
  // public headersTable: string[] = ['cnpj', 'name', 'balance', 'actions']
  public headersTable: string[] = ['cnpj', 'name', 'balance']
  public airport: any[] = []

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.listAirports()
  }

  listAirports () {
    this.airportsService.preRegisterCompaniesAirport().then(
      response => {
        this.airport = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Aeroporto?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.deleteAirport(id).then(
            async response => {
              this.listAirports()
              this.snackBar.open('Aeroporto removida com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  async report () {
    await this.reportService.preRegistrationCompaniesAirports().then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Relatório de Pré Cadastro CNPJ.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }
}
