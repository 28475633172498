import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms'
import { MatSnackBar } from '@angular/material'
import * as queryString from 'query-string'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { AuthService } from 'src/app/services/auth.service'

import { CurrencyPipe } from '@angular/common'
import { ReportService } from 'src/app/services/report.service'
import { User } from 'src/app/models/user'

@Component({
  selector: 'app-store-sells-report',
  templateUrl: './store-sells-report.component.html',
  styleUrls: ['./store-sells-report.component.scss'],
})
export class StoreSellsReportComponent implements OnInit {
  public formGroup: FormGroup
  public isReportToClient = false
  public userInfo: User
  public storeSelected: any
  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public authService: AuthService,
    private readonly snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
  ) {
    this.initForm()
  }

  ngOnInit () {
    this.storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  }

  initForm () {
    this.formGroup = this.formBuilder.group(
      {
        initialDate: [
          null,
          [Validators.required, this.dateValidator({ dateMax: new Date() })], // today
        ],
        finalDate: [new Date().toISOString().slice(0, 10), [Validators.required]],
      },
      { validator: [this.formValidator.bind(this)] },
    )
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      endDate: this.formGroup.controls.finalDate.value,
      startDate: this.formGroup.controls.initialDate.value,
      storeId: this.storeSelected.storeId,
    }

    const query = queryString.stringify(data)

    this.reportService.storeSellsReport(query).subscribe(
      async response => {
        this.downloadFile(response)
      },
      error => {
        // o backend retorna um http status 302, e o browser automaticamente tenta redirecionar pra url do pdf
        // isso resulta nesse erro, pois está sendo enviado pro AWS todos os nossos headers, e entao ocorre um erro 400
        // aqui é verificado se a url do erro é a do aws, se sim ele simplesmente abre uma janela com a url, e funciona
        if (error.url && error.url.indexOf('amazonaws') != -1) {
          this.downloadFile(error.url)
        } else {
          this.snackBar.open(error.error.message)
        }
      },
    )
  }

  downloadFile (url) {
    window.open(url, '_blank', 'width=800,height=500,menubar=no,location=no')
  }

  dateValidator (prms: any = {}): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const val: Date = new Date(control.value)
      console.log(val)
      return this.validateDate(val, prms)
    }
  }

  formValidator (form: FormGroup): { [key: string]: boolean } {
    console.log('formvalidator')
    const finalDateControl = form.controls.finalDate
    const initialDateControl = form.controls.initialDate

    if (finalDateControl && initialDateControl) {
      const finalDate = new Date(finalDateControl.value)
      const initialDate = new Date(initialDateControl.value)

      const res = this.validateDate(finalDate, { dateMin: initialDate })
      if (res != null) finalDateControl.setErrors(res)
      else finalDateControl.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    }

    return null
  }

  validateDate (val, prms) {
    if (!(prms.dateMin instanceof Date) && !(prms.dateMax instanceof Date)) {
      return null
    } else if (prms.dateMin instanceof Date) {
      return val < prms.dateMin ? { dateMin: true } : null
    } else if (prms.dateMax instanceof Date) {
      return val > prms.dateMax ? { dateMax: true } : null
    } else {
      return null
    }
  }
}
