import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component';
import { ModalCustomerInfoComponent } from 'src/app/components/modal-customer-info/modal-customer-info.component';
import * as _ from 'lodash';
import { ModalConfirmationComponent } from '../../../../components/modal-confirmation/modal-confirmation.component';
import { ModalRejectComponent } from '../../../../components/modal-reject/modal-reject.component';
import { ModalInstallmentsComponent } from 'src/app/components/modal-installments/modal-installments.component';
import numeral from 'numeral';
import { ModalRoadpassUnableToCancel } from 'src/app/components/modal-roadpass-unable-to-cancel/modal-roadpass-unable-to-cancel.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import moment from 'moment';
var StoreTransactionDetailComponent = /** @class */ (function () {
    function StoreTransactionDetailComponent(errorsService, zipCodeService, formBuilder, storeService, router, route, snackBar, datePipe, transactionService, dialog, location, fundService) {
        this.errorsService = errorsService;
        this.zipCodeService = zipCodeService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.datePipe = datePipe;
        this.transactionService = transactionService;
        this.dialog = dialog;
        this.location = location;
        this.fundService = fundService;
        this.headersTable = [];
        this.transactionsInfo = [];
        this.isInternational = false;
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] };
        this.internationalTransactionData = [];
        this.headerInternationalTransaction = ['currency', 'totalValue', 'totalValueWithTaxes', 'currencyQuote', 'purchaseFeePercent', 'purchaseFeeValue'];
        this.headersTableNonPartner = ['nomeFantasiaWithoutStore', 'cnpjWithoutStore'];
        this.transactionsInfoGeneral = [];
        this.transactionsInfoNonPartner = [];
        this.liveloData = [];
        this.headersLiveloData = ['id', 'points'];
        this.headersTableNfs = [
            'type',
            'totvsCode',
            'verificationCode',
            'createdAt',
        ];
        this.nfs = [];
        this.headersTableConfigs = [
            'pointsConversionFactorOut',
            'pointsConversionFactorIn',
            'maximumInstallmentsPerTransaction',
            'minimumTransactionValue',
        ];
        this.transactionsInfoConfig = [];
        this.headersTableInfosCard = [
            'netValue',
            'installments',
            'cardNumber',
            'cardBrand',
            'rewards',
            'cashbackValue',
        ];
        this.headersTableInfosCardTwo = [
            'taxAdministrative',
            'taxAdministrativeAmount',
            'storeSplitAmount',
            'paymentProvider',
        ];
        this.headersTableInfosCardThree = [
            'isInternational',
            'isVirtualCard',
            'isAdditionalCard',
        ];
        this.transactionsInfoCard = [];
        this.transactionsInfoCardTwo = [];
        this.transactionsInfoCardThree = [];
        this.headersTableInfosJC = [
            'pointsApplied',
            'discountValue',
            'taxRewardsTransaction',
            'taxRewardsTransactionAmount',
            'storeTransferAmount',
        ];
        this.headersTableLiquidationDetailOne = [
            'isAdministrativeTaxPaid',
            'administrativeTaxPaidAt',
            'administrativeTaxDueDate',
            'scheduleLink'
        ];
        this.headersTableLiquidationDetailTwo = [
            'isStoreTransferPaid',
            'storeTransferPaidAt',
            'storeTransferDueDate',
            'scheduleLink'
        ];
        this.transactionsInfoJC = [];
        this.funds = [];
        this.recipientCustomer = [];
        this.states = [];
        this.storeID = '';
        this.transactionID = '';
        this.fundId = '';
        this.isManual = false;
        this.title = 'app';
        this.elementType = 'string';
        this.valueQrCode = '';
        this.isMembershipActive = false;
        this.isPayActive = false;
        this.isAdmin = false;
        this.showCancelButton = false;
        this.showRejectButton = false;
        this.isNonPartner = false;
        this.isAdditionalCard = false;
        this.isVirtualCard = false;
        this.initForm();
    }
    StoreTransactionDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = localStorage.getItem('userRole');
        this.isAdmin = this.userRole == 'ADMIN';
        this.route.queryParams.subscribe(function (params) {
            console.log(params);
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
            _this.params.itComesFromNewTransaction = params.itComesFromNewTransaction;
        });
        // this.params.pageTotal = Number(this.route.snapshot.queryParamMap.get('pageTotal'))
        // this.params.dataSource = this.route.snapshot.queryParamMap.getAll('dataSource')
    };
    StoreTransactionDetailComponent.prototype.initForm = function () {
        var _this = this;
        // this.storeID = this.route.snapshot.paramMap.get('storeID')
        this.transactionID = this.route.snapshot.paramMap.get('transactionID');
        this.valueQrCode = this.storeID;
        this.isManual = JSON.parse(localStorage.getItem('isManual'));
        var filter = '';
        filter = this.transactionID + "?showManualDetails=true";
        this.transactionService.listTransactionsById(filter).subscribe(function (response) {
            _this.type = response.type;
            var taxRewardsTransaction = numeral(response.taxRewardsTransaction || 0).multiply(100).value();
            var taxAdministrative = numeral(response.taxAdministrative || 0).multiply(100).value();
            _this.subStores(response.storeId);
            if (response.recipientCustomerId) {
                _this.recipientCustomerId = response.recipientCustomerId;
                _this.headersTableCustomerRecipient = ['recipient'];
                _this.recipientCustomer = [
                    {
                        recipientCustomerBalance: response.recipientCustomerBalance,
                        recipientFullName: response.recipientFullName,
                        recipientCpf: response.recipientCpf,
                        recipientBirthDate: response.recipientBirthDate,
                        recipientEmailAddress: response.recipientEmailAddress,
                        recipientPhoneNumber: response.recipientPhoneNumber,
                        recipientGender: response.recipientGender,
                        recipientCustomerId: response.recipientCustomerId,
                    },
                ];
            }
            if (response.fundId) {
                _this.fundId = response.fundId;
                _this.headersTableFunds = ['fundName', 'document'];
                _this.funds = [
                    {
                        fundName: response.fundName,
                        fundDocument: response.customerFundDocument,
                        fundId: response.fundId,
                    },
                ];
            }
            _this.isInternational = response.isInternational;
            _this.isManual = response.isManual;
            _this.isAdditionalCard = response.isAdditionalCard;
            _this.isVirtualCard = response.isVirtualCard;
            if (_this.isManual && _this.isAdmin) {
                _this.showRejectButton = response.state == 'PENDING';
            }
            if (!_this.isManual || (_this.isManual && response.state == 'APPROVED')) {
                _this.showCancelButton = response.canBeCanceled;
            }
            if (_this.isManual) {
                _this.headersTable = ['orderNumber', 'totalValue', 'pointsApplied', 'state', 'actions'];
                if (_this.type === 'MEMBERSHIP') {
                    if (_this.isAdmin) {
                        _this.headersTableGeneral = [
                            'storeName',
                            'subStore',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'membershipPlan',
                            'approveDoc',
                        ];
                    }
                    else {
                        _this.headersTableGeneral = [
                            'storeName',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'membershipPlan',
                            'approveDoc',
                        ];
                    }
                }
                else {
                    if (_this.isAdmin) {
                        _this.headersTableGeneral = [
                            'storeName',
                            'subStore',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'approveDoc',
                        ];
                    }
                    else {
                        _this.headersTableGeneral = [
                            'storeName',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'approveDoc',
                        ];
                    }
                }
                if (_this.isAdmin) {
                    _this.balance = _.get(response, 'manualDetails.balance', 0);
                    _this.headersTableGeneral.splice(_this.headersTableGeneral.length - 1, 0, 'balance');
                }
                var userName = '';
                var canceledAt = void 0;
                if (response.cmsUser) {
                    userName = response.cmsUser.firstName + " " + response.cmsUser.lastName;
                    canceledAt = moment(response.canceledAt).format("DD/MM/YYYY HH:mm:ss");
                }
                _this.pointsApplied = Math.trunc(response.totalValue / response.pointsConversionFactorOut);
                _this.transactionsInfo = [
                    {
                        id: response.id,
                        reason: response.status,
                        orderNumber: response.orderNumber,
                        totalValue: response.totalValue / 100,
                        canBeCanceled: response.canBeCanceled,
                        isManual: response.isManual,
                        state: response.isManual && response.state,
                        canceledByUser: userName,
                        canceledAt: canceledAt,
                        rejectReason: _.get(response, 'manualDetails.reason')
                            ? response.manualDetails.reason
                            : '',
                        pointsApplied: response.pointsApplied,
                        type: response.type
                    },
                ];
                _this.transactionsInfoGeneral = [
                    {
                        storeName: response.storeName,
                        storeNomeFantasia: response.storeNomeFantasia,
                        description: response.description,
                        createdAt: response.createdAt,
                        customer: {
                            customerFullName: response.customerFirstName + " " + response.customerLastName,
                            customerCpf: response.customerCpf,
                            customerBirthDate: response.customerBirthDate,
                            customerPhoneNumber: response.customerPhoneNumber,
                            customerEmailAddress: response.customerEmailAddress,
                            customerBalance: response.customerBalance,
                            customerGender: response.customerGender,
                            customerId: response.customerId,
                        },
                        balance: _.get(response, 'manualDetails.balance')
                            ? response.manualDetails.balance
                            : 0,
                        approveDoc: _.get(response, 'manualDetails.approveDoc')
                            ? response.manualDetails.approveDoc
                            : '',
                        membershipPlan: response.membershipPlan,
                        subStore: response.subStoreId,
                    },
                ];
                _this.subStoreId = response.subStoreId;
            }
            else {
                if (response.cmsUser) {
                    _this.headersTable = ['orderNumber', 'totalValue', 'state', 'canceledByUser', 'canceledAt', 'actions'];
                }
                else {
                    _this.headersTable = ['orderNumber', 'totalValue', 'state', 'actions'];
                }
                if (_this.type === 'MEMBERSHIP') {
                    if (_this.isAdmin) {
                        _this.headersTableGeneral = [
                            'storeName',
                            'subStore',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'membershipPlan',
                        ];
                    }
                    else {
                        _this.headersTableGeneral = [
                            'storeName',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                            'membershipPlan',
                        ];
                    }
                }
                else {
                    if (_this.isAdmin) {
                        _this.headersTableGeneral = [
                            'storeName',
                            'subStore',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                        ];
                    }
                    else {
                        _this.headersTableGeneral = [
                            'storeName',
                            'description',
                            'createdAt',
                            'customer',
                            'info',
                        ];
                    }
                }
                var userName = '';
                var canceledAt = void 0;
                if (response.cmsUser) {
                    userName = response.cmsUser.firstName + " " + response.cmsUser.lastName;
                    canceledAt = moment(response.canceledAt).format("DD/MM/YYYY HH:mm:ss");
                }
                _this.transactionsInfo = [
                    {
                        id: response.id,
                        reason: response.status,
                        orderNumber: response.orderNumber,
                        totalValue: response.totalValue / 100,
                        internationalCurrencyValue: response.internationalCurrencyValue / 100,
                        isInternational: response.isInternational,
                        currency: response.currency,
                        canBeCanceled: response.canBeCanceled,
                        isManual: response.isManual,
                        state: response.state,
                        canceledByUser: userName,
                        canceledAt: canceledAt,
                        type: response.type,
                        internalErrorDescription: response.internalErrorDescription,
                        zoopErrorCategory: response.zoopErrorCategory,
                        zoopErrorMessage: response.zoopErrorMessage,
                        zoopPaymentStatus: response.zoopPaymentStatus,
                        zoopErrorMessageDisplay: response.zoopErrorMessageDisplay,
                        zoopErrorResponseCode: response.zoopErrorResponseCode,
                        errorCodeTransactionLivelo: response.errorCodeTransactionLivelo,
                        errorTransactionLivelo: response.errorTransactionLivelo,
                    },
                ];
                _this.transactionsInfoGeneral = [
                    {
                        storeName: response.storeName,
                        storeNomeFantasia: response.storeNomeFantasia,
                        description: response.description,
                        createdAt: response.createdAt,
                        customer: {
                            customerFullName: response.customerFirstName + " " + response.customerLastName,
                            customerCpf: response.customerCpf,
                            customerBirthDate: response.customerBirthDate,
                            customerPhoneNumber: response.customerPhoneNumber,
                            customerEmailAddress: response.customerEmailAddress,
                            customerBalance: response.customerBalance,
                            customerGender: response.customerGender,
                            customerId: response.customerId,
                        },
                        balance: '',
                        approveDoc: '',
                        membershipPlan: response.membershipPlan,
                        subStore: response.subStoreId,
                    },
                ];
                if (response.nomeFantasiaWithoutStore) {
                    _this.isNonPartner = true;
                    _this.transactionsInfoNonPartner = [
                        {
                            nomeFantasiaWithoutStore: response.nomeFantasiaWithoutStore,
                            cnpjWithoutStore: response.cnpjWithoutStore,
                        },
                    ];
                    _this.transactionsInfoGeneral[0].storeName = '-';
                    _this.transactionsInfoGeneral[0].storeNomeFantasia = '-';
                }
                _this.subStoreId = response.subStoreId;
            }
            _this.nfs = response.nfs;
            _this.transactionsInfoConfig = [
                {
                    pointsConversionFactorOut: response.pointsConversionFactorOut / 100,
                    pointsConversionFactorIn: response.pointsConversionFactorIn / 100,
                    maximumInstallmentsPerTransaction: response.maximumInstallmentsPerTransaction,
                    minimumTransactionValue: response.minimumTransactionValue / 100,
                },
            ];
            _this.transactionsInfoCard = [
                {
                    netValue: response.type === 'C2C'
                        ? 0
                        : response.state === 'APPROVED'
                            ? response.netValue / 100
                            : 0,
                    installments: response.type === 'C2C' || !response.netValue ? '' : response.installments,
                    paidInstallments: response.type === 'C2C' || !response.netValue ? '' : response.paidInstallments || 0,
                    cardBrand: typeof response.cardBrand !== 'undefined' ? response.cardBrand : '',
                    rewards: response.taxCashbackRewardsAmount,
                    cashbackValue: response.cashbackValue / 100,
                    cardNumber: response.cardMaskedNumber || response.cardNumber,
                    totalRewarded: response.walletExecution.reduce(function (acc, item) {
                        return acc + item.amount;
                    }, 0),
                    installmentsData: response.walletExecution,
                },
            ];
            _this.transactionsInfoCardTwo = [
                {
                    taxAdministrative: taxAdministrative.toString().replace('.', ','),
                    taxAdministrativeAmount: response.taxAdministrativeAmount / 100,
                    storeSplitAmount: response.storeSplitAmount / 100,
                    paymentProvider: response.paymentProvider,
                },
            ];
            _this.transactionsInfoCardThree = [
                {
                    isInternational: response.isInternational,
                    isVirtualCard: response.isVirtualCard,
                    isAdditionalCard: response.isAdditionalCard,
                },
            ];
            _this.transactionsInfoJC = [
                {
                    pointsApplied: response.pointsApplied ? response.pointsApplied : 0,
                    discountValue: response.discountValue / 100,
                    taxRewardsTransaction: taxRewardsTransaction.toString().replace('.', ','),
                    taxRewardsTransactionAmount: response.taxRewardsTransactionAmount / 100,
                    storeTransferAmount: response.storeTransferAmount / 100,
                },
            ];
            if (_this.isAdmin && response.liquidationDetails) {
                var liquidationDetails = {};
                if (response.liquidationDetails.administrativeTaxPayment) {
                    liquidationDetails.isAdministrativeTaxPaid = response.liquidationDetails.administrativeTaxPayment.success;
                    liquidationDetails.administrativeTaxPaidAt = response.liquidationDetails.administrativeTaxPayment.paidAt;
                    liquidationDetails.administrativeTaxDueDate = response.liquidationDetails.administrativeTaxPayment.dueDate;
                    liquidationDetails.administrativeTaxScheduleId = response.liquidationDetails.administrativeTaxPayment.scheduleId;
                }
                if (response.liquidationDetails.liquidationPayment) {
                    liquidationDetails.isStoreTransferPaid = response.liquidationDetails.liquidationPayment.success;
                    liquidationDetails.storeTransferPaidAt = response.liquidationDetails.liquidationPayment.paidAt;
                    liquidationDetails.storeTransferDueDate = response.liquidationDetails.liquidationPayment.dueDate;
                    liquidationDetails.storeTransferScheduleId = response.liquidationDetails.liquidationPayment.scheduleId;
                }
                _this.liquidationDetails = [
                    liquidationDetails
                ];
            }
            if (response.internationalCurrencyValue) {
                _this.internationalTransactionData.push(tslib_1.__assign({}, response, { internationalCurrencyValue: response.internationalCurrencyValue / 100, currencyQuote: response.currencyQuote / 100, purchaseFeeValue: response.purchaseFeeValue / 100 }));
            }
            if (response.type === "LIVELO") {
                _this.liveloData.push({
                    transactionIdLivelo: response.transactionIdLivelo,
                    pointsLivelo: response.pointsLivelo,
                });
            }
        }, function (error) {
            console.log(error);
        });
    };
    StoreTransactionDetailComponent.prototype.subStores = function (storeId) {
        var _this = this;
        if (this.isAdmin && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(storeId)) {
            this.storeService.listSubStores(storeId).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.subStore = res.results;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
    };
    StoreTransactionDetailComponent.prototype.formatBrand = function (brand) {
        console.log(brand);
        if (brand == 'undefined' || brand == null) {
            return;
        }
        if (brand == 'AMEX') {
            brand = 'american-express';
        }
        var brands = [
            'mastercard',
            'maestro',
            'visa',
            'hipercard',
            'hiper',
            'jcb',
            'elo',
            'banes',
            'aura',
            'american-express',
        ];
        brand = brand.trim().toLowerCase().replace(' ', '-');
        if (_.includes(brands, brand)) {
            return brand;
        }
        else {
            return 'unknown';
        }
    };
    StoreTransactionDetailComponent.prototype.convertPercentToNumber = function (value) {
        return value / 100;
    };
    StoreTransactionDetailComponent.prototype.refresh = function () {
        window.location.reload();
    };
    StoreTransactionDetailComponent.prototype.goBack = function () {
        if (this.params.itComesFromNewTransaction) {
            var lastSegment = this.isInternational ? 'new-international' : 'new';
            this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions', lastSegment], { queryParams: this.params, skipLocationChange: true });
        }
        else {
            if (this.params.storeID) {
                this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions'], { queryParams: this.params, skipLocationChange: true });
            }
            else {
                this.router.navigate(['dashboard', 'transactions'], { queryParams: this.params, skipLocationChange: true });
            }
        }
    };
    StoreTransactionDetailComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.cancel(transaction.id, result, transaction.type);
            }
        });
    };
    StoreTransactionDetailComponent.prototype.openDialogRejectTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalRejectComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.rejectTransactionManual(transaction.id, result);
            }
        });
    };
    StoreTransactionDetailComponent.prototype.openDialogConfirmation = function (transaction) {
        var _this = this;
        if (transaction.isManual) {
            if (this.balance < this.pointsApplied) {
                this.snackBar.open('Saldo insuficiente.');
                return false;
            }
            var dialogRef = this.dialog.open(ModalConfirmationComponent, {
                width: '500px',
                data: {
                    transaction: transaction,
                },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result && result != 'cancel') {
                    _this.approvedTransactionManual(transaction.id);
                }
            });
        }
    };
    StoreTransactionDetailComponent.prototype.rejectTransactionManual = function (transactionID, reason) {
        var _this = this;
        var data = {
            nextState: 'REJECTED',
            reason: reason,
        };
        this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação rejeitada com sucesso.');
                this.initForm();
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open('Não é permitido fazer transações nesse estabelecimento');
        });
    };
    StoreTransactionDetailComponent.prototype.approvedTransactionManual = function (transactionID) {
        var _this = this;
        var data = {
            nextState: 'APPROVED',
        };
        this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação aprovada com sucesso.');
                this.initForm();
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open('Não é permitido fazer transações nesse estabelecimento');
            _this.initForm();
        });
    };
    StoreTransactionDetailComponent.prototype.openModalWarningUnableToCancel = function () {
        var dialogRef = this.dialog.open(ModalRoadpassUnableToCancel, {
            width: '500px',
        });
        return dialogRef.afterClosed().toPromise();
    };
    StoreTransactionDetailComponent.prototype.openModalErrorDetails = function (transaction) {
        var errorsToShow = {
            "Descrição do Erro": transaction.internalErrorDescription,
            "Categoria do erro na Zoop": transaction.zoopErrorCategory,
            "Mensagem de erro da Zoop": transaction.zoopErrorMessage,
            "Status do pagamento na Zoop": transaction.zoopPaymentStatus,
            "Detalhes do erro na Zoop": transaction.zoopErrorMessageDisplay,
            "Código do erro na Zoop": transaction.zoopErrorResponseCode,
        };
        var dialogRef = this.dialog.open(ModalGenericComponent, {
            width: '500px',
            data: {
                html: Object.entries(errorsToShow).map(function (_a) {
                    var _b = tslib_1.__read(_a, 2), key = _b[0], val = _b[1];
                    return val ? "<div><strong>" + key + "</strong>: " + val + "</div>" : '';
                }).join(' ')
            }
        });
        return dialogRef.afterClosed().toPromise();
    };
    StoreTransactionDetailComponent.prototype.openModalLiveloErrorDetails = function (transaction) {
        var errorsToShow = {
            "Código do Erro na Livelo": transaction.errorCodeTransactionLivelo,
            "Descrição de erro da Livelo": transaction.errorTransactionLivelo,
        };
        var dialogRef = this.dialog.open(ModalGenericComponent, {
            width: '500px',
            data: {
                html: Object.entries(errorsToShow).map(function (_a) {
                    var _b = tslib_1.__read(_a, 2), key = _b[0], val = _b[1];
                    return val ? "<div><strong>" + key + "</strong>: " + val + "</div>" : '';
                }).join(' ')
            }
        });
        return dialogRef.afterClosed().toPromise();
    };
    StoreTransactionDetailComponent.prototype.cancel = function (transactionID, reason, transactionType) {
        var _this = this;
        var data = {
            reason: reason,
        };
        var cancelObservable;
        if (transactionType && transactionType.toLowerCase() === 'c2c')
            cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data);
        else
            cancelObservable = this.transactionService.cancelTransaction(transactionID, data);
        cancelObservable.subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(response && response.roadPassUnableToCancel)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.openModalWarningUnableToCancel()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.snackBar.open('Transação cancelada com sucesso.');
                        this.initForm();
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            _this.snackBar.open('Não é permitido fazer transações nesse estabelecimento');
        });
    };
    StoreTransactionDetailComponent.prototype.convertStatus = function (status) {
        switch (status) {
            case 'PENDING':
                return this.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente';
            case 'APPROVED':
                return 'Aprovado';
            case 'REJECTED':
                return 'Rejeitado';
            case 'CANCELED':
                return 'Cancelado';
            default:
                return '';
        }
    };
    StoreTransactionDetailComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    StoreTransactionDetailComponent.prototype.listInstallments = function (totalInstallments, installments) {
        this.dialog.open(ModalInstallmentsComponent, {
            data: {
                totalInstallments: totalInstallments,
                installments: installments,
            },
        });
    };
    StoreTransactionDetailComponent.prototype.detail = function (info) {
        this.dialog.open(ModalCustomerInfoComponent, {
            data: {
                customerBalance: info.customerBalance,
                customerCpf: info.customerCpf,
                customerFullName: info.customerFullName,
                customerBirthDate: info.customerBirthDate,
                customerEmailAddress: info.customerEmailAddress,
                customerPhoneNumber: info.customerPhoneNumber,
                customerGender: info.customerGender,
                customerId: info.customerId,
                user: this.userRole,
            },
        });
    };
    StoreTransactionDetailComponent.prototype.detailFund = function (fundId) {
        var _this = this;
        this.fundService
            .list("?filters={\"filters\":[{\"fields\":[\"funds.id\"], \"op\": \"=\", \"value\":\"" + fundId + "\"}]}")
            .then(function (result) {
            var fund = result.results.find(function () { return true; });
            var dialogRef = _this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    cnpj: fund.cnpj,
                    fundName: fund.name,
                    fundEmailAddress: fund.emailAddress,
                    fundJcoinBalance: fund.jcoinBalance,
                    user: _this.userRole,
                },
            });
        })
            .catch(function (err) {
            _this.snackBar.open(err.error.message);
        });
    };
    StoreTransactionDetailComponent.prototype.detailRecipient = function (info) {
        this.dialog.open(ModalCustomerInfoComponent, {
            data: {
                customerBalance: info.recipientCustomerBalance,
                customerCpf: info.recipientCpf,
                customerFullName: info.recipientFullName,
                customerBirthDate: info.recipientBirthDate,
                customerEmailAddress: info.recipientEmailAddress,
                customerPhoneNumber: info.recipientPhoneNumber,
                customerGender: info.recipientGender,
                customerId: info.recipientCustomerId,
                user: this.userRole,
            },
        });
    };
    StoreTransactionDetailComponent.prototype.changeSubStore = function (subStoreId) {
        this.subStoreId = subStoreId;
    };
    StoreTransactionDetailComponent.prototype.update = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = {
                            subStoreId: this.subStoreId,
                        };
                        if (!this.subStoreId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.transactionService.update(this.transactionID, body).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.snackBar.open('Sub-empresa atualizada com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    StoreTransactionDetailComponent.prototype.getNfType = function (entityId) {
        var type = entityId.split('-').at(-1);
        switch (type) {
            case '01':
                return 'Taxa Administrativa JHSF';
            case '03':
                return 'Taxa Transação JCoins';
            default:
                return "Desconhecido (" + type + ")";
        }
    };
    StoreTransactionDetailComponent.prototype.loadLiquidationPaymentInfo = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("ENTROU");
                        return [4 /*yield*/, this.transactionService.getLiquidationPaymentInfo(id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return StoreTransactionDetailComponent;
}());
export { StoreTransactionDetailComponent };
