import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(errorsService, formBuilder, authService, snackBar, router) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.snackBar = snackBar;
        this.router = router;
        this.hide = true;
        this.initForm();
    }
    LoginComponent.prototype.ngOnInit = function () {
        localStorage.removeItem('storeSelected');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userRole');
        localStorage.removeItem('storesUser');
        localStorage.removeItem('token');
        localStorage.removeItem('storesUser');
        localStorage.removeItem('categoriesInfos');
        localStorage.removeItem('regulationsInfos');
        localStorage.removeItem('isManual');
    };
    LoginComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            emailAddress: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
        });
    };
    LoginComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                this.authService.signin(this.formGroup.value).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.token) {
                            this.setToken(response);
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    LoginComponent.prototype.setToken = function (response) {
        var _this = this;
        localStorage.setItem('token', response.token);
        localStorage.setItem('userRole', response.profile.role);
        this.authService.userInfo(response.profile.id).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (res) {
                    localStorage.setItem('userInfo', JSON.stringify(res));
                    if (!response.validation.isEmailConfirmed) {
                        this.router.navigate(['./auth/change/passowrd']);
                        return [2 /*return*/];
                    }
                    this.getStores(response.profile.role);
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    LoginComponent.prototype.getStores = function (userRole) {
        var _this = this;
        this.authService.getStoresByUser().subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (res) {
                    if (res.length > 0) {
                        localStorage.setItem('storesUser', JSON.stringify(res));
                        if (userRole !== 'ADMIN' && userRole !== 'EMPTY') {
                            localStorage.setItem('storeSelected', JSON.stringify(res[0]));
                        }
                        this.router.navigate(['./dashboard/home']);
                    }
                    else {
                        this.snackBar.open('O usuário não tem nenhum parceiro cadastrado.');
                    }
                }
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
