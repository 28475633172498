import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component';
import { MatDialog, MatSnackBar, MatTableDataSource, MatPaginator, MatSort, } from '@angular/material';
import { ModalChooseTransactionTypeComponent } from 'src/app/components/modal-choose-transaction-type/modal-choose-transaction-type.component';
import { ModalConfirmationComponent } from 'src/app/components/modal-confirmation/modal-confirmation.component';
import { ModalRejectComponent } from 'src/app/components/modal-reject/modal-reject.component';
var StoreTransactionPendingListComponent = /** @class */ (function () {
    function StoreTransactionPendingListComponent(transactionService, storeService, route, router, currencyPipe, snackBar, dialog, location, utilService) {
        this.transactionService = transactionService;
        this.storeService = storeService;
        this.route = route;
        this.router = router;
        this.currencyPipe = currencyPipe;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.utilService = utilService;
        this.headersTable = [
            'orderNumber',
            'storeName',
            'customerCpf',
            'description',
            'createdAt',
            'totalValue',
            'state',
            'actions',
        ];
        this.storeID = '';
        this.params = { storeFormGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    StoreTransactionPendingListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
        });
        this.listTransactions();
        this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.store = response;
                return [2 /*return*/];
            });
        }); });
        this.initForm();
    };
    StoreTransactionPendingListComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'number',
                formControlName: 'numberTransactionPending',
                label: 'Número da Transação',
                placeholder: 'Número da Transação',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfTransactionPending',
                label: 'CPF do Cliente',
                placeholder: 'CPF do Cliente',
            },
            {
                field: 'input',
                type: 'date',
                formControlName: 'dateTransactionPending',
            },
        ];
    };
    StoreTransactionPendingListComponent.prototype.listTransactions = function () {
        var _this = this;
        if (this.storeID != '') {
            // ?state=PENDING&rewardsState=FUTURE
            this.transactionService
                .listTransactions("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"storeId\"], \"op\": \"=\", \"value\":\"" + this.storeID + "\"}, {\"fields\":[\"isManual\"], \"op\": \"=\", \"value\": true}, {\"fields\":[\"transactions.state\"], \"op\": \"=\", \"value\": \"PENDING\"}]}")
                .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    response.results.map(function (item) {
                        item.createdAt = item.createdAt.slice(0, 10);
                        delete item.updatedAt;
                        delete item.paidAt;
                    });
                    this.pageTotal = response.total;
                    this.dataSource = new MatTableDataSource(response.results);
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
        else {
            this.transactionService
                // .listTransactions(
                //   '?state=PENDING&rewardsState=FUTURE',
                // )
                .listTransactions("?filters={\"\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"transactions.state\"], \"op\": \"=\", \"value\": \"PENDING\"}]}")
                .subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.pageTotal = response.total;
                    this.dataSource = new MatTableDataSource(response.results);
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
    };
    StoreTransactionPendingListComponent.prototype.view = function (transaction) {
        if (transaction.isManual) {
            localStorage.setItem('isManual', 'true');
        }
        else {
            localStorage.setItem('isManual', 'false');
        }
    };
    StoreTransactionPendingListComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        if (!transaction.isManual) {
            var balance = transaction.manualDetails.balance;
            var pointsApplied = Math.trunc(transaction.totalValue / transaction.pointsConversionFactorOut);
            if (balance < pointsApplied) {
                this.snackBar.open('Saldo insuficiente.');
                return false;
            }
            var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
                width: '500px',
                data: {
                    transaction: transaction,
                },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result && result != 'cancel') {
                    if (!transaction.isManual) {
                        _this.cancel(transaction.id, result);
                    }
                    else {
                        _this.rejectTransactionManual(transaction.id, result);
                    }
                }
            });
        }
        else {
            var dialogRef = this.dialog.open(ModalRejectComponent, {
                width: '500px',
                data: {
                    transaction: transaction,
                },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result && result != 'cancel') {
                    _this.rejectTransactionManual(transaction.id, result);
                }
            });
        }
    };
    StoreTransactionPendingListComponent.prototype.openDialogConfirmation = function (transaction) {
        var _this = this;
        if (transaction.isManual) {
            var dialogRef = this.dialog.open(ModalConfirmationComponent, {
                width: '500px',
                data: {
                    transaction: transaction,
                },
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result && result != 'cancel') {
                    _this.approvedTransactionManual(transaction.id);
                }
            });
        }
    };
    StoreTransactionPendingListComponent.prototype.onAddTransactionClick = function () {
        if (this.store.manualPaymentEnabled) {
            // this.openDialogTransactionType()
            this.goToManualTransaction();
        }
        else {
            this.goToNormalTransaction();
        }
    };
    StoreTransactionPendingListComponent.prototype.openDialogTransactionType = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalChooseTransactionTypeComponent, {
            width: '500px',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result == 'normal') {
                _this.goToNormalTransaction();
            }
            else if (result == 'manual') {
                _this.goToManualTransaction();
            }
        });
    };
    StoreTransactionPendingListComponent.prototype.goToNormalTransaction = function () {
        this.router.navigateByUrl("/dashboard/stores/" + this.storeID + "/transactions/new");
    };
    StoreTransactionPendingListComponent.prototype.goToManualTransaction = function () {
        this.router.navigateByUrl("/dashboard/stores/" + this.storeID + "/transactions/new-manual");
    };
    StoreTransactionPendingListComponent.prototype.cancel = function (transactionID, reason) {
        var _this = this;
        var data = {
            reason: reason,
        };
        this.transactionService.cancelTransaction(transactionID, data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação cancelada com sucesso.');
                this.listTransactions();
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreTransactionPendingListComponent.prototype.rejectTransactionManual = function (transactionID, reason) {
        var _this = this;
        var data = {
            nextState: 'REJECTED',
            reason: reason,
        };
        this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação rejeitada com sucesso.');
                this.listTransactions();
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreTransactionPendingListComponent.prototype.approvedTransactionManual = function (transactionID) {
        var _this = this;
        var data = {
            nextState: 'APPROVED',
        };
        this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação aprovada com sucesso.');
                this.listTransactions();
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            alert(error.error.message);
        });
    };
    StoreTransactionPendingListComponent.prototype.convertStatus = function (transaction) {
        switch (transaction.state) {
            case 'PENDING':
                return transaction.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente';
                break;
            case 'APPROVED':
                return 'Aprovado';
                break;
            case 'REJECTED':
                return 'Rejeitado';
                break;
            case 'CANCELED':
                return 'Cancelado';
                break;
            default:
                return '';
        }
    };
    StoreTransactionPendingListComponent.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    StoreTransactionPendingListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return StoreTransactionPendingListComponent;
}());
export { StoreTransactionPendingListComponent };
