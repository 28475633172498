import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'

import { MatSnackBar, MatTableDataSource } from '@angular/material'

import { PaymentProviderCardFeeService } from '../../../../../services/paymentProviderCardFee.service' 
import { Router } from '@angular/router'
import { Location } from '@angular/common'

@Component({
  selector: 'payment-provider-card-fee-list',
  templateUrl: './payment-provider-card-fee-list.component.html',
  styleUrls: ['./payment-provider-card-fee-list.component.scss'],
})
export class PaymentProviderCardFeeListComponent implements OnInit {
 
  headersTable: string[] = ['paymentProvider','cardBrand','startsAt','installments1','installments2to6','installments7to12', 'debit']
  dataSource: any

  constructor(
    private readonly paymentProviderCardFeeService: PaymentProviderCardFeeService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly location: Location,
  ) {
    this.loadFeeList()
  }

  ngOnInit() { }

  async loadFeeList() {
    try {
      const response = await this.paymentProviderCardFeeService.list()
      console.log(response)
      this.dataSource = new MatTableDataSource(response)
    } catch(e) {
      this.snackBar.open("Erro ao listar as taxas")
    }    
  }


  onAddCardFeeClick() {
    this.router.navigateByUrl('/dashboard/payment-provider-card-fee-add')
  }

  goBack() {
    this.location.back()
  }
}
