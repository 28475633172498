<app-logged class="store-list">
  <header class="store-list__header">
    <h2>QUESTIONÁRIOS</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/quiz/trigger">
        GATILHO
      </button>
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/quiz/form">
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter actions="form" type="quiz" [fieldsForm]="fieldsForm" (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"></app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amountQuestions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantidade de Perguntas</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.questionCount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.questionCount" mat-icon-button matTooltip="Enviar Notifição" matTooltipPosition="left"
            (click)="sendNotification(element.id, element.name)">
            <mat-icon>send</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Perguntas" matTooltipPosition="left"
            routerLink="/dashboard/quiz/{{ element.id }}/questions/list" [queryParams]="params"
            skipLocationChange="true">
            <mat-icon>contact_support</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Respostas" matTooltipPosition="left"
            routerLink="/dashboard/quiz/{{ element.id }}/client" [queryParams]="params" skipLocationChange="true">
            <mat-icon>question_answer</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
            routerLink="/dashboard/quiz/form/{{ element.id }}" [queryParams]="params" skipLocationChange="true">
            <mat-icon>edit</mat-icon>
          </button>

          <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter actions="pagination" type="quiz" [length]="pageTotal" [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)" [formGroupValue]="formGroup"></app-form-filter>
  </div>
</app-logged>
