import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CdkDragDrop, CdkDragStart, moveItemInArray, } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material';
import * as _ from 'lodash';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
import { UploadAdapter } from '../../../../../services/uploadAdapter.service';
var QuestionsListComponent = /** @class */ (function () {
    function QuestionsListComponent(router, route, dialog, snackBar, questionsService, quizService, location) {
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.questionsService = questionsService;
        this.quizService = quizService;
        this.location = location;
        this.quizID = '';
        this.name = '';
        this.image = '';
        this.description = '';
        this.redirectUrl = false;
        this.Editor = ClassicEditor;
        this.params = { formGroupValue: [] };
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    QuestionsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.config = {};
        this.headersTable = ['ordination', 'ask', 'type', 'view', 'actions'];
        this.quizID = this.route.snapshot.paramMap.get('quizID');
        if (this.quizID) {
            this.quizService.findOne(this.quizID).then(function (response) {
                _this.name = response.name;
                _this.image = response.image;
                response.description = response.description.replace('<p>', '');
                response.description = response.description.replace('</p>', '');
                _this.description = response.description;
            }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        this.questionsList();
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameQuestion',
                label: 'Perguntas ',
                placeholder: 'Perguntas',
            },
        ];
    };
    QuestionsListComponent.prototype.goBack = function () {
        this.location.back();
    };
    QuestionsListComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    QuestionsListComponent.prototype.showImage = function () {
        localStorage.setItem('imageQuestion', this.image);
        window.open('/dashboard/quiz/image', '_blank');
    };
    QuestionsListComponent.prototype.dragging = function (event) {
        var maxStep = _.max(this.dataSource.data.filter(function (i) { return i.group; }).map(function (i) { return Number(i.group); }));
        this.dataSourceData = this.dataSource.data = this.dataSource.data.concat([
            { group: maxStep + 1 },
        ]);
    };
    QuestionsListComponent.prototype.drop = function (event) {
        var _this = this;
        if (event.previousContainer !== event.container) {
            return;
        }
        var previousIndex = this.dataSource.data.findIndex(function (row) { return row === event.item.data; });
        if (event.currentIndex === previousIndex) {
            var lastItem = void 0;
            do {
                lastItem = _.last(event.container.data);
                if (lastItem.group) {
                    event.container.data.pop();
                }
            } while (lastItem.group);
            this.dataSource.data = event.container.data;
            return;
        }
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja salvar alteração na ordem das Perguntas ?',
                buttonConfirmText: 'Salvar',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var lastItem, newWorldOrder, lastItem;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    if (event.currentIndex) {
                        moveItemInArray(event.container.data, previousIndex, event.currentIndex);
                    }
                    lastItem = void 0;
                    do {
                        lastItem = _.last(event.container.data);
                        if (lastItem.group) {
                            event.container.data.pop();
                        }
                    } while (lastItem.group);
                    this.reorder();
                    newWorldOrder = this.dataSource.data
                        .filter(function (i) { return !i.group; })
                        .map(function (i) { return ({ id: i.id, step: i.step, ordination: i.ordination }); });
                    this.questionsService.changeOrder(this.quizID, newWorldOrder).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.snackBar.open('Ordem alterada com sucesso.');
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    lastItem = void 0;
                    do {
                        lastItem = _.last(event.container.data);
                        if (lastItem.group) {
                            event.container.data.pop();
                        }
                    } while (lastItem.group);
                    this.dataSource.data = event.container.data;
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    QuestionsListComponent.prototype.reorder = function () {
        var e_1, _a;
        var step = 0;
        var ordination = 0;
        var data = [];
        try {
            for (var _b = tslib_1.__values(this.dataSource.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                if (item.group) {
                    var next = this.dataSource.data[this.dataSource.data.indexOf(item) + 1];
                    if (!next.group) {
                        step++;
                        item.group = step;
                        data.push(item);
                    }
                }
                else {
                    ordination++;
                    item.ordination = ordination;
                    item.step = step;
                    data.push(item);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.dataSource.data = data;
    };
    QuestionsListComponent.prototype.questionsList = function () {
        var _this = this;
        this.questionsService.list(this.quizID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                response.results.sort(function (a, b) {
                    return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0;
                });
                this.dataSource = new MatTableDataSource(this.groupStep(response.results));
                this.dataSourceData = this.dataSource.data;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuestionsListComponent.prototype.groupStep = function (data) {
        var e_2, _a;
        var groups = _(data)
            .groupBy(function (v) { return v.step; })
            .value();
        var result = [];
        for (var group in groups) {
            result.push({ group: group });
            try {
                for (var _b = tslib_1.__values(groups[group]), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    result.push(item);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return result;
    };
    QuestionsListComponent.prototype.isGroup = function (index, item) {
        return !!item.group;
    };
    QuestionsListComponent.prototype.convertType = function (type) {
        switch (type) {
            case 'TEXT':
                return 'Texto';
                break;
            case 'NUMBER':
                return 'Número';
                break;
            case 'DATE':
                return 'Data';
                break;
            case 'SINGLE-CHOICE':
                return 'Única escolha';
                break;
            case 'MULTIPLE-CHOICE':
                return 'Mútipla escolha';
                break;
            default:
                return '';
        }
    };
    QuestionsListComponent.prototype.convertView = function (view) {
        switch (view) {
            case 'SINGLE-LINE':
                return 'Única linha';
                break;
            case 'MULTIPLE-LINES':
                return 'Múltiplas linhas';
                break;
            case 'INPUT':
                return 'Livre digitação';
                break;
            case 'BUTTONS-DEGREE':
                return 'Botões +/-';
                break;
            case 'DATE':
                return 'Data';
                break;
            case 'TIME':
                return 'Hora';
                break;
            case 'DATE-TIME':
                return 'Data e Hora';
                break;
            case 'BUTTONS':
                return 'Botões';
                break;
            case 'CHECKBOX':
                return 'Checkbox';
                break;
            case 'RADIOBOX':
                return 'Radiobox';
                break;
            default:
                return '';
        }
    };
    QuestionsListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Pergunta?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.questionsService.delete(this.quizID, id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.questionsList();
                                this.snackBar.open('Pergunta removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    QuestionsListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        returnFilter.results.sort(function (a, b) {
            return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0;
        });
        this.dataSource = new MatTableDataSource(this.groupStep(returnFilter.results));
    };
    return QuestionsListComponent;
}());
export { QuestionsListComponent };
