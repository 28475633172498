<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Histórico de JCoins</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/airport-companies">
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="company" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ company.name }}</div>
      <div><b>CNPJ: </b>{{ company.cnpj | mask: '00.000.000/0000-00' }}</div>
    </mat-card>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpjStore">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cnpjStore | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Nome Fantasia</th>
        <td mat-cell *matCellDef="let element">{{ element.nomeFantasia }}</td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>JCOINS</th>
        <td mat-cell *matCellDef="let element">{{ element.balance }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
