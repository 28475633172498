<app-logged class="store-list">
  <header class="store-list__header">
    <h2>USUÁRIOS</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/stores"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
      <button class="btn-primary-light" mat-button (click)="openDialogNewUser()">ADICIONAR</button>
    </div>
  </header>
  <app-form-filter
    [storeID]="storeID"
    actions="form"
    type="storeUser"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>PARCEIRO: </b>{{ nomeFantasia }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Reenviar Credenciais"
            matTooltipPosition="left"
            (click)="openDialogResendCredentials(element.emailAddress)"
          >
            <mat-icon>send</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/users/{{ element.id }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="userRole === 'STOREKEEPER' || userRole === 'ADMIN'"
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->

    <app-form-filter
      [storeID]="storeID"
      type="storeUser"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>

  <div class="table-responsive" style="padding-top: 20px">
    <mat-card class="card-category">
      <div><b>Usuários removidos</b></div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSourceDeleted">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef>E-mail</th>
        <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="deletedAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.deletedAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableDeleted"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableDeleted"></tr>
    </table>
  </div>
</app-logged>
