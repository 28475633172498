import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatTableDataSource } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AirportsService } from '../../../../../services/airports.service'

@Component({
  selector: 'airport-company-historic-stores',
  templateUrl: './airport-company-historic-stores.component.html',
  styleUrls: ['./airport-company-historic-stores.component.scss'],
})
export class AirportCompanyHistoricStoresComponent implements OnInit {
  public headersTable: string[] = ['cnpjStore', 'nomeFantasia', 'balance', 'createdAt']
  dataSource: any
  public formGroup: FormGroup
  cnpj: any
  id: any
  company: any

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.cnpj = this.route.snapshot.paramMap.get('cnpj')
    this.id = this.route.snapshot.paramMap.get('id')

    this.list(this.cnpj).catch(err => { console.log(err) })
    if (this.id) this.findOne().catch(err => { console.log(err) })
  }

  async findOne () {
    await this.airportsService.fideOneCompanyAirport(this.id)
      .then(async response => {
        this.company = response
      })
  }

  async list(cnpj) {
    const { results } = await this.airportsService
      .listCompanyAirportHistoric(cnpj)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
  
    const sortedResults = results.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })
  
    this.dataSource = new MatTableDataSource(sortedResults)
  }
  
}
