import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'

import { ErrorsService } from '../../../../../core/services/errors.service'
import { AirportsService } from '../../../../../services/airports.service'

@Component({
  selector: 'app-documents-eligible-form',
  templateUrl: './documents-eligible-form.component.html',
  styleUrls: ['./documents-eligible-form.component.scss'],
})
export class DocumentsEligibleFormComponent implements OnInit {
  public formGroup: FormGroup
  customers: any[] = []
  id: any
  dynamicMask = '000.000.000-00'

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    public airportsService: AirportsService,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      prefix: [null, [Validators.required]],
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async findOne () {
    // await this.airportsService.findOneDocumentsNotIntegration(this.id)
    //   .then(async response => {
    //     this.formGroup.patchValue({
    //       cpf: response.cpf,
    //     })
    //   })
  }

  onDocumentInput () {
    const documentValue = this.formGroup.get('document').value
    if (documentValue.length === 14) {
      this.dynamicMask = '00.000.000/0000-00'
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      prefix: this.formGroup.value.prefix,
    }

    this.airportsService.documentsEligibleCreate(data).then(
      async response => {
        if (response) {
          await this.router.navigate(['./dashboard/airports/not-eligible'])
          this.snackBar.open('Prefixo da Aeronave cadastrado com sucesso.')
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
