import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
interface DialogData {
  cnpj: string
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-help.component.html',
  styleUrls: ['./modal-help.component.scss'],
})
export class ModalHelpComponent implements OnInit {
  public formGroup: FormGroup

  constructor(
    public dialogRef: MatDialogRef<ModalHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) {
    console.log('data', data)
  }

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  ngOnInit() {}
}
