import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import numeral from 'numeral';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var MembershipPlansFormComponent = /** @class */ (function () {
    function MembershipPlansFormComponent(router, route, location, formBuilder, errorsService, snackBar, membershipService, dialog) {
        this.router = router;
        this.route = route;
        this.location = location;
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.membershipService = membershipService;
        this.dialog = dialog;
        this.isFreeUsed = '';
        this.isDefaultUsed = {};
        this.valueInvalid = false;
        this.recurrencePeriodInvalid = false;
        this.params = { formGroupValue: [] };
    }
    MembershipPlansFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.planId = this.route.snapshot.paramMap.get('planID');
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.formGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            beginDate: [null, [Validators.required]],
            endDate: [null, []],
            value: [null, []],
            recurrencePeriod: [null, []],
            isDefault: [null, [Validators.required]],
            isFree: [null, [Validators.required]],
            isPj: [null, [Validators.required]],
            isActive: [null, [Validators.required]],
        });
        if (this.planId) {
            this.getById();
        }
        else {
            this.formGroup.patchValue({
                isActive: '0',
            });
        }
    };
    MembershipPlansFormComponent.prototype.getById = function () {
        var _this = this;
        this.membershipService.listPlansById(this.planId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup.patchValue({
                    name: response.name,
                    beginDate: response.beginDate.substring(0, 10),
                    endDate: response.endDate ? response.endDate.substring(0, 10) : '',
                    value: numeral(response.value).divide(100).value(),
                    recurrencePeriod: response.recurrencePeriod,
                    isDefault: !!response.isDefault,
                    isFree: !!response.isFree,
                    isPj: !!response.isPj,
                    isActive: String(response.isActive),
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.message);
        });
    };
    MembershipPlansFormComponent.prototype.changeDefault = function (e) {
        if (e.value) {
            this.clickDefault = true;
        }
    };
    MembershipPlansFormComponent.prototype.changeFree = function (e) {
        if (e.value) {
            this.clickFree = true;
            if (this.valueInvalid) {
                this.valueInvalid = false;
            }
            if (this.recurrencePeriodInvalid) {
                this.recurrencePeriodInvalid = false;
            }
        }
    };
    MembershipPlansFormComponent.prototype.modalConfirmation = function (data, text, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog
                            .open(ModalConfirmComponent, {
                            width: '500px',
                            data: {
                                text: "O " + data.name + " est\u00E1 como Plano Membership " + text + ", deseja alterar ?",
                                buttonConfirmText: 'Alterar',
                                buttonNot: 'NÃO',
                            },
                        })
                            .afterClosed()
                            .toPromise()
                            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(result && result !== 'cancel' && result !== 'not')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.membershipService.editPlan(data.id, body).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                return tslib_1.__generator(this, function (_a) {
                                                    return [2 /*return*/, response];
                                                });
                                            }); }, function (error) {
                                                _this.snackBar.open(error.error.message);
                                            })];
                                    case 1: return [2 /*return*/, _a.sent()];
                                    case 2:
                                        if (this.clickDefault) {
                                            this.formGroup.patchValue({
                                                isDefault: false,
                                            });
                                        }
                                        if (this.clickFree) {
                                            this.formGroup.patchValue({
                                                isFree: false,
                                            });
                                        }
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MembershipPlansFormComponent.prototype.onChange = function (e) {
        this.valueInvalid = !this.formGroup.value.value;
    };
    MembershipPlansFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.value.isDefault) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.membershipService.listPlans().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var body;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.isDefaultUsed = response
                                                .filter(function (p) { return p.isDefault === 1 && p.id !== _this.planId; })
                                                .find(function () { return true; });
                                            if (!this.isDefaultUsed) return [3 /*break*/, 2];
                                            body = {
                                                isDefault: 0,
                                            };
                                            return [4 /*yield*/, this.modalConfirmation(this.isDefaultUsed, 'Default', body)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.formGroup.value.isFree) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.membershipService.listPlans().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var body;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.isFreeUsed = response
                                                .filter(function (p) { return p.isFree === 1 && p.id !== _this.planId; })
                                                .find(function () { return true; });
                                            if (!this.isFreeUsed) return [3 /*break*/, 2];
                                            body = {
                                                isFree: 0,
                                            };
                                            return [4 /*yield*/, this.modalConfirmation(this.isFreeUsed, 'Free', body)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.error.message);
                            })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.valueInvalid = !!(!this.formGroup.value.isFree && !this.formGroup.value.value);
                        this.recurrencePeriodInvalid = !!(!this.formGroup.value.isFree && !this.formGroup.value.recurrencePeriod);
                        if (!this.formGroup.valid || this.valueInvalid || this.recurrencePeriodInvalid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (this.formGroup.value.isFree && this.formGroup.value.recurrencePeriod) {
                            this.formGroup.patchValue({
                                recurrencePeriod: '0',
                            });
                        }
                        if (this.formGroup.value.isFree && this.formGroup.value.value) {
                            this.formGroup.patchValue({
                                value: 0,
                            });
                        }
                        data = {
                            name: this.formGroup.value.name,
                            beginDate: this.formGroup.value.beginDate,
                            endDate: this.formGroup.value.endDate ? this.formGroup.value.endDate : null,
                            value: this.formGroup.value.value
                                ? numeral(this.formGroup.value.value).multiply(100).value()
                                : 0,
                            recurrencePeriod: this.formGroup.value.recurrencePeriod
                                ? this.formGroup.value.recurrencePeriod.toString()
                                : '0',
                            isDefault: this.formGroup.value.isDefault ? 1 : 0,
                            isFree: this.formGroup.value.isFree ? 1 : 0,
                            isPj: this.formGroup.value.isPj ? 1 : 0,
                            isActive: Number(this.formGroup.value.isActive),
                        };
                        if (!this.planId) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.membershipService.editPlan(this.planId, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.location.back();
                                    this.snackBar.open('Plano Membership alterado com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.message);
                            })];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.membershipService.createPlan(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.location.back();
                                this.snackBar.open('Plano Membership cadastrado com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.message);
                        })];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    return MembershipPlansFormComponent;
}());
export { MembershipPlansFormComponent };
