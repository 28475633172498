import { Component, OnInit } from '@angular/core'
import { MacroGroupsService } from '../../../../services/macro-groups.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-macro-group-list',
  templateUrl: './macro-group-list.component.html',
  styleUrls: ['./macro-group-list.component.scss'],
})
export class MacroGroupListComponent implements OnInit {
  public headersTable: string[] = ['title', 'actions']
  public macroGroup: any[] = []

  constructor (
    public macroGroupsService: MacroGroupsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.listAirports()
  }

  listAirports () {
    this.macroGroupsService.list().then(
      response => {
        this.macroGroup = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Macro grupo?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.macroGroupsService.delete(id).then(
            async response => {
              this.listAirports()
              this.snackBar.open('Macro grupo removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
