import { Component, OnInit } from '@angular/core'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { QuizService } from '../../../../services/quiz.service'
import { StoreService } from '../../../../services/store.service'
import { Location } from '@angular/common'
@Component({
  selector: 'app-quiz-customer-list',
  templateUrl: './quiz-customer-list.component.html',
  styleUrls: ['./quiz-customer-list.component.scss'],
})
export class QuizCustomerListComponent implements OnInit {
  public headersTable: string[]
  public pageTotal: number
  public customerID = ''
  public fullName = ''
  dataSource: any
  fieldsForm: any

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public params = { formGroupValue: [] }

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quizService: QuizService,
    public storeService: StoreService,
    public location: Location,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.customerID = this.route.snapshot.paramMap.get('customerID')
    this.headersTable = ['name', 'amountAnswer', 'actions']

    if (this.customerID) {
      this.storeService.listUsersCustomers(`/${this.customerID}`).subscribe(
        async response => {
          this.fullName = `${response.firstName}  ${response.lastName}`
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
    }

    this.quizList()

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'nameQuizCustomer',
        label: 'Questionário',
        placeholder: 'Questionário',
      },
    ]
  }

  quizList () {
    this.quizService
      .listCustomers(
        this.customerID,
        `?filters={"page":${this.page}, "pageSize": ${this.pageSize}}`,
      )
      .then(
        async response => {
          this.pageTotal = response.results.length
          localStorage.setItem('quiz', JSON.stringify(response.results))
          this.dataSource = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  goBack () {
    this.location.back()
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.results.length
    this.dataSource = new MatTableDataSource(returnFilter.results)
    localStorage.setItem('quiz', JSON.stringify(returnFilter.results))
  }
}
