<app-logged class="store-list">
  <header class="store-list__header">
    <h2>GESTÃO DAS TRANSAÇÕES</h2>
    <div class="store-list__header__actions">
      <a class="btn-primary-light" mat-button (click)="goBack()" *ngIf="userRole == 'ADMIN'">
        VOLTAR
      </a>
      <button
        class="btn-primary-light"
        mat-button
        (click)="onAddTransactionClick()"
        *ngIf="storeID != ''"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter
    maskExample="000.000.000-00"
    [storeID]="storeID"
    actions="form"
    type="transaction"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
    (returnFilterReserve)="reciverFeedbackReserve($event)"
  ></app-form-filter>

  <div class="table-responsive" *ngIf="dataSourceReserve">
    <b>PAGAMENTOS COM RESERVA</b>
    <table mat-table [dataSource]="dataSourceReserve">
      <ng-container matColumnDef="transactionOrderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.transactionOrderNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nomeFantasia">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nomeFantasia }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nameCustomer">
        <th mat-header-cell *matHeaderCellDef>Nome do Cliente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="CPF: {{ element.cpf | mask: '000.000.000-00' }}"
        >
          <button
            mat-icon-button
            (click)="detail(element.transactionId, element.fullName, element.cpf)"
          >
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="descriptionTransaction">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.descriptionPayment }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentDate">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.paymentDate | date: 'dd/MM/yyyy':'GMT -0300' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValueAnticipated">
        <th mat-header-cell *matHeaderCellDef>Valor Antecipado</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.reserveInCents) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor Total</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            canceledtransaction:
              element.state == 'REJECTED' ||
              element.state == 'CANCELED' ||
              element.state == 'PARTLYCANCELED' ||
              element.state == 'PARTLYREJECTED',
            approvedtransaction: element.state == 'APPROVED' || element.state == 'ANTICIPATED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }"
        >
          {{ convertStatusPayments(element) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Visualizar"
            (click)="view(element)"
            matTooltipPosition="left"
            routerLink="/dashboard/transactions/payment/{{ element.paymentId }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableReserve"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableReserve"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter
      [storeID]="storeID"
      type="transactionReserve"
      actions="pagination"
      [length]="pageTotalReserve"
      [pageSizeOptions]="pageSizeOptionsReserve"
      [formGroupValue]="formGroup"
      (returnFilterReserve)="reciverFeedbackReserve($event)"
    >
    </app-form-filter>
  </div>

  <div class="table-responsive">
    <b>TRANSAÇÕES</b>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element" style="width: 10px">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="storeType" *ngIf="userRole == 'ADMIN'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.nomeFantasiaWithoutStore ? 'Não Parceiro' : 'Parceiro' }}"
        >
          <button mat-icon-button>
            <mat-icon
              class="smallicon"
              [ngClass]="{
                storeNonPartner: element.nomeFantasiaWithoutStore,
                storePartner: !element.nomeFantasiaWithoutStore
              }"
              >store</mat-icon
            >
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="storeName" *ngIf="userRole == 'ADMIN'">
        <th mat-header-cell *matHeaderCellDef>Parceiros/Não Parceiros</th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element.storeName }}"
          class="cellText"
        >
          {{ element.storeName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fundName" *ngIf="userRole == 'ADMIN'">
        <th mat-header-cell *matHeaderCellDef>Fundo</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.fundName }}" class="cellText">
          {{ element.fundName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Nome do Cliente</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.fullName }}" class="cellText">
          {{ element.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customerCpf">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="CPF: {{ element.customerCpf | mask: '000.000.000-00' }}"
        >
          <button
            mat-icon-button
            (click)="detail(element.id, element.fullName, element.customerCpf)"
          >
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description != 'dynamic' ? element.description : 'QRcode' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy':'GMT -0300' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          matTooltip="{{ convertStatusTransactions(element) }}"
          class="cellText"
          *matCellDef="let element"
          [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }"
        >
          {{ convertStatusTransactions(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="
              element.type != 'CARD_MACHINE' &&
              ((!element.isManual && element.state == 'REJECTED') ||
                (!element.isManual && element.state == 'PENDING'))
            "
            mat-icon-button
            matTooltip="Enviar notificação"
            (click)="openDialogSendNotification(element)"
            matTooltipPosition="left"
          >
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button
            *ngIf="element.canBeCanceled && userRole !== 'STOREKEEPER_SALES'"
            mat-icon-button
            matTooltip="Cancelar"
            (click)="openDialogCancelTransaction(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Visualizar"
            (click)="view(element)"
            matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.id }}"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter
      [storeID]="storeID"
      type="transaction"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [formGroupValue]="formGroup"
      (returnFilter)="reciverFeedback($event)"
    >
    </app-form-filter>
  </div>
</app-logged>
