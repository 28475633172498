import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var ClosingsIncorporationsIntegrationsComponent = /** @class */ (function () {
    function ClosingsIncorporationsIntegrationsComponent(authService, snackBar, errorsService, route) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.route = route;
        this.headersTable = ['cnpj', 'nomeFantasia', 'nd', 'nf'];
        this.closings = [];
    }
    ClosingsIncorporationsIntegrationsComponent.prototype.ngOnInit = function () {
        this.idIncorporation = this.route.snapshot.paramMap.get('id');
        console.log(this.idIncorporation);
        this.listAll();
    };
    ClosingsIncorporationsIntegrationsComponent.prototype.listAll = function () {
        var _this = this;
        this.closings = [];
        this.authService.closingsIntegrationsIncorporations(this.idIncorporation).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.closings = response.results;
                console.log(response);
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsIncorporationsIntegrationsComponent.prototype.calcNF = function (jcoins) {
        return 0.5 * jcoins / 100;
    };
    return ClosingsIncorporationsIntegrationsComponent;
}());
export { ClosingsIncorporationsIntegrationsComponent };
