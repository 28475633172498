import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
var CampaignType;
(function (CampaignType) {
    CampaignType["ACCUMULATION"] = "Ac\u00FAmulo";
    CampaignType["REDEEM"] = "Resgate";
    CampaignType["CREATE_CUSTOMER"] = "Cadastro de Cliente";
})(CampaignType || (CampaignType = {}));
var ConfirmationCampaignType;
(function (ConfirmationCampaignType) {
    ConfirmationCampaignType["PENDING_POINTS"] = "Pontos pendentes";
    ConfirmationCampaignType["CONFIRMED"] = "Confirmados";
    ConfirmationCampaignType["PENDING_POINTS_CONFIRMATION"] = "Confirma\u00E7\u00E3o de pontos pendentes";
})(ConfirmationCampaignType || (ConfirmationCampaignType = {}));
var ConfirmationCampaign;
(function (ConfirmationCampaign) {
    ConfirmationCampaign["ALLOW_PARTIALS"] = "Permite parciais";
    ConfirmationCampaign["PARTIAL_ONCE"] = "Parcial apenas uma vez";
    ConfirmationCampaign["DENY_PARTIAL"] = "N\u00E3o permite parcial";
})(ConfirmationCampaign || (ConfirmationCampaign = {}));
var CampaignComponent = /** @class */ (function () {
    function CampaignComponent(route, campaignService, dialog, snackBar) {
        this.route = route;
        this.campaignService = campaignService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.headersTable = ['idCampaign', 'campaignTypes', 'description', 'confirmationCampaignType', 'confirmationCampaign', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    CampaignComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
            }
            else {
                _this.loadCampaigns();
            }
        });
        this.initForm();
    };
    CampaignComponent.prototype.initForm = function () {
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'campaignType',
                label: 'Tipo Campanha',
                placeholder: 'Campanha',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'idCampaign',
                label: 'ID da campanha',
                placeholder: 'ID da campanha',
            },
        ];
    };
    CampaignComponent.prototype.handlePropertiesTranslation = function (campaigns) {
        campaigns.map(function (campaign) {
            campaign.campaignTypes = CampaignType[campaign.campaignTypes];
            campaign.confirmationCampaignType =
                ConfirmationCampaignType[campaign.confirmationCampaignType];
            campaign.confirmationCampaign = ConfirmationCampaign[campaign.confirmationCampaign];
        });
        return campaigns;
    };
    CampaignComponent.prototype.loadCampaigns = function () {
        var _this = this;
        this.campaignService
            .listCampaigns("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .subscribe(function (response) {
            response.results = _this.handlePropertiesTranslation(response.results);
            _this.pageTotal = response.total;
            _this.campaigns = new MatTableDataSource(response.results);
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    CampaignComponent.prototype.reciverFeedback = function (returnFilter) {
        returnFilter.results = this.handlePropertiesTranslation(returnFilter.results);
        this.pageTotal = returnFilter.total;
        this.campaigns = new MatTableDataSource(returnFilter.results);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    CampaignComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa campanha?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.campaignService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.loadCampaigns();
                                this.snackBar.open('Campanha removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return CampaignComponent;
}());
export { CampaignComponent };
