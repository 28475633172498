<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!addressID">CADASTRO DE ENDEREÇO</h2>
    <h2 *ngIf="addressID">EDIÇÃO DE ENDEREÇO</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Endereço</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput formControlName="addressTitle" />
              <mat-error *ngIf="formGroup.controls.addressTitle?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.addressTitle) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>CEP</mat-label>
              <input matInput formControlName="zipCode" (blur)="getAddress()" mask="00000-000" />
              <mat-error *ngIf="formGroup.controls.zipCode?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.zipCode) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Rua</mat-label>
              <input matInput formControlName="street" />
              <mat-error *ngIf="formGroup.controls.street?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.street) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Número</mat-label>
              <input matInput formControlName="addressNumber" />
              <mat-error *ngIf="formGroup.controls.addressNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.addressNumber) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Complemento</mat-label>
              <input matInput formControlName="complement" />
              <mat-error *ngIf="formGroup.controls.complement?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.complement) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Bairro</mat-label>
              <input matInput formControlName="neighborhood" />
              <mat-error *ngIf="formGroup.controls.neighborhood?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.neighborhood) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Cidade</mat-label>
              <input matInput formControlName="city" />
              <mat-error *ngIf="formGroup.controls.city?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.city) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <input matInput formControlName="state" />
              <mat-error *ngIf="formGroup.controls.state?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.state) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>País</mat-label>
              <input matInput formControlName="country" />
              <mat-error *ngIf="formGroup.controls.country?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.country) }}
              </mat-error>
            </mat-form-field>

            <mat-radio-group formControlName="kind" (change)="onChange()">
              <mat-radio-button value="HOME">Residencial</mat-radio-button>
              <mat-radio-button value="COMPANY">Comercial</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="kindInvalid">Campo deve ser preenchido</mat-error>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
