import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalFormIntegrationsComponent } from '../../../../components/modal-form-integrations/modal-form-integrations.component';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';
var IntegrationsListComponent = /** @class */ (function () {
    function IntegrationsListComponent(authService, snackBar, dialog, reportService) {
        this.authService = authService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.reportService = reportService;
        this.headersTable = ['cnpj', 'nomeFantasia', 'cpf', 'name', 'cpfBeneficiary', 'nameBeneficiary', 'contract', 'cnpjCpfTitular', 'totalValue', 'totalPoints', 'tax', 'dateCreatedAt', 'dateCredit', 'isClient'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    IntegrationsListComponent.prototype.ngOnInit = function () {
        this.list().catch(function (err) { console.log(err); });
        var firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        var lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameIntegration',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfIntegration',
                label: 'CPF',
                placeholder: 'CPF',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Inicio',
                formControlName: 'dateInitIntegration',
                valueDefault: firstDayOfMonth,
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Fim',
                formControlName: 'dateEndIntegration',
                valueDefault: lastDayOfMonth
            },
            {
                field: 'select',
                formControlName: 'percentageIntegration',
                label: '% Jcoins',
                list: [
                    { key: '0.05', value: '% > 0.05' },
                    { key: 'all', value: 'TODOS' },
                ],
            },
        ];
    };
    IntegrationsListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now, firstDay, lastDay, _a, results, total, sortedResults;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        now = new Date();
                        firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                        lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                        return [4 /*yield*/, this.authService
                                .clientsIncorporations("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"incorporation_integration.createdAt\"], \"op\": \">=\", \"value\":\"" + moment(firstDay).format('YYYY-MM-DD') + "\"}, {\"fields\":[\"incorporation_integration.createdAt\"], \"op\": \"<=\", \"value\":\"" + moment(lastDay).format('YYYY-MM-DD') + "\"}]}")
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        sortedResults = results.sort(function (a, b) { return a.contract - b.contract; });
                        this.dataSource = new MatTableDataSource(sortedResults);
                        this.pageTotal = total;
                        this.resultsAll = sortedResults;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntegrationsListComponent.prototype.receiverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        var sortedResults = returnFilter.results.sort(function (a, b) { return a.contract - b.contract; });
        this.dataSource = new MatTableDataSource(sortedResults);
        this.resultsAll = sortedResults;
        if (returnFilter && returnFilter.formGroup) {
            this.formGroup = returnFilter.formGroup.value;
        }
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    IntegrationsListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalFormIntegrationsComponent, {
            width: '400px',
        });
        dialogRef.afterClosed().subscribe(function (filters) {
            if (filters) {
                _this.report(filters);
            }
        });
    };
    IntegrationsListComponent.prototype.report = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formatDate, formattedStartDate_1, formattedEndDate_1, response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        formatDate = function (dateStr) {
                            var day = dateStr.substring(0, 2);
                            var month = dateStr.substring(2, 4);
                            var year = dateStr.substring(4, 8);
                            return day + "/" + month + "/" + year;
                        };
                        formattedStartDate_1 = formatDate(filters.startDate);
                        formattedEndDate_1 = formatDate(filters.endDate);
                        return [4 /*yield*/, this.reportService.incorporationsExport(filters)];
                    case 1:
                        response = _a.sent();
                        new JsFileDownloader({
                            url: response.fileUrl,
                            nameCallback: function () {
                                return "Consulta Base - " + formattedStartDate_1 + " \u00E0 " + formattedEndDate_1 + ".xlsx";
                            },
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Erro ao baixar o relatório:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return IntegrationsListComponent;
}());
export { IntegrationsListComponent };
