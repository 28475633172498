<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="filterForm" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <div class="col-12 col-sm-12">
            
            <mat-card-content>
              <mat-form-field class="full-width">
                <mat-label>Mês/Ano</mat-label>
                <input
                  matInput
                  formControlName="monthYear"
                  placeholder="MM/YYYY"
                  maxlength="7"
                  mask="00/0000"
                />
                <mat-error *ngIf="filterForm.controls.monthYear?.invalid">
                  {{ errorsService.messageErrorFor(filterForm.controls.monthYear) }}
                </mat-error>
              </mat-form-field>
            </mat-card-content>

          </div>
        </div>
      </mat-card>
    </div>

    <!-- Ações do modal -->
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="submit" class="btn-submit-dark">Aplicar Filtros</button>
      </div>
    </div>
  </form>
</main>
