import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
var ModalGenericComponent = /** @class */ (function () {
    function ModalGenericComponent(dialogRef, data, snackBar, sanitizer) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        this.sanitizer = sanitizer;
        console.log('data', data);
    }
    ModalGenericComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalGenericComponent.prototype.confirm = function () {
        this.dialogRef.close(1);
    };
    ModalGenericComponent.prototype.not = function () {
        this.dialogRef.close('not');
    };
    ModalGenericComponent.prototype.ngOnInit = function () {
        // empty
    };
    ModalGenericComponent.prototype.getSanitizedHtml = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    return ModalGenericComponent;
}());
export { ModalGenericComponent };
