import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ErrorsService } from 'src/app/core/services/errors.service';

interface DialogData {
  stores: Array<any>;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-choose-store.component.html',
  styleUrls: ['./modal-choose-store.component.scss']
})
export class ModalChooseStoreComponent implements OnInit {

  public formGroup: FormGroup;
  public stores: Array<any> = [];
  public storesSelected: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<ModalChooseStoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar) {
     this.stores = data.stores;
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }
    
    this.dialogRef.close(this.formGroup.value.store);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        store: [null, [
          Validators.required,
        ]],
      });
  }
}

