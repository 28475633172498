
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: "root"
})
export class PaymentProviderCardFeeService {

  constructor(private http: HttpClient) { }

  async list (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/payment-provider-card-fees`).toPromise()
  }

  async addNewFee (fee): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/payment-provider-card-fees`, fee).toPromise()
  }
}