import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { PasswordValidator } from 'src/app/validators/password.validator';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  formGroup: FormGroup;
  public userInfo: User;
  hide = true;

  constructor(
    public errorsService: ErrorsService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    public authService: AuthService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

  initForm() {
    this.formGroup = this.formBuilder.group(
      {
        currentPassword: [null, [Validators.required]],
        newPassword: [null, [Validators.required]],
        confirmNewPassword: [null, [Validators.required]],
      },
      {
        validators: PasswordValidator('newPassword', 'confirmNewPassword'),
      }
    );
  }

  async submit() {

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    let data = {
      emailAddress: this.userInfo.emailAddress,
      password: this.formGroup.value.currentPassword,
      newPassword: this.formGroup.value.newPassword,
    }

    this.authService.passwordUpdate(data).subscribe(
      async (response) => {
        this.snackBar.open('A senha foi alterada com sucesso.');
        this.getStores();

        
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }

  getStores() {
    this.authService.getStoresByUser().subscribe(
      async (res) => {
        if (res) {
          localStorage.setItem('storesUser', JSON.stringify(res));
          localStorage.setItem('storeSelected', JSON.stringify(res[0]));
          this.router.navigate(['./dashboard/home']);
        }
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
    
  }
}
