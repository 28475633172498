<app-logged class="store-list">
  <header class="store-list__header">
    <h2>PLANOS MEMBERSHIP DO CLIENTE</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <app-form-filter
    actions="form"
    type="membershipListPlansCustomer"
    [fieldsForm]="fieldsForm"
    [customerID]="customerId"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>NOME: </b>{{ fullName }}</div>
      <div><b>CPF: </b>{{ cpf | mask: '000.000.000-00' }}</div>
      <div><b>E-MAIL: </b>{{ email }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plano Membership</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.namePlan }}" class="cellText">
          {{ element.namePlan }}
        </td>
      </ng-container>
      <ng-container matColumnDef="beginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Aquisição</th>
        <td mat-cell *matCellDef="let element">
          {{ element.beginDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Término de Vigência</th>
        <td mat-cell *matCellDef="let element">
          {{ element.endDate | date: 'dd/MM/yyyy' }}
        </td> </ng-container
      >término
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.value) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="recurrencePeriod">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Período Recorrente</th>
        <td mat-cell *matCellDef="let element">
          {{ month(element.recurrencePeriod) }}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive">close</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Planos Membership"
            matTooltipPosition="left"
            (click)="infoPlan(element.idPlan)"
          >
            <mat-icon>list_alt</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      actions="pagination"
      type="membershipListPlansCustomer"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      [customerID]="customerId"
      (returnFilter)="reciverFeedback($event)"
    >
    </app-form-filter>
  </div>
</app-logged>
