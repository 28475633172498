<app-logged class="store-list">
  <header class="store-list__header">
    <h2>PLANOS MEMBERSHIP</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/memberships/plan/form"
        [queryParams]="params" skipLocationChange="true">
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter actions="form" type="membershipListPlans" [fieldsForm]="fieldsForm" [formGroupValue]="formGroup"
    (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <b>PLANO RECORRENTE DEFAULT</b>
      <table mat-table [dataSource]="dataSourceIsDefault">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Plano Membership</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="beginDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Início</th>
          <td mat-cell *matCellDef="let element">
            {{ element.beginDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Final</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
          <td mat-cell *matCellDef="let element">
            {{ convertCentsToReal(element.value) | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="recurrencePeriod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Período Recorrente</th>
          <td mat-cell *matCellDef="let element">
            {{ month(element.recurrencePeriod) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.isActive">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!element.isActive">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Usuários" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/{{ element.id }}/customers" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>supervisor_account</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/form/{{ element.id }}" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headersTableIsDefault"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableIsDefault"></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let row" class="groupCell">
            {{ row.group }}
          </td>
        </ng-container>
      </table>
    </mat-card>
  </div>

  <div class="table-responsive">
    <mat-card class="card-category">
      <b>PLANO FREE</b>
      <table mat-table [dataSource]="dataSourceIsFree">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Plano Membership</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="beginDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Início</th>
          <td mat-cell *matCellDef="let element">
            {{ element.beginDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Final</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
          <td mat-cell *matCellDef="let element">
            {{ convertCentsToReal(element.value) | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="recurrencePeriod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Período Recorrente</th>
          <td mat-cell *matCellDef="let element">
            {{ month(element.recurrencePeriod) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.isActive">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!element.isActive">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Usuários" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/{{ element.id }}/customers" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>supervisor_account</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/form/{{ element.id }}" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headersTableIsFree"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTableIsFree"></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let row" class="groupCell">
            {{ row.group }}
          </td>
        </ng-container>
      </table>
    </mat-card>
  </div>

  <div class="table-responsive">
    <mat-card class="card-category">
      <b>PLANOS PJ E RECORRENTE</b>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Plano Membership</th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="beginDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Início</th>
          <td mat-cell *matCellDef="let element">
            {{ element.beginDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Final</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
          <td mat-cell *matCellDef="let element">
            {{ convertCentsToReal(element.value) | currency: 'BRL':'R$' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="recurrencePeriod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Período Recorrente</th>
          <td mat-cell *matCellDef="let element">
            {{ month(element.recurrencePeriod) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.isActive">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!element.isActive">close</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
          <td mat-cell *matCellDef="let element">
            {{ typePlan(element.isPj) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button matTooltip="Usuários" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/{{ element.id }}/customers" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>supervisor_account</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
              routerLink="/dashboard/memberships/plan/form/{{ element.id }}" [queryParams]="params"
              skipLocationChange="true">
              <mat-icon>edit</mat-icon>
            </button>

            <!-- <button
            *ngIf="element.isActive"
            mat-icon-button
            matTooltip="Inativo"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>cancel</mat-icon>
          </button> -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTable"></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <td colspan="999" mat-cell *matCellDef="let row" class="groupCell">
            {{ row.group }}
          </td>
        </ng-container>
        <!--
      <tr
        mat-row
        *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
        class="groupRow"
      ></tr> -->
      </table>
      <app-form-filter actions="pagination" type="membershipListPlans" [length]="pageTotal"
        [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)" [formGroupValue]="formGroup">
      </app-form-filter>
    </mat-card>
  </div>
</app-logged>
