<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h4>Deseja criar essa transação ?</h4>
        <div class="col-12 col-sm-12">
          <mat-card-content>
            <ul class="heroes">
              <li>
                <span class="badge"><b>CPF do Cliente</b></span
                >: {{ data.customerCpf | mask: '000.000.000-00' }}
              </li>
              <li>
                <span class="badge"><b>Nome do Cliente</b></span
                >: {{ data.customerName }}
              </li>
              <li>
                <span class="badge"><b> Valor em {{data.currency}}</b></span
                  >: {{ convertCentsToReal(data.internationalCurrencyValue) | currency: data.currency }}
              </li>
              <li>
                <span class="badge"><b>Cotação do {{data.currency}}</b></span
                  >: {{ convertCentsToReal(data.currencyQuote) | currency: 'BRL':'R$' }}
              </li>
              <li>
                <span class="badge"><b>Valor em reais com taxas</b></span
                >: {{ convertCentsToReal(data.totalValueWithTaxes) | currency: 'BRL':'R$' }}
              </li>             
              
            </ul>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="actions">      
      <button mat-button type="submit" class="btn-submit-dark" (click)="createTransacation()">
        CONFIRMAR TRANSAÇÃO E ENVIAR PARA O CLIENTE
      </button>
    </div>
  </div>
</main>
