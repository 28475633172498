import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import JsFileDownloader from 'js-file-downloader';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import numeral from 'numeral';
var ArchitectClosingDetails = /** @class */ (function () {
    function ArchitectClosingDetails(architectClosingsService, route, snackBar, dialog, location) {
        this.architectClosingsService = architectClosingsService;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.tableColumns = ['storeName', 'nd', 'nf', 'totalPay', 'nfQuantity', 'totalNfValues', 'actions'];
        this.architectClosingid = this.route.snapshot.paramMap.get('id');
    }
    ArchitectClosingDetails.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadClosing()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.loadClosing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.architectClosingsService.getClosing(this.architectClosingid)];
                    case 1:
                        _a.closing = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.export = function (storeBusinessUnitId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.architectClosingsService.exportStoreClosing(this.architectClosingid, storeBusinessUnitId)];
                    case 1:
                        response = _a.sent();
                        if (response.report) {
                            new JsFileDownloader({
                                url: response.report,
                                nameCallback: function (fileName) {
                                    return ('Relatório de Fechamento Arquitetos por Parceiro.' +
                                        (fileName.split('.').pop() || 'xls'));
                                },
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.checkNDPayment = function (store) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, res, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.dialog.open(ModalGenericComponent, {
                                width: '500px',
                                data: {
                                    text: 'Deseja checar o pagamento ND para esse parceiro?',
                                    buttonConfirmText: 'Confirmar pagamento ND',
                                },
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.architectClosingsService.checkNDPayment(this.architectClosingid, store.id)];
                    case 2:
                        res = _a.sent();
                        this.snackBar.open('Pagamento ND checado com sucesso');
                        return [4 /*yield*/, this.loadClosing()];
                    case 3:
                        _a.sent();
                        if (res.ndPaid && res.nfPaid && !store.approved) {
                            this.approveStoreBusinessUnitInvoices(store.id, 'Pagamento ND e NF confirmados.');
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        this.snackBar.open('Problema ao checar pagamento ND');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.checkNFPayment = function (store) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, res, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.dialog.open(ModalGenericComponent, {
                                width: '500px',
                                data: {
                                    text: 'Deseja checar o pagamento NF para esse parceiro?',
                                    buttonConfirmText: 'Confirmar pagamento NF',
                                },
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.architectClosingsService.checkNFPayment(this.architectClosingid, store.id)];
                    case 2:
                        res = _a.sent();
                        this.snackBar.open('Pagamento NF checado com sucesso');
                        return [4 /*yield*/, this.loadClosing()];
                    case 3:
                        _a.sent();
                        if (res.ndPaid && res.nfPaid && !store.approved) {
                            this.approveStoreBusinessUnitInvoices(store.id, 'Pagamento ND e NF confirmados.');
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        this.snackBar.open('Problema ao checar pagamento NF');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.approveStoreBusinessUnitInvoices = function (storeBusinessUnitId, additionaltext) {
        if (additionaltext === void 0) { additionaltext = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, res, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.dialog.open(ModalGenericComponent, {
                                width: '500px',
                                data: {
                                    text: additionaltext + " Deseja aprovar o pagamento de todas Notas fiscais desse parceiro? Essa a\u00E7\u00E3o ir\u00E1 distribuir os JCoins para os arquitetos envolvidos",
                                    buttonConfirmText: 'Aprovar todas',
                                },
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.architectClosingsService.approveAllStoreBusinessInvoices(this.architectClosingid, storeBusinessUnitId)];
                    case 2:
                        res = _a.sent();
                        this.snackBar.open('Notas Fiscais aprovadas com sucesso!');
                        return [4 /*yield*/, this.loadClosing()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_3 = _a.sent();
                        this.snackBar.open('Problema ao aprovar as notas fiscais desse fechamento');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingDetails.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectClosingDetails.prototype.convertCentsToReal = function (value) {
        if (value) {
            return value / 100;
        }
        else
            return value;
    };
    ArchitectClosingDetails.prototype.totalPay = function (taxCashback, taxAdm) {
        var total = Number(taxCashback) + Number(taxAdm);
        return numeral(Number(total)).divide(100).value();
    };
    return ArchitectClosingDetails;
}());
export { ArchitectClosingDetails };
