import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionsLength'
})
export class TransactionsLengthPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '-';
    }

    try {
      const parsedArray = JSON.parse(value);
      return Array.isArray(parsedArray) ? 
        `${parsedArray.filter(transaction => transaction.isInternational === true).length} de ${parsedArray.length}` : '-';
    } catch (e) {
      console.error('Invalid JSON string:', value);
      return '-';
    }
  }
}
