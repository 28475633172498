import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManualJcoinCreditService {
  public loading;
  private manualJcoinCreditCountSubject = new BehaviorSubject<string | null>(null);

  constructor(private readonly http: HttpClient) { }

  async list(params: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/manual-jcoins-credit?${params}`).toPromise()
  }

  async request(body: any): Promise<any> {
    const url = `${environment.apiUrl}/cms/manual-jcoins-credit`;
    return this.http.post<any>(url, body).toPromise();
  }

  async review(id: string, body: any): Promise<any> {
    const url = `${environment.apiUrl}/cms/manual-jcoins-credit/${id}/review`;
    return this.http.put<any>(url, body).toPromise();
  }

  async getPendingManualJcoinCreditsCount(): Promise<any> {
    const url = `${environment.apiUrl}/cms/manual-jcoins-credit/get-pending-requests-count`;
    return this.http.get<any>(url).toPromise();
  }

  async checkPendingManualJcoinCredits(): Promise<any> {
    
    const lastUpdate = localStorage.getItem('lastPendingRequestUpdate')
    const lastTime = moment(new Date(Number(lastUpdate)))    
    const today = moment()
    const isAfter = today.isAfter(lastTime.add(3, 'minute'))
    
    if(!lastUpdate || isAfter) {
      await this.updatePendingRequestsCount()
    }
  }

  async updatePendingRequestsCount(){
    const { count } = await this.getPendingManualJcoinCreditsCount()
    localStorage.setItem('lastPendingRequestUpdate',new Date().getTime()+'')
    localStorage.setItem('pendingManualJcoinCreditsCount',count+'')
    this.manualJcoinCreditCountSubject.next(count)
  }


  getManualJcoinCreditCountChange(): Observable<string | null> {
    return this.manualJcoinCreditCountSubject.asObservable();
  }
}
