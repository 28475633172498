<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Integrações de Prefixo da Aeronave não elegíveis</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="openDialog()">EXPORTAR</button>
    </div>
  </header>
  <app-form-filter
    maskExample="000.000.000-00"
    actions="form"
    type="airportIntegrationNotEligible"
    [fieldsForm]="fieldsForm"
    (returnFilter)="receiverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>CPF/CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ addMask(element.cnpjCpf) }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Creditar Pontos</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Creditar Pontos"
            matTooltipPosition="left"
            (click)="sendJcoins(element.id, element.taxAmountJcoins)"
          >
            <mat-icon>money</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="prefix">
        <th mat-header-cell *matHeaderCellDef>Prefixo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.preAircraft }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ ORIGEM</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cnpjOrigem | mask: '00.000.000/0000-00' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nameCli">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.nameCli }}</td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>Numero</th>
        <td mat-cell *matCellDef="let element">
          {{ element.number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOs">
        <th mat-header-cell *matHeaderCellDef>Numero OS</th>
        <td mat-cell *matCellDef="let element">{{ element.numberOs }}</td>
      </ng-container>

      <ng-container matColumnDef="amountReceived">
        <th mat-header-cell *matHeaderCellDef>Valor total recebido</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalAmountReceived) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="taxAmountJcoins">
        <th mat-header-cell *matHeaderCellDef>Quantidade de Jcoins</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.taxAmountJcoins }}
        </td>
      </ng-container>

      <ng-container matColumnDef="taxJcoins">
        <th mat-header-cell *matHeaderCellDef>% Jcoins</th>
        <td mat-cell *matCellDef="let element">
          {{ element.taxJcoins | mask: 'separator.4' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreatedAt">
        <th mat-header-cell *matHeaderCellDef>Data da Integração</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.createdAt | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ convertState(element.state) }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="dateCredit">
        <th mat-header-cell *matHeaderCellDef>Data Baixa</th>
        <td mat-cell *matCellDef="let element">{{ element.dateCredit | date: 'dd/MM/yyyy' }}</td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>

    <app-form-filter
      actions="pagination"
      type="airportIntegrationNotEligible"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="receiverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
