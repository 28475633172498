import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
var routes = [
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent
    },
    {
        path: 'auth/forget-password',
        pathMatch: 'full',
        component: ForgotPasswordComponent
    },
    {
        path: 'auth/change/passowrd',
        pathMatch: 'full',
        component: ChangePasswordComponent
    },
    {
        path: 'auth/email/confirmation/:token',
        pathMatch: 'full',
        component: EmailConfirmationComponent
    },
    {
        path: '',
        component: LoginComponent
    },
];
var AuthRoutingModule = /** @class */ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
