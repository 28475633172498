<app-logged class="architect-invoice-list">
  <header class="architect-invoice-list__header">
    <h2>NOTAS FISCAIS</h2>
    <div class="architect-invoice-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>


      <!-- <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button> -->
    </div>
  </header>

  <app-form-filter
    actions="form"
    type="architectInvoices"
    [storeBusinessUnitId]="storeBusinessUnitId"
    [fieldsForm]="fieldsForm"
    (returnFilter)="filterCallback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="invoiceNumbber">
        <th mat-header-cell *matHeaderCellDef>Número da Nota fiscal</th>
        <td mat-cell *matCellDef="let element">{{ element.invoiceNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceSerial">
        <th mat-header-cell *matHeaderCellDef>Número de série</th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceSerial }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Valor total</th>
        <td mat-cell *matCellDef="let element">{{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}</td>
      </ng-container>


      <ng-container matColumnDef="receiver">
        <th mat-header-cell *matHeaderCellDef>Recebedor dos jcoins</th>
        <td mat-cell *matCellDef="let element">
          <div  class="flex-align-center">
            <mat-icon *ngIf="element.type == 'PF'" matTooltip="Pessoa física" >person</mat-icon>
            <mat-icon *ngIf="element.type == 'PJ'" matTooltip="Empresa">business</mat-icon>
            <span>{{ element.receiverName }} </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <strong [ngStyle]="{color: getInvoiceStatus(element).color}">
            {{ getInvoiceStatus(element).text}}
          </strong>

        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd/MM/yyyy':'GMT -0300' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">

          <button
            *ngIf="showEditButton(element)"
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/architect-invoice/{{element.id}}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>


          <button
            *ngIf="showCancelButton(element)"
            mat-icon-button
            matTooltip="Cancelar"
            matTooltipPosition="left"
            (click)="cancelInvoice(element.id)"
          >
            <mat-icon>cancel</mat-icon>
          </button>

          <button
          mat-icon-button
          matTooltip="Ver detalhes"
          matTooltipPosition="left"
          routerLink="/dashboard/architect-invoice/{{element.id}}/detail"
        >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
  <!-- Angular 8 pagination -->
  <app-form-filter
    type="architectInvoices"
    actions="pagination"
    [length]="pageTotal"
    [storeBusinessUnitId]="storeBusinessUnitId"
    [pageSizeOptions]="pageSizeOptions"
    [formGroupValue]="formGroup"
    (returnFilter)="filterCallback($event)"
  >
  </app-form-filter>


</app-logged>
