import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { LyDialog } from '@alyle/ui/dialog'
import { Location } from '@angular/common'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { base64ToBlob } from 'base64-blob'
import { BannersService } from '../../../../services/banners.service'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { CategoriesService } from 'src/app/services/categories.service'
import { BannersPagesAppService } from '../../../../services/bannersPagesApp.service'

@Component({
  selector: 'app-banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.scss'],
})
export class BannerFormComponent implements OnInit {
  public formGroup: FormGroup
  public idPage: any
  public page: any
  public id: any
  public showDates = false
  public category: string
  public subcategory: string
  public pages: string

  private originalPartnerBannerImage: any
  public croppedPartnerBannerImage: any
  private partnerBannerImagePosition: any
  private partnerBannerImageData: any
  public readUrlPartnerBanner: any

  public menuIconUrl = ''

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public bannersService: BannersService,
    public bannersPagesAppService: BannersPagesAppService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    public categoriesService: CategoriesService,
  ) { }

  ngOnInit () {
    this.idPage = this.route.snapshot.paramMap.get('idPage')
    this.page = this.route.snapshot.paramMap.get('page')
    this.id = this.route.snapshot.paramMap.get('id')

    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      description: [null, [Validators.required]],
      image: [null, []],
      order: [null, [Validators.required]],
      initAt: [null, []],
      endAt: [null, []],
      isFixed: [null, []],
      menuIconUrl: [null, [Validators.required]],
      link: [null, []],
    })
    if (this.id) {
      this.loadInfos().catch(err => console.log(err))
    }
    if (this.page === 'category') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.category = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.page === 'subcategory') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.subcategory = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.page === 'home') {
      this.bannersPagesAppService.findOne(this.idPage).then(
        response => {
          this.pages = response.page
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  async loadInfos () {
    await this.bannersService.findOne(this.id).then(async response => {
      const dateInitAt = response.initAt ? response.initAt.split('T')[0] : null
      const dateEndAt = response.endAt ? response.endAt.split('T')[0] : null
      if (response.isFixed) {
        this.showDates = true
      }
      this.formGroup.patchValue({
        description: response.description,
        // image: response.image,
        order: response.order,
        initAt: dateInitAt,
        endAt: dateEndAt,
        isFixed: response.isFixed,
        menuIconUrl: response.image,
        link: response.link,
      })
      this.croppedPartnerBannerImage = response.image
      this.menuIconUrl = response.image
      console.log(this.menuIconUrl)
    })
  }

  onChange () {
    if (this.formGroup.value.isFixed) {
      this.showDates = true

      this.formGroup.get('endAt').setValidators(null)
      this.formGroup.get('endAt').updateValueAndValidity()
      this.formGroup.get('endAt').setErrors(null)

      this.formGroup.get('initAt').setValidators(null)
      this.formGroup.get('initAt').updateValueAndValidity()
      this.formGroup.get('initAt').setErrors(null)

      this.formGroup.value.endAt = null
      this.formGroup.value.initAt = null
    }
    if (!this.formGroup.value.isFixed) {
      this.showDates = false
    }
  }

  onChangeDate () {
    if (this.formGroup.value.initAt) {
      this.formGroup.get('endAt').setValidators([Validators.required])
      this.formGroup.get('endAt').updateValueAndValidity()
    }
    if (this.formGroup.value.endAt) {
      this.formGroup.get('initAt').setValidators([Validators.required])
      this.formGroup.get('initAt').updateValueAndValidity()
    }
  }

  selectFile (event, type) {
    const uploadData = new FormData()
    uploadData.append('file', event.target.files[0], event.target.files[0].name)

    this.categoriesService.upload(uploadData).subscribe(
      response => {
        switch (type) {
          case 'menuIconUrl': {
            this.formGroup.get('menuIconUrl').setValue(response.url)
            this.menuIconUrl = response.url
            break
          }
          default:
            break
        }
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  // selectFilePartnerBanner (event: Event) {
  // const originalPartnerBannerImageUrl = (event.target as any).files[0]

  // const reader: FileReader = new FileReader()
  // reader.onloadend = e => {
  //   this.originalPartnerBannerImage = reader.result
  // }
  // try {
  //   reader.readAsDataURL(originalPartnerBannerImageUrl)
  // } catch (e) {
  //   this.snackBar.open(e.message)
  // }

  //   this.croppedPartnerBannerImage = null!
  //   this._dialog
  //     .open<CropperDialogComponent, any>(CropperDialogComponent, {
  //     data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
  //     width: 650,
  //     disableClose: true,
  //   })
  //     .afterClosed.toPromise()
  //     .then(async (result?: { img: ImgCropperEvent, config: any }) => {
  //       if (result) {
  //         this.croppedPartnerBannerImage = result.img.dataURL
  //         this.partnerBannerImagePosition = result.config
  //         this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
  //         this._cd.markForCheck()
  //       }
  //     })
  //     .catch(error => {
  //       this.snackBar.open(error.message)
  //     })
  // }

  async editPartnerBannerImage () {
    try {
      const img = this.originalPartnerBannerImage
        ? this.originalPartnerBannerImage
        : await this.getBase64ImageFromUrl(this.croppedPartnerBannerImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.partnerBannerImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedPartnerBannerImage = result.img.dataURL
        this.partnerBannerImagePosition = result.config
        this.partnerBannerImageData = await base64ToBlob(this.croppedPartnerBannerImage)
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async removeImage (input) {
    if (input === 'partnerBanner') {
      this.readUrlPartnerBanner = ''
      this.originalPartnerBannerImage = undefined
      this.croppedPartnerBannerImage = undefined
      this.partnerBannerImagePosition = undefined
      this.partnerBannerImageData = undefined
    }
  }

  goBack () {
    this.location.back()
  }

  async submit () {
    if (!this.formGroup.value.isFixed &&
      !this.formGroup.value.initAt &&
      !this.formGroup.value.endAt
    ) {
      this.snackBar.open('Preencha as datas ou fixo.')
      return false
    }

    if (this.croppedPartnerBannerImage && this.partnerBannerImageData) {
      const response = await this.categoriesService.getS3Url()
      this.readUrlPartnerBanner = response.readUrl
      await this.categoriesService.uploadToS3(response.uploadUrl, this.partnerBannerImageData)
      this.formGroup.value.image = this.readUrlPartnerBanner
    }

    if (!this.formGroup.valid || !this.menuIconUrl) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    let page: string
    if (this.page === 'category') {
      page = 'CATEGORIES'
    }
    if (this.page === 'subcategory') {
      page = 'SUBCATEGORIES'
    }
    if (this.page === 'home') {
      page = this.page.toUpperCase()
    }

    const data = {
      description: this.formGroup.value.description,
      image: this.menuIconUrl,
      order: this.formGroup.value.order,
      initAt: this.formGroup.value.initAt,
      endAt: this.formGroup.value.endAt,
      isFixed: this.formGroup.value.isFixed,
      categoryId: this.page === 'category' || this.page === 'subcategory' ? this.idPage : undefined,
      appPageId: this.page === 'home' ? this.idPage : undefined,
      page,
      link: this.formGroup.value.link,
    }

    if (this.id) {
      this.bannersService.update(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            this.goBack()
            this.snackBar.open('Banner atualizado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.bannersService.create(data).then(
        async response => {
          if (response.isCreated) {
            this.goBack()
            this.snackBar.open('Banner criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
