import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import numeral from 'numeral';
import { ModalCancelTransactionComponent } from '../../../../components/modal-cancel-transaction/modal-cancel-transaction.component';
import { ModalCustomerInfoComponent } from '../../../../components/modal-customer-info/modal-customer-info.component';
var MembershipPlanTransactionsCustomerListComponent = /** @class */ (function () {
    function MembershipPlanTransactionsCustomerListComponent(router, route, location, transactionService, membershipService, snackBar, dialog) {
        this.router = router;
        this.route = route;
        this.location = location;
        this.transactionService = transactionService;
        this.membershipService = membershipService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.listStatusDefault = [];
        this.listStatus = [];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.headersTable = [
            'orderNumber',
            'storeName',
            'customerCpf',
            'createdAt',
            'totalValue',
            'state',
            'actions',
        ];
    }
    MembershipPlanTransactionsCustomerListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.planId = this.route.snapshot.paramMap.get('planID');
        this.customerId = this.route.snapshot.paramMap.get('customerID');
        this.membershipService.listPlansById(this.planId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.planName = response.name;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.transactionService
            .transactionsMembershipPlanByCustomer(this.planId, this.customerId, "?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var infos;
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = new MatTableDataSource(response.results);
                this.pageTotal = response.results.length;
                infos = response.results.find(function () { return true; });
                this.fullName = infos.fullName;
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.transactionService
            .getStatus()
            .toPromise()
            .then(function (response) {
            Object.keys(response).map(function (k) {
                _this.listStatusDefault = response;
                if (k !== 'PENDING') {
                    _this.listStatus.push({ key: k, value: _this.listStatusDefault[k] });
                }
            });
            _this.listStatus.sort(function (a, b) {
                return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
            });
        })
            .catch(function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'number',
                formControlName: 'numberTransactionPlanMembership',
                label: 'Número da Transação',
                placeholder: 'Número da Transação',
            },
            {
                field: 'input',
                type: 'date',
                formControlName: 'dateTransactionPlanMembership',
            },
            {
                field: 'select',
                formControlName: 'statusPlanMembership',
                label: 'Status',
                placeholder: 'Status',
                list: this.listStatus,
                optionDefault: 'TODOS',
            },
        ];
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.goBack = function () {
        this.location.back();
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.convertStatusTransactions = function (transaction) {
        if (transaction.isManual && transaction.state == 'PENDING') {
            return 'AGUARDANDO APROVAÇÃO DA JHSF';
        }
        else {
            return this.listStatusDefault[transaction.state];
        }
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.view = function (transaction) {
        if (transaction.isManual) {
            localStorage.setItem('isManual', 'true');
        }
        else {
            localStorage.setItem('isManual', 'false');
        }
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.openDialogCancelTransaction = function (transaction) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
            width: '500px',
            data: {
                transaction: transaction,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.cancel(transaction.id, result);
            }
        });
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.cancel = function (transactionID, reason) {
        var _this = this;
        var data = {
            reason: reason,
        };
        this.transactionService
            .cancelTransaction(transactionID, data)
            .toPromise()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.snackBar.open('Transação cancelada com sucesso.');
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.detail = function (transacationId) {
        var _this = this;
        this.transactionService
            .listTransactionsById(transacationId + "?showManualDetails=true")
            .toPromise()
            .then(function (response) {
            var dialogRef = _this.dialog.open(ModalCustomerInfoComponent, {
                data: {
                    customerBalance: response.customerBalance,
                    customerCpf: response.customerCpf,
                    customerFullName: response.customerFirstName + " " + response.customerLastName,
                    customerBirthDate: response.customerBirthDate,
                    customerEmailAddress: response.customerEmailAddress,
                    customerPhoneNumber: response.customerPhoneNumber,
                    customerGender: response.customerGender,
                },
            });
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    MembershipPlanTransactionsCustomerListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.results.length;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    return MembershipPlanTransactionsCustomerListComponent;
}());
export { MembershipPlanTransactionsCustomerListComponent };
