import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CategoriesService } from '../../../../services/categories.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { Location } from '@angular/common'

@Component({
  selector: 'app-exclusive-list',
  templateUrl: './exclusive-list.component.html',
  styleUrls: ['./exclusive-list.component.scss'],
})
export class ExclusiveListComponent implements OnInit {
  public headersTable: string[] = ['description', 'status', 'actions']
  public exclusives: any[] = []
  public categoryId: string
  public category: string

  constructor (
    public categoriesService: CategoriesService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.categoryId = this.route.snapshot.paramMap.get('categoryID')
    this.categoriesService.findOne(this.categoryId).then(
      response => {
        this.category = response.heading
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
    this.listCategories()
  }

  listCategories () {
    this.categoriesService.listExclusives(this.categoryId).then(
      response => {
        this.exclusives = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Icone ?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.categoriesService.deleteExclusive(id).then(
            async response => {
              this.listCategories()
              this.snackBar.open('Icone removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
