import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var PreRegistrationHistoricStoresComponent = /** @class */ (function () {
    function PreRegistrationHistoricStoresComponent(preRegistrationService, snackBar, route) {
        this.preRegistrationService = preRegistrationService;
        this.snackBar = snackBar;
        this.route = route;
        this.headersTable = ['cnpj', 'nomeFantasia', 'balance', 'createdAt'];
    }
    PreRegistrationHistoricStoresComponent.prototype.ngOnInit = function () {
        this.cpf = this.route.snapshot.paramMap.get('cpf');
        this.typePreRegistration = this.route.snapshot.paramMap.get('type');
        this.list(this.cpf).catch(function (err) { console.log(err); });
        this.findOne().catch(function (err) { console.log(err); });
    };
    PreRegistrationHistoricStoresComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.preRegistrationService.findCpf(this.cpf)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.preRegistration = response;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PreRegistrationHistoricStoresComponent.prototype.list = function (cpf) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.preRegistrationService
                            .listHistoric(cpf)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        results = (_a.sent()).results;
                        this.dataSource = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    return PreRegistrationHistoricStoresComponent;
}());
export { PreRegistrationHistoricStoresComponent };
