<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Conta(s) Bancária(s)</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores" [queryParams]="params"
        skipLocationChange="true">
        VOLTAR
      </button>
      <button class="btn-primary-light" mat-button
        routerLink="/dashboard/stores/{{storeID}}/bank-account/new">ADICIONAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="accounts">
      <ng-container matColumnDef="bankCode">
        <th mat-header-cell *matHeaderCellDef>Banco</th>
        <td mat-cell *matCellDef="let element">{{ element.bankCode }}</td>
      </ng-container>
      <ng-container matColumnDef="agency">
        <th mat-header-cell *matHeaderCellDef>Agência</th>
        <td mat-cell *matCellDef="let element">{{ element.agency }}</td>
      </ng-container>
      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef>Conta</th>
        <td mat-cell *matCellDef="let element">{{ element.accountNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="accountDigit">
        <th mat-header-cell *matHeaderCellDef>Dígito</th>
        <td mat-cell *matCellDef="let element">{{ element.accountDigit }}</td>
      </ng-container>
      <ng-container matColumnDef="accountType">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">{{ convertStatus(element.accountType) }}</td>
      </ng-container>
      <ng-container matColumnDef="zoopBankAccountId">
        <th mat-header-cell *matHeaderCellDef>Conta Bancária ZOOP</th>
        <td mat-cell *matCellDef="let element">{{ element.zoopBankAccountId }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
