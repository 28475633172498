import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalComponent = /** @class */ (function () {
    function ModalComponent(dialogRef, data, formBuilder, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.cnpjMask = '00.000.000/0000-00';
        this.cnpjLabel = 'CNPJ';
    }
    ModalComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalComponent.prototype.submit = function () {
        if (!this.formGroup.valid) {
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        var documentValue = this.formGroup.value.cnpj.replace(/\D/g, '');
        this.dialogRef.close({
            document: documentValue,
            isInternationalPartner: this.formGroup.value.isInternationalPartner
        });
    };
    ModalComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            cnpj: [null, [
                    Validators.required,
                    Validators.minLength(5),
                ]],
            isInternationalPartner: [false]
        });
    };
    ModalComponent.prototype.toggleMask = function (isInternational) {
        this.formGroup.get('cnpj').setValue('');
        this.cnpjMask = isInternational ? '' : '00.000.000/0000-00';
        this.cnpjLabel = isInternational ? 'Documento' : 'CNPJ';
    };
    return ModalComponent;
}());
export { ModalComponent };
