import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router'
import { StoreService } from 'src/app/services/store.service'
import { User } from 'src/app/models/user'
import { PageEvent } from '@angular/material/paginator'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'
import { ModalResendCredentialsComponent } from 'src/app/components/modal-resend-credentials/modal-resend-credentials.component'
import { AuthService } from 'src/app/services/auth.service'
import { UtilService } from 'src/app/services/util.service'
import { ReportService } from '../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { FormGroup } from '@angular/forms'
import { PermissionsService } from 'src/app/services/permissions.service'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'

@Component({
  selector: 'app-store-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit {
  public headersTable: string[] = [
    'name',
    'cpf',
    'phoneNumber',
    'emailAddress',
    'jcoinBalance',
    // 'membership',
    'typeRegister',
    'origin',
    'actions',
  ]

  public users: User[] = []
  public storeID: string = null
  dataSource: any
  fieldsForm: any
  public pageTotal: number
  formGroup: FormGroup
  params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  pageEvent: PageEvent
  customerId: string
  cpf: string

  dataString: any
  architect: any
  public userRole: any
  public cmsPermissions = enumCmsPermissions

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor (
    public storeService: StoreService,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    public utilService: UtilService,
    public router: Router,
    public reportService: ReportService,
    public permissionsService: PermissionsService,
  ) { }

  ngOnInit () {
    this.userRole = localStorage.getItem('userRole')
    this.architect = this.route.snapshot.paramMap.get('architect')
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
      } else {
        this.customerId = this.route.snapshot.paramMap.get('customerID')

        if (this.route.snapshot.paramMap.get('storeID')) {
          this.storeID = this.route.snapshot.paramMap.get('storeID')
          this.listUsersByStore()
        } else if (this.customerId) {
          this.userFindOne(this.customerId).catch(err => console.log(err))
        } else {
          this.listUsers()
        }
      }
      this.initForm()
    })
  }

  initForm () {
    if (!this.customerId && !this.architect) {
      this.fieldsForm = [
        {
          field: 'input',
          type: 'text',
          formControlName: 'nameClient',
          label: 'Nome',
          placeholder: 'Nome',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfClient',
          label: 'CPF do Cliente',
          placeholder: 'CPF do Cliente',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'phoneClient',
          label: 'Telefone',
          placeholder: 'Telefone',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'emailClient',
          label: 'E-mail',
          placeholder: 'E-mail',
        },
        {
          field: 'select',
          formControlName: 'typeRegister',
          label: 'Tipo Cadastro',
          placeholder: 'Tipo Cadastro',
          list: [
            { key: 'jhsfid', value: 'JHSF ID' },
            { key: 'architect', value: 'ARQUITETOS' },
            { key: 'jhsfid_architect', value: 'JHSF ID + ARQUITETOS' },
          ],
          optionDefault: 'TODOS',
        },
      ]
    } else {
      this.fieldsForm = [
        {
          field: 'input',
          type: 'text',
          formControlName: 'nameClient',
          label: 'Nome',
          placeholder: 'Nome',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfClient',
          label: 'CPF do Cliente',
          placeholder: 'CPF do Cliente',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'phoneClient',
          label: 'Telefone',
          placeholder: 'Telefone',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'emailClient',
          label: 'E-mail',
          placeholder: 'E-mail',
        },
      ]
    }
  }

  listUsers () {
    // const filter = this.architect ?
    //   `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["architect"], "op": "=", "value":"true"} ]}` :
    //   `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["architect"], "op": "=", "value":"false"} ]}`
    const filter = this.architect ?
      `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["architect_jhsfid_architect"], "op": "=", "value":"true"} ]}` :
      `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`
    this.storeService
      .listUsersCustomers(filter)
      .toPromise()
      .then(
        async response => {
          response.items.map(user => {
            user.fullName = `${user.firstName} ${user.lastName}`
          })
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.items)
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
  }

  listUsersByStore () {
    this.storeService.listUsersByStore(`?storeId=${this.storeID}`).subscribe(
      async response => {
        this.users = response
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  async listCustomerInfo (userID) {
    this.authService
      .customerUserInfo(userID)
      .toPromise()
      .then(
        response => {
          this.router.navigateByUrl(
            `/dashboard/memberships/transactions/plan/${response.planId}/customer/${userID}`,
          )
        },
        error => {
          alert(error.error.message)
        },
      )
  }

  async userFindOne (customerId) {
    this.storeService
      .listUsersCustomers(
        `?filters={"page": ${this.page}, "pageSize": ${this.pageSize}, "filters":[{"fields":["customers.id"], "op": "=", "value":"${customerId}"}]}`,
      )
      .toPromise()
      .then(
        async response => {
          response.items.map(user => {
            user.fullName = `${user.firstName} ${user.lastName}`
            this.cpf = user.cpf
          })
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.items)

          this.fieldsForm = [
            {
              field: 'input',
              type: 'text',
              formControlName: 'nameClient',
              label: 'Nome',
              placeholder: 'Nome',
            },
            {
              field: 'input',
              type: 'text',
              formControlName: 'cpfClient',
              label: 'CPF do Cliente',
              placeholder: 'CPF do Cliente',
              valueDefault: this.cpf,
            },
            {
              field: 'input',
              type: 'text',
              formControlName: 'phoneClient',
              label: 'Telefone',
              placeholder: 'Telefone',
            },
            {
              field: 'input',
              type: 'text',
              formControlName: 'emailClient',
              label: 'E-mail',
              placeholder: 'E-mail',
            },
          ]
        },
        error => {
          alert(error.error.message)
        },
      )
  }

  openDialogResendCredentials (userID): void {
    const dialogRef = this.dialog.open(ModalResendCredentialsComponent, {
      width: '550px',
      data: { text: 'Deseja fazer o reenvio das credenciais do cliente?' },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.authService.resendCredentials(userID).subscribe(
          async response => {
            this.snackBar.open('Credenciais enviadas com sucesso.')
          },
          error => {
            alert(error.error.message)
          },
        )
      }
    })
  }

  async remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        data: {
          text: 'Deseja remover esse Cliente?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.deleteCustomer(id).then(
            async response => {
              this.listUsers()
              this.snackBar.open('Cliente removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  async report () {
    let filter = ''
    if (this.architect) {
      filter = 'customers?architect=true'
    } else {
      filter = 'customers'
    }
    await this.reportService.createReport(filter).then(async response => {
      // eslint-disable-next-line no-new
      new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return 'Relatório de Clientes.' + response.fileUrl.split('.').splice(5, 5).join('.')
        },
      })
    })
  }

  parseOrigin (origin: string) {
    if (origin === 'JHSFID') {
      return 'JHSF ID'
    }
    if (origin === 'ARCHITECT') {
      return 'ARQUITETOS'
    }
    if (origin === 'JHSFID_ARCHITECT') {
      return 'JHSF ID + ARQUITETOS'
    }
  }

  parseTypeRegister (businessUnits) {
    if (businessUnits.length > 0) {
      return 'ARQUITETOS'
    } else {
      return 'JHSF ID'
    }
  }

  reciverFeedback (returnFilter) {
    returnFilter.items.map(user => {
      user.fullName = user.firstName + ' ' + user.lastName
    })
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.items)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
