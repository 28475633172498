import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MacroGroupsService = /** @class */ (function () {
    function MacroGroupsService(http) {
        this.http = http;
    }
    MacroGroupsService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/macro-groups").toPromise()];
            });
        });
    };
    MacroGroupsService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/macro-group", body).toPromise()];
            });
        });
    };
    MacroGroupsService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/macro-group/" + id, body).toPromise()];
            });
        });
    };
    MacroGroupsService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/macro-group/" + id).toPromise()];
            });
        });
    };
    MacroGroupsService.prototype.fetchMacroGroupPartners = function (macroGroupId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/stores/macro-group?macroGroupIds=" + macroGroupId).toPromise()];
            });
        });
    };
    MacroGroupsService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/macro-group/" + id).toPromise()];
            });
        });
    };
    MacroGroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MacroGroupsService_Factory() { return new MacroGroupsService(i0.ɵɵinject(i1.HttpClient)); }, token: MacroGroupsService, providedIn: "root" });
    return MacroGroupsService;
}());
export { MacroGroupsService };
