import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'

type RoadpassReceivableFiltersDTO = {
  fromDate: string
  toDate: string
  status?: 'AGENDADO' | 'LIQUIDADO'
  storeId?: string
}

@Injectable({
  providedIn: 'root',
})
export class ReceivablesService {
  public loading

  constructor (private readonly http: HttpClient) { }

  listAll (params: any): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/receivables`, { params })
      .pipe(
        tap((_) => this.log('/receivables')),
        catchError(this.handleError),
      )
  }

  listAllFromRoadpass (params: RoadpassReceivableFiltersDTO): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/business-partners/receivables`, { params })
      .pipe(
        catchError(this.handleError),
      )
  }

  async createReport (params?: any): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/cms/business-partners/receivables/export`, { params })
      .toPromise()
  }

  private handleError (error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log (message: string) { }
}
