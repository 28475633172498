import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StorePaymentAnticipatedTransactionFormComponent = /** @class */ (function () {
    function StorePaymentAnticipatedTransactionFormComponent(errorsService, formBuilder, storeService, transactionService, router, route, snackBar, currencyPipe) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.transactionService = transactionService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.states = [];
        this.plans = [];
        this.installments = [1];
        this.isValidTotalValue = false;
        this.isValidTotalValueMsg = '';
        this.reservationValueInvalid = false;
        this.headersTable = ['minimumTransactionValue', 'minimumInstallmentValue'];
        this.transactionsInfo = [];
        this.subStores = [];
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', storeFormGroupValue: [] };
    }
    StorePaymentAnticipatedTransactionFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
            _this.storeID = params.storeID;
        });
        this.userRole = localStorage.getItem('userRole');
        this.initForm();
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.initForm = function () {
        var _this = this;
        if (!this.storeID) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
        }
        this.formGroup = this.formBuilder.group({
            customerCpf: [
                null,
                [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
            ],
            totalValue: [null, [Validators.required]],
            description: [null, [Validators.required]],
            maximumInstallments: [1, [Validators.required]],
            isMembershipActive: [null, [Validators.required]],
            isPayActive: [null, [Validators.required]],
            reservationValue: [null, [Validators.required]],
            paymentDate: [null, [Validators.required]],
            maximumInstallmentsReserve: [null, [Validators.required]],
            subStoreId: [null, []],
        });
        if (this.storeID != '' && this.storeID != null) {
            var storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
            if (storeSelected && this.userRole != 'ADMIN') {
                this.storeID = storeSelected.storeId;
            }
            this.storeService.getStore(this.storeID).subscribe(function (response) {
                var e_1, _a;
                try {
                    for (var _b = tslib_1.__values(response.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var st = _c.value;
                        if (st.subStore.length) {
                            _this.subStores.push(st.subStore[0]);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                if (_this.subStores.length) {
                    _this.subStores.sort(function (a, b) {
                        return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0;
                    });
                }
                _this.store = response;
                _this.formGroup = _this.formBuilder.group({
                    customerCpf: [
                        null,
                        [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
                    ],
                    totalValue: [null, [Validators.required]],
                    description: [null, [Validators.required]],
                    maximumInstallments: [1, [Validators.required]],
                    isMembershipActive: [_this.store.isMembershipActive, [Validators.required]],
                    isPayActive: [_this.store.isPayActive, [Validators.required]],
                    reservationValue: [null, [Validators.required]],
                    paymentDate: [null, [Validators.required]],
                    maximumInstallmentsReserve: [null, [Validators.required]],
                    subStoreId: [null, []],
                });
                _this.transactionsInfo = [
                    {
                        minimumTransactionValue: _this.store.minimumTransactionValue / 100,
                        minimumInstallmentValue: _this.store.minimumInstallmentValue / 100,
                    },
                ];
            });
        }
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.changeValueTotal = function (event) {
        this.isValidTotalValue = false;
        if (this.formGroup.value.totalValue * 100 >= this.store.minimumTransactionValue) {
            this.isValidTotalValueMsg = "O valor m\u00EDnimo da Transa\u00E7\u00E3o \u00E9 de R$ " + this.store.minimumTransactionValue;
            this.isValidTotalValue = true;
            this.calcTotalInstallments();
        }
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.calcTotalInstallments = function () {
        this.installments = [1];
        for (var index = 2; index <= this.store.maximumInstallmentsPerTransaction; index++) {
            if ((this.formGroup.value.totalValue * 100) / index >= this.store.minimumInstallmentValue) {
                this.installments.push(index);
            }
        }
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.changeValueReservation = function () {
        if (this.formGroup.value.totalValue * 100 < this.formGroup.value.reservationValue * 100) {
            return (this.reservationValueInvalid = true);
        }
        return (this.reservationValueInvalid = false);
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.convertCentsToMoney = function (value) {
        this.currencyPipe.transform(value, 'BRL');
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.convertNumberToBoolean = function (num) {
        if (num == 0) {
            return false;
        }
        else {
            return true;
        }
    };
    StorePaymentAnticipatedTransactionFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var totalValue, reservationValue, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.formGroup.value.totalValue * 100 < this.store.minimumTransactionValue) {
                    this.formGroup.controls.totalValue.setErrors({ transactionNotMatch: true });
                }
                else {
                    this.formGroup.controls.totalValue.setErrors(null);
                }
                if (this.formGroup.value.totalValue * 100 < this.formGroup.value.reservationValue * 100) {
                    this.reservationValueInvalid = true;
                }
                else {
                    this.reservationValueInvalid = false;
                }
                if (!this.formGroup.valid || this.reservationValueInvalid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '');
                reservationValue = this.formGroup.value.reservationValue.toFixed(2).replace('.', '');
                data = {
                    customerCpf: this.formGroup.value.customerCpf,
                    totalValue: parseInt(totalValue),
                    descriptionPayment: this.formGroup.value.description,
                    description: this.formGroup.value.description + " (RESERVA)",
                    maximumInstallments: parseInt(this.formGroup.value.maximumInstallments),
                    isMembershipActive: this.formGroup.value.isMembershipActive,
                    isPayActive: this.formGroup.value.isPayActive,
                    storeId: this.storeID,
                    paymentDate: this.formGroup.value.paymentDate,
                    reservationValue: parseInt(reservationValue),
                    withReservation: true,
                    maximumInstallmentsReserve: parseInt(this.formGroup.value.maximumInstallmentsReserve),
                    subStoreId: this.formGroup.value.subStoreId,
                };
                this.transactionService.addTransaction(data).subscribe(function (response) {
                    if (response.isCreated) {
                        _this.snackBar.open('Transação criada com sucesso.');
                        _this.router.navigate(['./dashboard/transactions/payment/' + response.paymentId], { queryParams: _this.params, skipLocationChange: true });
                    }
                }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return StorePaymentAnticipatedTransactionFormComponent;
}());
export { StorePaymentAnticipatedTransactionFormComponent };
