import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import { MatTableDataSource, } from '@angular/material';
var NotificationTypesListComponent = /** @class */ (function () {
    function NotificationTypesListComponent(notificationService, snackBar, dialog, location, route) {
        this.notificationService = notificationService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.route = route;
    }
    NotificationTypesListComponent.prototype.ngOnInit = function () {
        this.notificationList();
        this.headersTable = ['name', 'type', 'actions'];
    };
    NotificationTypesListComponent.prototype.notificationList = function () {
        var _this = this;
        this.notificationService
            .listTypes()
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = new MatTableDataSource(response);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    NotificationTypesListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse tipo de notificação?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.notificationService.deleteType(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.notificationList();
                                this.snackBar.open('tipo de notificação removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return NotificationTypesListComponent;
}());
export { NotificationTypesListComponent };
