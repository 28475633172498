import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Questions } from '../models/questions'
import { Quizzes } from '../models/quiz'

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  public loading

  constructor (private readonly http: HttpClient) {}

  async list (id: string, filter?): Promise<{ results: Quizzes[], total: number }> {
    return this.http
      .get<{ results: Quizzes[], total: number }>(
        `${environment.apiUrl}/cms/quizzes/${id}/questions${filter || ''}`,
    )
      .toPromise()
  }

  async findOne (id: string, quizID: string): Promise<Questions> {
    return this.http
      .get<Questions>(`${environment.apiUrl}/cms/quizzes/${id}/questions/${quizID}`)
      .toPromise()
  }

  async create (id: string, data): Promise<Questions> {
    return this.http
      .post<Questions>(`${environment.apiUrl}/cms/quizzes/${id}/questions`, data)
      .toPromise()
  }

  async update (id: string, questiosID: string, data): Promise<Questions> {
    return this.http
      .put<Questions>(`${environment.apiUrl}/cms/quizzes/${id}/questions/${questiosID}`, data)
      .toPromise()
  }

  async changeOrder (id: string, data): Promise<Questions> {
    return this.http
      .patch<Questions>(`${environment.apiUrl}/cms/quizzes/${id}/questions/change-order`, data)
      .toPromise()
  }

  async delete (quizID: string, id: string): Promise<Questions> {
    return this.http
      .delete<Questions>(`${environment.apiUrl}/cms/quizzes/${quizID}/questions/${id}`)
      .toPromise()
  }
}
