<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Regulamentos</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/regulations/new">ADICIONAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="regulations">

      <ng-container matColumnDef="heading">
        <th mat-header-cell *matHeaderCellDef>Título</th>
        <td mat-cell *matCellDef="let element">{{ element.heading }}</td>
      </ng-container>

      <ng-container matColumnDef="subtitle">
        <th mat-header-cell *matHeaderCellDef>Subtítulo</th>
        <td mat-cell *matCellDef="let element">{{ element.subtitle }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Criado em</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef>Atualizado em</th>
        <td mat-cell *matCellDef="let element">{{ element.updatedAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <!--<button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="openRemoveDialog(element.id)">
            <mat-icon>delete</mat-icon>
          </button>-->
          <button mat-icon-button matTooltip="Editar" matTooltipPosition="left" (click)="viewRegulation(element)"
            routerLink="/dashboard/regulations/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>