import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var StoreCampaignReportComponent = /** @class */ (function () {
    function StoreCampaignReportComponent(campaignService, route) {
        this.campaignService = campaignService;
        this.route = route;
        this.headersTableReportInfo = [
            'CampaignId',
            'CampaignDesc',
            'CampaignInitialDate',
            'CampaignEndDate',
            'macroGroup',
        ];
        this.headersTableReportDefaultCampaignInfo = [
            'CampaignId',
            'CampaignDesc',
            'CampaignInitialDate',
            'macroGroup',
        ];
    }
    StoreCampaignReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.storeID = this.route.snapshot.paramMap.get('storeID');
        this.campaignService.fetchCampaignsReport(this.storeID)
            .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var activeCampaigns, inactiveCampaigns, futureCampaigns, defaultCampaigns;
            return tslib_1.__generator(this, function (_a) {
                activeCampaigns = [];
                inactiveCampaigns = [];
                futureCampaigns = [];
                defaultCampaigns = [];
                response.report.map(function (item) {
                    if (item.macroGroupId) {
                        item.campaigns = tslib_1.__assign({}, item.campaigns, { macroGroup: item.macroGroups.macroName });
                    }
                    else {
                        item.campaigns = tslib_1.__assign({}, item.campaigns, { macroGroup: 'N/A' });
                    }
                    if (item.campaigns.isDefault) {
                        defaultCampaigns.push(item.campaigns);
                    }
                    else {
                        if (Date.parse(item.campaigns.campaignEndDate) >= Date.now()
                            && Date.parse(item.campaigns.campaignInitialDate) <= Date.now()) {
                            activeCampaigns.push(item.campaigns);
                        }
                        else if (Date.parse(item.campaigns.campaignEndDate) < Date.now()) {
                            inactiveCampaigns.push(item.campaigns);
                        }
                        else if (Date.parse(item.campaigns.campaignInitialDate) > Date.now()) {
                            futureCampaigns.push(item.campaigns);
                        }
                    }
                });
                this.parsedActiveCampaigns = new MatTableDataSource(activeCampaigns);
                this.parsedInactiveCampaigns = new MatTableDataSource(inactiveCampaigns);
                this.parsedFutureCampaigns = new MatTableDataSource(futureCampaigns);
                this.parsedDefaultCampaigns = new MatTableDataSource(defaultCampaigns);
                return [2 /*return*/];
            });
        }); }, function (error) { return console.log(error); });
    };
    return StoreCampaignReportComponent;
}());
export { StoreCampaignReportComponent };
