import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { MacroGroupsService } from '../../../../services/macro-groups.service'

@Component({
  selector: 'app-macro-group-form',
  templateUrl: './macro-group-form.component.html',
  styleUrls: ['./macro-group-form.component.scss'],
})
export class MacroGroupFormComponent implements OnInit {
  public formGroup: FormGroup
  public macroGroupID: string

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public macroGroupsService: MacroGroupsService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.macroGroupID = this.route.snapshot.paramMap.get('macroGroupID')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      macroName: [null, [Validators.required]],
    })
    if (this.macroGroupID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.macroGroupsService.findOne(this.macroGroupID).then(async response => {
      this.formGroup.patchValue({
        macroName: response.macroName,
      })
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      macroName: this.formGroup.value.macroName,
    }

    if (this.macroGroupID) {
      this.macroGroupsService.update(this.macroGroupID, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/macro-group'])
            this.snackBar.open('Informação atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.macroGroupsService.create(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/macro-group'])
            this.snackBar.open('Informação criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
