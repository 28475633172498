import { FormGroup } from '@angular/forms';

export function PasswordValidator(password: string, newPassword: string) {
  return (formGroup: FormGroup) => {
    const controlPassword = formGroup.controls[password];
    const controlNewPassword = formGroup.controls[newPassword];

    if (
      controlNewPassword.errors &&
      !controlNewPassword.errors.confirmedValidator
    ) {
      return;
    }

    if (controlPassword.value !== controlNewPassword.value) {
      controlNewPassword.setErrors({ passwordNotMatch: true });
    } else {
      controlNewPassword.setErrors(null);
    }
  };
}
