<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVO USUÁRIO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.firstName)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.lastName)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.emailAddress)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <input matInput formControlName="phoneNumber" mask="(00)00000-0000"/>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{
                  errorsService.messageErrorFor(formGroup.controls.phoneNumber)
                }}
              </mat-error>
            </mat-form-field>
            
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>
          Salvar
        </button>
      </div>
    </mat-card>
  </form>
</app-logged>
