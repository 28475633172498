import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AirportsService } from '../../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-airport-company-customers-list',
  templateUrl: './airport-company-customers-list.component.html',
  styleUrls: ['./airport-company-customers-list.component.scss'],
})
export class AirportCompanyCustomersListComponent implements OnInit {
  public headersTable: string[] = ['cpf', 'fullName', 'isClient', 'percentage', 'actions']
  public headersTableDelete: string[] = ['cpf', 'fullName', 'isClient', 'percentage', 'deletedAt']
  dataSource: any
  dataSourceDelete: any
  public formGroup: FormGroup
  id: any
  company: any
  addNew = true

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.list().catch(err => { console.log(err) })
    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async findOne () {
    await this.airportsService.fideOneCompanyAirport(this.id)
      .then(async response => {
        this.company = response
      })
  }

  async list () {
    const { results, total } = await this.airportsService
      .listCompanyAirportCustomers(this.id)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
    let count = 0
    results.map(r => (count = count + Number(r.percentage) * 100))
    this.addNew = !(total > 3 || count > 99)
    this.dataSource = new MatTableDataSource(results)

    const listDelete = await this.airportsService
      .listCompanyAirportCustomersDelete(this.id)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSourceDelete = new MatTableDataSource(listDelete.results)
  }

  remove (id, companyId) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Ao excluir esse CPF será necessário vincular outro CPF com a mesma porcentagem, ou repassar a porcentagem para outro(s) CPF(s) cadastrado(s).',
          buttonNot: 'Remover',
          buttonConfirmText: 'Vincular outro CPF',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result === 1) {
          return this.airportsService.deleteAirportCompanyCustomer(id, '?removeTaxa=not').then(
            async response => {
              this.list().catch(error => console.log(error))
              await this.router.navigate([`./dashboard/airport-company/${companyId}/customer/new`])
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        } else if (result && result === 'not') {
          return this.airportsService.deleteAirportCompanyCustomer(id, '?removeTaxa=yes').then(
            async response => {
              this.list().catch(error => console.log(error))
              await this.snackBar.open('Cliente removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
