import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var BannerPagesAppListComponent = /** @class */ (function () {
    function BannerPagesAppListComponent(BannersPagesAppService, snackBar, dialog, route, location) {
        this.BannersPagesAppService = BannersPagesAppService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.location = location;
        this.headersTable = ['page', 'actions'];
        this.banners = [];
    }
    BannerPagesAppListComponent.prototype.ngOnInit = function () {
        this.page = this.route.snapshot.paramMap.get('page');
        this.idPage = this.route.snapshot.paramMap.get('idPage');
        this.list();
    };
    BannerPagesAppListComponent.prototype.list = function () {
        var _this = this;
        this.BannersPagesAppService.list().then(function (response) {
            _this.banners = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    BannerPagesAppListComponent.prototype.goBack = function () {
        this.location.back();
    };
    BannerPagesAppListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Macro grupo?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.BannersPagesAppService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list();
                                this.snackBar.open('Banner removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return BannerPagesAppListComponent;
}());
export { BannerPagesAppListComponent };
