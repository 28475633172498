<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Pré Cadastro CNPJ</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="airport">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Cliente</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>JCoins</th>
        <td mat-cell *matCellDef="let element">{{ element.balance || 0 }}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Usuarios"
            routerLink="/dashboard/airport-company/pre-register/{{ element.id }}/customer/new"
            matTooltipPosition="left"
          >
            <mat-icon>supervisor_account</mat-icon>
          </button>
        </td></ng-container
      > -->

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
