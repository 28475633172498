import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface DialogData {
  document: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public formGroup: FormGroup;
  public cnpjMask = '00.000.000/0000-00';
  public cnpjLabel = 'CNPJ'; 
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar) { }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }
  
    const documentValue = this.formGroup.value.cnpj.replace(/\D/g, '');
  
    this.dialogRef.close({
      document: documentValue,
      isInternationalPartner: this.formGroup.value.isInternationalPartner
    });
  }
  

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      cnpj: [null, [
        Validators.required,
        Validators.minLength(5),
      ]],
      isInternationalPartner: [false]
    });
  }

  toggleMask(isInternational: boolean): void {
    this.formGroup.get('cnpj').setValue('');
    this.cnpjMask = isInternational ? '' : '00.000.000/0000-00';
    this.cnpjLabel = isInternational ? 'Documento' : 'CNPJ';
  }
}