import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
interface DialogData {
  cnpj: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-roadpass-unable-to-cancel.component.html',
  styleUrls: ['./modal-roadpass-unable-to-cancel.component.scss']
})
export class ModalRoadpassUnableToCancel {

  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalRoadpassUnableToCancel>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar) {
 
     }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
  
}

