import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ClientExportToSalesforceComponent = /** @class */ (function () {
    function ClientExportToSalesforceComponent(http) {
        this.http = http;
        this.exportRunning = false;
        this.updateRunning = false;
        this.watchRunning = false;
        this.stopIntervalNextTick = false;
        this.whatchRunning = false;
        this.from = 0;
        this.quantity = 4;
    }
    ClientExportToSalesforceComponent.prototype.executeStartExport = function () {
        return this.http.get(environment.apiUrl + "/cms/customer/salesforce/start-export", { headers: { noloading: 'true' } }).toPromise();
    };
    ClientExportToSalesforceComponent.prototype.executeUpdate = function () {
        return this.http.get(environment.apiUrl + "/cms/customer/salesforce/update-exported?qtd=10", { headers: { noloading: 'true' } }).toPromise();
    };
    ClientExportToSalesforceComponent.prototype.ngOnInit = function () {
    };
    ClientExportToSalesforceComponent.prototype.startExportClick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.exportRunning)
                            return [2 /*return*/];
                        this.exportRunning = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.startWatchData();
                        return [4 /*yield*/, this.executeStartExport()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log("ERRO AO EXPORTAR", error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.exportRunning = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientExportToSalesforceComponent.prototype.startUpdateClick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.updateRunning)
                            return [2 /*return*/];
                        this.updateRunning = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.startWatchData();
                        return [4 /*yield*/, this.executeUpdate()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.log("ERRO AO EXPORTAR", error_2);
                        return [3 /*break*/, 4];
                    case 4:
                        this.updateRunning = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientExportToSalesforceComponent.prototype.getExportDataList = function () {
        return this.http.get(environment.apiUrl + "/cms/customer/salesforce/list-exported", { headers: { noloading: 'true' } }).toPromise();
    };
    ClientExportToSalesforceComponent.prototype.startWatchData = function () {
        var _this = this;
        this.watchRunning = true;
        this.interval = setInterval(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getExportDataList()];
                    case 1:
                        res = _a.sent();
                        this.exportData = res;
                        if (this.stopIntervalNextTick) {
                            this.stopIntervalNextTick = false;
                            this.watchRunning = false;
                            clearInterval(this.interval);
                        }
                        return [2 /*return*/];
                }
            });
        }); }, 3000);
    };
    ClientExportToSalesforceComponent.prototype.stopWatchData = function () {
        this.watchRunning = false;
        this.stopIntervalNextTick = true;
    };
    ClientExportToSalesforceComponent.prototype.getJCoinsFromJsonPayload = function (payload) {
        var saldo = '-';
        if (payload) {
            var obj = JSON.parse(payload);
            saldo = obj.Saldo_jCoins__c;
            if (obj.updateJcoins && obj.updateJcoins.data)
                saldo += " | updated: " + obj.updateJcoins.data.Saldo_jCoins__c;
        }
        return saldo;
    };
    return ClientExportToSalesforceComponent;
}());
export { ClientExportToSalesforceComponent };
