import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, } from '@angular/material';
var QuizCustomerListComponent = /** @class */ (function () {
    function QuizCustomerListComponent(router, route, snackBar, dialog, quizService, storeService, location) {
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.quizService = quizService;
        this.storeService = storeService;
        this.location = location;
        this.customerID = '';
        this.fullName = '';
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        this.params = { formGroupValue: [] };
    }
    QuizCustomerListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.customerID = this.route.snapshot.paramMap.get('customerID');
        this.headersTable = ['name', 'amountAnswer', 'actions'];
        if (this.customerID) {
            this.storeService.listUsersCustomers("/" + this.customerID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.fullName = response.firstName + "  " + response.lastName;
                    return [2 /*return*/];
                });
            }); }, function (error) {
                console.log(error);
                alert(error.error.message);
            });
        }
        this.quizList();
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameQuizCustomer',
                label: 'Questionário',
                placeholder: 'Questionário',
            },
        ];
    };
    QuizCustomerListComponent.prototype.quizList = function () {
        var _this = this;
        this.quizService
            .listCustomers(this.customerID, "?filters={\"page\":" + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.results.length;
                localStorage.setItem('quiz', JSON.stringify(response.results));
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuizCustomerListComponent.prototype.goBack = function () {
        this.location.back();
    };
    QuizCustomerListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.results.length;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        localStorage.setItem('quiz', JSON.stringify(returnFilter.results));
    };
    return QuizCustomerListComponent;
}());
export { QuizCustomerListComponent };
