<app-logged class="store-form">
  <header class="store-form__header">
    <h2>ESTOQUE DE JCOINS</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="openFilterModal()">EXPORTAR</button>
      <button class="btn-primary-light" mat-button routerLink="/dashboard/reports">VOLTAR</button>
    </div>
  </header>

  <app-form-filter
    actions="form"
    type="reportsConsolidated"
    [fieldsForm]="fieldsForm"
    [formGroupValue]="formGroup"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>

  <div *ngIf="date" class="table-responsive">
    <mat-card class="card-category">
      <div><b>Saldo inicial gerado do dia {{ date }}</b></div>
    </mat-card>
  </div>

  <table mat-table [dataSource]="dataSourceLastMonth">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="
            element.name !== 'sumTotalLastMonth' &&
            element.name !== 'totalAmount'
          "
        >
          <div style="padding-left:25px">
            {{ convertName(element.name) }}
          </div>
        </div>
        <b
          *ngIf="
            element.name === 'sumTotalLastMonth' ||
            element.name === 'totalAmount'
          "
          >{{ convertName(element.name) }}</b
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Saldo JCoins</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="
            element.name !== 'sumTotalLastMonth' &&
            element.name !== 'totalAmount'
          "
        >
          {{ element.totalJcoins | number:'':'pt-BR' }}
        </div>
        <b
          *ngIf="
            element.name === 'sumTotalLastMonth' ||
            element.name === 'totalAmount'
          "
          >{{ element.totalJcoins | number:'':'pt-BR' }}</b
        >
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
    <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
  </table>
  <br>
  <table mat-table [dataSource]="typesAccumulation">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element">
        <div
        *ngIf="
          element.name !== 'totalAmount' &&
            element.name !== 'typeAccumulationApp' &&
            element.name !== 'typeAccumulationManual'&&
            element.name !== 'movements' &&
            element.name !== 'totalRescue' &&
            element.name !== 'totalMovements' &&
            element.name !== 'totalFinal' &&
            element.name !== 'totalReversed'
        "
        >
          <div style="padding-left:25px">
            {{ convertName(element.name) }}
            <mat-icon
              *ngIf="element.name === 'totalAccumulationAirportsCompany' || element.name === 'totalAccumulationIncorporations'"
              class="smallicon" matTooltip="Saldo apenas para referência. Os jcoins deste acúmulo estão incluídos nos saldos acima.">
              info
            </mat-icon>
          </div>
        </div>
        <b
          *ngIf="
            element.name === 'totalAmount' ||
            element.name === 'typeAccumulationApp' ||
            element.name === 'typeAccumulationManual'||
            element.name === 'movements'||
            element.name === 'totalRescue' ||
            element.name === 'totalMovements' ||
            element.name === 'totalFinal' ||
            element.name === 'totalReversed'
          "
          >{{ convertName(element.name) }}
        </b>
          <mat-icon
            *ngIf="element.name === 'totalMovements'"
            class="smallicon" matTooltip="TOTAL MOVIMENTAÇÕES = (total Acumulo APP + total Acumulo fora app + total de Estorno de Resgate) - total Resgate">
            info
          </mat-icon>
          <mat-icon
            *ngIf="element.name === 'totalFinal'" class="smallicon"
            matTooltip="SALDO FINAL = Total Saldo Inicial + Total Movimentações">
            info
          </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Saldo JCoins</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="
            element.name !== 'totalAmount' &&
            element.name !== 'typeAccumulationApp' &&
            element.name !== 'typeAccumulationManual' &&
            element.name !== 'totalRescue' &&
            element.name !== 'totalMovements' &&
            element.name !== 'totalFinal' &&
            element.name !== 'totalReversed'
          "
        >
          {{ element.totalJcoins | number:'':'pt-BR' }}
        </div>
        <b
          *ngIf="
            element.name === 'totalAmount' ||
            element.name === 'typeAccumulationApp' ||
            element.name === 'typeAccumulationManual' ||
            element.name === 'movements'||
            element.name === 'totalRescue' ||
            element.name === 'totalMovements' ||
            element.name === 'totalFinal' ||
            element.name === 'totalReversed'
          "
          >{{ element.totalJcoins | number:'':'pt-BR' }}
        </b
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
    <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
  </table>
</app-logged>
