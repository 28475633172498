import { Component, OnInit } from '@angular/core'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { QuizService } from '../../../../services/quiz.service'

@Component({
  selector: 'app-quiz-client-list',
  templateUrl: './quiz-client-list.component.html',
  styleUrls: ['./quiz-client-list.component.scss'],
})
export class QuizClientListComponent implements OnInit {
  public quizID = ''
  public name = ''
  public headersTable: string[]
  public pageTotal: number
  dataSource: any
  fieldsForm: any
  public params = { formGroupValue: [] }

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  constructor (
    public router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quizService: QuizService,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.headersTable = ['cpfQuizClient', 'nameQuizClient', 'amountAnswer', 'actions']
    this.quizID = this.route.snapshot.paramMap.get('quizID')
    if (this.quizID) {
      this.quizService.findOne(this.quizID).then(
        response => {
          this.name = response.name
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )

      this.fieldsForm = [
        {
          field: 'input',
          type: 'text',
          formControlName: 'cpfQuizClient',
          label: 'CPF',
          placeholder: 'CPF do Cliente',
        },
        {
          field: 'input',
          type: 'text',
          formControlName: 'nameQuizClient',
          label: 'Nome',
          placeholder: 'Nome cliente',
        },
      ]
    }

    this.quizClientList()
  }

  quizClientList () {
    this.quizService
      .listQuizClient(this.quizID, `?filters={"page":${this.page}, "pageSize": ${this.pageSize}}`)
      .then(
        async response => {
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
