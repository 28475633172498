import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '../../../../services/uploadAdapter.service';
var QuizFormComponent = /** @class */ (function () {
    function QuizFormComponent(router, route, formBuilder, errorsService, snackBar, dialog, quizService, location) {
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.quizService = quizService;
        this.location = location;
        this.Editor = ClassicEditor;
        this.id = '';
        this.descriptionInvalid = false;
        this.initalized = false;
        this.params = { formGroupValue: [] };
    }
    QuizFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            quiz: [null, [Validators.required]],
            description: [null, []],
        });
        this.config = {
            image: {
                toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
            },
        };
        if (this.id) {
            this.getById();
        }
    };
    QuizFormComponent.prototype.onReady = function (eventData) {
        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
        };
    };
    QuizFormComponent.prototype.selectFile = function (event) {
        var _this = this;
        var file = event.target.files[0];
        this.uploadData = file;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
            _this.imageUrl = e.target.result;
        };
    };
    QuizFormComponent.prototype.removeImage = function () {
        this.imageUrl = undefined;
        this.uploadData = null;
        this.approveDoc = undefined;
    };
    QuizFormComponent.prototype.imgLoadError = function () {
        this.imageUrl = undefined;
    };
    QuizFormComponent.prototype.getById = function () {
        var _this = this;
        this.quizService.findOne(this.id).then(function (response) {
            _this.formGroup.patchValue({
                quiz: response.name,
                description: response.description,
            });
            _this.imageUrl = response.image;
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuizFormComponent.prototype.submit = function () {
        var _this = this;
        var imageUrl = this.imageUrl;
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, readUrl, uploadUrl, data;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.formGroup.value.quiz) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        if (!this.uploadData) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.quizService.getS3Url()];
                    case 1:
                        _a = _b.sent(), readUrl = _a.readUrl, uploadUrl = _a.uploadUrl;
                        imageUrl = readUrl;
                        return [4 /*yield*/, this.quizService.uploadToS3(uploadUrl, this.uploadData)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        data = {
                            name: this.formGroup.value.quiz,
                            description: this.formGroup.value.description || '',
                            image: imageUrl || '',
                        };
                        if (this.id) {
                            this.quizService.update(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.location.back();
                                    this.snackBar.open('Questionário alterado com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.message);
                            });
                        }
                        else {
                            this.quizService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.location.back();
                                    this.snackBar.open('Questionário cadastrado com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                _this.snackBar.open(error.message);
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); })().catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    return QuizFormComponent;
}());
export { QuizFormComponent };
