import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var AirportCompanyCustomersListComponent = /** @class */ (function () {
    function AirportCompanyCustomersListComponent(airportsService, snackBar, dialog, route, router) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.headersTable = ['cpf', 'fullName', 'isClient', 'percentage', 'actions'];
        this.headersTableDelete = ['cpf', 'fullName', 'isClient', 'percentage', 'deletedAt'];
        this.addNew = true;
    }
    AirportCompanyCustomersListComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.list().catch(function (err) { console.log(err); });
        if (this.id) {
            this.findOne().catch(function (err) { return console.log(err); });
        }
    };
    AirportCompanyCustomersListComponent.prototype.findOne = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.fideOneCompanyAirport(this.id)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.company = response;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyCustomersListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, results, total, count, listDelete;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.airportsService
                            .listCompanyAirportCustomers(this.id)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { results: [], total: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        count = 0;
                        results.map(function (r) { return (count = count + Number(r.percentage) * 100); });
                        this.addNew = !(total > 3 || count > 99);
                        this.dataSource = new MatTableDataSource(results);
                        return [4 /*yield*/, this.airportsService
                                .listCompanyAirportCustomersDelete(this.id)
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 2:
                        listDelete = _b.sent();
                        this.dataSourceDelete = new MatTableDataSource(listDelete.results);
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportCompanyCustomersListComponent.prototype.remove = function (id, companyId) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Ao excluir esse CPF será necessário vincular outro CPF com a mesma porcentagem, ou repassar a porcentagem para outro(s) CPF(s) cadastrado(s).',
                buttonNot: 'Remover',
                buttonConfirmText: 'Vincular outro CPF',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result === 1) {
                    return [2 /*return*/, this.airportsService.deleteAirportCompanyCustomer(id, '?removeTaxa=not').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.list().catch(function (error) { return console.log(error); });
                                        return [4 /*yield*/, this.router.navigate(["./dashboard/airport-company/" + companyId + "/customer/new"])];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                else if (result && result === 'not') {
                    return [2 /*return*/, this.airportsService.deleteAirportCompanyCustomer(id, '?removeTaxa=yes').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.list().catch(function (error) { return console.log(error); });
                                        return [4 /*yield*/, this.snackBar.open('Cliente removido com sucesso.')];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    return AirportCompanyCustomersListComponent;
}());
export { AirportCompanyCustomersListComponent };
