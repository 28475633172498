import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitsService {
  public loading

  constructor(private readonly http: HttpClient) { }

  async list(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/business-units`).toPromise()
  }
}
