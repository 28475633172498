<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Exportar clientes para Salesforce</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/clients">VOLTAR</button>
    </div>
  </header>


  
  <button class="btn-primary-light" mat-button (click)="startExportClick()">{{exportRunning ? "Exportação iniciada...": "Iniciar exportação"}}</button>
&nbsp;
  <button class="btn-primary-light" mat-button (click)="startUpdateClick()">{{updateRunning ? "Atualização iniciada...": "Iniciar Atualização de Saldo"}}</button>
  <div>
    <br>
    <button class="btn-primary-light" mat-button (click)="watchRunning ? stopWatchData() : startWatchData()">
      {{watchRunning? "Parar" : "Pegar informações da exportação"}}
    </button>
    &nbsp;<mat-icon *ngIf="watchRunning" class="spin">loop</mat-icon>
    
    <table  *ngIf="exportData && exportData.length > 0" class="tabela">
      <thead>
        <tr >
          <td>CPF</td>        
          <td>Nome</td>        
          <td>Importado com Sucesso</td>        
          <td>Saldo Jcoins</td>        
          <td>Resposta Zoop</td>
          <td>Payload</td>
          <td>Data</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of exportData" >
          <td>{{item.cpf}}</td>   
          <td>{{item.customerName}}</td>   
          <td>
            <mat-icon *ngIf="item.success" style="color:green">check_circle</mat-icon>
            <mat-icon *ngIf="!item.success" style="color:red">cancel</mat-icon>          
          </td> 
          <td>{{getJCoinsFromJsonPayload(item.salesforcePayload)}}</td>       
          <td width="20%" >
            <textarea>
              {{item.salesforceResponse}}
            </textarea>
          </td>
          <td width="20%" >
            <textarea>
              {{item.salesforcePayload}}
            </textarea>
          </td>
          <td>
            {{item.createdAt | date: 'dd/MM/yyyy hh:mm:ss'}}
          </td>
        </tr>
      <tbody>

    </table>
    
  </div>


</app-logged>
