<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Empresas</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="report()">EXPORTAR</button>
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-company/new">
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter
    maskExample="00.000.000/0000-00"
    actions="form"
    type="architectCompany"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
    [formGroupValue]="formGroup"
  >
  </app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{ element.cnpj | mask: '00.000.000/0000-00' }}</td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>Nome da Empresa</th>
        <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
      </ng-container>

      <ng-container matColumnDef="isCpf">
        <th mat-header-cell *matHeaderCellDef width="145px">CPF cadastrado</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="!element.customers.length" matTooltip="Não">cancel</mat-icon>
          <mat-icon *ngIf="element.customers.length" matTooltip="Sim"
            >check_circle_outline</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Usuarios"
            routerLink="/dashboard/architect-company/{{ element.id }}/customers/new"
            matTooltipPosition="left"
          >
            <mat-icon>supervisor_account</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Termos"
            matTooltipPosition="left"
            routerLink="/dashboard/customer/{{ element.cnpj }}/terms/company"
            [queryParams]="params"
            skipLocationChange="true"
          >
            <mat-icon>description</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Editar"
            routerLink="/dashboard/architect-company/{{ element.id }}/edit"
            matTooltipPosition="left"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id, element.preRegistration)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>

    <app-form-filter
      actions="pagination"
      type="architectCompany"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"
    ></app-form-filter>
  </div>
</app-logged>
