<app-logged class="store-list">
  <header class="store-list__header">
    <h2 *ngIf="!parentId">LISTA DE PARCEIROS DA CATEGORIA</h2>
    <h2 *ngIf="parentId">LISTA DE PARCEIROS DA SUB CATEGORIA</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/categories">
        VOLTAR
      </button>
      <button
        class="btn-primary-light"
        routerLink="/dashboard/categories/{{ categoryID }}/business-partners/form"
        mat-button
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <app-form-filter
    *ngIf="isFilter"
    [categoryID]="categoryID"
    [categoryJoinId]="categoryJoinId"
    actions="form"
    type="businessPartners"
    [fieldsForm]="fieldsForm"
    (returnFilter)="receiveFeedback($event)"
    (returnFilterMain)="receiveFeedbackMain($event)"
  ></app-form-filter>

  <div class="table-responsive">
    <mat-card class="card-category" *ngIf="parentId"
      ><b>Categoria: </b>{{ category }} / <b>Subcategoria: </b>{{ heading }}</mat-card
    >
    <mat-card class="card-category" *ngIf="!parentId"><b>Categoria: </b>{{ heading }}</mat-card>
  </div>

  <!-- <div class="table-responsive">
    <b>Principais Parceiros</b>
    <table
      *ngIf="!tableFilter"
      mat-table
      [dataSource]="dataSourceMain"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      <ng-container matColumnDef="ordination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordem</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ordination }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nameCategoryMain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="activeMain">
        <th mat-header-cell *matHeaderCellDef width="145px">Ativo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" matTooltip="Ativo">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive" matTooltip="Inativo">build_circle</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsMain">
        <th mat-header-cell *matHeaderCellDef width="50px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.categoryId }}/business-partners/form/{{
              element.id
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableMain"></tr>
      <tr
        class="grabbable"
        mat-row
        *matRowDef="let row; columns: headersTableMain"
        cdkDrag
        [cdkDragStartDelay]="50"
        [cdkDragData]="row"
      ></tr>
    </table>

    <table *ngIf="tableFilter" mat-table [dataSource]="dataSourceMain">
      <ng-container matColumnDef="ordination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordem</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ordination }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nameCategoryMain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="activeMain">
        <th mat-header-cell *matHeaderCellDef width="145px">Ativo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" matTooltip="Ativo">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive" matTooltip="Inativo">build_circle</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsMain">
        <th mat-header-cell *matHeaderCellDef width="50px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.categoryId }}/business-partners/form/{{
              element.id
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableMain"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableMain"></tr>
    </table>
  </div> -->

  <div class="table-responsive">
    <b>Principais Parceiros</b>
    <table *ngIf="!tableFilter" mat-table [dataSource]="dataSourceMain">
      <ng-container matColumnDef="nameCategoryMain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="activeMain">
        <th mat-header-cell *matHeaderCellDef width="145px">Ativo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" matTooltip="Ativo">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive" matTooltip="Inativo">build_circle</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsMain">
        <th mat-header-cell *matHeaderCellDef width="50px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.categoryId }}/business-partners/form/{{
              element.id
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableMain"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableMain"></tr>
    </table>

    <table *ngIf="tableFilter" mat-table [dataSource]="dataSourceMain">
      <ng-container matColumnDef="ordination">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordem</th>
        <td mat-cell *matCellDef="let element">
          {{ element.ordination }}
        </td>
      </ng-container>
      <ng-container matColumnDef="nameCategoryMain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="activeMain">
        <th mat-header-cell *matHeaderCellDef width="145px">Ativo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" matTooltip="Ativo">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive" matTooltip="Inativo">build_circle</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsMain">
        <th mat-header-cell *matHeaderCellDef width="50px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.categoryId }}/business-partners/form/{{
              element.id
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTableMain"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTableMain"></tr>
    </table>
  </div>

  <div class="table-responsive">
    <b>Outros</b>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="nameCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef width="50px">Ativo</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.isActive" matTooltip="Ativo">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!element.isActive" matTooltip="Inativo">build_circle</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="150px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.categoryId }}/business-partners/form/{{
              element.id
            }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      *ngIf="isFilter"
      [categoryID]="categoryID"
      [categoryJoinId]="categoryJoinId"
      type="businessPartners"
      actions="pagination"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="receiveFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
