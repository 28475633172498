<app-logged class="store-list">
  <header class="store-list__header">
    <h2>FECHAMENTO</h2>
  </header>

  <div class="row">
    <div class="col-sm-12">
      <button
        type="submit"
        class="btn-primary-light btn-new"
        mat-button
        matStepperNext
        (click)="openModal()"
      >
        Novo
      </button>
    </div>
  </div>

  <div class="col-sm-12">
    <div class="table-responsive">
      <table mat-table [dataSource]="closings">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Número do Fechamento</th>
          <td mat-cell *matCellDef="let element">
            {{ element.closingNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>Período Transações</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date: 'dd/MM/yyyy':'UTC' }} até
            {{ element.endDate | date: 'dd/MM/yyyy':'UTC' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="paymentDate">
          <th mat-header-cell *matHeaderCellDef>Data Pagamento</th>
          <td mat-cell *matCellDef="let element">
            {{ element.paymentDate | date: 'dd/MM/yyyy':'UTC' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              matTooltip="Detalhe"
              matTooltipPosition="left"
              routerLink="/dashboard/closings/{{ element.id }}/stores"
            >
              <mat-icon>home_work</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="Transações"
              matTooltipPosition="left"
              routerLink="/dashboard/closings/{{ element.id }}/transactions"
            >
              <mat-icon>list_alt</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="Deletar"
              matTooltipPosition="left"
              (click)="openDialog(element.id)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
        <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
      </table>
    </div>
  </div>
</app-logged>
