import { Component, Inject, Input, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service';

@Component({
  selector: 'app-liquidation-transfer-details',
  templateUrl: './modal-liquidation-transfer-details.component.html',
  styleUrls: ['./modal-liquidation-transfer-details.component.scss'],
})
export class ModalLiquidationTransferDetails implements OnInit {
  

  public transfer

  constructor (
    public dialogRef: MatDialogRef<ModalLiquidationTransferDetails>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    console.log('data', data)
    this.transfer = data
  }

  ngOnInit () {
    // empty
  }

  
 
}
