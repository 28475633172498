import * as tslib_1 from "tslib";
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CampaignService = /** @class */ (function () {
    function CampaignService(http) {
        this.http = http;
    }
    CampaignService.prototype.listCampaigns = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/campaigns/" + (filter || '')).pipe(tap(function (_) { return _this.log('cms/campaigns'); }), catchError(this.handleError));
    };
    CampaignService.prototype.fetchCampaignsReport = function (storeId) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/campaigns/reports/" + storeId).pipe(tap(function (_) { return _this.log('cms/campaigns/reports'); }), catchError(this.handleError));
    };
    CampaignService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/campaigns/", body).toPromise()];
            });
        });
    };
    CampaignService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/campaigns/" + id, body).toPromise()];
            });
        });
    };
    CampaignService.prototype.findOne = function (id) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/campaigns/" + id).pipe(tap(function (_) { return _this.log("cms/campaigns/" + id); }), catchError(this.handleError));
    };
    CampaignService.prototype.verifyStores = function (body) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/campaigns-verify-stores/", body).pipe(tap(function (_) { return _this.log('cms/campaigns-verify-stores/'); }), catchError(this.handleError));
    };
    CampaignService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/campaigns/" + id).toPromise()];
            });
        });
    };
    CampaignService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    CampaignService.prototype.log = function (message) { };
    CampaignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignService_Factory() { return new CampaignService(i0.ɵɵinject(i1.HttpClient)); }, token: CampaignService, providedIn: "root" });
    return CampaignService;
}());
export { CampaignService };
