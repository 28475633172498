import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ZipCodeService {
  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(this.handler);
  }

  getAddress(zipCode: string): any {
    const zipCodeNumbers = zipCode && zipCode.match(/\d+/g).join('');

    return this.http.get(`${environment.zipCodeApiUrl}${zipCodeNumbers}/json`);
  }
}
