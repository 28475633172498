import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
import { enumManualJcoinCredit } from 'src/app/models/enumManualJcoinCredit';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';
var ModalReviewFormComponent = /** @class */ (function () {
    function ModalReviewFormComponent(dialogRef, dialogData, service, formBuilder, permissionsService, snackBar) {
        this.dialogRef = dialogRef;
        this.dialogData = dialogData;
        this.service = service;
        this.formBuilder = formBuilder;
        this.permissionsService = permissionsService;
        this.snackBar = snackBar;
        this.cmsPermissions = enumCmsPermissions;
        this.manualPayment = dialogData.manualJcoinCredit;
        console.info(this.manualPayment);
    }
    ModalReviewFormComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalReviewFormComponent.prototype.submit = function () {
        var _this = this;
        if (this.formGroup.invalid) {
            console.info(this.formGroup);
            this.snackBar.open("Preencha corretamente os campos e tente novamente.", '', {
                duration: 3000,
            });
            return false;
        }
        var formData = {
            reviewReason: this.formGroup.get('reviewReason').value,
            isApproved: this.formGroup.get('isApproved').value,
        };
        this.service.review(this.manualPayment.id, formData).then(function () {
            _this.dialogRef.close('success');
            _this.service.updatePendingRequestsCount();
        });
        return true;
    };
    ModalReviewFormComponent.prototype.isPending = function () {
        return this.manualPayment.status === enumManualJcoinCredit.PENDING;
    };
    ModalReviewFormComponent.prototype.getStatusColor = function (status) {
        switch (status) {
            case enumManualJcoinCredit.APPROVED:
                return 'green';
            case enumManualJcoinCredit.DENIED:
                return 'red';
            case enumManualJcoinCredit.PENDING:
            default:
                return 'grey';
        }
    };
    ModalReviewFormComponent.prototype.getStatusName = function (status) {
        switch (status) {
            case enumManualJcoinCredit.APPROVED:
                return 'APROVADO';
            case enumManualJcoinCredit.DENIED:
                return 'REPROVADO';
            case enumManualJcoinCredit.PENDING:
                return 'PENDENTE';
            default:
                return 'DESCONHECIDO';
        }
    };
    ModalReviewFormComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            reviewReason: [null, []],
            isApproved: [null, [
                    Validators.required,
                ]],
        });
    };
    ModalReviewFormComponent.prototype.getOriginName = function (name) {
        switch (name) {
            case 'JHSFID':
                return 'JHSF ID';
            case 'ARQUITETO':
                return 'ARQUITETO';
            case 'JHSFID_ARCHITECT':
                return 'JHSF ID + ARQUITETO';
            default:
                return '';
        }
    };
    ModalReviewFormComponent.prototype.getStoreObject = function (element) {
        if (element.store)
            return element.store;
        if (element.storeBusinessUnit)
            return element.storeBusinessUnit;
    };
    return ModalReviewFormComponent;
}());
export { ModalReviewFormComponent };
