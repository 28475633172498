<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <div class="col-12 col-sm-12">
            <mat-card-content>
              <mat-form-field class="full-width">
                <mat-label>Data Inicial</mat-label>
                <input type="date" matInput formControlName="startDate" />
                <mat-error *ngIf="formGroup.controls.startDate?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.startDate) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width">
                <mat-label>Data Final</mat-label>
                <input type="date" matInput formControlName="dateEnd" />
                <mat-error *ngIf="formGroup.controls.dateEnd?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.dateEnd) }}
                </mat-error>
              </mat-form-field>
              <!-- <mat-form-field class="col-sm-4">
                <mat-label>Data Pagamento</mat-label>
                <input type="date" matInput formControlName="paymentDate" />
                <mat-error *ngIf="formGroup.controls.paymentDate?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.paymentDate) }}
                </mat-error>
              </mat-form-field> -->
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="submit" class="btn-submit-dark">Gerar Fechamento</button>
      </div>
    </div>
  </form>
</main>
