import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AccountBankService = /** @class */ (function () {
    function AccountBankService(http) {
        this.http = http;
    }
    AccountBankService.prototype.list = function (storeID) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/" + storeID + "/bank-account")
            .pipe(tap(function (_) { return _this.log('/bank-account'); }), catchError(this.handleError));
    };
    AccountBankService.prototype.add = function (data, storeID) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/stores/" + storeID + "/bank-account", data)
            .pipe(tap(function (_) { return _this.log('cms/bank-accoun'); }), catchError(this.handleError));
    };
    AccountBankService.prototype.delete = function (accountID, storeID) {
        var _this = this;
        return this.http.delete(environment.apiUrl + "/cms/stores/" + storeID + "/bank-account/" + accountID)
            .pipe(tap(function (_) { return _this.log('cms/bank-accoun'); }), catchError(this.handleError));
    };
    AccountBankService.prototype.listBankCodes = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/stores/bank-codes")
            .pipe(tap(function (_) { return _this.log('/bank-codes'); }), catchError(this.handleError));
    };
    AccountBankService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    AccountBankService.prototype.log = function (message) {
    };
    AccountBankService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountBankService_Factory() { return new AccountBankService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountBankService, providedIn: "root" });
    return AccountBankService;
}());
export { AccountBankService };
