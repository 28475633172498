/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-partner-edition-macro-binding-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./modal-partner-edition-macro-binding-information.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/material/snack-bar";
var styles_ModalPartnerEditionMacroBindingInformation = [i0.styles];
var RenderType_ModalPartnerEditionMacroBindingInformation = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalPartnerEditionMacroBindingInformation, data: {} });
export { RenderType_ModalPartnerEditionMacroBindingInformation as RenderType_ModalPartnerEditionMacroBindingInformation };
function View_ModalPartnerEditionMacroBindingInformation_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" est\u00E1 vinculado a campanha "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.participatingCampaignWithMacroGroup.macroGroups.macroName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.participatingCampaignWithMacroGroup.campaigns.description; _ck(_v, 5, 0, currVal_1); }); }
function View_ModalPartnerEditionMacroBindingInformation_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPartnerEditionMacroBindingInformation_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" est\u00E1 vinculado a campanha "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.participatingCampaignWithMacroGroup.macroGroups.id; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.replaceCampaign.macroGroups.macroName; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.replaceCampaign.campaigns.description; _ck(_v, 8, 0, currVal_2); }); }
export function View_ModalPartnerEditionMacroBindingInformation_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "main", [["class", "modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "link-cancel"], ["mat-icon-button", ""], ["matTooltip", "Cancelar"], ["matTooltipPosition", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "mat-card", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AVISO"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalPartnerEditionMacroBindingInformation_1)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Para alterar o vinculo do parceiro para essa nova campanha, efetue a altera\u00E7\u00E3o diretamente na p\u00E1gina de edi\u00E7\u00E3o da campanha desejada."])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "btn-submit-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn-submit-dark"], ["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fechar"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _co.data.results; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ModalPartnerEditionMacroBindingInformation_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-partner-edition-macro-binding-information", [], null, null, null, View_ModalPartnerEditionMacroBindingInformation_0, RenderType_ModalPartnerEditionMacroBindingInformation)), i1.ɵdid(1, 114688, null, 0, i5.ModalPartnerEditionMacroBindingInformation, [i6.MatDialogRef, i6.MAT_DIALOG_DATA, i7.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalPartnerEditionMacroBindingInformationNgFactory = i1.ɵccf("app-modal-partner-edition-macro-binding-information", i5.ModalPartnerEditionMacroBindingInformation, View_ModalPartnerEditionMacroBindingInformation_Host_0, {}, {}, []);
export { ModalPartnerEditionMacroBindingInformationNgFactory as ModalPartnerEditionMacroBindingInformationNgFactory };
