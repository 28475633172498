import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalLiquidationTransferDetails } from '../modal-liquidation-transfer-details/modal-liquidation-transfer-details.component';
import { ModalTransferLogs } from '../modal-transfer-logs/modal-transfer-logs.component';
import { enumTransferApprovalStatus } from 'src/app/models/enumTransferApprovalStatus';
import { ModalManualTedRequestComponent } from 'src/app/components/modal-manual-ted-request/modal-manual-ted-request.component';
var LiquidationTransferScheduleDetails = /** @class */ (function () {
    function LiquidationTransferScheduleDetails(liquidationTransferService, route, dialog, snackBar, router, location) {
        this.liquidationTransferService = liquidationTransferService;
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.router = router;
        this.location = location;
        this.retryButtonsClicked = {};
        this.headersTable = ['createdAt', 'totalValue', 'status', 'executedAt', 'actions'];
        this.enumTransferApprovalStatus = enumTransferApprovalStatus;
        this.enableRetryButton = false;
        this.scheduleId = this.route.snapshot.paramMap.get('scheduleId');
    }
    LiquidationTransferScheduleDetails.prototype.ngOnInit = function () {
        this.loadScheduleInfo();
    };
    LiquidationTransferScheduleDetails.prototype.loadScheduleInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.getScheduleDetail(this.scheduleId).toPromise()];
                    case 1:
                        result = _a.sent();
                        this.schedule = result;
                        this.transfers = new MatTableDataSource(result.transfer);
                        this.enableRetryButton = this.checkIfRetryIsAvailable();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferScheduleDetails.prototype.checkIfRetryIsAvailable = function () {
        var transfers = this.schedule.transfer;
        if (!transfers)
            return false;
        if (this.schedule.success !== false)
            return false;
        if (this.schedule.status !== enumTransferApprovalStatus.ERROR)
            return false;
        return true;
    };
    LiquidationTransferScheduleDetails.prototype.openTransferDetails = function (transfer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.open(ModalLiquidationTransferDetails, {
                            width: '500px',
                            data: transfer,
                        }).afterClosed().toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferScheduleDetails.prototype.openTransferLogs = function (transfer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialog.open(ModalTransferLogs, {
                            minWidth: '600px',
                            width: '80%',
                            data: transfer.logs,
                        }).afterClosed().toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferScheduleDetails.prototype.retrySendToApproval = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transfers, transferData, result, retryPaymentData, result_1, currentUrl_1, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        transfers = this.schedule ? this.schedule.transfer : null;
                        if (transfers) {
                            transfers.sort(function (a, b) {
                                if (!a.createdAt)
                                    return 1;
                                if (!b.createdAt)
                                    return -1;
                                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                            });
                            transferData = transfers[0];
                        }
                        else {
                            console.log("Transferencias nao encontradas para realizar a retentativa");
                            return [2 /*return*/];
                        }
                        this.retryButtonsClicked[1] = true;
                        return [4 /*yield*/, this.dialog.open(ModalManualTedRequestComponent, {
                                width: '500px',
                                data: tslib_1.__assign({ text: 'Caso queira atualizar os dados bancários nesse momento, após, será necessário atualizar no cadastro do parceiro.', buttonConfirmText: 'enviar', buttonNot: 'Cancelar', dueDate: this.schedule.dueDate }, transferData),
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'not')) return [3 /*break*/, 6];
                        retryPaymentData = {
                            transferId: transferData.id,
                            bankAccountNumber: result.bankAccountNumber ?
                                result.bankAccountNumber :
                                transferData.bankAccountNumber,
                            bankNumber: result.bankNumber ? result.bankNumber : transferData.bankNumber,
                            bankAccountDigit: result.bankAccountDigit ?
                                result.bankAccountDigit :
                                transferData.bankAccountDigit,
                            bankAgency: result.bankAgency ? result.bankAgency : transferData.bankAgency,
                            bankAccountType: transferData.bankAccountType,
                            bankPixKey: transferData.bankPixKey,
                            propagateChangesToNextTransferSchedules: result.propagateChangesToNextTransferSchedules,
                            propagateChangesToStore: result.propagateChangesToStore,
                            storeCnpj: transferData.storeCnpj,
                            scheduleToNextBusinessDay: result.scheduleToNextBusinessDay,
                        };
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.liquidationTransferService.retrySendToApproval(retryPaymentData).toPromise()];
                    case 3:
                        result_1 = _a.sent();
                        currentUrl_1 = this.router.url;
                        this.snackBar.open('Solicitação de TED enviada para processamento');
                        setTimeout(function () {
                            _this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
                                _this.router.navigate([currentUrl_1]);
                            });
                        }, 1000);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.retryButtonsClicked[1] = false;
                        this.snackBar.open('Erro ao tentar realizar a Solicitação de TED');
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.retryButtonsClicked[1] = false;
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferScheduleDetails.prototype.goBack = function () {
        this.location.back();
    };
    LiquidationTransferScheduleDetails.prototype.getLastStatusUpdateDate = function () {
        var transfers = this.schedule ? this.schedule.transfer : null;
        if (transfers) {
            transfers.sort(function (a, b) {
                if (!a.statusUpdatedAt)
                    return 1;
                if (!b.statusUpdatedAt)
                    return -1;
                return new Date(b.statusUpdatedAt).getTime() - new Date(a.statusUpdatedAt).getTime();
            });
        }
        return transfers && transfers[0] ? transfers[0].statusUpdatedAt : null;
    };
    LiquidationTransferScheduleDetails.prototype.getStatus = function () {
        var _a;
        var mappedText = (_a = {},
            _a[enumTransferApprovalStatus.PENDING_APPROVAL] = 'Pendente de aprovação',
            _a[enumTransferApprovalStatus.APPROVAL_GRANTED] = 'Aprovação concedida',
            _a[enumTransferApprovalStatus.APPROVAL_REJECTED] = 'Aprovação rejeitada',
            _a);
        return mappedText[this.schedule.status] || ' - ';
    };
    return LiquidationTransferScheduleDetails;
}());
export { LiquidationTransferScheduleDetails };
