import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../../../services/auth.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-client-kind-list',
  templateUrl: './client-kind-list.component.html',
  styleUrls: ['./client-kind-list.component.scss'],
})
export class ClientKindListComponent implements OnInit {
  public headersTable: string[] = ['title', 'isDefault', 'actions']
  public kinds: any[] = []

  constructor (
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.listAirports()
  }

  listAirports () {
    this.authService.clientKindList().then(
      response => {
        this.kinds = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Segmentação?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.authService.clientKindDelete(id).then(
            async response => {
              this.listAirports()
              this.snackBar.open('Segmentação removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
