import { PreRegistrationService } from '../../../../services/preRegistration.service'
import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatTableDataSource } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'pre-registration-historic-stores',
  templateUrl: './pre-registration-historic-stores.component.html',
  styleUrls: ['./pre-registration-historic-stores.component.scss'],
})
export class PreRegistrationHistoricStoresComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'nomeFantasia', 'balance', 'createdAt']
  dataSource: any
  public formGroup: FormGroup
  cpf: any
  preRegistration: any
  typePreRegistration: any

  constructor (
    public preRegistrationService: PreRegistrationService,
    public snackBar: MatSnackBar,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit () {
    this.cpf = this.route.snapshot.paramMap.get('cpf')
    this.typePreRegistration = this.route.snapshot.paramMap.get('type')
    this.list(this.cpf).catch(err => { console.log(err) })
    this.findOne().catch(err => { console.log(err) })
  }

  async findOne () {
    await this.preRegistrationService.findCpf(this.cpf)
      .then(async response => {
        this.preRegistration = response
      })
  }

  async list (cpf) {
    const { results } = await this.preRegistrationService
      .listHistoric(cpf)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })
    this.dataSource = new MatTableDataSource(results)
  }
}
