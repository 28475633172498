<app-logged class="store-list">
  <header class="store-list__header">
    <h2>TRANSAÇÕES MEMBERSHIP</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>
  <app-form-filter
    actions="form"
    type="membershipPlanTrasactions"
    [fieldsForm]="fieldsForm"
    [planId]="planId"
    [customerID]="customerId"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category">
      <div><b>PLANO MEMBERSHIP: </b>{{ planName }}</div>
      <div><b>CLIENTE: </b>{{ fullName }}</div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef>Número da Transação</th>
        <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">{{ element.storeName }}</td>
      </ng-container>
      <ng-container matColumnDef="customerCpf">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          matTooltip="CPF: {{ element.customerCpf | mask: '000.000.000-00' }}"
        >
          <button mat-icon-button (click)="detail(element.id)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="totalValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">
          {{ convertCentsToReal(element.totalValue) | currency: 'BRL':'R$' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            canceledtransaction: element.state == 'REJECTED' || element.state == 'CANCELED',
            approvedtransaction: element.state == 'APPROVED',
            pendingtransaction: element.state == 'PENDING',
            smalltext: element.state == 'PENDING' && element.isManual
          }"
        >
          {{ convertStatusTransactions(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="element.canBeCanceled"
            mat-icon-button
            matTooltip="Cancelar"
            (click)="openDialogCancelTransaction(element)"
            matTooltipPosition="left"
          >
            <mat-icon class="canceltransaction">cancel</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Visualizar"
            (click)="view(element)"
            matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.id }}"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      actions="pagination"
      type="membershipPlanTrasactions"
      [length]="pageTotal"
      [planId]="planId"
      [customerID]="customerId"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
