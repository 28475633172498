<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div>
    <div>
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="cpf">
                  <th mat-header-cell *matHeaderCellDef>CPF</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="cpf1"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="cpf2"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="cpf3"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="cpf4"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef>First Name</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="firstName1"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="firstName2"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="firstName3"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="customer-autocomplete" style="padding: 3px">
                        <mat-label>CPF ou Nome do Cliente</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let customer of selectedCustomers"
                            [selectable]="false"
                            [removable]="true"
                            (removed)="removeCustomer(customer)"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            matInput
                            #customerInput
                            [formControl]="customerControl"
                            formControlName="firstName4"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event)"
                        >
                          <mat-option
                            *ngFor="let customer of filteredCustomers | async"
                            [value]="customer"
                          >
                            {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef>lastName Name</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <mat-form-field>
                        <input matInput formControlName="lastName1" placeholder="Sobrenome" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="lastName2" placeholder="Sobrenome" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="lastName3" placeholder="Sobrenome" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="lastName4" placeholder="Sobrenome" />
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="percentage">
                  <th mat-header-cell *matHeaderCellDef>Porcentagem</th>
                  <td mat-cell *matCellDef="let element">
                    <div>
                      <mat-form-field>
                        <input matInput formControlName="percentage1" placeholder="Porcentagem" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="percentage2" placeholder="Porcentagem" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="percentage3" placeholder="Porcentagem" />
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput formControlName="percentage4" placeholder="Porcentagem" />
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- <div style="overflow-y: hidden; height: calc(100vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit(data)">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row"> -->
  <!-- <div>
                <mat-form-field class="customer-autocomplete">
                  <mat-label>CPF ou Nome do Cliente</mat-label>
                  <mat-chip-list #chipList>
                    <mat-chip
                      *ngFor="let customer of selectedCustomers"
                      [selectable]="false"
                      [removable]="true"
                      (removed)="removeCustomer(customer)"
                    >
                      {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      matInput
                      #customerInput
                      [formControl]="customerControl"
                      formControlName="customersInput1"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option
                      *ngFor="let customer of filteredCustomers | async"
                      [value]="customer"
                    >
                      {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="firstName1" placeholder="Nome" />
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="lastName1" placeholder="Sobrenome" />
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="percentage1" placeholder="Porcentagem" />
                </mat-form-field>
              </div> -->
  <!-- </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">Exportar</button>
          </div>
        </div>
      </form> -->
  <!-- </div>
  </div> -->
</main>
