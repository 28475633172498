<app-logged class="store-form">
  <header class="store-form__header">
    <h2>RELATÓRIO DE TRANSAÇÃO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/home">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Data inicial</mat-label>
              <input type="date" matInput formControlName="initialDate" />
              <mat-error *ngIf="formGroup.controls.initialDate?.invalid">
                {{
                  formGroup.controls.initialDate.errors?.dateMax
                    ? 'A data inicial não pode ser no futuro'
                    : errorsService.messageErrorFor(formGroup.controls.initialDate)
                }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data final</mat-label>
              <input type="date" matInput formControlName="finalDate" />
              <mat-error>
                {{
                  formGroup.controls.finalDate.errors?.dateMin
                    ? 'A data final não pode ser menor do que a inicial'
                    : errorsService.messageErrorFor(formGroup.controls.finalDate)
                }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Exportar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
