import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
var ClientExportToZoopComponent = /** @class */ (function () {
    function ClientExportToZoopComponent(http) {
        this.http = http;
        this.exportRunning = false;
        this.whatchRunning = false;
        this.from = 0;
        this.quantity = 4;
    }
    ClientExportToZoopComponent.prototype.executeStartExport = function () {
        return this.http.get(environment.apiUrl + "/cms/profile/start-export-to-zoop", { params: { quantity: "" + this.quantity, from: "" + this.from }, headers: { noloading: 'true' } }).toPromise();
    };
    ClientExportToZoopComponent.prototype.ngOnInit = function () {
    };
    ClientExportToZoopComponent.prototype.startExportClick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.exportRunning)
                            return [2 /*return*/];
                        this.exportRunning = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.executeStartExport()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log("ERRO AO EXPORTAR", error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.exportRunning = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientExportToZoopComponent.prototype.getExportDataList = function () {
        return this.http.get(environment.apiUrl + "/cms/profile/get-zoop-exported-data", { headers: { noloading: 'true' } }).toPromise();
    };
    ClientExportToZoopComponent.prototype.startWatchData = function () {
        var _this = this;
        this.exportRunning = true;
        this.interval = setInterval(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getExportDataList()];
                    case 1:
                        res = _a.sent();
                        this.exportData = res;
                        return [2 /*return*/];
                }
            });
        }); }, 3000);
    };
    ClientExportToZoopComponent.prototype.stopWatchData = function () {
        this.exportRunning = false;
        clearInterval(this.interval);
    };
    return ClientExportToZoopComponent;
}());
export { ClientExportToZoopComponent };
