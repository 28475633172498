import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var ModalCancelTransactionComponent = /** @class */ (function () {
    function ModalCancelTransactionComponent(dialogRef, data, formBuilder, snackBar, transactionService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.transactionService = transactionService;
        this.showLiquidationMessage = false;
        console.log('data', data);
        this.userRole = localStorage.getItem('userRole');
    }
    ModalCancelTransactionComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalCancelTransactionComponent.prototype.submit = function () {
        if (!this.formGroup.valid) {
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        this.dialogRef.close(this.formGroup.value.reason);
    };
    ModalCancelTransactionComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var transactionLiquidationInfo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formGroup = this.formBuilder.group({
                            reason: [null, [
                                    Validators.required,
                                ]],
                        });
                        if (!(this.userRole === 'ADMIN')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.transactionService.getLiquidationPaymentInfo(this.data.transaction.id)];
                    case 1:
                        transactionLiquidationInfo = _a.sent();
                        if (transactionLiquidationInfo) {
                            if (transactionLiquidationInfo.administrativeTaxPayment && ![true, false].includes(transactionLiquidationInfo.administrativeTaxPayment.success))
                                this.showLiquidationMessage = true;
                            if (transactionLiquidationInfo.liquidationPayment && ![true, false].includes(transactionLiquidationInfo.liquidationPayment.success))
                                this.showLiquidationMessage = true;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return ModalCancelTransactionComponent;
}());
export { ModalCancelTransactionComponent };
