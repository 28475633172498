import { Component, OnInit, ViewChild } from '@angular/core'
import { Transaction } from 'src/app/models/transaction'
import { TransactionService } from 'src/app/services/transaction.service'
import { ActivatedRoute, Router } from '@angular/router'
import { CurrencyPipe, Location } from '@angular/common'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material'
import { UtilService } from 'src/app/services/util.service'
import { StoreService } from 'src/app/services/store.service'
@Component({
  selector: 'app-store-list',
  templateUrl: './closings-transactions-list.component.html',
  styleUrls: ['./closings-transactions-list.component.scss'],
})
export class ClosingsTransactionsListComponent implements OnInit {
  public headersTable: string[] = [
    'orderNumber',
    'storeName',
    'customerCpf',
    'description',
    'createdAt',
    'totalValue',
  ]

  public transactions: Transaction[] = []
  public isAdmin: boolean = false
  public userRole
  public closingsID = ''
  listStatus: any

  dataSource: any
  stores: Array<any> = []

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  constructor(
    public transactionService: TransactionService,
    private readonly route: ActivatedRoute,
    public router: Router,
    public currencyPipe: CurrencyPipe,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location: Location,
    public utilService: UtilService,
    public storeService: StoreService,
  ) {}

  ngOnInit() {
    this.closingsID = this.route.snapshot.paramMap.get('closingsID')
    this.userRole = localStorage.getItem('userRole')
    this.isAdmin = this.userRole == 'ADMIN'
    this.listTransactions()
  }

  listTransactions() {
    this.transactionService.listTransactionsByClosing(this.closingsID).subscribe(
      async response => {
        let listTransactions: Array<any> = response.map(item => {
          return item.transaction
        })

        listTransactions.map(item => {
          item.createdAt = item.createdAt.slice(0, 10)
          item.storeName = item.store.nomeFantasia
          delete item.updatedAt
          delete item.paidAt
        })

        this.dataSource = new MatTableDataSource(listTransactions)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.paginator._intl.itemsPerPageLabel = 'Itens por página'
      },
      error => {
        console.log(error)
        alert(error.error.message)
      },
    )

    this.storeService.listStores().subscribe(
      async response => {
        this.stores = response
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  goBack() {
    this.location.back()
  }

  applyFilter(filterValue: string, type) {
    switch (type) {
      case 'numberTransaction':
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
        break
      case 'cpfClient':
        this.dataSource.filter = this.utilService.extractNumbers(filterValue)
        break
      case 'dateTransaction':
        console.log(filterValue)
        this.dataSource.filter = filterValue
        break
      case 'status':
        this.dataSource.filter = filterValue
        break
      case 'store':
        filterValue = filterValue.trim() // Remove whitespace
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
        break
      default:
        filterValue = filterValue.trim() // Remove whitespace
        filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue
    }
  }

  convertCentsToReal(value) {
    return value / 100
  }
}
