import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Location } from '@angular/common'

import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { BusinessPartnersService } from '../../../../services/businessPartners.service'
import { CategoriesService } from '../../../../services/categories.service'

@Component({
  selector: 'app-business-partners-list',
  templateUrl: './business-partners-list.component.html',
  styleUrls: ['./business-partners-list.component.scss'],
})
export class BusinessPartnersListComponent implements OnInit {
  public categoryID = ''
  public heading = ''
  public category = ''
  public headersTable: string[]
  public headersTableMain: string[]
  public dataSourceData: any
  parentId: string
  isCategoryJoin: string
  public categoryJoin: any
  public categoryJoinId: any
  fieldsForm: any
  dataSource: any
  dataSourceMain: any
  tableFilter = false
  isFilter = false

  public pageTotal: number
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  constructor (
    public businessPartnersService: BusinessPartnersService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    public categoriesService: CategoriesService,
    private readonly location: Location,
  ) {}

  ngAfterViewChecked () {
    this.cdRef.detectChanges()
  }

  ngOnInit () {
    this.headersTable = ['nameCategory', 'active', 'actions']
    // this.headersTableMain = ['ordination', 'nameCategoryMain', 'activeMain', 'actionsMain']
    this.headersTableMain = ['nameCategoryMain', 'activeMain', 'actionsMain']
    this.categoryID = this.route.snapshot.paramMap.get('categoryID')
    // this.fieldsForm = [
    //   {
    //     field: 'input',
    //     type: 'text',
    //     formControlName: 'nameBusinessPartners',
    //     label: 'Nome',
    //     placeholder: 'Nome',
    //   },
    //   {
    //     field: 'select',
    //     formControlName: 'activeBusinessPartners',
    //     label: 'Ativo',
    //     list: [
    //       { key: '1', value: 'ATIVO' },
    //       { key: '0', value: 'INATIVO' },
    //     ],
    //     optionDefault: 'TODOS',
    //   },
    // ]

    this.initial().catch(err => console.log(err))
  }

  async initial () {
    if (this.categoryID) {
      await this.loadInfos().catch(err => console.log(err))
    }

    await this.businessPartnersList()
  }

  async loadInfos () {
    await this.categoriesService.findOne(this.categoryID).then(async response => {
      this.heading = response.heading
      this.parentId = response.parentId
    })
    if (this.parentId) {
      await this.categoriesService.findOne(this.parentId).then(
        response => {
          this.category = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }

    if (this.isCategoryJoin) {
      // await this.categoriesService.joinList(this.categoryID).then(
      //   async response => {
      //     this.categoryJoin = await response.results
      //     this.categoryJoinId = await this.categoryJoin[0].id
      //     this.isFilter = await true
      //     this.fieldsForm = await [
      //       {
      //         field: 'input',
      //         type: 'text',
      //         formControlName: 'nameBusinessPartners',
      //         label: 'Nome',
      //         placeholder: 'Nome',
      //       },
      //       {
      //         field: 'select',
      //         formControlName: 'activeBusinessPartners',
      //         label: 'Ativo',
      //         list: [
      //           { key: '1', value: 'ATIVO' },
      //           { key: '0', value: 'INATIVO' },
      //         ],
      //         optionDefault: 'TODOS',
      //       },
      //       {
      //         field: 'select',
      //         formControlName: 'categoriesBusinessPartners',
      //         label: 'Categorias',
      //         list: [
      //           {
      //             key: `${this.categoryJoin[0].id}`,
      //             value: `${this.categoryJoin[0].categoryHeading}`,
      //           },
      //           {
      //             key: `${this.categoryJoin[1].id}`,
      //             value: `${this.categoryJoin[1].categoriesName}`,
      //           },
      //         ],
      //         // optionDefault: this.categoryJoin[0].id,
      //       },
      //     ]
      //   },
      //   error => {
      //     console.log(error)
      //     this.snackBar.open(error.error.message)
      //   },
      // )
    } else {
      this.isFilter = true
      this.fieldsForm = [
        {
          field: 'input',
          type: 'text',
          formControlName: 'nameBusinessPartners',
          label: 'Nome',
          placeholder: 'Nome',
        },
        {
          field: 'select',
          formControlName: 'activeBusinessPartners',
          label: 'Ativo',
          list: [
            { key: '1', value: 'ATIVO' },
            { key: '0', value: 'INATIVO' },
          ],
          optionDefault: 'TODOS',
        },
      ]
    }
  }

  goBack () {
    this.location.back()
  }

  drop (event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      return
    }
    const previousIndex = this.dataSourceMain.data.findIndex(row => row === event.item.data)

    if (event.currentIndex === Number(previousIndex)) {
      return
    }
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja salvar alteração na ordem das Perguntas ?',
          buttonConfirmText: 'Salvar',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (!result || result === 'cancel') {
          return
        }
        moveItemInArray(this.dataSourceMain.data, Number(previousIndex), event.currentIndex)
        this.reorder()

        const newWorldOrder = this.dataSourceMain.data.map(i => ({
          id: i.id,
          ordination: i.ordination,
        }))

        this.businessPartnersService.changeOrderPartners(this.categoryID, newWorldOrder).then(
          async response => {
            this.snackBar.open('Ordem alterada com sucesso.')
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reorder () {
    let ordination = 0
    const data = []
    for (const item of this.dataSourceMain.data) {
      item.ordination = ++ordination
      data.push(item)
    }
    this.dataSourceMain.data = data
  }

  businessPartnersList () {
    let filter
    let filterMain
    if (this.isCategoryJoin) {
      filter = `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["isMain"], "op": "=", "value":"0"},{"fields":["categoryJoin.id"], "op": "=", "value":"${this.categoryJoin[0].id}"}]}`
      filterMain = `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["isMain"], "op": "=", "value":"1"},{"fields":["categoryJoin.id"], "op": "=", "value":"${this.categoryJoin[0].id}"}]}`
    } else {
      filter = `?filters={"page": ${this.page}, "pageSize": ${this.pageSize},"filters":[{"fields":["isMain"], "op": "=", "value":"0"}]}`
      filterMain = '?filters={"filters":[{"fields":["isMain"], "op": "=", "value":"1"}]}'
    }
    this.businessPartnersService.list(this.categoryID, filter).then(
      async response => {
        this.pageTotal = response.total

        this.dataSource = new MatTableDataSource(response.results)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
    this.businessPartnersService.list(this.categoryID, filterMain).then(
      async response => {
        // response.results.sort((a, b) => {
        //   return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0
        // })
        this.dataSourceMain = new MatTableDataSource(response.results)
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  receiveFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }

  receiveFeedbackMain (returnFilterMain) {
    const nameInput = returnFilterMain.results.find(b => b.nameInput)
    const activeInput = returnFilterMain.results.find(b => b.activeInput)
    this.tableFilter = !!(nameInput || activeInput)

    returnFilterMain.results.sort((a, b) => {
      return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0
    })
    returnFilterMain.results.splice(returnFilterMain.results.indexOf('nameInput'), 1)
    returnFilterMain.results.splice(returnFilterMain.results.indexOf('activeInput'), 1)
    this.dataSourceMain = new MatTableDataSource(returnFilterMain.results)
  }
}
