<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h4>Deseja criar essa transação ?</h4>
        <div class="col-12 col-sm-12">
          <mat-card-content>
            <ul class="heroes">
              <li>
                <span class="badge"><b>CPF do Cliente</b></span
                >: {{ data.cpf | mask: '000.000.000-00' }}
              </li>
              <li>
                <span class="badge"><b>Nome do Cliente</b></span
                >: {{ data.fullName }}
              </li>
              <li>
                <span class="badge"><b>Descrição</b></span
                >: {{ data.descriptionPayment }}
              </li>
              <li>
                <span class="badge"><b>Data da antecipação</b></span
                >: {{ data.paymentDate | date: 'dd/MM/yyyy' }}
              </li>
              <li *ngIf="!showInput">
                <span class="badge"><b>Valor restante</b></span
                >: {{ convertCentsToReal(data.amountPayment) | currency: 'BRL':'R$' }}
              </li>
              <li *ngIf="showInput">
                <span class="badge"><b>Valor restante</b></span
                >:
                <mat-form-field>
                  <input
                    matInput
                    currencyMask
                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                    [formControl]="totalValue"
                  />
                </mat-form-field>
              </li>
            </ul>
          </mat-card-content>
        </div>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="actions">
      <button mat-button class="btn-submit-dark" (click)="alterValue()">Alterar Valor</button>
      <button mat-button type="submit" class="btn-submit-dark" (click)="createTransacation()">
        ENVIAR PARA O CLIENTE
      </button>
    </div>
  </div>
</main>
