import { Component, OnInit } from '@angular/core'
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CompaniesService } from '../../../../../services/companies.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-architect-company-customers-list',
  templateUrl: './architect-company-customers-list.component.html',
  styleUrls: ['./architect-company-customers-list.component.scss'],
})
export class ArchitectCompanyCustomersListComponent implements OnInit {
  public headersTable: string[] = ['cpf', 'fullName', 'actions']
  // public headersTableDelete: string[] = ['cpf', 'fullName', 'isClient', 'percentage', 'deletedAt']
  dataSource: any
  dataSourceDelete: any
  public formGroup: FormGroup
  id: any
  company: any
  addNew = true

  constructor (
    public companiesService: CompaniesService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.list().catch(err => { console.log(err) })
    // if (this.id) {
    //   this.findOne().catch(err => console.log(err))
    // }
  }

  // async findOne () {
  //   await this.companiesService.findOne(this.id)
  //     .then(async response => {
  //       this.company = response
  //     })
  // }

  async list () {
    await this.companiesService
      .findOne(this.id)
      .then(async response => {
        console.log(response.customers)
        this.dataSource = new MatTableDataSource(response.customers)
      })
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    // const listDelete = await this.companiesService
    //   .listCompanyAirportCustomersDelete(this.id)
    //   .catch(err => {
    //     this.snackBar.open(err.message)
    //     return { results: [], total: 0 }
    //   })

    // this.dataSourceDelete = new MatTableDataSource(listDelete.results)
  }

  remove (id, companyId) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Ao excluir esse CPF será necessário vincular outro CPF com a mesma porcentagem, ou repassar a porcentagem para outro(s) CPF(s) cadastrado(s).',
          buttonNot: 'Remover',
          buttonConfirmText: 'Vincular outro CPF',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result === 1) {
          return this.companiesService.delete(id, '?removeTaxa=not').then(
            async response => {
              this.list().catch(error => console.log(error))
              await this.router.navigate([`./dashboard/airport-company/${companyId}/customer/new`])
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
