import * as tslib_1 from "tslib";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MembershipService = /** @class */ (function () {
    function MembershipService(http) {
        this.http = http;
    }
    MembershipService.prototype.list = function () {
        var _this = this;
        return this.http.get(environment.apiUrl + "/*/memberships").pipe(tap(function (_) { return _this.log('*/memberships'); }), catchError(this.handleError));
    };
    MembershipService.prototype.add = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/memberships", data).pipe(tap(function (_) { return _this.log('cms/memberships'); }), catchError(this.handleError));
    };
    MembershipService.prototype.update = function (data, membershipsID) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/memberships/" + membershipsID, data).pipe(tap(function (_) { return _this.log('cms/memberships'); }), catchError(this.handleError));
    };
    MembershipService.prototype.delete = function (membershipsID) {
        var _this = this;
        return this.http.delete(environment.apiUrl + "/cms/memberships/" + membershipsID).pipe(tap(function (_) { return _this.log('cms/memberships'); }), catchError(this.handleError));
    };
    MembershipService.prototype.listPlans = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/membership/plans" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    MembershipService.prototype.listPlansOthers = function (filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/membership/plans/others" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    MembershipService.prototype.createPlan = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/membership/plans", data).toPromise()];
            });
        });
    };
    MembershipService.prototype.editPlan = function (planId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/membership/plans/" + planId, data)
                        .toPromise()];
            });
        });
    };
    MembershipService.prototype.deletePlan = function (planId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/membership/plans/" + planId).toPromise()];
            });
        });
    };
    MembershipService.prototype.listPlansById = function (planId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/membership/plans/" + planId).toPromise()];
            });
        });
    };
    MembershipService.prototype.listCustomersByPlan = function (planId, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/membership/plan-customers/" + planId + (filter || ''))
                        .toPromise()];
            });
        });
    };
    MembershipService.prototype.listPlansByCustomer = function (customerID, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/membership/customer-plans/" + customerID + (filter || ''))
                        .toPromise()];
            });
        });
    };
    MembershipService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    MembershipService.prototype.log = function (message) { };
    MembershipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MembershipService_Factory() { return new MembershipService(i0.ɵɵinject(i1.HttpClient)); }, token: MembershipService, providedIn: "root" });
    return MembershipService;
}());
export { MembershipService };
