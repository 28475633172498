import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var NoticationTypesFormComponent = /** @class */ (function () {
    function NoticationTypesFormComponent(errorsService, formBuilder, notificationService, snackBar, router, route, dialog) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.notificationService = notificationService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
    }
    NoticationTypesFormComponent.prototype.ngOnInit = function () {
        this.id = this.route.snapshot.paramMap.get('id');
        this.initForm();
    };
    NoticationTypesFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            type: [null, [Validators.required]],
        });
        if (this.id) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    NoticationTypesFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationService.findOneType(this.id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    name: response.name,
                                    type: response.type,
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NoticationTypesFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        data = {
                            name: this.formGroup.value.name,
                            type: this.formGroup.value.type,
                        };
                        if (!(this.formGroup.value.type === 'AUTOMATIC')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.notificationService.listTypes('?type=AUTOMATIC').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    console.log(response[0].id);
                                    if (response[0].id === this.id) {
                                        this.createOrUpdate(data);
                                    }
                                    else {
                                        this.dialog
                                            .open(ModalConfirmComponent, {
                                            width: '500px',
                                            data: {
                                                text: "Tipo de notifica\u00E7\u00E3o \"" + response[0].name + "\" est\u00E1 como autom\u00E1tico deseja remover ?",
                                                buttonConfirmText: 'Remover',
                                            },
                                        })
                                            .afterClosed()
                                            .toPromise()
                                            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            var _this = this;
                                            return tslib_1.__generator(this, function (_a) {
                                                if (result && result !== 'cancel') {
                                                    return [2 /*return*/, this.notificationService.deleteType(response[0].id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                            return tslib_1.__generator(this, function (_a) {
                                                                this.snackBar.open('tipo de notificação removida com sucesso.');
                                                                this.createOrUpdate(data);
                                                                return [2 /*return*/];
                                                            });
                                                        }); }, function (error) {
                                                            _this.snackBar.open(error.error.message);
                                                        })];
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); })
                                            .catch(function (err) {
                                            _this.snackBar.open(err.message);
                                        });
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.createOrUpdate(data);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    NoticationTypesFormComponent.prototype.createOrUpdate = function (data) {
        var _this = this;
        if (this.id) {
            this.notificationService.updateType(this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!response.isUpdated) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.router.navigate(['./dashboard/notification-types'])];
                        case 1:
                            _a.sent();
                            this.snackBar.open('Tipo da notificação atualizada com sucesso.');
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
        else {
            this.notificationService.createType(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!response.isCreated) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.router.navigate(['./dashboard/notification-types'])];
                        case 1:
                            _a.sent();
                            this.snackBar.open('Tipo da notificação criado com sucesso.');
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                _this.snackBar.open(error.error.message);
            });
        }
    };
    return NoticationTypesFormComponent;
}());
export { NoticationTypesFormComponent };
