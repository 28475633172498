import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import numeral from 'numeral'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import { ModalRejectGenericComponent } from 'src/app/components/modal-reject-generic/modal-reject-generic.component'
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions'
import { ArchitectInvoiceService } from 'src/app/services/architectInvoice.service'
import { PermissionsService } from 'src/app/services/permissions.service'

@Component({
  selector: 'architect-invoice-detail',
  templateUrl: './architect-invoice-detail.component.html',
  styleUrls: ['./architect-invoice-detail.component.scss'],
})
export class ArchitectInvoiceDetailComponent implements OnInit {
  public tableColumns = ['name', 'fee', 'jcoins', 'cashbackStatus', 'customerStatus']

  public invoice: any
  public userInfo: any
  public userRole: string
  public invoiceDetails: any
  public invoiceStatus: { text: string, color: string }
  private readonly architectInvoiceId: string

  constructor (
    private readonly location: Location,
    private readonly architectInvoiceService: ArchitectInvoiceService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    public snackBar: MatSnackBar,
    public permissionsService: PermissionsService,
  ) {
    if (this.route.snapshot.paramMap.get('architectInvoiceId')) {
      this.architectInvoiceId = this.route.snapshot.paramMap.get('architectInvoiceId')
    }

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userRole = localStorage.getItem('userRole')
  }

  async ngOnInit () {
    this.invoice = await this.architectInvoiceService.get(this.architectInvoiceId)
    this.invoiceStatus = this.architectInvoiceService.getInvoiceStatus(this.invoice)
    if (this.isArchitectAdmin()) { this.invoiceDetails = await this.architectInvoiceService.getDetails(this.architectInvoiceId) }

    console.log(this.invoice)
    console.log(this.invoiceDetails)
  }

  goBack () {
    this.location.back()
  }

  getCustomerName (architectInvoicePaymentHistory) {
    const { customer, preRegistrationArchitect } = architectInvoicePaymentHistory
    try {
      return customer
        ? `${customer.firstName} ${customer.lastName}`
        : `${preRegistrationArchitect.preRegistration.firstName} ${preRegistrationArchitect.preRegistration.lastName}`
    } catch (e) {
      return ''
    }
  }

  getCustomerJcoinStatus (architectInvoicePaymentHistory) {
    const { customer } = architectInvoicePaymentHistory
    const {
      isComputed,
      isConfirmPointsQueued,
      isPendingPointsQueued,
      isCanceled,
      loyaltyPendingId,
    } = architectInvoicePaymentHistory.paymentHistories

    if (isComputed) return 'Jcoins confirmados'
    if (isConfirmPointsQueued) return 'Processando confirmação dos Jcoins...'
    if (isPendingPointsQueued) return 'Processando Jcoins pendentes...'
    if (isCanceled) return 'Jcoins cancelados'
    if (loyaltyPendingId) return 'Jcoins Pendentes - Aguardando aprovação da Nota'
    if (!customer) return 'Aguardando cadastro do cliente'
    return 'Aguardando pagamento da Nota'
  }

  async validateInvoice () {
    try {
      const result = await this.dialog.open(ModalGenericComponent, {
        width: '500px',
        data: {
          text: 'Deseja validar essa nota fiscal?',
          buttonConfirmText: 'Validar Nota',
        },
      }).afterClosed().toPromise()

      if (result && result !== 'cancel') {
        await this.architectInvoiceService.validateInvoices([this.architectInvoiceId])
        this.snackBar.open('Nota Fiscal validada')
        window.location.reload()
      }
    } catch (err) {
      this.snackBar.open('Não foi possível validar essa Nota fiscal')
    }
  }

  async invalidateInvoice () {
    try {
      const reason = await this.dialog
        .open(ModalRejectGenericComponent, {
          width: '500px',
          data: {
            title: 'Deseja tornar essa nota fiscal inválida?',
            buttonText: 'Invalidar nota fiscal',
            reasonText: 'Motivo da rejeição',
          },
        })
        .afterClosed()
        .toPromise()

      if (reason && reason !== 'cancel') {
        await this.architectInvoiceService.invalidateInvoices(this.architectInvoiceId, reason)
        this.snackBar.open('Nota Fiscal invalidada')
        window.location.reload()
      }
    } catch (err) {
      this.snackBar.open('Não foi possível invalidar essa Nota fiscal')
    }
  }

  showValidateButtons () {
    if (!this.invoiceDetails) return false

    return !this.invoiceDetails.cancelled
      && !this.invoiceDetails.approved
      && !this.invoiceDetails.validated
      && !this.invoiceDetails.invalidated
  }

  convertCentsToReal (value) {
    return value / 100
  }

  multiplyTo100 (value) {
    return numeral(value).multiply(100).value()
  }

  isArchitectAdmin () {
    return this.userRole === 'ADMIN'
      || this.permissionsService.userHasPermission(
        enumCmsPermissions.architect_admin_menu,
      )
  }
}
