import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MatDialogRef, MatSnackBar } from '@angular/material'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { ReportService } from '../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
@Component({
  selector: 'app-modal-form-transactions-admin',
  templateUrl: './modal-form-transactions-admin.component.html',
  styleUrls: ['./modal-form-transactions-admin.component.scss'],
})
export class ModalFormTransactionsAdminComponent implements OnInit {
  public formGroup: FormGroup
  public storeControl = new FormControl()
  public selectedStores: any[] = []
  public statusControl = new FormControl()
  public selectedStatus: any[] = []
  public filter: string
  stores: any[] = []
  status: any[] = []
  visible = true
  selectable = true
  filteredStores: Observable<string[]>
  filteredStatus: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('statusInput', { static: false }) statusInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocomplete: MatAutocomplete
  @ViewChild('autoStatus', { static: false }) matAutocompleteStatus: MatAutocomplete

  constructor (
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalFormTransactionsAdminComponent>,
    public reportService: ReportService,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) {}

  ngOnInit () {
    this.formGroup = this.formBuilder.group({
      beginDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      storesInput: [null, []],
      statusInput: [null, []],
    })

    this.reportService.listStores().subscribe(
      response => {
        response.map(item => {
          this.stores.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.reportService.getStatus().subscribe(response => {
      Object.keys(response).map(k => {
        this.status.push({ key: k, value: response[k] })
      })
      this.status.sort((a, b) => {
        return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
      })
    })

    this.filteredStores = this.storeControl.valueChanges.pipe(
      startWith(''),
      map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 20))),
    )

    this.filteredStatus = this.statusControl.valueChanges.pipe(
      startWith(''),
      map((status: any) => (status ? this._filterStatus(status) : this.status.slice(0, 20))),
    )
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.stores
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  private _filterStatus (status: string): string[] {
    const remove = String(status)

    if (typeof remove !== 'string') {
      return
    }
    return this.status
      .filter(c => !this.selectedStatus.includes(c))
      .filter(c => `${String(c.value)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  remove (value: any): void {
    if (value.nomeFantasia) {
      const index = this.selectedStores.indexOf(value)
      if (index >= 0) {
        this.selectedStores.splice(index, 1)
      }
    }
    if (value.key) {
      const index = this.selectedStatus.indexOf(value)
      if (index >= 0) {
        this.selectedStatus.splice(index, 1)
      }
    }
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.nomeFantasia) {
      this.selectedStores.push(event.option.value)
      this.storeInput.nativeElement.value = ''
      this.storeControl.setValue(null)
    }
    if (event.option.value.key) {
      this.selectedStatus.push(event.option.value)
      this.statusInput.nativeElement.value = ''
      this.statusControl.setValue(null)
    }
  }

  submit () {
    const storesIds = this.selectedStores.map(c => c.id)
    const status = this.selectedStatus.map(c => c.key)

    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.selectedStores.length && this.selectedStatus.length) {
      this.filter = `transactions-admin?transactionsState=${status}&storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    } else if (this.selectedStores.length) {
      this.filter = `transactions-admin?storesIds=${storesIds}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    } else if (this.selectedStatus.length) {
      this.filter = `transactions-admin?transactionsState=${status}&beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    } else {
      this.filter = `transactions-admin?beginDate=${this.formGroup.value.beginDate}&endDate=${this.formGroup.value.endDate}`
    }
    console.log(this.filter)
    this.reportService
      .createReport(this.filter)
      .then(async response => {
        // eslint-disable-next-line no-new
        new JsFileDownloader({
          url: response.fileUrl,
          nameCallback: () => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return 'Relatório de Transações.' + response.fileUrl.split('.').splice(5, 5).join('.')
          },
        })
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }
}
