import { Component, OnInit, Input, TemplateRef, Output,EventEmitter, forwardRef, ViewChild, ElementRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MaskPipe } from "ngx-mask";
import { Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";

import { AuthService } from "src/app/services/auth.service";

interface CustomerData {
  firstName: string;
  lastName: string;
  cpf: string;
  id: string;
}

@Component({
  selector: 'app-customer-search-input',
  templateUrl: './customer-search-input.component.html',
  styleUrls: ['./customer-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSearchInputComponent),
      multi: true
    }
  ]
})
export class CustomerSearchInputComponent implements OnInit, ControlValueAccessor {
  @Input() debounceTime = 800;  
  @Input() minLength = 3;        
  @Input() placeholder = 'Buscar por nome ou CPF...'; 
  @Input() options: Observable<CustomerData[]>;  
  @Input() selectedItemTemplate: TemplateRef<any>;  
  @Input() formControl: FormControl
  @Input() searchOnlyArchitects: boolean = false
  @Input() searchFunction: (term: string) => Observable<CustomerData[]>
  @Input() onSelectedItemRender: (item: CustomerData) => string; 
  @Input() onDisplayItemRender: (item: CustomerData) => string; 
  @Output() search = new EventEmitter<string>();  
  @Output() onSelect = new EventEmitter<CustomerData>();  

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>

  
  filteredOptions: Observable<any[]>;
  selectedItem: CustomerData;
  loading: boolean = false;
  localFormControl: FormControl = new FormControl();
  
  constructor(
    private authService: AuthService,
    private maskPipe: MaskPipe,
  ) {}
  writeValue(obj: any): void {    
  }
  registerOnChange(fn: any): void {    
  }
  registerOnTouched(fn: any): void {    
  }
  setDisabledState?(isDisabled: boolean): void {    
  }

  ngOnInit() {

      if (this.localFormControl) {

        this.filteredOptions = this.localFormControl.valueChanges.pipe(
          debounceTime(this.debounceTime),
          distinctUntilChanged(),
          tap(value => this.loading = this.shouldSearch(value)),
          switchMap(value => {
            if (this.shouldSearch(value)) {
              this.search.emit(value);              
              return this.searchCustomers(value).pipe(
                tap(() => this.loading = false)
              );
            }
            this.loading = false             
            return of([]);
          })
        );
      }
    
  }

  private shouldSearch(value: string): boolean {
    return value && value.length >= this.minLength;
  }

  searchCustomers(term: string): Observable<CustomerData[]> {
    if(this.searchFunction) return this.searchFunction(term)
    return this.authService.searchCustomers(term, this.searchOnlyArchitects);
  }

  onOptionSelected(event: any) {
    const selectedValue = event.option.value;
    this.selectedItem = selectedValue;
    
    this.customerInput.nativeElement.value = ''
    this.localFormControl.setValue(null)
    this.formControl.setValue(selectedValue)
    this.onSelect.emit(selectedValue);  
  }

  clearSelection() {
    this.customerInput.nativeElement.value = ''
    this.selectedItem = null;
    this.formControl.reset();  
    this.localFormControl.setValue(null)
    this.onSelect.emit(null); 
  }

  getSelectedItemText() {    
    if(this.onSelectedItemRender) return this.onSelectedItemRender(this.selectedItem)

    return this.selectedItem.firstName+' '+this.selectedItem.lastName+' - CPF: '+this.maskPipe.transform(this.selectedItem.cpf, '000.000.000-00');
  }

  getOptionDisplayName(option){
    if(this.onDisplayItemRender) return this.onDisplayItemRender(option)

    return option.firstName+' '+option.lastName+' - CPF: '+this.maskPipe.transform(option.cpf, '000.000.000-00');
  }

  
}
