import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormInternationalIofAccumulationComponent = /** @class */ (function () {
    function ModalFormInternationalIofAccumulationComponent(formBuilder, dialogRef, reportService, snackBar, errorsService, data) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.reportService = reportService;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.data = data;
        this.storeControl = new FormControl();
        this.selectedStores = [];
        this.statusControl = new FormControl();
        this.stores = [];
        this.visible = true;
        this.selectable = true;
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormInternationalIofAccumulationComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            beginDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            selectedCustomer: [null, []],
        });
    };
    ModalFormInternationalIofAccumulationComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormInternationalIofAccumulationComponent.prototype.submit = function () {
        var _this = this;
        if (!this.formGroup.valid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var url = 'internation-iof-accumulation';
        var filter = "beginDate=" + this.formGroup.value.beginDate + "&endDate=" + this.formGroup.value.endDate;
        if (this.formGroup.value.selectedCustomer && this.formGroup.value.selectedCustomer.id) {
            filter += "&customerId=" + this.formGroup.value.selectedCustomer.id;
        }
        this.reportService
            .createReport(url + "?" + filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return 'Relatório Fatura AMEX - IOF Jcoins.' + response.fileUrl.split('.').splice(5, 5).join('.');
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormInternationalIofAccumulationComponent;
}());
export { ModalFormInternationalIofAccumulationComponent };
