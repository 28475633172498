import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { ModalConfirmationInternationalTransactionComponent } from 'src/app/components/modal-confirmation-international-transaction/modal-confirmation-international-transaction.component';
var StoreInternationalTransactionFormComponent = /** @class */ (function () {
    function StoreInternationalTransactionFormComponent(errorsService, formBuilder, storeService, transactionService, router, route, snackBar, currencyPipe, dialog) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.transactionService = transactionService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.currencyPipe = currencyPipe;
        this.dialog = dialog;
        this.states = [];
        this.plans = [];
        this.installments = [1];
        this.isValidTotalValue = false;
        this.isValidTotalValueMsg = '';
        this.headersTable = ['minimumTransactionValue', 'minimumInstallmentValue'];
        this.transactionsInfo = [];
        this.subStores = [];
        this.params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] };
        this.userRole = localStorage.getItem('userRole');
    }
    StoreInternationalTransactionFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.storeFormGroupValue = params.storeFormGroupValue;
            _this.params.formGroupValue = params.formGroupValue;
            _this.params.previousRoute = params.previousRoute;
            _this.params.storeID = params.storeID;
            _this.storeID = params.storeID;
        });
        this.initForm();
    };
    StoreInternationalTransactionFormComponent.prototype.initForm = function () {
        var _this = this;
        if (!this.storeID) {
            this.storeID = this.route.snapshot.paramMap.get('storeID');
            this.params.storeID = this.storeID;
        }
        this.formGroup = this.formBuilder.group({
            customerCpf: [
                null,
                [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
            ],
            internationalCurrencyValue: [null, [Validators.required]],
            description: [null, [Validators.required]],
            maximumInstallments: [1, [Validators.required]],
            isMembershipActive: [null, [Validators.required]],
            isPayActive: [null, [Validators.required]],
            subStoreId: [null, []],
        });
        if (this.storeID != '' && this.storeID != null) {
            var storeSelected = JSON.parse(localStorage.getItem('storeSelected'));
            if (storeSelected && this.userRole != 'ADMIN') {
                this.storeID = storeSelected.storeId;
            }
            this.storeService.getStore(this.storeID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var e_1, _a, _b, _c, st;
                return tslib_1.__generator(this, function (_d) {
                    try {
                        for (_b = tslib_1.__values(response.subStoreAndMacroGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                            st = _c.value;
                            if (st.subStore.length) {
                                this.subStores.push(st.subStore[0]);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (this.subStores.length) {
                        this.subStores.sort(function (a, b) {
                            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0;
                        });
                    }
                    this.store = response;
                    this.currency = this.store.currency;
                    this.formGroup = this.formBuilder.group({
                        customerCpf: [
                            null,
                            [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
                        ],
                        internationalCurrencyValue: [null, [Validators.required]],
                        description: [null, [Validators.required]],
                        isMembershipActive: [this.store.isMembershipActive, [Validators.required]],
                        isPayActive: [this.store.isPayActive, [Validators.required]],
                        subStoreId: [null, []],
                    });
                    this.transactionsInfo = [
                        {
                            minimumTransactionValue: this.store.minimumTransactionValue / 100,
                            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
                        },
                    ];
                    return [2 /*return*/];
                });
            }); });
        }
    };
    StoreInternationalTransactionFormComponent.prototype.convertCentsToMoney = function (value) {
        var money = this.currencyPipe.transform(value, this.store.currency);
        // this.formGroup.controls['totalValue'].setValue(money);
        console.log(money);
    };
    StoreInternationalTransactionFormComponent.prototype.convertNumberToBoolean = function (num) {
        if (num == 0) {
            return false;
        }
        else {
            return true;
        }
    };
    StoreInternationalTransactionFormComponent.prototype.openConfirmationModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, id, totalValue, totalValueWithTaxes, currency, internationalCurrencyValue, currencyQuote, purchaseFeePercent, purchaseFeeValue, customerName, result, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.submitInternationalPaymentIntent()];
                    case 1:
                        _a = _b.sent(), id = _a.id, totalValue = _a.valueInReais, totalValueWithTaxes = _a.valueInReaisWithTaxes, currency = _a.currency, internationalCurrencyValue = _a.internationalCurrencyValue, currencyQuote = _a.currencyQuote, purchaseFeePercent = _a.purchaseFeePercent, purchaseFeeValue = _a.purchaseFeeValue, customerName = _a.customerName;
                        return [4 /*yield*/, this.dialog.open(ModalConfirmationInternationalTransactionComponent, {
                                width: '500px',
                                data: {
                                    id: id,
                                    totalValue: totalValue,
                                    totalValueWithTaxes: totalValueWithTaxes,
                                    currency: currency,
                                    internationalCurrencyValue: internationalCurrencyValue,
                                    currencyQuote: currencyQuote,
                                    purchaseFeePercent: purchaseFeePercent,
                                    purchaseFeeValue: purchaseFeeValue,
                                    customerName: customerName,
                                    customerCpf: this.formGroup.value.customerCpf,
                                },
                            }).afterClosed().toPromise()];
                    case 2:
                        result = _b.sent();
                        if (!(result.createTransacation === true)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.submitTransaction(result.idInternationalPaymentIntent)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _b.sent();
                        this.snackBar.open('Problema ao checar pagamento ND');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StoreInternationalTransactionFormComponent.prototype.submitInternationalPaymentIntent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var internationalCurrencyValue, data;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    // this.snackBar.open(error.error.message);
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                internationalCurrencyValue = this.formGroup.value.internationalCurrencyValue.toFixed(2).replace('.', '');
                data = {
                    internationalCurrencyValue: parseInt(internationalCurrencyValue),
                    storeId: this.storeID,
                    customerCpf: this.formGroup.value.customerCpf,
                };
                return [2 /*return*/, this.transactionService.createInternationalPaymentIntent(data).toPromise()];
            });
        });
    };
    StoreInternationalTransactionFormComponent.prototype.submitTransaction = function (idInternationalPaymentIntent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    // this.snackBar.open(error.error.message);
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    id: idInternationalPaymentIntent,
                    description: this.formGroup.value.description,
                    isPayActive: this.formGroup.value.isPayActive,
                    subStoreId: this.formGroup.value.subStoreId,
                };
                this.transactionService.createInternationalTransactionFromIntent(data).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.isCreated) {
                            this.snackBar.open('Transação criada com sucesso.');
                            this.params = tslib_1.__assign({}, this.params, { itComesFromNewTransaction: true });
                            this.router.navigate(['./dashboard/transactions/detail/' + response.id], { queryParams: this.params, skipLocationChange: true });
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return StoreInternationalTransactionFormComponent;
}());
export { StoreInternationalTransactionFormComponent };
