<app-logged class="store-list">
  <header class="store-list__header">
    <h2>LISTA DE PERGUNTAS DO QUESTIONÁRIO</h2>

    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/quiz/list" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
      <button class="btn-primary-light" mat-button mat-button routerLink="/dashboard/quiz/{{ quizID }}/questions/form">
        ADICIONAR
      </button>
    </div>
  </header>
  <!-- <app-form-filter
    actions="form"
    [quizID]="quizID"
    type="question"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter> -->
  <div class="table-responsive">
    <mat-card class="card-category">
      <div>
        <mat-card-title>Informações do Questionário</mat-card-title>
        <mat-card-content style="width: 250px">
          <mat-form-field>
            <mat-label>Questionário</mat-label>
            <input matInput placeholder="Questionário" [value]="name" disabled="true" />
          </mat-form-field>
        </mat-card-content>
        <div *ngIf="description">
          Descrição
          <div class="description">
            <ckeditor disabled="true" [config]="config" [editor]="Editor" [data]="description"
              (ready)="onReady($event)"></ckeditor>
          </div>
        </div>
        <!-- <div *ngIf="image">
          <p style="margin-block-end: 0px">Imagem (Opcional)</p>
          <button
            (click)="showImage()"
            class="showImage"
            mat-icon-button
            matTooltip="Visualizar"
            matTooltipPosition="left"
          >
            <b>Abrir Imagem</b>
            <mat-icon>launch</mat-icon>
          </button>
        </div> -->
      </div>
    </mat-card>
  </div>
  <table mat-table [dataSource]="dataSource" cdkDropList [cdkDropListData]="dataSourceData"
    (cdkDropListDropped)="drop($event)">
    <ng-container matColumnDef="ordination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center">Ordem</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.ordination }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ask">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pergunta</th>
      <td mat-cell *matCellDef="let element" matTooltip="{{ element.ask }}" class="cellText">
        {{ element.ask }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
      <td mat-cell *matCellDef="let element">
        {{ convertType(element.type) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Forma de exibição</th>
      <td mat-cell *matCellDef="let element">
        {{ convertView(element.view) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
          routerLink="/dashboard/quiz/{{ quizID }}/questions/form/{{ element.id }}">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="remove(element.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
    <tr class="grabbable" mat-row *matRowDef="let row; columns: headersTable" cdkDrag
      (cdkDragStarted)="dragging($event)" [cdkDragStartDelay]="200" [cdkDragData]="row"></tr>

    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
      <td colspan="999" mat-cell *matCellDef="let row" class="groupCell">Step: {{ row.group }}</td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="groupRow" cdkDrag cdkDragDisabled>
    </tr>
  </table>
  <div style="height: 30px"></div>
  <!-- <app-form-filter
    actions="pagination"
    type="question"
    [quizID]="quizID"
    [length]="pageTotal"
    [pageSizeOptions]="pageSizeOptions"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter> -->
</app-logged>
