import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Params } from '@angular/router';
import JsFileDownloader from 'js-file-downloader';
import * as moment from 'moment';
import numeral from 'numeral';
import { ModalFormIntegrationsComponent } from 'src/app/components/modal-form-integrations/modal-form-integrations.component';
var AirportsIntegrationsListComponent = /** @class */ (function () {
    function AirportsIntegrationsListComponent(airportsService, snackBar, dialog) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.headersTable = ['prefix', 'document', 'cnpj', 'nameCli', 'number', 'numberOs', 'amountReceived', 'taxAmountJcoins', 'taxJcoins', 'state', 'observation', 'dateCreatedAt'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    AirportsIntegrationsListComponent.prototype.ngOnInit = function () {
        // erro
        this.list().catch(function (err) { console.log(err); });
        var firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        var lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'documentAirportIntegration',
                label: 'CPF/CNPJ',
                placeholder: 'CPF/CNPJ',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cnpjOriginAirportIntegration',
                label: 'CNPJ ORIGEM',
                placeholder: 'CNPJ ORIGEM',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'prefixAirportIntegration',
                label: 'Prefixo',
                placeholder: 'Prefixo',
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Inicio',
                formControlName: 'dateAirportInitIntegration',
                valueDefault: firstDayOfMonth,
            },
            {
                field: 'input',
                type: 'date',
                label: 'Data Integração Fim',
                formControlName: 'dateAirportEndIntegration',
                valueDefault: lastDayOfMonth,
            },
        ];
    };
    AirportsIntegrationsListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now, firstDay, lastDay, _a, results, total;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        now = new Date();
                        firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                        lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                        return [4 /*yield*/, this.airportsService
                                .integrationsList("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + ",\"filters\":[{\"fields\":[\"airports_integration.createdAt\"], \"op\": \">=\", \"value\":\"" + moment(firstDay).format('YYYY-MM-DD') + " 00:00:00\"}, {\"fields\":[\"airports_integration.createdAt\"], \"op\": \"<=\", \"value\":\"" + moment(lastDay).format('YYYY-MM-DD') + " 23:59:59\"}]}")
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, total = _a.total;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = total;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportsIntegrationsListComponent.prototype.addMask = function (document) {
        var apenasNumeros = document.replace(/\D/g, '');
        if (apenasNumeros.length === 11) {
            return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        else if (apenasNumeros.length > 11) {
            return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        return apenasNumeros;
    };
    AirportsIntegrationsListComponent.prototype.convertCentsToReal = function (value) {
        return numeral(value).divide(100).value();
    };
    AirportsIntegrationsListComponent.prototype.convertState = function (state) {
        if (state === 'COMPUTED')
            return 'COMPUTADO';
        if (state === 'FAILED')
            return 'FALHA';
    };
    AirportsIntegrationsListComponent.prototype.receiverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter;
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    AirportsIntegrationsListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalFormIntegrationsComponent, {
            width: '400px',
        });
        dialogRef.afterClosed().subscribe(function (filters) {
            if (filters) {
                _this.report(filters);
            }
        });
    };
    AirportsIntegrationsListComponent.prototype.report = function (filters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formatDate, formattedStartDate_1, formattedEndDate_1, fileUrl, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        formatDate = function (dateStr) {
                            var day = dateStr.substring(0, 2);
                            var month = dateStr.substring(2, 4);
                            var year = dateStr.substring(4, 8);
                            return day + "/" + month + "/" + year;
                        };
                        formattedStartDate_1 = formatDate(filters.startDate);
                        formattedEndDate_1 = formatDate(filters.endDate);
                        return [4 /*yield*/, this.airportsService.integrationsExport(filters)];
                    case 1:
                        fileUrl = _a.sent();
                        if (!fileUrl || fileUrl.trim() === '') {
                            throw new Error('URL do arquivo não encontrada.');
                        }
                        console.log("File URL:", fileUrl);
                        new JsFileDownloader({
                            url: fileUrl.trim(),
                            nameCallback: function () {
                                return "Integra\u00E7\u00F5es - " + formattedStartDate_1 + " \u00E0 " + formattedEndDate_1 + ".xlsx";
                            },
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Erro ao baixar o relatório:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AirportsIntegrationsListComponent;
}());
export { AirportsIntegrationsListComponent };
