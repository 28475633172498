<app-logged class="store-list">
  <header class="store-list__header">
    <h2>RECEBÍVEIS FINANCEIROS</h2>
  </header>

  <form [formGroup]="formFilter" class="row">
    <mat-form-field class="col-sm-3" *ngIf="isAdmin">
      <mat-label>Parceiro</mat-label>

      <input type="text" placeholder="Parceiro" matInput formControlName="storeName" [matAutocomplete]="auto" />
      <mat-error *ngIf="formFilter.controls.storeName?.invalid">
        {{ errorsService.messageErrorFor(formFilter.controls.storeName) }}
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleStoreSelected($event)">
        <mat-option *ngFor="let store of storeOptions | async" [value]="store">
          {{ store.nomeFantasia }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="col-sm-3">
      <mat-label>Status da transação</mat-label>

      <mat-select formControlName="status">
        <mat-option value="">Não selecionado</mat-option>
        <mat-option value="AGENDADO">Agendado</mat-option>
        <mat-option value="LIQUIDADO">Liquidado</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-sm-3">
      <mat-label>Data inicial</mat-label>

      <input type="date" matInput formControlName="startDate" />
      <mat-error *ngIf="formFilter.controls.startDate?.invalid">
        {{ errorsService.messageErrorFor(formFilter.controls.startDate) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-3">
      <mat-label>Data final</mat-label>

      <input type="date" matInput formControlName="finalDate" />
      <mat-error *ngIf="formFilter.controls.finalDate?.invalid">
        {{ errorsService.messageErrorFor(formFilter.controls.finalDate) }}
      </mat-error>
    </mat-form-field>

    <div class="col-sm-12">
      <button type="submit" class="btn-primary-light" mat-button matStepperNext (click)="submitFilter()">
        Buscar Recebíveis
      </button>
      <button type="button" class="btn-primary-light export-button" mat-button (click)="report()">
        Exportar
      </button>
    </div>
  </form>

  <div class="row" *ngIf="receivables.length > 0">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table mat-table [dataSource]="receivables">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef>Documento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.document | mask: '00.000.000/0000-00' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Valor total</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.status }}
            </td>
          </ng-container>
          <ng-container matColumnDef="cardValue">
            <th mat-header-cell *matHeaderCellDef>Valor no cartão</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cardValue | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="coinValue">
            <th mat-header-cell *matHeaderCellDef>Valor em JCoins</th>
            <td mat-cell *matCellDef="let element">
              {{ element.coinValue | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="cardDiscount">
            <th mat-header-cell *matHeaderCellDef>Desconto no cartão</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cardDiscount | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="coinDiscount">
            <th mat-header-cell *matHeaderCellDef>Desconto nos JCoins</th>
            <td mat-cell *matCellDef="let element">
              {{ element.coinDiscount | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element">
              {{ element.date | date: 'dd/MM/yyyy HH:mm' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="installment">
            <th mat-header-cell *matHeaderCellDef>Parcela</th>
            <td mat-cell *matCellDef="let element">
              {{ element.installment }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
      </div>
    </div>
  </div>
</app-logged>
