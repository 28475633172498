import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Params } from '@angular/router'
import { CampaignService } from 'src/app/services/campaign.service'
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component'

enum CampaignType {
  'ACCUMULATION' = 'Acúmulo',
  'REDEEM' = 'Resgate',
  'CREATE_CUSTOMER' = 'Cadastro de Cliente',
}

enum ConfirmationCampaignType {
  'PENDING_POINTS' = 'Pontos pendentes',
  'CONFIRMED' = 'Confirmados',
  'PENDING_POINTS_CONFIRMATION' = 'Confirmação de pontos pendentes',
}

enum ConfirmationCampaign {
  'ALLOW_PARTIALS' = 'Permite parciais',
  'PARTIAL_ONCE' = 'Parcial apenas uma vez',
  'DENY_PARTIAL' = 'Não permite parcial',
}
@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  public headersTable: string[] = ['idCampaign', 'campaignTypes', 'description', 'confirmationCampaignType', 'confirmationCampaign', 'actions']
  formGroup: FormGroup
  public campaigns: any
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  public pageTotal: number
  public fieldsForm: any
  constructor (
    private readonly route: ActivatedRoute,
    private readonly campaignService: CampaignService,
    private readonly dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      if (params.formGroupValue) {
        this.formGroup = JSON.parse(params.formGroupValue)
      } else {
        this.loadCampaigns()
      }
    })
    this.initForm()
  }

  initForm () {
    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'campaignType',
        label: 'Tipo Campanha',
        placeholder: 'Campanha',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'idCampaign',
        label: 'ID da campanha',
        placeholder: 'ID da campanha',
      },
    ]
  }

  handlePropertiesTranslation (campaigns: any) {
    campaigns.map((campaign) => {
      campaign.campaignTypes = CampaignType[campaign.campaignTypes]
      campaign.confirmationCampaignType =
        ConfirmationCampaignType[
          campaign.confirmationCampaignType
        ]
      campaign.confirmationCampaign = ConfirmationCampaign[campaign.confirmationCampaign]
    })
    return campaigns
  }

  loadCampaigns () {
    this.campaignService
      .listCampaigns(`?filters={"page": ${this.page}, "pageSize": ${this.pageSize}}`)
      .subscribe(
        response => {
          response.results = this.handlePropertiesTranslation(response.results)

          this.pageTotal = response.total
          this.campaigns = new MatTableDataSource(response.results)
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
  }

  reciverFeedback (returnFilter) {
    returnFilter.results = this.handlePropertiesTranslation(returnFilter.results)
    this.pageTotal = returnFilter.total
    this.campaigns = new MatTableDataSource(returnFilter.results)
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  remove (id: string) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa campanha?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.campaignService.delete(id).then(
            async response => {
              this.loadCampaigns()
              this.snackBar.open('Campanha removida com sucesso.')
            },
            error => {
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
