import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Location } from '@angular/common'
import { CustomersAssociatesService } from 'src/app/services/customersAssociates.service'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { MatSnackBar } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'

@Component({
  selector: 'app-funds-associates-form',
  templateUrl: './associates-form.component.html',
  styleUrls: ['./associates-form.component.scss'],
})
export class FundAssociatesFormComponent implements OnInit {
  public formGroup: FormGroup
  public fundId = ''
  public selectedCustomers: any[] = []
  public customerControl = new FormControl()
  customers: any[] = []
  filteredCustomers: Observable<string[]>
  separatorKeysCodes: number[] = [ENTER, COMMA]
  selectedCustomersInvalid = false

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    private readonly customersAssociatesService: CustomersAssociatesService,
    private readonly snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.fundId = this.route.snapshot.paramMap.get('fundId')
    this.customersAssociatesService.listCustomers(this.fundId).then(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      customersInput: [null, [Validators.required]],
    })
  }

  async submit () {
    if (!this.selectedCustomers.length) {
      this.selectedCustomersInvalid = true
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }
    this.customersAssociatesService
      .add(
        this.fundId,
        this.selectedCustomers.map(c => c.customerId),
      )
      .then(async response => {
        if (response.success) {
          this.snackBar.open('Usuário associado com sucesso.')
          this.location.back()
        } else {
          this.snackBar.open('Falha ao associar o usuário.')
        }
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  goBack () {
    this.location.back()
  }

  private _filter (customer: string): string[] {
    const remove = String(customer).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.customers
      .filter(c => !this.selectedCustomers.includes(c))
      .filter(c =>
        `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
      )
      .slice(0, 20)
  }

  removeCustomer (customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
    this.selectedCustomersInvalid = true
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    this.selectedCustomers.push(event.option.value)
    this.customerInput.nativeElement.value = ''
    this.customerControl.setValue(null)
    if (this.selectedCustomers) this.selectedCustomersInvalid = false
  }
}
