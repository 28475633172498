import { AbstractControl } from '@angular/forms';
import * as i0 from "@angular/core";
var ErrorsService = /** @class */ (function () {
    function ErrorsService() {
    }
    ErrorsService.prototype.messageErrorFor = function (control) {
        if (control.invalid) {
            if (control.errors.required) {
                return 'Campo deve ser preenchido';
            }
            else if (control.errors.email) {
                return 'Campo deve ser um e-mail';
            }
            else if (control.errors.minlength) {
                return "Campo deve conter pelo menos " + control.errors.minlength.requiredLength + " d\u00EDgitos";
            }
            else if (control.errors.maxlength) {
                return "Campo deve conter no m\u00E1ximo " + control.errors.maxlength.requiredLength + " d\u00EDgitos";
            }
            else if (control.errors.pattern || control.errors.invalid) {
                return 'Valor inválido';
            }
            else if (control.errors.passwordNotMatch) {
                return 'Senhas não correspondem';
            }
            else if (control.errors.transactionNotMatch) {
                return 'Informe um valor válido';
            }
            else {
                return 'Campo deve ser preenchido corretamente.';
            }
        }
    };
    ErrorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorsService_Factory() { return new ErrorsService(); }, token: ErrorsService, providedIn: "root" });
    return ErrorsService;
}());
export { ErrorsService };
