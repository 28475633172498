<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Colunas</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airport/{{ airportID }}/information/{{ informationID }}/fields"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Colunas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Ordem</mat-label>
              <input type="number" matInput formControlName="order" />
              <mat-error *ngIf="formGroup.controls.order?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.order) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Título do Header</mat-label>
              <input matInput formControlName="fieldTitle" />
              <mat-error *ngIf="formGroup.controls.fieldTitle?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.fieldTitle) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valor</mat-label>
              <input matInput formControlName="fieldValue" />
              <mat-error *ngIf="formGroup.controls.fieldValue?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.fieldValue) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Texto</mat-label>
              <input matInput formControlName="fieldLabel" />
              <mat-error *ngIf="formGroup.controls.fieldLabel?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.fieldLabel) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
