import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
var BusinessPartnersListComponent = /** @class */ (function () {
    function BusinessPartnersListComponent(businessPartnersService, router, snackBar, dialog, route, cdRef, categoriesService, location) {
        this.businessPartnersService = businessPartnersService;
        this.router = router;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.route = route;
        this.cdRef = cdRef;
        this.categoriesService = categoriesService;
        this.location = location;
        this.categoryID = '';
        this.heading = '';
        this.category = '';
        this.tableFilter = false;
        this.isFilter = false;
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    BusinessPartnersListComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    BusinessPartnersListComponent.prototype.ngOnInit = function () {
        this.headersTable = ['nameCategory', 'active', 'actions'];
        // this.headersTableMain = ['ordination', 'nameCategoryMain', 'activeMain', 'actionsMain']
        this.headersTableMain = ['nameCategoryMain', 'activeMain', 'actionsMain'];
        this.categoryID = this.route.snapshot.paramMap.get('categoryID');
        // this.fieldsForm = [
        //   {
        //     field: 'input',
        //     type: 'text',
        //     formControlName: 'nameBusinessPartners',
        //     label: 'Nome',
        //     placeholder: 'Nome',
        //   },
        //   {
        //     field: 'select',
        //     formControlName: 'activeBusinessPartners',
        //     label: 'Ativo',
        //     list: [
        //       { key: '1', value: 'ATIVO' },
        //       { key: '0', value: 'INATIVO' },
        //     ],
        //     optionDefault: 'TODOS',
        //   },
        // ]
        this.initial().catch(function (err) { return console.log(err); });
    };
    BusinessPartnersListComponent.prototype.initial = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.categoryID) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadInfos().catch(function (err) { return console.log(err); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.businessPartnersList()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BusinessPartnersListComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.categoriesService.findOne(this.categoryID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.heading = response.heading;
                                this.parentId = response.parentId;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        if (!this.parentId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.categoriesService.findOne(this.parentId).then(function (response) {
                                _this.category = response.heading;
                            }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (this.isCategoryJoin) {
                            // await this.categoriesService.joinList(this.categoryID).then(
                            //   async response => {
                            //     this.categoryJoin = await response.results
                            //     this.categoryJoinId = await this.categoryJoin[0].id
                            //     this.isFilter = await true
                            //     this.fieldsForm = await [
                            //       {
                            //         field: 'input',
                            //         type: 'text',
                            //         formControlName: 'nameBusinessPartners',
                            //         label: 'Nome',
                            //         placeholder: 'Nome',
                            //       },
                            //       {
                            //         field: 'select',
                            //         formControlName: 'activeBusinessPartners',
                            //         label: 'Ativo',
                            //         list: [
                            //           { key: '1', value: 'ATIVO' },
                            //           { key: '0', value: 'INATIVO' },
                            //         ],
                            //         optionDefault: 'TODOS',
                            //       },
                            //       {
                            //         field: 'select',
                            //         formControlName: 'categoriesBusinessPartners',
                            //         label: 'Categorias',
                            //         list: [
                            //           {
                            //             key: `${this.categoryJoin[0].id}`,
                            //             value: `${this.categoryJoin[0].categoryHeading}`,
                            //           },
                            //           {
                            //             key: `${this.categoryJoin[1].id}`,
                            //             value: `${this.categoryJoin[1].categoriesName}`,
                            //           },
                            //         ],
                            //         // optionDefault: this.categoryJoin[0].id,
                            //       },
                            //     ]
                            //   },
                            //   error => {
                            //     console.log(error)
                            //     this.snackBar.open(error.error.message)
                            //   },
                            // )
                        }
                        else {
                            this.isFilter = true;
                            this.fieldsForm = [
                                {
                                    field: 'input',
                                    type: 'text',
                                    formControlName: 'nameBusinessPartners',
                                    label: 'Nome',
                                    placeholder: 'Nome',
                                },
                                {
                                    field: 'select',
                                    formControlName: 'activeBusinessPartners',
                                    label: 'Ativo',
                                    list: [
                                        { key: '1', value: 'ATIVO' },
                                        { key: '0', value: 'INATIVO' },
                                    ],
                                    optionDefault: 'TODOS',
                                },
                            ];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BusinessPartnersListComponent.prototype.goBack = function () {
        this.location.back();
    };
    BusinessPartnersListComponent.prototype.drop = function (event) {
        var _this = this;
        if (event.previousContainer !== event.container) {
            return;
        }
        var previousIndex = this.dataSourceMain.data.findIndex(function (row) { return row === event.item.data; });
        if (event.currentIndex === Number(previousIndex)) {
            return;
        }
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja salvar alteração na ordem das Perguntas ?',
                buttonConfirmText: 'Salvar',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var newWorldOrder;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!result || result === 'cancel') {
                    return [2 /*return*/];
                }
                moveItemInArray(this.dataSourceMain.data, Number(previousIndex), event.currentIndex);
                this.reorder();
                newWorldOrder = this.dataSourceMain.data.map(function (i) { return ({
                    id: i.id,
                    ordination: i.ordination,
                }); });
                this.businessPartnersService.changeOrderPartners(this.categoryID, newWorldOrder).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('Ordem alterada com sucesso.');
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    BusinessPartnersListComponent.prototype.reorder = function () {
        var e_1, _a;
        var ordination = 0;
        var data = [];
        try {
            for (var _b = tslib_1.__values(this.dataSourceMain.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                item.ordination = ++ordination;
                data.push(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.dataSourceMain.data = data;
    };
    BusinessPartnersListComponent.prototype.businessPartnersList = function () {
        var _this = this;
        var filter;
        var filterMain;
        if (this.isCategoryJoin) {
            filter = "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"isMain\"], \"op\": \"=\", \"value\":\"0\"},{\"fields\":[\"categoryJoin.id\"], \"op\": \"=\", \"value\":\"" + this.categoryJoin[0].id + "\"}]}";
            filterMain = "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"isMain\"], \"op\": \"=\", \"value\":\"1\"},{\"fields\":[\"categoryJoin.id\"], \"op\": \"=\", \"value\":\"" + this.categoryJoin[0].id + "\"}]}";
        }
        else {
            filter = "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"isMain\"], \"op\": \"=\", \"value\":\"0\"}]}";
            filterMain = '?filters={"filters":[{"fields":["isMain"], "op": "=", "value":"1"}]}';
        }
        this.businessPartnersService.list(this.categoryID, filter).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
        this.businessPartnersService.list(this.categoryID, filterMain).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // response.results.sort((a, b) => {
                //   return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0
                // })
                this.dataSourceMain = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    BusinessPartnersListComponent.prototype.receiveFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
    };
    BusinessPartnersListComponent.prototype.receiveFeedbackMain = function (returnFilterMain) {
        var nameInput = returnFilterMain.results.find(function (b) { return b.nameInput; });
        var activeInput = returnFilterMain.results.find(function (b) { return b.activeInput; });
        this.tableFilter = !!(nameInput || activeInput);
        returnFilterMain.results.sort(function (a, b) {
            return a.ordination < b.ordination ? -1 : a.ordination > b.ordination ? 1 : 0;
        });
        returnFilterMain.results.splice(returnFilterMain.results.indexOf('nameInput'), 1);
        returnFilterMain.results.splice(returnFilterMain.results.indexOf('activeInput'), 1);
        this.dataSourceMain = new MatTableDataSource(returnFilterMain.results);
    };
    return BusinessPartnersListComponent;
}());
export { BusinessPartnersListComponent };
