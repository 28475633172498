import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import {
  MatSort,
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
} from '@angular/material'
import { Location } from '@angular/common'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { AuthService } from '../../../../../services/auth.service'
import { ZipCodeService } from '../../../../../core/services/zip-code.service'

@Component({
  selector: 'app-client-form-address',
  templateUrl: './client-form-address.component.html',
  styleUrls: ['./client-form-address.component.scss'],
})
export class ClientFormAddressComponent implements OnInit {
  public formGroup: FormGroup
  private readonly initalized = false
  public customerID: string
  public addressID: string
  public kindInvalid = false

  constructor (
    private readonly formBuilder: FormBuilder,
    public errorsService: ErrorsService,
    private readonly route: ActivatedRoute,
    public authService: AuthService,
    private readonly zipCodeService: ZipCodeService,
    public snackBar: MatSnackBar,
    public location: Location,
  ) { }

  ngOnInit () {
    this.customerID = this.route.snapshot.paramMap.get('customerID')
    this.addressID = this.route.snapshot.paramMap.get('addressID')
    this.formGroup = this.formBuilder.group({
      addressTitle: [null, [Validators.required]],
      street: [null, [Validators.required]],
      addressNumber: [null, [Validators.required]],
      complement: [null, []],
      zipCode: [null, [Validators.required]],
      neighborhood: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      country: [null, [Validators.required]],
      kind: [null, []],
    })

    if (this.addressID) {
      this.addressFindOne()
    }
  }

  addressFindOne () {
    this.authService.addressFindOne(this.addressID, this.customerID).then(
      async response => {
        this.formGroup.patchValue({
          addressTitle: response.addressTitle,
          street: response.street,
          addressNumber: response.addressNumber,
          complement: response.complement,
          zipCode: response.zipCode,
          neighborhood: response.neighborhood,
          city: response.city,
          state: response.state,
          country: response.country,
          kind: response.kind,
        })
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  getAddress () {
    if (this.formGroup.value.zipCode) {
      this.zipCodeService.getAddress(this.formGroup.value.zipCode).subscribe((address: any) => {
        this.formGroup.patchValue({
          state: address.uf,
          city: address.localidade,
          neighborhood: address.bairro,
          street: address.logradouro,
        })
      })
    }
  }

  onChange () {
    this.kindInvalid = false
  }

  goBack () {
    this.location.back()
  }

  submit () {
    this.kindInvalid = !this.formGroup.value.kind
    if (!this.formGroup.valid || this.kindInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      addressTitle: this.formGroup.value.addressTitle,
      street: this.formGroup.value.street,
      addressNumber: this.formGroup.value.addressNumber,
      complement: this.formGroup.value.complement ? this.formGroup.value.complement : '',
      zipCode: this.formGroup.value.zipCode,
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      country: this.formGroup.value.country,
      customerId: this.customerID,
      kind: this.formGroup.value.kind,
    }

    if (this.addressID) {
      this.authService.updateAddress(this.addressID, data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Endereço cadastrado com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.authService.createAddress(data).then(
        async response => {
          this.location.back()
          this.snackBar.open('Endereço cadastrado com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
