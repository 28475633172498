<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Documentos</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
      <button class="btn-primary-light" mat-button (click)="refresh()">ATUALIZAR</button>
      <button class="btn-primary-light" mat-button routerLink="/dashboard/stores/{{ storeID }}/documents/new">
        ADICIONAR
      </button>
    </div>
  </header>

  <div *ngIf="this.store" class="store-zoop-status">
    <mat-label>Status na Zoop</mat-label> :
    <span [ngClass]="[store.isZoopEnabled ? 'enabled-zoop' : 'pending-zoop']">
      {{ store.isZoopEnabled ? 'Habilitado' : 'Aguardando aprovação' }}
    </span>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="documents">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">{{ convertType(element.type) }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ convertStatusDocument(element.status) }}</td>
      </ng-container>
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>Link</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Abrir Documento" matTooltipPosition="left"
            (click)="openDocument(element.path)">
            <mat-icon>get_app</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
