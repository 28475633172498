<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Adicionar Termo</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack(document)">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Termo (PDF)</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputBanner.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <span *ngIf="approveDoc" class="filename-placeholder">
                <mat-icon>description</mat-icon> {{ this.approveDoc }}
              </span>
              <div>
                <span class="remove-doc-wrapper" style="margin-right: 5px; margin-left: 20px">
                  <button
                    class="btn-primary-light"
                    mat-button
                    *ngIf="id && documentUrl"
                    (click)="download($event)"
                  >
                    Download
                  </button>
                </span>
                <span class="remove-doc-wrapper">
                  <button
                    class="btn-primary-light"
                    mat-button
                    *ngIf="approveDoc"
                    (click)="removeDocument()"
                  >
                    <mat-icon>cancel</mat-icon> Remover termo
                  </button>
                </span>
              </div>
              <input hidden (change)="selectFile($event)" #fileInputBanner type="file" id="file" />
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
