import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TermService = /** @class */ (function () {
    function TermService(http) {
        this.http = http;
    }
    TermService.prototype.list = function (filter) {
        var _this = this;
        return this.http.get(environment.apiUrl + "/cms/terms")
            .pipe(tap(function (_) { return _this.log('cms/terms?page=0&range=10'); }), catchError(this.handleError));
    };
    TermService.prototype.add = function (data) {
        var _this = this;
        return this.http.post(environment.apiUrl + "/cms/terms", data)
            .pipe(tap(function (_) { return _this.log('cms/terms'); }), catchError(this.handleError));
    };
    TermService.prototype.update = function (data, termID) {
        var _this = this;
        return this.http.put(environment.apiUrl + "/cms/terms/" + termID, data)
            .pipe(tap(function (_) { return _this.log('cms/terms'); }), catchError(this.handleError));
    };
    TermService.prototype.delete = function (termID) {
        var _this = this;
        return this.http.delete(environment.apiUrl + "/cms/terms/" + termID)
            .pipe(tap(function (_) { return _this.log('cms/terms'); }), catchError(this.handleError));
    };
    TermService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    TermService.prototype.log = function (message) {
    };
    TermService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TermService_Factory() { return new TermService(i0.ɵɵinject(i1.HttpClient)); }, token: TermService, providedIn: "root" });
    return TermService;
}());
export { TermService };
