<app-logged class="store-form">
  <header class="store-form__header">
    <h2>NOVO CLIENTE</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/clients">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" mask="000.000.000-00" required />
              <mat-error *ngIf="formGroup.controls.cpf?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.cpf) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" required />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" required />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Gênero</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option [value]="'female'">Feminino</mat-option>
                <mat-option [value]="'male'">Masculino</mat-option>
                <mat-option [value]="'none'">Prefiro não falar</mat-option>
                <mat-option [value]="'other'">Outro</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.gender?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.gender) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Data de Nascimento</mat-label>
              <input type="date" matInput formControlName="birthDate" />
              <mat-error *ngIf="formGroup.controls.birthDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.birthDate) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" required />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <div class="additional-email-section">
              <mat-card-title class="bold-small-font">Documento</mat-card-title>
              <mat-form-field>
                <mat-label>Frente</mat-label>
                <input matInput disabled />
                <a mat-icon-button matSuffix (click)="fileInputDocumentFront.click()">
                  <mat-icon>attach_file</mat-icon>
                </a>
                <a
                  *ngIf="croppedDocumentFrontImage"
                  matSuffix
                  mat-icon-button
                  (click)="editDocumentFrontImage()"
                >
                  <mat-icon>edit</mat-icon>
                </a>
                <a
                  *ngIf="croppedDocumentFrontImage"
                  matSuffix
                  mat-icon-button
                  (click)="removeImage('documentFront')"
                >
                  <mat-icon>delete</mat-icon>
                </a>
                <img
                  *ngIf="croppedDocumentFrontImage != ''"
                  width="350"
                  src="{{ croppedDocumentFrontImage }}"
                />
                <input
                  hidden
                  (change)="selectFileDocumentFront($event)"
                  #fileInputDocumentFront
                  type="file"
                  accept="image/*"
                />
              </mat-form-field>
            </div>
            <!-- <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="frontImageInvalid"
              >Campo deve ser preenchido</mat-error
            > -->

            <mat-form-field>
              <mat-label>Verso</mat-label>
              <input matInput disabled />
              <a mat-icon-button matSuffix (click)="fileInputDocumentBehind.click()">
                <mat-icon>attach_file</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="editDocumentBehindImage()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                *ngIf="croppedDocumentBehindImage"
                matSuffix
                mat-icon-button
                (click)="removeImage('documentBehind')"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <img
                *ngIf="croppedDocumentBehindImage != ''"
                width="350"
                src="{{ croppedDocumentBehindImage }}"
              />
              <input
                hidden
                (change)="selectFileDocumentBehind($event)"
                #fileInputDocumentBehind
                type="file"
                accept="image/*"
              />
            </mat-form-field>
            <!-- <mat-error style="padding-bottom: 9px; font-size: 11px" *ngIf="behindImageInvalid"
              >Campo deve ser preenchido</mat-error
            > -->

            <mat-form-field>
              <mat-label>Habilitar membership</mat-label>
              <mat-select
                formControlName="membershipEnabled"
                (selectionChange)="changeMembershipEnabled($event)"
                required
              >
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.membershipEnabled?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.membershipEnabled) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="planSelectEnabled">
              <mat-label>Plano</mat-label>
              <mat-select
                formControlName="plan"
                (selectionChange)="changeTypePlan($event)"
                required
              >
                <mat-option [value]="'free'">Free</mat-option>
                <mat-option [value]="'recurrent'">Pagamento Recorrente</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.plan?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.plan) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="firstPaymentEnabled">
              <mat-label>Primeiro pagamento</mat-label>
              <input type="date" matInput formControlName="firstPayment" required />
              <mat-error *ngIf="formGroup.controls.firstPayment?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstPayment) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Segmentação</mat-label>
              <mat-select formControlName="kind" required>
                <mat-option *ngFor="let kind of kinds" [value]="kind.id"
                  >{{ kind.kindName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.kind?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.kind) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Atendentes</mat-label>
              <mat-select formControlName="attendant">
                <mat-option *ngFor="let attendant of attendants" [value]="attendant.id"
                  >{{ attendant.firstName }} {{ attendant.lastName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.attendant?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.attendant) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bloquear pagamentos</mat-label>
              <mat-select formControlName="blockTransaction" required>
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.blockTransaction?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.blockTransaction) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bloquear pagamentos com JCoins</mat-label>
              <mat-select formControlName="blockTransactionJcoins" required>
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="additional-email-section">
              <mat-card-title class="bold-small-font">E-mail Adicional para Cobrança</mat-card-title>
              <mat-form-field>
                <mat-label>E-mail Adicional para Cobrança</mat-label>
                <input matInput formControlName="billingEmail" placeholder="E-mail Adicional para Cobrança" />
                <mat-error *ngIf="formGroup.controls.billingEmail?.invalid">
                  {{ errorsService.messageErrorFor(formGroup.controls.billingEmail) }}
                </mat-error>
              </mat-form-field>
            </div>

          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
