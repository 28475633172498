import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
var ModalLateArchitectInvoices = /** @class */ (function () {
    function ModalLateArchitectInvoices(dialogRef, data, snackBar, sanitizer) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        this.sanitizer = sanitizer;
        this.headersTable = ['selector', 'storeName', 'invoiceNumber', 'amount', 'createdAt'];
        this.selectedInvoices = {};
        console.log(data);
        this.dataSource = new MatTableDataSource(data);
    }
    ModalLateArchitectInvoices.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalLateArchitectInvoices.prototype.confirm = function () {
        var selectedInvoiceIds = Object.entries(this.selectedInvoices)
            .map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return value ? key : null;
        })
            .filter(function (key) { return key; });
        this.dialogRef.close(selectedInvoiceIds);
    };
    ModalLateArchitectInvoices.prototype.not = function () {
        this.dialogRef.close('not');
    };
    ModalLateArchitectInvoices.prototype.ngOnInit = function () {
        // empty
    };
    ModalLateArchitectInvoices.prototype.getSanitizedHtml = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    ModalLateArchitectInvoices.prototype.onInvoiceSelect = function (isChecked, invoiceId) {
        this.selectedInvoices[invoiceId] = isChecked;
    };
    ModalLateArchitectInvoices.prototype.convertCentsToReal = function (value) {
        if (value)
            return value / 100;
        else
            return value;
    };
    return ModalLateArchitectInvoices;
}());
export { ModalLateArchitectInvoices };
