<main class="modal-customer-info">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>

  <div mat-dialog-content>
    <mat-card>
      <h3>CPF: {{ data.customerCpf | mask: '000.000.000-00' }}</h3>
    </mat-card>
  </div>
</main>
