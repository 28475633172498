<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Colunas</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airport/{{ airportID }}/informations"
      >
        VOLTAR
      </button>
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airport/{{ airportID }}/information/{{ informationID }}/field/new"
      >
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card-category"><b>Título Informação: </b>{{ titleInfo }}</mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="fields">
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Ordem</th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>

      <ng-container matColumnDef="fieldTitle">
        <th mat-header-cell *matHeaderCellDef>Título do Header</th>
        <td mat-cell *matCellDef="let element">{{ element.fieldTitle }}</td>
      </ng-container>

      <ng-container matColumnDef="fieldValue">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let element">{{ element.fieldValue }}</td>
      </ng-container>

      <ng-container matColumnDef="fieldLabel">
        <th mat-header-cell *matHeaderCellDef>Texto</th>
        <td mat-cell *matCellDef="let element">{{ element.fieldLabel }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            routerLink="/dashboard/airport/{{ airportID }}/information/{{ informationID }}/field/{{
              element.id
            }}/edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
