import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { Params } from '@angular/router'
import { AirportsService } from '../../../../../services/airports.service'
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'airports-stores-tax-cashback',
  templateUrl: './airports-stores-tax-cashback-list.component.html',
  styleUrls: ['./airports-stores-tax-cashback-list.component.scss'],
})
export class AirportsStoresTaxCashbackListComponent implements OnInit {
  public headersTable: string[] = ['cnpj', 'storeName', 'taxCashback', 'actions']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })
  }

  async list () {
    const { results } = await this.airportsService
      .storesTaxCashbackList()
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [] }
      })

    console.log(results)
    this.dataSource = new MatTableDataSource(results)
    // this.resultsAll = results
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa taxa do parceiro de origem ?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.deleteStoresTaxCashback(id).then(
            async response => {
              this.list().catch(error => console.log(error))
              this.snackBar.open('Taxa removida com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }
}
