import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalDeleteClosingComponent } from 'src/app/components/modal-delete-closing/modal-delete-closing.component';
import { ModalIntegrationsClosingsComponent } from 'src/app/components/modal-integrations-closings/modal-closings.component';
import JsFileDownloader from 'js-file-downloader';
var ClosingsAirportsComponent = /** @class */ (function () {
    function ClosingsAirportsComponent(authService, formBuilder, snackBar, errorsService, router, dialog, reportService) {
        this.authService = authService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.errorsService = errorsService;
        this.router = router;
        this.dialog = dialog;
        this.reportService = reportService;
        this.headersTable = ['closingNumber', 'startDateEndendDate', 'actions'];
        this.closings = [];
        this.closingsSelecteds = [];
    }
    ClosingsAirportsComponent.prototype.ngOnInit = function () {
        this.formFilter = this.formBuilder.group({
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            paymentDate: [null, [Validators.required]],
        });
        this.listAll();
    };
    ClosingsAirportsComponent.prototype.create = function (result) {
        var _this = this;
        this.authService.closingAirportCreate(result).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.listAll();
                this.snackBar.open('Fechamento criado com sucesso');
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsAirportsComponent.prototype.listAll = function () {
        var _this = this;
        this.closings = [];
        this.authService.closingsAirportsIntegrations().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.closings = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ClosingsAirportsComponent.prototype.openModal = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalIntegrationsClosingsComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result !== 'cancel') {
                _this.create(result);
            }
            else {
                console.log('Modal fechado sem ações.');
            }
        });
    };
    ClosingsAirportsComponent.prototype.delete = function (id) {
        var _this = this;
        var dialogRef = this.dialog.open(ModalDeleteClosingComponent, {
            width: '500px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result != 'cancel') {
                _this.authService.deleteClosingAirport(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        this.snackBar.open('Fechamento deletado com sucesso.');
                        this.listAll();
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    console.log(error);
                    _this.snackBar.open(error.error.message);
                });
            }
        });
    };
    ClosingsAirportsComponent.prototype.export = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.closingAirportsExport(id)];
                    case 1:
                        fileUrl = _a.sent();
                        new JsFileDownloader({
                            url: fileUrl.trim(),
                            nameCallback: function () {
                                return "Fechamento Financeiro.xlsx";
                            },
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return ClosingsAirportsComponent;
}());
export { ClosingsAirportsComponent };
