import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatSnackBar } from '@angular/material';
import { Observable, Subject, of } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service';
import { MacroGroupsService } from 'src/app/services/macro-groups.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-liquidation-transfer-permissions',
  templateUrl: './liquidation-transfer-permissions.component.html',
  styleUrls: ['./liquidation-transfer-permissions.component.scss']
})
export class LiquidationTransferPermissions implements OnInit {
  macroGroups: any[] = []; 
  // stores: any[] = []; 
  settingsForm: FormGroup;
  filteredStores: Observable<any[]>;
  filteredMacroGroups: any[];
  storeSearchInput: string;
  macroGroupSearchInput: string;
  @ViewChild('input', {static: true}) input: ElementRef<HTMLInputElement>;
  private searchTerms = new Subject<string>();

  constructor(
    private formBuilder: FormBuilder,
    private storeService: StoreService,
    private macroGroupsService: MacroGroupsService,
    private snackBar: MatSnackBar,
    private liquidationTransferService: LiquidationTransferService,
    ) { }

  ngOnInit(): void {
    
    this.settingsForm = this.formBuilder.group({
      selectedMacroGroups: [[]],
      blackListedStores: [[]],
      storeSearchInput: [''],
      macroGroupSearchInput: [''],
    });
   
    this.filteredStores = this.searchTerms.pipe(      
      debounceTime(1000),
      switchMap((term => {
        if (term.length > 3) {
          return this.getFilteredStores(term);
        } else {
          return of([]); 
        }
      })),
      switchMap((result) => {
        const selectedStores =  this.settingsForm.get('blackListedStores').value

        if (selectedStores && selectedStores.length > 0) {
          const filteredStores = result.filter(store => !selectedStores.find(selectedStore => selectedStore.id === store.id));
          return of(filteredStores);
        }
        return of(result);     
      })     
    );

    
    this.settingsForm.get('macroGroupSearchInput').valueChanges.subscribe(value => {
      try {
        const filterValue = value.toLowerCase();      
        const filteredMacroGroups = this.macroGroups.filter(macroGroup => 
          macroGroup.macroName.toLowerCase().includes(filterValue)
        );

        this.setFilteredMacrogroups(filteredMacroGroups)
      } catch (e) {
        
      }
    });

    this.loadSelectedResources()
    this.loadMacroGroups()
  }

  async loadMacroGroups() : Promise<void> { 
    const { results } = await this.macroGroupsService.list()
    this.macroGroups = results
    this.setFilteredMacrogroups(this.macroGroups)
  }

  setFilteredMacrogroups(filteredMacroGroups) {
    this.filteredMacroGroups = filteredMacroGroups.filter(
      macroGroup => 
      !this.settingsForm.get('selectedMacroGroups').value.find(
        selectedMacroGroup => selectedMacroGroup.id === macroGroup.id
      )
    )  
  }

  async loadSelectedResources() {
    const { 
      macroGroupsEnabled,
      storesBlackListed, 
    } = await this.liquidationTransferService.getLiquidationTransferPermissions().toPromise()

    this.settingsForm.patchValue({
      selectedMacroGroups: macroGroupsEnabled,
      blackListedStores: storesBlackListed
    })

  }

  getFilteredStores(term: string): Observable<any[]> {
    return this.storeService.getStoreByCnpjOrName(term)
  }

  search(term: string): void {
    if (term.length > 3) {     
      this.searchTerms.next(term);
    }
  }

  searchMacroGroup(term: string): void {
    if (term.length > 3) {     
      this.searchTerms.next(term);
    }
  }


  highlightMatches(text: string, term: string): string {
    if (!term) {
      return text;
    }
    const re = new RegExp(term, 'gi');
    return text.replace(re, match => `<strong>${match}</strong>`);
  }

  // Event handler for when an option is selected in mat-autocomplete
  onOptionSelected(event: MatAutocompleteSelectedEvent): void {    
    const selectedStore = event.option.value;    
    const stores =this.settingsForm.get('blackListedStores').value || []
    stores.push(selectedStore)
    this.settingsForm.get('blackListedStores').setValue(stores);
    this.settingsForm.get('storeSearchInput').setValue('')
    event.source.options.reset([])
    
  }

  onMacroGroupOptionSelected(event: MatAutocompleteSelectedEvent): void {    
    const selectedMacroGroup = event.option.value;    
    const macroGroups =this.settingsForm.get('selectedMacroGroups').value || []
    macroGroups.push(selectedMacroGroup)
    
    this.settingsForm.get('selectedMacroGroups').setValue(macroGroups);
    this.settingsForm.get('macroGroupSearchInput').setValue('');    
    this.setFilteredMacrogroups(this.macroGroups)
    this.input.nativeElement.blur()
  }

  // Event handler for removing a store from the blackListedStores
  removeStore(index: number): void {
    const stores =this.settingsForm.get('blackListedStores').value || []
    stores.splice(index, 1);
    this.settingsForm.get('blackListedStores').setValue(stores);
  }
  
  
  removeMacroGroup(index: number): void {
    const macroGroups =this.settingsForm.get('selectedMacroGroups').value || []
    macroGroups.splice(index, 1);
    this.settingsForm.get('selectedMacroGroups').setValue(macroGroups);
    this.settingsForm.get('macroGroupSearchInput').setValue(
      this.settingsForm.get('macroGroupSearchInput').value
    )
  }

  submitForm() {
    
    const selectedMacroGroups = this.settingsForm.get('selectedMacroGroups').value
    const blackListedStores = this.settingsForm.get('blackListedStores').value
    
    const data = {
      macroGroupIds: selectedMacroGroups.map( m => m.id),
      storesBlackListedIds: blackListedStores.map( s => s.id),
    }

    if(data.macroGroupIds.length === 0) {
      this.snackBar.open('Selecione pelo menos um MacroGrupo')
      return
    }

    this.liquidationTransferService
      .updateLiquidationTransferPermissions(data)
      .toPromise()
      .then(result => {
        this.snackBar.open('Configurações atualizadas com sucesso')
      })
      .catch(err => {
        this.snackBar.open('Problema ao atualizar as informações')
      })

  }
}