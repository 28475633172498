import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TermService } from 'src/app/services/term.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './terms-list.component.html',
  styleUrls: ['./terms-list.component.scss'],
})
export class TermsListComponent implements OnInit {
  public headersTable: string[] = ['heading', 'subtitle', 'createdAt', 'updatedAt'];
  public terms: Array<any> = [];

  constructor(
    public termService: TermService,    
    public snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() { 
    this.list();
  }

  list() {
    this.termService.list().subscribe(
      async (response) => {
        this.terms = response;
      },
      (error) => {
        console.log(error);
        this.snackBar.open(error.error.message);
      }
    );
  }
}

