import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Router } from '@angular/router'
@Component({
  selector: 'app-modal-customer-info',
  templateUrl: './modal-customer-info.component.html',
  styleUrls: ['./modal-customer-info.component.scss'],
})
export class ModalCustomerInfoComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<ModalCustomerInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customerBalance: string
      customerCpf: string
      customerFullName: string
      customerBirthDate: string
      customerPhoneNumber: string
      customerEmailAddress: string
      customerGender: string
      cnpj: string
      fundName: string
      fundEmailAddress: string
      fundJcoinBalance: number
      customerId: string
      user: string
      associateFundCustomerId: string
      fundId: string
    },
    public router: Router,
  ) {}

  ngOnInit () {}

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  infoClient (customerId) {
    this.dialogRef.close()
    this.router.navigateByUrl(`/dashboard/clients/${customerId}/info`)
  }

  infoClientAssociateFund (customerId, fundId) {
    this.dialogRef.close()
    this.router.navigateByUrl(`/dashboard/funds/${fundId}/associate/client/${customerId}`)
  }
}
