import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentProviderCardFeeService = /** @class */ (function () {
    function PaymentProviderCardFeeService(http) {
        this.http = http;
    }
    PaymentProviderCardFeeService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/payment-provider-card-fees").toPromise()];
            });
        });
    };
    PaymentProviderCardFeeService.prototype.addNewFee = function (fee) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/payment-provider-card-fees", fee).toPromise()];
            });
        });
    };
    PaymentProviderCardFeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentProviderCardFeeService_Factory() { return new PaymentProviderCardFeeService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentProviderCardFeeService, providedIn: "root" });
    return PaymentProviderCardFeeService;
}());
export { PaymentProviderCardFeeService };
