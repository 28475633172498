import { Injectable } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor() {}

  messageErrorFor(control: AbstractControl) {
    if (control.invalid) {
      if (control.errors.required) {
        return 'Campo deve ser preenchido';
      } else if (control.errors.email) {
        return 'Campo deve ser um e-mail';
      } else if (control.errors.minlength) {
        return `Campo deve conter pelo menos ${control.errors.minlength.requiredLength} dígitos`;
      } else if (control.errors.maxlength) {
        return `Campo deve conter no máximo ${control.errors.maxlength.requiredLength} dígitos`;
      } else if (control.errors.pattern || control.errors.invalid) {
        return 'Valor inválido';
      } else if (control.errors.passwordNotMatch) {
        return 'Senhas não correspondem';
      }else if (control.errors.transactionNotMatch) {
        return 'Informe um valor válido';
      } else {
        return 'Campo deve ser preenchido corretamente.';
      }
    }
  }
}
