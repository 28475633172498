import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Store } from 'src/app/models/store'
import { ModalCancelTransactionComponent } from 'src/app/components/modal-cancel-transaction/modal-cancel-transaction.component'
import { ModalCustomerInfoComponent } from 'src/app/components/modal-customer-info/modal-customer-info.component'
import { MatDialog, MatSnackBar } from '@angular/material'
import { DatePipe, Location } from '@angular/common'
import { TransactionService } from 'src/app/services/transaction.service'
import { User } from 'src/app/models/user'
import * as _ from 'lodash'
import { ModalConfirmationComponent } from '../../../../components/modal-confirmation/modal-confirmation.component'
import { ModalRejectComponent } from '../../../../components/modal-reject/modal-reject.component'
import { ModalInstallmentsComponent } from 'src/app/components/modal-installments/modal-installments.component'
import { FundService } from 'src/app/services/fund.service'

import numeral from 'numeral'
import { ModalRoadpassUnableToCancel } from 'src/app/components/modal-roadpass-unable-to-cancel/modal-roadpass-unable-to-cancel.component'
import { Observable } from 'rxjs'
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component'
import moment from 'moment'
@Component({
  selector: 'app-store-form',
  templateUrl: './store-transaction-detail.component.html',
  styleUrls: ['./store-transaction-detail.component.scss'],
})
export class StoreTransactionDetailComponent implements OnInit {
  public userRole: any
  public userInfo: User
  public headersTable: any[] = []
  public transactionsInfo: any[] = []
  public isInternational: any = false
  public params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] }

  public internationalTransactionData: any[] = [];
  public headerInternationalTransaction: string[] = ['currency', 'totalValue', 'totalValueWithTaxes','currencyQuote', 'purchaseFeePercent', 'purchaseFeeValue'];

  public headersTableGeneral: string[]
  public headersTableNonPartner: string[] = ['nomeFantasiaWithoutStore', 'cnpjWithoutStore']
  public transactionsInfoGeneral: any[] = []
  public transactionsInfoNonPartner: any[] = []

  public liveloData: any[] = []
  public headersLiveloData: any[] = ['id','points']

  public headersTableNfs: string[] = [
    'type',
    'totvsCode',
    'verificationCode',
    'createdAt',
  ]

  public nfs: any[] = []

  public headersTableConfigs: string[] = [
    'pointsConversionFactorOut',
    'pointsConversionFactorIn',
    'maximumInstallmentsPerTransaction',
    'minimumTransactionValue',
  ]

  public transactionsInfoConfig: any[] = []

  public headersTableInfosCard: string[] = [
    'netValue',
    'installments',
    'cardNumber',
    'cardBrand',
    'rewards',
    'cashbackValue',
  ]

  public headersTableInfosCardTwo: string[] = [
    'taxAdministrative',
    'taxAdministrativeAmount',
    'storeSplitAmount',
    'paymentProvider',
  ]

  public headersTableInfosCardThree: string[] = [
    'isInternational',
    'isVirtualCard',
    'isAdditionalCard',
  ]

  public transactionsInfoCard: any[] = []
  public transactionsInfoCardTwo: any[] = []
  public transactionsInfoCardThree: any[] = []

  public headersTableInfosJC: string[] = [
    'pointsApplied',
    'discountValue',
    'taxRewardsTransaction',
    'taxRewardsTransactionAmount',
    'storeTransferAmount',
  ]

  public headersTableLiquidationDetailOne: string[] = [
    'isAdministrativeTaxPaid',
    'administrativeTaxPaidAt',
    'administrativeTaxDueDate',
    'scheduleLink'
  ]

  public headersTableLiquidationDetailTwo: string[] = [
    'isStoreTransferPaid',
    'storeTransferPaidAt',
    'storeTransferDueDate',
    'scheduleLink'
  ]

  public transactionsInfoJC: any[] = []
  public liquidationDetails: any[];

  public headersTableFunds: string[]
  public funds: any[] = []

  public headersTableCustomerRecipient: string[]
  public recipientCustomer: any[] = []
  public recipientCustomerId: string

  public formGroup: FormGroup
  public states: Object[] = []
  public storeID = ''
  public transactionID = ''
  public store: Store
  public fundId = ''

  public isManual = false
  public subStore: any

  public transactionInfo: any
  public title = 'app'
  public elementType = 'string'
  public valueQrCode = ''
  public isMembershipActive = false
  public isPayActive = false
  public isAdmin = false
  public showCancelButton = false
  public showRejectButton = false
  public isNonPartner = false
  public balance: number
  public pointsApplied: number
  public type: string
  public subStoreId: string
  public isAdditionalCard: boolean = false
  public isVirtualCard: boolean = false

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private readonly datePipe: DatePipe,
    public transactionService: TransactionService,
    public dialog: MatDialog,
    public location: Location,
    public fundService: FundService,
  ) {
    this.initForm()
  }

  ngOnInit () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userRole = localStorage.getItem('userRole')
    this.isAdmin = this.userRole == 'ADMIN'
    this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.params.storeFormGroupValue = params.storeFormGroupValue
      this.params.formGroupValue = params.formGroupValue
      this.params.previousRoute = params.previousRoute
      this.params.storeID = params.storeID
      this.params.itComesFromNewTransaction = params.itComesFromNewTransaction
    })
    // this.params.pageTotal = Number(this.route.snapshot.queryParamMap.get('pageTotal'))
    // this.params.dataSource = this.route.snapshot.queryParamMap.getAll('dataSource')
  }

  initForm () {
    // this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.transactionID = this.route.snapshot.paramMap.get('transactionID')

    this.valueQrCode = this.storeID

    this.isManual = JSON.parse(localStorage.getItem('isManual'))
    let filter = ''

    filter = `${this.transactionID}?showManualDetails=true`

    this.transactionService.listTransactionsById(filter).subscribe(
      response => {
        this.type = response.type
        const taxRewardsTransaction = numeral(response.taxRewardsTransaction || 0).multiply(100).value()
        const taxAdministrative = numeral(response.taxAdministrative|| 0).multiply(100).value()
        this.subStores(response.storeId)
        if (response.recipientCustomerId) {
          this.recipientCustomerId = response.recipientCustomerId
          this.headersTableCustomerRecipient = ['recipient']
          this.recipientCustomer = [
            {
              recipientCustomerBalance: response.recipientCustomerBalance,
              recipientFullName: response.recipientFullName,
              recipientCpf: response.recipientCpf,
              recipientBirthDate: response.recipientBirthDate,
              recipientEmailAddress: response.recipientEmailAddress,
              recipientPhoneNumber: response.recipientPhoneNumber,
              recipientGender: response.recipientGender,
              recipientCustomerId: response.recipientCustomerId,
            },
          ]
        }

        if (response.fundId) {
          this.fundId = response.fundId
          this.headersTableFunds = ['fundName', 'document']
          this.funds = [
            {
              fundName: response.fundName,
              fundDocument: response.customerFundDocument,
              fundId: response.fundId,
            },
          ]
        }
        this.isInternational = response.isInternational
        this.isManual = response.isManual

        this.isAdditionalCard = response.isAdditionalCard
        this.isVirtualCard = response.isVirtualCard

        if (this.isManual && this.isAdmin) {
          this.showRejectButton = response.state == 'PENDING'
        }

        if (!this.isManual || (this.isManual && response.state == 'APPROVED')) {
          this.showCancelButton = response.canBeCanceled
        }

        if (this.isManual) {
          this.headersTable = ['orderNumber', 'totalValue', 'pointsApplied', 'state', 'actions']
          if (this.type === 'MEMBERSHIP') {
            if (this.isAdmin) {
              this.headersTableGeneral = [
                'storeName',
                'subStore',
                'description',
                'createdAt',
                'customer',
                'info',
                'membershipPlan',
                'approveDoc',
              ]
            } else {
              this.headersTableGeneral = [
                'storeName',
                'description',
                'createdAt',
                'customer',
                'info',
                'membershipPlan',
                'approveDoc',
              ]
            }
          } else {
            if (this.isAdmin) {
              this.headersTableGeneral = [
                'storeName',
                'subStore',
                'description',
                'createdAt',
                'customer',
                'info',
                'approveDoc',
              ]
            } else {
              this.headersTableGeneral = [
                'storeName',
                'description',
                'createdAt',
                'customer',
                'info',
                'approveDoc',
              ]
            }
          }

          if (this.isAdmin) {
            this.balance = _.get(response, 'manualDetails.balance', 0)
            this.headersTableGeneral.splice(this.headersTableGeneral.length - 1, 0, 'balance')
          }

          let userName = '';
          let canceledAt
          if (response.cmsUser) {
            userName = `${response.cmsUser.firstName} ${response.cmsUser.lastName}`
            canceledAt = moment(response.canceledAt).format("DD/MM/YYYY HH:mm:ss");
          }
          this.pointsApplied = Math.trunc(response.totalValue / response.pointsConversionFactorOut)
          this.transactionsInfo = [
            {
              id: response.id,
              reason: response.status,
              orderNumber: response.orderNumber,
              totalValue: response.totalValue / 100,
              canBeCanceled: response.canBeCanceled,
              isManual: response.isManual,
              state: response.isManual && response.state,
              canceledByUser: userName,
              canceledAt: canceledAt,
              rejectReason: _.get(response, 'manualDetails.reason')
                ? response.manualDetails.reason
                : '',
              pointsApplied: response.pointsApplied,
              type: response.type
            },
          ]
          this.transactionsInfoGeneral = [
            {
              storeName: response.storeName,
              storeNomeFantasia: response.storeNomeFantasia,
              description: response.description,
              createdAt: response.createdAt,
              customer: {
                customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
                customerCpf: response.customerCpf,
                customerBirthDate: response.customerBirthDate,
                customerPhoneNumber: response.customerPhoneNumber,
                customerEmailAddress: response.customerEmailAddress,
                customerBalance: response.customerBalance,
                customerGender: response.customerGender,
                customerId: response.customerId,
              },
              balance: _.get(response, 'manualDetails.balance')
                ? response.manualDetails.balance
                : 0,
              approveDoc: _.get(response, 'manualDetails.approveDoc')
                ? response.manualDetails.approveDoc
                : '',
              membershipPlan: response.membershipPlan,
              subStore: response.subStoreId,
            },
          ]
          this.subStoreId = response.subStoreId
        } else {
          if (response.cmsUser) {
            this.headersTable = ['orderNumber', 'totalValue', 'state', 'canceledByUser', 'canceledAt', 'actions']
          } else {
            this.headersTable = ['orderNumber', 'totalValue', 'state', 'actions']
          }
          
          if (this.type === 'MEMBERSHIP') {
            if (this.isAdmin) {
              this.headersTableGeneral = [
                'storeName',
                'subStore',
                'description',
                'createdAt',
                'customer',
                'info',
                'membershipPlan',
              ]
            } else {
              this.headersTableGeneral = [
                'storeName',
                'description',
                'createdAt',
                'customer',
                'info',
                'membershipPlan',
              ]
            }
          } else {
            if (this.isAdmin) {
              this.headersTableGeneral = [
                'storeName',
                'subStore',
                'description',
                'createdAt',
                'customer',
                'info',
              ]
            } else {
              this.headersTableGeneral = [
                'storeName',
                'description',
                'createdAt',
                'customer',
                'info',
              ]
            }
          }
           let userName = ''
           let canceledAt
          if (response.cmsUser) {
            userName = `${response.cmsUser.firstName} ${response.cmsUser.lastName}`
            canceledAt = moment(response.canceledAt).format("DD/MM/YYYY HH:mm:ss");
          } 

          this.transactionsInfo = [
            {
              id: response.id,
              reason: response.status,
              orderNumber: response.orderNumber,
              totalValue: response.totalValue / 100,
              internationalCurrencyValue: response.internationalCurrencyValue / 100,
              isInternational: response.isInternational,
              currency: response.currency,
              canBeCanceled: response.canBeCanceled,
              isManual: response.isManual,
              state: response.state,
              canceledByUser: userName,
              canceledAt: canceledAt,
              type: response.type,
              internalErrorDescription: response.internalErrorDescription,
              zoopErrorCategory: response.zoopErrorCategory,
              zoopErrorMessage: response.zoopErrorMessage,
              zoopPaymentStatus: response.zoopPaymentStatus,
              zoopErrorMessageDisplay: response.zoopErrorMessageDisplay,
              zoopErrorResponseCode: response.zoopErrorResponseCode,
              errorCodeTransactionLivelo: response.errorCodeTransactionLivelo,
              errorTransactionLivelo: response.errorTransactionLivelo,
            },
          ]
          this.transactionsInfoGeneral = [
            {
              storeName: response.storeName,
              storeNomeFantasia: response.storeNomeFantasia,
              description: response.description,
              createdAt: response.createdAt,
              customer: {
                customerFullName: `${response.customerFirstName} ${response.customerLastName}`,
                customerCpf: response.customerCpf,
                customerBirthDate: response.customerBirthDate,
                customerPhoneNumber: response.customerPhoneNumber,
                customerEmailAddress: response.customerEmailAddress,
                customerBalance: response.customerBalance,
                customerGender: response.customerGender,
                customerId: response.customerId,
              },
              balance: '',
              approveDoc: '',
              membershipPlan: response.membershipPlan,
              subStore: response.subStoreId,
            },
          ]

          if (response.nomeFantasiaWithoutStore) {
            this.isNonPartner = true

            this.transactionsInfoNonPartner = [
              {
                nomeFantasiaWithoutStore: response.nomeFantasiaWithoutStore,
                cnpjWithoutStore: response.cnpjWithoutStore,
              },
            ]

            this.transactionsInfoGeneral[0].storeName = '-'
            this.transactionsInfoGeneral[0].storeNomeFantasia = '-'
          }

          this.subStoreId = response.subStoreId
        }

        this.nfs = response.nfs

        this.transactionsInfoConfig = [
          {
            pointsConversionFactorOut: response.pointsConversionFactorOut / 100,
            pointsConversionFactorIn: response.pointsConversionFactorIn / 100,
            maximumInstallmentsPerTransaction: response.maximumInstallmentsPerTransaction,
            minimumTransactionValue: response.minimumTransactionValue / 100,
          },
        ]

        this.transactionsInfoCard = [
          {
            netValue:
              response.type === 'C2C'
                ? 0
                : response.state === 'APPROVED'
                  ? response.netValue / 100
                  : 0,
            installments:
              response.type === 'C2C' || !response.netValue ? '' : response.installments,
            paidInstallments:
              response.type === 'C2C' || !response.netValue ? '' : response.paidInstallments || 0,
            cardBrand: typeof response.cardBrand !== 'undefined' ? response.cardBrand : '',
            rewards: response.taxCashbackRewardsAmount,
            cashbackValue: response.cashbackValue / 100,
            cardNumber: response.cardMaskedNumber || response.cardNumber,
            totalRewarded:
              response.walletExecution.reduce((acc: number, item: { amount: number }) => {
                return acc + item.amount
              }, 0),
            installmentsData: response.walletExecution,
          },
        ]

        this.transactionsInfoCardTwo = [
          {
            taxAdministrative: taxAdministrative.toString().replace('.', ','),
            taxAdministrativeAmount: response.taxAdministrativeAmount / 100,
            storeSplitAmount: response.storeSplitAmount / 100,
            paymentProvider: response.paymentProvider,
          },
        ]

        this.transactionsInfoCardThree = [
          {
            isInternational: response.isInternational,
            isVirtualCard: response.isVirtualCard,
            isAdditionalCard: response.isAdditionalCard,
          },
        ]

        this.transactionsInfoJC = [
          {
            pointsApplied: response.pointsApplied ? response.pointsApplied : 0,
            discountValue: response.discountValue / 100,
            taxRewardsTransaction: taxRewardsTransaction.toString().replace('.', ','),
            taxRewardsTransactionAmount: response.taxRewardsTransactionAmount / 100,
            storeTransferAmount: response.storeTransferAmount / 100,
          },
        ]

        if(this.isAdmin && response.liquidationDetails ) {
          const liquidationDetails: any = {}

          if(response.liquidationDetails.administrativeTaxPayment){
            liquidationDetails.isAdministrativeTaxPaid = response.liquidationDetails.administrativeTaxPayment.success
            liquidationDetails.administrativeTaxPaidAt = response.liquidationDetails.administrativeTaxPayment.paidAt
            liquidationDetails.administrativeTaxDueDate = response.liquidationDetails.administrativeTaxPayment.dueDate
            liquidationDetails.administrativeTaxScheduleId = response.liquidationDetails.administrativeTaxPayment.scheduleId
          }
          if(response.liquidationDetails.liquidationPayment){
            liquidationDetails.isStoreTransferPaid = response.liquidationDetails.liquidationPayment.success
            liquidationDetails.storeTransferPaidAt = response.liquidationDetails.liquidationPayment.paidAt
            liquidationDetails.storeTransferDueDate = response.liquidationDetails.liquidationPayment.dueDate
            liquidationDetails.storeTransferScheduleId = response.liquidationDetails.liquidationPayment.scheduleId
          }

          this.liquidationDetails = [
            liquidationDetails
          ]
        }

        if (response.internationalCurrencyValue) {
          this.internationalTransactionData.push({
            ...response,
            internationalCurrencyValue: response.internationalCurrencyValue / 100,
            currencyQuote: response.currencyQuote / 100,
            purchaseFeeValue: response.purchaseFeeValue / 100,
          });
        }

        if( response.type === "LIVELO") {
          this.liveloData.push({
            transactionIdLivelo: response.transactionIdLivelo,
            pointsLivelo: response.pointsLivelo,
          })
        }
      },
      error => {
        console.log(error)
      },
    )
  }

  subStores (storeId) {
    if (this.isAdmin && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(storeId)) {
      this.storeService.listSubStores(storeId).then(
        async res => {
          this.subStore = res.results
        },
        error => {
          console.log(error)
          alert(error.error.message)
        },
      )
    }
  }

  formatBrand (brand) {
    console.log(brand)
    if (brand == 'undefined' || brand == null) {
      return
    }

    if (brand == 'AMEX') {
      brand = 'american-express'
    }

    const brands = [
      'mastercard',
      'maestro',
      'visa',
      'hipercard',
      'hiper',
      'jcb',
      'elo',
      'banes',
      'aura',
      'american-express',
    ]

    brand = brand.trim().toLowerCase().replace(' ', '-')

    if (_.includes(brands, brand)) {
      return brand
    } else {
      return 'unknown'
    }
  }

  convertPercentToNumber (value) {
    return value / 100
  }

  refresh (): void {
    window.location.reload()
  }

  goBack () {
    if (this.params.itComesFromNewTransaction) {
      const lastSegment = this.isInternational ? 'new-international' : 'new'
      this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions', lastSegment], { queryParams: this.params, skipLocationChange: true })
    } else {
      if (this.params.storeID) {
        this.router.navigate(['dashboard', 'stores', this.params.storeID, 'transactions'], { queryParams: this.params, skipLocationChange: true })
      } else {
        this.router.navigate(['dashboard', 'transactions'], { queryParams: this.params, skipLocationChange: true })
      }
    }
  }

  openDialogCancelTransaction (transaction): void {
    const dialogRef = this.dialog.open(ModalCancelTransactionComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.cancel(transaction.id, result, transaction.type)
      }
    })
  }

  openDialogRejectTransaction (transaction) {
    const dialogRef = this.dialog.open(ModalRejectComponent, {
      width: '500px',
      data: {
        transaction: transaction,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.rejectTransactionManual(transaction.id, result)
      }
    })
  }

  openDialogConfirmation (transaction) {
    if (transaction.isManual) {
      if (this.balance < this.pointsApplied) {
        this.snackBar.open('Saldo insuficiente.')
        return false
      }

      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '500px',
        data: {
          transaction: transaction,
        },
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'cancel') {
          this.approvedTransactionManual(transaction.id)
        }
      })
    }
  }

  rejectTransactionManual (transactionID, reason) {
    const data = {
      nextState: 'REJECTED',
      reason,
    }
    this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(
      async response => {
        this.snackBar.open('Transação rejeitada com sucesso.')
        this.initForm()
      },
      error => {
        this.snackBar.open('Não é permitido fazer transações nesse estabelecimento')
      },
    )
  }

  approvedTransactionManual (transactionID) {
    const data = {
      nextState: 'APPROVED',
    }
    this.transactionService.changeStateTransactionManual(transactionID, data).subscribe(
      async response => {
        this.snackBar.open('Transação aprovada com sucesso.')
        this.initForm()
      },
      error => {
        this.snackBar.open('Não é permitido fazer transações nesse estabelecimento')
        this.initForm()
      },
    )
  }

  openModalWarningUnableToCancel (): Promise<void> {
    const dialogRef = this.dialog.open(ModalRoadpassUnableToCancel, {
      width: '500px',
    })

    return dialogRef.afterClosed().toPromise()
  }

  openModalErrorDetails(transaction) {
    const errorsToShow = {
      "Descrição do Erro": transaction.internalErrorDescription,
      "Categoria do erro na Zoop": transaction.zoopErrorCategory,
      "Mensagem de erro da Zoop": transaction.zoopErrorMessage,
      "Status do pagamento na Zoop": transaction.zoopPaymentStatus,
      "Detalhes do erro na Zoop": transaction.zoopErrorMessageDisplay,
      "Código do erro na Zoop": transaction.zoopErrorResponseCode,
    }
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '500px',
      data: {
        html: Object.entries(errorsToShow).map(([key, val]) => val ? `<div><strong>${key}</strong>: ${val}</div>`: '').join(' ')
      }
    })

    return dialogRef.afterClosed().toPromise()
  }
  openModalLiveloErrorDetails(transaction) {
    const errorsToShow = {
      "Código do Erro na Livelo": transaction.errorCodeTransactionLivelo,
      "Descrição de erro da Livelo": transaction.errorTransactionLivelo,
    }
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '500px',
      data: {
        html: Object.entries(errorsToShow).map(([key, val]) => val ? `<div><strong>${key}</strong>: ${val}</div>`: '').join(' ')
      }
    })

    return dialogRef.afterClosed().toPromise()
  }

  cancel (transactionID, reason, transactionType) {
    const data = {
      reason: reason,
    }

    let cancelObservable: Observable<any>;
    if(transactionType && transactionType.toLowerCase() === 'c2c')
      cancelObservable = this.transactionService.cancelTransactionC2C(transactionID, data)
    else
      cancelObservable = this.transactionService.cancelTransaction(transactionID, data)

    cancelObservable.subscribe(
      async response => {
        if (response && response.roadPassUnableToCancel) { await this.openModalWarningUnableToCancel() }

        this.snackBar.open('Transação cancelada com sucesso.')
        this.initForm()
      },
      error => {
        this.snackBar.open('Não é permitido fazer transações nesse estabelecimento')
      },
    )
  }

  convertStatus (status) {
    switch (status) {
      case 'PENDING':
        return this.isManual ? 'Aguardando aprovação da JHSF' : 'Pendente'
      case 'APPROVED':
        return 'Aprovado'
      case 'REJECTED':
        return 'Rejeitado'
      case 'CANCELED':
        return 'Cancelado'
      default:
        return ''
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }

  listInstallments (totalInstallments, installments) {
    this.dialog.open(ModalInstallmentsComponent, {
      data: {
        totalInstallments,
        installments,
      },
    })
  }

  detail (info) {
    this.dialog.open(ModalCustomerInfoComponent, {
      data: {
        customerBalance: info.customerBalance,
        customerCpf: info.customerCpf,
        customerFullName: info.customerFullName,
        customerBirthDate: info.customerBirthDate,
        customerEmailAddress: info.customerEmailAddress,
        customerPhoneNumber: info.customerPhoneNumber,
        customerGender: info.customerGender,
        customerId: info.customerId,
        user: this.userRole,
      },
    })
  }

  detailFund (fundId) {
    this.fundService
      .list(`?filters={"filters":[{"fields":["funds.id"], "op": "=", "value":"${fundId}"}]}`)
      .then(result => {
        const fund = result.results.find(() => true)
        const dialogRef = this.dialog.open(ModalCustomerInfoComponent, {
          data: {
            cnpj: fund.cnpj,
            fundName: fund.name,
            fundEmailAddress: fund.emailAddress,
            fundJcoinBalance: fund.jcoinBalance,
            user: this.userRole,
          },
        })
      })
      .catch(err => {
        this.snackBar.open(err.error.message)
      })
  }

  detailRecipient (info) {
    this.dialog.open(ModalCustomerInfoComponent, {
      data: {
        customerBalance: info.recipientCustomerBalance,
        customerCpf: info.recipientCpf,
        customerFullName: info.recipientFullName,
        customerBirthDate: info.recipientBirthDate,
        customerEmailAddress: info.recipientEmailAddress,
        customerPhoneNumber: info.recipientPhoneNumber,
        customerGender: info.recipientGender,
        customerId: info.recipientCustomerId,
        user: this.userRole,
      },
    })
  }

  changeSubStore (subStoreId) {
    this.subStoreId = subStoreId
  }

  async update () {
    const body = {
      subStoreId: this.subStoreId,
    }
    if (this.subStoreId) {
      await this.transactionService.update(this.transactionID, body).then(
        async response => {
          this.snackBar.open('Sub-empresa atualizada com sucesso.')
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  getNfType (entityId) {
    const type = entityId.split('-').at(-1)

    switch (type) {
      case '01':
        return 'Taxa Administrativa JHSF'

      case '03':
        return 'Taxa Transação JCoins'

      default:
        return `Desconhecido (${type})`
    }
  }

  async loadLiquidationPaymentInfo(id){
    console.log("ENTROU")
    await this.transactionService.getLiquidationPaymentInfo(id)
  }
}
