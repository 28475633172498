import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var PaymentProviderCardFeeFormComponent = /** @class */ (function () {
    function PaymentProviderCardFeeFormComponent(paymentProviderCardFeeService, formBuilder, snackBar, location, errorsService) {
        this.paymentProviderCardFeeService = paymentProviderCardFeeService;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.location = location;
        this.errorsService = errorsService;
        this.initForm();
    }
    PaymentProviderCardFeeFormComponent.prototype.ngOnInit = function () { };
    PaymentProviderCardFeeFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            paymentProvider: [null, [Validators.required]],
            cardBrand: [null, [Validators.required]],
            installments1: [null, [Validators.required]],
            installments2to6: [null, [Validators.required]],
            installments7to12: [null, [Validators.required]],
            debit: [null, [Validators.required]],
        });
    };
    PaymentProviderCardFeeFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formGroup.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/];
                        }
                        data = {
                            paymentProvider: this.formGroup.value.paymentProvider,
                            cardBrand: this.formGroup.value.cardBrand,
                            installments1: this.formGroup.value.installments1,
                            installments2to6: this.formGroup.value.installments2to6,
                            installments7to12: this.formGroup.value.installments7to12,
                            debit: this.formGroup.value.debit,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.paymentProviderCardFeeService.addNewFee(data)];
                    case 2:
                        _a.sent();
                        this.snackBar.open('Taxa adicionada com sucesso.');
                        this.goBack();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        this.snackBar.open(e_1.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentProviderCardFeeFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    return PaymentProviderCardFeeFormComponent;
}());
export { PaymentProviderCardFeeFormComponent };
