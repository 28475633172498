/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/unlogged/unlogged.component.ngfactory";
import * as i3 from "../../../components/unlogged/unlogged.component";
import * as i4 from "./email-confirmation.component";
import * as i5 from "../../../core/services/errors.service";
import * as i6 from "../../../services/auth.service";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/router";
var styles_EmailConfirmationComponent = [i0.styles];
var RenderType_EmailConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailConfirmationComponent, data: {} });
export { RenderType_EmailConfirmationComponent as RenderType_EmailConfirmationComponent };
export function View_EmailConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-unlogged", [], null, null, null, i2.View_UnloggedComponent_0, i2.RenderType_UnloggedComponent)), i1.ɵdid(1, 114688, null, 0, i3.UnloggedComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "div", [["class", "forgot-password"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "forgot-password__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Logo JHSF ID"], ["src", "assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "forgot-password__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Obrigado por fazer parte do JHSF ID"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EmailConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password", [], null, null, null, View_EmailConfirmationComponent_0, RenderType_EmailConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i4.EmailConfirmationComponent, [i5.ErrorsService, i6.AuthService, i7.MatSnackBar, i8.FormBuilder, i9.Router, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailConfirmationComponentNgFactory = i1.ɵccf("app-forgot-password", i4.EmailConfirmationComponent, View_EmailConfirmationComponent_Host_0, {}, {}, []);
export { EmailConfirmationComponentNgFactory as EmailConfirmationComponentNgFactory };
