import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
var LiquidationTransferSettings = /** @class */ (function () {
    function LiquidationTransferSettings(formBuilder, errorsService, snackBar, liquidationTransferService) {
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.liquidationTransferService = liquidationTransferService;
    }
    LiquidationTransferSettings.prototype.ngOnInit = function () {
        this.settingsForm = this.formBuilder.group({
            administrativeTaxCnpjReceiver: [null, [Validators.required]],
            scheduleAdvanceInDays: [null, [Validators.required]],
            bancoOrigem: [null, [Validators.required]],
            agenciaOrigem: [null, [Validators.required]],
            contaOrigem: [null, [Validators.required]],
            tipoContaDebitada: [null, [Validators.required]],
            tokenUsuario: [null, [Validators.required]],
            inscricaoParceiro: [null, [Validators.required]],
        });
        this.loadSettings();
    };
    LiquidationTransferSettings.prototype.loadSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, administrativeTaxCnpjReceiver, scheduleAdvanceInDays, bancoOrigem, agenciaOrigem, contaOrigem, tipoContaDebitada, tokenUsuario, inscricaoParceiro;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.liquidationTransferService.getLiquidationTransferSettings().toPromise()];
                    case 1:
                        _a = _b.sent(), administrativeTaxCnpjReceiver = _a.administrativeTaxCnpjReceiver, scheduleAdvanceInDays = _a.scheduleAdvanceInDays, bancoOrigem = _a.bancoOrigem, agenciaOrigem = _a.agenciaOrigem, contaOrigem = _a.contaOrigem, tipoContaDebitada = _a.tipoContaDebitada, tokenUsuario = _a.tokenUsuario, inscricaoParceiro = _a.inscricaoParceiro;
                        this.settingsForm.patchValue({
                            administrativeTaxCnpjReceiver: administrativeTaxCnpjReceiver,
                            scheduleAdvanceInDays: scheduleAdvanceInDays,
                            bancoOrigem: bancoOrigem,
                            agenciaOrigem: agenciaOrigem,
                            contaOrigem: contaOrigem,
                            tipoContaDebitada: tipoContaDebitada,
                            tokenUsuario: tokenUsuario,
                            inscricaoParceiro: inscricaoParceiro,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    LiquidationTransferSettings.prototype.submitForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, res, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.settingsForm.valid) {
                            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                            return [2 /*return*/, false];
                        }
                        data = {
                            administrativeTaxCnpjReceiver: this.settingsForm.get('administrativeTaxCnpjReceiver').value,
                            scheduleAdvanceInDays: this.settingsForm.get('scheduleAdvanceInDays').value,
                            bancoOrigem: this.settingsForm.get('bancoOrigem').value,
                            agenciaOrigem: this.settingsForm.get('agenciaOrigem').value,
                            contaOrigem: this.settingsForm.get('contaOrigem').value,
                            tipoContaDebitada: this.settingsForm.get('tipoContaDebitada').value,
                            tokenUsuario: this.settingsForm.get('tokenUsuario').value,
                            inscricaoParceiro: this.settingsForm.get('inscricaoParceiro').value,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.liquidationTransferService.updateLiquidationTransferSettings(data).toPromise()];
                    case 2:
                        res = _a.sent();
                        this.snackBar.open('Configurações atualizadas com sucesso');
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.snackBar.open('Problema ao atualizar as configurações');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return LiquidationTransferSettings;
}());
export { LiquidationTransferSettings };
