import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import JsFileDownloader from "js-file-downloader";
import { ModalGenericComponent } from "src/app/components/modal-generic/modal-generic.component";
var ArchitectClosingList = /** @class */ (function () {
    function ArchitectClosingList(architectClosingsService, location, dialog, snackBar) {
        this.architectClosingsService = architectClosingsService;
        this.location = location;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.headersTable = ['closingNumber', 'startDate', 'endDate', 'totalInvoices', 'totalInvoicesValue', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ArchitectClosingList.prototype.ngOnInit = function () {
        this.loadClosings();
    };
    ArchitectClosingList.prototype.loadClosings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, total, results;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.architectClosingsService.findAll({
                            page: this.page,
                            range: this.pageSize
                        })];
                    case 1:
                        _a = _b.sent(), total = _a.total, results = _a.results;
                        this.pageTotal = total;
                        this.closingsList = new MatTableDataSource(results);
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingList.prototype.filterCallback = function (returnFilter) {
        var total = returnFilter.total, results = returnFilter.results;
        this.pageTotal = total;
        this.closingsList = new MatTableDataSource(results);
    };
    ArchitectClosingList.prototype.goBack = function () {
        this.location.back();
    };
    ArchitectClosingList.prototype.export = function (architectClosingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.architectClosingsService.exportClosing(architectClosingId)];
                    case 1:
                        response = _a.sent();
                        if (response.report) {
                            new JsFileDownloader({
                                url: response.report,
                                nameCallback: function (fileName) {
                                    return ('Relatório de Fechamento Arquitetos.' +
                                        (fileName.split('.').pop() || 'xls'));
                                },
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.snackBar.open(error_1.error.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingList.prototype.removeClosing = function (architectClosingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.dialog.open(ModalGenericComponent, {
                                width: '500px',
                                data: {
                                    text: 'Deseja remover esse fechamento?',
                                    buttonConfirmText: 'Remover'
                                }
                            }).afterClosed().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!(result && result !== 'cancel')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.architectClosingsService.removeClosing(architectClosingId)];
                    case 2:
                        _a.sent();
                        this.snackBar.open('Fechamento removido com sucesso');
                        this.loadClosings();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.snackBar.open('Problema ao remover o fechamento');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ArchitectClosingList.prototype.convertCentsToReal = function (value) {
        return value / 100;
    };
    return ArchitectClosingList;
}());
export { ArchitectClosingList };
