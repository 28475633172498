<app-logged class="store-list">
  <header class="store-list__header">
    <h2>LISTA DE QUESTIONÁRIOS</h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        mat-button
        (click)="goBack()"
        [queryParams]="params"
        skipLocationChange="true"
      >
        VOLTAR
      </button>
    </div>
  </header>
  <app-form-filter
    actions="form"
    type="quizCustomer"
    [customerID]="customerID"
    [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"
  ></app-form-filter>
  <div class="table-responsive">
    <mat-card class="card-category"><b>Cliente: </b>{{ fullName }}</mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }}" class="cellText">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amountAnswer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Perguntas Respondidas (QTDE)</th>
        <td mat-cell *matCellDef="let element" class="cellText">
          {{ element.answerCount }} / {{ element.questionCount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="Respostas"
            matTooltipPosition="left"
            routerLink="/dashboard/quiz/{{ element.id }}/answers/customer/{{ customerID }}"
          >
            <mat-icon>question_answer</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter
      actions="pagination"
      type="quizCustomer"
      [customerID]="customerID"
      [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions"
      (returnFilter)="reciverFeedback($event)"
    ></app-form-filter>
  </div>
</app-logged>
