import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { AirportsService } from '../../../../services/airports.service'

@Component({
  selector: 'app-info-field-form',
  templateUrl: './info-field-form.component.html',
  styleUrls: ['./info-field-form.component.scss'],
})
export class AirportInfoFieldFormComponent implements OnInit {
  public formGroup: FormGroup
  public airportID: string
  public informationID: string
  public fieldID: string

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public airportsService: AirportsService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit () {
    this.airportID = this.route.snapshot.paramMap.get('airportID')
    this.informationID = this.route.snapshot.paramMap.get('informationID')
    this.fieldID = this.route.snapshot.paramMap.get('fieldID')
    this.initForm()
  }

  initForm () {
    this.formGroup = this.formBuilder.group({
      order: [null, [Validators.required]],
      fieldTitle: [null, []],
      fieldValue: [null, []],
      fieldLabel: [null, []],
    })
    if (this.fieldID) {
      this.loadInfos().catch(err => console.log(err))
    }
  }

  async loadInfos () {
    await this.airportsService.infoFieldFindOne(this.fieldID).then(async response => {
      this.formGroup.patchValue({
        order: response.order,
        fieldTitle: response.fieldTitle,
        fieldValue: response.fieldValue,
        fieldLabel: response.fieldLabel,
      })
    })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      order: this.formGroup.value.order,
      fieldTitle: this.formGroup.value.fieldTitle,
      fieldValue: this.formGroup.value.fieldValue,
      fieldLabel: this.formGroup.value.fieldLabel,
    }

    if (this.fieldID) {
      this.airportsService.updateInfoField(this.informationID, this.fieldID, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate([
              './dashboard/airport/' +
                this.airportID +
                '/information/' +
                this.informationID +
                '/fields',
            ])
            this.snackBar.open('Informação atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.airportsService.createInfoField(this.informationID, data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate([
              './dashboard/airport/' +
                this.airportID +
                '/information/' +
                this.informationID +
                '/fields',
            ])
            this.snackBar.open('Informação criado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
