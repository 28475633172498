<app-logged class="funds-list">
  <header class="funds-list__header">
    <h2>Extrato do Fundo</h2>
    <div class="funds-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/funds" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card"><b>Fundo:</b> {{ fund.name }}</mat-card>
  </div>
  <br />
  <app-form-filter [fundId]="fundId" actions="form" type="fundExtract" [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)"></app-form-filter>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10px">Id Transação</th>
        <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="transactionDetail">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.transactionId" mat-icon-button matTooltip="Transação" matTooltipPosition="left"
            routerLink="/dashboard/transactions/detail/{{ element.transactionId }}">
            <mat-icon>swap_horiz</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
        <td mat-cell *matCellDef="let element">{{ element.date }}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="200px">Nome</th>
        <td mat-cell *matCellDef="let element">{{ element.customerFullName }}</td>
      </ng-container>
      <ng-container matColumnDef="infoCostumer">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" matTooltip="CPF: {{ element.customerCpf | mask: '000.000.000-00' }}">
          <button *ngIf="element.customerId" mat-icon-button
            (click)="detail(element.customerId, element.customerFullName, element.customerCpf)">
            <mat-icon class="smallicon">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="value">Valor</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{
            positiveValue: element.value > 0,
            negativeValue: element.value < 0
          }">
          {{ element.value }} pontos
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter [fundId]="fundId" type="fundExtract" actions="pagination" [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"></app-form-filter>
  </div>
</app-logged>
