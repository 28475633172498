import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
import moment from 'moment';
var ModalManualTedRequestComponent = /** @class */ (function () {
    function ModalManualTedRequestComponent(dialogRef, data, snackBar, sanitizer, formBuilder, errorsService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.errorsService = errorsService;
        this.dueDateIsSameOrBeforeToday = false;
        console.log('data', data);
    }
    ModalManualTedRequestComponent.prototype.cancel = function () {
        this.dialogRef.close('not');
    };
    ModalManualTedRequestComponent.prototype.confirm = function () {
        this.dialogRef.close({
            bankAgency: this.formGroup.value.bankAgency,
            bankNumber: this.formGroup.value.bankNumber,
            bankAccountNumber: this.formGroup.value.bankAccountNumber,
            bankAccountDigit: this.formGroup.value.bankAccountDigit,
            propagateChangesToStore: this.formGroup.value.propagateChangesToStore,
            scheduleToNextBusinessDay: this.formGroup.value.scheduleToNextBusinessDay,
        });
    };
    ModalManualTedRequestComponent.prototype.not = function () {
        this.dialogRef.close('not');
    };
    ModalManualTedRequestComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            bankAgency: [this.data.bankAgency, [Validators.required]],
            bankNumber: [this.data.bankNumber, [Validators.required]],
            bankAccountNumber: [this.data.bankAccountNumber, [Validators.required]],
            bankAccountDigit: [this.data.bankAccountDigit, [Validators.required]],
            propagateChangesToStore: [false, []],
            scheduleToNextBusinessDay: [false, []],
        });
        console.log(this.data);
        this.dueDateIsSameOrBeforeToday = moment(this.data.dueDate).isSameOrBefore(moment(), 'day');
    };
    ModalManualTedRequestComponent.prototype.getSanitizedHtml = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    ModalManualTedRequestComponent.prototype.submit = function () {
        console.log();
    };
    return ModalManualTedRequestComponent;
}());
export { ModalManualTedRequestComponent };
