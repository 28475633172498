import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var AirportInformationFormComponent = /** @class */ (function () {
    function AirportInformationFormComponent(errorsService, formBuilder, airportsService, snackBar, router, route) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
    }
    AirportInformationFormComponent.prototype.ngOnInit = function () {
        this.airportID = this.route.snapshot.paramMap.get('airportID');
        this.informationID = this.route.snapshot.paramMap.get('informationID');
        this.initForm();
    };
    AirportInformationFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            title: [null, [Validators.required]],
            order: [null, [Validators.required]],
            footerMsg: [null, [Validators.required]],
        });
        if (this.informationID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    AirportInformationFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.airportsService.informationFindOne(this.informationID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    title: response.title,
                                    order: response.order,
                                    footerMsg: response.footerMsg,
                                });
                                this.airportID = response.airportId;
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AirportInformationFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    title: this.formGroup.value.title,
                    order: this.formGroup.value.order,
                    footerMsg: this.formGroup.value.footerMsg,
                };
                if (this.informationID) {
                    this.airportsService.updateInformation(this.airportID, this.informationID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isUpdated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/airport/' + this.airportID + '/informations'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.airportsService.createInformation(this.airportID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    console.log(response);
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/airport/' + this.airportID + '/informations'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação criado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return AirportInformationFormComponent;
}());
export { AirportInformationFormComponent };
