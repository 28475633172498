<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!planId">CADASTRO PLANO MEMBERSHIP</h2>
    <h2 *ngIf="planId">EDIÇÃO PLANO MEMBERSHIP</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/memberships/plans" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações do Plano Membership</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome do Plano Membership</mat-label>
              <input matInput formControlName="name" placeholder="Nome do Plano Membership" id="name" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Data Início</mat-label>
              <input matInput type="date" formControlName="beginDate" placeholder="Data Início" />
              <mat-error *ngIf="formGroup.controls.beginDate?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.beginDate) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Data Final</mat-label>
              <input matInput type="date" formControlName="endDate" placeholder="Data Final" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Default</mat-label>
              <mat-select formControlName="isDefault" (selectionChange)="changeDefault($event)">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isDefault?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isDefault) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Free</mat-label>
              <mat-select formControlName="isFree" (selectionChange)="changeFree($event)">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isFree?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isFree) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>PJ</mat-label>
              <mat-select formControlName="isPj">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
              <mat-error *ngIf="formGroup.controls.isPj?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.isPj) }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="!formGroup.value.isFree">
              <mat-label>Valor Plano</mat-label>
              <input matInput formControlName="value" placeholder="Valor Plano" currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px" *ngIf="valueInvalid && !formGroup.value.isFree">
              Campo deve ser preenchido</mat-error>
            <mat-form-field *ngIf="!formGroup.value.isFree">
              <mat-label>Período Recorrente</mat-label>
              <input matInput formControlName="recurrencePeriod" placeholder="Período Recorrente" type="number" />
            </mat-form-field>
            <mat-error style="padding-bottom: 9px; font-size: 12px"
              *ngIf="recurrencePeriodInvalid && !formGroup.value.isFree">Campo deve ser preenchido</mat-error>
            <mat-radio-group formControlName="isActive">
              <mat-radio-button value="1">Ativo</mat-radio-button>
              <mat-radio-button value="0">Inativo</mat-radio-button>
            </mat-radio-group>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
