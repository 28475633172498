import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ModalUserComponent } from 'src/app/components/modal-user/modal-user.component';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
var ArchitectStoresUserListComponent = /** @class */ (function () {
    function ArchitectStoresUserListComponent(companiesService, snackBar, router, dialog, route, authService, storeService) {
        this.companiesService = companiesService;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.route = route;
        this.authService = authService;
        this.storeService = storeService;
        this.headersTable = ['fullName', 'phoneNumber', 'emailAddress', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ArchitectStoresUserListComponent.prototype.ngOnInit = function () {
        this.usersStoresId = this.route.snapshot.paramMap.get('id');
        this.list().catch(function (err) { console.log(err); });
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'fullNameUserArchitectStores',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'emailUserArchitectStores',
                label: 'Email',
                placeholder: 'Email',
            },
        ];
    };
    ArchitectStoresUserListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, usersStores, totalCount;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .usersListStoresBusinessUnits(this.usersStoresId, "?&page=" + this.page + "&limit=" + this.pageSize)
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { usersStores: [], totalCount: 0 };
                        })];
                    case 1:
                        _a = _b.sent(), usersStores = _a.usersStores, totalCount = _a.totalCount;
                        this.dataSource = new MatTableDataSource(usersStores);
                        this.pageTotal = totalCount;
                        return [2 /*return*/];
                }
            });
        });
    };
    ArchitectStoresUserListComponent.prototype.openDialogNewUser = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalUserComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != 'cancel') {
                _this.storeService.getUserInfo("email=" + encodeURIComponent(result)).toPromise()
                    .then(function (res) {
                    _this.router.navigate(["dashboard/architect-stores/" + _this.usersStoresId + "/users/" + res.userId + "/edit"]);
                })
                    .catch(function (err) {
                    _this.router.navigate(['./dashboard/architect-stores/' + _this.usersStoresId + '/users/new/' + result]);
                });
            }
        });
    };
    ArchitectStoresUserListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Usuário?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.authService.unrelateUserWithStoreBusinessUnit(id, this.usersStoresId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.list().catch(function (error) { return console.log(error); });
                                this.snackBar.open('Usuário removido com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    ArchitectStoresUserListComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ModalComponent, {
            width: '450px',
            data: {},
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result > 0) {
                _this.router.navigate(['./dashboard/architect-store/new/' + result]);
            }
        });
    };
    ArchitectStoresUserListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter.usersStores;
        this.pageTotal = returnFilter.totalCount;
        this.dataSource = new MatTableDataSource(returnFilter.usersStores);
        this.resultsAll = returnFilter.usersStores;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return ArchitectStoresUserListComponent;
}());
export { ArchitectStoresUserListComponent };
