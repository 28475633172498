import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RegulationService {

  public loading;

  constructor(private http: HttpClient) { }

  listRegulations(filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/regulations`)
      .pipe(
        tap(_ => this.log('cms/regulations?page=0&range=10')),
        catchError(this.handleError)
      )
  }

  addRegulation(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/regulations`, data)
      .pipe(
        tap(_ => this.log('cms/regulations')),
        catchError(this.handleError)
      )
  }

  updateRegulation(data, regulationID): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/regulations/${regulationID}`, data)
      .pipe(
        tap(_ => this.log('cms/regulations')),
        catchError(this.handleError)
      )
  }

  deleteRegulation(regulationID): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/regulations/${regulationID}`)
      .pipe(
        tap(_ => this.log('cms/regulations')),
        catchError(this.handleError)
      )
  }

  private handleError(error: any) { 
    return throwError(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
  }

  
}
