import { Injectable, Component } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  extractNumbers(value: string) {
    return value && value.match(/\d+/g).join('')
  }

  static getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
}
}
