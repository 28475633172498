import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'campaignFilter',
})
export class CampaignFilterPipe implements PipeTransform {
  transform (items: any[], searchText: string): any {
    if (!items) {
      return []
    }
    if (!searchText) {
      return items
    }
    searchText = searchText.toLocaleLowerCase()
    return items.filter(it => {
      return it.nomeFantasia.toLocaleLowerCase().includes(searchText)
    })
  }
}
