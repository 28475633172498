import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, } from '@angular/material';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import JsFileDownloader from 'js-file-downloader';
import { ActivatedRoute, Params } from '@angular/router';
var PreRegistrationListComponent = /** @class */ (function () {
    function PreRegistrationListComponent(preRegistrationService, snackBar, location, dialog, route, reportService) {
        this.preRegistrationService = preRegistrationService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.route = route;
        this.reportService = reportService;
        this.headersTable = ['cpf', 'name', 'isActive', 'Jcoins', 'actions'];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    PreRegistrationListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userRole = localStorage.getItem('userRole');
        this.typePreRegistration = this.route.snapshot.paramMap.get('type');
        if (this.typePreRegistration !== 'architects') {
            if (this.typePreRegistration) {
                this.filters = "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"typeRegistration\"], \"op\": \"=\", \"value\":\"INCORPORATION_AIRPORT\"}]}";
            }
            else {
                this.filters = "?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + ",\"filters\":[{\"fields\":[\"typeRegistration\"], \"op\": \"<>\", \"value\":\"INCORPORATION_AIRPORT\"}]}";
            }
            this.route.queryParams.subscribe(function (params) {
                if (params.formGroupValue) {
                    _this.formGroup = JSON.parse(params.formGroupValue);
                }
                else {
                    _this.preRegistrationlist();
                }
            });
        }
        if (this.typePreRegistration === 'architects') {
            this.preRegistrationlistArchitects();
        }
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'namePreRegistration',
                label: 'Nome',
                placeholder: 'Nome',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'cpfPreRegistration',
                label: 'CPF',
                placeholder: 'CPF',
            },
        ];
    };
    PreRegistrationListComponent.prototype.preRegistrationlistArchitects = function () {
        var _this = this;
        this.preRegistrationService
            .listArchitects("?filters={\"page\": " + this.page + ", \"pageSize\": " + this.pageSize + "}")
            .then(function (response) {
            var e_1, _a;
            response.results.map(function (user) {
                user.fullName = user.preRegistration.firstName + " " + user.preRegistration.lastName;
            });
            _this.pageTotal = response.total;
            try {
                for (var _b = tslib_1.__values(response.results), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var r = _c.value;
                    r.preRegistration.balancePending = r.balancePending;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.preRegistration = new MatTableDataSource(response.results.map(function (r) { return r.preRegistration; }));
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    PreRegistrationListComponent.prototype.preRegistrationlist = function () {
        var _this = this;
        this.preRegistrationService
            .list(this.filters)
            .then(function (response) {
            response.results.map(function (user) {
                user.fullName = user.firstName + " " + user.lastName;
            });
            _this.pageTotal = response.total;
            _this.preRegistration = new MatTableDataSource(response.results);
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    PreRegistrationListComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse Pré-Cadastro?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    if (!this.typePreRegistration || this.typePreRegistration === 'INCORPORATION_AIRPORT') {
                        return [2 /*return*/, this.preRegistrationService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.preRegistrationlist();
                                    this.snackBar.open('Pré-Cadastro removido com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    }
                    if (this.typePreRegistration === 'architects') {
                        return [2 /*return*/, this.preRegistrationService.deleteArchitect(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.preRegistrationlistArchitects();
                                    this.snackBar.open('Pré-Cadastro removido com sucesso.');
                                    return [2 /*return*/];
                                });
                            }); }, function (error) {
                                console.log(error);
                                _this.snackBar.open(error.error.message);
                            })];
                    }
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    PreRegistrationListComponent.prototype.reciverFeedback = function (returnFilter) {
        returnFilter.results.map(function (user) {
            user.fullName = user.firstName + " " + user.lastName;
        });
        this.pageTotal = returnFilter.total;
        if (!this.typePreRegistration || this.typePreRegistration === 'INCORPORATION_AIRPORT') {
            this.preRegistration = new MatTableDataSource(returnFilter.results);
        }
        if (this.typePreRegistration === 'architects') {
            this.preRegistration = new MatTableDataSource(returnFilter.results.map(function (r) { return r.preRegistration; }));
        }
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    PreRegistrationListComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.typePreRegistration === 'architects')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.reportService.createReport('architects/pre-registers').then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    // eslint-disable-next-line no-new
                                    new JsFileDownloader({
                                        url: response.fileUrl,
                                        nameCallback: function () {
                                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                            return 'Relatório de Pré Cadastro.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                        },
                                    });
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(this.typePreRegistration === 'INCORPORATION_AIRPORT' || !this.typePreRegistration)) return [3 /*break*/, 4];
                        this.typePreRegistration ? filter = '?typePreRegistration=INCORPORATION_AIRPORT' : filter = '?typePreRegistration=OTHERS,APP,SSO,CMS';
                        return [4 /*yield*/, this.reportService.createReportPreRegistration(filter).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    // eslint-disable-next-line no-new
                                    new JsFileDownloader({
                                        url: response.fileUrl,
                                        nameCallback: function () {
                                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                            return 'Relatório de Pré Cadastro.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                        },
                                    });
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return PreRegistrationListComponent;
}());
export { PreRegistrationListComponent };
