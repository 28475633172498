<app-logged class="segmentation-form">
  <header class="segmentation-form__header">
    <h2 *ngIf="!segmentationId">NOVA SEGMENTAÇÃO</h2>
    <h2 *ngIf="segmentationId">EDITAR SEGMENTAÇÃO</h2>
    <div class="segmentation-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/segmentation" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-6 col-sm-12">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="formGroup.controls.name?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.name) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>limite</mat-label>
              <input matInput type="number" formControlName="limit" (change)="limitChanged()" />
              <mat-error *ngIf="formGroup.controls.limit?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.limit) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
        </div>
      </div>
      <div class="table-responsive filters">
        <table mat-table [dataSource]="filters" cdkDropList [cdkDropListData]="filters"
          (cdkDropListDropped)="drop($event)">
          <ng-container matColumnDef="field">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="filtersHeader">Filtro</th>
            <td mat-cell *matCellDef="let element" class="filtersCell">
              {{ element.fieldTitle | titlecase }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <mat-form-field class="footerField">
                <mat-label>Campo</mat-label>
                <mat-select formControlName="field" (selectionChange)="selectedField($event)">
                  <mat-option *ngFor="let col of resultCols" [value]="col">
                    {{ col.field | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="op">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="filtersHeader">
              Operador
            </th>
            <td mat-cell *matCellDef="let element" class="filtersCell">{{ element.op }}</td>
            <td mat-footer-cell *matFooterCellDef>
              <mat-form-field class="footerField">
                <mat-label>Op</mat-label>
                <mat-select *ngIf="formGroup.value.field.key !== 'macroGroups'" formControlName="op">
                  <mat-option *ngFor="let op of operators" [value]="op">
                    {{ op }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="formGroup.value.field.key === 'macroGroups'" formControlName="op">
                  <mat-option [value]="'='"> = </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="filtersHeader">Valor</th>
            <td mat-cell *matCellDef="let element" class="filtersCell">
              <span *ngIf="element.fieldType == 'datetime' && element.field !== 'macroGroups'">{{
                element.value | date: 'dd/MM/yyyy'
                }}</span>
              <span *ngIf="element.fieldType !== 'datetime' && element.field !== 'macroGroups'">{{
                element.value
                }}</span>
              <span *ngIf="element.field === 'macroGroups'">{{ element.name }}</span>
              <!-- <span
                *ngIf="element.fieldType == 'datetime'; then datetimeType; else defaultType"
              ></span>
              <ng-template #datetimeType>{{ element.value | date: 'dd/MM/yyyy' }}</ng-template>
              <ng-template #defaultType>{{ element.value }}</ng-template> -->
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <mat-form-field class="footerField">
                <mat-label>Valor</mat-label>
                <input *ngIf="
                    formGroup.value.op != 'Ordem' &&
                    formGroup.value.field.type != 'datetime' &&
                    formGroup.value.field.key !== 'macroGroups'
                  " matInput formControlName="value" />
                <input *ngIf="
                    formGroup.value.op != 'Ordem' &&
                    formGroup.value.field.type == 'datetime' &&
                    formGroup.value.field.key !== 'macroGroups'
                  " type="date" matInput formControlName="value" />
                <select *ngIf="
                    formGroup.value.op == 'Ordem' && formGroup.value.field.key !== 'macroGroups'
                  " matNativeControl formControlName="value">
                  <option value="asc">ASC</option>
                  <option value="desc">DESC</option>
                </select>
                <mat-select *ngIf="formGroup.value.field.key == 'macroGroups'" formControlName="value">
                  <mat-option *ngFor="let mg of macroGroups" [value]="mg.id">
                    {{ mg.macroName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="50px"></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button matTooltip="Remover" matTooltipPosition="left" (click)="removeFilter(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <button mat-icon-button matTooltip="Adicionar" type="button" (click)="addFilter()">
                <mat-icon>add_circle</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable" cdkDrag [cdkDragStartDelay]="100" [cdkDragData]="row"
            class="grabbable"></tr>
          <tr mat-footer-row *matFooterRowDef="headersTable"></tr>
        </table>
        <div *ngIf="formGroup.value.op == 'like'">
          <br />
          <span>Use o like com o coringa "%" nas buscas. </span>
          <br />
          <span>ex.: filtrar por "Gênero" "like" "%male" retornará tanto "male" quando "female"</span>
          <br />
          <br />
          <span>Você pode usar o coringa para substituir qualquer parte do que esta procurando.</span>
          <br />
          <span>ex.: filtrar por "Gênero" "like" "%o%e" retornará tanto "other" quanto "none"</span>
        </div>
      </div>
      <div class="segmentation-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
  <br />
  <div class="table-responsive results">
    <h3>Preview</h3>
    <table mat-table [dataSource]="results">
      <ng-container *ngFor="let col of resultCols">
        <ng-container matColumnDef="{{ col.key }}">
          <th mat-header-cell *matHeaderCellDef class="resultRow">{{ col.field | titlecase }}</th>
          <td mat-cell *matCellDef="let element" class="resultRow">
            <span *ngIf="col.type == 'string'">{{ element[col.key] }}</span>
            <span *ngIf="col.type == 'number'">{{ element[col.key] }}</span>
            <span *ngIf="col.type == 'boolean'">{{ element[col.key] | yesNo }}</span>
            <span *ngIf="col.type == 'datetime'">{{ element[col.key] | date }}</span>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="resultColsKeys"></tr>
      <tr mat-row *matRowDef="let row; columns: resultColsKeys"></tr>
    </table>
    <!-- Angular 8 pagination -->
    <app-form-filter actions="pagination" type="segmentationForm" [limit]="limit" [filtersSegmentation]="filters"
      [length]="pageTotal" [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)">
    </app-form-filter>
  </div>
</app-logged>
