import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
var ModalChooseStoreComponent = /** @class */ (function () {
    function ModalChooseStoreComponent(dialogRef, data, formBuilder, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.stores = [];
        this.storesSelected = [];
        this.stores = data.stores;
    }
    ModalChooseStoreComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalChooseStoreComponent.prototype.submit = function () {
        if (!this.formGroup.valid) {
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        this.dialogRef.close(this.formGroup.value.store);
    };
    ModalChooseStoreComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            store: [null, [
                    Validators.required,
                ]],
        });
    };
    return ModalChooseStoreComponent;
}());
export { ModalChooseStoreComponent };
