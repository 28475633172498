import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import JsFileDownloader from 'js-file-downloader';
var ModalFormBalanceJCoinsComponent = /** @class */ (function () {
    function ModalFormBalanceJCoinsComponent(errorsService, snackBar, formBuilder, reportService, dialogRef) {
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this.reportService = reportService;
        this.dialogRef = dialogRef;
        this.selectedCustomers = [];
        this.customerControl = new FormControl();
        this.customerInvalid = false;
        this.allCpf = false;
        this.customers = [];
        this.id = [];
        this.separatorKeysCodes = [ENTER, COMMA];
    }
    ModalFormBalanceJCoinsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            customersInput: [null, [Validators.required]],
        });
        this.reportService.listCustomers().subscribe(function (response) {
            response.map(function (item) {
                _this.customers.push(item);
            });
        }, function (error) {
            console.log(error);
        });
        this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(function (customer) { return (customer ? _this._filter(customer) : _this.customers.slice(0, 20)); }));
    };
    ModalFormBalanceJCoinsComponent.prototype._filter = function (customer) {
        var _this = this;
        var remove = String(customer).replace(/[.-]/g, '');
        if (typeof remove !== 'string') {
            return;
        }
        return this.customers
            .filter(function (c) { return !_this.selectedCustomers.includes(c); })
            .filter(function (c) {
            return (String(c.cpf) + " " + String(c.name)).toLowerCase().includes(remove.toLowerCase());
        })
            .slice(0, 20);
    };
    ModalFormBalanceJCoinsComponent.prototype.removeCustomer = function (customer) {
        var index = this.selectedCustomers.indexOf(customer);
        if (index >= 0) {
            this.selectedCustomers.splice(index, 1);
        }
    };
    ModalFormBalanceJCoinsComponent.prototype.selected = function (event) {
        this.selectedCustomers.push(event.option.value);
        this.customerInput.nativeElement.value = '';
        this.customerControl.setValue(null);
        event.option.value ? (this.customerInvalid = false) : (this.customerInvalid = true);
    };
    ModalFormBalanceJCoinsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormBalanceJCoinsComponent.prototype.checkAllCpf = function () {
        if (this.allCpf) {
            this.allCpf = false;
            this.customerInput.nativeElement.value = '';
            this.customerControl.setValue(null);
            this.customerInvalid = false;
        }
        else {
            this.allCpf = true;
        }
    };
    ModalFormBalanceJCoinsComponent.prototype.submit = function () {
        var _this = this;
        var customers = [];
        if (!this.allCpf) {
            this.selectedCustomers.length ? (this.customerInvalid = false) : (this.customerInvalid = true);
            customers = this.selectedCustomers.map(function (c) { return c.customerId; });
        }
        else {
            customers = [];
            this.customerInvalid = false;
        }
        if (this.customerInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var filter = "balance-jcoins?customersIds=" + customers;
        this.reportService
            .createReport(filter)
            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // eslint-disable-next-line no-new
                new JsFileDownloader({
                    url: response.fileUrl,
                    nameCallback: function () {
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        return (
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        'Relatório de balanço J-Coins.' + response.fileUrl.split('.').splice(5, 5).join('.'));
                    },
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (error) {
            _this.snackBar.open(error.message);
        });
    };
    return ModalFormBalanceJCoinsComponent;
}());
export { ModalFormBalanceJCoinsComponent };
