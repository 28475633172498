import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ErrorsService } from 'src/app/core/services/errors.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup;

  hide = true;

  constructor(
    public errorsService: ErrorsService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
  ) {
    this.initForm();
  }

  ngOnInit() { }

  initForm() {
    this.formGroup = this.formBuilder.group({
      emailAddress: [null, [Validators.required, Validators.email]],
    });
  }

  submit() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente o campo e tente novamente.');
      return false;
    }
    this.authService.passwordRecover(this.formGroup.value).subscribe(
      async (response) => {
        if(response.isRecovered) {
          this.snackBar.open('E-mail de recuperação enviado com sucesso.');
          this.router.navigate(['/login']);
        }
        
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }
}
