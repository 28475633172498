<app-logged class="store-list">
  <header class="store-list__header">
    <h2>ENDEREÇOS DO CLIENTE</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()" [queryParams]="params"
        skipLocationChange="true">VOLTAR</button>
      <button class="btn-primary-light" mat-button routerLink="/dashboard/clients/{{ customerID }}/form/address">
        ADICIONAR
      </button>
    </div>
  </header>
  <div class="table-responsive">
    <mat-card class="card-category"><b>CLIENTE: </b>{{ customer }}</mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="addressTitle">
        <th mat-header-cell *matHeaderCellDef width="230px">Descrição</th>
        <td mat-cell *matCellDef="let element" matTooltip="{{ element.addressTitle }}">
          {{ element.addressTitle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Endereço</th>
        <td mat-cell matTooltip="{{ element.street }}, {{ element.addressNumber }} {{ element.complement }},
        {{ element.zipCode }}, {{ element.neighborhood }}, {{ element.city }},
        {{ element.state }}, {{ element.country }}" *matCellDef="let element">
          {{ element.street }}, {{ element.addressNumber }} {{ element.complement }},
          {{ element.zipCode }}, {{ element.neighborhood }}, {{ element.city }},
          {{ element.state }}, {{ element.country }}
        </td>
      </ng-container>

      <ng-container matColumnDef="kind">
        <th mat-header-cell *matHeaderCellDef width="120px">Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ convertKind(element.kind) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef width="100px">Ações</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Editar" matTooltipPosition="left"
            routerLink="/dashboard/clients/{{ customerID }}/form/address/{{ element.id }}">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="excluir" matTooltipPosition="left" (click)="remove(element.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
