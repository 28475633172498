import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup } from '@angular/forms';
var ModalResendCredentialsComponent = /** @class */ (function () {
    function ModalResendCredentialsComponent(dialogRef, data, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackBar = snackBar;
        console.log('data', data);
    }
    ModalResendCredentialsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalResendCredentialsComponent.prototype.confirm = function () {
        this.dialogRef.close(1);
    };
    ModalResendCredentialsComponent.prototype.ngOnInit = function () { };
    return ModalResendCredentialsComponent;
}());
export { ModalResendCredentialsComponent };
