<app-logged class="store-list">
  <header class="store-list__header">
    <h2>CRÉDITO MANUAL DE JCOINS</h2>
  </header>

  <app-form-filter maskExample="000.000.000-00" actions="form" type="manualJcoinCredit" [fieldsForm]="fieldsForm"
    (returnFilter)="reciverFeedback($event)" [formGroupValue]="formGroup">
  </app-form-filter>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Cliente</th>
        <td mat-cell *matCellDef="let element">
          <p><b>Nome:</b> {{ element.customer?.firstName }} {{ element.customer?.lastName }}</p>
          <p><b>CPF:</b> {{ element.customer?.cpf | mask: '000.000.000-00' }}</p>
          <p><b>Origem: </b> <b>{{ getOriginName(element.customer?.origin) }}</b></p>
        </td>
      </ng-container>
      <ng-container matColumnDef="storeName">
        <th mat-header-cell *matHeaderCellDef>Parceiro</th>
        <td mat-cell *matCellDef="let element">
          <p><b>Razão Social:</b> {{ getStoreObject(element)?.razaoSocial }}</p>
          <p><b>Nome:</b> {{ getStoreObject(element)?.nomeFantasia }}</p>
          <p><b>CNPJ:</b> {{ getStoreObject(element)?.cnpj | mask: '00.000.000/0000-00' }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="requestedAt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Solicitação</th>
        <td mat-cell *matCellDef="let element" style="text-align: left">
          <p><b>Em:</b> {{ element.requestedAt | date: 'dd/MM/yy HH:mm' }}</p>
          <p><b>Por:</b> {{ element.requestedByUser?.firstName }} {{ element.requestedByUser?.lastName }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="reviewedAt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Revisão</th>
        <td mat-cell *matCellDef="let element" style="text-align: left">
          <div *ngIf="element.reviewedAt">
            <p><b>Em:</b> {{ element.reviewedAt | date: 'dd/MM/yy HH:mm' }}</p>
            <p><b>Por:</b> {{ element.reviewedByUser?.firstName }} {{ element.reviewedByUser?.lastName }}</p>
          </div>
          <div *ngIf="!element.reviewedAt">
            <p>-</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="jcoinsQt">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">JCOINS</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <b>{{ element.jcoinsQt }}</b>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Status</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <b [ngStyle]="{ 'color': getStatusColor(element.status) }">
            {{ getStatusName(element.status) }}
          </b>
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">Ações</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <span (click)="openReviewModal(element)" style="cursor: pointer; display: inline-block">
            <mat-icon>remove_red_eye</mat-icon>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
    <app-form-filter actions="pagination" type="billPaymentNotification" [length]="pageTotal"
      [pageSizeOptions]="pageSizeOptions" (returnFilter)="reciverFeedback($event)"
      [formGroupValue]="formGroup"></app-form-filter>
  </div>
</app-logged>