import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
interface DialogData {
  text: string
  html: string
  buttonConfirmText: string
  buttonNot: string
}

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
})
export class ModalGenericComponent implements OnInit {
  public formGroup: FormGroup

  constructor (
    public dialogRef: MatDialogRef<ModalGenericComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    console.log('data', data)
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  confirm () {
    this.dialogRef.close(1)
  }

  not () {
    this.dialogRef.close('not')
  }

  ngOnInit () {
    // empty
  }

  getSanitizedHtml(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
