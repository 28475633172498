import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ErrorsService } from 'src/app/core/services/errors.service';
import { ZipCodeService } from 'src/app/core/services/zip-code.service';

import { PasswordValidator } from 'src/app/validators/password.validator';
import { StoreService } from 'src/app/services/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';
import { Store } from 'src/app/models/store';
import { ConfigService } from 'src/app/services/config.service';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { RegulationService } from 'src/app/services/regulation.service';

@Component({
  selector: 'app-store-form',
  templateUrl: './regulation-form.component.html',
  styleUrls: ['./regulation-form.component.scss'],
})
export class RegulationFormComponent implements OnInit {
  public formGroup: FormGroup;
  public states: Object[] = [];
  public plans: Object[] = [];
  public configs: Array<any> = [];
  public config: any;
  public Editor = ClassicEditor;
  public regulationID: any;
  public textDescription: any;
  public regulationsInfo: Object = {
    heading: '',
    subtitle: '',
    content: '',
  };

  constructor(
    public errorsService: ErrorsService,
    private formBuilder: FormBuilder,
    public regulationService: RegulationService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initForm();
    this.config = {
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
    };
  }

  ngOnInit() {
    
  }

  initForm() {
    this.formGroup = this.formBuilder.group(
      {
        heading: [null, [Validators.required]],
        subtitle: [null, [Validators.required]],
        content: [null, [Validators.required]],
      },
    );

    
    if (this.route.snapshot.paramMap.get('regulationID')) {
      this.regulationID = this.route.snapshot.paramMap.get('regulationID');

      if (this.regulationID != '') {
        this.loadInfos();
      } else {
        localStorage.removeItem('regulationsInfos');
      }
    }

  };

  public onReady( editor ) {
    console.log(editor);

  }

  loadInfos() {
    this.regulationsInfo = JSON.parse(localStorage.getItem('regulationsInfos'));

    this.formGroup.patchValue({
      heading: this.regulationsInfo['heading'],
      subtitle: this.regulationsInfo['subtitle'],
      content: this.regulationsInfo['content'],
    });

    this.textDescription = this.regulationsInfo['content'];
  }

  change({ editor }: ChangeEvent) {
    const EditorData = editor.getData();
    this.formGroup.get('content').setValue(EditorData);
  }

  async submit() {
    if (this.regulationsInfo['heading'] == '') {
      this.new();
    } else {
      this.update();
    }

  }




  async new() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.regulationService.addRegulation(this.formGroup.value).subscribe(
      async (response) => {
        if (response.isCreated) {
          this.router.navigate(['./dashboard/regulations']);
          this.snackBar.open('Regulamento criado com sucesso.');
        }
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }

  update() {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.');
      return false;
    }

    this.regulationService.updateRegulation(this.formGroup.value, this.regulationsInfo['id']).subscribe(
      async (response) => {
        if (response.isUpdated) {
          this.router.navigate(['./dashboard/regulations']);
          this.snackBar.open('Regulamento atualizado com sucesso.');
        }
      },
      (error) => {
        this.snackBar.open(error.error.message);
      }
    );
  }
}
