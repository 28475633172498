import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
var FundFormComponent = /** @class */ (function () {
    function FundFormComponent(errorsService, formBuilder, fundService, snackBar, location, route, dialog) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.fundService = fundService;
        this.snackBar = snackBar;
        this.location = location;
        this.route = route;
        this.dialog = dialog;
        this.fundId = '';
        this.params = { formGroupValue: [] };
    }
    FundFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.params.formGroupValue = params.formGroupValue;
        });
        this.fundId = this.route.snapshot.paramMap.get('fundId');
        this.initForm();
    };
    FundFormComponent.prototype.initForm = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            cnpj: [null, [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
            emailAddress: [
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
                ],
            ],
            active: true,
        });
        if (this.fundId) {
            this.fundService
                .find(this.fundId)
                .then(function (fund) {
                _this.formGroup.patchValue(fund);
                _this.formGroup.get('cnpj').disable();
            })
                .catch(function (err) {
                _this.snackBar.open(err.error.message);
            });
        }
    };
    FundFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                data = {
                    name: this.formGroup.value.name,
                    cnpj: this.formGroup.value.cnpj,
                    emailAddress: this.formGroup.value.emailAddress,
                    active: Boolean(this.formGroup.value.active),
                };
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (this.fundId) {
                    this.fundService
                        .edit(this.fundId, tslib_1.__assign({}, data, { cnpj: undefined }))
                        .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.success) {
                                this.snackBar.open('Fundo editado com sucesso.');
                                this.location.back();
                            }
                            else {
                                this.snackBar.open('Falha ao editar o fundo.');
                            }
                            return [2 /*return*/];
                        });
                    }); })
                        .catch(function (err) {
                        _this.snackBar.open(err.error.message);
                    });
                }
                else {
                    this.fundService
                        .create(data)
                        .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            if (response.success) {
                                this.snackBar.open('Fundo criado com sucesso.');
                                this.location.back();
                            }
                            else {
                                this.snackBar.open('Falha ao adicionar o fundo.');
                            }
                            return [2 /*return*/];
                        });
                    }); })
                        .catch(function (err) {
                        _this.snackBar.open(err.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    FundFormComponent.prototype.excluir = function () {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover esse fundo?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.fundService.delete(this.fundId).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (response.success) {
                                    this.snackBar.open('Fundo excluido com sucesso.');
                                    this.location.back();
                                }
                                else {
                                    this.snackBar.open('Falha ao excluir o fundo.');
                                }
                                return [2 /*return*/];
                            });
                        }); })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.error.message);
        });
    };
    FundFormComponent.prototype.goBack = function () {
        this.location.back();
    };
    return FundFormComponent;
}());
export { FundFormComponent };
