import { Component, OnInit } from '@angular/core'
import { Transaction } from 'src/app/models/transaction'
import { ActivatedRoute } from '@angular/router'
import { CurrencyPipe } from '@angular/common'
import { AccountBankService } from 'src/app/services/accountBank.service'
import { bankAccount } from 'src/app/models/bankAccount'
import { MatSnackBar } from '@angular/material'

@Component({
  selector: 'app-store-list',
  templateUrl: './store-bank-account-list.component.html',
  styleUrls: ['./store-bank-account-list.component.scss'],
})
export class StoreBankAccountListComponent implements OnInit {
  public headersTable: string[] = [
    'bankCode',
    'agency',
    'accountNumber',
    'accountDigit',
    'accountType',
    'zoopBankAccountId',
    'actions',
  ]

  public accounts: bankAccount[] = []
  public storeID = ''
  public params = { storeFormGroupValue: [] }

  constructor (
    public accountBankService: AccountBankService,
    private readonly route: ActivatedRoute,
    public currencyPipe: CurrencyPipe,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit () {
    this.storeID = this.route.snapshot.paramMap.get('storeID')
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
    })
    this.listBanckAccounts()
  }

  listBanckAccounts () {
    this.accounts = []
    if (this.storeID != '') {
      // ?state=PENDING&rewardsState=FUTURE
      this.accountBankService.list(this.storeID).subscribe(
        async response => {
          this.accounts = response
        },
        error => {
          // this.snackBar.open(error.error.message);
        },
      )
    } else {
      this.accountBankService.list(this.storeID).subscribe(
        async response => {
          this.accounts = response
        },
        error => {
          // this.snackBar.open(error.error.message);
        },
      )
    }
  }

  remove (id) {
    this.accountBankService.delete(id, this.storeID).subscribe(
      async response => {
        this.listBanckAccounts()
        this.snackBar.open('Conta bancária removida com sucesso.')
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }

  convertStatus (status) {
    switch (status) {
      case 'CHECKING':
        return 'Corrente'
        break
      case 'SAVINGS':
        return 'Poupança'
        break
      default:
        return ''
    }
  }

  convertCentsToReal (value) {
    return value / 100
  }
}
