<app-logged class="home">
  <header class="home__header">
    <h2>Dashboard</h2>
  </header>
  <!-- descomenta aqui -->
  <!-- <mat-card>
    <div #chartsMonth></div>
  </mat-card>
  <br />
  <mat-card>
    <div #chartsYear></div>
  </mat-card> -->
  <!--
    <div>
    <h4>Histórico de Vendas</h4>
    <mat-grid-list cols="4" rowHeight="2:1">
      <mat-grid-tile>
        <mat-grid-tile-header>Abril</mat-grid-tile-header>
        1.455 transações
        <mat-grid-tile-footer>R$ 215.050,00</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-grid-tile-header>Maio</mat-grid-tile-header>
        3.130 transações
        <mat-grid-tile-footer>R$ 445.040,00</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-grid-tile-header>Junho</mat-grid-tile-header>
        5.455 transações
        <mat-grid-tile-footer>R$ 665.090,00</mat-grid-tile-footer>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-grid-tile-header>Julho</mat-grid-tile-header>
        2.455 transações
        <mat-grid-tile-footer>R$ 345.090,00</mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  -->
</app-logged>
