import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClosingsService = /** @class */ (function () {
    function ClosingsService(http) {
        this.http = http;
    }
    ClosingsService.prototype.create = function (params) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/cms/closings", params)
            .pipe(tap(function (_) { return _this.log('/closings/create'); }), catchError(this.handleError));
    };
    ClosingsService.prototype.delete = function (params) {
        var _this = this;
        return this.http
            .delete(environment.apiUrl + "/cms/closings/" + params)
            .pipe(tap(function (_) { return _this.log('/closings/delete'); }), catchError(this.handleError));
    };
    ClosingsService.prototype.listAll = function () {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/cms/closings")
            .pipe(tap(function (_) { return _this.log('/closings'); }), catchError(this.handleError));
    };
    ClosingsService.prototype.handleError = function (error) {
        return throwError(error);
    };
    /** Log a HeroService message with the MessageService */
    ClosingsService.prototype.log = function (message) { };
    ClosingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClosingsService_Factory() { return new ClosingsService(i0.ɵɵinject(i1.HttpClient)); }, token: ClosingsService, providedIn: "root" });
    return ClosingsService;
}());
export { ClosingsService };
