import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { NgxQRCodeModule } from 'ngx-qrcode2'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { QRCodeModule } from 'angularx-qrcode'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { DragDropModule } from '@angular/cdk/drag-drop'

import {
  MatInputModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatSnackBarModule,
  MatTableModule,
  MatCardModule,
  MatGridListModule,
  MatPaginatorModule,
  MatSelectModule,
  MatIconModule,
  MatTooltipModule,
  MatDialogModule,
  MatChipsModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatBadgeModule,
} from '@angular/material'
import { NgxPrintModule } from 'ngx-print'
import { NgxMaskModule } from 'ngx-mask'
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input'

import { YesNoPipe } from '../../core/yesNo.pipe'

import { DashboardRoutingModule } from './dashboard-routing.module'
import { StoreListComponent } from './store/store-list/store-list.component'
import { StoreFormComponent } from './store/store-form/store-form.component'
import { StoreIntFormComponent } from './store/store-int-form/store-int-form.component'
import { LoggedModule } from 'src/app/components/logged/logged.module'
import { FormFilterModule } from 'src/app/components/form-filter/form-filter.module'
import { HomeComponent } from './home/home.component'
import { StoreUserListComponent } from './store/store-user-list/store-user-list.component'
import { StoreUserFormComponent } from './store/store-user-form/store-user-form.component'
import { StoreTransactionListComponent } from './store/store-transaction-list/store-transaction-list.component'
import { StoreTransactionFormComponent } from './store/store-transaction-form/store-transaction-form.component'
import { UserFormComponent } from './users/user-form/user-form.component'
import { StoreQrcodeComponent } from './store/store-qrcode/store-qrcode.component'
import { ConfigsFormComponent } from './configs/configs-form/configs-form.component'
import { RegulationFormComponent } from './regulation/regulation-form/regulation-form.component'
import { RegulationListComponent } from './regulation/regulation-list/regulation-list.component'
import { CategoriesListComponent } from './categories/categories-list/categories-list.component'
import { CategoriesFormComponent } from './categories/categories-form/categories-form.component'
import { ClientListComponent } from './clients/client-list/client-list.component'
import { TermsListComponent } from './terms/terms-list/terms-list.component'
import { TermsFormComponent } from './terms/terms-form/terms-form.component'
import { ClientFormComponent } from './clients/client-form/client-form.component'
import { StoreBankAccountListComponent } from './store/store-bank-account-list/store-bank-account-list.component'
import { StoreBankAccountFormComponent } from './store/store-bank-account-form/store-bank-account-form.component'
import { StoreDocumentsListComponent } from './store/store-documents-list/store-documents-list.component'
import { StoreDocumentsFormComponent } from './store/store-documents-form/store-documents-form.component'
import { StoreTransactionDetailComponent } from './store/store-transaction-detail/store-transaction-detail.component'
import { ClientFormAddComponent } from './clients/client-form-add/client-form-add.component'
import { ClientFormUpdateComponent } from './clients/client-form-update/client-form-update.component'
import { ReceivablesListComponent } from './receivables/receivables-list/receivables-list.component'
import { FinancialReceivablesListComponent } from './financial-receivables/financial-receivables-list/financial-receivables-list.component'
import { PrepaymentsListComponent } from './prepayments/prepayments-list/prepayments-list.component'
import { ReportsFormComponent } from './reports/reports-form/reports-form.component'
import { StoreManualTransactionFormComponent } from './store/store-manual-transaction-form/store-manual-transaction-form'
import { StoreSellsReportComponent } from './store/store-sells-report/store-sells-report.component'
import { StoreTransactionPendingListComponent } from './store/store-transaction-pending-list/store-transaction-pending-list.component'
import { ClosingsListComponent } from './closings/closings-list/closings-list.component'
import { ClosingsTransactionsListComponent } from './closings/closings-transactions-list/closings-transactions-list.component'
import { ClosingsStoreListComponent } from './closings/closings-store-list/closings-store-list.component'

import { ManualJcoinCreditFormComponent } from './manual-jcoin-credit/request-form/request-form.component'
import { FundsListComponent } from './funds/list/funds-list.component'
import { FundFormComponent } from './funds/form/fund-form.component'
import { FundAssociatesListComponent } from './funds/associates/list/associates-list.component'
import { FundAssociatesFormComponent } from './funds/associates/form/associates-form.component'
import { FundExtractComponent } from './funds/extract/extract.component'
import { FundTransactionsComponent } from './funds/transactions/transactions.component'
import { FundPointsFormComponent } from './funds/points-form/fund-points-form.component'
import { BusinessPartnersListComponent } from './business-partners/business-partners-list/business-partners-list.component'
import { BusinessPartnersFormComponent } from './business-partners/business-partners-form/business-partners-form.component'
import { NotificationListComponent } from './notifications/list/notification-list.component'
import { NoticationFormComponent } from './notifications/form/notication-form.component'
import { QuizListComponent } from './quiz/list/quiz-list.component'
import { QuizFormComponent } from './quiz/form/quiz-form.component'
import { QuestionsListComponent } from './quiz/questions/questions-list/questions-list.component'
import { QuestionsFormComponent } from './quiz/questions/questions-form/questions-form.component'
import { TriggerComponent } from './quiz/trigger/trigger.component'
import { QuizCustomerListComponent } from './quiz/quiz-customer-list/quiz-customer-list.component'
import { AnswerCustomerListComponent } from './quiz/answer-customer-list/answer-customer-list.component'
import { QuizClientListComponent } from './quiz/quiz-client-list/quiz-client-list.component'
import { QuestionsShowImageComponent } from './quiz/questions/questions-show-image/questions-show-image.component'
import { SegmentationListComponent } from './segmentations/list/segmentation-list.component'
import { SegmentationFormComponent } from './segmentations/form/segmentation-form.component'
import { SegmentationResultsComponent } from './segmentations/results/segmentation-results.component'
import { ClientAddressListComponent } from './clients/client-address/list/client-address-list.component'
import { ClientFormAddressComponent } from './clients/client-address/form/client-form-address.component'
import { NotificationCustomersComponent } from './notifications/notification-customers/notification-customers.component'
import { StorePaymentAnticipatedTransactionFormComponent } from './store/store-payment-anticipated-transaction-form/store-payment-anticipated-transaction-form.component'
import { StorePaymentTransactionsListComponent } from './store/store-payment-transactions-list/store-payment-transactions-list.component'
import { MembershipsPlansListComponent } from './memberships/plans-list/plans-list.component'
import { MembershipPlansFormComponent } from './memberships/plans-form/plans-form.component'
import { MembershipCustomersPlanListComponent } from './memberships/plan-customers-list/plan-customers-list.component'
import { MembershipPlanTransactionsCustomerListComponent } from './memberships/plan-transactions-customer-list/plan-transactions-customer-list.component'
import { MembershipPlansListCustomerComponent } from './memberships/plans-list-customer/plans-list-customer.component'
import { ClientMovementJcoinsComponent } from './clients/client-movement-jcoins/client-movement-jcoins.component'
import { subCategoriesListComponent } from './categories/subcategories-list/subcategories-list.component'
import { subCategoriesFormComponent } from './categories/subcategories-form/subcategories-form.component'
import { AirportsListComponent } from './airports/airports-list/airports-list.component'
import { AirportsFormComponent } from './airports/airports-form/airports-form.component'
import { AirportInformationsListComponent } from './airports/information-list/airport-informations-list.component'
import { AirportInformationFormComponent } from './airports/information-form/information-form.component'
import { AirportInfoFieldsListComponent } from './airports/info-fields-list/airport-info-fields-list.component'
import { AirportInfoFieldFormComponent } from './airports/info-field-form/info-field-form.component'
import { ExclusiveListComponent } from './categories/exclusive-list/exclusive-list.component'
import { ExclusiveFormComponent } from './categories/exclusive-form/exclusive-form.component'
import { MacroGroupListComponent } from './macro-group/list/macro-group-list.component'
import { MacroGroupFormComponent } from './macro-group/form/macro-group-form.component'
import { PreRegistrationListComponent } from './pre-registration/pre-registration-list/pre-registration-list.component'
import { PreRegistrationFormComponent } from './pre-registration/pre-registration-form/pre-registration-form.component'
import { preRegistrationAddressFormComponent } from './pre-registration/pre-registration-address/form/pre-registration-address-form.component'
import { PreRegistrationAddressListComponent } from './pre-registration/pre-registration-address/list/pre-registration-address-list.component'
import { ClientKindListComponent } from './clients/client-kind/list/client-kind-list.component'
import { ClientKindFormComponent } from './clients/client-kind/form/client-kind-form.component'
import { CustomerAttendantListComponent } from './customer-attendant/list/customer-attendant-list.component'
import { CustomerAttendantFormComponent } from './customer-attendant/form/customer-attendant-form.component'
import { ClientImportComponent } from './clients/client-import/client-import.component'
import { CampaignComponent } from './campaigns/campaigns-list/campaign.component'
import { CampaignsFormComponent } from './campaigns/campaigns-form/campaigns-form.component'
import { CampaignFilterPipe } from '../../pipes/campaign-filter.pipe'
import { StoreCampaignReportComponent } from './store/store-campaign-report/store-campaign-report.component'
import { BeneficiaryListComponent } from './beneficiary/list/beneficiary.component'
import { BeneficiaryFormComponent } from './beneficiary/form/beneficiary.component'
import { IntegrationsListComponent } from './incorporations/list/integrations.component'
import { ClosingsIncorporationsComponent } from './incorporations/closings/closings-incorporations.component'
import { ClosingsIncorporationsIntegrationsComponent } from './incorporations/closings-incorporations/closings-incorporations-integrations.component'
import { NotificationTypesListComponent } from './notifications/types-list/notification-types-list.component'
import { NoticationTypesFormComponent } from './notifications/types-form/notication-types-form.component'
import { DocumentsNotIntegrationListComponent } from './incorporations/documents-not-integration/list/documents-not-integration.component'
import { DocumentsNotIntegrationFormComponent } from './incorporations/documents-not-integration/form/documents-not-integration.component'
import { AirportCompanyListComponent } from './airports/company/list/airport-company-list.component'
import { preRegisterCompaniesAirportsComponent } from './airports/company/pre-register-companies-airports/pre-register-companies-airports.component'
import { AirportCompanyFormComponent } from './airports/company/form/airport-company-form.component'
import { AirportCompanyCustomersListComponent } from './airports/company/list-customers/airport-company-customers-list.component'
import { AirportCompanyCustomerFormComponent } from './airports/company/form-customer/airport-company-customer-form.component'
import { AirportCompanyHistoricStoresComponent } from './airports/company/historic-stores/airport-company-historic-stores.component'
import { PreRegistrationHistoricStoresComponent } from './pre-registration/pre-registration-historic-stores/pre-registration-historic-stores.component'
import { ClientExportToZoopComponent } from './clients/client-export-to-zoop/client-export-to-zoop'
import { StoreExportToZoopComponent } from './store/store-export-to-zoop/store-export-to-zoop'
import { BannerFormComponent } from './banners/banner-form/banner-form.component'
import { BannerListComponent } from './banners/banner-list/banner-list.component'
import { BannerPagesAppListComponent } from './banners-pages-app/banner-pages-app-list/banner-pages-app-list.component'
import { BannerPagesAppFormComponent } from './banners-pages-app/banner-pages-app-form/banner-pages-app-form.component'
import { ArchitectCompanyFormComponent } from './architects/company/form/architect-company-form.component'
import { ArchitectCompanyListComponent } from './architects/company/list/architect-company-list.component'
import { ArchitectCompanyCustomersListComponent } from './architects/company/list-architects/architect-company-customers-list.component'
import { ArchitectsCompanyCustomerFormComponent } from './architects/company/form-architects/architect-company-customer-form.component'
import { ArchitectInvoiceFormComponent } from './architects/architect-invoice/architect-invoice-form/architect-invoice-form.component'
import { ArchitectInvoiceListComponent } from './architects/architect-invoice/architect-invoice-list/architect-invoice-list.component'
import { ArchitectStoresListComponent } from './architects/stores/list/architect-stores-list.component'
import { ArchitectStoresFormComponent } from './architects/stores/form/architect-stores-form.component'
import { ArchitectsStoreUserFormComponent } from './architects/stores/user-form/architects-store-user-form.component'
import { ArchitectStoresUserListComponent } from './architects/stores/users-list/architect-stores-user-list.component'
import { ArchitectInvoiceListAdminComponent } from './architects/architect-invoice/architect-invoice-list-admin/architect-invoice-list-admin.component'
import { ArchitectInvoiceDetailComponent } from './architects/architect-invoice/architect-invoice-detail/architect-invoice-detail.component'
import { ArchitectClosingList } from './architects/architect-closings/architect-closings-list/architect-closings-list.component'
import { ArchitectClosingDetails } from './architects/architect-closings/architect-closing-details/architect-closing-details.component'
import { ArchitectClosingForm } from './architects/architect-closings/architect-closings-form/architect-closings-form.component'
import { ArchitectClosingListForStore } from './architects/architect-closings/architect-closing-list-for-store/architect-closing-list-for-store.component'
import { ClientTermFormComponent } from './clients/client-terms/form/client-term-form.component'
import { ClientTermsListComponent } from './clients/client-terms/list/client-terms-list.component'
import { ReportsFundJcoinsComponent } from './reports/reports-fund-jcoins/reports-fund-jcoins.component'
import { ReportsStockClosingComponent } from './reports/reports-stock-closing/reports-stock-closing.component'
import { ClientExportToSalesforceComponent } from './clients/client-export-to-salesforce/client-export-to-salesforce'
import { LiquidationTransferSchedulesList } from './liquidation-transfer/liquidation-transfer-schedules-list/liquidation-transfer-schedules-list.component'
import { LiquidationTransferScheduleDetails } from './liquidation-transfer/schedule-details/schedule-details.component'
import { LiquidationTransactionsList } from './liquidation-transfer/liquidation-transactions-list/liquidation-transactions-list.component'
import { StoreInternationalTransactionFormComponent } from './store/store-international-transaction-form/store-international-transaction-form.component'
import { LiquidationTransferPermissions } from './liquidation-transfer/liquidation-transfer-permissions/liquidation-transfer-permissions.component'
import { StoreLiquidationTransfersComponent } from './liquidation-transfer/store-liquidation-transfers/store-liquidation-transfers.component'
import { LiquidationTransferSettings } from './liquidation-transfer/liquidation-transfer-settings/liquidation-transfer-settings.component'
import { DocumentsEligibleListComponent } from './airports/documents-eligible/list/documents-eligible.component'
import { DocumentsEligibleFormComponent } from './airports/documents-eligible/form/documents-eligible-form.component'
import { AirportsIntegrationsListComponent } from './airports/list-integrations/airports-integrations.component'
import { AirportsIntegrationsNotEligibleListComponent } from './airports/list-integrations-not-eligible/airports-integrations-not-eligible.component'
import { AirportsStoresTaxCashbackFormComponent } from './airports/stores-tax-cashback/form/airports-stores-tax-cashback-form.component'
import { AirportsStoresTaxCashbackListComponent } from './airports/stores-tax-cashback/list/airports-stores-tax-cashback-list.component'
import { PaymentProviderCardFeeListComponent } from './configs/payment-provider-card-fees/payment-provider-card-fee-list/payment-provider-card-fee-list.component'
import { PaymentProviderCardFeeFormComponent } from './configs/payment-provider-card-fees/payment-provider-card-fee-form/payment-provider-card-fee-form.component'
import { ConsolidatedJcoinsComponent } from './reports/reports-consolidated/reports-consolidated-jcoins.component'
import { BillPaymentNotificationsComponent } from './bill-payment-notifications/bill-payment-notifications.component'
import { TransactionsLengthPipe } from 'src/app/core/transactions-length.pipe'
import { ManualJcoinCreditListComponent } from './manual-jcoin-credit/list/list.component'
import { ClosingsAirportsComponent } from './airports/closings-airports/closings-airports.component'
import { CustomerSearchInputComponent } from 'src/app/components/customer-search-input/customer-search-input.component'

@NgModule({
  declarations: [
    HomeComponent,
    StoreListComponent,
    StoreFormComponent,
    StoreIntFormComponent,
    StoreBankAccountListComponent,
    StoreBankAccountFormComponent,
    StoreDocumentsListComponent,
    StoreDocumentsFormComponent,
    StoreQrcodeComponent,
    StoreUserListComponent,
    StoreUserFormComponent,
    StoreTransactionListComponent,
    StoreTransactionFormComponent,
    StoreInternationalTransactionFormComponent,
    StoreTransactionDetailComponent,
    StoreTransactionPendingListComponent,
    UserFormComponent,
    ConfigsFormComponent,
    RegulationFormComponent,
    RegulationListComponent,
    CategoriesListComponent,
    CategoriesFormComponent,
    ClientListComponent,
    ClientFormAddComponent,
    ClientFormUpdateComponent,
    ClientFormComponent,
    ManualJcoinCreditFormComponent,
    ManualJcoinCreditListComponent,
    TermsListComponent,
    TermsFormComponent,
    ReceivablesListComponent,
    FinancialReceivablesListComponent,
    PrepaymentsListComponent,
    ReportsFormComponent,
    StoreManualTransactionFormComponent,
    StoreSellsReportComponent,
    ClosingsListComponent,
    ClosingsTransactionsListComponent,
    ClosingsStoreListComponent,
    FundsListComponent,
    FundFormComponent,
    FundAssociatesListComponent,
    FundAssociatesFormComponent,
    FundExtractComponent,
    FundTransactionsComponent,
    FundPointsFormComponent,
    BusinessPartnersListComponent,
    BusinessPartnersFormComponent,
    NotificationListComponent,
    NoticationFormComponent,
    QuizFormComponent,
    QuizListComponent,
    QuestionsListComponent,
    QuestionsFormComponent,
    TriggerComponent,
    QuizCustomerListComponent,
    AnswerCustomerListComponent,
    QuizClientListComponent,
    QuestionsShowImageComponent,
    SegmentationListComponent,
    SegmentationFormComponent,
    SegmentationResultsComponent,
    YesNoPipe,
    ClientAddressListComponent,
    ClientFormAddressComponent,
    NotificationCustomersComponent,
    StorePaymentAnticipatedTransactionFormComponent,
    StorePaymentTransactionsListComponent,
    MembershipsPlansListComponent,
    MembershipPlansFormComponent,
    MembershipCustomersPlanListComponent,
    MembershipPlanTransactionsCustomerListComponent,
    MembershipPlansListCustomerComponent,
    ClientMovementJcoinsComponent,
    subCategoriesListComponent,
    subCategoriesFormComponent,
    AirportsListComponent,
    AirportsFormComponent,
    AirportInformationsListComponent,
    AirportInformationFormComponent,
    AirportInfoFieldsListComponent,
    AirportInfoFieldFormComponent,
    ExclusiveListComponent,
    ExclusiveFormComponent,
    MacroGroupListComponent,
    MacroGroupFormComponent,
    PreRegistrationListComponent,
    PreRegistrationFormComponent,
    preRegistrationAddressFormComponent,
    PreRegistrationAddressListComponent,
    SegmentationListComponent,
    ClientKindListComponent,
    ClientKindFormComponent,
    CustomerAttendantListComponent,
    CustomerAttendantFormComponent,
    ClientImportComponent,
    CampaignComponent,
    CampaignsFormComponent,
    CampaignFilterPipe,
    StoreCampaignReportComponent,
    BeneficiaryListComponent,
    BeneficiaryFormComponent,
    IntegrationsListComponent,
    ClosingsIncorporationsComponent,
    ClosingsIncorporationsIntegrationsComponent,
    NotificationTypesListComponent,
    NoticationTypesFormComponent,
    DocumentsNotIntegrationListComponent,
    DocumentsNotIntegrationFormComponent,
    AirportCompanyListComponent,
    preRegisterCompaniesAirportsComponent,
    AirportCompanyFormComponent,
    AirportCompanyCustomersListComponent,
    AirportCompanyCustomerFormComponent,
    AirportCompanyHistoricStoresComponent,
    PreRegistrationHistoricStoresComponent,
    ClientExportToZoopComponent,
    ClientExportToSalesforceComponent,
    StoreExportToZoopComponent,
    BannerFormComponent,
    BannerListComponent,
    BannerPagesAppListComponent,
    BannerPagesAppFormComponent,
    ArchitectCompanyFormComponent,
    ArchitectCompanyListComponent,
    ArchitectCompanyCustomersListComponent,
    ArchitectsCompanyCustomerFormComponent,
    ArchitectInvoiceFormComponent,
    ArchitectInvoiceListComponent,
    ArchitectStoresListComponent,
    ArchitectStoresFormComponent,
    ArchitectsStoreUserFormComponent,
    ArchitectStoresUserListComponent,
    ArchitectInvoiceListAdminComponent,
    ArchitectInvoiceDetailComponent,
    ArchitectClosingList,
    ArchitectClosingDetails,
    ArchitectClosingForm,
    ArchitectClosingListForStore,
    ClientTermFormComponent,
    ClientTermsListComponent,
    ReportsFundJcoinsComponent,
    ReportsStockClosingComponent,
    LiquidationTransferSchedulesList,
    BillPaymentNotificationsComponent,
    LiquidationTransferScheduleDetails,
    LiquidationTransactionsList,
    LiquidationTransferPermissions,
    StoreLiquidationTransfersComponent,
    LiquidationTransferSettings,
    DocumentsEligibleListComponent,
    DocumentsEligibleFormComponent,
    AirportsIntegrationsListComponent,
    AirportsIntegrationsNotEligibleListComponent,
    AirportsStoresTaxCashbackFormComponent,
    AirportsStoresTaxCashbackListComponent,
    PaymentProviderCardFeeListComponent,
    PaymentProviderCardFeeFormComponent,
    ConsolidatedJcoinsComponent,
    TransactionsLengthPipe,
    ClosingsAirportsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    NgxPrintModule,
    MatTooltipModule,
    MatChipsModule,
    MatRadioModule,
    LoggedModule,
    FormFilterModule,
    DashboardRoutingModule,
    NgxQRCodeModule,
    QRCodeModule,
    CKEditorModule,
    CurrencyMaskModule,
    DragDropModule,
    MatButtonToggleModule,
    MatButtonModule,
    NgxMatIntlTelInputModule,
    NgxMaskModule.forRoot(),
    MatBadgeModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    TransactionsLengthPipe
  ]
})
export class DashboardModule { }
