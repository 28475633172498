import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { StoreService } from 'src/app/services/store.service'
import { Router, ActivatedRoute } from '@angular/router'
import { TransactionService } from 'src/app/services/transaction.service'
import { Store } from 'src/app/models/store'
import { MatSnackBar } from '@angular/material'
import { DecimalPipe, CurrencyPipe } from '@angular/common'

@Component({
  selector: 'app-store-form',
  templateUrl: './store-transaction-form.component.html',
  styleUrls: ['./store-transaction-form.component.scss'],
})
export class StoreTransactionFormComponent implements OnInit {
  public userRole: any
  public formGroup: FormGroup
  public states: Object[] = []
  public plans: Object[] = []
  public storeID: any
  public store: Store
  public installments: any[] = [1]
  public isValidTotalValue = false
  public isValidTotalValueMsg = ''
  public headersTable: string[] = ['minimumTransactionValue', 'minimumInstallmentValue']
  public transactionsInfo: any[] = []
  public isCardPayment: boolean
  public subStores: any[] = []
  public params = { formGroupValue: [], previousRoute: '', storeID: '', itComesFromNewTransaction: false, storeFormGroupValue: [] }

  constructor (
    public errorsService: ErrorsService,
    private readonly zipCodeService: ZipCodeService,
    private readonly formBuilder: FormBuilder,
    public storeService: StoreService,
    public transactionService: TransactionService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public currencyPipe: CurrencyPipe,
  ) {
    this.userRole = localStorage.getItem('userRole')
  }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.storeFormGroupValue = params.storeFormGroupValue
      this.params.formGroupValue = params.formGroupValue
      this.params.previousRoute = params.previousRoute
      this.params.storeID = params.storeID
      this.storeID = params.storeID
    })
    this.initForm()
  }

  initForm () {
    if (!this.storeID) {
      this.storeID = this.route.snapshot.paramMap.get('storeID')
      this.params.storeID = this.storeID
    }

    this.formGroup = this.formBuilder.group({
      customerCpf: [
        null,
        [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
      ],
      totalValue: [null, [Validators.required]],
      description: [null, [Validators.required]],
      maximumInstallments: [1, [Validators.required]],
      isMembershipActive: [null, [Validators.required]],
      isPayActive: [null, [Validators.required]],
      subStoreId: [null, []],
    })

    if (this.storeID != '' && this.storeID != null) {
      const storeSelected = JSON.parse(localStorage.getItem('storeSelected'))
      if (storeSelected && this.userRole != 'ADMIN') {
        this.storeID = storeSelected.storeId
      }

      this.storeService.getStore(this.storeID).subscribe(async response => {
        for (const st of response.subStoreAndMacroGroup) {
          if (st.subStore.length) {
            this.subStores.push(st.subStore[0])
          }
        }
        if (this.subStores.length) {
          this.subStores.sort((a, b) => {
            return a.subStoreName < b.subStoreName ? -1 : a.subStoreName > b.subStoreName ? 1 : 0
          })
        }
        this.store = response
        this.isCardPayment = this.store.isCardPayment
        this.formGroup = this.formBuilder.group({
          customerCpf: [
            null,
            [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
          ],
          totalValue: [null, [Validators.required]],
          description: [null, [Validators.required]],
          maximumInstallments: this.isCardPayment ? [null, [Validators.required]] : [null, []],
          isMembershipActive: [this.store.isMembershipActive, [Validators.required]],
          isPayActive: [this.store.isPayActive, [Validators.required]],
          subStoreId: [null, []],
        })

        this.transactionsInfo = [
          {
            minimumTransactionValue: this.store.minimumTransactionValue / 100,
            minimumInstallmentValue: this.store.minimumInstallmentValue / 100,
          },
        ]
      })
    }
  }

  changeValueTotal (event) {
    this.isValidTotalValue = false
    if (this.formGroup.value.totalValue * 100 >= this.store.minimumTransactionValue) {
      this.isValidTotalValueMsg = `O valor mínimo da Transação é de R$ ${this.store.minimumTransactionValue}`
      this.isValidTotalValue = true
      this.calcTotalInstallments()
    }
  }

  calcTotalInstallments () {
    this.installments = [1]
    for (let index = 2; index <= this.store.maximumInstallmentsPerTransaction; index++) {
      if ((this.formGroup.value.totalValue * 100) / index >= this.store.minimumInstallmentValue) {
        this.installments.push(index)
      }
    }
  }

  convertCentsToMoney (value) {
    const money = this.currencyPipe.transform(value, 'BRL')
    // this.formGroup.controls['totalValue'].setValue(money);
    console.log(money)
  }

  convertNumberToBoolean (num) {
    if (num == 0) {
      return false
    } else {
      return true
    }
  }

  async submit () {
    if (this.formGroup.value.totalValue * 100 < this.store.minimumTransactionValue) {
      this.formGroup.controls.totalValue.setErrors({ transactionNotMatch: true })
    } else {
      this.formGroup.controls.totalValue.setErrors(null)
    }

    if (!this.formGroup.valid) {
      // this.snackBar.open(error.error.message);
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const totalValue = this.formGroup.value.totalValue.toFixed(2).replace('.', '')
    const data = {
      customerCpf: this.formGroup.value.customerCpf,
      totalValue: parseInt(totalValue),
      description: this.formGroup.value.description,
      maximumInstallments: this.formGroup.value.maximumInstallments
        ? parseInt(this.formGroup.value.maximumInstallments)
        : 1,
      isMembershipActive: this.formGroup.value.isMembershipActive,
      isPayActive: this.formGroup.value.isPayActive,
      storeId: this.storeID,
      subStoreId: this.formGroup.value.subStoreId,
    }
    this.transactionService.addTransaction(data).subscribe(
      async response => {
        if (response.isCreated) {
          this.snackBar.open('Transação criada com sucesso.')
          this.params = { ...this.params, itComesFromNewTransaction: true }
          this.router.navigate(['./dashboard/transactions/detail/' + response.id], { queryParams: this.params, skipLocationChange: true })
        }
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }
}
