import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuestionsService = /** @class */ (function () {
    function QuestionsService(http) {
        this.http = http;
    }
    QuestionsService.prototype.list = function (id, filter) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/quizzes/" + id + "/questions" + (filter || ''))
                        .toPromise()];
            });
        });
    };
    QuestionsService.prototype.findOne = function (id, quizID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get(environment.apiUrl + "/cms/quizzes/" + id + "/questions/" + quizID)
                        .toPromise()];
            });
        });
    };
    QuestionsService.prototype.create = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(environment.apiUrl + "/cms/quizzes/" + id + "/questions", data)
                        .toPromise()];
            });
        });
    };
    QuestionsService.prototype.update = function (id, questiosID, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put(environment.apiUrl + "/cms/quizzes/" + id + "/questions/" + questiosID, data)
                        .toPromise()];
            });
        });
    };
    QuestionsService.prototype.changeOrder = function (id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch(environment.apiUrl + "/cms/quizzes/" + id + "/questions/change-order", data)
                        .toPromise()];
            });
        });
    };
    QuestionsService.prototype.delete = function (quizID, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(environment.apiUrl + "/cms/quizzes/" + quizID + "/questions/" + id)
                        .toPromise()];
            });
        });
    };
    QuestionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionsService_Factory() { return new QuestionsService(i0.ɵɵinject(i1.HttpClient)); }, token: QuestionsService, providedIn: "root" });
    return QuestionsService;
}());
export { QuestionsService };
