import { FormFilterComponent } from './form-filter.component'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CurrencyMaskModule } from 'ng2-currency-mask'

import {
  MatInputModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatSnackBarModule,
  MatTableModule,
  MatCardModule,
  MatPaginatorModule,
  MatSelectModule,
  MatDialogModule,
} from '@angular/material'
import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatCardModule,
    MatDialogModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [FormFilterComponent],
  exports: [FormFilterComponent],
})
export class FormFilterModule {}
