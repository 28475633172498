import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material'
import { FormGroup } from '@angular/forms'
import { AirportsService } from '../../../../services/airports.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { ReportService } from '../../../../services/report.service'
import JsFileDownloader from 'js-file-downloader'
import * as moment from 'moment'
import numeral from 'numeral'
import { ModalFormIntegrationsComponent } from 'src/app/components/modal-form-integrations/modal-form-integrations.component'

@Component({
  selector: 'app-airports-integrations-not-eligible-list',
  templateUrl: './airports-integrations-not-eligible.component.html',
  styleUrls: ['./airports-integrations-not-eligible.component.scss'],
})
export class AirportsIntegrationsNotEligibleListComponent implements OnInit {
  public headersTable: string[] = ['action', 'prefix', 'document', 'cnpj', 'nameCli', 'number', 'numberOs', 'amountReceived', 'taxAmountJcoins', 'taxJcoins', 'state', 'dateCreatedAt']
  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  public formGroup: FormGroup
  public params: Params

  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    public airportsService: AirportsService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.list().catch(err => { console.log(err) })

    const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'documentAirportIntegrationNotEligible',
        label: 'CPF/CNPJ',
        placeholder: 'CPF/CNPJ',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjOriginAirportIntegrationNotEligible',
        label: 'CNPJ ORIGEM',
        placeholder: 'CNPJ ORIGEM',
      },
      {
        field: 'input',
        type: 'text',
        formControlName: 'prefixAirportIntegrationNotEligible',
        label: 'Prefixo',
        placeholder: 'Prefixo',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Inicio',
        formControlName: 'dateAirportInitIntegrationNotEligible',
        valueDefault: firstDayOfMonth,
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Integração Fim',
        formControlName: 'dateAirportEndIntegrationNotEligible',
        valueDefault: lastDayOfMonth,
      },
    ]
  }

  async list () {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    const { results, total } = await this.airportsService
      .integrationsNotEligible(`?filters={"page": ${this.page}, "pageSize":${this.pageSize},"filters":[{"fields":["airports_integration.createdAt"], "op": ">=", "value":"${moment(firstDay).format('YYYY-MM-DD')} 00:00:00"}, {"fields":["airports_integration.createdAt"], "op": "<=", "value":"${moment(lastDay).format('YYYY-MM-DD')} 23:59:59"}]}`)
      .catch(err => {
        this.snackBar.open(err.message)
        return { results: [], total: 0 }
      })

    this.dataSource = new MatTableDataSource(results)
    this.pageTotal = total
    this.resultsAll = results
  }

  addMask (document) {
    const apenasNumeros = document.replace(/\D/g, '')
    if (apenasNumeros.length === 11) {
      return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else if (apenasNumeros.length > 11) {
      return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
    return apenasNumeros
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  convertState (state) {
    if (state === 'COMPUTED') return 'COMPUTADO'
    if (state === 'FELIED') return 'FALHA'
  }

  receiverFeedback (returnFilter) {
    this.returnedFilters = returnFilter
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
    this.resultsAll = returnFilter.results
    this.formGroup = returnFilter.formGroup.value
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalFormIntegrationsComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(filters => {
      if (filters) {
        this.report(filters);
      }
    });
  }

  async report(filters: { startDate: string, endDate: string, status: string }) {
    try {
      const formatDate = (dateStr: string) => {
        const day = dateStr.substring(0, 2);
        const month = dateStr.substring(2, 4);
        const year = dateStr.substring(4, 8);
        return `${day}/${month}/${year}`;
      };
  
      const formattedStartDate = formatDate(filters.startDate);
      const formattedEndDate = formatDate(filters.endDate);
  
      const fileUrl = await this.airportsService.integrationsNotEligibleExport(filters);
  
      if (!fileUrl || fileUrl.trim() === '') {
        throw new Error('URL do arquivo não encontrada.');
      }
  
      console.log("File URL:", fileUrl);
  
      new JsFileDownloader({
        url: fileUrl.trim(),
        nameCallback: () => {
          return `Integrações Não Elegíveis - ${formattedStartDate} à ${formattedEndDate}.xlsx`;
        },
      });
  
    } catch (error) {
      console.error('Erro ao baixar o relatório:', error);
    }
  }   

  sendJcoins (id, taxAmountJcoins) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: `Deseja creditar JCoins - Valor: ${taxAmountJcoins}`,
          buttonConfirmText: 'creditar',
          buttonNot: 'não',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.airportsService.documentsNotEligibleaddJcoins(
            { airportIntegrationId: id },
          ).then(
            async response => {
              this.list().catch(err => { console.log(err) })
              this.snackBar.open('JCoins creditado com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
