import { Component, OnInit } from '@angular/core'
import { ReceivablesService } from 'src/app/services/receivables.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material'
import { ErrorsService } from 'src/app/core/services/errors.service'
import { PrepaymentsService } from 'src/app/services/prepayments.service'
import { StoreService } from 'src/app/services/store.service'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import JsFileDownloader from 'js-file-downloader'

type Receivable = {
  name: string
  document: string
  total: number
  status: string
  cardValue: number
  coinValue: number
  cardDiscount: number
  coinDiscount: number
  date: string
  installment: number
}

@Component({
  selector: 'app-store-list',
  templateUrl: './financial-receivables-list.component.html',
  styleUrls: ['./financial-receivables-list.component.scss'],
})
export class FinancialReceivablesListComponent implements OnInit {
  public readonly isAdmin = localStorage.getItem('userRole') === 'ADMIN'

  public stores: any[] = []

  public headersTable: string[] = ['name', 'document', 'total', 'status', 'cardValue', 'coinValue', 'cardDiscount', 'coinDiscount', 'date', 'installment']
  public receivables: Receivable[] = []

  public formFilter: FormGroup
  public storeOptions: Observable<any[]>
  public selectedStore = ''

  constructor (
    public receivablesService: ReceivablesService,
    public prepaymentsService: PrepaymentsService,
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public errorsService: ErrorsService,
    public storeService: StoreService,
  ) { }

  ngOnInit () {
    this.formFilter = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      finalDate: ['', [Validators.required]],
      status: [''],
      storeName: ['', [Validators.required]],
    })

    if (this.isAdmin) {
      this.loadStores()

      this.storeOptions = this.formFilter.controls.storeName.valueChanges.pipe(
        startWith(''),
        map(value => {
          if (value === '') {
            this.selectedStore = ''
          }

          return this.stores.filter(store => {
            const newValue = typeof value === 'object' ? value.nomeFantasia : value
            return (
              store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
              store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
              store.cnpj.toLowerCase().includes(newValue.toLowerCase())
            )
          })
        }),
      )
    } else {
      this.selectedStore = JSON.parse(localStorage.getItem('storeSelected')).storeId
      this.headersTable.splice(0, 2)
    }
  }

  listAll (fromDate: string, toDate: string, storeId: string, status?: 'AGENDADO' | 'LIQUIDADO') {
    if (!storeId) {
      return this.snackBar.open('Insira uma lojá para os recebíveis')
    }

    this.receivables = []

    this.receivablesService.listAllFromRoadpass({ status, fromDate, toDate, storeId }).subscribe(
      (response) => {
        this.receivables = response || []
      },
      (error) => {
        console.log(error)
        alert(error.error.message)
      },
    )
  }

  async submitFilter () {
    if (!this.formFilter.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const startDate = new Date(this.formFilter.value.startDate)
    const finalDate = new Date(this.formFilter.value.finalDate)

    const diffInDays =
      Math.ceil(Math.abs(finalDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1

    if (diffInDays > 30) {
      this.snackBar.open('O filtro deve ter no máximo 30  dias.')
      return false
    }

    this.listAll(
      this.formFilter.value.startDate,
      this.formFilter.value.finalDate,
      this.selectedStore,
      this.formFilter.value.status,
    )
  }

  convertCentsToReal (value: number) {
    return value / 100
  }

  handleStoreSelected (event: any) {
    this.selectedStore = event.option.value.id
    this.formFilter.patchValue({ storeName: event.option.value.nomeFantasia })
  }

  loadStores () {
    this.storeService.listStores().subscribe(
      response => {
        this.stores = response
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  async report () {
    if (!this.formFilter.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')

      return false
    }

    const filters = {
      fromDate: this.formFilter.value.startDate,
      toDate: this.formFilter.value.finalDate,
      storeId: this.selectedStore,
      status: this.formFilter.value.status,
    }

    await this.receivablesService.createReport(filters).then(async response => {
      return new JsFileDownloader({
        url: response.fileUrl,
        nameCallback: () => {
          return `Relatório de Recebíveis Financeiro. ${response.fileUrl.split('.').splice(5, 5).join('.')}`
        },
      })
    })
  }
}
