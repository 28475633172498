<app-logged class="store-list">
  <header class="store-list__header">
    <h2>Categorias</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/categories/new">
        ADICIONAR
      </button>
    </div>
  </header>
  <div *ngIf="titleCategory" class="table-responsive">
    <mat-card class="card-category">
      <div>
        <b>Título:</b>
        <div [innerHTML]="titleCategory"></div>
      </div>
    </mat-card>
  </div>
  <div class="table-responsive">
    <table mat-table [dataSource]="categories">
      <ng-container matColumnDef="heading">
        <th mat-header-cell *matHeaderCellDef>Título</th>
        <td mat-cell *matCellDef="let element">{{ element.heading }}</td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef>Ordem</th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon matTooltip="Ativo" *ngIf="element.status === 1">check_circle_outline</mat-icon>
          <mat-icon matTooltip="Inativo" *ngIf="element.status === 0">cancel</mat-icon>
          <p *ngIf="element.status === 'Unificado'">
            <strong>{{ element.status }}</strong>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" width="100">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="element.isBannerExclusive"
            mat-icon-button
            matTooltip="Icones Exclusivos"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.id }}/exclusives"
          >
            <mat-icon>art_track</mat-icon>
          </button>
          <button
            *ngIf="element.hasSubItem"
            mat-icon-button
            matTooltip="Sub Categorias"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.id }}/subcategories"
          >
            <mat-icon>list</mat-icon>
          </button>

          <button
            *ngIf="!element.categoriesIdJoin01 && !element.categoriesIdJoin02"
            mat-icon-button
            matTooltip="Parceiros"
            matTooltipPosition="left"
            routerLink="/dashboard/categories/{{ element.id }}/business-partners"
          >
            <mat-icon>store</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Banner"
            matTooltipPosition="left"
            routerLink="/dashboard/banners/category/{{ element.id }}"
          >
            <mat-icon>image</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Editar"
            matTooltipPosition="left"
            (click)="viewCategories(element)"
            routerLink="/dashboard/categories/{{ element.id }}"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remover"
            matTooltipPosition="left"
            (click)="remove(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
