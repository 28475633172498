import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BusinessUnitsService = /** @class */ (function () {
    function BusinessUnitsService(http) {
        this.http = http;
    }
    BusinessUnitsService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/business-units").toPromise()];
            });
        });
    };
    BusinessUnitsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessUnitsService_Factory() { return new BusinessUnitsService(i0.ɵɵinject(i1.HttpClient)); }, token: BusinessUnitsService, providedIn: "root" });
    return BusinessUnitsService;
}());
export { BusinessUnitsService };
