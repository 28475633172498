import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
interface DialogData {
  results: any[]
}

@Component({
  selector: 'app-modal-store-bank-account-changed',
  templateUrl: './modal-store-bank-account-changed.component.html',
  styleUrls: ['./modal-store-bank-account-changed.component.scss'],
})
export class ModalStoreBankAccountChangedComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<ModalStoreBankAccountChangedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar) {
  }

  cancel (): void {
    this.dialogRef.close('cancel')
  }

  confirm () {
    this.dialogRef.close('confirm')
  }

  ngOnInit () {
  }
}
