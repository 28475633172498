import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorsService } from 'src/app/core/services/errors.service';

interface DialogData {
  monthYear: string;
  status: string;
}

@Component({
  selector: 'app-modal-form-export-consolidated-jcoins',
  templateUrl: './modal-form-export-consolidated-jcoins.component.html',
  styleUrls: ['./modal-form-export-consolidated-jcoins.component.scss']
})
export class ModalFormExportConsolidatedJcoinsComponent implements OnInit {

  public filterForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalFormExportConsolidatedJcoinsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public errorsService: ErrorsService,
  ) { }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      monthYear: [null, [Validators.required, this.validMonthYear]],
    });
  }

  validMonthYear(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null;

    const regex = /^\d{6}$/;
    if (!regex.test(value)) {
      return { invalidFormat: true };
    }

    const month = parseInt(value.slice(0, 2), 10);
    const year = parseInt(value.slice(2), 10);

    if (month < 1 || month > 12) {
      return { invalidMonth: true };
    }
    if (year < 1900 || year > new Date().getFullYear()) {
      return { invalidYear: true };
    }

    return null;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    const monthYearValue = this.filterForm.value.monthYear;

    if (!this.filterForm.valid) {
      console.log("Erros no formulário:", this.filterForm.controls.monthYear.errors);
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }

    const filters = {
      monthYear: monthYearValue,
    };

    this.dialogRef.close(filters);
  }
}
