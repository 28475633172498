import { Component, OnInit } from '@angular/core'
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material'
import numeral from 'numeral'
import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { MembershipService } from '../../../../services/membership.service'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { StoreService } from '../../../../services/store.service'

@Component({
  selector: 'app-membership-plans-list-customer',
  templateUrl: './plans-list-customer.component.html',
  styleUrls: ['./plans-list-customer.component.scss'],
})
export class MembershipPlansListCustomerComponent implements OnInit {
  public headersTable = ['name', 'beginDate', 'endDate', 'value', 'active', 'actions']

  customerId: string
  dataSource: any
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
  fieldsForm: any
  cpf: string
  fullName: string
  email: string
  planId: string
  public pageTotal: number
  public params = { formGroupValue: [] }

  constructor (
    public snackBar: MatSnackBar,
    public membershipService: MembershipService,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    public storeService: StoreService,
    public router: Router,
  ) { }

  ngOnInit () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.customerId = this.route.snapshot.paramMap.get('customerID')

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'namePlanMembershipCustomer',
        label: 'Plano Membership',
        placeholder: 'Plano Membership',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Início',
        formControlName: 'beginDatePlanMembershipCustomer',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Data Final',
        formControlName: 'endDatePlanMembershipCustomer',
      },
    ]

    this.listPlans()

    this.storeService
      .listUsersCustomers(
        `?filters={"filters":[{"fields":["customers.id"], "op": "=", "value":"${this.customerId}"}]}`,
      )
      .toPromise()
      .then(
        async response => {
          const customer = response.items.find(() => true)
          this.cpf = customer.cpf
          this.fullName = `${customer.firstName} ${customer.lastName}`
          this.email = customer.emailAddress
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  listPlans () {
    this.membershipService
      .listPlansByCustomer(
        this.customerId,
        `?filters={"page": ${this.page}, "pageSize":${this.pageSize}}`,
      )
      .then(
        async response => {
          this.pageTotal = response.total
          this.dataSource = new MatTableDataSource(response.results)
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
  }

  goBack () {
    this.location.back()
  }

  convertCentsToReal (value) {
    return numeral(value).divide(100).value()
  }

  month (recurrencePeriod) {
    if (recurrencePeriod == 0) {
      return ''
    } else if (recurrencePeriod > 1) {
      return `${recurrencePeriod} meses`
    } else {
      return `${recurrencePeriod} mês`
    }
  }

  infoPlan (planId) {
    this.router.navigateByUrl(`/dashboard/memberships/plans/${planId}`)
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja inativar esse Plano Membership?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          const data = {
            isActive: false,
          }
          await this.membershipService.editPlan(id, data).then(
            async response => {
              this.listPlans()
              this.snackBar.open('Plano Membership inativo.')
            },
            error => {
              this.snackBar.open(error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  reciverFeedback (returnFilter) {
    this.pageTotal = returnFilter.total
    this.dataSource = new MatTableDataSource(returnFilter.results)
  }
}
