import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, } from '@angular/material';
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component';
import { ModalFormNotificationQuizComponent } from '../../../../components/modal-form-notification-quiz/modal-form-notification-quiz.component';
var QuizListComponent = /** @class */ (function () {
    function QuizListComponent(router, route, snackBar, dialog, quizService) {
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.quizService = quizService;
        this.customerID = '';
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    QuizListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.formGroupValue) {
                _this.formGroup = JSON.parse(params.formGroupValue);
                _this.initForm();
            }
            else {
                _this.initForm();
                _this.quizList();
            }
        });
    };
    QuizListComponent.prototype.initForm = function () {
        this.headersTable = ['name', 'amountQuestions', 'actions'];
        this.fieldsForm = [
            {
                field: 'input',
                type: 'text',
                formControlName: 'nameQuiz',
                label: 'Questionário',
                placeholder: 'Questionário',
            },
        ];
    };
    QuizListComponent.prototype.quizList = function () {
        var _this = this;
        this.quizService.list("?filters={\"page\": " + this.page + ", \"pageSize\":" + this.pageSize + "}").then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.pageTotal = response.total;
                localStorage.setItem('quiz', JSON.stringify(response.results));
                this.dataSource = new MatTableDataSource(response.results);
                return [2 /*return*/];
            });
        }); }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuizListComponent.prototype.sendNotification = function (id, name) {
        this.dialog.open(ModalFormNotificationQuizComponent, {
            data: {
                id: id,
                name: name,
            },
        });
    };
    QuizListComponent.prototype.remove = function (id) {
        var _this = this;
        var text;
        var idTrigger;
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.quizService.getTrigger().then(function (response) {
                            var idt = response.map(function (c) { return c.quizId; });
                            idTrigger = idt[0];
                        }, function (error) {
                            _this.snackBar.open(error.error.message);
                        })];
                    case 1:
                        _a.sent();
                        if (idTrigger === id) {
                            text = 'Este questionário está vinculado no Gatilho, deseja remover ?';
                        }
                        else {
                            text = 'Deseja remover esse Questionário ?';
                        }
                        this.dialog
                            .open(ModalConfirmComponent, {
                            width: '500px',
                            data: {
                                text: text,
                                buttonConfirmText: 'Remover',
                            },
                        })
                            .afterClosed()
                            .toPromise()
                            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                if (result && result !== 'cancel') {
                                    return [2 /*return*/, this.quizService.delete(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                this.quizList();
                                                this.snackBar.open('Questionário removido com sucesso.');
                                                return [2 /*return*/];
                                            });
                                        }); }, function (error) {
                                            _this.snackBar.open(error.error.message);
                                        })];
                                }
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                        });
                        return [2 /*return*/];
                }
            });
        }); })().catch(function (error) { return _this.snackBar.open(error.error.message); });
    };
    QuizListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.pageTotal = returnFilter.total;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    return QuizListComponent;
}());
export { QuizListComponent };
