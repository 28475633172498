import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { MatTableDataSource, } from '@angular/material';
import * as XLSX from 'xlsx';
import { cnpj } from 'cpf-cnpj-validator';
var ClientImportComponent = /** @class */ (function () {
    function ClientImportComponent(route, dialog, snackBar, authService, router) {
        this.route = route;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.authService = authService;
        this.router = router;
        this.headersTable = [
            'name',
            'cpf',
            'cnpj',
            'jcoins',
        ];
        this.headersTablePreRegister = [
            'cnpj',
            'name',
            'cnpjOrigem',
            'jcoins',
        ];
        this.hideFile = true;
        this.hideFilePreRegister = true;
    }
    ClientImportComponent.prototype.ngOnInit = function () { };
    ClientImportComponent.prototype.onFileChange = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workBook, jsonData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.hideFilePreRegister = false;
                workBook = null;
                jsonData = null;
                this.reader = new FileReader();
                this.file = ev.target.files[0];
                if (this.file) {
                    this.reader.onload = function (event) {
                        var data = _this.reader.result;
                        workBook = XLSX.read(data, { type: 'binary' });
                        jsonData = workBook.SheetNames.reduce(function (initial, name) {
                            var sheet = workBook.Sheets[name];
                            initial = XLSX.utils.sheet_to_json(sheet);
                            return initial;
                        }, {});
                        _this.dataString = jsonData;
                        _this.dataSource = new MatTableDataSource(_this.dataString);
                    };
                    this.reader.readAsBinaryString(this.file);
                }
                return [2 /*return*/];
            });
        });
    };
    ClientImportComponent.prototype.saveFile = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.authService.importCustomers({ customers: this.dataString }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.import === true) {
                            this.snackBar.open('Clientes cadastrados com sucesso.');
                            this.clear().catch(function (err) { return console.log(err); });
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    ClientImportComponent.prototype.clear = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSource = null;
                this.dataString = null;
                this.InputVar.nativeElement.value = '';
                this.hideFilePreRegister = true;
                return [2 /*return*/];
            });
        });
    };
    ClientImportComponent.prototype.clearPreRegister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.dataSourcePreRegister = null;
                this.dataStringPreRegister = null;
                this.InputVarPreRegister.nativeElement.value = '';
                this.hideFile = true;
                this.cnpjError = false;
                return [2 /*return*/];
            });
        });
    };
    ClientImportComponent.prototype.onFileChangePreRegister = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workBook, jsonData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.hideFile = false;
                workBook = null;
                jsonData = null;
                this.cnpjError = false;
                this.readerPreRegister = new FileReader();
                this.filePreRegister = ev.target.files[0];
                if (this.filePreRegister) {
                    this.readerPreRegister.onload = function (event) {
                        var e_1, _a;
                        var data = _this.readerPreRegister.result;
                        workBook = XLSX.read(data, { type: 'binary' });
                        jsonData = workBook.SheetNames.reduce(function (initial, name) {
                            var sheet = workBook.Sheets[name];
                            initial = XLSX.utils.sheet_to_json(sheet);
                            return initial;
                        }, {});
                        _this.dataStringPreRegister = jsonData;
                        try {
                            for (var jsonData_1 = tslib_1.__values(jsonData), jsonData_1_1 = jsonData_1.next(); !jsonData_1_1.done; jsonData_1_1 = jsonData_1.next()) {
                                var d = jsonData_1_1.value;
                                if (!cnpj.isValid(d.CNPJ)) {
                                    d.cnpjError = true;
                                    _this.cnpjError = true;
                                }
                                else {
                                    d.cnpjError = false;
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (jsonData_1_1 && !jsonData_1_1.done && (_a = jsonData_1.return)) _a.call(jsonData_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _this.dataSourcePreRegister = new MatTableDataSource(_this.dataStringPreRegister);
                    };
                    this.readerPreRegister.readAsBinaryString(this.filePreRegister);
                }
                return [2 /*return*/];
            });
        });
    };
    ClientImportComponent.prototype.saveFilePreRegister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.cnpjError) {
                    this.snackBar.open('O CNPJ em vermelho está inválido');
                    return [2 /*return*/, false];
                }
                this.authService.importCustomersPreRegister({ companies: this.dataStringPreRegister }).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (response.import === true) {
                            this.snackBar.open('Clientes CNPJ cadastrados com sucesso.');
                            this.clearPreRegister().catch(function (err) { return console.log(err); });
                        }
                        return [2 /*return*/];
                    });
                }); }, function (error) {
                    _this.snackBar.open(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return ClientImportComponent;
}());
export { ClientImportComponent };
