<main class="modal-customer-info">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <h2>Parcelas Pagas</h2>

      <table mat-table [dataSource]="data.installments">
        <ng-container matColumnDef="installmentNumber">
          <th mat-header-cell *matHeaderCellDef>Parcela</th>
          <td mat-cell *matCellDef="let element">
            {{ element.installmentNumber }}/{{ data.totalInstallments }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>JCoins</th>
          <td mat-cell *matCellDef="let element">
            {{ element.amount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="installmentValue">
          <th mat-header-cell *matHeaderCellDef>Valor pago</th>
          <td mat-cell *matCellDef="let element">
            {{ (element.installmentValue / 100) | currency: 'R$' }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tableHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: tableHeader"></tr>
      </table>
    </mat-card>
  </div>
</main>
