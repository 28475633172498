import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import moment from 'moment';
import 'moment/locale/pt-br';
import numeral from 'numeral';
import 'numeral/locales/pt-br';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
moment.locale('pt-br');
numeral.locale('pt-br');
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
