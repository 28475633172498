import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BannersPagesAppService = /** @class */ (function () {
    function BannersPagesAppService(http) {
        this.http = http;
    }
    BannersPagesAppService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/app-pages").toPromise()];
            });
        });
    };
    BannersPagesAppService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/app-page", body).toPromise()];
            });
        });
    };
    BannersPagesAppService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/app-page/" + id, body).toPromise()];
            });
        });
    };
    BannersPagesAppService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/app-page/" + id).toPromise()];
            });
        });
    };
    BannersPagesAppService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/app-page/" + id).toPromise()];
            });
        });
    };
    BannersPagesAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BannersPagesAppService_Factory() { return new BannersPagesAppService(i0.ɵɵinject(i1.HttpClient)); }, token: BannersPagesAppService, providedIn: "root" });
    return BannersPagesAppService;
}());
export { BannersPagesAppService };
