import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { enumManualJcoinCredit } from 'src/app/models/enumManualJcoinCredit';
import { ManualJcoinCreditService } from 'src/app/services/manual-jcoin-credit.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { enumCmsPermissions } from 'src/app/models/enumCmsPermissions';

interface DialogData {
  manualJcoinCredit: object
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-review-form.component.html',
  styleUrls: ['./modal-review-form.component.scss']
})
export class ModalReviewFormComponent implements OnInit {

  public formGroup: FormGroup;
  public manualPayment: any;
  public cmsPermissions = enumCmsPermissions;

  constructor(
    public dialogRef: MatDialogRef<ModalReviewFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private readonly service: ManualJcoinCreditService,
    private formBuilder: FormBuilder,
    public permissionsService: PermissionsService,
    public snackBar: MatSnackBar) {
    this.manualPayment = dialogData.manualJcoinCredit;
    console.info(this.manualPayment);
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (this.formGroup.invalid) {
      console.info(this.formGroup);
      this.snackBar.open("Preencha corretamente os campos e tente novamente.", '', {
        duration: 3000,
      });
      return false;
    }

    const formData = {
      reviewReason: this.formGroup.get('reviewReason').value,
      isApproved: this.formGroup.get('isApproved').value,
    }

    this.service.review(this.manualPayment.id, formData).then(() => {
      this.dialogRef.close('success');
      this.service.updatePendingRequestsCount()
    })

    return true;
  }

  isPending() {
    return this.manualPayment.status === enumManualJcoinCredit.PENDING;
  }

  getStatusColor(status: string): string {
    switch (status) {
      case enumManualJcoinCredit.APPROVED:
        return 'green';
      case enumManualJcoinCredit.DENIED:
        return 'red';
      case enumManualJcoinCredit.PENDING:
      default:
        return 'grey';
    }
  }

  getStatusName(status: string): string {
    switch (status) {
      case enumManualJcoinCredit.APPROVED:
        return 'APROVADO';
      case enumManualJcoinCredit.DENIED:
        return 'REPROVADO';
      case enumManualJcoinCredit.PENDING:
        return 'PENDENTE';
      default:
        return 'DESCONHECIDO';
    }
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        reviewReason: [null, []],
        isApproved: [null, [
          Validators.required,
        ]],
      });
  }

  getOriginName(name) {
    switch (name) {
      case 'JHSFID':
        return 'JHSF ID';
      case 'ARQUITETO':
        return 'ARQUITETO';
      case 'JHSFID_ARCHITECT':
        return 'JHSF ID + ARQUITETO';
      default:
        return '';
    }
  }

  getStoreObject(element) {
    if (element.store) return element.store
    if (element.storeBusinessUnit) return element.storeBusinessUnit
  }
}

