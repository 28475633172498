import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-modal-customer-cpf',
  templateUrl: './modal-customer-cpf.component.html',
  styleUrls: ['./modal-customer-cpf.component.scss'],
})
export class ModalCustomerCpfComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<ModalCustomerCpfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customerCpf: string }, // public snackBar: MatSnackBar,
  ) {}

  ngOnInit () {}

  cancel (): void {
    this.dialogRef.close('cancel')
  }
}
