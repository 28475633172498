import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalChooseCampaignTypeComponent = /** @class */ (function () {
    function ModalChooseCampaignTypeComponent(formBuilder, dialogRef, data) {
        this.formBuilder = formBuilder;
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ModalChooseCampaignTypeComponent.prototype.ngOnInit = function () {
        this.formGroup = this.formBuilder.group({
            campaignId: [null, []],
            defaultCampaignId: [null, [Validators.required]],
        });
    };
    ModalChooseCampaignTypeComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalChooseCampaignTypeComponent.prototype.submit = function () {
        if (!this.formGroup.valid) {
            return false;
        }
        this.dialogRef.close({
            campaignId: this.formGroup.value.campaignId,
            defaultCampaignId: this.formGroup.value.defaultCampaignId,
        });
    };
    return ModalChooseCampaignTypeComponent;
}());
export { ModalChooseCampaignTypeComponent };
