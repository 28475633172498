import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import JsFileDownloader from 'js-file-downloader'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { CompaniesService } from '../../../../../services/companies.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

@Component({
  selector: 'app-architect-company-form',
  templateUrl: './architect-company-form.component.html',
  styleUrls: ['./architect-company-form.component.scss'],
})
export class ArchitectCompanyFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  company: any
  public states: Object[] = []
  public documentUrl: any
  public uploadData: FormData
  public approveDoc: string
  public commercialPhoneInvalid = false
  public phoneNumberInvalid = false
  public socialContractInvalid = false
  public otherSegment = false

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zipCodeService: ZipCodeService,
  ) {
    this.states = [
      {
        abbr: 'AC',
        name: 'Acre',
      },
      {
        abbr: 'AL',
        name: 'Alagoas',
      },
      {
        name: 'Amapá',
        abbr: 'AP',
      },
      {
        name: 'Amazonas',
        abbr: 'AM',
      },
      {
        name: 'Bahia',
        abbr: 'BA',
      },
      {
        name: 'Ceará',
        abbr: 'CE',
      },
      {
        name: 'Distrito Federal',
        abbr: 'DF',
      },
      {
        name: 'Espírito Santo',
        abbr: 'ES',
      },
      {
        name: 'Goiás',
        abbr: 'GO',
      },
      {
        name: 'Maranhão',
        abbr: 'MA',
      },
      {
        name: 'Mato Grosso',
        abbr: 'MT',
      },
      {
        name: 'Mato Grosso do Sul',
        abbr: 'MS',
      },
      {
        name: 'Minas Gerais',
        abbr: 'MG',
      },
      {
        name: 'Pará',
        abbr: 'PA',
      },
      {
        name: 'Paraíba',
        abbr: 'PB',
      },
      {
        name: 'Paraná',
        abbr: 'PR',
      },
      {
        name: 'Pernambuco',
        abbr: 'PE',
      },
      {
        name: 'Piauí',
        abbr: 'PI',
      },
      {
        name: 'Rio de Janeiro',
        abbr: 'RJ',
      },
      {
        name: 'Rio Grande do Norte',
        abbr: 'RN',
      },
      {
        name: 'Rio Grande do Sul',
        abbr: 'RS',
      },
      {
        name: 'Rondônia',
        abbr: 'RO',
      },
      {
        name: 'Roraima',
        abbr: 'RR',
      },
      {
        name: 'Santa Catarina',
        abbr: 'SC',
      },
      {
        name: 'São Paulo',
        abbr: 'SP',
      },
      {
        name: 'Sergipe',
        abbr: 'SE',
      },
      {
        name: 'Tocantins',
        abbr: 'T',
      },
    ]
  }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.formGroup = this.formBuilder.group({
      cnpj: [null, [Validators.required]],
      // businessName: [null, []],
      companyName: [null, [Validators.required]],
      responsibleName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      emailAddress: [null, [Validators.required]],
      zipCode: [null, [Validators.required]],
      street: [null, [Validators.required]],
      addressNumber: [null, [Validators.required]],
      complement: [null, []],
      neighborhood: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      operatingTime: [null, [Validators.required]],
      commercialPhone: [null, [Validators.required]],
      segment: [null, [Validators.required]],
      otherSegment: [null, []],
      storeReferralName: [{ value: null, disabled: true }, []],
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async findOne () {
    await this.companiesService.findOne(this.id)
      .then(async response => {
        this.company = response
        this.formGroup.patchValue({
          cnpj: response.cnpj,
          companyName: response.companyName,
          responsibleName: response.responsibleName,
          phoneNumber: response.phoneNumber,
          emailAddress: response.emailAddress,
          zipCode: response.zipCode,
          street: response.street,
          addressNumber: response.addressNumber,
          complement: response.complement,
          neighborhood: response.neighborhood,
          city: response.city,
          state: response.state,
          operatingTime: response.operatingTime,
          commercialPhone: response.commercialPhone,
          segment: response.segment,
          otherSegment: response.otherSegment,
          storeReferralName: response.storeReferralName,
        })

        this.otherSegment = response.segment === 'OTHERS'
        this.documentUrl = response.socialContractUrl
        this.approveDoc = response.socialContractName
        if (response.cnpj) {
          this.formGroup.get('cnpj').disable()
        }
      })
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.approveDoc = undefined
  }

  download (event) {
    event.preventDefault()
    // eslint-disable-next-line no-new
    new JsFileDownloader({
      url: this.documentUrl,
      nameCallback: () => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return this.approveDoc
      },
    })
  }

  async selectFile (event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = () => {
        this.documentUrl = reader.result
      }

      this.uploadData = new FormData()
      this.uploadData.append('file', event.target.files[0], event.target.files[0].name)
      this.approveDoc = event.target.files[0].name
      this.socialContractInvalid = false
    } else {
      this.removeDocument()
    }
  }

  async loadAddress () {
    const zipCode = this.formGroup.controls.zipCode.value

    if (zipCode.length === 8) {
      this.zipCodeService.getAddress(zipCode).subscribe((address: any) => {
        this.formGroup.patchValue({
          state: address.uf,
          city: address.localidade,
          neighborhood: address.bairro,
          street: address.logradouro,
        })
      })
    }
  }

  onChange () {
    this.commercialPhoneInvalid = !this.formGroup.value.commercialPhone
  }

  onChangePhone () {
    this.phoneNumberInvalid = !this.formGroup.value.phoneNumber
  }

  others (e: any) {
    if (e === 'OTHERS') {
      this.otherSegment = true
    } else {
      this.otherSegment = false
    }
  }

  async submit () {
    this.commercialPhoneInvalid = !this.formGroup.value.commercialPhone
    this.phoneNumberInvalid = !this.formGroup.value.phoneNumber
    this.socialContractInvalid = !this.documentUrl
    if (!this.formGroup.valid && this.commercialPhoneInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      cnpj: this.formGroup.value.cnpj,
      companyName: this.formGroup.value.companyName,
      responsibleName: this.formGroup.value.responsibleName,
      phoneNumber: this.formGroup.value.phoneNumber,
      emailAddress: this.formGroup.value.emailAddress,
      zipCode: this.formGroup.value.zipCode,
      street: this.formGroup.value.street,
      addressNumber: this.formGroup.value.addressNumber,
      complement: this.formGroup.value.complement || undefined,
      neighborhood: this.formGroup.value.neighborhood,
      city: this.formGroup.value.city,
      state: this.formGroup.value.state,
      socialContract: this.documentUrl ? this.documentUrl : undefined,
      socialContractName: this.approveDoc,
      operatingTime: this.formGroup.value.operatingTime,
      commercialPhone: this.formGroup.value.commercialPhone,
      segment: this.formGroup.value.segment,
      otherSegment: this.formGroup.value.otherSegment || undefined,
    }

    if (this.id) {
      this.companiesService.update(this.id, data).then(
        async response => {
          if (response.company) {
            await this.router.navigate(['./dashboard/architect-companies'])
            this.snackBar.open('Empresa atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      this.companiesService.create(data).then(
        async response => {
          if (response.company) {
            await this.router.navigate(['./dashboard/architect-companies'])
            this.snackBar.open('Empresa cadastrada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
