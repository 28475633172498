import { NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, loaderService) {
        var _this = this;
        this.router = router;
        this.loaderService = loaderService;
        this.title = 'jhsf-cms';
        this.loaderService.isLoading.next(true);
        router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; }), take(1))
            .subscribe(function (e) {
            _this.loaderService.isLoading.next(false);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
    };
    AppComponent.prototype.ngOnDestroy = function () {
    };
    return AppComponent;
}());
export { AppComponent };
