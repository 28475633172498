<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="filterForm" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <div class="col-12 col-sm-12">
            
            <!-- Campo Data Início Integração -->
            <mat-card-content>
              <mat-form-field class="full-width">
                <mat-label>Data Início Integração</mat-label>
                <input type="date" matInput formControlName="startDate" />
                <mat-error *ngIf="filterForm.controls.startDate?.invalid">
                  {{ errorsService.messageErrorFor(filterForm.controls.startDate) }}
                </mat-error>
              </mat-form-field>
            </mat-card-content>
            
            <!-- Campo Data Final Integração -->
            <mat-card-content>
              <mat-form-field class="full-width">
                <input type="date" matInput formControlName="endDate" />
                <mat-error *ngIf="filterForm.controls.endDate?.invalid">
                  {{ errorsService.messageErrorFor(filterForm.controls.endDate) }}
                </mat-error>
              </mat-form-field>
            </mat-card-content>
            

            <!-- Campo Status -->
            <mat-card-content>
              <mat-form-field class="full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option value="COMPUTED">APROVADO</mat-option>
                  <mat-option value="FAILED">FALHA</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card-content>

          </div>
        </div>
      </mat-card>
    </div>

    <!-- Ações do modal -->
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="submit" class="btn-submit-dark">Aplicar Filtros</button>
      </div>
    </div>
  </form>
</main>
