<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div style="overflow-y: hidden; height: calc(105vh - 15rem)">
    <div style="overflow-y: auto; height: 100%">
      <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div mat-dialog-content class="content">
          <mat-card>
            <div class="row">
              <h3>Enviar questionário como Notificação</h3>
              <div class="col-12 col-sm-12">
                <b>Questionário: </b>{{ data.name }}
                <div class="check">
                  <input formControlName="sendPush" type="checkbox" />
                  <mat-label> &nbsp;Enviar push</mat-label>
                </div>
                <div class="check">
                  <input
                    formControlName="sendForAllCustomers"
                    type="checkbox"
                    (change)="checkCustomers($event)"
                  />
                  <mat-label> &nbsp;Notificar todos os usuario</mat-label>
                </div>
                <mat-form-field *ngIf="!checkUser" class="customer-autocomplete">
                  <mat-label>CPF ou Nome do Cliente</mat-label>
                  <mat-chip-list #chipList>
                    <mat-chip
                      *ngFor="let customer of selectedCustomers"
                      [selectable]="false"
                      [removable]="true"
                      (removed)="removeCustomer(customer)"
                    >
                      {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      matInput
                      #customerInput
                      [formControl]="customerControl"
                      formControlName="customersInput"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    />
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option
                      *ngFor="let customer of filteredCustomers | async"
                      [value]="customer"
                    >
                      {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div class="check">
                  <input
                    formControlName="useSegmentation"
                    type="checkbox"
                    (change)="checkSegmentation($event)"
                  />
                  <mat-label> &nbsp;Usar segmentação</mat-label>
                </div>

                <mat-form-field *ngIf="checkUseSegmentation" class="customer-autocomplete">
                  <mat-label>Segmentação</mat-label>
                  <mat-chip-list #chipListSegmentation>
                    <mat-chip
                      *ngFor="let segmentation of selectedSegmentation"
                      [selectable]="false"
                      [removable]="true"
                      (removed)="removeSegmentation(segmentation)"
                    >
                      {{ segmentation.name }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      matInput
                      #segmentationInput
                      [formControl]="segmentationControl"
                      formControlName="segmentationsInput"
                      [matAutocomplete]="autoSegmentation"
                      [matChipInputFor]="chipListSegmentation"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    />
                  </mat-chip-list>
                  <mat-autocomplete
                    #autoSegmentation="matAutocomplete"
                    (optionSelected)="selected($event)"
                  >
                    <mat-option
                      *ngFor="let segmentation of filteredSegmentation | async"
                      [value]="segmentation"
                    >
                      {{ segmentation.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error *ngIf="segmentationInvalid">Campo deve ser preenchido</mat-error>
              </div>
            </div>
          </mat-card>
        </div>
        <div mat-dialog-actions>
          <div class="actions">
            <button mat-button class="btn-submit-dark">ENVIAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
