import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerAttendantService = /** @class */ (function () {
    function CustomerAttendantService(http) {
        this.http = http;
    }
    CustomerAttendantService.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/attendant").toPromise()];
            });
        });
    };
    CustomerAttendantService.prototype.create = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.apiUrl + "/cms/attendant", body).toPromise()];
            });
        });
    };
    CustomerAttendantService.prototype.update = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put(environment.apiUrl + "/cms/attendant/" + id, body).toPromise()];
            });
        });
    };
    CustomerAttendantService.prototype.findOne = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/attendant/" + id).toPromise()];
            });
        });
    };
    CustomerAttendantService.prototype.delete = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete(environment.apiUrl + "/cms/attendant/" + id).toPromise()];
            });
        });
    };
    CustomerAttendantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerAttendantService_Factory() { return new CustomerAttendantService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomerAttendantService, providedIn: "root" });
    return CustomerAttendantService;
}());
export { CustomerAttendantService };
