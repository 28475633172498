<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <div mat-dialog-content>
    <mat-card>
      <div class="row">
        <h4>Deseja aprovar essa transação manual?</h4>
      </div>
    </mat-card>
  </div>
  <div mat-dialog-actions>
    <div class="btn-submit-dark">
      <button mat-button (click)="confirm()" class="btn-submit-dark">Sim</button>
    </div>
  </div>
</main>
