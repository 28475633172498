import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
interface DialogData {
  store: string;
  points: string;
  value: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal-confirmation-points.component.html',
  styleUrls: ['./modal-confirmation-points.component.scss']
})
export class ModalConfirmationPointsComponent implements OnInit {

  public formGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmationPointsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar) {
      console.log('data', data);
     }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close(1);
  }

  ngOnInit() {
    
  }
}

