import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
// import { BusinessPartner } from '../models/businessPartner'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public loading

  constructor (private readonly http: HttpClient) { }

  async list (filter?): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/events${filter || ''}`).toPromise()
  }

  async findOne (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/events/${id}`).toPromise()
  }

  async create (data): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (
        !Object.prototype.hasOwnProperty.call(data, key) ||
        data[key] === undefined ||
        data[key] === null
      ) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .post<any>(`${environment.apiUrl}/cms/events`, formData, { headers })
      .toPromise()
  }

  async update (data, id: string) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    for (const key in data) {
      if (
        !Object.prototype.hasOwnProperty.call(data, key) ||
        data[key] === undefined ||
        data[key] === null
      ) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http
      .put<any>(`${environment.apiUrl}/cms/events/${id}`, formData, { headers })
      .toPromise()
  }

  async delete (id: string): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/events/${id}`).toPromise()
  }

  async getS3Url (): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/events/s3-slot`).toPromise()
  }

  async uploadToS3 (pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }

  async notificationCustomersList (id, filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/events/${id}/customers/${filter || ''}`)
      .toPromise()
  }

  async listTypes (filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/event/types-segmentation${filter || ''}`)
      .toPromise()
  }

  async createType (body): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/event/type-segmentation`, body).toPromise()
  }

  async updateType (id: string, body): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/event/type-segmentation/${id}`, body).toPromise()
  }

  async findOneType (id: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/event/types-segmentation/${id}`).toPromise()
  }

  async deleteType (id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cms/event/type-segmentation/${id}`).toPromise()
  }
}
