import { Component, ElementRef, OnInit, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { COMMA, ENTER } from '@angular/cdk/keycodes'

import { ReportService } from '../../services/report.service'
import { NotificationService } from '../../services/notification.service'
import { SegmentationService } from '../../services/segmentation.service'

@Component({
  selector: 'app-modal-form-notification-quiz',
  templateUrl: './modal-form-notification-quiz.component.html',
  styleUrls: ['./modal-form-notification-quiz.component.scss'],
})
export class ModalFormNotificationQuizComponent implements OnInit {
  public formGroup: FormGroup
  public customerControl = new FormControl()
  public segmentationControl = new FormControl()
  public selectedCustomers: any[] = []
  public selectedSegmentation: any[] = []
  public checkUser = false
  public checkUseSegmentation = false
  public segmentationInvalid = false
  filteredCustomers: Observable<string[]>
  filteredSegmentation: Observable<string[]>
  customers: any[] = []
  segmentation: any[] = []

  separatorKeysCodes: number[] = [ENTER, COMMA]

  @ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>

  @ViewChild('segmentationInput', { static: false }) segmentationInput: ElementRef<HTMLInputElement>
  @ViewChild('autoSegmentation', { static: false }) matAutocompleteSegmentation: MatAutocomplete

  constructor(
    private readonly formBuilder: FormBuilder,
    public reportService: ReportService,
    public notificationService: NotificationService,
    public segmentationService: SegmentationService,
    public dialogRef: MatDialogRef<ModalFormNotificationQuizComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; name: string },
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      sendPush: [null, [Validators.required]],
      sendForAllCustomers: [null, []],
      customersInput: [null, [Validators.required]],
      segmentationsInput: [null, []],
      useSegmentation: [null, []],
    })
    this.reportService.listCustomers().subscribe(
      response => {
        response.map(item => {
          this.customers.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.segmentationService.list().then(
      response => {
        response.results.map(item => {
          this.segmentation.push(item)
        })
      },
      error => {
        console.log(error)
      },
    )

    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(''),
      map((customer: any) => (customer ? this._filter(customer) : this.customers.slice(0, 20))),
    )

    this.filteredSegmentation = this.segmentationControl.valueChanges.pipe(
      startWith(''),
      map((segmentation: any) =>
        segmentation ? this._filterSegmentation(segmentation) : this.segmentation.slice(0, 20),
      ),
    )
  }

  cancel(): void {
    this.dialogRef.close('cancel')
  }

  checkCustomers(event) {
    if (this.formGroup.value.sendForAllCustomers) {
      this.selectedCustomers = []
      this.checkUser = true
    } else {
      this.checkUser = false
    }
  }

  checkSegmentation(event) {
    if (this.formGroup.value.useSegmentation) {
      this.checkUseSegmentation = true
    } else {
      this.checkUseSegmentation = false
      this.segmentationInvalid = false
    }
  }

  private _filter(customer: string): string[] {
    const remove = String(customer).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.customers
      .filter(c => !this.selectedCustomers.includes(c))
      .filter(c =>
        `${String(c.cpf)} ${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()),
      )
      .slice(0, 20)
  }

  private _filterSegmentation(segmentation: string): string[] {
    const remove = String(segmentation).replace(/[.-]/g, '')

    if (typeof remove !== 'string') {
      return
    }
    return this.segmentation
      .filter(c => !this.selectedSegmentation.includes(c))
      .filter(c => `${String(c.name)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  removeCustomer(customer: any): void {
    const index = this.selectedCustomers.indexOf(customer)
    if (index >= 0) {
      this.selectedCustomers.splice(index, 1)
    }
  }

  removeSegmentation(segmentation: any): void {
    const index = this.selectedSegmentation.indexOf(segmentation)
    if (index >= 0) {
      this.selectedSegmentation.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.id) {
      this.selectedSegmentation.push(event.option.value)
      this.segmentationInput.nativeElement.value = ''
      this.segmentationControl.setValue(null)
      this.segmentationInvalid = false
    } else {
      this.selectedCustomers.push(event.option.value)
      this.customerInput.nativeElement.value = ''
      this.customerControl.setValue(null)
    }
  }

  submit() {
    !this.selectedSegmentation.length && this.checkUseSegmentation
      ? (this.segmentationInvalid = true)
      : (this.segmentationInvalid = false)

    if (this.segmentationInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      title: this.data.name,
      description: this.data.name,
      content: '',
      sendPush: this.formGroup.value.sendPush,
      customers:
        !this.formGroup.value.sendForAllCustomers && !this.selectedCustomers.length
          ? undefined
          : JSON.stringify(
              this.formGroup.value.sendForAllCustomers
                ? []
                : this.selectedCustomers.map(c => c.customerId),
            ),
      redirectUrl: `jhsfid://quizzes/${this.data.id}`,
      segmentations: JSON.stringify(this.selectedSegmentation.map(c => c.id)),
    }
    this.notificationService.create(data).then(
      async response => {
        this.cancel()
        this.snackBar.open('Notificação cadastrada com sucesso.')
      },
      error => {
        this.snackBar.open(error.error.message)
      },
    )
  }
}
