import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { startWith, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { AirportsService } from '../../../../../services/airports.service'

@Component({
  selector: 'app-airport-company-form',
  templateUrl: './airport-company-form.component.html',
  styleUrls: ['./airport-company-form.component.scss'],
})
export class AirportCompanyFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  preRegisterId: any
  company: any

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public airportsService: AirportsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')
    this.preRegisterId = this.route.snapshot.paramMap.get('preRegisterId')

    this.formGroup = this.formBuilder.group({
      name: [null, [Validators.required]],
      cnpj: [null, [Validators.required]],
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
    if (this.preRegisterId) {
      this.findOnePreRegister().catch(err => console.log(err))
    }
  }

  async findOne () {
    await this.airportsService.fideOneCompanyAirport(this.id)
      .then(async response => {
        this.company = response
        this.formGroup.patchValue({
          name: response.name,
          cnpj: response.cnpj,
        })
        this.formGroup.get('cnpj').disable()
      })
  }

  async findOnePreRegister () {
    await this.airportsService.fideOneCompanyAirportPreRegister(this.preRegisterId)
      .then(async response => {
        this.company = response
        this.formGroup.patchValue({
          name: response.name,
          cnpj: response.cnpj,
        })
        this.formGroup.get('cnpj').disable()
      })
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      name: this.formGroup.value.name,
      cnpj: this.formGroup.value.cnpj,
    }

    if (this.preRegisterId) {
      this.airportsService.updatePreRegisterAirportCompany(this.preRegisterId, data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airport-companies'])
            this.snackBar.open('Pre cadastro atualizado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }

    if (this.id) {
      this.airportsService.updateAirportCompany(this.id, data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airport-companies'])
            this.snackBar.open('Empresa Aeroporto atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else if (!this.preRegisterId) {
      this.airportsService.createPreRegisterAirportCompany(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airport-companies'])
            this.snackBar.open('Empresa Aeroporto cadastrado com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
