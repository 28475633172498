import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var MacroGroupFormComponent = /** @class */ (function () {
    function MacroGroupFormComponent(errorsService, formBuilder, macroGroupsService, snackBar, router, route) {
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.macroGroupsService = macroGroupsService;
        this.snackBar = snackBar;
        this.router = router;
        this.route = route;
    }
    MacroGroupFormComponent.prototype.ngOnInit = function () {
        this.macroGroupID = this.route.snapshot.paramMap.get('macroGroupID');
        this.initForm();
    };
    MacroGroupFormComponent.prototype.initForm = function () {
        this.formGroup = this.formBuilder.group({
            macroName: [null, [Validators.required]],
        });
        if (this.macroGroupID) {
            this.loadInfos().catch(function (err) { return console.log(err); });
        }
    };
    MacroGroupFormComponent.prototype.loadInfos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.macroGroupsService.findOne(this.macroGroupID).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.formGroup.patchValue({
                                    macroName: response.macroName,
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MacroGroupFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                data = {
                    macroName: this.formGroup.value.macroName,
                };
                if (this.macroGroupID) {
                    this.macroGroupsService.update(this.macroGroupID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isUpdated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/macro-group'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação atualizada com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                else {
                    this.macroGroupsService.create(data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.isCreated) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.router.navigate(['./dashboard/macro-group'])];
                                case 1:
                                    _a.sent();
                                    this.snackBar.open('Informação criado com sucesso.');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (error) {
                        _this.snackBar.open(error.error.message);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return MacroGroupFormComponent;
}());
export { MacroGroupFormComponent };
