<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div mat-dialog-content>
      <mat-card>
        <div class="row" *ngIf="data?.transaction?.type === 'LIVELO'" >
          <h4><strong>Atenção!</strong>
            Ao cancelar a transação, apenas os jcoins serão estornados; 
            os pontos Livelo não serão cancelados. 
            Para efetuar o cancelamento, entre em contato com o atendimento da Livelo.
          </h4>
        </div>
        <div class="row" *ngIf="showLiquidationMessage" >
          <h4><strong>Atenção!</strong>
            Existe(m) TED(s) pendente(s) para liquidação que inclui os valores dessa transação.<br>
            Ao cancelá-la, o agendamento das TEDs pendentes serão cancelados e novas TEDs serão agendadas com os valores atualizados, sem o valor dessa transação.<br>
          </h4>
        </div>
        <div class="row">
          <h4>Por favor, qual é o motivo do cancelamento?</h4>
          <div class="col-12 col-sm-12">
            <mat-card-content>
              <mat-form-field>
                <mat-label>Motivo do cancelamento</mat-label>
                <input matInput formControlName="reason" />
              </mat-form-field>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="submit" class="btn-submit-dark">Cancelar Transação</button>
      </div>
    </div>
  </form>
</main>
