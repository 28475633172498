import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TermService {

  public loading;

  constructor(private http: HttpClient) { }

  list(filter?): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/terms`)
      .pipe(
        tap(_ => this.log('cms/terms?page=0&range=10')),
        catchError(this.handleError)
      )
  }

  add(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/terms`, data)
      .pipe(
        tap(_ => this.log('cms/terms')),
        catchError(this.handleError)
      )
  }

  update(data, termID): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/terms/${termID}`, data)
      .pipe(
        tap(_ => this.log('cms/terms')),
        catchError(this.handleError)
      )
  }

  delete(termID): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/terms/${termID}`)
      .pipe(
        tap(_ => this.log('cms/terms')),
        catchError(this.handleError)
      )
  }

  private handleError(error: any) { 
    return throwError(error);
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
  }

  
}
