<main class="modal">
  <a
    class="link-cancel"
    mat-icon-button
    matTooltip="Cancelar"
    (click)="cancel()"
    matTooltipPosition="left"
  >
    <mat-icon>close</mat-icon>
  </a>
  
    <div mat-dialog-content>
      <mat-card>
        <div class="row">
          <h4>
            Atenção!
          </h4>
          <div class="col-12 col-sm-12">
            Foi realizado o cancelamento da transação e estornado os jcoins no JHSF ID, agora o cancelamento da transação na RoadPass precisa ser feito direto na plataforma deles
          </div>
        </div>
      </mat-card>
    </div>
    <div mat-dialog-actions>
      <div class="login__actions">
        <button mat-button type="button" (click)="cancel()" class="btn-submit-dark">Ok</button>
      </div>
    </div>
  
</main>
