import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModalConfirmComponent } from '../../../../../components/modal-confirm/modal-confirm.component';
import JsFileDownloader from 'js-file-downloader';
var preRegisterCompaniesAirportsComponent = /** @class */ (function () {
    function preRegisterCompaniesAirportsComponent(airportsService, snackBar, location, dialog, reportService) {
        this.airportsService = airportsService;
        this.snackBar = snackBar;
        this.location = location;
        this.dialog = dialog;
        this.reportService = reportService;
        // public headersTable: string[] = ['cnpj', 'name', 'balance', 'actions']
        this.headersTable = ['cnpj', 'name', 'balance'];
        this.airport = [];
    }
    preRegisterCompaniesAirportsComponent.prototype.ngOnInit = function () {
        this.listAirports();
    };
    preRegisterCompaniesAirportsComponent.prototype.listAirports = function () {
        var _this = this;
        this.airportsService.preRegisterCompaniesAirport().then(function (response) {
            _this.airport = response.results;
        }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    preRegisterCompaniesAirportsComponent.prototype.remove = function (id) {
        var _this = this;
        this.dialog
            .open(ModalConfirmComponent, {
            width: '500px',
            data: {
                text: 'Deseja remover essa Aeroporto?',
                buttonConfirmText: 'Remover',
            },
        })
            .afterClosed()
            .toPromise()
            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (result && result !== 'cancel') {
                    return [2 /*return*/, this.airportsService.deleteAirport(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.listAirports();
                                this.snackBar.open('Aeroporto removida com sucesso.');
                                return [2 /*return*/];
                            });
                        }); }, function (error) {
                            console.log(error);
                            _this.snackBar.open(error.error.message);
                        })];
                }
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            _this.snackBar.open(err.message);
        });
    };
    preRegisterCompaniesAirportsComponent.prototype.report = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.reportService.preRegistrationCompaniesAirports().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                // eslint-disable-next-line no-new
                                new JsFileDownloader({
                                    url: response.fileUrl,
                                    nameCallback: function () {
                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                        return 'Relatório de Pré Cadastro CNPJ.' + response.fileUrl.split('.').splice(5, 5).join('.');
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return preRegisterCompaniesAirportsComponent;
}());
export { preRegisterCompaniesAirportsComponent };
