import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Segmentation } from '../models/segmentation'
import { environment } from '../../environments/environment'
import { FormGroup } from '@angular/forms'

@Injectable({ providedIn: 'root' })
export class SegmentationService {
  constructor (private readonly http: HttpClient) { }

  async list (filter?): Promise<{ formGroup: FormGroup, results: Segmentation[], total: number }> {
    return this.http
      .get<{ formGroup: FormGroup, results: Segmentation[], total: number }>(
        `${environment.apiUrl}/cms/segmentations${filter || ''}`,
    )
      .toPromise()
  }

  async listContexts (): Promise<Array<{ name: string, context: string }>> {
    return this.http
      .get<Array<{ name: string, context: string }>>(
        `${environment.apiUrl}/cms/segmentations/contexts`,
    )
      .toPromise()
  }

  async listResult (filters: any, page: number, pageSize: number, limit: number): Promise<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/cms/segmentations/results?filters=${encodeURI(
          JSON.stringify(filters),
        )}&page=${String(page)}&pageSize=${String(pageSize)}&limit=${limit || ''}`,
    )
      .toPromise()
  }

  async find (id: string): Promise<Segmentation> {
    return this.http.get<Segmentation>(`${environment.apiUrl}/cms/segmentations/${id}`).toPromise()
  }

  async reprocess (id: string): Promise<{ success: boolean, id: string }> {
    return this.http
      .post<{ success: boolean, id: string }>(`${environment.apiUrl}/cms/segmentations/${id}`, {})
      .toPromise()
  }

  async reprocessSegmentationAll (segmentationAll): Promise<any[]> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/cms/segmentations/reprocess-segmentation-all`,
        segmentationAll,
    )
      .toPromise()
  }

  async create (segmentation: Partial<Segmentation>): Promise<{ success: boolean, id: string }> {
    return this.http
      .post<{ success: boolean, id: string }>(
        `${environment.apiUrl}/cms/segmentations`,
        segmentation,
    )
      .toPromise()
  }

  async edit (
    id: string,
    segmentation: Partial<Segmentation>,
  ): Promise<{ success: boolean, id: string }> {
    return this.http
      .put<{ success: boolean, id: string }>(
        `${environment.apiUrl}/cms/segmentations/${id}`,
        segmentation,
    )
      .toPromise()
  }

  async delete (id: string): Promise<{ success: boolean, id: string }> {
    return this.http
      .delete<{ success: boolean, id: string }>(`${environment.apiUrl}/cms/segmentations/${id}`)
      .toPromise()
  }

  async listCustomers (id: string, filter?): Promise<{ results: any[], total: number }> {
    return this.http
      .get<{ results: any[], total: number }>(
        `${environment.apiUrl}/cms/segmentations/${id}/customers${filter || ''}`,
    )
      .toPromise()
  }

  async addField (
    segmentationId: string,
    fields: { name: string, type: string, value: string, direction: string },
  ): Promise<{ success: boolean }> {
    return this.http
      .post<{ success: boolean }>(
        `${environment.apiUrl}/cms/segmentations/${segmentationId}/fields`,
        fields,
    )
      .toPromise()
  }

  async deleteField (segmentationId: string, fieldId: string): Promise<{ success: boolean }> {
    return this.http
      .delete<{ success: boolean }>(
        `${environment.apiUrl}/cms/segmentations/${segmentationId}/fields/${fieldId}`,
    )
      .toPromise()
  }

  async listCustomersMacroGroup
  (filters: any, page: number, pageSize: number, limit: number):
  Promise<{ results: Segmentation[], total: number }> {
    return this.http
      .get<{ results: Segmentation[], total: number }>(
        `${environment.apiUrl}/cms/segmentations/customers/macro-group?filters=${encodeURI(
          JSON.stringify(filters),
        )}&page=${String(page)}&pageSize=${String(pageSize)}&limit=${limit || ''}`,
    )
      .toPromise()
  }
}
