<app-logged class="store-form">
  <header class="store-form__header">
    <h2>Cadastro de Cliente</h2>
    <div class="store-form__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        routerLink="/dashboard/airport-companies"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="company" style="margin-bottom: 1rem">
    <mat-card>
      <div><b>NOME: </b>{{ company.name }}</div>
      <div><b>CNPJ: </b>{{ company.cnpj | mask: '00.000.000/0000-00' }}</div>
    </mat-card>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-content>
            <div>
              <h2>Cliente 1</h2>
              <mat-form-field
                *ngIf="!showFirstName1"
                class="customer-autocomplete"
                style="padding: 3px"
              >
                <mat-label *ngIf="hideEdit1">Nome</mat-label>
                <mat-chip-list #chipList1>
                  <mat-chip
                    *ngFor="let customer of selectedCustomers1"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeCustomer(customer, 1)"
                  >
                    {{ customer.name }} - {{ customer.cpf | mask: '000.000.000-00' }}
                    <mat-icon matChipRemove *ngIf="hideEdit1">cancel</mat-icon>
                  </mat-chip>
                  <input
                    *ngIf="hideEdit1"
                    matInput
                    #customerInput1
                    [formControl]="customerControl1"
                    formControlName="firstName1"
                    [matAutocomplete]="auto1"
                    [matChipInputFor]="chipList1"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (blur)="valueInputCpf($event, 1)"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selected($event, 1)">
                  <mat-option
                    *ngFor="let customer of filteredCustomers1 | async"
                    [value]="customer"
                  >
                    {{ customer.name }} - {{ customer.cpf | mask: '000.000.000-00' }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field *ngIf="showFirstName1">
                <input matInput formControlName="firstName1" placeholder="Sobrenome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer1">
                <input matInput formControlName="lastName1" placeholder="Sobrenome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer1">
                <input matInput formControlName="cpf1" placeholder="CPF" mask="000.000.000-00" />
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  formControlName="percentage1"
                  placeholder="Porcentagem"
                  mask="separator.4"
                  decimalMarker=","
                  suffix="%"
                />
              </mat-form-field>
            </div>

            <div>
              <h2>Cliente 2</h2>
              <mat-form-field
                *ngIf="!showFirstName2"
                class="customer-autocomplete"
                style="padding: 3px"
              >
                <mat-label *ngIf="hideEdit2">Nome</mat-label>
                <mat-chip-list #chipList2>
                  <mat-chip
                    *ngFor="let customer of selectedCustomers2"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeCustomer(customer, 2)"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                    <mat-icon matChipRemove *ngIf="hideEdit2">cancel</mat-icon>
                  </mat-chip>
                  <input
                    *ngIf="hideEdit2"
                    matInput
                    #customerInput2
                    [formControl]="customerControl2"
                    formControlName="firstName2"
                    [matAutocomplete]="auto2"
                    [matChipInputFor]="chipList2"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (blur)="valueInputCpf($event, 2)"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selected($event, 2)">
                  <mat-option
                    *ngFor="let customer of filteredCustomers2 | async"
                    [value]="customer"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field *ngIf="showFirstName2">
                <input matInput formControlName="firstName2" placeholder="Nome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer2">
                <input matInput formControlName="lastName2" placeholder="Sobrenome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer2">
                <input matInput formControlName="cpf2" placeholder="CPF" mask="000.000.000-00" />
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  formControlName="percentage2"
                  placeholder="Porcentagem"
                  mask="separator.4"
                  decimalMarker=","
                  suffix="%"
                />
              </mat-form-field>
            </div>

            <div>
              <h2>Cliente 3</h2>
              <mat-form-field
                *ngIf="!showFirstName3"
                class="customer-autocomplete"
                style="padding: 3px"
              >
                <mat-label *ngIf="hideEdit3">Nome</mat-label>
                <mat-chip-list #chipList3>
                  <mat-chip
                    *ngFor="let customer of selectedCustomers3"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeCustomer(customer, 3)"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                    <mat-icon matChipRemove *ngIf="hideEdit3">cancel</mat-icon>
                  </mat-chip>
                  <input
                    *ngIf="hideEdit3"
                    matInput
                    #customerInput3
                    [formControl]="customerControl3"
                    formControlName="firstName3"
                    [matAutocomplete]="auto3"
                    [matChipInputFor]="chipList3"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (blur)="valueInputCpf($event, 3)"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="selected($event, 3)">
                  <mat-option
                    *ngFor="let customer of filteredCustomers3 | async"
                    [value]="customer"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field *ngIf="showFirstName3">
                <input matInput formControlName="firstName3" placeholder="Nome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer3">
                <input matInput formControlName="lastName3" placeholder="Sobrenome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer3">
                <input matInput formControlName="cpf3" placeholder="CPF" mask="000.000.000-00" />
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  formControlName="percentage3"
                  placeholder="Porcentagem"
                  mask="separator.4"
                  decimalMarker=","
                  suffix="%"
                />
              </mat-form-field>
            </div>

            <div>
              <h2>Cliente 4</h2>
              <mat-form-field
                *ngIf="!showFirstName4"
                class="customer-autocomplete"
                style="padding: 3px"
              >
                <mat-label *ngIf="hideEdit4">Nome</mat-label>
                <mat-chip-list #chipList4>
                  <mat-chip
                    *ngFor="let customer of selectedCustomers4"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeCustomer(customer, 4)"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                    <mat-icon matChipRemove *ngIf="hideEdit4">cancel</mat-icon>
                  </mat-chip>
                  <input
                    *ngIf="hideEdit4"
                    matInput
                    #customerInput4
                    [formControl]="customerControl4"
                    formControlName="firstName4"
                    [matAutocomplete]="auto4"
                    [matChipInputFor]="chipList4"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (blur)="valueInputCpf($event, 4)"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto4="matAutocomplete" (optionSelected)="selected($event, 4)">
                  <mat-option
                    *ngFor="let customer of filteredCustomers4 | async"
                    [value]="customer"
                  >
                    {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field *ngIf="showFirstName4">
                <input matInput formControlName="firstName4" placeholder="Nome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer4">
                <input matInput formControlName="lastName4" placeholder="Sobrenome" />
              </mat-form-field>

              <mat-form-field *ngIf="hideCustomer4">
                <input matInput formControlName="cpf4" placeholder="CPF" mask="000.000.000-00" />
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  formControlName="percentage4"
                  placeholder="Porcentagem"
                  mask="separator.4"
                  decimalMarker=","
                  suffix="%"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
      </div>
      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
