import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { RegulationService } from 'src/app/services/regulation.service'
import { CategoriesService } from 'src/app/services/categories.service'
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { Location } from '@angular/common'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { UploadAdapter } from '../../../../services/uploadAdapter.service'

@Component({
  selector: 'app-store-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
})
export class CategoriesListComponent implements OnInit {
  public headersTable: string[] = ['heading', 'order', 'status', 'actions']
  public categories: any[] = []
  public titleCategory: string
  public config: any
  public Editor = ClassicEditor

  constructor (
    public categoriesService: CategoriesService,
    public snackBar: MatSnackBar,
    private readonly location: Location,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit () {
    this.listCategories()
    this.config = { isReadOnly: true }
  }

  listCategories () {
    this.categoriesService.listCategories().subscribe(
      response => {
        localStorage.setItem('categories', JSON.stringify(response.results))
        this.categories = response.results
        this.titleCategory = response.titleCategory
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  viewCategories (categories) {
    localStorage.setItem('categoriesInfos', JSON.stringify(categories))
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover essa Categoria?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.categoriesService
            .deleteCategories(id)
            .toPromise()
            .then(
              async response => {
                this.listCategories()
                this.snackBar.open('Categoria removida com sucesso.')
              },
              error => {
                console.log(error)
                this.snackBar.open(error.error.message)
              },
            )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }

  onReady (eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdapter(loader)
    }
  }
}
