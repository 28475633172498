import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { startWith, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { ErrorsService } from '../../../../../core/services/errors.service'
import { AirportsService } from '../../../../../services/airports.service'
import { ReportService } from '../../../../../services/report.service'
import numeral from 'numeral'

@Component({
  selector: 'app-airports-stores-tax-cashback-form',
  templateUrl: './airports-stores-tax-cashback-form.component.html',
  styleUrls: ['./airports-stores-tax-cashback-form.component.scss'],
})
export class AirportsStoresTaxCashbackFormComponent implements OnInit {
  public formGroup: FormGroup
  id: any
  store: any

  public selectedStores: any[] = []
  stores: any[] = []
  filteredStores: Observable<string[]>
  public storeControl = new FormControl()
  separatorKeysCodes: number[] = [ENTER, COMMA]

  @ViewChild('storeInput', { static: false }) storeInput: ElementRef<HTMLInputElement>
  @ViewChild('autoStore', { static: false }) matAutocompleteStore: MatAutocomplete

  constructor (
    public errorsService: ErrorsService,
    public snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
    public airportsService: AirportsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public reportService: ReportService,
  ) { }

  ngOnInit () {
    this.id = this.route.snapshot.paramMap.get('id')

    this.reportService.listStores().subscribe(
      response => {
        response.map(item => {
          this.stores.push(item)
          this.filteredStores = this.storeControl.valueChanges.pipe(
            startWith(''),
            map((store: any) => (store ? this._filterStores(store) : this.stores.slice(0, 3))),
          )
        })
      },
      error => {
        console.log(error)
      },
    )

    this.formGroup = this.formBuilder.group({
      taxCashback: [null, [Validators.required]],
      storeId: [null, []],
    })

    if (this.id) {
      this.findOne().catch(err => console.log(err))
    }
  }

  async findOne () {
    await this.airportsService.fideOneStoresTaxCashback(this.id)
      .then(async response => {
        this.store = response.stores
        this.formGroup.patchValue({
          taxCashback: numeral(response.percentage).multiply(100).value(),
        })
        this.selectedStores.push(response.stores)
        this.formGroup.get('storeId').disable()
      })
  }

  private _filterStores (store: string): string[] {
    const remove = String(store)

    if (typeof remove !== 'string') {
      return
    }

    return this.stores
      .filter(c => !this.selectedStores.includes(c))
      .filter(c => `${String(c.nomeFantasia)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 3)
  }

  selectedStore (event: MatAutocompleteSelectedEvent): void {
    if (event.option.value.nomeFantasia && this.selectedStores.length < 1) {
      this.selectedStores.push(event.option.value)
      this.storeInput.nativeElement.value = ''
      this.storeControl.setValue(null)
    }
  }

  removeStore (value: any): void {
    if (value.nomeFantasia) {
      const index = this.selectedStores.indexOf(value)
      if (index >= 0) {
        this.selectedStores.splice(index, 1)
      }
    }
  }

  async submit () {
    if (!this.formGroup.valid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    const data = {
      percentage: numeral(this.formGroup.value.taxCashback).divide(100).value(),
      storeId: this.selectedStores.find(() => true).id,
    }

    if (this.id) {
      await this.airportsService.updateStoresTaxCashback(this.id, data).then(
        async response => {
          if (response.isUpdated) {
            await this.router.navigate(['./dashboard/airports/tax-cashback'])
            this.snackBar.open('taxa atualizada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    } else {
      await this.airportsService.createStoresTaxCashback(data).then(
        async response => {
          if (response.isCreated) {
            await this.router.navigate(['./dashboard/airports/tax-cashback'])
            this.snackBar.open('Taxa criada com sucesso.')
          }
        },
        error => {
          this.snackBar.open(error.error.message)
        },
      )
    }
  }
}
