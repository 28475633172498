import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
var QuestionsFormComponent = /** @class */ (function () {
    function QuestionsFormComponent(formBuilder, router, route, errorsService, snackBar, questionsService, location) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.errorsService = errorsService;
        this.snackBar = snackBar;
        this.questionsService = questionsService;
        this.location = location;
        this.quizID = '';
        this.id = '';
        this.typeQuestionSelected = false;
        this.typeOption = false;
        this.dateOption = false;
        this.textOption = false;
        this.singleChoiceOption = false;
        this.multipleChoiceOption = false;
        this.numberOption = false;
        this.optionInvalid = true;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.options = [];
    }
    QuestionsFormComponent.prototype.ngOnInit = function () {
        this.quizID = this.route.snapshot.paramMap.get('quizID');
        this.id = this.route.snapshot.paramMap.get('id');
        this.formGroup = this.formBuilder.group({
            question: [null, [Validators.required]],
            typeQuestion: [null, [Validators.required]],
            view: [null, [Validators.required]],
            option: [null, []],
        });
        if (this.id) {
            this.getById();
        }
    };
    QuestionsFormComponent.prototype.changeTypeQuestion = function (event) {
        this.typeQuestionSelected = true;
        if (event.value === 'SINGLE-CHOICE' ||
            event.value === 'MULTIPLE-CHOICE' ||
            event === 'SINGLE-CHOICE' ||
            event === 'MULTIPLE-CHOICE') {
            this.typeOption = true;
        }
        else {
            this.typeOption = false;
        }
        switch (event.value || event) {
            case 'TEXT':
                this.textOption = true;
                this.numberOption = false;
                this.dateOption = false;
                this.singleChoiceOption = false;
                this.multipleChoiceOption = false;
                break;
            case 'NUMBER':
                this.textOption = false;
                this.numberOption = true;
                this.dateOption = false;
                this.singleChoiceOption = false;
                this.multipleChoiceOption = false;
                break;
            case 'DATE':
                this.textOption = false;
                this.numberOption = false;
                this.dateOption = true;
                this.singleChoiceOption = false;
                this.multipleChoiceOption = false;
                break;
            case 'SINGLE-CHOICE':
                this.textOption = false;
                this.numberOption = false;
                this.dateOption = false;
                this.singleChoiceOption = true;
                this.multipleChoiceOption = false;
                break;
            case 'MULTIPLE-CHOICE':
                this.textOption = false;
                this.numberOption = false;
                this.dateOption = false;
                this.singleChoiceOption = false;
                this.multipleChoiceOption = true;
                break;
        }
    };
    QuestionsFormComponent.prototype.getById = function () {
        var _this = this;
        this.questionsService.findOne(this.quizID, this.id).then(function (response) {
            _this.changeTypeQuestion(response.type);
            _this.formGroup.patchValue({
                question: response.ask,
                typeQuestion: response.type,
                view: response.view,
                step: response.step,
            });
            _this.options = response.options.length ? response.options.split(';') : [];
        }, function (error) {
            _this.snackBar.open(error.error.message);
        });
    };
    QuestionsFormComponent.prototype.submit = function () {
        var _this = this;
        if ((this.formGroup.value.typeQuestion === 'SINGLE-CHOICE' && this.options.length === 0) ||
            (this.formGroup.value.typeQuestion === 'MULTIPLE-CHOICE' && this.options.length === 0)) {
            this.optionInvalid = false;
        }
        if (!this.formGroup.valid || !this.optionInvalid) {
            this.snackBar.open('Preencha corretamente os campos e tente novamente.');
            return false;
        }
        var data = {
            ask: this.formGroup.value.question,
            type: this.formGroup.value.typeQuestion,
            view: this.formGroup.value.view,
            options: this.options.length ? this.options.join(';') : '',
            isActive: true,
        };
        if (this.id) {
            this.questionsService.update(this.quizID, this.id, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.location.back();
                    this.snackBar.open('Pergunta alterada com sucesso.');
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.message);
            });
        }
        else {
            this.questionsService.create(this.quizID, data).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.location.back();
                    this.snackBar.open('Pergunta cadastrada com sucesso.');
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this.snackBar.open(error.message);
            });
        }
    };
    QuestionsFormComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if (value) {
            this.optionInvalid = true;
            this.options.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    };
    QuestionsFormComponent.prototype.remove = function (option) {
        var index = this.options.indexOf(option);
        if (index >= 0) {
            this.options.splice(index, 1);
        }
    };
    return QuestionsFormComponent;
}());
export { QuestionsFormComponent };
