import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModalVerifyStoreCampaignTypeComponent = /** @class */ (function () {
    function ModalVerifyStoreCampaignTypeComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.verifiedStores = [];
    }
    ModalVerifyStoreCampaignTypeComponent.prototype.ngOnInit = function () { };
    ModalVerifyStoreCampaignTypeComponent.prototype.changeFromStoreListCheckbox = function (event, i) {
        this.data.results[i].stores
            .map(function (partner) {
            if (partner.id === event.target.value) {
                partner.checked = event.target.checked;
            }
        });
    };
    ModalVerifyStoreCampaignTypeComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalVerifyStoreCampaignTypeComponent.prototype.submit = function () {
        var checkedStores = this.data.results
            .map(function (campaign) { return campaign.stores.filter(function (store) { return !store.checked; }); });
        this.dialogRef.close(checkedStores);
    };
    return ModalVerifyStoreCampaignTypeComponent;
}());
export { ModalVerifyStoreCampaignTypeComponent };
