import { Component, OnInit } from '@angular/core'
import { BannersService } from '../../../../services/banners.service'
import { CategoriesService } from 'src/app/services/categories.service'
import { MatSnackBar, MatDialog } from '@angular/material'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { ModalConfirmComponent } from '../../../../components/modal-confirm/modal-confirm.component'
import { BannersPagesAppService } from '../../../../services/bannersPagesApp.service'

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
})
export class BannerListComponent implements OnInit {
  public headersTable: string[] = ['order', 'description', 'initAt', 'endAt', 'isFixed', 'actions']
  public banners: any[] = []
  public idPage: any
  public page: any
  public category: string
  public subcategory: string
  public pages: string

  constructor (
    public bannersService: BannersService,
    public categoriesService: CategoriesService,
    public bannersPagesAppService: BannersPagesAppService,
    public snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
  ) { }

  ngOnInit () {
    this.page = this.route.snapshot.paramMap.get('page')
    this.idPage = this.route.snapshot.paramMap.get('idPage')

    this.list()
    if (this.page === 'category') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.category = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.page === 'subcategory') {
      this.categoriesService.findOne(this.idPage).then(
        response => {
          this.subcategory = response.heading
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
    if (this.page === 'home') {
      this.bannersPagesAppService.findOne(this.idPage).then(
        response => {
          this.pages = response.page
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )
    }
  }

  list () {
    let filter
    if (this.page === 'category') {
      filter = `?categoryId=${this.idPage}`
    }
    if (this.page === 'subcategory') {
      filter = `?categoryId=${this.idPage}`
    }
    if (this.page === 'home') {
      filter = `?appPageId=${this.idPage}`
    }

    this.bannersService.list(filter).then(
      response => {
        this.banners = response.results
      },
      error => {
        console.log(error)
        this.snackBar.open(error.error.message)
      },
    )
  }

  goBack () {
    this.location.back()
  }

  remove (id) {
    this.dialog
      .open(ModalConfirmComponent, {
        width: '500px',
        data: {
          text: 'Deseja remover esse Banner?',
          buttonConfirmText: 'Remover',
        },
      })
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (result && result !== 'cancel') {
          return this.bannersService.delete(id).then(
            async response => {
              this.list()
              this.snackBar.open('Banner removido com sucesso.')
            },
            error => {
              console.log(error)
              this.snackBar.open(error.error.message)
            },
          )
        }
      })
      .catch(err => {
        this.snackBar.open(err.message)
      })
  }
}
