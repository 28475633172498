<app-logged class="store-form">
  <header class="store-form__header">
    <h2 *ngIf="!userId">NOVO USUÁRIO</h2>
    <h2 *ngIf="userId">EDITAR USUÁRIO</h2>
    <div class="store-form__header__actions">
      <button class="btn-primary-light" mat-button routerLink="/dashboard/architect-store-users/{{ id }}">
        VOLTAR
      </button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-card-title>Informações Básicas</mat-card-title>
          <mat-card-content>
            <mat-form-field>
              <mat-label>Nome</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="formGroup.controls.firstName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.firstName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Sobrenome</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="formGroup.controls.lastName?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.lastName) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>E-mail</mat-label>
              <input matInput formControlName="emailAddress" />
              <mat-error *ngIf="formGroup.controls.emailAddress?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.emailAddress) }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefone</mat-label>
              <ngx-mat-intl-tel-input
                formControlName="phoneNumber"
                required
                [preferredCountries]="['br']"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="phone"
                #phone
              >
              </ngx-mat-intl-tel-input>
              <mat-error *ngIf="formGroup.controls.phoneNumber?.invalid">
                {{ errorsService.messageErrorFor(formGroup.controls.phoneNumber) }}
              </mat-error>
            </mat-form-field>

            <div *ngIf="userRole === 'ADMIN'">
              <mat-card-title >Permissoes</mat-card-title>
              <mat-card-content>
                <div *ngFor="let permission of permissions | keyvalue; let i=index" >
                  <label>
                    <input type="checkbox" 
                  [checked]="selectedPermissions[permission.key]"
                  [value]="permission.key"
                  (change)="changePermissions($event)"/> {{permission.value}} 
                  </label>
                </div>
              </mat-card-content>
            </div>

            <a class="btn-primary-light" (click)="openDialog()" mat-button> Adicionar </a>
            <!-- <p class="msg-error-not-store">{{ msgErrorNotStores }}</p> -->

            <p>Parceiros</p>
            <div class="group-radio" *ngIf="storesSelected.length">
              
              <div class="itens" *ngFor="let store of storesSelected; let i = index">
                <div class="detail">
                  <!-- <input
                    type="radio"
                    name="storeMain"
                    id="store-{{ store.id }}"
                    value="{{ store.id }}"
                    checked="{{ isChecked(store.id) }}"
                  /> -->
                  <label for="store-{{ store.id }}">{{ store.nomeFantasia }}</label
                  ><br />
                </div>
                <div class="actions">
                  <button
                    mat-icon-button
                    matTooltip="Remover"
                    matTooltipPosition="left"
                    (click)="removeStore(store)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!-- <p class="msg-error-not-store" *ngIf="!storeIdValidation">Campo deve ser preenchido</p> -->
          </mat-card-content>
        </div>
      </div>

      <div class="store-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Salvar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
