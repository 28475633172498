import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { enumManualJcoinCredit } from 'src/app/models/enumManualJcoinCredit';
import { ModalReviewFormComponent } from '../modal-review-form/modal-review-form.component';
var ManualJcoinCreditListComponent = /** @class */ (function () {
    function ManualJcoinCreditListComponent(service, snackBar, router, dialog, route, location) {
        this.service = service;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.route = route;
        this.location = location;
        this.headersTable = [
            'customerName', 'storeName', 'requestedAt', 'reviewedAt', 'jcoinsQt', 'status', 'buttons'
        ];
        this.page = 1;
        this.pageSize = 10;
        this.pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
    ManualJcoinCreditListComponent.prototype.ngOnInit = function () {
        this.list();
        this.fieldsForm = [
            {
                field: 'select',
                formControlName: 'manualJcoinCreditStatus',
                label: 'Status',
                list: [
                    { key: '', value: 'Todos' },
                    { key: enumManualJcoinCredit.PENDING, value: 'Pedente' },
                    { key: enumManualJcoinCredit.APPROVED, value: 'Aprovado' },
                    { key: enumManualJcoinCredit.DENIED, value: 'Reprovado' }
                ],
                optionDefault: '',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'manualJcoinCreditCreatedAtFrom',
                label: 'Solicitado a partir de',
                placeholder: 'dd/mm/yyyy',
            },
            {
                field: 'input',
                type: 'text',
                formControlName: 'manualJcoinCreditCreatedAtTo',
                label: 'Até',
                placeholder: 'dd/mm/yyyy',
            },
        ];
    };
    ManualJcoinCreditListComponent.prototype.list = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, _a, results, pagination;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = new URLSearchParams({
                            page: this.page.toString(),
                            pageSize: this.pageSize.toString()
                        });
                        return [4 /*yield*/, this.service
                                .list(params.toString())
                                .catch(function (err) {
                                _this.snackBar.open(err.message);
                                return { results: [], total: 0 };
                            })];
                    case 1:
                        _a = _b.sent(), results = _a.results, pagination = _a.pagination;
                        this.dataSource = new MatTableDataSource(results);
                        this.pageTotal = pagination.totalCount;
                        this.resultsAll = results;
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualJcoinCreditListComponent.prototype.getStatusColor = function (status) {
        switch (status) {
            case enumManualJcoinCredit.APPROVED:
                return 'green';
            case enumManualJcoinCredit.DENIED:
                return 'red';
            case enumManualJcoinCredit.PENDING:
            default:
                return 'grey';
        }
    };
    ManualJcoinCreditListComponent.prototype.getStatusName = function (status) {
        switch (status) {
            case enumManualJcoinCredit.APPROVED:
                return 'APROVADO';
            case enumManualJcoinCredit.DENIED:
                return 'REPROVADO';
            case enumManualJcoinCredit.PENDING:
                return 'PENDENTE';
            default:
                return 'DESCONHECIDO';
        }
    };
    ManualJcoinCreditListComponent.prototype.getOriginName = function (name) {
        switch (name) {
            case 'JHSFID':
                return 'JHSF ID';
            case 'ARCHITECT':
                return 'ARQUITETO';
            case 'JHSFID_ARCHITECT':
                return 'JHSF ID + ARQUITETO';
            default:
                return name;
        }
    };
    ManualJcoinCreditListComponent.prototype.openReviewModal = function (manualJcoinCredit) {
        var _this = this;
        console.info("Review modal opened for: " + manualJcoinCredit.id);
        var dialogRef = this.dialog.open(ModalReviewFormComponent, {
            width: '700px',
            data: {
                manualJcoinCredit: manualJcoinCredit,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.list();
        });
    };
    ManualJcoinCreditListComponent.prototype.reciverFeedback = function (returnFilter) {
        this.returnedFilters = returnFilter.results;
        this.pageTotal = returnFilter.pagination.totalCount;
        this.dataSource = new MatTableDataSource(returnFilter.results);
        this.resultsAll = returnFilter.results;
        this.formGroup = returnFilter.formGroup.value;
        this.params = {
            formGroupValue: JSON.stringify(this.formGroup),
        };
    };
    ManualJcoinCreditListComponent.prototype.getStoreObject = function (element) {
        if (element.store)
            return element.store;
        if (element.storeBusinessUnit)
            return element.storeBusinessUnit;
    };
    return ManualJcoinCreditListComponent;
}());
export { ManualJcoinCreditListComponent };
