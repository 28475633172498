<app-logged class="fund-form">
  <header class="fund-form__header">
    <h2>ASSOCIAR USUÁRIO AO FUNDO</h2>
    <div class="fund-form__header__actions">
      <button class="btn-primary-light" mat-button (click)="goBack()">VOLTAR</button>
    </div>
  </header>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-card>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mat-form-field class="customer-autocomplete">
            <mat-label>CPF ou Nome do Cliente</mat-label>
            <mat-chip-list #chipList>
              <mat-chip
                *ngFor="let customer of selectedCustomers"
                [selectable]="false"
                [removable]="true"
                (removed)="removeCustomer(customer)"
              >
                {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                matInput
                #customerInput
                [formControl]="customerControl"
                formControlName="customersInput"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                {{ customer.cpf | mask: '000.000.000-00' }} {{ customer.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error *ngIf="selectedCustomersInvalid">Campo deve ser preenchido</mat-error>
        </div>
      </div>
      <div class="fund-form__actions">
        <button class="btn-primary-light" type="submit" mat-button>Associar</button>
      </div>
    </mat-card>
  </form>
</app-logged>
